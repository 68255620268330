import { useState } from 'react';
import CreatePlaylist from 'pages/Playlists/components/CreatePlaylist';
import AssignUsers from '../AssignUsers';
import AddContent from '../AddContent';
import { IPlaylist } from 'contexts/playlists/types';
import { FIRST_PLAYLIST_CATEGORY } from 'constants/settings';

interface IStepsCreationParams {
  hasFinished: (refresh: boolean) => void;
}
const ThreeStepsCreation: React.FC<IStepsCreationParams> = ({ hasFinished }): JSX.Element => {
  const [isCreatingPlaylist, setIsCreatingPlaylist] = useState(true);
  const [newPlaylist, setNewPlaylist] = useState<IPlaylist>();
  const [addContentVisible, setAddContentVisible] = useState(false);
  const [addingUsers, setAddingUsers] = useState(false);

  const handleCloseCreatePlaylist = (playlist) => {
    setIsCreatingPlaylist(false);
    if (playlist) {
      setNewPlaylist(playlist);
      setAddContentVisible(true);
    } else {
      hasFinished(false);
    }
  };
  const handleAddContentFinished = () => {
    setAddContentVisible(false);
    setAddingUsers(true);
  };

  const handleAddUsersFinish = () => {
    setAddingUsers(false);
    setNewPlaylist(null);
    hasFinished(true);
  };

  return (
    <>
      {isCreatingPlaylist && (
        <CreatePlaylist
          title="Create Playlist"
          confirmLabel="Create playlist"
          method="create"
          isVisible={isCreatingPlaylist}
          onClose={handleCloseCreatePlaylist}
        />
      )}
      {!!addContentVisible && newPlaylist && (
        <AddContent
          playlist={newPlaylist}
          category={FIRST_PLAYLIST_CATEGORY}
          isVisible={addContentVisible}
          onClose={() => handleAddContentFinished()}
        />
      )}
      {!!addingUsers && newPlaylist && (
        <AssignUsers
          isVisible={addingUsers}
          playlist={newPlaylist}
          onCancel={handleAddUsersFinish}
        />
      )}
    </>
  );
};

export default ThreeStepsCreation;
