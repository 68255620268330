import React from 'react';

interface DonutProgressBarProps extends React.SVGProps<SVGSVGElement> {
  progress: number;
}

const DonutProgressBar: React.FC<DonutProgressBarProps> = ({ progress, ...rest }) => {
  const size = 24;
  const axis = size / 2;
  const strokeWidth = 3;
  const color = '#A793F6';
  const trackColor = '#EEEEEE';
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - progress * circumference;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      {...rest}
    >
      <circle
        cx={axis}
        cy={axis}
        r={radius}
        stroke={trackColor}
        strokeWidth={strokeWidth}
        fill="none"
      />
      <circle
        cx={axis}
        cy={axis}
        r={radius}
        stroke={color}
        strokeWidth={strokeWidth}
        fill="none"
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        strokeLinecap="round"
        style={{
          transition: 'stroke-dashoffset 0.35s',
          transform: 'rotate(-90deg)',
          transformOrigin: '50% 50%',
        }}
      />
    </svg>
  );
};
export default DonutProgressBar;
