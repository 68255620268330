import { createContext } from 'react';
import { FCProps } from 'types/FCProps';
import { raxInitialValues } from './utils';
import { IRaxDataCtx } from './types';

export const RaxContext = createContext<IRaxDataCtx>(raxInitialValues);

const RaxProvider = ({ children }: FCProps): JSX.Element => {
  return <RaxContext.Provider value={{ value: null }}>{children}</RaxContext.Provider>;
};

export default RaxProvider;
