import { useRef, useState } from 'react';
import { Container, Content, Item, Title } from './styles';
import ButtonIcon from 'components/buttonIcon';
import Radio from 'components/radio';
import useOnClickOutside from 'hooks/useOutsideClick';

export interface SortTableProps {
  name: string;
  id?: string;
  title?: string;
  items: SortItemsProps[];
  position?: string;
  onSelect: ({ name, value }: { name: string; value: string }) => void;
}

export interface SortItemsProps {
  value: string;
  label: string;
  selected: boolean;
}

export const SortTable = (props: SortTableProps): JSX.Element => {
  const { name, title, id, items, onSelect, position } = props;
  const [open, setOpen] = useState(false);
  const ref = useRef();

  const onClick = () => {
    setOpen(!open);
  };

  const close = () => {
    setOpen(false);
  };

  useOnClickOutside(ref, close);

  const handleOnSelect = ({ value }) => {
    setOpen(false);
    onSelect({ name, value });
  };

  return (
    <Container ref={ref}>
      <ButtonIcon
        style={{ margin: '0px 4px', minWidth: '30px' }}
        $terciary
        size={'small'}
        icon={'Sort'}
        onClick={onClick}
      />
      <Content open={open} position={position}>
        {title && <Title>{title}</Title>}
        {items.map((item, i) => (
          <Item key={i}>
            <Radio
              name={id}
              value={item.value}
              label={item.label}
              checked={item.selected}
              onChange={() => handleOnSelect({ value: item.value })}
              controlled
            />
          </Item>
        ))}
      </Content>
    </Container>
  );
};

export default SortTable;
