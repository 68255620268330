import { Dropdown } from 'antd';
import { useMemo, useRef, useState } from 'react';

import {
  AssingedValueContainer,
  AssingedValueName,
  DropdownContainer,
  ExtendedOptionContainer,
  ExtendedOptionInfo,
  ExtendedOptionName,
  IconPoint,
  InputArrowBtnContainer,
  InputSelectOptions,
  InputTypeSelectSearch,
  SelectDropdownCheckbox,
  SelectDropdownCheckboxContainer,
} from 'components/inputs/styles';
import ButtonIcon from 'components/button';
import Icon from 'components/icons';

interface IOption {
  label: string;
  value: string;
  info?: string;
  key?: string;
}

interface InputSelectSearchProps {
  value?: string;
  onChange?: (selected: IOption) => void;
  options?: IOption[];
  defaultOpen?: boolean;
  placeHolder?: string;
  disabled?: boolean;
  maxDropdownHeight?: string;
}

const inputSelectSearch: React.FC<InputSelectSearchProps> = ({
  onChange,
  options,
  defaultOpen,
  placeHolder,
  disabled,
  maxDropdownHeight,
}) => {
  const [open, setOpen] = useState(defaultOpen);
  const [selected, setSelected] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [showSelected, setShowSelected] = useState(false);
  const inputRef = useRef(null);
  const defaultEmptyOption = { label: null, value: null, info: null, key: null };

  const handleSelected = (value: IOption) => {
    setSelected(value);
    setShowSelected(true);
    onChange(value);
    setOpen(false);
  };

  const handleSearch = (value) => {
    if (selected) {
      setShowSelected(false);
      setSelected(defaultEmptyOption);
      onChange(defaultEmptyOption);
    }
    if (!open) setOpen(true);
    setSelected(null);
    setSearchQuery(value.target.value);
  };

  const handleArrowClick = () => {
    return null;
  };

  const handleVisibleChange = (visible) => {
    setOpen(visible);
  };

  const currentOptions = useMemo(() => {
    if (!searchQuery) return options;
    return options.filter(
      (option) =>
        option.label.toLowerCase().includes(searchQuery.toLowerCase()) ||
        option.info.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [searchQuery]);

  return (
    <DropdownContainer>
      {showSelected ? (
        <AssingedValueContainer>
          <AssingedValueName>{selected?.label}</AssingedValueName>
          <ButtonIcon
            className="account-edit__btn"
            $secondary
            size="small"
            icon="Edit"
            onClick={() => {
              setShowSelected(false);
              setOpen(true);
            }}
          />
        </AssingedValueContainer>
      ) : (
        <Dropdown
          overlay={
            <SelectDropdownCheckboxContainer>
              <SelectDropdownCheckbox maxHeight={maxDropdownHeight}>
                {currentOptions.map((option) => {
                  return (
                    <InputSelectOptions
                      extended
                      key={option.key}
                      onClick={() => handleSelected(option)}
                    >
                      <IconPoint />
                      <ExtendedOptionContainer>
                        <ExtendedOptionName>{option.label}</ExtendedOptionName>
                        {option.info && <ExtendedOptionInfo>{option.info}</ExtendedOptionInfo>}
                      </ExtendedOptionContainer>
                    </InputSelectOptions>
                  );
                })}
              </SelectDropdownCheckbox>
            </SelectDropdownCheckboxContainer>
          }
          overlayStyle={{ width: 'fit-content', minWidth: 'fit-content' }}
          trigger={['click']}
          visible={open}
          disabled={disabled}
          onVisibleChange={handleVisibleChange}
        >
          <InputTypeSelectSearch
            ref={inputRef}
            value={selected?.label}
            placeholder={placeHolder}
            onKeyUp={handleSearch}
            mode="multiple"
          ></InputTypeSelectSearch>
        </Dropdown>
      )}
      {!showSelected && (
        <InputArrowBtnContainer
          className={`${open ? 'input-arrow-rotated' : ''}`}
          onClick={handleArrowClick}
        >
          <Icon icon={'ChevronDown'} size={'smaller'} />
        </InputArrowBtnContainer>
      )}
    </DropdownContainer>
  );
};

export default inputSelectSearch;
