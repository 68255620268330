import { Input, Label, InputContainer } from 'components/modal/styles';

interface ISectionName {
  sectionName: string;
  setSectionName: (value: string) => void;
  section: boolean;
}

export const SectionNameInput = ({ sectionName, setSectionName }: ISectionName): JSX.Element => {
  return (
    <InputContainer>
      <Label>Section name</Label>
      <Input
        placeholder={'New section'}
        type={'text'}
        value={sectionName}
        onChange={(e) => setSectionName(e.currentTarget.value)}
      />
    </InputContainer>
  );
};
