import { useEffect, useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { useParams } from 'react-router-dom';
import AccountUsersAsideFilters from 'components/accountUsersAsideFilters';
import { MainContent } from 'components/layout/profile/profileContent/styles';
import AppReadyLoading from 'components/loadings/AppReadyLoading';
import Notification from 'components/notification';
import UserHeaderFilters from 'components/usersHeaderFilters';
import { Account } from 'contexts/accounts/types';
import { getTeamsAction } from 'contexts/teams/actions';
import { Team } from 'contexts/teams/types';
import UsersProvider from 'contexts/users';
import { getAccountData } from 'services/accounts';
import AccountUsersHeader from './AccountUsersHeader';
import GenericUsersTable from 'components/usersTable/GenericUsersTable';

const AccountUsersPage = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const [accountData, setAccountData] = useState<Account>(null);
  const [accountTeams, setAccountTeams] = useState<Team[]>();
  const [fetching, setFetching] = useState(false);

  const getProfileAccountData = async () => {
    setFetching(true);
    try {
      const response = await getAccountData({ id });
      setAccountData(response.data);
    } catch (error) {
      Notification({ text: 'Error retrieving Account information', type: 'error' });
    } finally {
      setFetching(false);
    }
  };

  const getAccountTeamsData = async () => {
    setFetching(true);
    const teamsData = await getTeamsAction({
      account: id,
      paginate: 'false',
      deep_details: 'false',
    });
    if (!(teamsData instanceof Error)) {
      setAccountTeams(teamsData?.results);
    }
    setFetching(false);
  };

  useEffect(() => {
    getProfileAccountData();
    getAccountTeamsData();
  }, []);

  if (!accountData) return <AppReadyLoading />;
  return (
    <>
      <MobileView>
        <AccountUsersHeader account={accountData} />
      </MobileView>
      <MainContent fetching={fetching}>
        <BrowserView>
          <AccountUsersHeader account={accountData} />
          <UsersProvider fixedFilters={{ accounts: accountData.id }}>
            <div className="mt-2 mt-md-0 mb-mb-18">
              <div className="m-md-0">
                <div className="container-fluid px-0 m-auto mw-100">
                  <div className="row pe-0 mw-100 m-auto">
                    <div className="col-3 d-none d-md-block ps-0" style={{ maxWidth: '275px' }}>
                      <div className="d-flex flex-column justify-content-between bg-white rounded h-100 rounded p-8">
                        <AccountUsersAsideFilters account={accountData} teams={accountTeams} />
                      </div>
                    </div>
                    <div
                      className="col-md-9 p-0 pe-md-0"
                      style={{ flexGrow: 1, background: '#F2F2F7', position: 'relative' }}
                    >
                      <div className={'rounded h-100 rounded p-0 p-md-2 bg-white'}>
                        <UserHeaderFilters />
                        <GenericUsersTable accountPage={true} refreshData={getProfileAccountData} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </UsersProvider>
        </BrowserView>
      </MainContent>
    </>
  );
};

export default AccountUsersPage;
