import { CloudGroups, CyberSecurityGroups, NetworkingGroups, SkillTabs } from './types';
import CyberSecurityIcon from 'assets/icons/skills/cyber-security.svg';
import CloudIcon from 'assets/icons/skills/cloud.svg';
import NetworkingIcon from 'assets/icons/skills/networking.svg';
import { colors } from 'constants/styles/colors';

export const TEAM_SKILLS_TABS: SkillTabs[] = [
  {
    key: 'cyberSecurity',
    item: 'CYBER_SECURITY',
    label: 'Cyber Security',
    soon: false,
    icon: CyberSecurityIcon,
    color: colors.learningArea.cybersecurity.base,
    background: colors.learningArea.cybersecurity.light,
    groups: CyberSecurityGroups,
  },
  {
    key: 'networking',
    item: 'NETWORKING',
    label: 'Networking',
    soon: false,
    icon: NetworkingIcon,
    color: colors.learningArea.cybersecurity.base,
    background: colors.learningArea.cybersecurity.light,
    groups: NetworkingGroups,
  },
  {
    key: 'cloud',
    item: 'CLOUD',
    label: 'Cloud',
    soon: false,
    icon: CloudIcon,
    color: colors.learningArea.cloud.base,
    background: colors.learningArea.cloud.light,
    groups: CloudGroups,
  },
];
