import { AxiosResponse } from 'axios';
import { config } from 'config/config.local';
import { DeepTeams, Team, Teams, TeamsParams } from 'contexts/teams/types';
import { BUSINESS_PYTHON } from './axios';

interface GetTeams {
  results: Teams[];
  count: number;
}

export interface BasicResponse {
  result?: boolean;
  status?: boolean;
}

export const getTeams = (): Promise<AxiosResponse<Team[]>> => {
  return BUSINESS_PYTHON.get(config.routes.teams.getTeams);
};

export const createTeam = (data: Team): Promise<AxiosResponse<Team>> => {
  return BUSINESS_PYTHON.post(config.routes.teams.createTeams, { ...data });
};

export const updateTeam = (data: Team): Promise<AxiosResponse<Team>> => {
  return BUSINESS_PYTHON.put(`${config.routes.teams.createTeams}${data.id}/`, { ...data });
};

export const getPaginatedTeams = (params: TeamsParams): Promise<AxiosResponse<GetTeams>> => {
  return BUSINESS_PYTHON.get(config.routes.teams.getTeams, { params });
};

export const removeTeams = ({ ids }: { ids: string[] }): Promise<AxiosResponse<BasicResponse>> => {
  return BUSINESS_PYTHON.post(config.routes.teams.removeTeams, { teams_ids: ids });
};

export const getTeamData = ({ id }: { id: string }): Promise<AxiosResponse<DeepTeams>> => {
  return BUSINESS_PYTHON.get(config.routes.teams.getTeams + id + '/');
};
