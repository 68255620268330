import { formatDistanceToNowStrict } from 'date-fns';
import { AccountCell, AccountStatusContainer, AccountStatusDot, DateToNowSpan } from './styles';
import { formatDateInUtc } from 'utils/helpers';

interface ItemProps {
  end_date: string;
  status: 'active' | 'past_due' | 'expired';
}

const statusTagStates = {
  past_due: { bgColor: '#F28815', label: 'Past Due' },
  expired: { bgColor: '#EE3B00', label: 'Expired' },
  active: null,
};

const DateCell = (props: ItemProps): JSX.Element => {
  const { end_date, status } = props;

  const endDate = new Date(end_date);
  const datetoNowLabel = formatDistanceToNowStrict(new Date(end_date), {
    addSuffix: true,
  });

  const statusTagState = statusTagStates[status];

  return (
    <AccountCell
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'baseline',
      }}
    >
      {statusTagState ? (
        <AccountStatusContainer>
          <AccountStatusDot bgColor={statusTagState.bgColor} />
          <span>{statusTagState.label}</span>
        </AccountStatusContainer>
      ) : (
        <b>{formatDateInUtc(endDate, 'PP')}</b>
      )}
      <DateToNowSpan>{datetoNowLabel}</DateToNowSpan>
    </AccountCell>
  );
};

export default DateCell;
