import { useContext } from 'react';
import InputSelectMultiple from 'components/inputs/inputSelectMultiple';
import { ActivityLogContext } from 'contexts/activityLog';
import { FilterItem } from 'contexts/activityLog/types';
import { SessionContext } from 'contexts/session';

interface IActivityLogTypesFilter {
  modalScreen?: boolean;
  modalValues?: string;
  typesFilterChange?: (filter: FilterItem) => void;
}

const ActivityLogTypesFilter = ({
  modalScreen,
  modalValues,
  typesFilterChange,
}: IActivityLogTypesFilter): JSX.Element => {
  const { isStaff, isAccountOwner } = useContext(SessionContext);
  const { actions, filters } = useContext(ActivityLogContext);

  let initialActivityLogTypes: string[];
  if (!modalScreen) {
    initialActivityLogTypes = filters.types ? filters.types.split(',') : [];
  } else {
    initialActivityLogTypes = modalValues ? modalValues.split(',') : [];
  }

  const activityLogTypes = isStaff
    ? [
        { label: 'Accounts', value: 'account', key: 'account' },
        { label: 'Invites', value: 'invite', key: 'invite' },
        { label: 'Licenses', value: 'license', key: 'license' },
        { label: 'Notes', value: 'note', key: 'note' },
        { label: 'Teams', value: 'team', key: 'team' },
      ]
    : isAccountOwner
    ? [
        { label: 'Accounts', value: 'account', key: 'account' },
        { label: 'Invites', value: 'invite', key: 'invite' },
        { label: 'Licenses', value: 'license', key: 'license' },
        { label: 'Teams', value: 'team', key: 'team' },
      ]
    : [
        { label: 'Invites', value: 'invite', key: 'invite' },
        { label: 'Licenses', value: 'license', key: 'license' },
        { label: 'Teams', value: 'team', key: 'team' },
      ];

  const onChangeCreatedBy = (value: string[] | number[]) => {
    if (modalScreen) {
      typesFilterChange({
        name: 'types',
        value: value.toString(),
      });
    } else {
      actions &&
        actions.onChangeFilter({
          name: 'types',
          value: value.toString(),
        });
    }
  };
  return (
    <InputSelectMultiple
      values={initialActivityLogTypes}
      inputLabel="Type of activity"
      placeholder="All"
      optionFilterProp={'label'}
      options={activityLogTypes}
      onChange={onChangeCreatedBy}
    />
  );
};

export default ActivityLogTypesFilter;
