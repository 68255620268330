import './statics.scss';
import Button from 'components/button';
import { INE_URL } from 'constants/domains';
import { durationFromSecs } from 'utils/helpers';
import { HideOnMobile } from './styles';

interface IPreviewData {
  objectID: string;
  description: string;
  difficulty: string;
  duration: number;
  learning_areas: { name: string }[];
  instructors: { name: string }[];
  summary: {
    total_courses: number;
    total_videos: number;
    total_quizzes: number;
    total_labs: number;
    total_iframes: number;
    total_exercises: number;
  };
}

const PreviewLearningPath = (props: { data: IPreviewData }): JSX.Element => {
  const { objectID, description, difficulty, duration, learning_areas, instructors, summary } =
    props.data;

  const handleDetails = () => {
    window.open(`${INE_URL}/path/${objectID}`);
  };

  return (
    <div className="preview-tooltip">
      <p className="preview-tooltip__description">
        {description.length > 600 ? description.slice(0, 600) + '...' : description}
      </p>

      <div className="preview-tooltip__info-container">
        <div className="preview-tooltip__info-row">
          <div className="preview-tooltip__info-column">DIFFICULTY</div>
          <div className="preview-tooltip__info-column preview-tooltip__text-capitalize">
            {difficulty}
          </div>
        </div>
        <div className="preview-tooltip__info-row">
          <div className="preview-tooltip__info-column">LEARNING AREA</div>
          <div className="preview-tooltip__info-column preview-tooltip__text-capitalize">
            {learning_areas[0]?.name}
          </div>
        </div>
        <div className="preview-tooltip__info-row">
          <div className="preview-tooltip__info-column">DURATION</div>
          <div className="preview-tooltip__info-column">
            {duration ? durationFromSecs(duration, 'seconds') : 0}
          </div>
        </div>
        {instructors.length > 0 && (
          <div className="preview-tooltip__info-row">
            <div className="preview-tooltip__info-column">INSTRUCTOR</div>
            <div className="preview-tooltip__info-column">{instructors[0]?.name}</div>
          </div>
        )}
        <HideOnMobile>
          <div className="preview-tooltip__info-row">
            <div className="preview-tooltip__info-column">ACTIVITIES</div>
            <div className="preview-tooltip__info-row">
              {summary?.total_videos > 0 && (
                <div className="preview-tooltip__activity-amount-tag">
                  VIDEOS:{summary.total_videos}
                </div>
              )}
              {summary?.total_quizzes > 0 && (
                <div className="preview-tooltip__activity-amount-tag">
                  QUIZZES:{summary.total_quizzes}
                </div>
              )}
              {summary?.total_labs > 0 && (
                <div className="preview-tooltip__activity-amount-tag">
                  LABS:{summary.total_labs}
                </div>
              )}
              {summary?.total_exercises > 0 && (
                <div className="preview-tooltip__activity-amount-tag">
                  EXERCISES:{summary.total_exercises}
                </div>
              )}
              {summary?.total_iframes > 0 && (
                <div className="preview-tooltip__activity-amount-tag">
                  SLIDES:{summary.total_iframes}
                </div>
              )}
            </div>
          </div>
        </HideOnMobile>
      </div>

      <div className="preview-tooltip__button-container">
        <Button icon="ExternalLink" $secondary size="small" onClick={handleDetails}>
          Preview learning path
        </Button>
      </div>
    </div>
  );
};

export default PreviewLearningPath;
