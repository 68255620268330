import { FC, useReducer, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Columns, Row } from 'components/layout/profile/profileContent/styles';
import Modal from 'components/modal';
import { MODAL_SIZES } from 'components/modal/types';
import {
  BodyContainer,
  HeaderContainer,
  InnerContainer,
  Title,
} from 'pages/Users/components/InviteUsers/styles';
import { formDataReducer, FormReducer, IAccountSsoProviderError } from './helper';
import SsoProviderForm from './SsoProviderForm';
import SsoProviderInformation from './SsoProviderInformation';
import { SsoProvider } from 'contexts/accounts/types';
import { createSsoProvider, editSsoProvider, CreateSsoProviderPayload } from 'services/accounts';
import Notification from 'components/notification';
import { certificateWithComments, validSlug } from 'components/utilities/formUtils';
import { Spinner } from 'components/loadings';

interface IAccountSsoProvider {
  isModalOpen?: boolean;
  ssoProvider: SsoProvider;
  accountId: string;
  onClose: (result?: 'ok' | 'error') => void;
}

const AccountSsoProviderCreate: FC<IAccountSsoProvider> = ({
  isModalOpen,
  ssoProvider,
  accountId,
  onClose,
}) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useReducer(formDataReducer, {
    companyName: ssoProvider?.company_name || '',
    providerName: ssoProvider?.provider_name || '',
    samlIssuerId: ssoProvider?.saml_issuer_id || '',
    loginUrl: ssoProvider?.login_url || '',
    certificate: ssoProvider?.certificate || '',
    givenName: ssoProvider?.given_name || '',
    familyName: ssoProvider?.family_name || '',
    email: ssoProvider?.email || '',
  });
  const [errors, setErrors] = useState<IAccountSsoProviderError[]>([]);
  const {
    companyName,
    providerName,
    samlIssuerId,
    loginUrl,
    certificate,
    givenName,
    familyName,
    email,
  } = formData;

  const handleFormValidations = () => {
    if (!companyName) {
      setErrors((prevState) => [
        ...prevState,
        { key: 'SET_COMPANY_NAME', msg: 'Company Name required' },
      ]);
      return false;
    } else if (!validSlug(companyName)) {
      setErrors((prevState) => [
        ...prevState,
        { key: 'SET_COMPANY_NAME', msg: 'Invalid Company Name' },
      ]);
      return false;
    } else if (!providerName) {
      setErrors((prevState) => [
        ...prevState,
        { key: 'SET_PROVIDER_NAME', msg: 'Provider Name required' },
      ]);
      return false;
    } else if (!samlIssuerId) {
      setErrors((prevState) => [
        ...prevState,
        { key: 'SET_SAML_ISSUER_ID', msg: 'SAML Issuer ID required' },
      ]);
      return false;
    } else if (!loginUrl) {
      setErrors((prevState) => [...prevState, { key: 'SET_LOGIN_URL', msg: 'Login URL required' }]);
      return false;
    } else if (!certificate) {
      setErrors((prevState) => [
        ...prevState,
        { key: 'SET_CERTIFICATE', msg: 'Certificate required' },
      ]);
      return false;
    } else if (certificateWithComments(certificate)) {
      setErrors((prevState) => [
        ...prevState,
        { key: 'SET_CERTIFICATE', msg: 'Please remove comments from your certificate' },
      ]);
      return false;
    } else if (!givenName) {
      setErrors((prevState) => [
        ...prevState,
        { key: 'SET_GIVEN_NAME', msg: 'Given Name required' },
      ]);
      return false;
    } else if (!familyName) {
      setErrors((prevState) => [
        ...prevState,
        { key: 'SET_FAMILY_NAME', msg: 'Family Name required' },
      ]);
      return false;
    } else if (!email) {
      setErrors((prevState) => [...prevState, { key: 'SET_EMAIL', msg: 'Email required' }]);
      return false;
    }
    return true;
  };

  const handleFormChanges = (data: FormReducer) => {
    data.value && setErrors((prev) => prev.filter((error) => error.key !== data.type));
    setFormData({
      type: data.type,
      value: data.value,
    });
  };

  const handleSubmit = async () => {
    if (handleFormValidations()) {
      setLoading(true);
      const ssoProviderData = {
        company_name: formData.companyName,
        provider_name: formData.providerName,
        saml_issuer_id: formData.samlIssuerId,
        login_url: formData.loginUrl,
        certificate: formData.certificate,
        given_name: formData.givenName,
        family_name: formData.familyName,
        email: formData.email,
      } as CreateSsoProviderPayload;

      try {
        ssoProvider
          ? await editSsoProvider(ssoProviderData, accountId, ssoProvider.id)
          : await createSsoProvider(ssoProviderData, accountId);
        const notificationMsg = ssoProvider ? 'SSO provider edited' : 'SSO provider created';
        Notification({ type: 'success', text: notificationMsg, duration: 8 });
        setLoading(false);
        onClose('ok');
      } catch (error) {
        const notificationMsg = ssoProvider
          ? 'Couldn´t edit SSO provider'
          : 'Couldn´t create SSO provider';
        setLoading(false);
        Notification({ type: 'error', text: notificationMsg });
      }
    }
  };

  return (
    <Modal
      centered
      onCancel={() => onClose()}
      visible={isModalOpen}
      fullscreen={isMobile}
      footer={null}
      width={isMobile ? '100%' : MODAL_SIZES.LARGE}
    >
      <InnerContainer>
        <HeaderContainer>
          <Title>Configure SSO/SAML</Title>
        </HeaderContainer>
        <BodyContainer>
          <Spinner active={loading} top="300px" left="50%" position="relative" />
          <Row>
            <Columns className="setup-information">
              <SsoProviderInformation />
            </Columns>
            <Columns className="setup-form">
              <SsoProviderForm
                errors={errors}
                formData={formData}
                loading={loading}
                onCancel={onClose}
                onFieldChange={handleFormChanges}
                onSubmit={handleSubmit}
              />
            </Columns>
          </Row>
        </BodyContainer>
      </InnerContainer>
    </Modal>
  );
};

export default AccountSsoProviderCreate;
