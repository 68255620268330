export const LEARNING_AREAS = {
  CLOUD: 'Cloud',
  CYBER_SECURITY: 'Cyber Security',
  DATA_SCIENCE: 'Data Science',
  DEVELOPMENT: 'Development',
  IT_ESSENTIALS: 'IT Essentials',
  NETWORKING: 'Networking',
};

export const getInitialPreferredContentValues = () => {
  return [
    {
      name: LEARNING_AREAS.CLOUD,
      itemData: 0,
    },
    {
      name: LEARNING_AREAS.CYBER_SECURITY,
      itemData: 0,
    },
    {
      name: LEARNING_AREAS.DATA_SCIENCE,
      itemData: 0,
    },
    {
      name: LEARNING_AREAS.DEVELOPMENT,
      itemData: 0,
    },
    {
      name: LEARNING_AREAS.IT_ESSENTIALS,
      itemData: 0,
    },
    {
      name: LEARNING_AREAS.NETWORKING,
      itemData: 0,
    },
  ];
};

export const CHART_BAR_COLORS = {
  OVERALL_ACTIVITIES: '#A793F6',
  VIDEOS_WATCHED: '#3E6496',
  PASSED_QUIZZES: '#F19E5B',
  FINISHED_LABS: '#D73F47',
  FINISHED_SLIDES: '#65AAA3',
};

export interface BarsChartProps {
  data: Array<{
    itemData: number;
    itemId: ActivitiesTypes;
    name: string;
  }>;
  tickImage: boolean;
  svg?: boolean;
}

export type ActivitiesTypes =
  | 'OVERALL_ACTIVITIES'
  | 'VIDEOS_WATCHED'
  | 'PASSED_QUIZZES'
  | 'FINISHED_LABS'
  | 'FINISHED_SLIDES';
