import styled from 'styled-components';
import { DatePicker as AntdDatePicker } from 'antd';
import { colors } from 'constants/styles/colors';
import { screenMd, screenSm } from 'constants/styles/breakpoints';
import { Content } from 'pages/Analytics/components/ActiveUsersCollapse/styles';
import ModalDrawer from 'components/microLayouts/drawer';

const { RangePicker } = AntdDatePicker;

export interface NotesTableHeaderProps {
  columns: string;
  sticky?: boolean;
}

export const NotesTableHeader = styled.div<NotesTableHeaderProps>`
  border-bottom: 1px solid
    ${({ theme }) => theme.default.colors.palette.Business_LightThemeGreyLighter};
  height: 70px;
  align-items: center;
  padding: 0 10px;
  margin: 2px 0px;
  background: #ffffff;
  border-radius: 10px;
  ${screenSm} {
    height: 54px;
    background: transparent;
    border-radius: 0px;
    padding: 0;
    margin: 0;
  }

  ${({ sticky }) =>
    sticky &&
    `
		${screenMd} {
			position: sticky;
			top: 0px;
			background: #FFF !important;
			z-index: 99;
		}
	`}

  display: grid;
  grid-template-columns: 40px 6fr 40px;
  ${screenMd} {
    grid-template-columns: ${({ columns }) => columns || '4fr 5fr 4fr 128px'};
  }
`;

export const NotesTableColumn = styled.div`
  display: flex;
  width: 100%;
  white-space: nowrap;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.fonts.darkGray};
  position: relative;
`;

export const NotesTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 140px;
  height: 400px;
  overflow-y: scroll;

  padding: 0px 0px 0px 20px;
`;

export interface ICustomNotesTableRow {
  columns: string;
  showMore?: boolean;
}

export const CustomNotesTableRow = styled.div<ICustomNotesTableRow>`
  border-bottom: 1px solid
    ${({ theme }) => theme.default.colors.palette.Business_LightThemeGreyLighter};
  align-items: initial;
  text-align: start;
  margin: 2px 0px;
  background: #ffffff;
  display: grid;
  grid-template-columns: 40px 6fr 40px;

  ${screenMd} {
    grid-template-columns: ${({ columns }) => columns || '43px 6fr 4fr 2fr 2fr 2fr 90px'};
  }
`;

export const NotesTableTextCell = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  align-items: center;
  padding: 6px 3px;

  small {
    color: ${colors.fonts.darkGray};
    font-weight: 450;
    font-size: 10px;
    line-height: 12px;
  }
`;

export const NotesCell = styled.div<{ showMore?: boolean }>`
  display: flex;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-items: center;
  padding: 6px 3px;

  ${({ showMore }) =>
    showMore &&
    `
    align-items: initial;
    margin-top: 15px;
  `}
`;

export const NotesFiltersContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const NotesMobileDrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NotesContainerMobile = styled(Content)`
  display: grid;
  gap: 10px;
  max-height: ${window.innerHeight - 350}px;
  overflow-y: scroll;
`;

export const NoteDataItemMobile = styled.div`
  width: 100%;
  border: 1px solid ${colors.dataVisualization.gray.dusty};
  border-radius: 10px;
  padding: 10px;
  height: fit-content;
`;

export const NotesMobileTitle = styled.h2`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
`;

export const NotesDrawerFiltersContainer = styled.div`
  margin-top: 10px;
  display: flex;
  gap: 15px;
`;

export const NoteListItemHeader = styled.div`
  font-size: 12px;
  line-height: 14px;
  strong {
    font-weight: 700;
  }
  span {
    font-weight: 450;
    color: ${colors.fonts.darkGray};
  }
`;

export const NoteListItemText = styled.div`
  font-size: 12px;
  line-height: 20px;
  font-weight: 450;
`;

export const NoteDetailDrawer = styled(ModalDrawer)`
  .ant-drawer-content {
    background-color: red !important;
  }
`;

export const NoteDetailHeader = styled.div`
  padding: 10px;
  h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 14px;
    color: ${colors.fonts.darkGray};
  }
  strong {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  }
  span {
    font-weight: 450;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const NotesRangeDatePicker = styled(RangePicker)`
  background-color: transparent;
  border-color: transparent;
  box-shadow: unset;
  &:hover {
    border-color: transparent;
  }
  .ant-picker-range-separator,
  .ant-picker-input,
  .ant-picker-clear {
    display: none;
  }
  .ant-picker-suffix {
    svg path {
      stroke: #a793f6;
    }
  }
`;
