import {
  IPlaylistFetchParams,
  IPlaylistsFetchParams,
  getPlaylist,
  getPlaylists,
  userAssignedPlaylist,
} from 'services/playlists';
import { IPlaylist } from './types';
import { handleErrors } from '../helpers';
import { fetchPlaylistChangelog } from 'services/playlists';

export const getPlaylistsAction = async (
  params: IPlaylistsFetchParams
): Promise<{ results: IPlaylist[]; count: number } | Error> => {
  try {
    const { data } = await getPlaylists(params);
    return {
      results: data.results,
      count: data.count,
    };
  } catch (error) {
    return handleErrors(error);
  }
};

export const getPlaylistAction = async (
  params: IPlaylistFetchParams
): Promise<{ playlist: IPlaylist } | Error> => {
  try {
    const { data } = await getPlaylist(params);
    return {
      playlist: data,
    };
  } catch (error) {
    return handleErrors(error);
  }
};

export const getUserAssignedPlaylistAction = async (
  user_id: string
): Promise<{ results: any; count: number } | Error> => {
  try {
    const { data } = await userAssignedPlaylist(user_id);
    return {
      results: data.results,
      count: data.count,
    };
  } catch (error) {
    return handleErrors(error);
  }
};

export const getPlaylistChangelogAction = async (playlist_id: string): Promise<any | Error> => {
  try {
    const { data } = await fetchPlaylistChangelog(playlist_id);
    return data;
  } catch (error) {
    return handleErrors(error);
  }
};
