import React, { useEffect, useRef, useState } from 'react';
import styled, { CSSObject, css } from 'styled-components';

const Balloon = styled.div<{
  visible: boolean;
  customStyles?: React.CSSProperties;
  position: {
    top?: string;
    right?: string;
    bottom?: string;
    left?: string;
    transform?: string;
  };
  placement?: 'top' | 'topRight';
}>`
  position: fixed;
  z-index: 9;
  ${({ position }) =>
    `
      top: ${position.top};
      right: ${position.right};
      bottom: ${position.bottom};
      left: ${position.left};
      transform: ${position.transform};
    `}

  display: flex;
  padding: 8px 16px;
  border-radius: 4px;
  background: #57575c;
  color: white;
  text-align: center;
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  font-style: normal;
  font-feature-settings: 'liga' off, 'clig' off;
  width: max-content;
  max-width: 300px;
  white-space: normal;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  transition: opacity 0.5s;
  ${({ customStyles }) => customStyles && css(customStyles as CSSObject)}

  &::after {
    content: '';
    position: absolute;
    z-index: 9;
    bottom: -5px;

    ${({ placement }) => {
      switch (placement) {
        case 'top':
          return `
            left: 50%;
          `;
        case 'topRight':
          return `
            left: 80%;
          `;
        default:
          return `
            left: 50%;
          `;
      }
    }}

    transform: translateX(-50%) rotate(45deg);

    width: 10px;
    height: 10px;
    background: #57575c;
  }
`;

export const Tooltip: React.FC<{
  content: React.ReactNode;
  textStyles?: React.CSSProperties;
  triggerRef?: React.RefObject<HTMLElement | SVGSVGElement>;
  placement?: 'top' | 'topRight';
}> = ({ content, textStyles, triggerRef, children, placement = 'top' }) => {
  const [visible, setVisible] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const childrenRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<{
    top?: string;
    right?: string;
    bottom?: string;
    left?: string;
    transform?: string;
  }>({});

  const showTooltip = () => setVisible(true);
  const hideTooltip = () => setVisible(false);

  const clonedChildren = React.isValidElement(children)
    ? React.cloneElement(children as React.ReactElement, {
        ref: (node: HTMLDivElement) => {
          childrenRef.current = node;
        },
      })
    : children;

  useEffect(() => {
    const refListener = triggerRef || childrenRef;
    if (refListener?.current) {
      const elementListener = refListener.current;
      elementListener.addEventListener('mouseenter', showTooltip);
      elementListener.addEventListener('mouseleave', hideTooltip);

      return () => {
        elementListener.removeEventListener('mouseenter', showTooltip);
        elementListener.removeEventListener('mouseleave', hideTooltip);
      };
    }
  }, [triggerRef, childrenRef]);

  useEffect(() => {
    if (visible && tooltipRef.current && childrenRef.current) {
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      const childrenRect = childrenRef.current.getBoundingClientRect();
      const triangleSpacing = 10;

      const newPosition: {
        top?: number;
        right?: number;
        bottom?: number;
        left?: number;
        transform?: string;
      } = {};

      switch (placement) {
        case 'top':
          newPosition.top = childrenRect.y - tooltipRect.height - triangleSpacing;
          newPosition.left = childrenRect.x + childrenRect.width / 2;
          newPosition.transform = 'translateX(-50%)';
          break;
        case 'topRight':
          newPosition.top = childrenRect.y - tooltipRect.height - triangleSpacing;
          newPosition.left = childrenRect.x + childrenRect.width / 2;
          newPosition.transform = 'translateX(-80%)';
          break;
        default:
          break;
      }

      setPosition({
        top: newPosition.top ? `${newPosition.top}px` : 'auto',
        right: newPosition.right ? `${newPosition.right}px` : 'auto',
        bottom: newPosition.bottom ? `${newPosition.bottom}px` : 'auto',
        left: newPosition.left ? `${newPosition.left}px` : 'auto',
        transform: newPosition.transform,
      });
    }
  }, [visible, tooltipRef, childrenRef]);

  return (
    <>
      {clonedChildren}
      <Balloon
        ref={tooltipRef}
        visible={visible}
        position={position}
        customStyles={textStyles}
        placement={placement}
      >
        {content}
      </Balloon>
    </>
  );
};

export default Tooltip;
