import { useState } from 'react';
import Tooltip from 'components/tooltip';
import Icon from 'components/icons';
import {
  LabFilterRadioContainer,
  LabFilterRadioLabel,
  LabFilterTooltipWrapper,
  LabsFilterRadio,
  LabsFilterWrapper,
} from './styles';

interface LabsFilterProps {
  onChange: (selected: string) => void;
  options: {
    label: string;
    value: string;
    key?: string;
    tooltip?: string;
  }[];
  value: string | boolean;
}

const LabsFilter = ({ onChange, options }: LabsFilterProps): JSX.Element => {
  const [selected, setSelected] = useState('');

  const handleRadioChange = (value: string) => {
    setSelected(value);
    onChange(value);
  };

  return (
    <LabsFilterWrapper>
      {options.map(({ label, value, tooltip }) => (
        <LabFilterRadioContainer key={value}>
          <LabsFilterRadio
            value={value}
            checked={selected === value}
            onClick={() => handleRadioChange(value)}
          />
          <LabFilterRadioLabel>{label}</LabFilterRadioLabel>

          {tooltip && (
            <LabFilterTooltipWrapper>
              <Tooltip placement="right" destroyTooltipOnHide title={tooltip} zIndex={9999}>
                <Icon size="small" fill={'#7965C6'} style={{ cursor: 'pointer' }} icon="Info" />
              </Tooltip>
            </LabFilterTooltipWrapper>
          )}
        </LabFilterRadioContainer>
      ))}
    </LabsFilterWrapper>
  );
};

export default LabsFilter;
