import { Modal } from 'antd';
import styled from 'styled-components';

export const TeamSelectorModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-body {
    padding: 10px 10px 0px 10px;
    height: 350px;
    overflow: auto;
  }
  .ant-modal-footer {
    padding: 0px;
    border-top: 0px;
  }
`;

export const TeamMobileFooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  padding: 10px;
  border-radius: 6px;
  > button {
    flex-grow: 1;
    &:first-of-type {
      margin-right: 10px;
    }
  }
`;
