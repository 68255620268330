import { FC, useContext } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { AccountHeaderProfileProps } from 'contexts/accounts/types';
import emptyAccOwnersSvg from 'assets/images/profile-no-accowner.svg';
import Button from 'components/button';
import Tooltip from 'components/tooltip';
import InviteUsers from 'pages/Users/components/InviteUsers';
import { SessionContext } from 'contexts/session';

const Container = styled.div`
  width: 100%;
  max-height: 352px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed ${({ theme }) => theme.default.colors.palette.Business_DataVizLilacLighter};
  padding: 30px;
  border-radius: 10px;
  box-shadow: none !important;
  > img {
    max-height: 218px;
  }
  > span {
    max-width: 226px;
  }
`;

const NoAccountOwner: FC<AccountHeaderProfileProps> = ({ account, handleModal, refreshData }) => {
  const { isTeamManager, isAccountOwner } = useContext(SessionContext);
  const isOwnerOrManager = isTeamManager || isAccountOwner;

  const accountInfo = {
    ...account,
    licenses_available:
      account.licenses_details?.count === null
        ? true
        : account.licenses_details?.count - account.licenses_details?.used,
    id: account.id,
  };

  const hasDomains = account?.email_domains?.length > 0; // update when backend sends domains data

  const handleInviteOwners = (result) => {
    handleModal(null);
    result === 'ok' && refreshData();
  };

  const handleAddAccOwner = () => {
    handleModal(
      <InviteUsers
        account={accountInfo}
        isEditingInvite={true}
        inviteAccOwner
        onClose={handleInviteOwners}
      />
    );
  };

  return (
    <Container>
      <img src={emptyAccOwnersSvg} />
      {hasDomains && !isOwnerOrManager ? (
        <Button icon={'Plus'} onClick={handleAddAccOwner}>
          Add account owner
        </Button>
      ) : (
        <Tooltip
          placement="top"
          title="Add a valid domain first"
          trigger={isMobile ? 'click' : 'hover'}
        >
          <span>
            <Button icon={'Plus'} onClick={handleAddAccOwner} disabled={true}>
              Add account owner
            </Button>
          </span>
        </Tooltip>
      )}
    </Container>
  );
};

export default NoAccountOwner;
