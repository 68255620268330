import { useHistory, useLocation } from 'react-router-dom';
import { URLSearchParams as URLSearchParamsTypes } from '../contexts/users/types';

interface SearchHook {
  search: URLSearchParamsTypes;
  updateSearch: (newSearch: string) => void;
  getFilter: (filter: string) => string;
  clearSearch: () => void;
  searchQuery: string;
  getLastFilter: () => string;
}

const useSearchQuery = (): SearchHook => {
  const { search } = useLocation();
  const history = useHistory();

  const urlSearch = new URLSearchParams(search);

  const handleSearchUpdate = (newSearch: string) => {
    history.push({ search: newSearch });
  };

  const handleGetFilter = (filter: string) => {
    return urlSearch.get(filter);
  };

  const handleClearSearch = () => {
    history.push({ search: '' });
  };

  const getLastFilter = () => {
    const arr = search.split('&');
    return arr?.[arr?.length - 1].split('=')[0];
  };

  return {
    search: urlSearch,
    updateSearch: handleSearchUpdate,
    getFilter: handleGetFilter,
    clearSearch: handleClearSearch,
    searchQuery: search,
    getLastFilter: getLastFilter,
  };
};

export default useSearchQuery;
