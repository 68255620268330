import React, { useContext, useState } from 'react';

import Modal from 'components/modal';

import { isMobile } from 'react-device-detect';
import { PlaylistNameInput } from './PlaylistNameInput';
import { PlaylistDescriptionInput } from './PlaylistDescriptionInput';
import {
  HeaderContainer,
  ModalActions,
  ModalContainer,
  ModalContent,
  ModalSection,
} from 'components/modal/styles';
import { PlaylistColorSelect } from './PlaylistColorSelect';
import Button from 'components/button';
import { createPlaylist, editPlaylist } from 'services/playlists';
import { IPlaylist } from 'contexts/playlists/types';
import Notification from 'components/notification';
import { SessionContext } from 'contexts/session';
import { FIRST_PLAYLIST_CATEGORY, INE_UUID } from 'constants/settings';

interface ICreatePlaylist {
  title: string;
  confirmLabel: string;
  method: string;
  isVisible?: boolean;
  playlist?: IPlaylist;
  onClose?: (playlist?: IPlaylist) => void;
  onGoBack?: () => void;
}

const CreatePlaylist: React.FC<ICreatePlaylist> = ({
  title,
  confirmLabel,
  method,
  isVisible,
  playlist,
  onClose,
}) => {
  const [name, setName] = useState(playlist?.name || '');
  const [description, setDescription] = useState(playlist?.description || '');
  const [color, setColor] = useState<string>(playlist?.color || '');
  const [localLoading, setLocalLoading] = useState(false);
  const { account } = useContext(SessionContext);
  const business_account_id = account?.id;

  const updateColor = (color: string) => {
    setColor(color);
  };

  const handleConfirm = () => {
    const reqData: IPlaylist = {
      name: name || 'No name',
      color,
      description,
      business_account_id,
      company: INE_UUID, // INE always
    };

    reqData.content = playlist?.content
      ? playlist.content.map(({ name, content_type, content }) => {
          return {
            name,
            content_type,
            content: content.map(({ uuid, content_type }) => {
              return { uuid, content_type };
            }),
          };
        })
      : [
          {
            name: FIRST_PLAYLIST_CATEGORY,
            content_type: 'group',
            content: [],
          },
        ];

    method === 'create' ? handlePlaylistCreation(reqData) : handlePlaylistEdit(reqData);
  };

  const handlePlaylistCreation = async (data) => {
    setLocalLoading(true);
    try {
      const response = await createPlaylist(data);
      if (response.status === 201) {
        Notification({ text: `${response.data.name} has been created`, type: 'success' });
        setLocalLoading(false);
        onClose(response.data);
        return;
      } else {
        Notification({ text: 'There has been a problem creating your playlist', type: 'error' });
      }
    } catch (error) {
      if (error?.response?.data?.details) {
        Notification({ text: error.response.data.details, type: 'error' });
      }
    }
    setLocalLoading(false);
    onClose();
  };

  const handlePlaylistEdit = async (data) => {
    setLocalLoading(true);
    try {
      const response = await editPlaylist({ ...data, id: playlist.id });
      if (response.status === 200) {
        Notification({ text: `${response.data.name} has been edited`, type: 'success' });
        setLocalLoading(false);
        onClose(response.data);
        return;
      } else {
        Notification({ text: 'There has been a problem editing your playlist', type: 'error' });
      }
    } catch (error) {
      Notification({ text: 'There has been a problem editing your playlist', type: 'error' });
      throw error;
    }
    setLocalLoading(false);
    onClose();
  };

  return (
    <Modal
      centered
      visible={isVisible}
      fullscreen={isMobile}
      onCancel={() => onClose()}
      width={isMobile ? '100%' : '595px'}
      footer={null}
      closeIconColor="#FFF"
      maskClosable={false}
    >
      <ModalContainer>
        <HeaderContainer>
          <h2>{title}</h2>
        </HeaderContainer>
        <ModalContent>
          <ModalSection>
            <PlaylistNameInput
              playlist={!!playlist}
              playlistName={name}
              setPlaylistName={(value) => setName(value)}
            />
            <PlaylistDescriptionInput
              playlist={!!playlist}
              playlistDescription={description}
              setPlaylistDescription={(value) => setDescription(value)}
            />
          </ModalSection>
          <ModalSection>
            <PlaylistColorSelect
              selectedColor={color}
              onChange={(color: string) => updateColor(color)}
            />
          </ModalSection>
        </ModalContent>
        <ModalActions>
          <Button
            $primary
            disabled={!((/^(?!\s*$).+/g.test(name) && /^(?!\s*$).+/g.test(color)) || localLoading)}
            style={{ marginLeft: 'auto' }}
            onClick={handleConfirm}
          >
            {confirmLabel}
          </Button>
        </ModalActions>
      </ModalContainer>
    </Modal>
  );
};

export default CreatePlaylist;
