import { useRef } from 'react';
import styled from 'styled-components';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import generatePicker from 'antd/lib/date-picker/generatePicker';
import { PickerProps } from 'antd/lib/date-picker/generatePicker';

const DateFnsPicker = generatePicker<Date>(dateFnsGenerateConfig);

interface CustomDatePickerProps {
  hideIcon?: boolean;
  small?: boolean;
  isForm?: boolean;
  error?: boolean;
}

const CustomDatePicker = styled(DateFnsPicker)<{
  hideIcon?: boolean;
  small?: boolean;
  isForm?: boolean;
  error?: boolean;
}>`
  border: none;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.default.colors.palette.Business_LightThemeGreyLighter};
  color: ${({ theme }) => theme.default.colors.palette.Business_DarkThemeCardBackground};
  font-size: 12px;
  .ant-picker-input {
    input::placeholder {
      color: ${({ theme }) => theme.default.colors.fonts.darkGray} !important;
    }
    > .ant-picker-clear {
      width: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${({ theme }) =>
        theme.default.colors.palette.Business_LightThemeGreyLighter};
      ${({ isForm }) =>
        isForm &&
        `
					width: 16px;
					height: 16px;
				`}
    }
  }
  ${({ hideIcon }) =>
    hideIcon &&
    `
		.ant-picker-suffix{
			display: none;
		}
	`};
  ${({ small }) =>
    small &&
    `
		.ant-picker-input{
			input{ 
				font-size: 12px;
				line-height: 28px; 
			}
		}
		${
      !small &&
      `
			height: 44px;
		`
    }
	`};
  ${({ small }) =>
    !small &&
    `
			height: 44px;
	`}
  ${({ error }) =>
    error &&
    `
		box-shadow: 0px 0px 0px 1px red;	`}
`;

const CustomDropdown = styled.div`
  .ant-picker-panel-container {
    border-radius: 12px;
    padding: 10px;

    .ant-picker-header-view {
      height: 60px !important;
      line-height: 60px !important;
    }

    .ant-picker-panel {
      border: none !important;
    }
  }

  .ant-picker-header-super-prev-btn,
  .ant-picker-header-super-next-btn {
    display: none;
  }

  .ant-picker-header-prev-btn,
  .ant-picker-header-next-btn {
    color: ${({ theme }) => theme.default.colors.palette.Business_DataVizLilacLighter};
    &:hover {
      color: ${({ theme }) => theme.default.colors.palette.Business_DataVizLilacDark};
    }
    .ant-picker-prev-icon,
    .ant-picker-next-icon {
      width: 12px;
      height: 12px;
      &:before {
        width: 12px;
        height: 12px;
      }
    }
  }

  .ant-picker-cell-inner {
    border-radius: 50% !important;
    &:before {
      border-color: ${({ theme }) =>
        theme.default.colors.palette.Business_DataVizLilacLighter} !important;
      border-radius: 50% !important;
      z-index: -1 !important;
      opacity: 0.7 !important;
    }
  }

  .ant-picker-cell-selected {
    .ant-picker-cell-inner {
      background-color: ${({ theme }) => theme.default.colors.palette.Business_DataVizLilacLighter};
    }
  }

  .ant-picker-footer {
    padding: 20px !important;
    .ant-picker-today-btn {
      border: 1px solid ${({ theme }) => theme.default.colors.palette.Business_DataVizLilacLighter};
      display: block;
      border: 1px solid #7965c6;
      font-size: 14px;
      width: 100%;
      line-height: 36px;
      text-align: center;
      border-radius: 8px;
      color: ${({ theme }) => theme.default.colors.palette.Business_DarkThemeGeneralBackground};
    }
  }
`;

const DatePicker = (props: CustomDatePickerProps & PickerProps<Date>): JSX.Element => {
  const dropdown = useRef();
  return (
    <>
      <CustomDropdown ref={dropdown}></CustomDropdown>
      <CustomDatePicker {...props} getPopupContainer={() => dropdown.current} />
    </>
  );
};

export default DatePicker;
