import { useState } from 'react';
import Button from 'components/button';
import CalendarContainer from './CalendarContainer';
import Modal from 'components/modal';
import {
  HeaderContainer,
  ModalActions,
  ModalContainer,
  ModalContent,
} from 'components/modal/styles';

import { FooterContainer, FooterInfo, InfoColorContainer, InfoColorReference } from './styles';
interface AvailabilityProps {
  raxLabId: string;
  accountId?: string;
}

const AvailabilityModalAction = ({ raxLabId, accountId }: AvailabilityProps): JSX.Element => {
  const [openModal, setOpenModal] = useState(false);
  const closeModal = () => setOpenModal(false);

  return (
    <>
      <Button
        $terciary
        size={'small'}
        className="availability-action"
        onClick={() => setOpenModal(true)}
      >
        View calendar
      </Button>
      {openModal && (
        <div className="position-relative">
          <Modal
            visible={openModal}
            bodyStyle={{ borderRadius: '20px' }}
            footer={null}
            width={'719px'}
            closeIconColor="#fff"
            maskClosable={false}
            onCancel={() => setOpenModal(false)}
          >
            <ModalContainer
              style={{ borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }}
            >
              <HeaderContainer isRax={true} style={{ height: '108px', padding: 0 }}>
                <h2>Product Availability</h2>
              </HeaderContainer>
            </ModalContainer>
            <ModalContent style={{ paddingBottom: 10, height: '580px' }}>
              <CalendarContainer
                isInteractable={false}
                raxLabId={raxLabId}
                accountId={accountId}
                fetchData={true}
                isModal={true}
                isLarge={true}
              />
            </ModalContent>
            <ModalActions>
              <FooterContainer>
                <InfoColorContainer>
                  <InfoColorReference bgcolor="#D0D2DB" borderColor="#D0D2DB" />
                </InfoColorContainer>
                <FooterInfo>
                  Not available: All slots have been booked. <br />
                  This product requires scheduling for five consecutive days.
                </FooterInfo>
              </FooterContainer>
              <Button $secondary style={{ marginLeft: 'auto' }} onClick={closeModal}>
                Close calendar
              </Button>
            </ModalActions>
          </Modal>
        </div>
      )}
    </>
  );
};

export default AvailabilityModalAction;
