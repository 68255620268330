import styled from 'styled-components';
import { Switch } from 'antd';

export const StyledSwitch = styled(Switch)`
  &.ant-switch {
    display: block;
    background-color: ${({ theme }) => theme.default.colors.dataVisualization.gray.dark};
    &.ant-switch-checked {
      background-color: ${({ theme }) => theme.default.colors.dataVisualization.purple.base};
    }
  }
`;

export const SwitchLabel = styled.label`
  display: block;
  font-size: 12px;
  line-height: 14px;
  color: #1a2535;
  margin-bottom: 6px;
  text-align: center;
`;

export const SwitchContainer = styled.div`
  display: block;
`;
