import React, { useMemo } from 'react';
import {
  AccountName,
  AssigmentsList,
  AssigmentsListContainer,
  AssignmentCount,
  AssignmentDivider,
  AssignmentItem,
  AssignmentTextContainer,
  Assignments,
  Card,
  CardContent,
  Category,
  CategoryText,
  Container,
  ContentBlock,
  ContentItem,
  ContentSection,
  Description,
  DescriptionAndCategory,
  FooterDivider,
  Indicator,
  InfoIcon,
  ItemDetailContainer,
  ItemDetailTitle,
  ItemText,
  NoAssignments,
  Number,
  NumberBig,
  Subtitle,
  Text,
  Title,
  ViewCalendar,
} from './styles/ProductCard.styles';

import Tooltip from 'components/tooltip';
import AssignModalAction from './AssignModalAction';
import AvailabilityModalAction from './AvailabilityModalAction';
import { Link } from 'react-router-dom';

export interface ProductCardProps {
  name: string;
  description?: string;
  category?: { name: string; color: string; id: string };
  assignments: { account_id: string; account_name: string; quantity: number }[];
  checkfront_sku: string;
  id: string;
  max_quantity: number;
  booking_consecutive_days: number;
  accounts?: Array<any>;
  onAssign?: (value) => void;
}

const ProductCard: React.FC<ProductCardProps> = ({
  name,
  description,
  category,
  assignments,
  checkfront_sku,
  max_quantity,
  id,
  booking_consecutive_days,
  accounts,
  onAssign,
}) => {
  const durationText = `${booking_consecutive_days} ${
    booking_consecutive_days === 1 ? 'day' : 'days'
  }`;

  const totalAssignments = assignments?.reduce((acc, assignment) => acc + assignment.quantity, 0);

  const product = {
    id,
    name,
    description,
    category,
    checkfront_sku,
    max_quantity,
    booking_consecutive_days,
    assignments,
  };

  const onAssignProduct = (data) => {
    onAssign({ ...data, rax_lab: id });
  };

  const filteredAssignments = useMemo(() => {
    const filteredAssignments = [];
    assignments?.map((assignment) => {
      const filteredIndex = filteredAssignments.findIndex((filteredAssignment) => {
        return filteredAssignment.account_id === assignment.account_id;
      });
      return filteredIndex === -1
        ? filteredAssignments.push(assignment)
        : (filteredAssignments[filteredIndex].quantity += assignment.quantity);
    });
    return filteredAssignments;
  }, [assignments]);

  return (
    <>
      <Container>
        <Card>
          <Indicator color={category?.color} />
          <CardContent>
            <ContentSection>
              <ContentBlock>
                <ContentItem>
                  <Title>{name}</Title>
                </ContentItem>
              </ContentBlock>
              <ContentBlock className="gap-9">
                <ContentItem>
                  <DescriptionAndCategory>
                    <Description>{description}</Description>
                    <Category>
                      <CategoryText>{category?.name}</CategoryText>
                    </Category>
                  </DescriptionAndCategory>
                </ContentItem>
                <ContentItem info>
                  <ItemDetailContainer style={{ width: '200px' }}>
                    <ItemDetailTitle>SKU</ItemDetailTitle>
                    <ItemText>{checkfront_sku}</ItemText>
                  </ItemDetailContainer>
                  <ItemDetailContainer>
                    <ItemDetailTitle>
                      <Text>Duration</Text>
                    </ItemDetailTitle>
                    <ItemText>{durationText}</ItemText>
                  </ItemDetailContainer>
                  <AssignmentCount>
                    <AssignmentDivider />
                    <Assignments>
                      <NumberBig>{totalAssignments}</NumberBig>
                      <AssignmentTextContainer>
                        <Text>Assignments</Text>
                        <Tooltip
                          placement="bottom"
                          title={'Following bookings, scheduled or unscheduled'}
                          overlayInnerStyle={{ textAlign: 'center' }}
                        >
                          <InfoIcon size="small" icon="Info" />
                        </Tooltip>
                      </AssignmentTextContainer>
                    </Assignments>
                  </AssignmentCount>
                  <AssignModalAction
                    product={product}
                    accounts={accounts}
                    onAssign={onAssignProduct}
                  />
                </ContentItem>
              </ContentBlock>
            </ContentSection>
            <ContentSection className="pt-0">
              <FooterDivider />
              <Subtitle>Assignments</Subtitle>
              <AssigmentsListContainer>
                <AssigmentsList>
                  {filteredAssignments?.length ? (
                    filteredAssignments.map((assignment, index) => (
                      <AssignmentItem key={index}>
                        <Number>{assignment.quantity}</Number>
                        <Link to={`/account/${assignment.account_id}`}>
                          <AccountName>{assignment.account_name}</AccountName>
                        </Link>
                      </AssignmentItem>
                    ))
                  ) : (
                    <NoAssignments>
                      No assignments yet. Start by assigning a product now.{' '}
                    </NoAssignments>
                  )}
                </AssigmentsList>
                <ViewCalendar>
                  <AvailabilityModalAction raxLabId={product.id} />
                </ViewCalendar>
              </AssigmentsListContainer>
            </ContentSection>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default ProductCard;
