import styled, { css } from 'styled-components';

const twoItemsStyles = css`
  width: 75px;
  height: 75px;
  margin-left: 10px;
  > div {
    width: 56px;
    height: 56px;
    &:nth-child(1) {
      top: -7px;
      right: 50%;
    }
    &:nth-child(2) {
      bottom: -7px;
      left: 50%;
    }
  }
`;

const threeItemsStyles = css`
  width: 115px;
  height: 115px;
  > div {
    &:nth-child(1) {
      top: 0px;
      left: 2px;
      width: 59.29px;
      height: 59.29px;
    }
    &:nth-child(2) {
      top: 27px;
      right: -10px;
      width: 52px;
      height: 52px;
    }
    &:nth-child(3) {
      bottom: -3px;
      left: 25px;
      width: 54px;
      height: 54px;
    }
  }
`;
const fourItemsStyles = css`
  width: 115px;
  height: 115px;
  > div {
    &:nth-child(1) {
      width: 59px;
      height: 59px;
      top: 0px;
      left: 0px;
    }
    &:nth-child(2) {
      top: 8px;
      right: -10px;
      width: 52px;
      height: 52px;
    }
    &:nth-child(3) {
      bottom: 6px;
      left: 12px;
      width: 46px;
      height: 46px;
    }
    &:nth-child(4) {
      bottom: 10px;
      right: 11px;
      width: 40px;
      height: 40px;
    }
  }
`;

const fiveItemsStyles = css`
  width: 92px;
  height: 92px;
  > div {
    &:nth-child(1) {
      top: -7px;
      left: 16px;
      width: 44px;
      height: 44px;
    }
    &:nth-child(2) {
      top: 20px;
      right: -8px;
      width: 40px;
      height: 40px;
    }
    &:nth-child(3) {
      top: 42px;
      left: 35px;
      width: 22px;
      height: 22px;
      > div {
        font-size: 100%;
      }
    }
    &:nth-child(4) {
      top: 36px;
      left: -3px;
      width: 35px;
      height: 35px;
      > div {
        font-size: 100%;
      }
    }
    &:nth-child(5) {
      bottom: -7px;
      left: 29px;
      width: 30px;
      height: 30px;
      > div {
        font-size: 100%;
      }
    }
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const BaseCircle = styled.div<{ circles: number }>`
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 50%;
  background: linear-gradient(
    112.2deg,
    rgba(187, 188, 199, 0.4) -46.39%,
    rgba(187, 188, 199, 0) 112.38%
  );
  > div {
    position: absolute;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  }
  ${({ circles }) => {
    switch (circles) {
      case 2:
        return twoItemsStyles;
      case 3:
        return threeItemsStyles;
      case 4:
        return fourItemsStyles;
      default:
        return fiveItemsStyles;
        break;
    }
  }}
`;

export const CountMore = styled.div`
  width: 25px;
  height: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 10px;
  font-weight: 700;
  background-color: ${({ theme }) => theme.default.colors.palette.Business_DarkThemeCardBackground};
  border-radius: 50%;
  bottom: 2px;
  right: 2px;
`;

export const Circle = styled.div`
  overflow: hidden;
  border: 1px solid #fff;
  border-radius: 50%;
  filter: drop-shadow(0px 6px 24px rgba(97, 84, 148, 0.3));
  > div,
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    > svg {
      width: 80% !important;
      height: 80% !important;
    }
  }
`;
