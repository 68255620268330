import { useMemo, useState } from 'react';
import { Row } from 'antd';
import { SectionCard } from 'pages/Analytics/components/AnalyticsContent/styles';
import {
  Content,
  Title,
  TitleWrapper,
} from 'pages/Analytics/components/ActiveUsersCollapse/styles';
import Button from 'components/button';
import ButtonIcon from 'components/buttonIcon';
import AssignedProductCard from 'pages/RaxPage/components/AssignedProductCard';
import { Link } from 'react-router-dom';
import { Spinner } from 'components/loadings';

interface AccountRaxProps {
  products: any;
  showBookedFirst?: boolean;
  onRemoveRaxSlot?: (id: string) => void;
}

const AccountRaxCollapsibleContent = ({
  loading,
  setLoading,
  products,
  showBookedFirst,
  onRemoveRaxSlot,
}) => {
  return (
    <Content
      fetching={loading}
      className="mt-9"
      style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
    >
      <Spinner active={loading} left={'50%'} top={'100px'} size="50" position="relative" />
      {products?.map((product, index) => (
        <Row key={`p-${index}`}>
          <AssignedProductCard
            {...product}
            onRemoveRaxSlot={onRemoveRaxSlot}
            showBookedFirst={showBookedFirst}
          />
        </Row>
      ))}
    </Content>
  );
};

const AccountRax: React.FC<AccountRaxProps> = ({
  products,
  showBookedFirst = false,
  onRemoveRaxSlot,
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <>
      <SectionCard padding={'30px'} rounded dashboard shadow>
        <header>
          <TitleWrapper className="align-items-center gap-3">
            <Title>Remote Access Experience</Title>
            <Link to={`/rax`} className="ms-auto">
              <Button $secondary size="small">
                Go to RAX
              </Button>
            </Link>
            <div className="d-flex">
              <ButtonIcon
                style={{ marginLeft: '10px' }}
                $terciary
                size="small"
                icon={collapsed ? 'ChevronUp' : 'ChevronDown'}
                onClick={() => toggleCollapse()}
              />
            </div>
          </TitleWrapper>
        </header>
        {!collapsed && (
          <AccountRaxCollapsibleContent
            {...{ loading, setLoading, products, showBookedFirst, onRemoveRaxSlot }}
          />
        )}
      </SectionCard>
    </>
  );
};

export default AccountRax;
