import { screenMd } from 'constants/styles/breakpoints';
import styled from 'styled-components';

export const Container = styled.section<{ lock?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${({ lock }) =>
    lock &&
    `
  opacity: 0.3;
  pointer-events:none;`}

  ${screenMd} {
    flex-direction: column;
  }
`;
