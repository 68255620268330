import { AxiosResponse } from 'axios';
import { UserTeamsPayload } from 'components/usersTable/types';
import { config } from 'config/config.local';
import { BUSINESS_PYTHON } from './axios';

export interface UpdateInviteLinkPayload {
  assign_license: boolean;
  teams: UserTeamsPayload[];
  inviteId?: string;
}

export const getInviteLink = (data: UpdateInviteLinkPayload): Promise<AxiosResponse> => {
  return BUSINESS_PYTHON.post(`${config.routes.users.getInviteLink}`, { ...data });
};

export const getUpdatedInviteLink = (
  data: UpdateInviteLinkPayload
): Promise<AxiosResponse<UpdateInviteLinkPayload>> => {
  const inviteId = data.inviteId;
  delete data['inviteId'];
  return BUSINESS_PYTHON.put(`${config.routes.users.getInviteLink}${inviteId}/`, { ...data });
};
