import Bugsnag from '@bugsnag/js';
import { BUSINESS_URL, INE_URL } from 'constants/domains';
import { INVALID_TOKEN, NOT_ALLOWED } from 'constants/errors/codes';
import { END_USERS_NOT_ALLOWED_MSG, INVALID_TOKEN_REDIRECT_MSG } from 'constants/errors/messages';

interface IServiceError extends Error {
  response;
}

export const handleErrors = (error: IServiceError): Error | any => {
  const errorMsg = error.response?.data?.details
    ? error.response?.data?.details
    : error.response?.data?.detail;
  if (process.env.NODE_ENV !== 'test') {
    Bugsnag.notify(errorMsg);
    const redirectUrl = `${INE_URL}/login?redirect_url=${BUSINESS_URL}`;
    if (error.response?.status === INVALID_TOKEN) {
      window.location.replace(`${redirectUrl}&error=${INVALID_TOKEN_REDIRECT_MSG}`);
    } else if (error.response?.status === NOT_ALLOWED) {
      window.location.replace(`${redirectUrl}&error=${END_USERS_NOT_ALLOWED_MSG}`);
    }
    throw Error;
  }
};
