import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0px 20px 0 0;
  width: 100%;
`;

export const Content = styled.div<{ open: boolean; position: string }>`
  position: absolute;
  top: 45px;
  padding: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  min-width: 240px;
  z-index: 10;
  display: none;

  ${({ open }) =>
    open &&
    `
		display: inline-block;
	`}

  ${({ position }) =>
    position === 'right' &&
    `
		right: -50%;
	`}

	${({ position }) =>
    position === 'left' &&
    `
		left: -50%;
	`}
`;

export const Item = styled.div`
  display: flex;
  padding: 10px;
`;

export const Title = styled.div`
  padding: 10px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
`;
