import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import useOnClickOutside from 'hooks/useOutsideClick';

const Container = styled.div`
  display: flex;
  position: relative;
`;

const Bg = styled.div<{ dark: boolean }>`
  background: #fff;

  ${({ dark }) =>
    dark &&
    `
		background: linear-gradient(104.99deg, rgba(62, 63, 73, 0.7) -13.91%, rgba(62, 63, 73, 0) 100.48%);
	`};
`;

const Dropdown = styled.div<{ dark: boolean; options?: Options }>`
  position: absolute;
  overflow: hidden;
  border-radius: 10px;
  top: ${({ options }) => options.top};
  right: ${({ options }) => options.right};
  bottom: ${({ options }) => options.bottom};
  left: ${({ options }) => options.left};
  height: ${({ options }) => options.height};
  width: ${({ options }) => options.width};
  background: #fff;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.15);
  z-index: 700;

  ${({ dark }) =>
    dark &&
    `
		background: linear-gradient(180deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #222222;
		box-shadow: 0px 30px 48px rgba(0, 0, 0, 0.25);
	`};
`;

interface Options {
  top?: string;
  bottom?: string;
  right?: string;
  left?: string;
  height?: string;
  width?: string;
}

interface DropdownProps {
  ButtonElement?: React.ReactNode;
  buttonOnClick?: ({ active: boolean }) => void;
  children?: React.ReactNode;
  styleElement: Options;
  dark?: boolean;
  active?: boolean;
}

const SimpleDropdown = ({
  ButtonElement,
  buttonOnClick,
  children,
  styleElement,
  dark,
  active,
}: DropdownProps): JSX.Element => {
  const ref = useRef();
  const [open, setOpen] = useState(false);

  const onClickItem = () => {
    if (buttonOnClick) buttonOnClick({ active: !open });
    setOpen(!open);
  };

  const onClickOutside = () => {
    setOpen(false);
    if (buttonOnClick) buttonOnClick({ active: false });
  };

  useEffect(() => {
    setOpen(active);
  }, [active]);

  useOnClickOutside(ref, onClickOutside);

  const options: Options = {
    top: styleElement.top || 'unset',
    bottom: styleElement.bottom || 'unset',
    right: styleElement.right || 'unset',
    left: styleElement.left || 'unset',
    height: styleElement.height || 'auto',
    width: styleElement.width || '280px',
  };

  return (
    <Container ref={ref}>
      <span
        onClick={() => {
          onClickItem();
        }}
      >
        {ButtonElement}
      </span>
      {open && (
        <Dropdown options={options} dark={dark}>
          <Bg dark={dark}>{children}</Bg>
        </Dropdown>
      )}
    </Container>
  );
};

export default SimpleDropdown;
