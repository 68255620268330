import styled from 'styled-components';

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
  border: none;
  .ant-input-affix-wrapper,
  span.ant-input-suffix,
  input {
    width: 100%;
    border: none;
    background-color: var(--grey-lighter);
    color: var(--light-gray-darker);
    border-radius: 10px;
  }
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    box-shadow: none;
  }
`;

export const UsersContainer = styled.div`
  height: 100%;
  max-height: 482px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  overflow-y: auto;
`;

export const UserRow = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--grey-lighter);
  height: 70px;
  box-sizing: border-box;
  cursor: pointer;
  .form-check-input {
    background-color: var(--grey-lighter);
    border-color: var(--grey-lighter);
    position: relative;
    cursor: pointer;
    &:checked {
      background-color: var(--grey-lighter);
      border-color: var(--grey-lighter);
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: var(--lilac);
      }

      .ant-radio-inner::after {
        display: block; /* Show when radio is checked */
      }
    }
  }
`;

export const UserContainer = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  margin: 5px 0;
`;

export const UserName = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-left: 5px;
  color: var(--positive-dark);
  span {
    color: var(--menu-subcategory);
  }
`;

export const ScheduleDaysInfo = styled.div`
  font-size: 12px;
  display: flex;
  flex: 0 0 318px;
  font-style: italic;
  color: var(--menu-subcategory);
  span {
    margin-right: 1px;
    font-style: normal;
  }
`;
