import { FC, useEffect, useState } from 'react';
import { format, subDays } from 'date-fns';
import { Skeleton } from 'antd';
import { getAgregationAction } from 'contexts/analytics/actions';
import { AggregationCard, AggregationCardContainer, AggregationStatsCardLabel } from './styles';
import { AggregationStatsData } from 'contexts/analytics/types';
import Icon from 'components/icons';
import { colors } from 'constants/styles/colors';

const AggregationStats: FC<{ aggregations: AggregationStatsData[] }> = ({ aggregations }) => {
  const [counters, setCounters] = useState(null);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    processAggregationsData();
  }, []);

  const processAggregationsData = async () => {
    aggregations.map(async ({ name, params }) => {
      setFetching(true);
      const currentParams = {
        ...params,
        date_from: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
        date_to: format(new Date(), 'yyyy-MM-dd'),
      };
      const previousParams = {
        ...params,
        date_from: format(subDays(new Date(), 60), 'yyyy-MM-dd'),
        date_to: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
      };
      try {
        const currentResponse = await getAgregationAction(currentParams);
        const previousResponse = await getAgregationAction(previousParams);
        const currentValue = currentResponse instanceof Error ? '-' : currentResponse.results;
        const previousValue = previousResponse instanceof Error ? '-' : previousResponse.results;
        const variation = calculateVariation(currentValue, previousValue);
        setCounters((prevState) => ({
          ...prevState,
          [name]: {
            current: currentValue,
            previous: previousValue,
            variation: variation,
          },
        }));
        setFetching(false);
      } catch (error) {
        setCounters((prevState) => ({
          ...prevState,
          [name]: '-',
        }));
        setFetching(false);
      }
    });
  };

  const calculateVariation = (current, previous) => {
    if (current !== '-' && previous !== '-') {
      if (current === 0 && previous === 0) {
        return 0;
      } else if (previous === 0) {
        return 100;
      } else {
        return Math.floor(((current - previous) / previous) * 100);
      }
    }
    return '-';
  };

  return (
    <>
      {fetching ? (
        <Skeleton />
      ) : (
        <>
          <AggregationCardContainer>
            {aggregations.map(({ name, label }) => (
              <AggregationCard key={name}>
                <div className="stat-container">
                  {counters?.[name] && (
                    <>
                      <span className="stat-quantity">{counters?.[name]?.current}</span>
                      <div className="stat-variation-container">
                        {counters?.[name]?.variation > 0 ? (
                          <Icon icon="ArrowUp" color={colors.performance.positive} />
                        ) : (
                          <Icon icon="ArrowDown" color={colors.performance.negative} />
                        )}
                        <span className="stat-variation">
                          {counters?.[name]?.variation < 0
                            ? counters?.[name]?.variation.toString().split('-')[1]
                            : counters?.[name]?.variation}
                          %
                        </span>
                      </div>
                    </>
                  )}
                </div>
                <span className="stat-footer">{label}</span>
              </AggregationCard>
            ))}
          </AggregationCardContainer>
          <AggregationStatsCardLabel>Current month vs previous month</AggregationStatsCardLabel>
        </>
      )}
    </>
  );
};

export default AggregationStats;
