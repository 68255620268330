import styled from 'styled-components';
import { Button as AntdButton } from 'antd';

import TeamSelect from '../../../Users/components/InviteUsers/TeamSelect';

import { DatePicker } from 'antd';
import { screenSm } from 'constants/styles/breakpoints';
const { RangePicker } = DatePicker;

export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
`;

export const TeamsSelectContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

export const TeamsSelector = styled(TeamSelect)`
  &:nth-child(2) {
    background: red;
    div:nth-child(2) {
      background: red;
    }
  }
  flex-wrap: wrap;
`;

export const FilterContainer = styled.div`
  font-weight: 450;
  font-size: 15px;
  line-height: 19px;
  color: #57575c;
`;
