import { useContext } from 'react';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { AccountsContext } from 'contexts/accounts';
import { Content, Count, Filter, FilterContainer, Title } from '../styles';
import Checkbox from 'components/checkbox/Checkbox';

const AccountAddonsFilter = (): JSX.Element => {
  const { filters, actions, counts } = useContext(AccountsContext);

  const setFilter = (e: React.ChangeEvent<HTMLInputElement> | CheckboxChangeEvent) => {
    const { name, value } = e.target;
    actions.onChangeFilters({
      name: name,
      value: value,
      multiple: true,
    });
    actions.clearSelectedItems();
  };

  return (
    <FilterContainer>
      <Title>Add-ons</Title>
      {counts?.addons_count?.map((addonCount) => (
        <Content key={addonCount.id}>
          <Filter>
            <Checkbox
              label={addonCount.name || 'Addon'}
              value={addonCount.id}
              id="addons"
              name="addons"
              onChange={setFilter}
              defaultChecked={filters.addons.includes(addonCount.id)}
              checked={filters.addons.includes(addonCount.id)}
              controlled
            />
          </Filter>
          <Count>{addonCount.count}</Count>
        </Content>
      ))}
    </FilterContainer>
  );
};

export default AccountAddonsFilter;
