import { FC, useContext, useMemo } from 'react';
import { format, formatDistanceToNowStrict } from 'date-fns';
import { Account } from 'contexts/accounts/types';
import Icon from 'components/icons';
import {
  AddonContainer,
  AddonText,
  Container,
  CustomCard,
  PastDueTag,
  RemainingTag,
} from './styles';
import Button from 'components/button';
import useAccountPlanData from 'hooks/useAccountPlanData';
import { SessionContext } from 'contexts/session';
import { INE_CONTACT_SUPPORT } from 'constants/settings';
import { findRoles } from 'contexts/session/utilities';
import { ROLES } from 'constants/roles';
import { formatDateInUtc } from 'utils/helpers';

const AccountStatusCard: FC<{ account: Account }> = ({ account }) => {
  const {
    free,
    unlimited,
    pricingPlan,
    professional,
    enterprise,
    enterprisePlus,
    labExperience,
    isExpired,
    isPastDue,
    status,
  } = useAccountPlanData({ account });
  const { userRoleIds, isStaff, account: accountLogged } = useContext(SessionContext);
  const expiredOrPastDue = isExpired || isPastDue;
  const showContactButton =
    !findRoles(
      [ROLES.STAFF, ROLES.ADMIN, ROLES.SALES, ROLES.SUPPORT, ROLES.TEAM_MANAGER],
      userRoleIds
    ) &&
    (!enterprisePlus || expiredOrPastDue);

  const handleContact = () => {
    window.location.href = `mailto:${INE_CONTACT_SUPPORT},${accountLogged?.sales_contact?.email}`;
  };

  const title = useMemo(() => {
    if (isExpired) {
      if (isStaff) return free ? 'The Free Trial expired' : 'The Account expired';
      else return free ? 'Your free trial expired' : 'Your account expired';
    }

    let planType, text;

    if (free) planType = 'Free Trial';
    else {
      switch (pricingPlan) {
        case 'Professional':
          planType = 'Professional Plan';
          break;
        case 'Enterprise':
          planType = 'Enterprise Plan';
          break;
        case 'Enterprise + Lab Experience':
          planType = 'Enterprise Plan + Lab Experience';
          break;
        case 'Lab Experience':
          planType = 'Lab Experience Plan';
          break;
      }
    }

    text = isStaff ? '' : `You have ${planType.startsWith('Enterprise') ? 'an ' : 'a '}`;

    text += planType;

    if (unlimited && !free) text += ' with unlimited licenses';

    return text;
  }, [isExpired, isStaff, free, pricingPlan, unlimited]);

  let warningText, buttonLabel;

  switch (status) {
    case 'unstarted':
    case 'active':
      warningText = (
        <p className="m-0">
          {isStaff ? 'The ' : 'Your '} {free ? 'free trial' : 'plan'} expires on{' '}
          {formatDateInUtc(new Date(account.end_date), 'P')}.
        </p>
      );
      buttonLabel = 'Contact INE to upgrade';

      break;
    case 'past_due':
      warningText = free ? (
        <p className="m-0">
          {isStaff ? 'The ' : 'Your '} Free Trial expired on{' '}
          {format(new Date(account.end_date), 'P')}.
          <br />
          {!isStaff && 'Unlock the perfect plan for you and your team'}
        </p>
      ) : (
        <p className="m-0">
          {isStaff ? 'The payment ' : 'Your payment '} was due on{' '}
          {format(new Date(account.end_date), 'P')}.<br />
          {isStaff ? 'The account ' : 'Your account '} will be disabled soon if{' '}
          {isStaff ? 'the' : 'your '} past-due balance isn&apos;t paid.
        </p>
      );
      buttonLabel = free ? 'Contact INE to upgrade' : 'Contact INE to make a payment';

      break;
    case 'expired': {
      const expireDateAgo = format(new Date(account.expire_date), 'P');
      let warnTxt;

      if (isStaff)
        warnTxt = free
          ? `The Free trial expired on ${expireDateAgo}`
          : `The Plan expired on ${expireDateAgo}`;
      else
        warnTxt = free
          ? `Your free trial expired on ${expireDateAgo}`
          : `Your plan expired on ${expireDateAgo}`;

      warningText = <p className="m-0">{warnTxt}</p>;
      buttonLabel = 'Contact INE to reactivate your account';

      break;
    }
  }

  const TimePill = () => {
    if (isPastDue && !free)
      return (
        <PastDueTag>PAST DUE {formatDistanceToNowStrict(new Date(account.end_date))}</PastDueTag>
      );
    else if (free && !isExpired && !isPastDue)
      return (
        <RemainingTag>
          {formatDistanceToNowStrict(new Date(account.end_date))} REMAINING
        </RemainingTag>
      );

    return null;
  };

  return (
    <CustomCard
      professional={professional}
      free={free}
      enterprise={enterprise}
      enterprisePlus={enterprisePlus}
      labExperience={labExperience}
      isExpired={isExpired}
    >
      <TimePill />

      <Container>
        <div>
          <h2>{title}</h2>
          <div className="d-flex">
            {expiredOrPastDue && (
              <Icon
                stroke="#F28815"
                icon="AlertCircle"
                size="medium"
                style={{ marginRight: '10px' }}
              />
            )}
            {warningText}
          </div>
        </div>
        {account.account_addons?.count > 0 && (
          <AddonContainer>
            {account.account_addons?.addons.map((addon, index) => {
              return (
                <AddonText key={addon.id}>
                  {index > 0 ? ', ' : 'Add on: '}
                  <span>{addon.name}</span>
                </AddonText>
              );
            })}
          </AddonContainer>
        )}
        {showContactButton && (
          <div>
            <Button
              onClick={handleContact}
              $secondary={free && !isExpired}
              $dark={free && !isExpired}
              style={{ marginTop: '10px' }}
              size="small"
            >
              {buttonLabel}
            </Button>
          </div>
        )}
      </Container>
      <div className="img-background" />
    </CustomCard>
  );
};

export default AccountStatusCard;
