import { useContext, useEffect, useReducer, useState } from 'react';
import { Col, Row } from 'antd';
import { Account, Staff } from 'contexts/accounts/types';
import { isMobile } from 'react-device-detect';
import { isPast } from 'date-fns';
import { useHistory } from 'react-router-dom';
import {
  AccountAddonPayload,
  CreateEditAccountPayload,
  changeAccountPlan,
  createAccount,
  editAccount as editAccountService,
  reactivateAccount,
} from 'services/accounts';
import {
  FooterLeftText,
  HeaderContainer,
  Label,
  ModalContainer,
  ModalContent,
  ModalSection,
} from 'components/modal/styles';

import AccountDates from './AccountDates';
import AccountLicenses from './AccountLicenses';
import AccountName from './AccountName';
import AccountSales from './AccountSales';
import AccountSupport from './AccountSupport';
import AccountsPlans from './AccountPlans';
import Button from 'components/button';
import Checkbox from 'components/checkbox';
import { ConfirmModal } from 'components/modal';
import Icon from 'components/icons';
import Modal from 'components/modal';
import Notification from 'components/notification';
import Tabs from 'components/tabs';
import { getStaff } from 'contexts/accounts/actions';
import { SessionContext } from 'contexts/session';
import { PricingPlan } from 'contexts/pricingPlans/types';
import AccountAddonsSelector from './AccountAddonsSelector';

interface CreateAccountProps {
  managePlan: boolean;
  editAccount: boolean;
  onClose: (result?: 'ok' | 'error') => void;
  account?: Account;
  isVisible: boolean;
  onAccountEdition?: (account: Account) => void;
}

interface AccountDataState {
  name?: string;
  pricingPlan?: string;
  isFreeTrial?: boolean;
  logo?: string;
  licenseCount?: number;
  support?: number | string;
  sales?: number | string;
  inviteOnly?: boolean;
  period?: {
    since?: string;
    to?: string;
  };
  addons?: any[];
}

type StateReducerActions =
  | 'SET_NAME'
  | 'SET_DATE_SINCE'
  | 'SET_DATE_TO'
  | 'SET_PRICING_PLAN'
  | 'SET_LICENCE_TYPE'
  | 'SET_LOGO'
  | 'SET_LICENSES'
  | 'SET_SUPPORT'
  | 'SET_INVITE_TYPE'
  | 'SET_FREE_TRIAL'
  | 'SET_SALES'
  | 'SET_ADDONS';

interface FormReducer {
  type: StateReducerActions;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}
const formDataReducer = (state: AccountDataState, action: FormReducer): AccountDataState => {
  switch (action.type) {
    case 'SET_NAME':
      return { ...state, name: action.value };
    case 'SET_DATE_SINCE':
      return {
        ...state,
        period: {
          ...state.period,
          since: action.value,
        },
      };
    case 'SET_DATE_TO':
      return {
        ...state,
        period: {
          ...state.period,
          to: action.value,
        },
      };
    case 'SET_PRICING_PLAN':
      return { ...state, pricingPlan: action.value };
    case 'SET_LOGO':
      return { ...state, logo: action.value };
    case 'SET_LICENSES':
      return { ...state, licenseCount: action.value };
    case 'SET_SUPPORT':
      return { ...state, support: action.value };
    case 'SET_SALES':
      return { ...state, sales: action.value };
    case 'SET_FREE_TRIAL':
      return { ...state, isFreeTrial: action.value };
    case 'SET_INVITE_TYPE':
      return { ...state, inviteOnly: action.value };
    case 'SET_ADDONS':
      return { ...state, addons: action.value };
    default:
      return state;
  }
};

const tabItems = [
  { name: 'Account details', value: 'details' },
  { name: 'Plan details', value: 'plans' },
];

const CreateAccount: React.FC<CreateAccountProps> = ({
  managePlan,
  editAccount,
  onClose,
  account,
  isVisible,
  onAccountEdition,
}) => {
  const history = useHistory();
  const title = editAccount
    ? 'Edit account'
    : managePlan
    ? `Manage ${account.name} plan`
    : 'Create account';
  const [staff, setStaff] = useState<Staff[]>([]);
  const [formData, setFormData] = useReducer(formDataReducer, {
    name: account?.name || '',
    period: {
      since: account?.start_date || null,
      to: account?.end_date || null,
    },
    isFreeTrial: account?.is_free_trial,
    inviteOnly: account?.is_invite_only,
    pricingPlan: account?.pricing_plan,
    licenseCount:
      account?.licenses_details?.count !== null ? account?.licenses_details?.count : null,
    sales: account?.representative.sales,
    support: account?.representative.support,
    addons: account?.account_addons?.addons || [],
  });
  const [errors, setErrors] = useState<string[]>([]);
  const [selectedTab, setSelectedTab] = useState(tabItems[0].value);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [fetching, setFetching] = useState(false);
  const { pricingPlans } = useContext(SessionContext);
  const [addons, setAddons] = useState(null);

  const { name, period, pricingPlan, support, sales } = formData;
  const isExpired = !!account?.expire_date;
  const isEditing = editAccount || managePlan;

  useEffect(() => {
    updateIneStaff();
    if (account?.pricing_plan) {
      const plan = pricingPlans?.find((plan) => plan.name === account?.pricing_plan);
      setAddons(plan?.addons);
    }
  }, []);

  const handleFormValidations = () => {
    if (!name) {
      setErrors((prevState) => [...prevState, 'SET_NAME']);
      return false;
    } else if (!period?.since) {
      setErrors((prevState) => [...prevState, 'SET_DATE_SINCE']);
      return false;
    } else if (!period?.to) {
      setErrors((prevState) => [...prevState, 'SET_DATE_TO']);
      return false;
    } else if (!pricingPlan) {
      setErrors((prevState) => [...prevState, 'SET_PRICING_PLAN']);
      return false;
    } else if (!support && !isEditing) {
      setErrors((prevState) => [...prevState, 'SET_SUPPORT']);
      return false;
    } else if (!sales && !isEditing) {
      setErrors((prevState) => [...prevState, 'SET_SALES']);
      return false;
    } else if (
      formData.addons &&
      formData.licenseCount &&
      formData.licenseCount >= 0 &&
      formData.addons.some((addon) => addon.count > formData.licenseCount)
    ) {
      setErrors((prevState) => [...prevState, 'SET_ADDONS']);
      Notification({
        text: 'Validation error',
        description: 'Addons count cannot be greater than licenses count',
        type: 'error',
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (handleFormValidations()) {
      const accountData = {
        name: formData.name || null,
        start_date: formData.period?.since ? formData.period?.since : null,
        end_date: formData.period?.to ? formData.period?.to : null,
        license_count: formData.licenseCount,
        is_free_trial: Boolean(formData.isFreeTrial),
        is_invite_only: Boolean(formData.inviteOnly),
        support: staff.find(
          ({ id, first_name, last_name }) =>
            formData.support === id || formData.support === `${first_name} ${last_name}`
        )?.id,
        sales: staff.find(
          ({ id, first_name, last_name }) =>
            formData.sales === id || formData.sales === `${first_name} ${last_name}`
        )?.id,
        addons: formData.addons,
      } as CreateEditAccountPayload;

      if (!account)
        accountData.pricing_plan = pricingPlans.find((plan) => plan.name === pricingPlan)?.id;

      try {
        let response, notificationMsg;

        if (isEditing) {
          if (isExpired) {
            response = await reactivateAccount(account.id, {
              start_date: accountData.start_date,
              end_date: accountData.end_date,
            });
            notificationMsg = `${response.data?.name} account reactivated`;
          } else {
            const editPayload = {
              name: accountData.name,
              start_date: accountData.start_date,
              end_date: accountData.end_date,
              is_free_trial: accountData.is_free_trial,
              is_invite_only: accountData.is_invite_only,
              support: accountData.support,
              sales: accountData.sales,
            };
            response = await editAccountService(editPayload, account.id);
            notificationMsg = `${response.data?.name} account edited`;
          }
        } else {
          response = await createAccount(accountData);

          notificationMsg = (
            <span>
              {`${response.data?.name} account created. `}
              <span
                className="notification-link"
                onClick={() => history.push(`/account/${response.data?.id}`)}
              >
                Go to account.
              </span>
            </span>
          );
        }

        response.status && Notification({ type: 'success', text: notificationMsg, duration: 8 });
        onClose('ok');
      } catch (error) {
        let notificationMsg;
        if (error?.response?.data?.details?.start_date)
          notificationMsg = error?.response?.data?.details?.start_date;
        else notificationMsg = isEditing ? 'Couldn´t edit account' : 'Couldn´t create account';
        Notification({ type: 'error', text: notificationMsg });
        onClose();
      }
    }
  };

  const handleChangePlan = async () => {
    if (
      formData.addons &&
      formData.licenseCount &&
      formData.licenseCount >= 0 &&
      formData.addons.some((addon) => addon.count > formData.licenseCount)
    ) {
      Notification({
        text: 'Validation error',
        description: 'Addons count cannot be greater than licenses count',
        type: 'error',
      });
      return;
    }
    const changePlanData = {
      pricing_plan: pricingPlans.find((plan) => plan.name === pricingPlan)?.id,
      license_count: formData.licenseCount,
      addons: formData.addons,
    };
    setFetching(true);
    try {
      const response = await changeAccountPlan(changePlanData, account.id);

      if (onAccountEdition) onAccountEdition(response.data);

      const notificationMsg = `${response.data?.name} account plan changed`;
      Notification({ type: 'success', text: notificationMsg, duration: 8 });
      onClose('ok');
    } catch (error) {
      const errorMsg = error?.response?.data?.detail;
      if (errorMsg) {
        Notification({
          text: 'Couldn´t change account plan',
          description: errorMsg,
          type: 'error',
        });
      }
      onClose();
    }
    setFetching(false);
    setConfirmModalOpen(false);
  };

  const handleFormChanges = (data: FormReducer) => {
    data.value && setErrors((prev) => prev.filter((error) => error !== data.type));
    setFormData({
      type: data.type,
      value: data.value,
    });
  };

  const updateIneStaff = async () => {
    const newStaff = await getStaff();
    if (!(newStaff instanceof Error)) {
      setStaff(newStaff);
    }
  };

  const handlePricingPlanChange = (plan: PricingPlan) => {
    setAddons(plan.addons);
    setFormData({ type: 'SET_PRICING_PLAN', value: plan.name });
  };

  const handleAddonsChangeValue = (value: AccountAddonPayload[]) => {
    setFormData({ type: 'SET_ADDONS', value: value });
  };

  return (
    <Modal
      centered
      visible={isVisible}
      fullscreen={isMobile}
      onCancel={() => onClose()}
      width={isMobile ? '100%' : '595px'}
      footer={null}
      closeIconColor="#fff"
      maskClosable={false}
    >
      <ModalContainer>
        {isMobile && (
          <span
            onClick={() => onClose()}
            style={{ position: 'absolute', top: 10, left: 10, width: 18 }}
          >
            <Icon icon={'ArrowLeft'} color={'#FFF'} />
          </span>
        )}
        <HeaderContainer style={{ height: '139px' }}>
          <h2>{title}</h2>
        </HeaderContainer>
        <ModalContent grow flexColumn>
          {isEditing && !isExpired && (
            <Tabs
              items={tabItems}
              selected={selectedTab}
              $flex="grow"
              onTabChange={(tab) => setSelectedTab(tab)}
            />
          )}

          <ModalSection>
            <Row gutter={[0, 30]}>
              {selectedTab === 'details' && (
                <>
                  {/* Account Name */}
                  {!managePlan && (
                    <Col span={24}>
                      <AccountName
                        onChange={(value) => handleFormChanges({ type: 'SET_NAME', value: value })}
                        value={formData.name}
                        error={errors.includes('SET_NAME')}
                      />
                    </Col>
                  )}

                  {/* Start/End plan */}
                  {!editAccount && (
                    <Col span={24}>
                      <AccountDates
                        dateSince={formData.period?.since}
                        dateTo={formData.period?.to}
                        onStartChange={(value) =>
                          handleFormChanges({ type: 'SET_DATE_SINCE', value })
                        }
                        onEndChange={(value) => handleFormChanges({ type: 'SET_DATE_TO', value })}
                        error={{
                          since: errors.includes('SET_DATE_SINCE'),
                          to: errors.includes('SET_DATE_TO'),
                        }}
                      />
                    </Col>
                  )}

                  {/* Plan */}
                  {!isEditing && (
                    <Col span={24}>
                      <AccountsPlans
                        pricingPlans={pricingPlans}
                        error={errors.includes('SET_PRICING_PLAN')}
                        onPricingPlanChange={(plan) => handlePricingPlanChange(plan)}
                        pricingPlan={formData.pricingPlan || null}
                        isEditing={isEditing}
                      />
                    </Col>
                  )}

                  {!editAccount && !isExpired && (
                    <Col span={24}>
                      <Row gutter={30}>
                        <Col>
                          <Row align="middle">
                            <Checkbox
                              onChange={(e) =>
                                handleFormChanges({
                                  type: 'SET_FREE_TRIAL',
                                  value: e.target.checked,
                                })
                              }
                              defaultChecked={formData.isFreeTrial}
                            />
                            <Label style={{ margin: '0 0 0 10px' }}>Free Trial</Label>
                          </Row>
                        </Col>
                        <Col>
                          <Row align="middle">
                            <Checkbox
                              onChange={(e) =>
                                handleFormChanges({
                                  type: 'SET_INVITE_TYPE',
                                  value: e.target.checked,
                                })
                              }
                              defaultChecked={formData.inviteOnly}
                            />
                            <Label style={{ margin: '0 0 0 10px' }}>Invite only</Label>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  )}

                  {/* Licenses */}
                  {!editAccount && !managePlan && !isExpired && (
                    <AccountLicenses
                      error={errors.includes('SET_LICENSES')}
                      value={formData.licenseCount}
                      minValue={account?.licenses_details.used}
                      unlimited={formData.licenseCount === null}
                      onChange={(value) =>
                        handleFormChanges({ type: 'SET_LICENSES', value: value })
                      }
                    />
                  )}

                  {/* Addons */}
                  {!editAccount && !managePlan && !isExpired && addons && (
                    <Col span={24}>
                      <AccountAddonsSelector
                        accountAddons={account?.account_addons.addons}
                        addons={addons}
                        licensesSelected={formData.licenseCount}
                        unlimited={formData.licenseCount === null}
                        onChange={(value) => handleAddonsChangeValue(value)}
                      />
                    </Col>
                  )}

                  {/* Support */}
                  {!editAccount && !isExpired && (
                    <Col span={24}>
                      <AccountSupport
                        error={errors.includes('SET_SUPPORT')}
                        onChange={(value) => handleFormChanges({ type: 'SET_SUPPORT', value })}
                        value={formData.support}
                        staff={staff}
                      />
                    </Col>
                  )}

                  {/* Sales */}
                  {!editAccount && !isExpired && (
                    <Col span={24}>
                      <AccountSales
                        error={errors.includes('SET_SALES')}
                        onChange={(value) => handleFormChanges({ type: 'SET_SALES', value })}
                        value={formData.sales}
                        staff={staff}
                      />
                    </Col>
                  )}
                </>
              )}

              {/* Plan */}
              {selectedTab === 'plans' && (
                <>
                  <Col span={24}>
                    <AccountsPlans
                      pricingPlans={pricingPlans}
                      error={errors.includes('SET_PRICING_PLAN')}
                      onPricingPlanChange={(plan) => handlePricingPlanChange(plan)}
                      pricingPlan={formData.pricingPlan || null}
                      isEditing={isEditing}
                    />
                  </Col>
                  <Col span={24}>
                    <AccountLicenses
                      error={errors.includes('SET_LICENSES')}
                      value={formData.licenseCount}
                      minValue={account?.licenses_details.used}
                      unlimited={formData.licenseCount === null}
                      onChange={(value) =>
                        handleFormChanges({ type: 'SET_LICENSES', value: value })
                      }
                    />
                  </Col>
                  {addons && (
                    <Col span={24}>
                      <AccountAddonsSelector
                        accountAddons={account?.account_addons.addons}
                        addons={addons}
                        licensesSelected={formData.licenseCount}
                        unlimited={formData.licenseCount === null}
                        onChange={(value) => handleAddonsChangeValue(value)}
                      />
                    </Col>
                  )}
                </>
              )}

              {/* Actions */}
              <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                {account && selectedTab === 'plans' && (
                  <FooterLeftText>
                    <Icon size="small" fill={'#A793F6'} icon="Info" />
                    <span>If you change the plan, some licenses might be affected.</span>
                  </FooterLeftText>
                )}
                <Button
                  $primary
                  disabled={isExpired && isPast(new Date(formData.period?.to))}
                  style={{ margin: isMobile ? 'auto 10px 0 auto' : '0 0 0 auto' }}
                  icon={
                    !account ? 'Plus' : selectedTab === 'plans' || isExpired ? 'Refresh' : 'Save'
                  }
                  onClick={
                    !account || selectedTab === 'details'
                      ? handleSubmit
                      : () => setConfirmModalOpen(true)
                  }
                >
                  {!account
                    ? 'Create account'
                    : selectedTab === 'plans'
                    ? 'Change plan'
                    : isExpired
                    ? 'Reactivate account'
                    : 'Save changes'}
                </Button>
              </Col>
            </Row>
          </ModalSection>
        </ModalContent>
        <ConfirmModal
          open={confirmModalOpen}
          title="Are you sure you want to change the plan?"
          description="This might take a while. Changing plans may affect some licenses."
          acceptLabel="Confirm"
          cancelLabel="Cancel"
          loading={fetching}
          onAccept={handleChangePlan}
          onCancel={() => setConfirmModalOpen(false)}
        />
      </ModalContainer>
    </Modal>
  );
};

export default CreateAccount;
