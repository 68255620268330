import InputSelect from 'components/inputs/inputSelect';
import { Label } from 'components/modal/styles';
import { Staff } from 'contexts/accounts/types';
import { useMemo } from 'react';

interface AccountSupport {
  onChange(value: string): void;
  value: number | string;
  error?: boolean;
  staff: Staff[];
}

const AccountSupport: React.FC<AccountSupport> = ({ onChange, value, error, staff }) => {
  const options = useMemo(
    () =>
      staff
        .filter(({ staff_role }) => staff_role === 'Support')
        .map(({ first_name, last_name, id }) => {
          return {
            label: `${first_name} ${last_name}`,
            value: id,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label)),
    [staff]
  );

  const selected = staff.find(
    ({ id, first_name, last_name }) => id === value || value === `${first_name} ${last_name}`
  );

  return (
    <>
      <Label>Support</Label>
      <InputSelect
        error={error}
        value={selected ? `${selected.first_name} ${selected.last_name}` : null}
        placeHolder={'Select a representative'}
        options={options}
        maxDropdownHeight={'500px'}
        onChange={onChange}
      />
    </>
  );
};

export default AccountSupport;
