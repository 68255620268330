import { useMemo, useState } from 'react';
import Button from 'components/button';
import Modal from 'components/modal';
import {
  HeaderContainer,
  ModalActions,
  ModalContainer,
  ModalContent,
} from 'components/modal/styles';

import AssignButton from './AssignButton';
import ProductCardAssignment from './ProductCardAssignment';
import { ICategory } from 'contexts/rax/types';

export interface IProduct {
  name: string;
  description?: string;
  category?: ICategory;
  assignments?: { account_id: string; account_name: string; quantity: number }[];
  checkfront_sku: string;
  id: string;
  max_quantity: number;
  booking_consecutive_days: number;
}
interface AvailabilityProps {
  product: IProduct;
  accounts: Array<any>;
  onAssign: (value) => void;
}

interface IAssignProduct {
  account: string;
  quantity: number;
}

const AssignModalAction = ({ product, accounts, onAssign }: AvailabilityProps): JSX.Element => {
  const defaultData = { account: null, quantity: 1 };
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState(defaultData);

  const onAssignProduct = (data: IAssignProduct) => {
    setData(data);
  };

  const canSave = useMemo(() => {
    return data.account !== null;
  }, [data]);

  const onAssignHandler = () => {
    onAssign(data);
    setOpenModal(false);
  };

  const modalHandler = (value: boolean) => {
    setData(defaultData);
    setOpenModal(value);
  };

  return (
    <>
      <AssignButton onClick={() => modalHandler(true)} />
      {openModal && (
        <Modal
          visible={openModal}
          bodyStyle={{ borderRadius: '20px' }}
          footer={null}
          width={'719px'}
          closeIconColor="#fff"
          maskClosable={false}
          onCancel={() => modalHandler(false)}
        >
          <ModalContainer style={{ borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }}>
            <HeaderContainer isRax={true} style={{ height: '155px', padding: 0 }}>
              <h2>Assign product</h2>
            </HeaderContainer>
          </ModalContainer>
          <ModalContent style={{ paddingBottom: 10 }} sidePadding>
            <ProductCardAssignment {...product} accounts={accounts} onAssign={onAssignProduct} />
          </ModalContent>
          <ModalActions>
            <Button $secondary style={{ marginLeft: 'auto' }} onClick={() => setOpenModal(false)}>
              Cancel
            </Button>
            <Button $primary onClick={onAssignHandler} disabled={!canSave}>
              Confirm and assign
            </Button>
          </ModalActions>
        </Modal>
      )}
    </>
  );
};

export default AssignModalAction;
