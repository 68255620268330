import styled from 'styled-components';
import { colors } from 'constants/styles/colors';
import Icon from 'components/icons';

export const Filters = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const FilterTitle = styled.div`
  color: ${colors.fonts.dark};
  font-family: Circular-Std;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`;

export const TypeFiltersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 30px;
`;

export const SingleFilterContainer = styled.div`
  margin-left: 20px;
  display: flex;
  align-items: center;
`;

export const InfoIcon = styled(Icon)<{ fill?: string }>`
  fill: ${colors.secondaryPallete.purple.light};
  &:hover {
    fill: ${colors.secondaryPallete.purple.base};
  }
`;
