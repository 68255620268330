import { useContext, useEffect, useState } from 'react';
import Button from 'components/button';
import ButtonIcon from 'components/buttonIcon';
import LittleTeamsCard from 'components/cards/LittleTeamsCard';
import Modal from 'components/modal';
import {
  HeaderContainer,
  ModalActions,
  ModalContainer,
  ModalContent,
} from 'components/modal/styles';
import TeamsSelector from 'components/teamSelect';
import Tooltip from 'components/tooltip';
import { SessionContext } from 'contexts/session';
import { UserTeam } from 'contexts/teams/types';
import { UserTeamsPayload } from 'components/usersTable/types';
import { UserDetail } from 'types/users';

interface EditTeamsModalProps {
  userTeams: UserTeam[];
  onTeamsEdited: (userTeams: UserTeam[]) => void;
  user: UserDetail;
}

const EditTeamsModal = ({ userTeams, onTeamsEdited, user }: EditTeamsModalProps): JSX.Element => {
  const { teams } = useContext(SessionContext);
  const [openModal, setOpenModal] = useState(false);
  const [teamsDraft, setTeamsDraft] = useState([]);

  const handleSwitchManager = (teamsData: UserTeamsPayload[]) => {
    const userTeamIndex = teamsDraft.findIndex(({ team_id }) => team_id === teamsData[0].team_id);
    const updatedUserTeams = [...teamsDraft];
    updatedUserTeams[userTeamIndex].is_manager = teamsData[0].is_manager;
    setTeamsDraft(updatedUserTeams);
  };

  const handleTeamChange = (teamIds: string[]) => {
    const newTeamsDraft = [];
    teamIds.forEach((ti) => {
      const teamDraft = teamsDraft.find((t) => t.team_id === ti)
        ? teamsDraft.find((t) => t.team_id === ti)
        : teams.find((t) => t.id === ti);
      newTeamsDraft.push({
        name: teamDraft.name,
        team_id: ti,
        is_manager: teamDraft?.is_manager || false,
      });
    });
    setTeamsDraft(newTeamsDraft);
  };

  const handleUpdateTeams = () => {
    onTeamsEdited(teamsDraft);
    setOpenModal(false);
  };

  useEffect(() => {
    openModal && setTeamsDraft(userTeams);
  }, [openModal]);
  return (
    <>
      <div>
        <Tooltip placement="bottomRight" title={'Edit teams'}>
          <ButtonIcon
            $secondary
            size={'small'}
            icon={'Edit'}
            onClick={() => setOpenModal(!openModal)}
          />
        </Tooltip>
      </div>
      <Modal
        visible={openModal}
        bodyStyle={{ borderRadius: '20px' }}
        footer={null}
        width={'390px'}
        closeIconColor="#fff"
        maskClosable={false}
        onCancel={() => setOpenModal(false)}
      >
        <ModalContainer style={{ borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }}>
          <HeaderContainer style={{ height: '139px' }}>
            <h2>Edit teams</h2>
          </HeaderContainer>
        </ModalContainer>
        <ModalContent>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              border: '1px solid #F2F2F7',
              borderRadius: '10px',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '15px',
                marginLeft: '10px',
              }}
            >
              <TeamsSelector
                accountTeams={teams}
                userTeams={teamsDraft}
                onTeamChange={handleTeamChange}
              />
            </div>
            <LittleTeamsCard
              teams={teamsDraft}
              onSwitchManager={handleSwitchManager}
              flat={true}
              user={user}
            />
          </div>
        </ModalContent>
        <ModalActions>
          <Button $primary style={{ marginLeft: 'auto' }} onClick={handleUpdateTeams}>
            Update
          </Button>
        </ModalActions>
      </Modal>
    </>
  );
};

export default EditTeamsModal;
