import React from 'react';
import { createPortal } from 'react-dom';
import HeaderLoading from './HeaderLoading';

interface HeaderLoadingPortalProps {
  active: boolean;
  portals: boolean;
}

const HeaderLoadingPortal = (props: HeaderLoadingPortalProps): JSX.Element => {
  const { active, portals } = props;

  const Loading = () => {
    if (portals) {
      return createPortal(
        <HeaderLoading active={active} />,
        document.getElementById('react-header-loading')
      );
    } else {
      return <HeaderLoading active={active} />;
    }
  };

  return <>{active && <Loading />}</>;
};

export default HeaderLoadingPortal;
