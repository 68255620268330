import { useContext, useEffect, useState } from 'react';
import {
  ActivityLogDataCtx,
  FilterItem,
  FilterProps,
  FilterRange,
} from 'contexts/activityLog/types';
import { activitiesLogInitialValues } from 'contexts/activityLog/utils';
import { getActivitiesLogAction, getActivityLogUsersAction } from 'contexts/activityLog/actions';
import { SessionContext } from 'contexts/session';

interface ActivityLogHook {
  fetching: boolean;
  filters: FilterProps;
  isEmpty: boolean;
  activitiesLogTable: ActivityLogDataCtx['table'];
  activityLogUsers: ActivityLogDataCtx['activityLogUsers'];
  error: string | boolean;
  onChangeFilter: (filter: FilterItem) => void;
  onChangeFilters: (filters: FilterItem[]) => void;
  onChangeRangeFilters: (range: FilterRange) => void;
  onRefreshActivities: () => void;
  onRetrieveActivityLogUsers: (account_id?: string) => void;
  onResetActivities: (account_id?: string) => void;
}

const useActivityLogData = (): ActivityLogHook => {
  const [activitiesLogTable, setActivitiesLogTable] = useState(activitiesLogInitialValues.table);
  const [fetching, setFetching] = useState(false);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const [filters, setFilters] = useState<FilterProps>(activitiesLogInitialValues.filters);
  const [activityLogUsers, setActivityLogUsers] = useState(
    activitiesLogInitialValues.activityLogUsers
  );
  const { isStaff } = useContext(SessionContext);

  const getPaginatedActivities = async ({ params }) => {
    if (isStaff && !params.account_id) return;
    setFetching(true);
    try {
      const activitiesLogData = await getActivitiesLogAction(params);
      if (activitiesLogData instanceof Error) {
        setActivitiesLogTable((prevState) => ({
          ...prevState,
          error: activitiesLogData?.message,
        }));
      } else {
        setActivitiesLogTable((prevState) => {
          return {
            ...prevState,
            count: activitiesLogData?.results?.count,
            items: filters.append_results
              ? [...prevState.items, ...activitiesLogData?.results?.results]
              : activitiesLogData?.results?.results,
          };
        });
        setIsEmpty(activitiesLogData.results?.results.length === 0);
      }
      setFetching(false);
    } catch (error) {
      setFetching(false);
    }
  };

  const refreshActivities = async ({ params }) => {
    const activitiesLogData = await getActivitiesLogAction(params);
    if (activitiesLogData instanceof Error) {
      setActivitiesLogTable((prevState) => ({
        ...prevState,
        error: activitiesLogData?.message,
      }));
    } else {
      if (activitiesLogTable.count !== activitiesLogData?.results?.count) {
        const diff = activitiesLogData.results.count - activitiesLogTable.count;
        const newElems = activitiesLogData.results.results.slice(0, diff);
        setActivitiesLogTable((prevState) => {
          return {
            ...prevState,
            count: activitiesLogData?.results?.count,
            items: [...newElems, ...prevState.items],
          };
        });
      }
    }
  };

  const refreshActivityLogs = () => {
    setFilters((prevState) => ({
      ...prevState,
      ['autoRefresh']: true,
      page: '1',
    }));
  };

  const retrieveActivityLogUsers = async (account_id: string) => {
    const activityLogUsersResponse = account_id
      ? await getActivityLogUsersAction({
          account_id: account_id,
        })
      : await getActivityLogUsersAction();
    if (activityLogUsersResponse instanceof Error) {
      setActivityLogUsers([]);
    } else {
      const systemUser = {
        label: 'System',
        value: 'system',
        key: 'system',
      };
      const users = activityLogUsersResponse?.results?.map(({ id, name }) => {
        return {
          label: name,
          value: id.toString(),
          key: id.toString(),
        };
      });
      setActivityLogUsers([systemUser, ...users]);
    }
  };

  const handleReqData = () => {
    return {
      page_size: parseInt(filters['page_size']),
      page: parseInt(filters['page']),
      from_date: filters['from_date'] ? `${filters['from_date']}T00:00:00` : null,
      to_date: filters['to_date'] ? `${filters['to_date']}T23:59:59` : null,
      generated_by: filters['generated_by'] || null,
      types: filters['types'] || null,
      account_id: filters['account_id'] || null,
    };
  };

  const onChangeFilter = (filter: FilterItem) => {
    const name = filter.name.toString();
    const value = filter.value.toString();
    const resetPage = name !== 'page';
    setFilters((prevState) => {
      return resetPage
        ? {
            ...prevState,
            page: '1',
            [name]: value,
            append_results: false,
            autoRefresh: false,
          }
        : {
            ...prevState,
            [name]: value,
            append_results: true,
            autoRefresh: false,
          };
    });
  };

  const onChangeFilters = (filters: FilterItem[]) => {
    const newFilters = filters.reduce(
      (acc, filter) => ({ ...acc, [filter.name]: filter.value }),
      {}
    );
    setFilters((prevState) => ({
      ...prevState,
      ...newFilters,
    }));
  };

  const onChangeRangeFilters = (dates: FilterRange) => {
    const fromName = dates.fromName;
    const fromValue = dates.fromValue;
    const toName = dates.toName;
    const toValue = dates.toValue;

    setFilters((prevState) => ({
      ...prevState,
      page: '1',
      [fromName]: fromValue,
      [toName]: toValue,
      append_results: false,
      autoRefresh: false,
    }));
  };

  const onRetrieveActivityLogUsers = (account_id?: string) => {
    retrieveActivityLogUsers(account_id);
  };

  const onResetActivities = (account_id?: string) => {
    const filters = [
      { name: 'from_date', value: null },
      { name: 'to_date', value: '' },
      { name: 'generated_by', value: null },
      { name: 'types', value: null },
      { name: 'page', value: 1 },
      { name: 'append_results', value: false },
    ];
    account_id && filters.push({ name: 'account_id', value: account_id });
    onChangeFilters(filters);
  };

  useEffect(() => {
    filters['autoRefresh']
      ? refreshActivities({ params: handleReqData() })
      : getPaginatedActivities({ params: handleReqData() });
  }, [filters]);

  return {
    fetching: fetching,
    activitiesLogTable: activitiesLogTable,
    error: false,
    isEmpty,
    filters,
    activityLogUsers,
    onChangeFilter,
    onChangeFilters,
    onChangeRangeFilters,
    onRefreshActivities: refreshActivityLogs,
    onRetrieveActivityLogUsers,
    onResetActivities,
  };
};

export default useActivityLogData;
