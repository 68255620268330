import { Avatar, AvatarImage, AvatarLetter, Avatars, Container } from './styles';
import Tooltip from 'components/tooltip';
import { randomColor } from 'utils/colors';

export interface AvatarsCountProps {
  items: Array<AvatarsCountItemProps>;
  more?: number;
}

export interface AvatarsCountItemProps {
  image?: string;
  title?: string;
  link?: string;
  color?: {
    lighter: string;
    base: string;
  };
}

export const AvatarsCount = (props: AvatarsCountProps): JSX.Element => {
  const { items, more = 0 } = props;

  return (
    <Container>
      <Avatars length={items.length}>
        {items.map((item, i) => (
          <Avatar key={i} index={i}>
            <Tooltip title={item.title}>
              {item.image ? (
                <AvatarImage title={item.title} src={item.image} alt={item.title} />
              ) : (
                <AvatarLetter
                  bg={randomColor(item.title).lighter}
                  color={randomColor(item.title).base}
                >
                  {item.title.trim() ? item.title[0].toUpperCase() : 'P'}
                </AvatarLetter>
              )}
            </Tooltip>
          </Avatar>
        ))}
        {more >= 1 && (
          <Avatar count index={items.length}>
            <p>{'+' + more}</p>
          </Avatar>
        )}
      </Avatars>
    </Container>
  );
};

export default AvatarsCount;
