import { Notice, Close } from './styles';
import Icon from 'components/icons';

interface NoticeTopBarProps {
  onClose?: () => void;
  children: React.ReactChild;
}

const NoticeTopBar = (props: NoticeTopBarProps): JSX.Element => {
  const { onClose, children } = props;

  return (
    <>
      <Notice>
        {children}
        {onClose && (
          <Close onClick={onClose}>
            <Icon icon={'Close'} fill={'#FFFFFF'} />
          </Close>
        )}
      </Notice>
    </>
  );
};

export default NoticeTopBar;
