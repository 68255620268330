import { handleErrors } from '../helpers';
import {
  getAccountRaxLabs,
  getRaxBookings,
  getRaxCalendarAvailability,
  getRaxProducts,
  postAccountRaxLab,
  postRaxBooking,
  removeRaxBooking,
  removeRaxSlot,
} from '../../services/rax';
import {
  IAccountLabParams,
  IBookingsParams,
  IGetCalendarAvailability,
  IGetProductPayload,
  IGetRaxBookings,
} from './types';

export const removeRaxSlotAction = async (account_rax_lab_id: string): Promise<any> => {
  try {
    const response = await removeRaxSlot(account_rax_lab_id);
    return response;
  } catch (error) {
    handleErrors(error);
  }
};

export const getRaxLabsAction = async (params: IGetProductPayload): Promise<any> => {
  try {
    const response = await getRaxProducts(params);
    return response;
  } catch (error) {
    handleErrors(error);
  }
};

export const getAccountRaxLabsAction = async (params: IGetProductPayload): Promise<any> => {
  try {
    const response = await getAccountRaxLabs(params);
    return response;
  } catch (error) {
    handleErrors(error);
  }
};

export const postAccountRaxLabAction = async (params: IAccountLabParams): Promise<any> => {
  try {
    const response = await postAccountRaxLab(params);
    return response;
  } catch (error) {
    handleErrors(error);
  }
};

export const getRaxBookingsAction = async (params: IGetRaxBookings = {}): Promise<any> => {
  try {
    const response = await getRaxBookings(params);
    return response;
  } catch (error) {
    handleErrors(error);
  }
};

export const postRaxBookingAction = async (params: IBookingsParams): Promise<any> => {
  try {
    const response = await postRaxBooking(params);
    return response;
  } catch (error) {
    handleErrors(error);
  }
};

export const removeRaxBookingAction = async (account_rax_lab_id: string): Promise<any> => {
  try {
    const response = await removeRaxBooking(account_rax_lab_id);
    return response;
  } catch (error) {
    handleErrors(error);
  }
};

export const getRaxCalendarAvailabilityAction = async (
  params?: IGetCalendarAvailability
): Promise<any> => {
  try {
    const response = await getRaxCalendarAvailability(params);
    return response;
  } catch (error) {
    handleErrors(error);
  }
};
