import { Space } from 'antd';
import ButtonIcon from 'components/buttonIcon';
import { isDesktop, isMobile } from 'react-device-detect';
import { ActionsContainer } from 'components/layout/profile/profileHeader/styles';
import Tooltip from 'components/tooltip';
import { AccountHeaderProfileProps } from 'contexts/accounts/types';
import Button from 'components/button';
import CreateAccount from '../CreateAccount';
import ManageDomains from '../ManageDomains';
import Popover from 'components/popover';
import MobileAccountActions from './MobileAccountActions';
import { useContext, useEffect, useState } from 'react';
import { SessionContext } from 'contexts/session';
import { validateRole } from 'contexts/session/utilities';
import useAccountPlanData from 'hooks/useAccountPlanData';
import useAccountsTableContent from 'hooks/useAccountsTableContent';
import { ConfirmModal } from 'components/modal';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const HeaderActions = ({
  account,
  handleModal,
  refreshData,
}: AccountHeaderProfileProps): JSX.Element => {
  const [showOptions, setShowOptions] = useState(false);
  const [isImpersonating, setIsImpersonating] = useState(false);
  const { user, actions: sessionActions, isImpersonated } = useContext(SessionContext);
  const { isExpired } = useAccountPlanData({ account });
  const { expireTableItem } = useAccountsTableContent();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const hasINERole = validateRole({
    email: user?.email,
    roles: user?.uaa_data?.roles?.data?.map(({ id }) => id),
  });
  const impersonateDisabled = account.contacts.length === 0 || isExpired || isImpersonated;

  useEffect(() => {
    setIsImpersonating(false);
  }, [user]);

  const handleAccountEdit = (result: string) => {
    if (result === 'ok') {
      refreshData();
    }
    handleModal(null);
  };

  const handleManageAccount = ({ type }) => {
    handleModal(
      <CreateAccount
        isVisible={true}
        managePlan={type === 'managePlan'}
        editAccount={type === 'edit'}
        onClose={handleAccountEdit}
        account={account}
      />
    );
  };

  const handleCreateDomain = (created: boolean) => {
    handleModal(null);
    created && refreshData();
  };

  const handleEditAccount = () => {
    handleManageAccount({ type: 'edit' });
  };

  const handleManagePlan = () => {
    handleManageAccount({ type: 'managePlan' });
  };

  const handleCreateDomains = () => {
    handleModal(<ManageDomains account={account} onClose={handleCreateDomain} />);
  };

  const handleShowOptions = (bool) => {
    setShowOptions(bool);
  };

  const handleImpersonate = () => {
    setIsImpersonating(true);
    sessionActions.impersonate(account?.contacts?.[0]);
  };

  const handleExpireAccount = async () => {
    await expireTableItem(account.id);
    setConfirmModalOpen(false);
    refreshData();
  };

  return (
    <ActionsContainer>
      {isDesktop && (
        <>
          <Space size={10} align={'end'}>
            {hasINERole && (
              <Button
                $primary
                size={'small'}
                onClick={handleImpersonate}
                loading={isImpersonating}
                disabled={impersonateDisabled}
              >
                Impersonate as account owner
              </Button>
            )}
            {hasINERole && (
              <>
                <Button $secondary size={'small'} onClick={handleEditAccount}>
                  Edit account
                </Button>
                <Button $secondary size={'small'} onClick={handleManagePlan}>
                  Manage plan
                </Button>
                <Button $secondary size={'small'} onClick={handleCreateDomains}>
                  Create domain
                </Button>
                <Tooltip placement="bottomRight" title={'Expire account'}>
                  <ButtonIcon
                    $secondary
                    size={'small'}
                    icon={'Trash'}
                    onClick={() => setConfirmModalOpen(true)}
                  />
                </Tooltip>
              </>
            )}
          </Space>
        </>
      )}
      {isMobile && hasINERole && (
        <Space size={10} align={'end'}>
          <Popover
            placement={'bottom'}
            color={'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #222222'}
            content={
              <MobileAccountActions
                onClose={() => handleShowOptions(false)}
                onCreateDomain={handleCreateDomains}
                onEdit={handleEditAccount}
                onManagePlan={handleManagePlan}
              />
            }
            trigger="click"
            visible={showOptions}
          >
            <Button
              $secondary={!showOptions}
              $primary={showOptions}
              size={'small'}
              onClick={() => handleShowOptions(true)}
            >
              Edit account
            </Button>
          </Popover>
          <ButtonIcon
            $secondary
            size={'small'}
            icon={'Trash'}
            onClick={() => setConfirmModalOpen(true)}
          />
        </Space>
      )}
      <ConfirmModal
        open={confirmModalOpen}
        title={'Expire Account'}
        description="Are you sure you want to expire the account?"
        acceptLabel={'Expire Account'}
        cancelLabel={'Cancel'}
        onAccept={handleExpireAccount}
        onCancel={() => setConfirmModalOpen(false)}
      />
    </ActionsContainer>
  );
};

export default HeaderActions;
