import Icon from 'components/icons';
import { FlatButton, FlatInputContainer, StyledNumericInput } from './styles';
import { useMemo, useState } from 'react';
interface NumericInputProps {
  onChange: (value: number) => void;
  value: number;
  unlimited?: boolean;
  minValue?: number;
  maxValue?: number;
  placeholder: string;
  error: boolean;
}

const InputNumberFlat: React.FC<NumericInputProps> = ({
  onChange,
  value,
  unlimited,
  minValue,
  maxValue,
  placeholder,
  error,
}) => {
  const [currentNumber, setCurrentNumber] = useState(1);
  const minusDisabled = unlimited || minValue >= value || !value;
  const plusDisabled = unlimited || maxValue <= value;

  const handleSum = () => {
    const currentValue = value ? value + 1 : 1;
    onChange(currentValue);
    setCurrentNumber(currentValue);
  };

  const handleRest = () => {
    const currentValue = value ? value - 1 : 1;
    onChange(currentValue);
    setCurrentNumber(currentValue);
  };

  const handleOnChange = (e) => {
    const newValue = e.target.value;
    if (newValue.match(/[^0-9]/g)) return;
    const currentValue = parseInt(newValue) || 0;
    onChange(currentValue);
    setCurrentNumber(currentValue);
  };

  const twoDigits = useMemo(() => {
    const number = String(currentNumber);
    return number.length === 2;
  }, [currentNumber]);

  const handleOnBlur = () => {
    if (minValue && minValue >= value) onChange(minValue);
    if (maxValue && maxValue <= value) onChange(maxValue);
  };

  return (
    <FlatInputContainer short={minusDisabled} twoDigits={twoDigits}>
      {!minusDisabled && (
        <FlatButton
          className="numeric-input__minus-button"
          disabled={minusDisabled}
          onClick={() => handleRest()}
        >
          <Icon
            color={'#A793F6'}
            size={'small'}
            icon={'MinusNumeric'}
            style={{ width: '24px', marginTop: '1px' }}
          />
        </FlatButton>
      )}

      <StyledNumericInput
        unlimited={unlimited}
        value={unlimited ? '∞' : value}
        disabled={unlimited}
        placeholder={placeholder}
        error={error}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
      />
      <FlatButton
        className="numeric-input__plus-button"
        disabled={plusDisabled}
        onClick={() => handleSum()}
        style={{ cursor: plusDisabled && 'not-allowed' }}
      >
        <Icon
          color={plusDisabled ? '#AEAEB2' : '#A793F6'}
          size={'small'}
          icon={'PlusNumeric'}
          style={{ width: '24px' }}
        />
      </FlatButton>
    </FlatInputContainer>
  );
};

export default InputNumberFlat;
