import {
  SkillGroupContainer,
  SkillGroupProgressContainer,
  SkillGroupTitle,
  SonarEmptyScore,
  UserSkillAreasContainer,
} from './styles';
import SkillProgressBar from './SkillProgressBar';
import RedTeamIcon from 'assets/icons/assessments/red_team.svg';
import BlueTeamIcon from 'assets/icons/assessments/blue_team.svg';
import YellowTeamIcon from 'assets/icons/assessments/yellow_team.svg';
import { TabIcon } from 'components/stats/activitiesChartTabs/styles';
import { SKILL_GROUPS } from 'pages/Teams/components/TeamSkills/types';

interface UserSkillAreasProps {
  skillAreas: any;
}

const UserSkillAreas = ({ skillAreas }: UserSkillAreasProps): JSX.Element => {
  const getSkillAreaIcon = (skillArea: string) => {
    if (skillArea === SKILL_GROUPS.RED_TEAM) {
      return <TabIcon src={RedTeamIcon} />;
    } else if (skillArea === SKILL_GROUPS.BLUE_TEAM) {
      return <TabIcon src={BlueTeamIcon} />;
    } else if (skillArea === SKILL_GROUPS.YELLOW_TEAM) {
      return <TabIcon src={YellowTeamIcon} />;
    } else {
      return null;
    }
  };

  return (
    <UserSkillAreasContainer>
      {Object.keys(skillAreas).length ? (
        Object.keys(skillAreas).map((skillArea: string) => (
          <SkillGroupContainer key={skillArea}>
            <SkillGroupTitle>
              {getSkillAreaIcon(skillArea)}
              {skillArea}
            </SkillGroupTitle>
            {skillAreas[skillArea].map((skill: any) => (
              <SkillGroupProgressContainer key={skill.id}>
                <SkillProgressBar
                  skillName={skill.name}
                  value={skill.user_status.score * 100}
                  difficulty={skill.user_status.difficulty}
                  level={skill.user_status.level}
                />
              </SkillGroupProgressContainer>
            ))}
          </SkillGroupContainer>
        ))
      ) : (
        <SonarEmptyScore>No data available</SonarEmptyScore>
      )}
    </UserSkillAreasContainer>
  );
};

export default UserSkillAreas;
