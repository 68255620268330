import { useState } from 'react';
import styled from 'styled-components';

interface ViewMoreTypes {
  moreLabel: string;
  lessLabel: string;
  content?: JSX.Element | string;
  children?: JSX.Element;
}

const Container = styled.div`
  display: block; // flex fail.
`;

const Content = styled.div<{ open: boolean }>`
  ${({ open }) =>
    !open &&
    `
		display: none;
	`}
`;

const Label = styled.div`
  color: ${({ theme }) => theme.default.colors.palette.Business_DataVizLilacDark};
  font-size: 14px;
  line-height: 20px;
  margin: 10px 0px;
  cursor: pointer;
`;

let userFiltersViewMore = false; // Refactor, uso esa variable porque el componente se recarga y cierra en cada filtrado.

const ViewMore = (props: ViewMoreTypes): JSX.Element => {
  const [open, setOpen] = useState(userFiltersViewMore);

  const handleTrigger = () => {
    setOpen(!open);
    userFiltersViewMore = !open;
  };

  return (
    <Container>
      <Content open={open}>{props.children}</Content>
      <Label onClick={handleTrigger}>
        <>{open ? props.lessLabel : props.moreLabel}</>
      </Label>
    </Container>
  );
};

export default ViewMore;
