import styled from 'styled-components';

interface IPillButton {
  onClick: () => void;
  label: string;
  selected: boolean;
}

const Button = styled.button<{ selected: boolean }>`
  height: 24px;
  padding: 5px 10px;
  white-space: nowrap;
  border-style: none;
  background: transparent;

  span {
    vertical-align: middle;
  }

  ${({ selected }) =>
    selected &&
    `
		font-weight: 700;
		background: #F2F2F7;
		border-radius: 10px;
		`}
`;

const PillButton = ({ label, ...rest }: IPillButton): JSX.Element => {
  return (
    <Button {...rest}>
      <span className="button__label">{label}</span>
    </Button>
  );
};

export default PillButton;
