import styled from 'styled-components';
// import { screenSm } from 'constants/styles/breakpoints';

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 32px;
`;

export const Title = styled.h2`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 0;
`;
export const Subtitle = styled.h4`
  margin-top: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

export const Content = styled.div<{
  fetching?: boolean;
}>`
  ${({ fetching }) =>
    fetching &&
    `
		opacity: 0.6;
		pointer-events: none;
	`}
`;
