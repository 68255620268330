import { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import Button from 'components/button';
import { Account } from 'contexts/accounts/types';
import { SectionCard } from 'pages/Analytics/components/AnalyticsContent/styles';
import { Title } from 'pages/Analytics/components/ActiveUsersCollapse/styles';
import CreateNote from 'components/Notes/CreateNote';
import { Note } from 'contexts/notes/types';
import { NotesContext } from 'contexts/notes';
import NotesDrawer from 'components/Notes/NotesMobile/NotesDrawer';
import ButtonIcon from 'components/buttonIcon';
import NoteListItemMobile from 'components/Notes/NotesMobile/NoteListItemMobile';
import { NotesContainerMobile } from 'components/Notes/styles';
import NoteDetailModal from 'components/Notes/NotesMobile/NoteDetailModal';
import NotesEmpty from 'components/Notes/NotesEmpty';
import { SessionContext } from 'contexts/session';

interface IAccounNotesContentMobileProps {
  account: Account;
}
export const AccountNotesContentMobile = ({
  account,
}: IAccounNotesContentMobileProps): JSX.Element => {
  const { showNotesDrawer, setShowNotesDrawer } = useContext(SessionContext);
  const { fetching, actions, table, isEmpty } = useContext(NotesContext);
  const [showCreateNoteModal, setShowCreateNoteModal] = useState(false);
  const [showNoteDetail, setShowNoteDetail] = useState<boolean>(false);
  const [noteSelected, setNoteSelected] = useState<Note>(null);

  const handleCreateNoteModalResponse = (result) => {
    setShowCreateNoteModal(false);
    setNoteSelected(null);
    if (result === 'ok') {
      actions.onNotesRefresh();
      actions.onRefreshNoteUsers();
    }
  };

  const handleNoteDetailEdited = () => {
    setShowNoteDetail(false);
    setNoteSelected(null);
    actions.onNotesRefresh();
  };

  const handleDeleteNoteCofirmation = () => {
    actions.onDeleteNote(noteSelected.id);
    setShowNoteDetail(false);
  };

  const handleShowNoteDetail = (note: Note) => {
    setNoteSelected(note);
    setShowNoteDetail(true);
  };

  const handleCloseNoteDetail = () => {
    setNoteSelected(null);
    setShowNoteDetail(false);
  };

  useEffect(() => {
    actions.onChangeFilters([
      { name: 'content', value: 'account' },
      { name: 'content_id', value: account.id },
    ]);
  }, []);

  return (
    <>
      <SectionCard padding={'20px'} rounded shadow dashboard>
        <header>
          <Row>
            <Col xs={20} style={{ paddingTop: '5px' }}>
              <Title style={{ fontSize: '20px' }}>Notes</Title>
            </Col>
            <Col xs={4}>
              <ButtonIcon
                $terciary
                icon={'ArrowRight'}
                onClick={() => setShowNotesDrawer(true)}
                style={{ float: 'right' }}
              />
            </Col>
          </Row>
        </header>
        <NotesContainerMobile fetching={fetching}>
          {!isEmpty ? (
            table?.items?.map((item, index) => {
              return (
                <NoteListItemMobile
                  key={index}
                  note={item}
                  onShowNoteDetail={handleShowNoteDetail}
                />
              );
            })
          ) : (
            <NotesEmpty onAddNote={() => setShowCreateNoteModal(true)} />
          )}
        </NotesContainerMobile>
        <Button
          className="w-100 mt-5"
          $secondary
          icon={'Clip'}
          size={'small'}
          onClick={() => setShowCreateNoteModal(true)}
        >
          Add note
        </Button>
      </SectionCard>
      {showCreateNoteModal && (
        <CreateNote
          isModalOpen={showCreateNoteModal}
          note={noteSelected}
          entity={account}
          onClose={handleCreateNoteModalResponse}
        />
      )}
      {showNotesDrawer && <NotesDrawer entity={account} notesDrawerVisible={showNotesDrawer} />}
      {showNoteDetail && (
        <NoteDetailModal
          isModalOpen={showNoteDetail}
          note={noteSelected}
          entity={account}
          onClose={handleCloseNoteDetail}
          onNoteEdited={handleNoteDetailEdited}
          onDeleteConfirmation={handleDeleteNoteCofirmation}
        />
      )}
    </>
  );
};

export default AccountNotesContentMobile;
