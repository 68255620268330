import styled, { css } from 'styled-components';
import { colors } from 'constants/styles/colors';
import Button from 'components/button';
import { LEVEL_CONSTANTS } from './types';

const skillBackgroundColor = (value: string) => {
  if (value === LEVEL_CONSTANTS.NOVICE) {
    return css`
      color: #ffffff;
      background-color: #fe6d6d;
    `;
  } else if (value === LEVEL_CONSTANTS.PROFESSIONAL) {
    return css`
      color: #ffffff;
      background-color: #f8b369;
    `;
  } else if (value === LEVEL_CONSTANTS.ADVANCED) {
    return css`
      color: #ffffff;
      background-color: #49e0b3;
    `;
  } else {
    return css`
      color: #999999;
      background-color: #f2f2f7;
    `;
  }
};

export const TeamSkillsContainer = styled.div`
  min-height: 250px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const TeamSkillsRowHeader = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  z-index: 100;
  background: ${colors.fonts.white};
`;

export const GruopsFilterRow = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  z-index: 100;
  background: ${colors.fonts.white};
  height: 50px;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: 30px;
`;

export const GroupFilterContainer = styled.div`
  display: flex;
`;

export const TeamSkillsRow = styled.div`
  display: flex;
  margin-top: 10px;

  @media (max-width: 450px) {
    align-items: center;
  }
`;

export const TeamSkillsColUserHeader = styled.div`
  height: 50px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  flex: 0 0 142px;
  @media (max-width: 450px) {
    width: 20%;
    display: block;
  }
`;

export const TeamSkillsColUser = styled.div`
  display: flex;
  align-items: center;
  min-width: 100px;
  width: 15%;
  height: 50px;
  gap: 10px;
  flex: 0 0 142px;
  @media (max-width: 450px) {
    width: 20%;
  }
`;

export const UserLabel = styled.div`
  font-size: 12px;
  line-height: 14px;
  font-weight: 450;
  cursor: pointer;
`;

export const AverageLabel = styled.div`
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  color: ${colors.fonts.darkGray};
`;

export const BetaPill = styled.div`
  display: flex;
  padding: 5px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  background: var(
    --glass-extra-light,
    linear-gradient(140deg, rgba(187, 188, 199, 0.4) 0%, rgba(187, 188, 199, 0) 100%)
  );
  color: var(--dark-page-fill, #111);
  text-align: center;
  font-size: 12px;
  font-weight: 450;
  line-height: 14px;
`;

export const TeamSkillsColHeader = styled.div<{
  widthValue?: number;
}>`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  font-weight: 450;
  color: ${colors.fonts.darkGray};
  min-width: 5%;
  min-width: 5%;
  width: ${({ widthValue }) => (widthValue ? `${widthValue}%` : '15%')};
  height: 50px;

  @media (max-width: 450px) {
    height: 90px;
    transform: rotate(-90deg);
  }
`;

export const TeamSkillsCol = styled.div<{
  widthValue?: number;
}>`
  min-width: 5%;
  width: ${({ widthValue }) => (widthValue ? `${widthValue}%` : '15%')};
  height: 50px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 450;
  margin-right: 10px;
`;

export const EmtpyTeamSkillsCol = styled.div`
  width: 35px;
  min-width: 35px;
  height: 50px;
  border: 1px solid #f2f2f7;
  border-radius: 5px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 450;

  @media (max-width: 450px) {
    display: none;
  }
`;

export const SkillGridContent = styled.div<{
  dataValue?: string;
}>`
  ${({ dataValue }) => skillBackgroundColor(dataValue)};

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
`;

export const SkillsMenuContainer = styled.div`
  padding: 5px 5px 10px 10px;
  background: #ffffff;
  box-shadow: 0px 30px 48px rgba(0, 0, 0, 0.2);
  position: relative;
  border-radius: 6px;
  overflow-y: auto;
  max-height: 250px;
  width: 310px;
`;

export const SkillsEmptyContainer = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
`;

export const SkillsEmptyWrapper = styled.div`
  width: 60%;
  margin: 5% 0;
  @media (max-width: 450px) {
    width: 90%;
  }
`;

export const EmptySkillsImg = styled.img`
  width: 25%;

  @media (max-width: 450px) {
    width: 70%;
  }
`;

export const EmptySkillTitle = styled.div`
  margin: 15px 0px 15px 0px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: ${colors.fonts.dark};
`;

export const EmptySkillSubTitle = styled.div`
  margin: 15px 0px 15px 0px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 450;
  color: ${colors.fonts.darkGray};
`;

export const ComingSoonSubtitle = styled.div`
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  color: ${colors.fonts.darkGray};

  span {
    font-weight: 600;
  }
`;

export const CopySonarLinkBtn = styled(Button)`
  &&& {
    border: none;
    background: #f2f2f7;
    padding: 0px 0px 0px 15px;
    font-size: 15px;
    font-weight: 450;
    line-height: 18.97px;
    color: ${colors.fonts.darkGray};

    @media (max-width: 450px) {
      width: 100%;
    }

    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: ${colors.dataVisualization.purple.dark};
    }

    .linkContainer {
      height: 100%;
      display: flex;
      align-items: center;

      @media (max-width: 450px) {
        width: 100%;
      }
    }

    .copyIconContainer {
      height: 100%;
      display: flex;
      align-items: center;
      border-radius: 0px 6px 6px 0px;
      padding: 0px 10px 0px 5px;
    }

    .copyIconContainerClicked {
      background-color: ${colors.dataVisualization.black.dark};
      color: ${colors.dataVisualization.purple.dark};
    }

    &:hover {
      background: #f2f2f7;
      color: ${colors.fonts.darkGray};
      box-shadow: none;
      border: 1px solid ${colors.dataVisualization.purple.dark};

      .copyIconContainer {
        border-left: 1px solid ${colors.dataVisualization.purple.dark};
        background-color: ${colors.dataVisualization.black.dark};
      }
    }
  }
`;
