import styled, { css } from 'styled-components';
import AvatarBg from 'assets/icons/avatar-bg.svg';
import { pendingColor } from 'utils/colors';
import AccountDefaultLogo from 'assets/images/account-avatar.svg';

enum SIZES {
  xs = 25,
  sm = 45,
  md = 50,
  lg = 75,
}
enum SIZES_IMG {
  xs = 25, //25,
  sm = 45, //34,
  md = 50, //40,
  lg = 75, //58,
}
enum SIZESPERC {
  sm = '25%',
  md = '50%',
  lg = '100%',
}
const STATUS_POSITION = {
  xs: {
    left: 1,
    height: 4,
    width: 4,
    top: 1,
  },
  sm: {
    left: 2,
    height: 6,
    width: 6,
    top: 2,
  },
  md: {
    left: 4,
    height: 8,
    width: 8,
    top: 4,
  },
  lg: {
    left: 4,
    height: 12,
    width: 12,
    top: 4,
  },
};

const backgroundImageSize = (size: keyof typeof SIZES_IMG = 'sm') => {
  return css`
    background-size: ${SIZES_IMG[size]}px;
    height: ${SIZES_IMG[size]}px;
    width: ${SIZES_IMG[size]}px;
  `;
};

const backgroundSize = (size: keyof typeof SIZES = 'sm', outlined?: boolean) => {
  const subtract = outlined ? 9 : 0;
  return css`
    background-size: ${SIZES[size] - subtract}px ${size}px;
    height: ${SIZES[size] - subtract}px;
    width: ${SIZES[size] - subtract}px;
  `;
};

export const ProgressBar = styled.div<{ size?: keyof typeof SIZES }>`
  align-items: center;
  display: flex;
  background-image: url(${AvatarBg});
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  justify-content: center;

  ${({ size }) => backgroundSize(size)};
`;

export const AvatarImage = styled.div<{
  size?: keyof typeof SIZES;
  outlined?: boolean;
  src: string;
}>`
  align-items: center;
  background-position: center center;
  background-repeat: no-repeat, no-repeat;
  background-size: cover;
  border-radius: 50%;
  position: relative;
  flex-shrink: 0;

  ${({ size }) => backgroundImageSize(size)};

  ${(props) =>
    props.src
      ? `
		background-image: url(${props.src}), url(${AccountDefaultLogo});
		`
      : `
		background-color: rgba(0,0,0,0.02);
	`}
`;

export const Status = styled.div<{ size: keyof typeof STATUS_POSITION }>`
  background: ${({ theme }) => theme.default.colors.performance.positive};
  border-radius: 50%;
  border: 2px solid white;
  position: absolute;
  height: ${({ size }) => STATUS_POSITION[size].height}px;
  left: ${({ size }) => STATUS_POSITION[size].left}px;
  top: ${({ size }) => STATUS_POSITION[size].top}px;
  width: ${({ size }) => STATUS_POSITION[size].width}px;
`;

export const AvatarContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 20%;
  justify-content: center;
  text-align: center;
  width: 100%;
`;

export const AvatarFirstDiv = styled.div<{ colors?: boolean | undefined }>`
  height: 100px;
  width: 100%;
  background: ${({ colors }) =>
    colors
      ? 'linear-gradient(89.99deg, #863AD2 3.58%, #5936E4 97.14%)'
      : `linear-gradient(89.99deg, ${pendingColor.base} 3.58%, ${pendingColor.light} 97.14%)`};
`;

export const AvatarSecondDiv = styled.div<{ color?: string; size?: number }>`
  color: ${({ color }) => (color ? color : '#65AAA3')};
  border-radius: 50%;
  position: fixed;
  border: ${({ size }) => size && '1px solid'};
  font-size: ${({ size }) => (size ? `${size}em` : '2em')};
  font-weight: 700;
  height: 15%;
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AvatarLetter = styled.div<{
  size?: keyof typeof SIZESPERC;
  src: string;
}>`
  align-items: center;
  background-position: center center;
  background-repeat: no-repeat, no-repeat;
  background-size: cover;
  border-radius: 50%;
  position: relative;
  flex-shrink: 0;
  ${({ size }) => backgroundImageSize(size)};
  ${(props) =>
    props.src
      ? `
		background-image: url(${props.src});
		`
      : `
		background-color: rgba(0,0,0,0.02);
	`}
`;
