import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';
import styled from 'styled-components';
import { getUserProfile } from 'services/userProfile';
import {
  CardsContainer,
  Columns,
  MainContent,
  Card as ProfileCard,
  Row,
} from 'components/layout/profile/profileContent/styles';
import AppReadyLoading from 'components/loadings/AppReadyLoading';
import { screenMd } from 'constants/styles/breakpoints';
import UserProfileHeader from './components/profileHeader';
import TeamSlider from './components/teamSlider';
import UserCardLicense from './components/licenseCard';
import { MONTH_PERIOD, WEEK_PERIOD } from 'constants/filters';
import { SessionContext } from 'contexts/session';
import PreferredContent from 'components/stats/preferredContent';
import { UserProfileData } from './types';
import UserAnalytics from './components/UserAnalytics';
import {
  USER_PROFILE_AGGREGATIONS_STATS,
  USER_PROFILE_AGGREGATION_TABS,
  USER_PROFILE_AGGRETATION_SERIES,
} from './helper';
import AggregationStats from 'components/stats/aggregationStats';
import { UserTeam } from 'contexts/teams/types';
import Notification from 'components/notification';
import { setUserTeams } from 'components/usersTable/actions';
import { ACC_OWNER } from 'constants/roles';
import UserSkills from './components/UserSkills/UserSkills';
import AssignedPlaylistsCard from './components/AssignedPlaylistsCard';
import UserAddonCard from './components/UserAddonCard/UserAddonCard';
import { getAccountData } from 'services/accounts';
import { Account } from 'contexts/accounts/types';
import PlaylistsProvider from 'contexts/playlists';

const Card = styled(ProfileCard)`
  &.card-license {
    flex-grow: 2.4;
    flex-shrink: 2.4;
    ${screenMd} {
      height: 100%;
    }
  }
  &.card-user-addons {
    flex-grow: 2.4;
    flex-shrink: 2.4;
    ${screenMd} {
      height: 100%;
    }
    padding: 25px;
  }
  &.card-user-playlists {
    flex-grow: 2.4;
    flex-shrink: 2.4;
    ${screenMd} {
      height: 100%;
    }
    padding: 25px;
  }
  &.card-team {
    flex-grow: 2.1;
    flex-shrink: 2.1;
  }
  &.card-user-counts {
    flex-grow: 1.6;
    flex-shrink: 1.6;
    padding: 15px 10px 15px 10px;
    ${screenMd} {
      height: 100%;
    }
  }
  &.card-user-preferred {
    flex-grow: 1.5;
    flex-shrink: 1.5;
    ${screenMd} {
      height: 100%;
    }
  }
  &.card-user-analytics {
    flex-grow: 1.5;
    flex-shrink: 1.5;
    ${screenMd} {
      height: 100%;
    }
  }
`;

const UsersProfile = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const [userData, setUserData] = useState<UserProfileData>(null);
  const [accountData, setAccountData] = useState<Account>(null);
  const [loading, setLoading] = useState(false);
  const { user: userSession, account, isStaff, accounts } = useContext(SessionContext);

  const getPreferredContentParams = () => {
    return {
      period: MONTH_PERIOD.VALUE,
      user_id: userSession.uaa_id,
      account_id: isStaff ? userData.user.account : account?.id,
      business_user_id: userData.user.uaa_id,
    };
  };

  const getAggregationStatsParams = () => {
    const aggregations = [];
    USER_PROFILE_AGGREGATIONS_STATS.map((ag) => {
      aggregations.push({
        name: ag.name,
        label: ag.label,
        params: {
          user_id: userSession.uaa_id,
          account_id: isStaff ? userData.user.account : account?.id,
          actions: ag.actions,
          metadata: ag.metadata,
          business_user_id: userData.user.uaa_id,
        },
      });
    });
    return aggregations;
  };

  const getAggregationTabsData = () => {
    const aggregationTabs = [];
    USER_PROFILE_AGGREGATION_TABS.map((at) => {
      aggregationTabs.push({
        name: at.name,
        label: at.label,
        icon: at.icon,
        params: {
          period: MONTH_PERIOD.VALUE,
          user_id: userSession.uaa_id,
          account_id: isStaff ? userData.user.account : account?.id,
          actions: at.actions,
          external_action: at.external_action,
          business_user_id: userData.user.uaa_id,
        },
      });
    });
    return aggregationTabs;
  };

  const getAggregationSeriesData = () => {
    const aggregationSeries = [];
    USER_PROFILE_AGGRETATION_SERIES.map((as) => {
      aggregationSeries.push({
        name: as.name,
        series: as.series.map((s) => ({
          key: s.key,
          color: s.color,
          label: s.label,
          params: {
            period: WEEK_PERIOD.VALUE,
            date_from: null,
            date_to: null,
            user_id: userSession.uaa_id,
            account_id: isStaff ? userData.user.account : account?.id,
            actions: s.actions,
            metadata_filter: s.metadata,
            business_user_id: userData.user.uaa_id,
          },
        })),
      });
    });
    return aggregationSeries;
  };

  const handleTeamsEdited = async (userTeams: UserTeam[]) => {
    const editedTeams = userTeams.reduce((acc, team) => {
      acc.push({ team_id: team.team_id, is_manager: team.is_manager });
      return acc;
    }, []);
    setLoading(true);
    const response = await setUserTeams({
      user_invite_id: userData?.user_invite?.id,
      teams: editedTeams,
    });
    if (!(response instanceof Error) && response.result) {
      setLoading(false);
      Notification({ text: 'Teams updated', type: 'success' });
      getUserData();
    } else {
      setLoading(false);
      Notification({ text: 'Teams couldn’t be updated', type: 'error' });
    }
  };

  const getProfileAccountData = async (account_id: string) => {
    setLoading(true);
    try {
      const response = await getAccountData({ id: account_id });
      setLoading(false);
      setAccountData(response.data);
    } catch (error) {
      setLoading(false);
      Notification({ text: 'Error retrieving Account information', type: 'error' });
    }
  };

  const getUserData = async () => {
    try {
      const response = await getUserProfile(id);
      if (response.status === 200) {
        const data = response.data;
        if (response.status) {
          setUserData(data);
          isStaff && getProfileAccountData(data.user?.account);
        }
      }
    } catch (error) {
      Notification({ text: 'Error retrieving user information', type: 'error' });
    }
  };

  useEffect(() => {
    getUserData();
  }, [id]);

  if (!userData) {
    return <AppReadyLoading />;
  }

  return (
    <PlaylistsProvider>
      <MobileView>
        <UserProfileHeader
          user={userData.user}
          user_invite={userData.user_invite}
          onTeamsEdited={handleTeamsEdited}
        />
      </MobileView>
      <MainContent fetching={loading}>
        <BrowserView>
          <UserProfileHeader
            user={userData.user}
            user_invite={userData.user_invite}
            onTeamsEdited={handleTeamsEdited}
          />
        </BrowserView>
        <CardsContainer>
          <Row>
            <Columns style={{ height: '180px' }}>
              <Card className={'card-license'}>
                <UserCardLicense
                  user={userData.user}
                  user_invite={userData.user_invite}
                  onRefreshData={getUserData}
                />
              </Card>
              {/* Will show addons card or playlist card until it's changed in figma */}
              {account?.account_available_addons?.length > 0 ||
              accountData?.account_available_addons?.length > 0 ? (
                <Card className="card-user-addons">
                  <UserAddonCard
                    onRefreshData={getUserData}
                    account={isStaff ? accountData : account}
                    license_addons={userData.user?.license_addons}
                    user={userData?.user}
                  />
                </Card>
              ) : (
                <Card className="card-user-addons">
                  <AssignedPlaylistsCard uaa_id={userData.user.uaa_id} />
                </Card>
              )}
              <Card className={'card-user-counts'}>
                <AggregationStats aggregations={getAggregationStatsParams()} />
              </Card>
            </Columns>
          </Row>
          <Row className="mt-2">
            <Columns>
              <TeamSlider
                userInvite={userData.user_invite}
                teamsData={userData.user.teams}
                dashboard={false}
                userTeamAccOwner={userData?.user.uaa_data?.roles?.data?.some(
                  (role) => role.name === ACC_OWNER
                )}
                isItself={userData?.user?.email === userSession?.email}
              />
            </Columns>
          </Row>
          {accounts?.sonar_enabled && (
            <Card>
              <Row className="mt-2">
                <UserSkills
                  uaaId={userData?.user?.uaa_id}
                  userProfile={userData?.user?.uaa_data?.profile?.data}
                />
              </Row>
            </Card>
          )}
          <Row className="mt-2">
            {userData && (
              <Columns>
                <PreferredContent params={getPreferredContentParams()} />
                <UserAnalytics
                  aggregationTabs={getAggregationTabsData()}
                  aggregationSeries={getAggregationSeriesData()}
                />
              </Columns>
            )}
          </Row>
        </CardsContainer>
      </MainContent>
    </PlaylistsProvider>
  );
};

export default UsersProfile;
