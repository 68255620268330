export const ACTION_TYPES = {
  ACCOUNT_ADD: 'account.add',
  ACCOUNT_CHANGE_PLAN: 'account.change_plan',
  ACCOUNT_EMAIL_DOMAIN_CREATE: 'account.email_domain.create',
  ACCOUNT_EMAIL_DOMAIN_MODIFY: 'account.email_domain.modify',
  ACCOUNT_EMAIL_DOMAIN_REMOVE: 'account.email_domain.remove',
  ACCOUNT_EXPIRE: 'account.expire',
  ACCOUNT_MODIFY: 'account.modify',
  ACCOUNT_PAST_DUE: 'account.past_due',
  ACCOUNT_REACTIVATE: 'account.reactivate',
  ACCOUNT_SSO_ENABLED: 'account.sso.enabled',
  ACCOUNT_SSO_DISABLED: 'account.sso.disabled',
  ACCOUNT_SSO_CONFIGURED: 'account.sso.configured',
  TEAM_ADD: 'team.add',
  TEAM_BATCH_REMOVE: 'team.batch.remove',
  TEAM_MODIFY: 'team.modify',
  NOTE_ADD: 'note.add',
  NOTE_REMOVE: 'note.remove',
  INVITE_BATCH_REMOVE: 'invite.batch.remove',
  INVITE_BATCH_EMAIL: 'invite.batch.email',
  INVITE_BATCH_SET_ROLE: 'invite.batch.set_role',
  INVITE_TEAM_SET: 'invite.team.set',
  INVITE_TEAM_UNSET: 'invite.team.unset',
  INVITE_ACCEPT: 'invite.accept',
  LICENSE_BATCH_ADD: 'license.batch.add',
  LICENSE_BATCH_REMOVE: 'license.batch.remove',
  SONAR_ENABLED: 'account.sonar.enabled',
  SONAR_DISABLED: 'account.sonar.disabled',
  LICENSE_ADDON_ASSIGN: 'license.addon.assign',
  LICENSE_ADDON_UNASSIGN: 'license.addon.unassign',
};

export const ACTION_TYPES_BYPASSED = [ACTION_TYPES.ACCOUNT_PAST_DUE];

export interface ActivityLog {
  id: string;
  action: string;
  created_at: string;
  generated_by: {
    id: string;
    name: string;
    is_staff: boolean;
  };
  account_id: string;
  metadata: any;
}

export interface FilterProps {
  page_size: string;
  page: string;
  from_date: string;
  to_date: string;
  generated_by: string;
  types: string;
  account_id: string;
  append_results: boolean;
}

export interface AcitivityLogParams {
  page_size?: string;
  page?: string;
  from_date?: string;
  to_date?: string;
  generated_by?: string;
  types?: string;
  account_id?: string;
}

export interface FilterItem {
  name: string;
  value: string | number | boolean;
}

export interface FilterRange {
  toName: string;
  fromName: string;
  toValue: string;
  fromValue: string;
}

export interface ActivityLogUsersParams {
  account_id: string;
}

export interface ActivityLogUsers {
  id: string;
  name: string;
}

export interface ActivityLogUsersOptions {
  label: string;
  value: string;
  key: string | number;
}

export interface ActivityLogDataCtx {
  fetching: boolean;
  table: {
    items?: ActivityLog[] | null;
    pageSize?: number;
    page?: number;
    loading: boolean;
    count: number;
    error?: string | boolean;
  };
  activityLogUsers: ActivityLogUsersOptions[] | null;
  isEmpty: boolean;
  filters: FilterProps;
  actions?: {
    onChangeFilter: (filter: FilterItem) => void;
    onChangeFilters: (filters: FilterItem[]) => void;
    onChangeRangeFilters: (filter: FilterRange) => void;
    onRefreshActivities: () => void;
    onRetrieveActivityLogUsers: (account_id?: string) => void;
    onResetActivities: (account_id?: string) => void;
  };
  error?: typeof Error;
}
