import AvatarsCount from 'components/avatarsCount';
import { getPendingsItems } from 'utils/colors';
import { memo } from 'react';
import { EmptyCell } from 'components/Table';

interface ItemTeamProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}

const Pending = (props: ItemTeamProps): JSX.Element => {
  const { data } = props;

  const more = data.length - 3;

  return (
    <>
      {data?.length > 0 ? (
        <AvatarsCount items={getPendingsItems(data)} more={more} />
      ) : (
        <EmptyCell />
      )}
    </>
  );
};

export default memo(Pending);
