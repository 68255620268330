import { useContext, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import useSearchQuery from './useSearchQuery';
import { getPlaylistsAction } from 'contexts/playlists/actions';
import { playlistsInitialValues } from 'contexts/playlists/utils';
import { IPlaylist, IPlaylistsDataCtx } from '../contexts/playlists/types';
import { deletePlaylist as deletePlaylistService } from 'services/playlists';
import { assignUsers as assignUsersService } from 'services/playlists';
import { unAssignUsers as unAssignUsersService } from 'services/playlists';
import { IAssignUsersParams } from 'services/playlists';
import { SessionContext } from 'contexts/session';
import Notification from 'components/notification';

interface IPlaylistsContentHook {
  fetching: boolean;
  playlists: IPlaylistsDataCtx['list'];
  error: string | boolean;
  updatePlaylistsList: (items: IPlaylist[]) => void;
  deletePlaylist: (playlist: IPlaylist) => Promise<AxiosResponse>;
  refreshPlaylists: () => void;
  onAssignUser: (playlist?: IPlaylist) => void;
  onUnassignUser: (playlist?: IPlaylist) => void;
}

const usePlaylistsContent = (): IPlaylistsContentHook => {
  const [playlistsList, setPlaylistsList] = useState(playlistsInitialValues.list);
  const [fetching, setFetching] = useState(false);
  const { searchQuery, search } = useSearchQuery();
  const { account } = useContext(SessionContext);
  const business_account_id = account?.id;

  const getPlaylists = async () => {
    setFetching(true);
    const playlistsResponse = await getPlaylistsAction(handleReqData());
    setFetching(false);
    if (playlistsResponse instanceof Error) {
      setPlaylistsList((prevState) => ({ ...prevState, error: playlistsResponse.message }));
    } else {
      setPlaylistsList((prevState) => ({
        ...prevState,
        ...playlistsResponse,
        items: playlistsResponse.results,
      }));
    }
  };

  const handleDeletePlaylist = async (playlist: IPlaylist) => {
    let response;
    setFetching(true);
    try {
      response = await deletePlaylistService({
        id: playlist.id,
        business_account_id: business_account_id,
      });
      return response;
    } catch (error) {
      return new Error(error);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    getPlaylists();
  }, [searchQuery]);

  const handleReqData = () => {
    return {
      page: parseInt(search.get('page')) || playlistsInitialValues.filters.page,
      page_size: parseInt(search.get('page_size')) || playlistsInitialValues.filters.page_size,
      name__icontains: search.get('name') || null,
      business_account_id,
      type: search.get('type') || null,
      assigned: search.get('assigned') || null,
    };
  };

  const updatePlaylistsList = () => {
    getPlaylists();
  };

  const handleAssignUser = async (playlist: IPlaylist) => {
    setFetching(true);
    try {
      if (!playlist.created_for) {
        Notification({ text: 'There has been a problem assigning user', type: 'error' });
        throw new Error('No user to assign');
      }
      const reqData: IAssignUsersParams = {
        id: playlist.id,
        user_ids: [playlist.created_for?.id],
        business_account_id: business_account_id,
      };

      const response = await assignUsersService(reqData);
      if (response.status === 200) {
        Notification({
          text: `You've assigned ${playlist.name} playlist`,
          type: 'success',
        });
        updatePlaylistsList();
      } else {
        Notification({ text: 'There has been a problem assigning user', type: 'error' });
      }
    } catch (error) {
      if (error?.response?.data?.details) {
        Notification({ text: error.response.data.details, type: 'error' });
      }
    }
  };

  const handleUnassignUser = async (playlist: IPlaylist) => {
    setFetching(true);
    try {
      if (!playlist.created_for) {
        Notification({ text: 'There has been a problem unassigning user', type: 'error' });
        throw new Error('No user to unassign');
      }
      const reqData: IAssignUsersParams = {
        id: playlist.id,
        user_ids: [playlist.created_for?.id],
        business_account_id: business_account_id,
      };
      const response = await unAssignUsersService(reqData);
      if (response.status === 204) {
        Notification({
          text: `You've unassigned ${playlist.name} playlist`,
          type: 'success',
        });
        updatePlaylistsList();
      } else {
        Notification({ text: 'There has been a problem unassigning user', type: 'error' });
      }
    } catch (error) {
      if (error?.response?.data?.details) {
        Notification({ text: error.response.data.details, type: 'error' });
      }
    }
  };

  return {
    fetching: fetching,
    playlists: playlistsList,
    error: false,
    updatePlaylistsList: updatePlaylistsList,
    deletePlaylist: handleDeletePlaylist,
    refreshPlaylists: getPlaylists,
    onAssignUser: handleAssignUser,
    onUnassignUser: handleUnassignUser,
  };
};

export default usePlaylistsContent;
