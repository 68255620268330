import './statics.scss';
import { durationFromSecs } from 'utils/helpers';

export interface IPreviewData {
  objectID: string;
  description: string;
  duration: number;
  instructors: { name: string }[];
}

interface IPreviewProps {
  data: IPreviewData;
}

const PreviewVideo = ({ data }: IPreviewProps): JSX.Element => (
  <div className="preview-tooltip">
    <div className="preview-tooltip__info-container">
      <div className="preview-tooltip__info-row">
        <div className="preview-tooltip__info-column">DURATION</div>
        <div className="preview-tooltip__info-column">
          {data.duration ? durationFromSecs(data.duration, 'minutes') : 0}
        </div>
      </div>
      {data.instructors.length > 0 && (
        <div className="preview-tooltip__info-row">
          <div className="preview-tooltip__info-column">INSTRUCTOR</div>
          <div className="preview-tooltip__info-column">{data.instructors[0]?.name}</div>
        </div>
      )}
    </div>
  </div>
);

export default PreviewVideo;
