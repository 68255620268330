import styled from 'styled-components';
import { screenSm, screenMd } from 'constants/styles/breakpoints';
import { colors } from 'constants/styles/colors';

export const RecommendedContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const BodyTitle = styled.div`
  font-family: Circular-Std;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0px;
  margin: 0 0 10px;
  text-align: left;
  padding: 0;
  ${screenMd} {
    padding: 0;
  }
`;

export const BodySubtitle = styled.div`
  color: ${colors.fonts.darkGray};
  font-family: Circular-Std;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
  margin: 0 0 20px;
  ${screenMd} {
    padding: 0;
  }
`;
export const PlaylistsCardsContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  gap: 25px 20px;
  flex-wrap: wrap;
  justify-content: center;
  ${screenSm} {
    justify-content: flex-start;
  }
`;

export const PlaylistLoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  z-index: 500;
  background-color: var(--white-50);
  position: absolute;
`;
