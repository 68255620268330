import { screenMd } from 'constants/styles/breakpoints';
import styled from 'styled-components';

export const TeamColorBall = styled.div<{ selected: boolean; color: string }>`
  border-radius: 50%;
  width: 25px;
  height: 25px;
  background: ${({ color }) => color};
  display: inline-block;
  margin-right: 16px;
  cursor: pointer;
  vertical-align: middle;
  transition: all 0.1s linear;
  ${({ selected }) =>
    selected &&
    `
		transform: scale(1.4);
	`}
  ${screenMd} {
    margin-right: 10px;
  }
`;

export const TeamIconsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  ${screenMd} {
    height: 160px;
  }
`;

export const TeamIconWrapper = styled.div<{ selected: boolean }>`
  display: inline-block;
  transition: all 0.1s linear;
  vertical-align: middle;
  margin: 10px 5px;
  cursor: pointer;
  ${screenMd} {
    margin: 5px;
  }
  ${({ selected }) =>
    selected &&
    `
		transform: scale(1.4); 
		margin: 5px 15px;
		${screenMd} {
			margin: 5px 15px;
		}
	`}
`;
