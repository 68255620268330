import { useContext } from 'react';
import { isMobile } from 'react-device-detect';
import { SectionCard } from 'pages/Analytics/components/AnalyticsContent/styles';

import ActiveUsersStatsPanel from 'pages/Analytics/components/ActiveUsersStatsPanel';
import { AnalyticsContext } from 'contexts/analytics';

const DashboardUsersActiveStats = (): JSX.Element => {
  const { activities, counters, actions, currentChart, entitiesTable } =
    useContext(AnalyticsContext);

  return (
    <SectionCard padding={isMobile ? '0' : '30px'} rounded dashboard shadow>
      <ActiveUsersStatsPanel
        activities={activities}
        actions={actions}
        counters={counters}
        tabSelected={currentChart}
        entitiesTable={entitiesTable}
        displayTable={false}
        availableTypes={['users', 'activities']}
        actionButton={'See more analytics'}
        hideChartTypeSelector={true}
      />
    </SectionCard>
  );
};

export default DashboardUsersActiveStats;
