import { screenMd, screenXs } from 'constants/styles/breakpoints';

import Button from 'components/button';
import MaintenanceBackground from 'assets/images/maintenance.png';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;

  color: #1a2535;
  text-align: center;
  img {
    width: inherit;

    ${screenXs} {
      width: auto;
    }
  }
  h1 {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    max-width: 1060px;

    ${screenMd} {
      font-size: 32px;
      line-height: 38px;
    }
  }
  h4 {
    font-weight: 450;
    font-size: 16px;
    line-height: 24px;
    color: #57575c;

    ${screenMd} {
      font-size: 24px;
      line-height: 32px;
    }
  }
`;

const LinkButton = styled.div`
  width: 100%;
  margin-top: auto;
  padding: 10px;

  ${screenMd} {
    margin-top: 70px;
    width: auto;
  }
`;

const Maintenance = (): JSX.Element => {
  return (
    <Container>
      <img src={MaintenanceBackground} alt="under maintenance" />
      <h1>The Business Dashboards are undergoing maintenance for upgrades to our systems</h1>
      <h4>This will be temporary and should be back online in just a moment.</h4>
      <LinkButton className="button-wrapper">
        <a href={process.env.REACT_APP_PUBLIC_URL}>
          <Button $full>Continue learning</Button>
        </a>
      </LinkButton>
    </Container>
  );
};

export default Maintenance;
