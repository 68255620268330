import styled from 'styled-components';
import { colors } from 'constants/styles/colors';

export const UserSkillsContainer = styled.div`
  width: 100%;
  margin: 10px 0 40px;
  padding: 0 0 40px;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const UserSkillsRow = styled.div`
  display: flex;
  margin-top: 15px;
  height: 100%;
  gap: 40px;
  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

export const UserSkillsColRadar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 15%;
  gap: 10px;

  @media (max-width: 450px) {
    width: 100%;
  }
`;

export const UserSkillsColAreas = styled.div`
  display: flex;
  width: 75%;
`;

export const UserSkillAreasContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  gap: 10px;
  width: 100%;
  color: black;
`;

export const SkillGroupContainer = styled.div``;

export const SkillGroupProgressContainer = styled.div`
  margin-bottom: 8px;
`;

export const SkillGroupTitle = styled.div`
  display: flex;
  gap: 10px;
  font-size: 16px;
  line-height: 23px;
  font-weight: 500;
  margin-bottom: 10px;
  align-items: center;
`;

export const SkillProgressContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;

export const SkillGroupNameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0px 5px 0px;

  span {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const SkillProgressBarItem = styled.div<{ first?: boolean; last?: boolean }>`
  background: ${colors.dataVisualization.gray.light};
  height: 24px;
  border-radius: 2px;

  ${({ first }) => first && 'border-radius: 4px 2px 2px 4px !important'};

  ${({ last }) =>
    last &&
    `
    &&& {
      border-radius: 2px 4px 4px 2px;
      background: linear-gradient(
        112.2deg,
        rgba(187, 188, 199, 0.4) -46.39%,
        rgba(187, 188, 199, 0) 112.38%
      );
    }
  `};
`;

export const SkillProgressBarItemFill = styled.div<{
  first?: boolean;
  second?: boolean;
  last?: boolean;
}>`
  height: 24px;
  position: absolute;
  top: 0;
  ${({ first }) =>
    first && 'background: linear-gradient(96.51deg, #ff5656 7.05%, #ff923d 93.87%) !important;'};

  ${({ second }) =>
    second && 'background: linear-gradient(96.51deg, #ff923d 7.05%, #97c759 93.87%) !important;'};

  ${({ last }) =>
    last && 'background: linear-gradient(96.51deg, #97c759 7.05%, #00DE9C 100%) !important;'};
`;

export const AveragePill = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  width: 37px;
  height: 24px;
  background: linear-gradient(89.99deg, #863ad2 3.58%, #5936e4 97.14%);
  color: #ffffff;
  font-size: 12px;
  line-height: 14px;
`;

export const SkillNameLabel = styled.span`
  position: absolute;
  z-index: 1;
  margin-left: 5px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 450;
  color: ${colors.fonts.black};
`;

export const SkillLevel = styled.span`
  position: absolute;
  z-index: 1;
  font-size: 12px;
  line-height: 14px;
  font-weight: 450;
  color: ${colors.fonts.black};
  right: 115px;
`;

export const SonarEmptyScore = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  top: 50%;
  transform: translateY(calc(-15px - 50%));
`;
