import { useContext } from 'react';
import { BrowserView } from 'react-device-detect';
import { Container } from './styles';
import AmountLicenses from './Filters/AmountLicenses';
import PricingPlan from './Filters/PricingPlan';
import AccountStatus from './Filters/AccountStatus';
import PlanExpiration from './Filters/PlanExpiration';
import Representative from './Filters/Representative';
import Button from 'components/button';
import { AccountsContext } from 'contexts/accounts';
import AccountAddonsFilter from './Filters/AccountAddonsFilter';

const AccountsFilters: React.FC = (): JSX.Element => {
  const { actions } = useContext(AccountsContext);
  return (
    <>
      <Container>
        <PricingPlan />
        <AccountAddonsFilter />
        <AccountStatus />
        <PlanExpiration />
        <AmountLicenses />
        <Representative />
        <BrowserView>
          <Button $terciary onClick={actions?.onClearSearch} style={{ fontWeight: 450 }}>
            Clear Filters
          </Button>
        </BrowserView>
      </Container>
    </>
  );
};

export default AccountsFilters;
