import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { isDesktop, isMobile } from 'react-device-detect';
import { Space } from 'antd';
import { formatDistanceToNowStrict } from 'date-fns';
import ButtonIcon from 'components/buttonIcon';
import {
  Actions,
  ActionsContainer,
  HeaderLabel,
  LastActive,
} from 'components/layout/profile/profileHeader/styles';
import { SessionContext } from 'contexts/session';
import { UserDetail } from 'types/users';
import { getRemoveUsersAction } from 'contexts/users/actions';
import Notification from 'components/notification';
import Tooltip from 'components/tooltip';
import { ActionModal } from 'components/modal';
import { ACC_OWNER } from 'constants/roles';
import { EmptyCell } from 'components/Table';
import EditTeamsModal from './EditTeamsModal';
import { UserTeam } from 'contexts/teams/types';

const HeaderActions = ({
  user,
  user_invite,
  onTeamsEdited,
}: {
  user: UserDetail;
  user_invite: { id: string; role: string };
  onTeamsEdited: (userTeams: UserTeam[]) => void;
}): JSX.Element => {
  const { allows, user: userLogged } = useContext(SessionContext);
  const [userTeams, setUserTeams] = useState([]);
  const lastActiveDate = user?.last_login ? (
    formatDistanceToNowStrict(new Date(user?.last_login), { addSuffix: true })
  ) : (
    <EmptyCell />
  );
  const history = useHistory();

  const isItself = userLogged?.email === user?.email;

  const { can_delete_user } = allows;

  const handleRemoveUser = async (ids: string[]) => {
    const response = await getRemoveUsersAction({ ids });
    if (response instanceof Error) {
      Notification({ text: 'User remove couldn’t be updated', type: 'error' });
    } else {
      Notification({ text: 'User successfully removed', type: 'success' });
      history.push('/users');
    }
  };

  const handleRemoveActiveUser = ({ ids }: { ids: string[] }) => {
    RemoveUserModal({
      name: `${user?.uaa_data.profile.data.first_name} ${user?.uaa_data.profile.data.last_name}`,
      onAccept: () => handleRemoveUser(ids),
    });
  };

  const RemoveUserModal = ({ name, onAccept }) => {
    setActionModal({
      title: 'Remove User',
      description: `Are you sure you want to remove ${name}?`,
      onCancel: () => {
        setActionModalOpen(false);
      },
      buttons: [
        { label: 'Cancel', type: 'secondary', onClick: () => setActionModalOpen(false) },
        {
          label: 'Remove user',
          type: 'primary',
          onClick: () => {
            onAccept();
            setActionModalOpen(false);
          },
        },
      ],
    });
  };

  // Actions Modal
  const [actionModalOpen, setActionModalOpen] = useState(false);
  const [actionModalOptions, setActionModalOptions] = useState({
    title: '',
    description: '',
    buttons: [],
    onCancel: null,
  });

  const setActionModal = ({ title, description, buttons, onCancel }) => {
    setActionModalOpen(true);
    setActionModalOptions({
      title: title,
      description: description,
      buttons: buttons,
      onCancel: onCancel,
    });
  };

  useEffect(() => {
    const userTeams = user?.teams?.reduce((acc, team) => {
      const ut = {
        team: team,
        team_id: team.team_id,
        name: team.name,
        is_manager: team.is_manager,
      };
      acc.push(ut);
      return acc;
    }, []);
    setUserTeams(userTeams);
  }, []);

  return (
    <ActionsContainer style={{ gap: '10px' }}>
      {isMobile && (
        <>
          <EditTeamsModal userTeams={userTeams} onTeamsEdited={onTeamsEdited} user={user} />
          <ButtonIcon
            $secondary
            size={'small'}
            icon={'Trash'}
            onClick={() => handleRemoveActiveUser({ ids: [user_invite.id] })}
          />
        </>
      )}
      {isDesktop && (
        <>
          <Space size={10}>
            <Actions>
              {/* Last Active */}
              <HeaderLabel>LAST ACTIVE:</HeaderLabel>
              <LastActive>{lastActiveDate}</LastActive>
            </Actions>
            <EditTeamsModal userTeams={userTeams} onTeamsEdited={onTeamsEdited} user={user} />
            {/* Remove User */}
            <Tooltip placement="bottomRight" title={'Remove user'}>
              <ButtonIcon
                $secondary
                size={'small'}
                icon={'Trash'}
                disabled={user_invite?.role === ACC_OWNER || !can_delete_user || isItself}
                onClick={() => handleRemoveActiveUser({ ids: [user_invite.id] })}
              />
            </Tooltip>
          </Space>
        </>
      )}
      <ActionModal
        title={actionModalOptions.title}
        description={actionModalOptions.description}
        active={actionModalOpen}
        buttons={actionModalOptions.buttons}
        handleCancel={actionModalOptions.onCancel}
      />
    </ActionsContainer>
  );
};

export default HeaderActions;
