import TeamsProvider from 'contexts/teams';
import TeamsContent from './TeamsContent';
import styled from 'styled-components';

const PageContent = styled.div`
  position: relative;
  background: '#F2F2F7';
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Teams = (): JSX.Element => {
  return (
    <TeamsProvider>
      <PageContent>
        <TeamsContent />
      </PageContent>
    </TeamsProvider>
  );
};

export default Teams;
