import { AxiosResponse } from 'axios';
import { config } from 'config/config.local';
import { AccountSummary } from 'types/users';
import { BUSINESS_PYTHON } from './axios';

export interface InvitesData {
  email: string;
  account: AccountSummary;
  team: {
    name: string;
    slug: string;
  };
  is_active: boolean;
}

export interface AlreadyInviteds {
  already_invited: InvitesData[];
}

export const getAlreadyInvited = (emails: string[]): Promise<AxiosResponse<AlreadyInviteds>> => {
  const params = { emails: emails };
  return BUSINESS_PYTHON.post(config.routes.users.getAlreadyInvited, {
    ...params,
  });
};
