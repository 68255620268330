import { AxiosResponse } from 'axios';
import { config } from 'config/config.local';
import { Note, Notes, NotesParams, NotesUsers, NotesUsersParams } from 'contexts/notes/types';
import { BUSINESS_PYTHON } from './axios';

interface GetNotes {
  results: Notes[];
  count: number;
}

interface GetNoteUsers {
  results: NotesUsers[];
}

export interface BasicResponse {
  result?: boolean;
  status?: boolean;
}

export interface CreateNotePayload {
  text: string;
  content: string;
  content_id: string;
}

export const getNotes = (params: NotesParams): Promise<AxiosResponse<GetNotes>> => {
  return BUSINESS_PYTHON.get(config.routes.notes, { params });
};

export const createNote = (data: CreateNotePayload): Promise<AxiosResponse<Note>> => {
  return BUSINESS_PYTHON.post(`${config.routes.notes}`, data);
};

export const editNote = (data: CreateNotePayload, id: string): Promise<AxiosResponse<Note>> => {
  return BUSINESS_PYTHON.put(`${config.routes.notes}${id}/`, data);
};

export const deleteNote = (id: string): Promise<AxiosResponse<Note>> => {
  return BUSINESS_PYTHON.delete(`${config.routes.notes}${id}/`);
};

export const getNotesUsers = (params: NotesUsersParams): Promise<AxiosResponse<GetNoteUsers>> => {
  return BUSINESS_PYTHON.get(`${config.routes.notes}users`, { params });
};
