import EmptySvg from 'assets/images/empty-users.svg';
import { EmptyCustomCard } from 'components/layout/profile/profileContent/styles';

const EmptyTeamUsersCard = (): JSX.Element => (
  <EmptyCustomCard>
    <img src={EmptySvg} />
    <h5>This team hasn’t add any team users yet</h5>
  </EmptyCustomCard>
);

export default EmptyTeamUsersCard;
