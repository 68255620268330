import UsersAsideFilters from 'components/usersAsideFilters';
import UserHeaderFilters from 'components/usersHeaderFilters';
import UsersProvider from 'contexts/users';
import GenericUsersTable from 'components/usersTable/GenericUsersTable';

const Users = (): JSX.Element => {
  return (
    <UsersProvider>
      <div className="mt-2 mt-md-0 mb-mb-18">
        <div className="m-md-0">
          <div className="container-fluid px-0 m-auto mw-100">
            <div className="row pe-0 mw-100 m-auto">
              <div className="col-3 d-none d-md-block ps-0" style={{ maxWidth: '275px' }}>
                <div className="d-flex flex-column justify-content-between bg-white rounded h-100 rounded p-8">
                  <UsersAsideFilters autoApply />
                </div>
              </div>
              <div
                className="col-md-9 p-0 pe-md-0"
                style={{ flexGrow: 1, background: '#F2F2F7', position: 'relative' }}
              >
                <div className={'rounded h-100 rounded p-0 p-md-2 bg-white'}>
                  <UserHeaderFilters />
                  <GenericUsersTable />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </UsersProvider>
  );
};

export default Users;
