import { Input } from 'components/modal/styles';
import { UseSearchBoxProps, useSearchBox } from 'react-instantsearch-hooks';
import { debounce } from 'lodash';

export type SearchBoxProps = UseSearchBoxProps;

export const SearchInput = (props: SearchBoxProps): JSX.Element => {
  const { refine } = useSearchBox(props);

  const handleSearch = debounce((criteria) => {
    refine(criteria);
  }, 350);

  return (
    <div className="ais-SearchBox">
      <Input
        className="ais-SearchBox-input"
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        placeholder={'Search content'}
        spellCheck={false}
        maxLength={512}
        type="search"
        onChange={(event) => handleSearch(event.currentTarget.value)}
      />
    </div>
  );
};
