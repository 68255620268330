import { useContext, useState } from 'react';
import { Space } from 'antd';
import styled from 'styled-components';
import Switch from 'components/switch/Switch';
import { Account } from 'contexts/accounts/types';
import { LicenseAddon } from 'contexts/users/types';
import { SessionContext } from 'contexts/session';
import Tooltip from 'components/tooltip';
import Notification from 'components/notification';
import { assignAddonLicense, unassignAddonLicense } from 'components/usersTable/actions';

const AddonData = styled.div`
  display: flex;
  justify-content: space-between;
  .addon-data__content {
    > h5 {
      margin-bottom: 0;
      font-size: 24px;
      margin-bottom: 10px;
    }
    > p {
      font-size: 14px;
      margin-bottom: 0;
    }
  }
`;

function formatDate(date) {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [month, day, year].join('.');
}

interface UserAddonCardProps {
  onRefreshData?: () => void;
  account: Account;
  license_addons: LicenseAddon[];
  user: any;
}

const UserAddonCard = ({
  onRefreshData,
  account,
  license_addons,
  user,
}: UserAddonCardProps): JSX.Element => {
  const { allows } = useContext(SessionContext);
  const [isLoading, setIsLoading] = useState(false);
  const { can_unnasign_license } = allows;

  const getAddonsSwitchStatus = () => {
    const addonsStatusSwitch = {
      disabled: true,
      tooltip: '',
    };
    if (account.account_available_addons?.length > 0) {
      // If user's account has addons on its plan allow to add them
      if (!user?.license) {
        //If user has no license assigned, check if there are available licenses
        addonsStatusSwitch.disabled = true;
        addonsStatusSwitch.tooltip =
          'User must have a plan license assigned to this user to add Skill Dive';
      } else {
        // If user has a license check if it has addons license assigned
        if (license_addons?.length > 0) {
          // If user has a license addon allow to remove it
          addonsStatusSwitch.disabled = can_unnasign_license ? false : true;
          addonsStatusSwitch.tooltip = can_unnasign_license
            ? 'Remove Skill Dive license'
            : 'Skill Dive licenses are non-transferable after assigning to a user';
        } else if (account.account_available_addons[0].count > 0) {
          // If user has no license addon, check if there are available addons count
          addonsStatusSwitch.disabled = false;
          addonsStatusSwitch.tooltip = can_unnasign_license
            ? 'Assign Skill Dive license'
            : 'Skill Dive licenses are non-transferable after assigning to a user';
        } else {
          addonsStatusSwitch.disabled = true;
          addonsStatusSwitch.tooltip =
            'Your account has no remaining Skill Dive Licenses available. Contact Sales to add additional licenses';
        }
      }
    } else {
      addonsStatusSwitch.disabled = true;
      addonsStatusSwitch.tooltip = 'Contact INE Sales to upgrade to Skill Dive';
    }
    return addonsStatusSwitch;
  };

  const addonsSwitchStatus = getAddonsSwitchStatus();

  const addonsSwitchTooltipWarning = (
    <p className="text-center m-0">{addonsSwitchStatus.tooltip}</p>
  );

  const handleSwitchAddon = async () => {
    setIsLoading(true);
    const is_assigning = !license_addons[0]?.id;
    let response = null;
    if (is_assigning) {
      response = await assignAddonLicense({
        assigned_to: user.id,
        addon: account.account_available_addons[0].id,
      });
    } else {
      response = await unassignAddonLicense({ license_addon_id: license_addons[0].id });
    }
    setIsLoading(false);
    if (!(response instanceof Error) && response) {
      onRefreshData();
      Notification({
        text: `Skill Dive license ${is_assigning ? 'assigned' : 'removed'}`,
        type: 'success',
      });
    } else {
      Notification({
        text: `Skill Dive license couldn’t be ${is_assigning ? 'assigned' : 'removed'}`,
        type: 'error',
      });
    }
  };

  return (
    <Space direction={'vertical'} style={{ width: '100%' }}>
      <AddonData>
        <div className="addon-data__content">
          <h5>Skill Dive License</h5>
          <p>
            {license_addons[0]?.assigned_at
              ? `Assigned: ${formatDate(license_addons[0]?.assigned_at)}`
              : 'No license assigned'}
          </p>
          <p>
            {license_addons[0]?.active_since
              ? `Active since: ${formatDate(license_addons[0]?.active_since)}`
              : 'No license active'}
          </p>
        </div>
        <Tooltip placement="top" destroyTooltipOnHide title={addonsSwitchTooltipWarning}>
          <Switch
            disabled={addonsSwitchStatus.disabled || isLoading}
            defaultChecked={Boolean(license_addons?.length > 0)}
            checked={Boolean(license_addons?.length > 0)}
            onChange={handleSwitchAddon}
          />
        </Tooltip>
      </AddonData>
    </Space>
  );
};

export default UserAddonCard;
