import 'antd/dist/antd.css';
import styled, { css } from 'styled-components';
import { Checkbox } from 'antd';
import checkSVG from 'assets/icons/check.svg';
import { screenMd } from 'constants/styles/breakpoints';

const checkobxWitdh = css`
  min-width: 1.75rem;
  height: 1.75rem;
`;

const antdCheckbox = css`
  ${checkobxWitdh}
  top: 0;
`;

const antdCheckboxAfter = css`
  border: 1px solid ${({ theme }) => theme.default.colors.dataVisualization.purple.base};
  border-radius: 6px;
`;

const antCheckboxInner = css`
  ${checkobxWitdh}
  border: none;
  border-radius: 6px;
  display: none;
`;

const labelSpan = css`
  color: #1a2535;
  font-weight: 400;
  font-size: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  box-sizing: border-box;
`;

const antdCheckboxBackground = css<{ darkBackground: boolean }>`
  background-color: ${({ theme, darkBackground }) =>
    !darkBackground
      ? theme.default.colors.dataVisualization.gray.light
      : theme.default.colors.dataVisualization.gray.dark};
`;

const checkedStyles = css`
  &:checked {
    background-color: ${({ theme }) => theme.default.colors.dataVisualization.purple.base};
    border-color: ${({ theme }) => theme.default.colors.dataVisualization.purple.base};
    position: relative;
    border: none;
    &:after {
      ${checkobxWitdh}
      content: '';
      background-image: url(${checkSVG});
      background-size: 60% 60%;
      background-repeat: no-repeat;
      background-position-x: 4px;
      background-position-y: 5px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 9;
    }
    &:hover:after {
      opacity: 0.5;
    }
  }
`;

const antdCheckboxInput = css`
  ${checkobxWitdh}
  ${antdCheckboxBackground}
	border-radius: 6px;
  background-repeat: no-repeat;
  cursor: pointer;
  opacity: 1;
  appearance: none;
  transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out,
    border 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: 1px solid transparent;
  &:focus-active {
    border: none !important;
    outline: none !important;
  }
  ${screenMd} {
    &:hover {
      ${antdCheckboxAfter}
    }
  }
  &:disabled {
    ${antdCheckboxAfter}
    border-color: transparent;
    cursor: not-allowed;
  }
`;

const antdCheckboxIndeterminateInner = css`
	top: 50%;
	left: 50%;
	width: 8px;
	height: 8px;
	background-color: #a793f6;
	border: 0;
	transform: translate(-50%, -50%) scale(1);
	opacity: 1;
	z-index: 999;
	content: '';
	}
`;

export const StyledCheckbox = styled(Checkbox)<{ darkBackground?: boolean }>`
  &&& {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 1.5rem;
    overflow: hidden;
    cursor: pointer;
    > span:not(.ant-checkbox) {
      ${labelSpan}
    }
    > .ant-checkbox {
      ${antdCheckbox}
      > .ant-checkbox-input {
        ${antdCheckboxInput}
        ${checkedStyles}
      }
      &.ant-checkbox-checked::after {
        ${antdCheckboxAfter}
      }
      > .ant-checkbox-inner {
        ${antCheckboxInner}
      }
      &.ant-checkbox-indeterminate > .ant-checkbox-inner:after {
        ${antdCheckboxIndeterminateInner}
      }
    }
  }
`;

export const StyledCheckboxLabel = styled.span<{ disabled: boolean }>`
  color: ${({ disabled, theme }) =>
    disabled ? theme.default.colors.fonts.lightGray : theme.default.colors.fonts.dark};
`;
