import { IconTeam, TeamIcon } from 'components/teamSelect/IconTeam';
import { PendingLetterAvatar } from 'components/usersTable/styles';
import { colors } from 'constants/styles/colors';
import { DEFAULT_IMG_USERS } from 'constants/urls';
import { UserInvites } from 'contexts/users/types';
import { newPendingcolor } from 'utils/colors';

export const userImagesArray = (usersData: UserInvites[]): JSX.Element[] =>
  usersData?.map(({ avatar, first_name, email }, index) => {
    if (!!avatar && avatar !== DEFAULT_IMG_USERS) {
      return <img key={index + avatar} src={avatar} />;
    } else {
      const color = newPendingcolor();
      return (
        <PendingLetterAvatar color={color}>{first_name?.[0] || email?.[0]}</PendingLetterAvatar>
      );
    }
  });

export const teamsIconsArray = (
  accountTeams: Array<{
    name: string;
    icon_color: keyof typeof colors.dataVisualization;
    icon_name: TeamIcon;
  }>
): JSX.Element[] =>
  accountTeams?.map(({ icon_color, icon_name }, index) => {
    return (
      <IconTeam
        key={index}
        iconName={icon_name as TeamIcon}
        color={icon_color as keyof typeof colors.dataVisualization}
      />
    );
  });
