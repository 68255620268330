// DATES
export const WEEK_RANGE = {
  LABEL: 'WEEK',
  VALUE: 7,
  PERIOD: 'days',
};
export const THIRTY_DAY_RANGE = {
  LABEL: 'MONTH',
  VALUE: 30,
  PERIOD: 'days',
};
export const NINETY_DAY_RANGE = {
  LABEL: '90 DAYS',
  VALUE: 90,
  PERIOD: 'days',
};
export const SIX_MONTH_RANGE = {
  LABEL: '6 MONTH',
  VALUE: 6,
  PERIOD: 'months',
};
export const TWELVE_MONTH_RANGE = {
  LABEL: '12 MONTH',
  VALUE: 12,
  PERIOD: 'months',
};
export const CUSTOM_RANGE = {
  LABEL: 'CUSTOM',
  VALUE: 'custom',
  PERIOD: null,
};
export const DATE_RANGES = [
  WEEK_RANGE,
  THIRTY_DAY_RANGE,
  NINETY_DAY_RANGE,
  SIX_MONTH_RANGE,
  TWELVE_MONTH_RANGE,
  CUSTOM_RANGE,
];
// PERIODICITY
export const DAY_PERIOD = {
  LABEL: 'Daily',
  VALUE: 'day',
};
export const WEEK_PERIOD = {
  LABEL: 'Weekly',
  VALUE: 'week',
};
export const MONTH_PERIOD = {
  LABEL: 'Monthly',
  VALUE: 'month',
};
export const YEAR_PERIOD = {
  LABEL: 'Yearly',
  VALUE: 'year',
};
export const DATE_PERIODS = [DAY_PERIOD, WEEK_PERIOD, MONTH_PERIOD, YEAR_PERIOD];
export const DATE_PERIODS_MAP = {
  [WEEK_RANGE.VALUE]: [DAY_PERIOD.VALUE],
  [THIRTY_DAY_RANGE.VALUE]: [DAY_PERIOD.VALUE, WEEK_PERIOD.VALUE],
  [NINETY_DAY_RANGE.VALUE]: [WEEK_PERIOD.VALUE, MONTH_PERIOD.VALUE],
  [SIX_MONTH_RANGE.VALUE]: [WEEK_PERIOD.VALUE, MONTH_PERIOD.VALUE],
  [TWELVE_MONTH_RANGE.VALUE]: [MONTH_PERIOD.VALUE],
  [CUSTOM_RANGE.VALUE]: [
    DAY_PERIOD.VALUE,
    WEEK_PERIOD.VALUE,
    MONTH_PERIOD.VALUE,
    YEAR_PERIOD.VALUE,
  ],
};
