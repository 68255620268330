import styled from 'styled-components';

export const ContentHeader = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
`;

export const ContentLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

export const ContentDate = styled.div`
  font-size: 14px;
  font-weight: 450;
  color: var(--light-blue-gray);
  min-width: fit-content;
  margin-left: auto;
`;

export const ContentSection = styled.section`
  padding-left: 15px;
  border-left: solid 4px var(--dusty-gray);
  margin-bottom: 10px;
`;

export const ContentItemWrapper = styled.li`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 3px;
  border-top: solid 1px var(--grey-lighter);
  padding: 3px 0 2px;
  list-style: none;
`;

export const ContentName = styled.div`
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  color: var(--light-gray-darker);
`;

export const ContentWrapper = styled.div`
  margin-top: 20px;
  padding-top: 20px;
  border-top: solid 1px var(--dusty-gray);
`;

export const ContentButton = styled.div`
  width: 16px;
  height: 16px;
  margin-left: auto;
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    width: 16px;
    height: 16px;
    color: var(--lilac);
  }
`;

export const EmptyState = styled.div`
  height: 15vh;
  max-height: 130px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ItemSection = styled.div`
  height: 60vh;
  max-height: 250px;
  overflow-y: auto;
  padding-right: 10px;
`;
