import { useContext, useEffect, useState } from 'react';
import { isDesktop, isMobile } from 'react-device-detect';
import FeatherIcon from 'feather-icons-react';
import Button from 'components/button';
import ButtonIcon from 'components/buttonIcon';
import TeamSelect from '../TeamSelect';
import Switch from 'components/switch/Switch';
import { Team, UserTeam } from 'contexts/teams/types';
import Notification from 'components/notification';
import { SessionContext } from 'contexts/session';
import { getInviteLink, getUpdatedInviteLink } from 'services/inviteLink';
import { Container, InviteLinkInput } from './styles';
import { INE_URL } from 'constants/domains';
import { UserTeamsPayload } from 'components/usersTable/types';

export interface InviteByLink {
  assign_license: boolean;
  teams: UserTeamsPayload[];
}

const AddByLink: React.FC<{ handleBackPress?: () => void }> = ({ handleBackPress }) => {
  const [inviteData, setInviteData] = useState<InviteByLink>(null);
  const [invite, setInvite] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userTeams, setUserTeams] = useState<UserTeam[]>([]);
  const [managerSelected, setManagerSelected] = useState<boolean>(false);
  const { licenses_available } = useContext(SessionContext);

  const infiniteLicenses = licenses_available === true;

  const updateInviteData = async () => {
    const linkResponse = await getUpdatedInviteLink({
      assign_license: inviteData?.assign_license || false,
      teams: inviteData?.teams || [],
      inviteId: invite?.id,
    });
    if (linkResponse.status === 201) {
      setInvite(linkResponse.data);
    }
    setLoading(false);
  };

  const getInviteData = async () => {
    const linkResponse = await getInviteLink({
      assign_license: infiniteLicenses || Number(licenses_available) > 0 || false,
      teams: [],
    });
    if (linkResponse.status === 201) {
      setInvite(linkResponse.data);
    }
    setLoading(false);
  };

  const handleSettingsChange = ({ type, value }) => {
    setInviteData((prevState) => {
      return {
        ...prevState,
        [type]: value,
      };
    });
  };

  const handleCopyLink = () => {
    const urlElement = document.createElement('input');
    document.body.appendChild(urlElement);
    urlElement.value = `${INE_URL}/invite/${invite.id}`;
    urlElement.select();
    document.execCommand('copy');
    document.body.removeChild(urlElement);
    Notification({ text: 'Link copied to clipboard successfully', type: 'success' });
  };

  const handleUpdateLicenses = (checked: boolean) => {
    handleSettingsChange({ type: 'assign_license', value: checked });
  };

  const handleTeamChange = (newTeams: Team[]) => {
    setUserTeams(
      newTeams.reduce((acc, team) => {
        acc.push({ team: team, is_manager: managerSelected });
        return acc;
      }, [])
    );
  };

  const handleManagerChange = (checked: boolean) => {
    setManagerSelected(checked);
    const updatedTeams = userTeams.reduce((acc, userTeam) => {
      acc.push({ team_id: userTeam.team.id, is_manager: checked });
      return acc;
    }, []);
    handleSettingsChange({ type: 'teams', value: updatedTeams });
  };

  useEffect(() => {
    setLoading(true);
    if (invite?.id) {
      updateInviteData();
    } else {
      getInviteData();
    }
  }, [inviteData]);

  useEffect(() => {
    if (inviteData?.teams?.length > 0 || userTeams.length > 0) {
      const updatedTeams = userTeams.reduce((acc, userTeam) => {
        acc.push({ team_id: userTeam.team.id, is_manager: managerSelected });
        return acc;
      }, []);
      updatedTeams.length === 0 && setManagerSelected(false);
      handleSettingsChange({ type: 'teams', value: updatedTeams });
    }
  }, [userTeams]);

  return (
    <>
      {isMobile && (
        <FeatherIcon
          icon={'arrow-left'}
          style={{ position: 'absolute', top: 20, left: 10 }}
          color={'#FFF'}
          onClick={handleBackPress}
        />
      )}
      <Container>
        <div className={'invite-link-container'}>
          <InviteLinkInput>
            {invite?.id && `${INE_URL}/invite/${invite.id}`}
            {loading && <FeatherIcon icon="loader" color={'#A793F6'} />}
          </InviteLinkInput>
          <Switch
            label={'License'}
            defaultChecked={infiniteLicenses || Number(licenses_available) > 0}
            style={{ marginLeft: '12px' }}
            disabled={Number(licenses_available) <= 0}
            onChange={(checked: boolean) => handleUpdateLicenses(checked)}
          />
          <ButtonIcon icon={'Copy'} onClick={handleCopyLink} disabled={loading} />
        </div>
        <div className={'invite-link-settigs'}>
          <div className={'invite-link-teams'}>
            <TeamSelect
              teamsSelected={userTeams}
              onTeamChange={handleTeamChange}
              withManagerSwitch={true}
              onSwitchChange={handleManagerChange}
              isManager={managerSelected}
            />
          </div>
        </div>
        {isDesktop && (
          <span className={'licenses-available'}>
            {' '}
            Licenses available: <b>{licenses_available}</b>
          </span>
        )}
        <Button onClick={handleCopyLink} disabled={loading}>
          <FeatherIcon icon="copy" color={'#A793F6'} /> Copy invite link
        </Button>
      </Container>
    </>
  );
};

export default AddByLink;
