import { ActivityLogDataCtx } from './types';

export const activitiesLogInitialValues: ActivityLogDataCtx = {
  fetching: false,
  table: {
    items: [],
    pageSize: 9,
    page: 1,
    loading: false,
    count: 0,
    error: 'false',
  },
  isEmpty: false,
  filters: {
    page_size: '9',
    page: '1',
    from_date: null,
    to_date: null,
    generated_by: null,
    types: null,
    account_id: null,
    append_results: false,
  },
  activityLogUsers: [],
  error: null,
};
