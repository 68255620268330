import { useContext } from 'react';
import InputSelectMultiple from 'components/inputs/inputSelectMultiple';
import { Team } from 'contexts/teams/types';
import { UsersContext } from 'contexts/users';
import { FilterWrapper } from './styles';

const AccountUsersAsideFilterTeams = ({ teams = [] }: { teams: Team[] }): JSX.Element => {
  const { filters, actions } = useContext(UsersContext);

  const handleFilter = (value: string[]) => {
    actions.onChangeFilters(
      {
        name: 'team',
        value: value.toString(),
      },
      false,
      true
    );
  };
  return (
    <>
      <h3 className="mt-16 mb-5">Teams</h3>
      <div>
        <FilterWrapper>
          <InputSelectMultiple
            name="team"
            values={filters.team}
            placeholder={'All'}
            options={teams.map(({ slug, name }) => ({
              key: slug,
              value: slug,
              label: name,
            }))}
            onChange={(value) => {
              handleFilter(value);
            }}
            filterOption={false}
          />
        </FilterWrapper>
      </div>
    </>
  );
};

export default AccountUsersAsideFilterTeams;
