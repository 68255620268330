import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg';
import StatsCardGroup from './StatsCardGroup';
import './accountsStats.scss';

const AccountsStats = (): JSX.Element => {
  return (
    <div className="users-stats px-4 mb-md-4">
      <div className="d-none d-md-block ps-4 pt-8">
        <h2 className="fw-bolder text-white">Accounts stats</h2>
      </div>
      <div className="d-md-none">
        <button
          className="btn px-0 w-100 collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#userStatsCollapse"
          aria-expanded="false"
          aria-controls="userStatsCollapse"
        >
          <div className="d-flex justify-content-between align-items-center">
            <p className="mb-0 fw-bold text-white">Accounts Stats</p>
            <span className="text-white">
              <ChevronDown className="btn-tiny-icon" />
            </span>
          </div>
        </button>
        <div className="collapse" id="userStatsCollapse">
          <StatsCardGroup />
        </div>
      </div>
      <div className="d-none d-md-block">
        <StatsCardGroup />
      </div>
    </div>
  );
};

export default AccountsStats;
