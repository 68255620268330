import { format } from 'date-fns';
import { MIGRATED_NOTICE_HEIGHT, MIGRATED_NOTICE_HEIGHT_MOB } from 'constants/settings';
import { isMobile } from 'react-device-detect';

function pad(num: number): string {
  return `0${num}`.slice(-2);
}

export function hhmmss(secs: number): string {
  let minutes = Math.floor(secs / 60);
  secs %= 60;
  const hours = Math.floor(minutes / 60);
  minutes %= 60;
  return `${hours}:${pad(minutes)}:${pad(secs)}`;
}

export function durationOn(totalDuration: string, type: string): string {
  const colon = totalDuration.split(':');
  let seconds = +colon[0] * 60 * 60 + +colon[1] * 60 + +colon[2];
  seconds = Number(seconds);
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor((seconds % 3600) % 60);
  const hDisplay = h > 0 ? h + 'h ' : '';
  const hoursDisplay = h > 0 ? h + (h === 1 ? ' hour ' : ' hours ') : '';
  const mDisplay = m > 0 ? m + 'm ' : '';
  const minutesDisplay = m > 0 ? m + (m === 1 ? ' minute ' : ' minutes ') : '';
  const sDisplay = s > 0 ? s + 's' : '';
  if (type === 'hours') {
    return hDisplay;
  }
  if (type === 'minutes') {
    return hDisplay + mDisplay;
  }
  if (type === 'seconds') {
    return hDisplay + mDisplay + sDisplay;
  }
  if (type === 'zeros') {
    return `${hDisplay || '0h'}${mDisplay || '0m'}`.replace(/ /g, '');
  }
  if (type === 'HOURSMIN') {
    return `${hoursDisplay || ''}${minutesDisplay || ''}`;
  }
  if (type === 'hhmm') {
    return `${hDisplay || ''}${mDisplay || ''}`;
  }
  return hDisplay + mDisplay + sDisplay;
}

export function durationFromSecs(sec: number, type = ''): string {
  return durationOn(hhmmss(sec), type);
}

export const capitalize = (str = ''): string => str.charAt(0).toUpperCase() + str.slice(1);

export const camelize = (str = ''): string =>
  str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return '';
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });

export const formatDateInUtc = (date: Date, pattern: string): string => {
  return format(new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000), pattern);
};

export const stripHtml = (html: string) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  const newParserText = doc.body.textContent.replace(/[\n\r]+/g, ' ') || '';
  return newParserText.trim();
};

export const validUUID = (id: string): boolean => {
  const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return regex.test(id);
};

export const getLearningAreaName = (content: any): string => {
  const defaultLearningArea = 'INE';
  if (!content || !content.learning_areas) {
    return defaultLearningArea;
  }
  return content?.learning_areas[0]?.name ?? defaultLearningArea;
};

export const getLearningAreaColor = (item: any): string => {
  return `${getLearningAreaSnipeColor(getLearningAreaName(item))}`;
};

export const getLearningAreaSnipeColor = (area: string): string => {
  const colorMap = {
    Networking: '#FAD740',
    'Data Science': '#66C19A',
    'Cyber Security': '#BB85FF',
    Cloud: '#88A9FF',
    Development: '#D73F47',
    'IT Essentials': '#F647C0',
  };
  return colorMap[area] || colorMap['IT Essentials'];
};

export const getInstructorName = (content: any): string => {
  const multipleInstructors = content?.instructors?.length;
  const defaultName = 'INE Instructor';
  if (multipleInstructors) {
    return content?.instructors[0]?.name ?? defaultName;
  }
  return content?.instructor?.name ?? defaultName;
};

export const getAmountOfInstructors = (content: any): number => {
  return content?.instructors?.length ?? 0;
};

export const getVendorName = (content: any): string => {
  const { vendor, vendors } = content;
  if (vendor && typeof vendor === 'string') {
    return vendor;
  }
  if (vendor && vendor.name) {
    return vendor.name;
  }
  if (vendors && vendors.length) {
    return vendors[0].name;
  }
  return 'INE';
};

export const topBarHeight = (user: any): number => {
  return user?.should_show_migration_info
    ? isMobile
      ? MIGRATED_NOTICE_HEIGHT_MOB
      : MIGRATED_NOTICE_HEIGHT
    : 0;
};
