import styled from 'styled-components';
import { useContext, useState } from 'react';
import { screenMd } from 'constants/styles/breakpoints';
import { ReactComponent as BusinessContentsvg } from 'assets/images/business-content.svg';
import Button from 'components/button/Button';
import { SessionContext } from 'contexts/session';
import InviteUsers from 'pages/Users/components/InviteUsers';
import { Invite } from 'pages/Users/components/InviteUsers/AddByEmails/invitesReducer';

const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  padding: 20px 10px;
  svg {
    height: auto;
    max-width: 100%;
  }
  ${screenMd} {
    margin: auto;
    svg {
      height: auto;
      max-width: 45%;
    }
  }
`;
const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  ${screenMd} {
    font-size: 32px;
    line-height: 38px;
  }
`;
const SubTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  ${screenMd} {
    font-size: 20px;
    line-height: 26px;
  }
`;

export const ActionButton = styled.div`
  margin-top: 10px;

  ${screenMd} {
    margin-top: 70px;
  }
`;

const AnalyticsEmpty = (): JSX.Element => {
  const [isInvitingUsers, setIsInvitingUsers] = useState(false);
  const { account, actions: sessionActions } = useContext(SessionContext);

  const handleInviteClose = (result?: string, invites?: Invite[]) => {
    setIsInvitingUsers(false);
    if (result === 'ok' && invites) {
      sessionActions.refreshSession();
    }
  };
  return (
    <Content>
      <BusinessContentsvg />
      <Title>You haven’t tracked your activity yet!</Title>
      <SubTitle>To track activity, invite your teammates.</SubTitle>
      <ActionButton>
        <Button
          $primary={true}
          style={{ paddingLeft: 30, paddingRight: 30 }}
          onClick={() => setIsInvitingUsers(true)}
          loading={isInvitingUsers}
          icon={'Plus'}
        >
          Add users
        </Button>
      </ActionButton>
      {isInvitingUsers && (
        <InviteUsers
          account={account}
          isEditingInvite={isInvitingUsers}
          onClose={handleInviteClose}
        />
      )}
    </Content>
  );
};

export default AnalyticsEmpty;
