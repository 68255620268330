import React from 'react';
import { CheckboxPropTypes } from './types';
import { StyledCheckbox, StyledCheckboxLabel } from './styles';
const Checkbox = ({
  checked,
  disabled,
  onChange,
  label,
  controlled,
  darkBackground,
  ...rest
}: CheckboxPropTypes): JSX.Element => {
  const checkboxProps = controlled ? { checked: checked } : {};

  return (
    <StyledCheckbox
      onChange={onChange}
      disabled={disabled}
      {...checkboxProps}
      {...rest}
      darkBackground={darkBackground}
    >
      <StyledCheckboxLabel disabled={disabled}>{label}</StyledCheckboxLabel>
    </StyledCheckbox>
  );
};

export default Checkbox;
