import { Col, Row } from 'antd';
import { format } from 'date-fns';
import Icon from 'components/icons';
import { colors } from 'constants/styles/colors';
import { Note } from 'contexts/notes/types';
import { NoteDataItemMobile, NoteListItemHeader, NoteListItemText } from '../styles';

interface INoteListItem {
  note: Note;
  onShowNoteDetail: (note: Note) => void;
}

const NoteListItemMobile = ({ note, onShowNoteDetail }: INoteListItem): JSX.Element => {
  const handleShow = () => {
    onShowNoteDetail(note);
  };
  return (
    <NoteDataItemMobile onClick={handleShow}>
      <Row>
        <Col span={22}>
          <NoteListItemHeader>
            <strong>{note.created_by}</strong>
            <span> | {format(new Date(note.created_at), 'p - yyyy/MM/dd')}</span>
          </NoteListItemHeader>
          <NoteListItemText>{note.text}</NoteListItemText>
        </Col>
        <Col span={2}>
          <Icon
            icon="ChevronRight"
            size="small"
            color={colors.dataVisualization.purple.base}
            style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)' }}
          />
        </Col>
      </Row>
    </NoteDataItemMobile>
  );
};

export default NoteListItemMobile;
