import styled, { keyframes } from 'styled-components';
import { screenMd } from 'constants/styles/breakpoints';

const spin = keyframes`
0% { 
    transform:rotate(360deg); 
}
`;

export const Container = styled.div`
  padding: 30px 10px 0px 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  ${screenMd} {
    padding: 20px 0px 0px 0px;
  }
  > .invite-link-container {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    > button {
      margin-left: 12px;
    }
  }
  > .invite-link-settigs {
    display: flex;
    width: 100%;
    > .invite-link-teams {
      flex-grow: 1;
      ${screenMd} {
        max-width: none;
      }
    }
    > .invite-settings-switchs {
      display: flex;
      align-items: end;
      justify-content: end;
      padding-bottom: 3.5px;
      > div {
        margin-left: 15px;
      }
    }
  }
  > .licenses-available {
    font-size: 14px;
    color: #57575c;
    margin-top: 8px;
  }
  > button {
    margin-top: auto;
    align-self: end !important;
    ${screenMd} {
      margin-top: 20px;
    }
  }
`;

export const InviteLinkInput = styled.div`
  border-radius: 10px;
  height: 58px;
  padding: 10px;
  font-size: 12px;
  background: ${({ theme }) => theme.default.colors.palette.Business_LightThemeGreyLighter};
  flex: 1;
  display: flex;
  align-items: center;
  word-break: break-word;
  ${screenMd} {
    width: 100%;
    height: 44px;
    line-height: 15px;
  }
  > svg {
    margin-left: auto;
    min-width: 25px;
    animation: ${spin} 2s infinite linear;
  }
`;
