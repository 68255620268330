/**
 * `Sticky Footer`
 * @param {ReactNode} children optionals components.
 * @returns {JSX.Element} Footer Component.
 */

import { ReactNode } from 'react';
import { StickyFooterContainer } from './styles';

type Props = { children?: ReactNode };

const StickyFooter = ({ children }: Props): JSX.Element => (
  <StickyFooterContainer>{children}</StickyFooterContainer>
);

export default StickyFooter;
