import { UserDetail } from 'types/users';
import AvatarsCount from 'components/avatarsCount';
import { getAvatarItems } from 'utils/colors';
import { memo } from 'react';
import { EmptyCell } from 'components/Table';

interface ItemTeamProps {
  data: UserDetail[];
}

const Manager = (props: ItemTeamProps): JSX.Element => {
  const { data } = props;

  return (
    <>{data?.length > 0 ? <AvatarsCount items={getAvatarItems(data)} more={0} /> : <EmptyCell />}</>
  );
};

export default memo(Manager);
