import { FC } from 'react';
import { CustomCard } from '../../AccountUsersCard/AccountUsersCard';
import { ToggleContainer } from '../styles';
import { Account } from 'contexts/accounts/types';
import { Title } from '../../AccountsHeader/styles';
import Switch from 'components/switch';
import { ToggleSwitchWrapper, ToggleWrapper } from '../styles';

interface IAccountSonarStaffCard {
  account: Account;
  sonarToggled(value: boolean): void;
}

const AccountSonarStaffCard: FC<IAccountSonarStaffCard> = ({ account, sonarToggled }) => {
  return (
    <CustomCard dashboard={true}>
      <ToggleContainer>
        <ToggleWrapper>
          <Title>Sonar</Title>
          <ToggleSwitchWrapper>
            <Switch
              checked={account?.sonar_enabled}
              onChange={(e) => sonarToggled(e)}
              style={{ marginRight: '10px' }}
            />
          </ToggleSwitchWrapper>
        </ToggleWrapper>
      </ToggleContainer>
    </CustomCard>
  );
};

export default AccountSonarStaffCard;
