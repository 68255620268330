import { Col, Row } from 'antd';
import { addDays, format, isBefore, set, subDays } from 'date-fns';
import { Label } from 'components/modal/styles';
import DatePicker from 'components/datepicker';
import Icon from 'components/icons';
import { DATE_FORMAT, PERIOD_SINCE, PERIOD_TO } from 'constants/accounts';

const TIME = { hours: 0, minutes: 0, seconds: 0 };

function disableBeforeToday(date) {
  return isBefore(new Date(date), subDays(new Date(), 1));
}

function disableSameOrBefore(dateCalendar, dateReference) {
  return isBefore(new Date(dateCalendar), addDays(new Date(dateReference), 1));
}

interface AccountDatesProps {
  dateSince?: string;
  dateTo?: string;
  onStartChange(value: string): void;
  onEndChange(value: string): void;
  error?: { since: boolean; to: boolean };
}

const AccountDates: React.FC<AccountDatesProps> = ({
  dateSince,
  dateTo,
  onEndChange,
  onStartChange,
  error,
}) => {
  const handleDateChenge = (period, value) => {
    const selectedDate = value ? format(set(value, TIME), "yyyy-MM-dd'T'HH:mm:ss.SSS") : null;
    period === PERIOD_SINCE ? onStartChange(`${selectedDate}Z`) : onEndChange(`${selectedDate}Z`);
  };
  return (
    <Row>
      <Col span={24}>
        <Label>Start/End date</Label>
      </Col>
      <Col span={24}>
        <Row gutter={[20, 20]}>
          <Col span={12}>
            <DatePicker
              error={error.since}
              disabledDate={disableBeforeToday}
              style={{ width: '100%' }}
              placeholder={DATE_FORMAT.toLowerCase()}
              isForm
              suffixIcon={<Icon icon="Calendar" size="small" />}
              defaultValue={dateSince ? addDays(new Date(dateSince), 1) : null}
              onChange={(dateString) => handleDateChenge(PERIOD_SINCE, dateString)}
            />
          </Col>
          <Col span={12}>
            <DatePicker
              error={error.to}
              disabled={!dateSince}
              disabledDate={(dateCalendar) => disableSameOrBefore(dateCalendar, dateSince)}
              style={{ width: '100%' }}
              placeholder={DATE_FORMAT.toLowerCase()}
              isForm
              suffixIcon={<Icon icon="Calendar" size="small" />}
              defaultValue={dateTo ? addDays(new Date(dateTo), 1) : null}
              onChange={(dateString) => handleDateChenge(PERIOD_TO, dateString)}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AccountDates;
