import ButtonIcon from 'components/buttonIcon';
import styled from 'styled-components';
import './statics.scss';

const Container = styled.div`
  display: flex;
  background: #d3c9fa;
  border-radius: 5px;
  padding: 2px;
  grid-gap: 2px;
  &:hover {
    background: #c4b7f9;
    .slide-buttons-group__button--active {
      background: #f5f8fa !important;
    }
  }
`;

const SlideButtonGroup = ({ action, options, current }): JSX.Element => {
  const activeClass = (chartType: string) => {
    return current === chartType ? 'slide-buttons-group__button--active' : '';
  };

  return (
    <Container>
      {options.map(({ icon, value }) => {
        return (
          <ButtonIcon
            size="small"
            key={`type-${value}`}
            className={`slide-buttons-group__button ${activeClass(value)}`}
            icon={icon}
            onClick={() => {
              action(value);
            }}
          />
        );
      })}
    </Container>
  );
};
export default SlideButtonGroup;
