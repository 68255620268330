import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from 'constants/styles/colors';
import { Content } from 'pages/Analytics/components/ActiveUsersCollapse/styles';

export const ActivityLogFiltersContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ActivityLogListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 400px;
  overflow-y: scroll;
`;

export const ActivityNavLink = styled(NavLink)`
  color: ${colors.dataVisualization.purple.dark};
  fontweight: 700;
  overflow: hidden;
  textoverflow: ellipsis;
`;

export const ActivityMailTo = styled.a`
  color: ${colors.dataVisualization.purple.dark};
  fontweight: 700;
  overflow: hidden;
  textoverflow: ellipsis;
`;

export const ActivityUserLabel = styled.span<{ active?: boolean }>`
  color: ${({ active }) =>
    !active ? colors.dataVisualization.black.light : colors.dataVisualization.purple.dark};
  fontweight: 700;
  overflow: hidden;
  textoverflow: ellipsis;
`;

export const ActivityLogItemRow = styled.div`
  display: grid;
  grid-template-columns: 100px 25px 2fr;
  padding: 5px;
`;

export const TimelineDot = styled.div`
  box-sizing: border-box;
  width: 13.66px;
  height: 13.5px;
  left: 82.49px;
  top: 164.71px;
  border: 3px solid #a793f6;
  border-radius: 10px;
  margin: 5px auto;
`;

export const TimelineTail = styled.div`
  width: 3px;
  height: 50%;
  background: ${colors.dataVisualization.gray.light};
  border-radius: 8px;
  margin: auto;
`;

export const ItemDateContainer = styled.div`
  font-size: 12px;
  line-heigth: 14px;
  text-align: right;
  margin-top: 2px;
`;

export const ItemDateLabel = styled.div`
  color: ${colors.dataVisualization.black.light};
  font-weight: 700;
`;

export const ItemTimeLabel = styled.div`
  color: ${colors.fonts.darkGray};
  font-weight: 450;
`;

export const ActivityLogContainerMobile = styled(Content)`
  display: grid;
  gap: 10px;
  overflow-y: auto;
`;

export const ActivityLogItemRowMobile = styled.div`
  display: grid;
  grid-template-columns: 55px 25px 2fr;
  padding: 5px;
`;

export const ActivityLogDrawerContainer = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  height: ${window.innerHeight - 190}px;
`;

export const ActivityLogMobileTitle = styled.h2`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
`;

export const ActivityLogMobileContentTitle = styled.h2`
  font-size: 20px;
  line-height: 20px;
`;

export const NoMoreActivitiesIconContainer = styled.div`
  width: 36px;
  height: 36px;
  box-sizing: border-box;
  background: linear-gradient(
    112.2deg,
    rgba(187, 188, 199, 0.4) -46.39%,
    rgba(187, 188, 199, 0) 112.38%
  );
  border-radius: 100%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NoMoreActivitiesContainer = styled.div`
  margin-top: 15px;
  border-top: solid 1px #f2f2f7;
  text-align: center;
  display: grid;
  justify-items: center;

  span {
    margin-top: 5px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 450;
  }
`;
