import { useContext, useState, FormEvent, useEffect } from 'react';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { AccountsContext } from 'contexts/accounts';

const SearchFilter = (): JSX.Element => {
  const { actions, filters } = useContext(AccountsContext);
  const [value, setValue] = useState(filters.search);

  useEffect(() => {
    setValue(filters.search);
  }, [filters.search]);

  const onInput = (e: FormEvent<HTMLInputElement>): void => {
    setValue(e.currentTarget.value);
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (value !== filters.search) {
      actions && actions.onChangeFilters({ name: 'search', value: value });
      actions.clearSelectedItems();
    }
  };

  return (
    <form
      className="col input-group input-group-solid align-items-center ps-4 rounded"
      onSubmit={onSubmit}
      style={{ backgroundColor: '#F2F2F7' }}
    >
      <SearchIcon />
      <input
        type="text"
        className="form-control"
        placeholder="Search"
        aria-label="search user"
        aria-describedby="basic-search"
        onChange={onInput}
        value={value}
      />
    </form>
  );
};

export default SearchFilter;
