import { useContext, useState } from 'react';
import styled from 'styled-components';
import Button from 'components/button/Button';
import { screenMd } from 'constants/styles/breakpoints';
import EmptyStateSvg from '../../assets/teams_emptyState.svg';
import { DeepTeams, Team } from 'contexts/teams/types';
import { SessionContext } from 'contexts/session';
import useManageTeam from '../hooks/useManageTeam';
import { TeamsContext } from 'contexts/teams';
import useInviteToTeam from '../hooks/useInviteToTeam';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px auto 40px auto;
  padding-bottom: 100px;
  ${screenMd} {
    margin: 20px auto;
  }
  > h4 {
    font-size: 16px;
    text-align: center;
    ${screenMd} {
      font-size: 24px;
    }
  }
  > p {
    font-size: 14px;
    text-align: center;
    ${screenMd} {
      font-size: 18px;
    }
  }
`;

export const EmptyStateImg = styled.img`
  width: 90vw;
  ${screenMd} {
    width: 700px;
  }
`;

export const ActionButton = styled.div`
  position: fixed;
  bottom: 80px;
  left: 1rem;
  right: 1rem;
  z-index: 900 !important;
  button {
    width: 100%;
  }

  ${screenMd} {
    position: static;
    bottom: auto;
    left: auto;
    right: auto;
    z-index: 0 !important;
    .button {
      width: auto;
    }
  }
`;

const TeamsEmpty: React.FC = (): JSX.Element => {
  const [isCreatingTeam, setIsCreatingTeam] = useState(false);
  const { account, actions: sessionActions, licenses_available } = useContext(SessionContext);
  const { actions: teamsTableActions } = useContext(TeamsContext);
  const { manageTeamModal, openManageTeamModal, closeManageTeamModal } = useManageTeam();
  const { inviteToTeamModal, openInviteToTeamModal, closeInviteToTeamModal } = useInviteToTeam();

  const handleCloseCreateTeam = (teamData) => {
    setIsCreatingTeam(false);
    if (teamData) {
      sessionActions.updateAccountTeams((prev) => {
        const editedIndex = prev.findIndex(({ id }) => id === teamData.id);
        prev[editedIndex] = teamData;
        return [...prev];
      });
      handleInviteToTeam(teamData);
      teamsTableActions.onForceTeamsRequest();
      sessionActions.refreshTeams();
    }
    closeManageTeamModal();
  };

  const handleCreateTeam = () => {
    setIsCreatingTeam(true);
    openManageTeamModal({
      onClose: handleCloseCreateTeam,
      team: null,
    });
  };

  const handleInviteToTeam = (team: Team | DeepTeams) => {
    const handleInviteFinish = (afterInvite) => {
      if (afterInvite) {
        if (!licenses_available) {
          sessionActions.refreshSession();
        }
        teamsTableActions.onForceTeamsRequest();
      }
      closeInviteToTeamModal();
    };
    openInviteToTeamModal({
      onClose: handleInviteFinish,
      team: team,
      account: account,
      isInvite: false,
    });
  };

  return (
    <>
      <Container>
        <EmptyStateImg src={EmptyStateSvg} />
        <h4>You haven’t created any team yet</h4>
        <p>Create your first team, invite people and start tracking activity!</p>
        <br />
        <ActionButton>
          <Button
            $primary={true}
            style={{ paddingLeft: 30, paddingRight: 30 }}
            onClick={handleCreateTeam}
            loading={isCreatingTeam}
            icon={'Plus'}
          >
            Create team
          </Button>
        </ActionButton>
        {manageTeamModal}
        {inviteToTeamModal}
      </Container>
    </>
  );
};

export default TeamsEmpty;
