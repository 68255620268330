import { SectionUnderConstruction, UnderConstruction, UnderConstructionState } from './styles';
import underConstruction from '../../../../assets/images/underConstruction.png';

type Props = {
  entity: string;
};

const UnderConstructionTable = ({ entity }: Props): JSX.Element => {
  return (
    <UnderConstruction>
      <UnderConstructionState src={underConstruction} />
      <SectionUnderConstruction>
        <h1>Under construction</h1>
        <p>
          We’re working hard to bring you new & improved <span>{entity}</span> analytics. Check back
          soon for updates. We appreciate your patience!
        </p>
      </SectionUnderConstruction>
    </UnderConstruction>
  );
};

export default UnderConstructionTable;
