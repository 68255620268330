import { AxiosResponse } from 'axios';
import { config } from 'config/config.local';
import { UserProfileData } from 'pages/Users/types';
import { BUSINESS_PYTHON } from './axios';

export const getUserProfile = (id: string): Promise<AxiosResponse<UserProfileData>> => {
  return BUSINESS_PYTHON.get(
    `${config.routes.users.users}${id}/${config.routes.users.userProfile}`
  );
};
