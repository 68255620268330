import { FC, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import InputSelect from 'components/inputs/inputSelect';
import { Card } from 'components/layout/profile/profileContent/styles';
import { Spinner } from 'components/loadings';
import { WEEK_PERIOD } from 'constants/filters';
import { DEFAULT_IMG_USERS } from 'constants/urls';
import { getAgregationAction, getUsersTableAction } from 'contexts/analytics/actions';
import { SessionContext } from 'contexts/session';
import {
  DataContainer,
  DataDescription,
  DataNumber,
  IconWrapper,
  TabButton,
  TabIcon,
  TabWrapper,
  TabsContainer,
} from 'pages/Analytics/components/MetricTabs/styles';
import { CardHeaderSection } from '../TeamSharedStyles';
import { TOP_USER_ACTIVITY_TABS } from './configs';
import { ChartNoData, ContainerMobile, GraphContainter } from './styles';
import TeamTopUsersChart from './TeamTopUserChart';
import { TeamActivitiesTabs } from './types';
import { AxisTickData } from '../../../../components/charts/types';
import Notification from 'components/notification';
import { durationFromSecs } from 'utils/helpers';

const TeamTopUsersAnalytics: FC = () => {
  const [counters, setCounters] = useState(null);
  const [graphData, setGraphData] = useState(null);
  const [fetching, setFetching] = useState(true);
  const [selectedTab, setSelectedTab] = useState<TeamActivitiesTabs>(TOP_USER_ACTIVITY_TABS[0]);
  const { user, account } = useContext(SessionContext);
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    updateTabsData();
  }, []);

  useEffect(() => {
    getGraphsUsersData();
  }, [selectedTab]);

  const getTabData = async ({ item }) => {
    const current = TOP_USER_ACTIVITY_TABS.find(({ item: thisTab }) => thisTab === item);
    const params = {
      period: WEEK_PERIOD.VALUE,
      date_to: undefined,
      date_from: undefined,
      user_id: user.uaa_id,
      account_id: account?.id,
      team_ids: id,
      actions: current.actions,
      external_action: current.counter_external_actions
        ? current.counter_external_actions
        : current.external_actions,
      metadata: current.metadata,
    };
    return await getAgregationAction(params);
  };

  const updateTabsData = async () => {
    TOP_USER_ACTIVITY_TABS.map(async ({ item }) => {
      try {
        const response = await getTabData({ item });
        setCounters((prevState) => {
          if (response instanceof Error) {
            return { ...prevState, [item]: '-' };
          } else {
            return {
              ...prevState,
              [item]: typeof response.results === 'number' ? response.results : '-',
            };
          }
        });
      } catch (error) {
        setCounters((prevState) => ({
          ...prevState,
          [item]: '-',
        }));
      }
    });
  };

  const getGraphsUsersData = async () => {
    setFetching(true);
    const params = {
      period: WEEK_PERIOD.VALUE,
      date_to: undefined,
      date_from: undefined,
      user_id: user.uaa_id,
      account_id: account?.id,
      team_ids: id,
      actions: selectedTab.actions,
      column_external_action: selectedTab.external_actions,
      page: 1,
      page_size: 99999, //Get all in order to process top five users
      ordering: undefined, // TODO: set ordering when backend support is ready
      column1: selectedTab.actions,
      column2: selectedTab.actions, // setting 2 columns to make service work (column1/2 always required)
    };
    try {
      const response = await getUsersTableAction(params);
      if (response instanceof Error) {
        setFetching(false);
        Notification({ text: `Error retrieving ${selectedTab.label}`, type: 'error' });
      } else {
        const processedData = response.results?.data
          ?.sort((a, b) => b.column1 - a.column2)
          .slice(0, 5);
        const newGraphData = buildGraphData(processedData);
        setGraphData(newGraphData);
        setFetching(false);
      }
    } catch (error) {
      setFetching(false);
      Notification({ text: `Error retrieving ${selectedTab.label}`, type: 'error' });
    }
  };

  const getGraphData = ({ user, column1 }) => {
    const image = user?.avatar !== DEFAULT_IMG_USERS ? user?.avatar : user.email;
    const email = user.email;
    const name = `${user.first_name} ${user.last_name}`;
    const itemData = column1;
    const itemId = selectedTab.item;
    const renderData = JSON.stringify({
      image,
      email,
      name,
      itemData,
      itemId,
    });
    return {
      image,
      email,
      name,
      itemData,
      itemId,
      renderData,
    };
  };

  const buildGraphData = (topUsersData) => {
    let graphDataWithRender = {} as AxisTickData;
    return topUsersData.map((topUsersData) => {
      graphDataWithRender = getGraphData(topUsersData) as AxisTickData;
      return graphDataWithRender;
    });
  };

  const handleSelectTab = (item) => {
    const tab = TOP_USER_ACTIVITY_TABS.find(({ item: thisTab }) => thisTab === item);
    setSelectedTab(tab);
    setGraphData(null);
  };

  const usersWithZeroProgress = graphData?.every(({ itemData }) => itemData === 0);

  const mobileOptions = TOP_USER_ACTIVITY_TABS.map(({ icon, label, item }) => {
    return {
      value: item,
      label: `${counters?.[item] ?? '-'} ${label}`,
      icon: icon,
    };
  });

  const dataNumberText = (type) => {
    if (type === 'VIDEOS_WATCHED') {
      return (counters && durationFromSecs(counters[type] * 60, 'zeros').replace('h', 'h ')) ?? '-';
    } else {
      return counters?.[type] ?? '-';
    }
  };

  return (
    <Card>
      <CardHeaderSection>
        <h5>Top users</h5>
      </CardHeaderSection>
      <TabsContainer>
        {
          <ContainerMobile>
            <InputSelect
              value={`${counters?.[selectedTab.item] ?? '-'} ${selectedTab.label}`}
              icon={selectedTab.icon}
              options={mobileOptions}
              onChange={(selectValue) => handleSelectTab(selectValue)}
            />
          </ContainerMobile>
        }

        {TOP_USER_ACTIVITY_TABS.map(({ icon, label, item }) => (
          <TabButton
            key={item}
            active={item === selectedTab?.item}
            onClick={() => handleSelectTab(item)}
          >
            <TabWrapper>
              <IconWrapper>{<TabIcon src={icon} />}</IconWrapper>
              <DataContainer>
                <DataNumber>{dataNumberText(item)}</DataNumber>
                <DataDescription>{label}</DataDescription>
              </DataContainer>
            </TabWrapper>
          </TabButton>
        ))}
      </TabsContainer>
      <GraphContainter>
        {fetching ? (
          <Spinner active top={'50%'} />
        ) : graphData && !usersWithZeroProgress ? (
          <TeamTopUsersChart tickImage={true} data={graphData} />
        ) : (
          <ChartNoData>No data</ChartNoData>
        )}
      </GraphContainter>
    </Card>
  );
};

export default TeamTopUsersAnalytics;
