import React from 'react';
import { ConfirmModal } from 'components/modal';

interface RemoveBookingModalProps {
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
}

const RemoveBookingModal: React.FC<RemoveBookingModalProps> = ({ visible, onOk, onCancel }) => {
  const RemoveBookingModalState = {
    open: visible,
    onAccept: onOk,
    acceptLabel: 'Remove booking',
    cancelLabel: 'Cancel',
    title: 'Remove booking',
    description: `Are you sure you want to remove this booking?`,
    onCancel,
  };

  return <ConfirmModal {...RemoveBookingModalState} />;
};

export default RemoveBookingModal;
