import styled from 'styled-components';
import { screenXs, screenSm, screenMd } from 'constants/styles/breakpoints';

export const Container = styled.div<{ loading?: boolean }>`
  padding: 20px 10px;
  background: white;
  padding-bottom: 20px;
  ${({ loading }) =>
    loading &&
    `
    height: 50vh;
  `}
  button {
    svg {
      stroke: none !important;
    }
  }
  ${screenXs} {
    border-radius: 10px;
    padding: 30px;
    height: unset;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HeaderCtaContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 95px;
  button {
    width: 43px !important;
    height: 43px !important;
  }
`;

export const Title = styled.div`
  font-family: 'Circular-Std';
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;

  ${screenMd} {
    padding-left: 5px;
    padding-left: unset;
  }
`;

export const Filename = styled.div`
  font-family: 'Circular-Std';
  font-size: 14px;
  font-weight: 700;
  line-height: 32px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 97%;
`;

export const Table = {
  Container: styled.table`
    display: flex;
    flex-direction: column;
    width: 100%;

    ${screenMd} {
      padding-top: 22px;
    }
  `,
  Thead: styled.thead`
    display: none;

    ${screenMd} {
      display: table;
      position: sticky;
      top: 120px;
      z-index: 1;
    }
  `,
  Tbody: styled.tbody`
    ${screenMd} {
      tr {
        height: 80px;
      }
    }
  `,
  Loading: styled.tr`
    height: unset;
    ${screenXs} {
      display: grid;
      grid-template-columns: 100%;
      img {
        position: relative;
        left: 50%;
        margin-top: 50px;
        top: 0;
      }
    }
  `,
  Tr: styled.tr<{ borderBottom?: boolean; hidden?: boolean; isTablet?: boolean }>`
    display: grid;
    grid-template-columns: 73% 14% 9%;
    padding: 15px 0;
    button {
      width: 43px !important;
      height: 43px !important;
    }
    ${({ hidden }) =>
      hidden &&
      `
      display: none;
    `}
    ${screenXs} {
      grid-template-columns: 80% 10% 10%;
    }
    ${screenSm} {
      grid-template-columns: 87.5% 7% 5%;
    }
    ${screenMd} {
      display: grid;
      grid-template-columns: 31% 9% 15% 16% 19% 10%;
      padding: 0;
      ${({ borderBottom }) =>
        borderBottom &&
        `
        border-bottom: solid 1px #F2F2F7;
      `}
      ${({ isTablet }) =>
        isTablet &&
        `
        grid-template-columns: 88% 6% 5%;
      `}
    }
  `,
  Th: styled.th<{ withSort?: boolean }>`
    display: flex;
    align-items: center;
    background-color: #ffffff;
    height: 45px;
    ${({ withSort }) =>
      withSort &&
      `
      button {
        margin-left: 5px;
        width: 26px !important;
        height: 26px !important;
        padding: 0px 5px !important;
      }
    `}
    ${screenMd} {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  `,
  Td: styled.td<{
    hiddenMd?: boolean;
    centerContent?: boolean;
    hidden?: boolean;
    hiddenSm?: boolean;
  }>`
    ${({ centerContent }) =>
      centerContent &&
      `
      text-align: center;
    `}
    ${({ hidden }) =>
      hidden &&
      `
      display: none;
    `}

    ${screenXs} {
      ${({ hiddenSm }) => {
        return hiddenSm ? 'display: none;' : 'display: block;';
      }}
    }
    ${screenMd} {
      ${({ hiddenMd }) => {
        return hiddenMd ? 'display: none;' : 'display: block;';
      }}
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      align-self: center;
    }
  `,
};

export const StatusContainer = styled.div<{ hiddenSm?: boolean }>`
  display: flex;
  ${screenMd} {
    ${({ hiddenSm }) =>
      hiddenSm &&
      `
      display: none;
    `}
  }
  svg {
    margin-right: 10px;
  }
`;

export const Details = {
  Background: styled.div`
    background-color: #f2f2f7;
    height: 100%;
  `,
  Header: styled.div`
    background: linear-gradient(89.99deg, #863ad2 3.58%, #5936e4 97.14%);
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-flow: column;
  `,
  Title: styled.div`
    color: #fff;
    font-size: 20px;
    line-height: 26px;
    font-weight: 700;
    margin: 0 0 10px 10px;
    max-width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  `,
  Content: styled.div`
    margin: 20px 15px;
    height: 80%;
  `,
  Row: {
    Container: styled.div`
      background-color: #fff;
      height: 45px;
      display: grid;
      grid-template-columns: 40% 1px 60%;
      margin-bottom: 10px;
    `,
    Title: styled.div`
      color: #57575c;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-transform: uppercase;
      margin-left: 20px;
      align-self: center;
    `,
    Separator: styled.div`
      width: 1px;
      height: 100%;
      border: #e5e5e5 solid 1px;
    `,
    Value: styled.div`
      color: #1a2535;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      text-align: right;
      margin-right: 20px;
      align-self: center;
    `,
  },
  CTAs: styled.div<{ isMobile?: boolean }>`
    margin: 20px 15px;
    position: relative;
    top: 60%;
    ${({ isMobile }) =>
      isMobile &&
      `
      top: 65%;
    `}
    ${screenSm} {
      top: 75%;
    }
    ${screenMd} {
      top: 90%;
    }
  `,
};
