import { useContext } from 'react';
import { NotesContext } from 'contexts/notes';
import InputSelectMultiple from 'components/inputs/inputSelectMultiple';
import { FilterItem } from 'contexts/notes/types';

interface INoteUsersFilter {
  modalScreen?: boolean;
  modalValues?: string;
  usersFilterChange?: (filter: FilterItem) => void;
}

const NoteUsersFilter = ({
  modalScreen,
  modalValues,
  usersFilterChange,
}: INoteUsersFilter): JSX.Element => {
  const { actions, filters, noteUsers } = useContext(NotesContext);
  const initialNoteUsersValues = !modalScreen
    ? filters.created_by
      ? filters.created_by.split(',')
      : []
    : modalValues
    ? modalValues.split(',')
    : [];

  const onChangeGeneratedBy = (value: string[] | number[]) => {
    if (modalScreen) {
      usersFilterChange({
        name: 'created_by',
        value: value.toString(),
      });
    } else {
      actions &&
        actions.onChangeFilter({
          name: 'created_by',
          value: value.toString(),
        });
    }
  };

  return (
    <InputSelectMultiple
      values={initialNoteUsersValues}
      inputLabel="Generated by"
      placeholder="All"
      optionFilterProp={'label'}
      options={noteUsers}
      maxDropdownHeight={'150px'}
      onChange={onChangeGeneratedBy}
    />
  );
};

export default NoteUsersFilter;
