import React from 'react';
import styled from 'styled-components/';

const Loading = styled.div<{ active: boolean }>`
  position: absolute;
  bottom: -3px;
  left: 0;
  right: 0;
  opacity: 1;
  transition: all 0.4s;

  .loader {
    width: 100%;
    margin: 0 auto;
    position: relative;
    padding: 1px;
    background: linear-gradient(41deg, #7c38d5 0%, #7c38d5 5%);
    transition: all 0.4s;
  }
  .loader:before {
    content: '';
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
  }
  .loader .loaderBar {
    position: absolute;
    border-radius: 10px;
    top: 0;
    right: 100%;
    bottom: 0;
    left: 0;
    background: #c55676;
    width: 0;
    animation: borealisBar 2s linear infinite;
  }

  @keyframes borealisBar {
    0% {
      left: 0%;
      right: 100%;
      width: 0%;
    }
    10% {
      left: 0%;
      right: 75%;
      width: 45%;
    }
    90% {
      right: 0%;
      left: 75%;
      width: 45%;
    }
    100% {
      left: 100%;
      right: 0%;
      width: 0%;
    }
  }

  ${({ active }) =>
    !active &&
    `
		opacity: 0;
	`}
`;

const HeaderLoading = ({ active }: { active: boolean }): JSX.Element => {
  return (
    <Loading active={active}>
      <div className="loader">
        <div className="loaderBar"></div>
      </div>
    </Loading>
  );
};

export default HeaderLoading;
