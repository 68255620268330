import { FC } from 'react';
import styled from 'styled-components';
import Icon from 'components/icons';
import { UserInvites } from 'contexts/users/types';
import { CardContent, CustomLink } from 'components/layout/profile/profileContent/styles';
import { DEFAULT_IMG_USERS } from 'constants/urls';
import { PendingLetterAvatar } from 'components/usersTable/styles';
import { colorsArray } from 'utils/colors';
import { getRandomInt } from 'utils/randomMax';
import Tooltip from 'components/tooltip';
import EmptyTeamManagersCard from './EmptyTeamManagersCard';
import { TeamCardContainer } from '../TeamSharedStyles';
import { ImageDiv } from 'components/charts/styles';
import { NavLink } from 'react-router-dom';
import { colors } from 'constants/styles/colors';
import { CustomNavLink } from '../styles';

const ManagersAvatars = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const AvatarContainer = styled.div<{ zIndex: number }>`
  z-index: ${({ zIndex }) => (zIndex ? zIndex : 'inherit')};
  > img,
  div {
    border-radius: 50%;
    margin-top: -10px;
    border: 1px solid #fff;
    max-width: 50px;
    max-height: 50px;
    flex-shrink: 0;
  }
`;

const ManagerNames = styled.div`
  display: flex;
  flex-direction: column;
  > p {
    margin: 0;
    display: inline-flex;
  }
`;

const RemainingManagers = styled.span`
  display: inline-flex;
  padding: 2px 8px;
  width: 30px;
  background: linear-gradient(111.02deg, rgba(18, 18, 18, 0.5) 3.86%, rgba(18, 18, 18, 0) 107.96%);
  border-radius: 20px;
  position: relative;
  margin-left: 5px;
  cursor: default;
  > span {
    position: absolute;
    top: 2px;
    background: linear-gradient(
      111.02deg,
      rgba(18, 18, 18, 0.2) 19.86%,
      rgba(18, 18, 18, 0.1) 107.96%
    );
    bottom: 2px;
    left: 2px;
    right: 2px;
    border-radius: 10px;
    text-align: center;
    font-size: 12px;
    color: #fff;
  }
`;

interface TeamManagersCardProps {
  managers: UserInvites[];
  teamSlug: string;
  total: number;
}

const TeamManagersCard: FC<TeamManagersCardProps> = ({ teamSlug, managers, total }) => {
  const users = managers.map((item) => {
    const { uaa_data } = item;
    return {
      avatar: uaa_data?.profile?.data.avatar,
      first_name: uaa_data?.profile?.data.first_name,
      last_name: uaa_data?.profile?.data.last_name,
      active: item.is_active,
      ...item,
    };
  });

  if (total <= 0) return <EmptyTeamManagersCard />;

  return (
    <TeamCardContainer>
      <ManagersAvatars>
        {users.map(({ active, first_name, avatar, email }, index) => {
          if (index >= 4) return null;
          const avatarColor = colorsArray[getRandomInt(colorsArray.length)];
          return (
            <AvatarContainer key={email} zIndex={index + 1}>
              {active && avatar !== DEFAULT_IMG_USERS ? (
                <ImageDiv urlImage={avatar} fixed={false} />
              ) : (
                <PendingLetterAvatar key={email || first_name} color={avatarColor}>
                  {email?.[0].toUpperCase() || first_name[0].toUpperCase()}
                </PendingLetterAvatar>
              )}
            </AvatarContainer>
          );
        })}
      </ManagersAvatars>
      <CardContent style={{ marginBottom: 'auto' }}>
        <h5>Team manager(s)</h5>
        <ManagerNames>
          {users.map(
            ({ first_name, last_name, email, user }, index) =>
              index <= 3 && (
                <p key={email}>
                  <CustomNavLink to={`/user/${user}`}>{`${first_name} ${last_name}`}</CustomNavLink>
                  {index + 1 === users.length && !!(total - users.length) && (
                    <Tooltip placement="bottom" title={`${total - users.length} more managers(s)`}>
                      <RemainingManagers>
                        <span>+{total - users.length}</span>
                      </RemainingManagers>
                    </Tooltip>
                  )}
                </p>
              )
          )}
        </ManagerNames>
      </CardContent>
      <CustomLink to={`/users?team=${teamSlug}&role=manager`}>
        <Icon icon="ArrowRightShort" size={'small'} />
      </CustomLink>
    </TeamCardContainer>
  );
};

export default TeamManagersCard;
