import { TeamColorBall } from './styles';
import { Label } from 'components/modal/styles';
import { colors } from 'constants/styles/colors';

interface ITeamsIcon {
  onChange(color: string): void;
  active: keyof typeof colors.dataVisualization;
}

export const TeamColorSelect = ({ onChange, active }: ITeamsIcon): JSX.Element => {
  return (
    <>
      <Label>Select a color</Label>
      <div style={{ marginLeft: '4px' }}>
        {Object.keys(colors.dataVisualization).map((color) => {
          if (!['beige', 'black', 'gray'].includes(color)) {
            return (
              <TeamColorBall
                key={color}
                selected={active === color}
                color={colors.dataVisualization[color].base}
                onClick={() => onChange(color)}
              />
            );
          }
        })}
      </div>
    </>
  );
};
