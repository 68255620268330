import { endOfMonth, endOfWeek, format, startOfMonth, startOfWeek } from 'date-fns';

export const BOOKING_STATUS = {
  RESERVED: 'reserved',
  UPCOMING: 'upcoming',
  ACTIVE: 'active',
  FINISHED: 'finished',
  DELETED: 'deleted',
};

export const availabilityDatesParams = (date: string): any => {
  const currentDate = new Date(date);
  const start_date = format(currentDate, 'yyyy-MM-dd');
  const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  const end_date = format(endDate, 'yyyy-MM-dd');
  return {
    start_date,
    end_date,
  };
};

export const availabilityDatesParamsV2 = (date: string): any => {
  const currentDate = date.split(',');
  const start_date = currentDate[0];
  const end_date = currentDate[1];
  return {
    start_date,
    end_date,
  };
};

export const dateRangeHelper = (date: string): string => {
  const currentDate = new Date(date);
  const firstMonthDay = startOfMonth(currentDate);
  const lastMonthDay = endOfMonth(currentDate);

  const firstWeekDay = startOfWeek(firstMonthDay);
  const lastWeekDay = endOfWeek(lastMonthDay);

  return `${format(firstWeekDay, 'yyyy-MM-dd')},${format(lastWeekDay, 'yyyy-MM-dd')}`;
};
