import './styles.scss';

import Bugsnag from '@bugsnag/js';
import FeatherIcon from 'feather-icons-react';
import Icon from 'components/icons';
import { notification as antd_notification } from 'antd';

interface NotificationProps {
  type: string;
  text: string | JSX.Element;
  description?: string | JSX.Element;
  duration?: number;
}

const Notification = ({
  type = 'success',
  text,
  description,
  duration = 5,
}: NotificationProps): void => {
  const iconType = {
    success: { icon: 'check-circle', color: '#49E0B3' },
    error: { icon: 'x-circle', color: '#FE6D6D' },
    warning: { icon: 'alert-circle', color: '#F8B369' },
    info: { icon: 'alert-circle', color: '#FFF' },
  };

  const iconClose = <Icon icon={'Close'} fill={'#FFFFFF'} />;

  const iconMessage = (
    <FeatherIcon
      icon={iconType[type].icon}
      size={'20px'}
      strokeWidth={'2px'}
      color={iconType[type].color}
    />
  );

  // type= success, error, warning
  antd_notification.open({
    message: text,
    description,
    className: `notificacion-${type}`,
    style: {
      width: 'auto',
    },
    top: 50,
    duration,
    icon: iconMessage,
    closeIcon: iconClose,
  });

  if (type === 'error') {
    Bugsnag.notify(text.toString());
  }
};

export default Notification;
