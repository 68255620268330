import { INE_URL } from 'constants/domains';

export const toolbarMenuItems = [
  /* 	{
		text: 'Active courses',
		href: `${process.env.REACT_APP_PUBLIC_URL}/dashboard/active-courses`,
	}, */
  {
    text: 'My analytics',
    href: `${INE_URL}/dashboard/analytics`,
  },
  {
    text: 'Bookmarks',
    href: `${INE_URL}/dashboard/bookmarks`,
  },
  {
    text: 'Bootcamps',
    href: `${INE_URL}/bootcamps`,
  },
  {
    text: 'Certifications',
    href: `${INE_URL}/dashboard/certifications`,
  },
  {
    text: 'Workbooks',
    href: '#',
  },
  {
    text: 'Rack Rentals',
    href: 'https://members.ine.com/',
  },
  {
    text: 'Subscriptions',
    href: `${INE_URL}/dashboard/subscriptions`,
  },
];

export const notifications = [
  {
    name: 'Brad Simmons',
    ago: '2 min ago',
    message: 'Joined your team Marketing',
    isOnline: true,
  },
  {
    name: 'Amanda Stanberry',
    ago: '27 min ago',
    message: 'Passed Pentration Testing Student quiz',
    isOnline: false,
  },
];
