import { screenMd, screenXs, screenXxs } from 'constants/styles/breakpoints';
import styled from 'styled-components';
import migratedBanner from 'assets/images/migrated-banner.png';

export const MigratedNoticeContainer = styled.div`
  width: 100%;
  height: 110px !important;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: all 0.2s ease;
  display: flex;
  justify-content: start;
  flex-direction: column;
  padding: 8.5px 10px !important;
  align-items: flex-start;
  gap: 10px;
  background: linear-gradient(89.99deg, #863ad2 3.58%, #5936e4 97.14%);

  ${screenXxs} {
    align-items: center;
    justify-content: center;
    flex-direction: row;
    height: 70px !important;
  }

  ${screenXs} {
    align-items: center;
    justify-content: center;
    flex-direction: row;
    height: 70px !important;
  }
  ${screenMd} {
    display: -webkit-box !important;
    background-image: url(${migratedBanner});
    background-size: cover;
    height: 66px !important;
    flex-wrap: wrap;
    padding: 15px 10px;
    gap: 20px;
    .titles-container {
      display: flex;
      flex-direction: column;
    }
  }
  p {
    font-size: 12px;
    line-height: 14px;
    ${screenMd} {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
    }
    &.migrated-title {
      color: #ffffff;
      font-weight: 700;
      font-size: 15px;
      line-height: 24px;
      margin: 0;

      ${screenMd} {
        font-size: 24px;
        line-height: 32px;
      }
    }
    &.migrated-subtitle {
      color: #fff;
      margin: 0;
      a {
        color: inherit;
        text-decoration: underline;
      }
    }
  }
`;

export const MigratedPill = styled.span`
  display: none;
  padding: 5px 8px;
  width: 82px;
  height: 24px;
  background: linear-gradient(
    92.23deg,
    #ef7d22 3.65%,
    #ef741d 25.47%,
    #ee5a10 62.46%,
    #ee3b00 98.5%
  );
  box-shadow: 0px 0px 10px rgba(248, 179, 105, 0.5);
  border-radius: 30px;
  color: #ffffff;
  text-align: center;
  font-size: 12px;
  line-height: 14px;

  ${screenXs} {
    display: block;
  }
`;

export const MigratedCongratsIcon = styled.img`
  display: none;
  ${screenXs} {
    display: block;
  }
`;

export const MigratedCTA = styled.div`
  display: flex;
  gap: 20px;
`;
