import Button from 'components/button';
import { FooterContainer, FooterLinks, FooterActions } from './styles';
import ContactSupport from './ContactSupport';

const Footer = (): JSX.Element => {
  return (
    <FooterContainer>
      <FooterLinks>
        <span>© 2023 INE</span>
        <a
          href={'https://checkout.shopify.com/17217507/policies/16723909.html'}
          title={'Terms of services'}
        >
          Terms of services
        </a>
        <a
          href={'https://checkout.shopify.com/17217507/policies/16723845.html'}
          title={'Privacy policy'}
        >
          Privacy policy
        </a>
        <a href={'https://community.ine.com/'} title={'INE Community'}>
          INE Community
        </a>
      </FooterLinks>

      <FooterActions>
        <ContactSupport />
      </FooterActions>
    </FooterContainer>
  );
};

export default Footer;
