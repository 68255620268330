import styled from 'styled-components';
import ProfessionalPlanLogo from 'assets/images/icon-professional.svg';
import EnterprisePlanLogo from 'assets/images/icon-enterprise.svg';
import EnterprisePlusLabExperiencePlanLogo from 'assets/images/icon-enterprise-plus-lab.svg';
import LabExperiencePlanLogo from 'assets/images/icon-lab-experiences-plan.svg';
import SkillDiveLogo from 'assets/images/skill_dive.svg';

const PlanTypeContainer = styled.div<{ active: boolean; error: boolean }>`
  width: 177px;
  height: 64px;
  background: linear-gradient(
    112.2deg,
    rgba(187, 188, 199, 0.4) -46.39%,
    rgba(187, 188, 199, 0) 112.38%
  );
  border-radius: 10px;
  padding: 10px;
  transition: box-shadow 0.2s ease;
  display: flex;
  align-items: center;
  flex: 1;
  cursor: pointer;
  ${({ active }) =>
    active &&
    `
    box-shadow: 0px 0px 0px 1px #5936e4;
	`}
  > img {
    margin-right: 10px;
  }
  > label {
    font-size: 14px;
    color: #57575c;
  }
  ${({ error }) =>
    error &&
    `
		box-shadow: 0px 0px 0px 1px red;
		`}
`;

const PlansLogos = {
  enterprise: EnterprisePlanLogo,
  'enterprise-plus-lab-experience': EnterprisePlusLabExperiencePlanLogo,
  professional: ProfessionalPlanLogo,
  'lab-experience': LabExperiencePlanLogo,
  'skill-dive': SkillDiveLogo,
};

export interface PlanTypeProps {
  label?: string;
  type: keyof typeof PlansLogos | string;
  active?: boolean;
  onClick?(): void;
  error?: boolean;
}

const PlanType = ({ label, type, active, onClick, error }: PlanTypeProps): JSX.Element => {
  return (
    <PlanTypeContainer as={'label'} htmlFor={type} active={active} onClick={onClick} error={error}>
      <img src={PlansLogos[type]} />
      <label htmlFor={type}>{label}</label>
    </PlanTypeContainer>
  );
};

export default PlanType;
