import { FC, useContext, useState } from 'react';
import CirclesInfoDisplay from 'components/circlesInfoDisplay/CirclesInfoDisplay';
import { userImagesArray } from 'components/circlesInfoDisplay/utilities';
import { CustomLink } from 'components/layout/profile/profileContent/styles';
import Icon from 'components/icons';
import Button from 'components/button';
import { SessionContext } from 'contexts/session';
import EmptyUsersCard from './EmptyUsers';
import AccountDefaultLogo from 'assets/images/account-avatar.svg';
import InviteUsers from 'pages/Users/components/InviteUsers';
import { DeepTeams } from 'contexts/teams/types';
import { CustomCard, CustomCardContent } from './AccountUsersCard';
import useAccountPlanData from 'hooks/useAccountPlanData';

const AccountUsersTeamManagerCard: FC<{
  dashboard: boolean;
  team: DeepTeams;
  onInvitesSent: () => void;
}> = ({ dashboard, team, onInvitesSent }) => {
  const { account } = useContext(SessionContext);
  const { isExpired } = useAccountPlanData({ account });
  const [isEditingInvite, setEditingInvite] = useState<boolean>(false);

  const total = team?.active_users_count + team?.pending_users_count || 0;

  const teamsItems = userImagesArray([
    ...(team?.active_users ?? []),
    ...(team?.pending_users ?? []),
  ]);

  if (teamsItems.length < 2) {
    teamsItems.push(<img src={AccountDefaultLogo} />);
  }

  if (total < 1) return <EmptyUsersCard />;

  const handleInviteClose = (result?: string) => {
    setEditingInvite(false);
    if (result == 'ok') {
      onInvitesSent();
    }
  };

  return (
    <>
      <CustomCard dashboard={true} className="users-card">
        <CirclesInfoDisplay elements={teamsItems} count={total - team?.active_users_count} />
        <CustomCardContent className="users-card__content" dashboard={true}>
          <h5 className="users-card__title">{total} Users</h5>
          <span className="users-card__counters">
            <p>Active users: {team?.active_users_count}</p>
            {dashboard && <div className="divider" />}
            <p>Pending users: {team?.pending_users_count} </p>
          </span>
          {dashboard && (
            <div className="users-card__add-user-btn">
              <Button
                disabled={isExpired}
                size="small"
                icon="Plus"
                style={{ padding: '0px 25px' }}
                onClick={() => setEditingInvite(true)}
              >
                Add user
              </Button>
            </div>
          )}
        </CustomCardContent>
        <CustomLink to="/users">
          <Icon icon="ArrowRight" size={'small'} />
        </CustomLink>
      </CustomCard>
      {isEditingInvite && (
        <InviteUsers
          account={account}
          isEditingInvite={isEditingInvite}
          onClose={handleInviteClose}
        />
      )}
    </>
  );
};

export default AccountUsersTeamManagerCard;
