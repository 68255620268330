/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { isEmail } from 'react-multi-email';
import { StyledInputMultiEmail } from './styles';
import FeatherIcon from 'feather-icons-react';
interface IProps {
  emails: string[];
  placeholder?: string;
  onChange?: (_emails: string[]) => void;
  validateEmail?: (email: string) => boolean;
  getLabel?: (email: string, index: number, removeEmail: (index: number) => void) => void;
  setEmails: (emails: string[]) => void;
}

class Basic extends React.Component<IProps> {
  render() {
    return (
      <StyledInputMultiEmail
        placeholder={this.props.placeholder || 'Email(s)'}
        emails={this.props.emails}
        onChange={(_emails: string[]) => {
          this.props.setEmails(_emails.map((email) => email.toLocaleLowerCase()));
        }}
        validateEmail={() => {
          return true; // return boolean
        }}
        getLabel={(email: string, index: number, removeEmail: (index: number) => void) => {
          return (
            <div data-tag key={index} className={!isEmail(email) ? 'invalid' : ''}>
              {email}
              <span data-tag-handle onClick={() => removeEmail(index)}>
                <FeatherIcon icon={'x'} size={'14px'} strokeWidth={'4px'} />
              </span>
            </div>
          );
        }}
      />
    );
  }
}

export default Basic;
