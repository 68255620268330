import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 20px 0 0;
  width: 100%;
  min-height: 60px;
`;

export const Avatars = styled.div<{ length }>`
  position: relative;
  height: 40px;
  width: 100%;
  max-width: ${({ length }) => length && `${(length + 1) * 33}px`};
`;

export const Avatar = styled.div<{ index: number; count?: boolean }>`
  position: absolute;
  top: 0;
  width: 40px;
  height: 40px;
  border: 1px solid white;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  cursor: default;

  p {
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0 4px;
    margin: 0;
  }

  ${({ count }) =>
    count &&
    `
		background-color: black;
	`}

  ${({ index }) => {
    if (index === 0) return 'left: 0';
    if (index === 1) return 'left: 23%';
    if (index === 2) return 'left: 46%';
    if (index === 3) return 'left: 69%';
    if (index === 4) return 'left: 92%';
  }}
`;

export const AvatarImage = styled.img`
  width: 40px;
  height: 40px;
`;

export const AvatarLetter = styled.div<{ color: string; bg: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  width: 100%;
  height: 100%;
  background-color: ${({ bg }) => bg};
  color: ${({ color }) => color};
`;
