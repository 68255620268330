import { screenMd, screenSm } from 'constants/styles/breakpoints';
import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  margin-bottom: 20px;
  background-color: #fff;
  margin: 5px 0px;
  background: white;
  padding: 10px;

  ${screenMd} {
    border-radius: 12px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    margin-bottom: 10px;
    padding: 20px 30px;
  }
`;

export const ContentTitle = styled.div`
  flex-direction: column;
  white-space: nowrap;
  display: flex;
  gap: 2px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  justify-content: space-between;
  grid-gap: 10px;

  ${screenMd} {
    grid-gap: 20px;
    align-items: center;
    flex-direction: row;
  }

  button {
    padding: 0 24px !important;
  }
`;

export const FilterButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.default.colors.palette.Business_LightThemeGreyLighter};
  width: 39px;
  height: 39px;
`;

export const Content = styled.div<{ grow?: number }>`
  flex-grow: ${({ grow }) => grow};
  flex-direction: row;
  white-space: nowrap;
  display: flex;
  gap: 10px;
`;

export const Title = styled.h4`
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  margin: 0;
  color: ${({ theme }) => theme.default.colors.palette.Business_DarkThemeGeneralBackground};
`;

export const SelectedCounts = styled.p`
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin: 0;
  color: ${({ theme }) => theme.default.colors.palette.Business_LightThemeGrayDark};
`;

export const ActionButton = styled.div`
  position: fixed;
  bottom: 80px;
  left: 1rem;
  right: 1rem;
  z-index: 900 !important;
  button {
    width: 100%;
  }

  ${screenMd} {
    position: static;
    bottom: auto;
    left: auto;
    right: auto;
    z-index: 0 !important;
    .button {
      width: auto;
    }
  }
`;

export const ContentModalFilters = styled.div<{ fetching?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  opacity: 1;
  border-radius: 12px;
  transition: opacity 0.4s;
  box-sizing: border-box;
  width: 100%;
  max-height: 100%;
  padding-bottom: 36px;

  ${screenSm} {
    background-color: #fff;
    padding: 26px;
    min-width: 276px;
    max-width: 276px;
  }

  ${({ fetching }) =>
    fetching &&
    `
		opacity: 0.6;
		pointer-events: none;
	`}
`;

export const ModalFiltersFooter = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  background: white;
  padding: 20px;
  gap: 12px;
  z-index: 1;
  > button {
    width: 50%;
  }
`;

export const ContentScroll = styled.div`
  max-height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  padding: 20px;
`;
