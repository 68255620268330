import { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useContext } from 'react';
import { SessionContext } from 'contexts/session';
import { NoticeTopBar, UpgradeNotice } from './index';

const TopContainer = styled.div<{ active?: boolean }>`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 901;

  transform: translateY(-100%);
  visibility: hidden;
  opacity: 0;
  transition: all 0.6s;

  ${({ active }) =>
    active &&
    `
		visibility: visible;
		opacity: 1;
		transform: translateY(0%);
`}
`;

const UserUpgradeNotification = ({
  onActive,
}: {
  onActive: ({ height: string }) => void;
}): JSX.Element => {
  const ref = useRef();
  const [activeTopBar, setActiveTopBar] = useState(false);
  const { accounts, isImpersonated } = useContext(SessionContext);

  const onClose = () => {
    onActive({ height: 0 });
    setActiveTopBar(false);
  };

  const setActive = ({ ref }) => {
    setActiveTopBar(true);
    onActive({ height: ref.current ? ref.current.clientHeight : 0 });
  };

  useEffect(() => {
    if (accounts?.id && !isImpersonated) {
      setActive({ ref });
    }
  }, [accounts]);

  return (
    <TopContainer ref={ref} active={activeTopBar}>
      <NoticeTopBar onClose={onClose}>
        {accounts?.id && <UpgradeNotice type={accounts?.is_free_trial ? 'free' : 'professional'} />}
      </NoticeTopBar>
    </TopContainer>
  );
};

export default UserUpgradeNotification;
