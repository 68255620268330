import { FC } from 'react';
import { BaseCircle, Circle, Container, CountMore } from './styles';

export interface CirclesInfoDisplayProps {
  elements: JSX.Element[];
  count?: number;
}

const CirclesInfoDisplay: FC<CirclesInfoDisplayProps> = ({ count, elements }) => (
  <Container>
    <BaseCircle circles={elements.length}>
      {elements.map((item, index) => index <= 4 && <Circle key={index}>{item}</Circle>)}
      {!!count && count > 0 && <CountMore>+{count}</CountMore>}
    </BaseCircle>
  </Container>
);

export default CirclesInfoDisplay;
