import { addDays, differenceInDays } from 'date-fns';
import { GetCountsProps } from 'contexts/accounts/types';
import { formatDateInUtc } from 'utils/helpers';

interface AllLicencesProps {
  from: string;
  to: string;
  unlimited: string;
}

interface AllPricingProps {
  plan: string[];
}

export const getRangeLicenses = ({ from, to }: { from: string; to: string }): [number, number] => {
  const fromValue = parseInt(from);
  const toValue = parseInt(to);

  if (toValue === 0 && fromValue === 0) {
    return [0, 500];
  }
  if (toValue === 0) {
    return [fromValue, 500];
  } else {
    return [fromValue, toValue];
  }
};

export const isAllLicenses = ({ from, to, unlimited }: AllLicencesProps): boolean => {
  return from === '0' && to === '0' && unlimited !== 'true';
};

export const isAllPricing = ({ plan }: AllPricingProps): boolean => {
  return plan[0] === '' || plan.length === 0;
};

export const getPricingCount = (type: string, counts: GetCountsProps): number => {
  for (const item in counts?.pricing_plan_count) {
    if (counts?.pricing_plan_count[item].name === type)
      return counts?.pricing_plan_count[item].count;
  }
  return 0;
};

export const getAccountStatusCount = (type: string, counts: GetCountsProps): number => {
  if (counts?.status_count[type]) return counts.status_count[type];

  return 0;
};

export const isCloseToExpiration = (from: string, to: string): boolean => {
  return differenceInDays(new Date(to), new Date(from)) === 30;
};

export const generateCloseToExpirationValues = (): [string, string] => {
  const from = formatDateInUtc(new Date(), 'yyyy-MM-dd');
  const to = formatDateInUtc(addDays(new Date(from), 30), 'yyyy-MM-dd');
  return [from, to];
};
