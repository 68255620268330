import { PlaylistColorBall } from './styles';
import { Label } from 'components/modal/styles';
import { colors } from 'constants/styles/colors';

interface IPlaylistsIcon {
  onChange(color: string): void;
  selectedColor: string;
}

export const PlaylistColorSelect = ({ onChange, selectedColor }: IPlaylistsIcon): JSX.Element => {
  return (
    <>
      <Label>Select a color</Label>
      <div style={{ marginLeft: '4px' }}>
        {Object.keys(colors.dataVisualization).map(
          (color: keyof typeof colors.dataVisualization) => {
            if (!['beige', 'black', 'gray'].includes(color)) {
              return (
                <PlaylistColorBall
                  key={color}
                  selected={selectedColor === colors.dataVisualization[color].base}
                  color={colors.dataVisualization[color].base}
                  onClick={() => onChange(colors.dataVisualization[color].base)}
                />
              );
            }
          }
        )}
      </div>
    </>
  );
};
