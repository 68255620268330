import { useState } from 'react';
import { format } from 'date-fns';
import Button from 'components/button';
import { StickyFooter } from 'components/cards';
import { ConfirmModal } from 'components/modal';
import { DrawerContainer } from 'components/usersTable/styles';
import { Account } from 'contexts/accounts/types';
import { Note } from 'contexts/notes/types';
import CreateNote from '../CreateNote';
import { NoteDetailDrawer, NoteDetailHeader } from '../styles';

interface INoteDetailModalProps {
  note: Note;
  isModalOpen: boolean;
  entity: Account; //The idea is to add more entities that model notes can handle e.g Users
  onClose(): void;
  onDeleteConfirmation(): void;
  onNoteEdited(): void;
}

const NoteDetailModal = ({
  note,
  isModalOpen,
  entity,
  onClose,
  onDeleteConfirmation,
  onNoteEdited,
}: INoteDetailModalProps): JSX.Element => {
  const [showCreateNoteModal, setShowCreateNoteModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteNoteModal] = useState<boolean>(false);

  const handleCreateNoteModalResponse = (result) => {
    setShowCreateNoteModal(false);
    if (result === 'ok') {
      onNoteEdited();
    }
  };

  const handleDeleteNoteCofirmation = () => {
    setShowDeleteNoteModal(false);
    onDeleteConfirmation();
  };

  return (
    <>
      <NoteDetailDrawer
        closable={false}
        placement="bottom"
        visible={isModalOpen}
        onClose={onClose}
        height="calc(100% - 120px)"
        destroyOnClose
      >
        <DrawerContainer style={{ paddingTop: '10px' }}>
          <NoteDetailHeader>
            <h2>GENERATED BY</h2>
            <strong>{note.created_by}</strong>
          </NoteDetailHeader>
          <NoteDetailHeader>
            <h2>DATE</h2>
            <span>{format(new Date(note.created_at), 'p - yyyy/MM/dd')}</span>
          </NoteDetailHeader>
          <NoteDetailHeader>
            <h2>NOTE</h2>
            <span>{note.text}</span>
          </NoteDetailHeader>
        </DrawerContainer>
        <StickyFooter>
          <Button
            className="w-100"
            $secondary
            icon={'Edit'}
            onClick={() => setShowCreateNoteModal(true)}
          >
            Edit note
          </Button>
          <Button
            className="w-100"
            $secondary
            icon={'Trash'}
            onClick={() => setShowDeleteNoteModal(true)}
          >
            Remove note
          </Button>
        </StickyFooter>
      </NoteDetailDrawer>
      {showCreateNoteModal && (
        <CreateNote
          isModalOpen={showCreateNoteModal}
          note={note}
          entity={entity}
          onClose={handleCreateNoteModalResponse}
        />
      )}
      <ConfirmModal
        open={showDeleteModal}
        title={'Delete note'}
        description="Are you sure you want to delete this note?"
        acceptLabel={'Delete note'}
        cancelLabel={'Cancel'}
        onAccept={() => handleDeleteNoteCofirmation()}
        onCancel={() => setShowDeleteNoteModal(false)}
      />
    </>
  );
};

export default NoteDetailModal;
