import { useContext } from 'react';
import Button from 'components/button/Button';
import { PlaylistsContext } from 'contexts/playlists';

type IAssignPlaylistButton = {
  playlist: any;
  setFetching: (fetching: boolean) => void;
  refreshPlaylist: () => void;
};

const AssignPlaylistButton = (props: IAssignPlaylistButton): JSX.Element => {
  const { playlist, setFetching, refreshPlaylist } = props;

  const { actions } = useContext(PlaylistsContext);
  const handleAssignUser = async () => {
    setFetching(true);
    actions.onAssignUser(playlist);
    await refreshPlaylist();
    setFetching(false);
  };

  return (
    <Button $primary onClick={handleAssignUser}>
      Assign Playlist
    </Button>
  );
};

export default AssignPlaylistButton;
