import { useRef } from 'react';
import { Slider as AntdSlider } from 'antd';
import { SliderRangeProps } from './types';
import styled from 'styled-components';

const CustomSlider = styled(AntdSlider)<{ unlimited: boolean }>`
  // Rail & Track
  .ant-slider-rail {
    background-color: #e9e9ed;
  }
  .ant-slider-track {
    background-color: #c9bbff !important;
  }
  &:hover .ant-slider-track {
    background-color: #a793f6 !important;
  }

  // Handle Dots
  .ant-slider-handle {
    background-color: #a793f6 !important;
    border: none;
    width: 10px;
    height: 10px;
    margin-top: -3px;
  }

  // Marks
  .ant-slider-mark {
    font-size: 13px;
    color: #57575c;
    transform: translateY(-2px);
  }

  // Disabled
  &.ant-slider-disabled {
    opacity: 0.5 !important;
  }

  ${({ unlimited }) =>
    unlimited &&
    `
		pointer-events: none;
		opacity: 0.5;
	`};
`;

const CustomTooltips = styled.div<{ unlimited: boolean }>`
  // Tooltips
  .ant-tooltip {
    z-index: 1 !important;
    padding: 0;
    .ant-tooltip-content {
      transform: translateY(-4px);
      .ant-tooltip-arrow {
        display: none;
      }
      .ant-tooltip-inner {
        padding: 0;
        margin: 0;
        background: transparent;
        box-shadow: none;
        color: ${({ unlimited }) => (unlimited ? '#7965C650' : '#7965C6')};
        font-size: 14px;
        font-weight: 700;
        box-shadow: 0px 5px 3px 5px rgb(255 255 255);
        background: #fff;
        min-height: 7px;
        line-height: 10px;
        margin-top: 6px;
      }
    }
  }

  ${({ unlimited }) =>
    unlimited &&
    `
		pointer-events: none;
	`};
`;

const Slider = (props: SliderRangeProps): JSX.Element => {
  const sliderTooltips = useRef();

  return (
    <>
      <CustomTooltips ref={sliderTooltips} unlimited={props.unlimited}></CustomTooltips>
      <CustomSlider
        {...props}
        getTooltipPopupContainer={() => sliderTooltips.current}
        unlimited={props.unlimited}
      />
    </>
  );
};

export default Slider;
