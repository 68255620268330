import styled from 'styled-components';
import { screenSm } from 'constants/styles/breakpoints';

export const ContentWrapper = styled.div`
  padding: 35px 50px;
`;

export const Title = styled.div`
  font-weight: 400;
  font-size: 24px;
  line-height: 38px;
  color: #1a2535;
  margin-bottom: 45px;
  margin-top: 15px;
  ${screenSm} {
    margin-top: 40px;
    font-size: 32px;
  }
`;

export const SubTitle = styled.div`
  color: #111a27;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  ${screenSm} {
    font-weight: 700;
    font-size: 20px;
  }
`;

export const AddUsersWrapper = styled.div`
  display: block;
`;

export const CourseListContainer = styled.div`
  background: #f2f2f7;
  border-radius: 10px;
  padding: 20px;
  flex: 1;
`;

export const CourseListTitle = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #111a27;
  margin-bottom: 10px;
`;

export const CourseItem = styled.div`
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 8px;
  background: #111a27;
  margin-bottom: 5px;
  border-radius: 60px;
  width: fit-content;
`;

export const SearchResultsWrapper = styled.div`
  height: 500px;
  overflow: auto;
  margin-top: 20px;
`;

export const ModalContentWrapper = styled.div`
  padding: 0;
  ${screenSm} {
    padding: 0 30px;
  }
`;

export const UserFilterWrapper = styled.div`
  margin-top: 10px;
  max-height: 370px;
  ${screenSm} {
    max-height: 300px;
  }
  overflow: scroll;
`;
