import { useContext } from 'react';
import { BrowserView } from 'react-device-detect';
import { UsersContext } from 'contexts/users';
import AccountUsersAsideFilterActive from './AccountUsersAsideFilterActive';
import AccountUsersAsideFilterLicense from './AccountUsersAsideFilterLicense';
import AccountUsersAsideFilterRoles from './AccountUsersAsideFilterRoles';
import AccountUsersAsideFilterTeams from './AccountUsersAsideFilterTeams';
import { Account } from 'contexts/accounts/types';
import { Team } from 'contexts/teams/types';
import AccountUsersAsideFilterAddons from './AccountUsersAsideFilterAddons';

interface AccountUserAsideFilterProps {
  account: Account;
  teams: Team[];
}

const AccountUsersAsideFilters = ({ account, teams }: AccountUserAsideFilterProps): JSX.Element => {
  const { actions } = useContext(UsersContext);

  return (
    <>
      <div className="users-aside-filters">
        <AccountUsersAsideFilterActive account={account} />
        <AccountUsersAsideFilterTeams teams={teams} />
        <AccountUsersAsideFilterLicense />
        <AccountUsersAsideFilterAddons />
        <AccountUsersAsideFilterRoles account={account} />
      </div>
      <BrowserView>
        <div className="mt-10">
          <button className="btn" onClick={actions.onClearSearch}>
            Clear Filters
          </button>
        </div>
      </BrowserView>
    </>
  );
};

export default AccountUsersAsideFilters;
