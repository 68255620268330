import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { isEmail } from 'react-multi-email';
import Button from 'components/button';
import Modal from 'components/modal';
import {
  HeaderContainer,
  ModalActions,
  ModalContainer,
  ModalContent,
  ModalSection,
  ModalSectionTitle,
} from 'components/modal/styles';
import { MODAL_SIZES } from 'components/modal/types';
import Notification from 'components/notification';
import { setUserLicense, setUserTeams as setUserTeamsAction } from 'components/usersTable/actions';
import { getDomain } from 'constants/utilities/emails';
import { Domains } from 'contexts/accounts/types';
import { DeepTeams, Team, UserTeam } from 'contexts/teams/types';
import { Invite } from 'pages/Users/components/InviteUsers/AddByEmails/invitesReducer';
import AdjustUsersSettings from 'pages/Users/components/InviteUsers/AdjustUsersSettings';
import EmailInput from 'pages/Users/components/InviteUsers/EmailInput';
import RemoveUsers from 'pages/Users/components/InviteUsers/RemoveUsers/RemoveUsers';
import { getAlreadyInvited } from 'services/alreadyInviteds';
import { sendUserInvites } from 'services/userInvites';
import TeamInfo from './TeamInfo';
import UsersSuggestions from './UsersSuggestions';

interface IInviteToTeam {
  isVisible?: boolean;
  editTeam?: boolean;
  team?: Team | DeepTeams;
  onClose?: (ok?: string | boolean) => void;
  isInvite?: boolean;
  account?: AccountInvite;
}

interface AccountInvite {
  id: string;
  licenses_available?: number;
  email_domains?: Domains[];
}

const InviteToTeam: React.FC<IInviteToTeam> = ({ isVisible, team, onClose, isInvite, account }) => {
  const [activeUsers, setActiveUsers] = useState<Invite[]>([]);
  const [emailInvites, setEmailInvites] = useState<Invite[]>([]);
  const [emails, setEmails] = useState([]);
  const [step, setStep] = useState(0);
  const [errors, setErrors] = useState([]);
  const [suggestions, areSuggestions] = useState<boolean>(true);
  const [inviteEditMobile, setinviteEditMobile] = useState(false);
  const [disableSaveInvitations, setDisableSaveInvitations] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userTeams, setUserTeams] = useState<UserTeam[]>([]);

  const validAccountDomain = (email) => {
    const domain = getDomain(email);
    return account?.email_domains.some(({ email_domain }) => email_domain === domain);
  };

  const validEmails = emails.filter((email) => isEmail(email) && validAccountDomain(email));

  const modalSize =
    (step === 0 && MODAL_SIZES.SMALL) ||
    (step == 1 && MODAL_SIZES.LARGE) ||
    (step === 2 && MODAL_SIZES.LARGE) ||
    (step === 3 && MODAL_SIZES.SMALL);

  const setupDefaultLicense = (license_already_added: boolean) => {
    if (
      (typeof account.licenses_available === 'boolean' && account.licenses_available === true) ||
      license_already_added
    )
      return true;
    if (account.licenses_available >= validEmails.length) return true;
    return false;
  };

  const handleActiveUsersSelection = (data) => {
    const activeUserInvite = data;
    activeUserInvite.userTeams = userTeams;
    const newUserInvites = data.checked
      ? [...activeUsers, activeUserInvite]
      : activeUsers.filter(({ id }) => id !== activeUserInvite.id);

    setActiveUsers(newUserInvites);
  };

  const handleUserInvites = () => {
    handleValidateEmails();
  };

  const handleValidateEmails = async () => {
    setLoading(true);
    const response = await getAlreadyInvited(emails);
    let errors = [];
    if (response?.status === 200) {
      const alreadyInvited = response.data.already_invited.length > 0;
      if (alreadyInvited) {
        const alreadyInvited = handleAlreadyInvited(response.data.already_invited);
        errors = [...errors, ...alreadyInvited];
      }
    } else {
      // @ToDo handle errors properly
      setLoading(false);
      return;
    }
    const invalidDomain = handleInvalidDomains();
    errors = [...errors, ...invalidDomain];
    setErrors((prevState) => [...prevState, ...errors]);
    setLoading(false);
    setStep(1);
  };

  const handleInvalidDomains = () => {
    const invitationInvalidDomain = emails
      .map((email) => {
        if (isEmail(email) && !validAccountDomain(email)) {
          return {
            email: email,
            error: "Failed: The email address doesn't match with account valid domains",
          };
        } else return null;
      })
      .filter((item) => item);
    return invitationInvalidDomain;
  };

  const handleAlreadyInvited = (alreadyInvited) => {
    const initialErrors = [];
    const invitationErrors = alreadyInvited.map((invitation) => {
      let error = '';
      if (invitation.account?.id !== account.id) {
        error =
          'Failed: This email address is associated with another account, check if the email is already associated with an account';
      } else {
        if (invitation.is_active) {
          error =
            invitation.teams.length > 0
              ? `Failed: This email address is already associated to teams ${invitation.teams
                  .map((team) => team.name)
                  .join(', ')}`
              : 'Failed: This email address is already associated with this account';
        } else {
          error =
            invitation.teams.length > 0
              ? `Failed: This email address has already been invited to teams ${invitation.teams
                  .map((team) => team.name)
                  .join(', ')}`
              : 'Failed: This email address has already been invited checks if you have another invite active';
        }
      }
      if (
        !initialErrors.includes(invitation.email) &&
        !errors
          .filter((item) => item)
          .map(({ email }) => email)
          .includes(invitation.email)
      ) {
        initialErrors.push(invitation.email);
        return {
          email: invitation.email,
          error: error,
        };
      }
    });
    return invitationErrors;
  };

  const handleRemoveErrors = () => {
    const invalids = errors.map((error) => error.email);
    const newEmails = emails.filter((email) => !invalids.includes(email) && isEmail(email));
    setErrors([]);
    setEmails(newEmails);
    if (newEmails.length < 1 && activeUsers.length < 1) {
      setStep(0);
    }
  };

  const saveActiveUserSettings = async () => {
    const teamsPayload = [];
    const userIdsWithLicense = [];
    const userIdsWithNoLicense = [];
    activeUsers.map((activeUser) => {
      const otherTeams = activeUser.teams.reduce((acc, team) => {
        acc.push({ is_manager: team.is_manager, team_id: team.team_id });
        return acc;
      }, []);
      teamsPayload.push({
        user_invite_id: activeUser.id,
        teams: [
          ...otherTeams,
          {
            is_manager: activeUser.userTeams[0].is_manager,
            team_id: activeUser.userTeams[0].team_id,
          },
        ],
      });
      activeUser.has_license
        ? userIdsWithLicense.push(activeUser.id)
        : userIdsWithNoLicense.push(activeUser.id);
    });

    const teamsResponses = await Promise.all(
      teamsPayload.map(async (tp) => {
        return await setUserTeamsAction({
          user_invite_id: tp.user_invite_id,
          teams: tp.teams,
        });
      })
    );
    let teamRespOk = 0;
    let teamRespError = 0;
    teamsResponses.map((resp) => {
      if (!(resp instanceof Error)) {
        teamRespOk++;
      } else {
        teamRespError++;
      }
    });
    teamRespOk > 0 &&
      Notification({
        text: `${teamRespOk} active user(s) invited to team ${team.name} `,
        type: 'success',
      });
    teamRespError > 0 &&
      Notification({
        text: `${teamRespError} active user(s) couldn’t be invited to team ${team.name}`,
        type: 'error',
      });

    const setNoLicense =
      userIdsWithNoLicense.length &&
      (await setUserLicense({ ids: userIdsWithNoLicense, license: false }));
    const setLicense =
      userIdsWithLicense.length &&
      (await setUserLicense({ ids: userIdsWithLicense, license: true }));

    const labels = ['License', 'License'];
    [setNoLicense, setLicense].map((response, index) => {
      if (response instanceof Error) {
        Notification({ text: `${labels[index]} couldn’t be updated`, type: 'error' });
      }
    });
  };

  const sendEmailInvites = async () => {
    try {
      const invitesResponse = await sendUserInvites({
        team: team.id,
        message: `You're being invited to ${team.name}`,
        invites: emailInvites,
      });
      if (invitesResponse?.status >= 200) {
        Notification({
          text: `${emailInvites.length} pending user(s) invited to team ${team.name}`,
          type: 'success',
        });
      } else {
        Notification({ text: 'Couldn’t send invitations', type: 'success' });
      }
    } catch (error) {
      Notification({ text: 'Couldn’t send invitations', type: 'error' });
    }
  };

  const handleSaveSettings = async () => {
    setLoading(true);
    activeUsers.length && (await saveActiveUserSettings());
    if (emailInvites.length) {
      setTeamsPayloadToInvites();
      await sendEmailInvites();
    }
    setLoading(false);
    onClose(true);
  };

  const setTeamsPayloadToInvites = () => {
    if (userTeams.length > 0) {
      const invitesUpdated = [];
      emailInvites.map((i) => {
        i.teams = userTeams.reduce((acc, userTeam) => {
          acc.push({ team_id: userTeam.team_id, is_manager: userTeam.is_manager });
          return acc;
        }, []);
        invitesUpdated.push(i);
      });
      setEmailInvites(invitesUpdated);
    }
  };

  const handleAdjustSettings = (invitesUpdated) => {
    const activeUserUpdated = invitesUpdated.filter((i) => i.id);
    const emailInvitesUpdated = invitesUpdated.filter((i) => !i.id);
    activeUserUpdated.length > 0 && setActiveUsers(activeUserUpdated);
    emailInvitesUpdated.length > 0 && setEmailInvites(emailInvitesUpdated);
  };

  const handleRemoveInvite = (emailToRemove) => {
    const newEmailInvites = emailInvites.filter(({ email }) => email !== emailToRemove);
    setEmailInvites(newEmailInvites);
    setEmails(newEmailInvites.map(({ email }) => email));
    const newActiveUsers = activeUsers.filter(({ email }) => email !== emailToRemove);
    setActiveUsers(newActiveUsers);

    if (!newEmailInvites.length && !newActiveUsers.length) {
      setStep(0);
    }
  };

  const handleUseLicenses = (usedLicenses) => {
    setDisableSaveInvitations(usedLicenses > account.licenses_available); // revisar este dato si en accounts siempre figura igual
  };

  useEffect(() => {
    const userTeams = [
      {
        team: team,
        team_id: team.id,
        name: team.name,
        is_manager: false,
      },
    ];
    setUserTeams(userTeams);
  }, [team]);

  useEffect(() => {
    let newInvites = [];
    validEmails.map((email) => {
      const { is_manager = false, has_license = false } =
        emailInvites.find((invite) => invite.email === email) || {};
      newInvites = [
        ...newInvites,
        {
          email,
          is_manager: is_manager,
          has_license: setupDefaultLicense(has_license),
          userTeams: userTeams,
        },
      ];
    });
    setEmailInvites(newInvites);
  }, [emails]);

  return (
    <Modal
      centered
      visible={isVisible}
      fullscreen={isMobile}
      onCancel={() => onClose()}
      footer={null}
      maskClosable={false}
      closeIconColor="#FFF"
      width={isMobile ? '100%' : modalSize}
    >
      <ModalContainer
        style={isMobile ? { maxHeight: '100%', height: '100%', overflow: 'hidden' } : {}}
      >
        <HeaderContainer>
          {isInvite ? <h2>Invite users to the team</h2> : <h2>Your team has been created!</h2>}
        </HeaderContainer>
        {step === 0 && (
          <>
            <ModalContent
              grow
              flexColumn
              style={isMobile ? { height: '100%', maxHeight: 'calc(100% - 211px)' } : {}}
            >
              <TeamInfo team={team} />
              {!isInvite && <ModalSectionTitle>Invite users to your team</ModalSectionTitle>}
              {suggestions && (
                <ModalSection style={isMobile ? { overflow: 'hidden' } : {}}>
                  <UsersSuggestions
                    onSelect={handleActiveUsersSelection}
                    checkeds={activeUsers.map((item) => item.id)}
                    areSuggestions={areSuggestions}
                    teamId={team.id}
                  />
                </ModalSection>
              )}
              <ModalSection toBottom>
                <EmailInput
                  title={suggestions && 'Not listed above? Invite by email.'}
                  placeholder={'Add multiple emails separated by a comma (,).'}
                  emails={emails}
                  setEmails={setEmails}
                />
              </ModalSection>
            </ModalContent>
            <ModalActions>
              {!isInvite ? (
                <>
                  <Button $secondary $full={isMobile} onClick={() => onClose()}>
                    I’ll do this later
                  </Button>
                  <Button
                    onClick={handleUserInvites}
                    $full={isMobile}
                    loading={loading}
                    disabled={emails.some((email) => !isEmail(email)) && activeUsers.length === 0}
                  >
                    Invite users
                  </Button>
                </>
              ) : (
                <Button
                  onClick={handleUserInvites}
                  loading={loading}
                  disabled={emails.some((email) => !isEmail(email)) && activeUsers.length === 0}
                >
                  Next
                </Button>
              )}
            </ModalActions>
          </>
        )}

        {step === 1 && errors.length > 0 && (
          <>
            <ModalContent>
              <RemoveUsers wrongEmails={errors} />
            </ModalContent>
            <ModalActions>
              <Button onClick={handleRemoveErrors}>Remove errors</Button>
            </ModalActions>
          </>
        )}

        {step === 1 && errors.length < 1 && (
          <>
            <ModalContent style={isMobile ? { maxHeight: 'calc(100% - 210px)' } : {}}>
              <TeamInfo team={team} />
              <AdjustUsersSettings
                hasTeam={Boolean(userTeams.length > 0)}
                invites={emailInvites}
                activeUserInvites={activeUsers}
                onInvitesUpdate={handleAdjustSettings}
                onInviteRemove={handleRemoveInvite}
                onEdit={setinviteEditMobile}
                isEditing={inviteEditMobile}
                onLicenseExcess={handleUseLicenses}
                footer={inviteEditMobile}
                account={account}
              />
            </ModalContent>
            {!inviteEditMobile && (
              <ModalActions>
                <Button
                  onClick={handleSaveSettings}
                  disabled={disableSaveInvitations}
                  loading={loading}
                >
                  Save changes
                </Button>
              </ModalActions>
            )}
          </>
        )}
      </ModalContainer>
    </Modal>
  );
};

export default InviteToTeam;
