import styled from 'styled-components';
import { screenMd } from 'constants/styles/breakpoints';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px auto 40px auto;
  padding-bottom: 100px;
  ${screenMd} {
    margin: 20px auto;
  }
  > h4 {
    font-size: 16px;
    text-align: center;
    ${screenMd} {
      font-size: 24px;
    }
  }
  > p {
    font-size: 14px;
    text-align: center;
    ${screenMd} {
      font-size: 18px;
    }
  }
`;

export const EmptyStateImg = styled.img`
  width: 90vw;
  ${screenMd} {
    width: 700px;
  }
`;

export const ActionButton = styled.div`
  position: fixed;
  bottom: 80px;
  left: 1rem;
  right: 1rem;
  z-index: 900 !important;
  button {
    width: 100%;
  }

  ${screenMd} {
    position: static;
    bottom: auto;
    left: auto;
    right: auto;
    z-index: 0 !important;
    .button {
      width: auto;
    }
  }
`;
