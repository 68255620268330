import { useEffect, useState } from 'react';
import useSearchQuery from './useSearchQuery';
import { teamsInitialValues } from 'contexts/teams/utils';
import { Team, TeamsDataCtx } from 'contexts/teams/types';
import { getRemoveTeamsAction, getTeamsAction } from 'contexts/teams/actions';
import { FilterItem } from 'contexts/users/types';
import Notification from 'components/notification';

interface TeamsContentHook {
  fetching: boolean;
  isEmpty: boolean;
  teamsTable: TeamsDataCtx['table'];
  error: string | boolean;
  updateTable: (items: Team[]) => void;
  removeTableItem: (ids: string[]) => void;
  onChangeFilters: (newValue: FilterItem) => void;
  onTeamEdition: (team: Team) => void;
  onForceTeamsRequest: () => void;
}

const useTeamsTableContent = (): TeamsContentHook => {
  const [teamsTable, setTeamsTable] = useState(teamsInitialValues.table);
  const [fetching, setFetching] = useState(false);
  const { searchQuery, search, updateSearch } = useSearchQuery();
  const [isEmpty, setIsEmpty] = useState<boolean>(false);

  const getPaginatedTeams = async ({ params }) => {
    setFetching(true);
    const teamsData = await getTeamsAction(params);
    if (teamsData instanceof Error) {
      setTeamsTable((prevState) => ({ ...prevState, error: teamsData?.message }));
    } else {
      setTeamsTable((prevState) => ({
        ...prevState,
        count: teamsData?.results?.count,
        items: teamsData?.results?.results,
      }));
      setIsEmpty(teamsData.results?.results.length === 0);
    }
    setFetching(false);
  };

  useEffect(() => {
    getPaginatedTeams({ params: handleReqData() });
  }, [searchQuery]);

  const handleReqData = () => {
    return {
      paginate: 'true',
      deep_details: 'true',
      page: parseFloat(search.get('page')) || null,
      page_size: parseInt(search.get('page_size') || '9') || null,
      search: search.get('search') || null,
    };
  };

  const getRemoveTeams = async (ids) => {
    setFetching(true);
    const response = await getRemoveTeamsAction({ ids });
    setFetching(false);
    if (response instanceof Error) {
      setTeamsTable((prevState) => ({ ...prevState, error: response.message }));
      Notification({ text: response.message, type: 'error' });
    } else {
      Notification({ text: 'Team(s) successfully removed', type: 'success' });
      getPaginatedTeams({ params: handleReqData() });
    }
  };

  const removeTableItem = (ids: string[]) => {
    getRemoveTeams(ids);
  };

  const updateTable = (newContent: Team[]) => {
    setTeamsTable((prevState) => {
      return {
        ...prevState,
        items: newContent,
      };
    });
  };

  const onChangeFilters = (newValue: FilterItem) => {
    const filter = newValue.name.toString();
    const value = newValue.value.toString();

    // remove filter so then it is moved to the last place.
    search.delete(filter);

    // Clear paging if last filter applied is not a page change.
    filter !== 'page' && search.set('page', '1');

    search.set(filter, value.toString());

    handleSearchApply();
  };

  const handleSearchApply = () => {
    updateSearch(search?.toString());
  };

  const onTeamEdition = (team: Team) => {
    const teamIndex = teamsTable.items.findIndex(({ id }) => id === team.id);
    const newTeamData = {
      ...teamsTable.items[teamIndex],
      ...team,
    };
    setTeamsTable((prevState) => {
      return {
        ...prevState,
        ...(prevState.items[teamIndex] = newTeamData),
      };
    });
  };

  return {
    fetching: fetching,
    teamsTable: teamsTable,
    error: false,
    isEmpty,
    removeTableItem,
    updateTable,
    onChangeFilters,
    onTeamEdition,
    onForceTeamsRequest: () => getPaginatedTeams({ params: handleReqData() }),
  };
};

export default useTeamsTableContent;
