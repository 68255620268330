import React, { useContext, useEffect, useState } from 'react';
import { PlaylistsContext } from 'contexts/playlists';
import {
  FilterTitle,
  Filters,
  InfoIcon,
  SingleFilterContainer,
  TypeFiltersContainer,
} from './styles';
import Checkbox from 'components/checkbox';
import { PLAYLIST_TYPE } from 'constants/playlists';
import Tooltip from 'components/tooltip';
import Icon from 'components/icons';
import { colors } from 'constants/styles/colors';
import { SessionContext } from 'contexts/session';

const PlaylistFilters = (): JSX.Element => {
  const { actions, filters, fetching } = useContext(PlaylistsContext);
  const { isSonarEnabled } = useContext(SessionContext);
  const initialState = {
    [PLAYLIST_TYPE.STANDARD]: true,
    [PLAYLIST_TYPE.SONAR_RECOMMENDED]: true,
    assigned: true,
    unassigned: true,
  };
  const [value, setValue] = useState(initialState);

  const handleTypeFilterChanger = (newValue) => {
    if (newValue[PLAYLIST_TYPE.STANDARD] === newValue[PLAYLIST_TYPE.SONAR_RECOMMENDED]) {
      actions && actions.onClearTypeFilters();
      return;
    }

    const typeToSearch = newValue[PLAYLIST_TYPE.STANDARD]
      ? PLAYLIST_TYPE.STANDARD
      : PLAYLIST_TYPE.SONAR_RECOMMENDED;

    actions && actions.onChangeFilters({ name: 'type', value: typeToSearch }, false, true);
  };

  const handleAssignedFilterChanger = (newValue) => {
    if (newValue.assigned === newValue.unassigned) {
      actions && actions.onClearAssignedFilters();
      return;
    }

    const stateToSearch = newValue.assigned;

    actions && actions.onChangeFilters({ name: 'assigned', value: stateToSearch }, false, true);
  };

  const handleFilterChange = (e) => {
    const currentValue = {
      name: e.target.name,
      value: e.target.checked,
    };
    const newValue = {
      ...value,
      [currentValue.name]: currentValue.value,
    };
    setValue(newValue);

    if (
      e.target.name === PLAYLIST_TYPE.STANDARD ||
      e.target.name === PLAYLIST_TYPE.SONAR_RECOMMENDED
    ) {
      handleTypeFilterChanger(newValue);
    } else {
      handleAssignedFilterChanger(newValue);
    }
  };

  useEffect(() => {
    const { type, assigned } = filters;
    const newState = {
      ...initialState,
      [PLAYLIST_TYPE.STANDARD]: type === PLAYLIST_TYPE.STANDARD,
      [PLAYLIST_TYPE.SONAR_RECOMMENDED]: type === PLAYLIST_TYPE.SONAR_RECOMMENDED,
      assigned: assigned === 'true',
      unassigned: assigned === 'false',
    };
    setValue(newState);
  }, []);

  const filtersConfigurations = [];

  if (isSonarEnabled) {
    filtersConfigurations[0] = {
      title: 'Playlist type:',
      singleFilters: [
        {
          label: 'Standard playlists',
          id: PLAYLIST_TYPE.STANDARD,
          tooltip: 'Created by you or other users on your account',
        },
        {
          label: 'Skill Sonar playlists',
          id: PLAYLIST_TYPE.SONAR_RECOMMENDED,
          tooltip: 'Auto-generated by Skill Sonar',
        },
      ],
    };
  }

  filtersConfigurations.push({
    title: 'Playlist state:',
    singleFilters: [
      {
        label: 'Assigned',
        id: 'assigned',
        tooltip: 'Playlists that have been assigned',
      },
      {
        label: 'Unassigned',
        id: 'unassigned',
        tooltip: 'Playlists that haven’t been assigned',
      },
    ],
  });

  return (
    <Filters>
      {filtersConfigurations.map((filterConfig) => (
        <TypeFiltersContainer key={filterConfig.title}>
          <FilterTitle>{filterConfig.title}</FilterTitle>
          {filterConfig.singleFilters.map((singleFilter) => (
            <SingleFilterContainer key={singleFilter.id}>
              <Checkbox
                label={singleFilter.label}
                value={singleFilter.id}
                id={singleFilter.id}
                name={singleFilter.id}
                onChange={handleFilterChange}
                defaultChecked={false}
                checked={value[singleFilter.id]}
                controlled
                darkBackground
                disabled={fetching}
              />
              <Tooltip
                placement="top"
                title={singleFilter.tooltip}
                overlayInnerStyle={{ textAlign: 'center' }}
              >
                <InfoIcon size="small" icon="Info" />
              </Tooltip>
            </SingleFilterContainer>
          ))}
        </TypeFiltersContainer>
      ))}
    </Filters>
  );
};

export default PlaylistFilters;
