import { Button as AntdButton } from 'antd';
import { ReactChild, ReactNode } from 'react';
import styled, { css } from 'styled-components/macro';
import { IButtonProps } from './Button';

export interface IStyledButtonProps extends IButtonProps {
  $hasIcon?: boolean;
  $full?: boolean;
  $btnStyle?: string;
  $btnSize?: string;
  $btnTheme?: string;
  children?: ReactNode | ReactNode[];
}

const basicStyles = css<{ loading: boolean }>`
  &&& {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    box-sizing: border-box;

    ${({ loading }) =>
      loading &&
      `
			.ant-btn-loading-icon {
				display: flex;
				justify-content: center;
				align-items: center;
				> span.anticon {
					> svg {
						fill: ${({ theme }) => theme.default.colors.iconButtons.purple.dark} !important;
					}
				}
			}
		`}
  }
`;

const buttonStyles = css<{ $btnSize: string }>`
  &&& {
    font-size: ${({ theme, $btnSize }) => theme.default.buttons[$btnSize].fontSize};
    border-radius: ${({ theme, $btnSize }) => theme.default.buttons[$btnSize].borderRadius};
    height: ${({ theme, $btnSize }) => theme.default.buttons[$btnSize].height};
    line-height: ${({ theme, $btnSize }) => theme.default.buttons[$btnSize].lineHeight};
    padding: ${({ theme, $btnSize }) => theme.default.buttons[$btnSize].padding};
    svg {
      width: ${({ theme, $btnSize }) => theme.default.buttons[$btnSize].icon.width};
      height: ${({ theme, $btnSize }) => theme.default.buttons[$btnSize].icon.height};
    }
  }
`;

const typeStyles = css<{ $btnStyle: string; $btnTheme: string }>`
  &&& {
    background-color: ${({ theme, $btnStyle, $btnTheme }) =>
      theme.default.buttons[$btnStyle][$btnTheme].iddle.background};
    border: ${({ theme, $btnStyle, $btnTheme }) =>
      theme.default.buttons[$btnStyle][$btnTheme].iddle.border};
    color: ${({ theme, $btnStyle, $btnTheme }) =>
      theme.default.buttons[$btnStyle][$btnTheme].iddle.color};
    opacity: ${({ theme, $btnStyle, $btnTheme }) =>
      theme.default.buttons[$btnStyle][$btnTheme].iddle.opacity};
    box-shadow: ${({ theme, $btnStyle, $btnTheme }) =>
      theme.default.buttons[$btnStyle][$btnTheme].iddle.boxshadow || 'none'};
    svg {
      color: ${({ theme, $btnStyle, $btnTheme }) =>
        theme.default.buttons[$btnStyle][$btnTheme].iddle.icon};
    }
    &:hover {
      background-color: ${({ theme, $btnStyle, $btnTheme }) =>
        theme.default.buttons[$btnStyle][$btnTheme].hover.background};
      border: ${({ theme, $btnStyle, $btnTheme }) =>
        theme.default.buttons[$btnStyle][$btnTheme].hover.border};
      color: ${({ theme, $btnStyle, $btnTheme }) =>
        theme.default.buttons[$btnStyle][$btnTheme].hover.color};
      opacity: ${({ theme, $btnStyle, $btnTheme }) =>
        theme.default.buttons[$btnStyle][$btnTheme].hover.opacity};
      box-shadow: 0px 4px 20px rgba(18, 18, 18, 0.4);
      svg {
        color: ${({ theme, $btnStyle, $btnTheme }) =>
          theme.default.buttons[$btnStyle][$btnTheme].hover.icon};
      }
    }
    &:active {
      background-color: ${({ theme, $btnStyle, $btnTheme }) =>
        theme.default.buttons[$btnStyle][$btnTheme].pressed.background};
      border: ${({ theme, $btnStyle, $btnTheme }) =>
        theme.default.buttons[$btnStyle][$btnTheme].pressed.border};
      color: ${({ theme, $btnStyle, $btnTheme }) =>
        theme.default.buttons[$btnStyle][$btnTheme].pressed.color};
      opacity: ${({ theme, $btnStyle, $btnTheme }) =>
        theme.default.buttons[$btnStyle][$btnTheme].pressed.opacity};
      box-shadow: ${({ theme, $btnStyle, $btnTheme }) =>
        theme.default.buttons[$btnStyle][$btnTheme].pressed.boxshadow || 'none'};
      transition: all 0.1s;
      a {
        transition: all 0s;
      }
      svg {
        color: ${({ theme, $btnStyle, $btnTheme }) =>
          theme.default.buttons[$btnStyle][$btnTheme].pressed.icon};
      }
    }
    &:disabled {
      background-color: ${({ theme, $btnStyle, $btnTheme }) =>
        theme.default.buttons[$btnStyle][$btnTheme].disabled.background};
      border: ${({ theme, $btnStyle, $btnTheme }) =>
        theme.default.buttons[$btnStyle][$btnTheme].disabled.border};
      color: ${({ theme, $btnStyle, $btnTheme }) =>
        theme.default.buttons[$btnStyle][$btnTheme].disabled.color};
      opacity: ${({ theme, $btnStyle, $btnTheme }) =>
        theme.default.buttons[$btnStyle][$btnTheme].disabled.opacity};
      box-shadow: ${({ theme, $btnStyle, $btnTheme }) =>
        theme.default.buttons[$btnStyle][$btnTheme].disabled.boxshadow || 'none'};
      svg {
        color: ${({ theme, $btnStyle, $btnTheme }) =>
          theme.default.buttons[$btnStyle][$btnTheme].disabled.icon};
      }
    }
  }
`;

const activeStyles = css<{ $btnStyle: string; $btnTheme: string }>`
  &&&,
  &&&:hover {
    background-color: ${({ theme, $btnStyle, $btnTheme }) =>
      theme.default.buttons[$btnStyle][$btnTheme].pressed.background};
    border: ${({ theme, $btnStyle, $btnTheme }) =>
      theme.default.buttons[$btnStyle][$btnTheme].pressed.border};
    color: ${({ theme, $btnStyle, $btnTheme }) =>
      theme.default.buttons[$btnStyle][$btnTheme].pressed.color};
    opacity: ${({ theme, $btnStyle, $btnTheme }) =>
      theme.default.buttons[$btnStyle][$btnTheme].pressed.opacity};
    box-shadow: ${({ theme, $btnStyle, $btnTheme }) =>
      theme.default.buttons[$btnStyle][$btnTheme].pressed.boxshadow || 'none'};
    svg {
      color: ${({ theme, $btnStyle, $btnTheme }) =>
        theme.default.buttons[$btnStyle][$btnTheme].pressed.icon};
    }
  }
`;

const StyledButton = styled(AntdButton)<IStyledButtonProps>`
  ${basicStyles};
  ${buttonStyles};
  ${typeStyles};

  ${({ $active }) => $active && activeStyles};

  ${({ $full }) => $full && 'width: 100% !important'};

  ${({ $secondary, $white }) => $secondary && $white && 'color: white !important'};

  ${({ children }) =>
    children !== undefined &&
    `
		padding: 0 12px;
	`}
`;

export { StyledButton };
