import { NotesDataCtx } from './types';

export const notesInitialValues: NotesDataCtx = {
  fetching: false,
  table: {
    items: [],
    pageSize: 9,
    page: 1,
    loading: false,
    count: 0,
    error: 'false',
  },
  isEmpty: false,
  filters: {
    page_size: '9',
    page: '1',
    search: '',
    from_date: '',
    to_date: '',
    content: '',
    content_id: '',
    ordering: '',
    created_by: '',
    append_results: false,
  },
  noteUsers: [],
  error: null,
};
