import { useState } from 'react';
import Icon from 'components/icons';
import Button from 'components/button';
import SimpleDropdown from 'components/simpleDropdown';
import { Container, Close, Item, Title, Link, Divider } from './ContactSupport.styles';
import { INE_CONTACT_SUPPORT } from 'constants/settings';

const ContactSupport = (): JSX.Element => {
  const [active, setActive] = useState(false);

  const onClickButton = ({ active }) => {
    setActive(active);
  };

  const ButtonContact = (
    <Button $secondary $active={active}>
      Contact support
    </Button>
  );

  const Content = (
    <Container>
      <Close
        onClick={() => {
          setActive(false);
        }}
      >
        <Icon icon="Close" size={'small'} />
      </Close>
      <Title>INE</Title>
      <Item>
        INE Main: <b>1-877-224-8987</b>
      </Item>
      <Item>
        INE Suport: <Link href={`mailto:${INE_CONTACT_SUPPORT}`}>{INE_CONTACT_SUPPORT}</Link>
      </Item>
      <Divider />
      <Title>Quick Links</Title>
      <Link href="https://checkout.shopify.com/17217507/policies/16723909.html">
        Terms of service
      </Link>
      <Link href="https://checkout.shopify.com/17217507/policies/16723845.html">
        Privacy policy
      </Link>
    </Container>
  );

  return (
    <SimpleDropdown
      ButtonElement={ButtonContact}
      buttonOnClick={onClickButton}
      active={active}
      styleElement={{
        bottom: '54px',
        right: '0px',
        width: 'auto',
      }}
    >
      {Content}
    </SimpleDropdown>
  );
};

export default ContactSupport;
