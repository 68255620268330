import { useContext, useState } from 'react';
import { useScrollDirection } from 'react-use-scroll-direction';
import { Col, Row, Timeline } from 'antd';
import { useParams } from 'react-router-dom';
import ButtonIcon from 'components/buttonIcon';
import Icon from 'components/icons';
import { Spinner } from 'components/loadings';
import ModalDrawer from 'components/microLayouts/drawer';
import { ActivityLogContext } from 'contexts/activityLog';
import { SessionContext } from 'contexts/session';
import {
  ActivityLogContainerMobile,
  ActivityLogDrawerContainer,
  ActivityLogMobileTitle,
  NoMoreActivitiesContainer,
  NoMoreActivitiesIconContainer,
} from '../styles';
import ActivityLogFiltersModal, { IModalActivityLogFiltersProps } from './ActivityLogFiltersModal';
import ActivityLogItemMobile from './ActivityLogItemMobile';

interface IActivityLogDrawer {
  activityLogDrawerVisible: boolean;
}

const isInViewport = (el) => {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
  );
};

const ActivityLogDrawer = ({ activityLogDrawerVisible }: IActivityLogDrawer): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const {
    fetching,
    actions,
    table,
    filters: { page },
    isEmpty,
  } = useContext(ActivityLogContext);
  const { isStaff } = useContext(SessionContext);
  const { isScrollingDown, scrollTargetRef } = useScrollDirection();
  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);

  const handleScroll = (e) => {
    if (
      isScrollingDown &&
      !fetching &&
      table.items.length !== table.count &&
      isInViewport(e.target.firstChild.lastChild)
    ) {
      actions.onChangeFilter({ name: 'page', value: (parseInt(page) + 1).toString() });
    }
  };

  const handleClearFilters = () => {
    isStaff ? actions.onResetActivities(id) : actions.onResetActivities();
    setShowFiltersModal(false);
  };

  const handleApplyFilters = (modalFilters: IModalActivityLogFiltersProps) => {
    const newFilters = [
      { name: 'page', value: 1 },
      { name: 'append_results', value: false },
    ];
    Object.keys(modalFilters).map((key) => {
      newFilters.push({ name: key, value: modalFilters[key] });
    });
    actions.onChangeFilters(newFilters);
    setShowFiltersModal(false);
  };

  return (
    <>
      <ModalDrawer
        closable={false}
        placement="bottom"
        visible={activityLogDrawerVisible}
        height="92%"
        destroyOnClose
      >
        <ActivityLogDrawerContainer>
          <Spinner active={fetching} />
          <Row>
            <Col xs={20} style={{ margin: '10px 0px 0px 0px' }}>
              <ActivityLogMobileTitle>Account recent activity</ActivityLogMobileTitle>
            </Col>
            <Col xs={4}>
              <ButtonIcon
                $primary
                icon={'Filter'}
                onClick={() => setShowFiltersModal(true)}
                style={{ float: 'right' }}
              />
            </Col>
          </Row>
          <ActivityLogContainerMobile
            fetching={fetching}
            className="mt-5"
            onScroll={handleScroll}
            ref={scrollTargetRef}
          >
            {!isEmpty && (
              <Timeline>
                {table?.items?.map((item, index) => {
                  return <ActivityLogItemMobile key={index} activity={item} />;
                })}
                {table?.items?.length === table?.count && (
                  <NoMoreActivitiesContainer>
                    <NoMoreActivitiesIconContainer>
                      <Icon icon="Check3" />
                    </NoMoreActivitiesIconContainer>
                    <span>You have no more activity.</span>
                  </NoMoreActivitiesContainer>
                )}
              </Timeline>
            )}
          </ActivityLogContainerMobile>
        </ActivityLogDrawerContainer>
      </ModalDrawer>
      {showFiltersModal && (
        <ActivityLogFiltersModal
          isModalOpen={showFiltersModal}
          onClose={() => setShowFiltersModal(false)}
          onClearFilters={handleClearFilters}
          onApplyFilters={handleApplyFilters}
        />
      )}
    </>
  );
};

export default ActivityLogDrawer;
