import { screenSm, screenXs } from 'constants/styles/breakpoints';
import styled from 'styled-components';

import { Divider, Space, Typography } from 'antd';

export const CourseDifficulty = styled.div`
  display: none;

  ${screenSm} {
    display: initial;

    text-transform: capitalize;
    font-weight: 450;
    font-size: 12px;
    line-height: 14px;
    color: #57575c;
  }
`;
export const GroupItemTitle = styled(Typography.Text)`
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: ${({ theme }) => theme.default.colors.palette.Business_DarkThemeGeneralBackground};

  ${screenXs} {
    font-weight: 450;
    font-size: 14px;
    line-height: 23px;
  }
`;

export const VerticalDivider = styled(Divider).attrs(() => ({
  type: 'vertical',
}))`
  margin: 0;
  border-color: #888;
`;

export const BottomDataWrapper = styled(Space).attrs(() => ({ size: 10 }))`
  display: none;

  ${screenSm} {
    display: flex;
    font-weight: 450;
    font-size: 12px;
    line-height: 14px;
    color: #57575c;
  }
`;

export const GroupItemDataWrapper = styled(Space).attrs(() => ({
  direction: 'vertical',
  size: 5,
}))`
  display: flex;
  flex: 1;
`;

export const GroupItemContainer = styled(Space)`
  display: flex;
  align-self: stretch;
  padding: 10px;
  column-gap: 10px;
  align-items: center;
  background: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #f2f2f7;
  border-radius: 10px;

  ${screenSm} {
    border-radius: 0px 10px 10px 0px;
    border-left-width: 0px;
    padding: 10px 20px 10px 30px;
  }
`;

export const GroupItemRow = styled(Space)`
  height: 80px;

  ${screenSm} {
    height: 92px;
  }
`;

export const ButtonsWrapper = styled(Space).attrs(() => ({ size: 10, align: 'center' }))``;
