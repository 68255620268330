/**
 * `PlaylistCard Container`
 * @param {IPlaylist} playlist optionals components.
 * @returns {JSX.Element} Component.
 */
import { useMemo, useRef, useLayoutEffect, useState } from 'react';
import { format } from 'date-fns';
import {
  Container,
  Content,
  ContentWrapper,
  InfoWrapper,
  DateContainer,
  Description,
  Divider,
  DurationPill,
  Header,
  PlaylistInfo,
  RecommendedDescription,
  RecommendedDescriptionContainer,
  Title,
  AssignedMessage,
} from './styles';
import { IContentGroup, IPlaylist } from 'contexts/playlists/types';
import { ReactComponent as PlaylistIcon } from 'assets/icons/playlist-icon.svg';
import { durationFromSecs } from 'utils/helpers';
import ActionButtons from './ActionButtons';
import { PLAYLIST_TYPE } from 'constants/playlists';
import SonarPlaylistLogo from 'assets/images/sonar-playlist.png';
import AvatarLetter from 'components/avatar/AvatarLetter';
import Icon from 'components/icons';
import Tooltip from 'components/tooltip';

type Props = {
  playlist: IPlaylist;
  onAddUsers: (playlist?: IPlaylist) => void;
  onDeletePlaylist: (playlist?: IPlaylist) => void;
  onEditPlaylist: (playlist?: IPlaylist) => void;
  onClonePlaylist: (playlist?: IPlaylist) => void;
  onAssignUser: (playlist?: IPlaylist) => void;
  onUnassignUser: (playlist?: IPlaylist) => void;
  hideClone?: boolean;
};

const contentLength = (content: IContentGroup[], content_count: number): string => {
  let quantity = 0;

  if (content_count) {
    quantity = content_count;
  } else {
    for (const group of content) {
      quantity = quantity + group.content.length;
    }
  }

  return quantity === 1 ? `${quantity} Activity` : `${quantity} Activities`;
};

const PlaylistCard = ({
  playlist,
  onAddUsers,
  onDeletePlaylist,
  onEditPlaylist,
  onClonePlaylist,
  onAssignUser,
  onUnassignUser,
  hideClone,
}: Props): JSX.Element => {
  const alreadyAssigned = useMemo(() => {
    return playlist?.assigned || !!playlist?.assigned_user_ids?.length;
  }, [playlist]);

  const assignedLabel = useMemo(() => {
    if (!alreadyAssigned) return 'Unassigned';
    if (playlist.type === PLAYLIST_TYPE.SONAR_RECOMMENDED) {
      const isAssigned =
        playlist?.assigned_user_ids?.some((id) => id === playlist?.created_for?.id) ||
        playlist?.assigned;
      if (isAssigned) return 'Assigned';
    }
    const usersCount = playlist?.assigned_user_ids?.length;
    const assignedUsersCount = usersCount > 99 ? 99 : usersCount;
    return `Assigned to ${assignedUsersCount}${usersCount > 99 ? '+' : ''} user${
      assignedUsersCount > 1 ? 's' : ''
    }`;
  }, [playlist]);

  const createdTime = format(new Date(playlist.created), 'MMM dd, yyyy');

  const modifiedTime = format(new Date(playlist.modified), 'MMM dd, yyyy');

  const titleRef = useRef(null);
  const descriptionRef = useRef(null);
  const descriptionTextRef = useRef(null);
  const headerRef = useRef(null);

  const [bothMaxHeight, setBothMaxHeight] = useState(false);
  const [descriptionHeight, setDescriptionHeight] = useState(0);

  useLayoutEffect(() => {
    setDescriptionHeight(descriptionTextRef?.current?.getBoundingClientRect()?.height);
  }, [playlist.description]);

  const descriptionMaxLines = useMemo(() => {
    const DESCRIPTION_LINE_HEIGHT = 24;
    const calculatedMaxLines = Math.floor(descriptionHeight / DESCRIPTION_LINE_HEIGHT);
    return calculatedMaxLines || 3;
  }, [descriptionHeight]);

  const descriptionTooltip = useMemo(() => {
    const MAX_SONAR_DESCRIPTION_LENGTH = 43;
    const MAX_DESCRIPTION_LENGTH = 118;
    if (playlist.type === PLAYLIST_TYPE.SONAR_RECOMMENDED) {
      const descriptionText = playlist.description;
      return descriptionText.length > MAX_SONAR_DESCRIPTION_LENGTH ? undefined : false;
    }
    return playlist.description.length > MAX_DESCRIPTION_LENGTH ? undefined : false;
  }, [playlist.description]);

  const titleTooltip = useMemo(() => {
    const MAX_TITLE_LENGTH = 72;
    const titleText = playlist.name;
    return titleText.length > MAX_TITLE_LENGTH ? undefined : false;
  }, [playlist.name]);

  useLayoutEffect(() => {
    const MAX_HEIGHT = 126;
    const height = headerRef?.current?.getBoundingClientRect().height;
    setBothMaxHeight(height >= MAX_HEIGHT);
  }, [playlist]);

  const isSonarRecommended = PLAYLIST_TYPE.SONAR_RECOMMENDED === playlist.type;

  return (
    <Container>
      <Header color={playlist.color} isSonar={isSonarRecommended}>
        {playlist.duration_in_seconds > 0 && (
          <DurationPill bgColor="#57575C">
            {durationFromSecs(playlist.duration_in_seconds)}
          </DurationPill>
        )}
        {isSonarRecommended ? (
          <img width="170px" src={SonarPlaylistLogo} alt="Sonar Playlist"></img>
        ) : (
          <PlaylistIcon
            width={'100px'}
            height={'70px'}
            stroke={playlist.color}
            viewBox="0 0 17 17"
          />
        )}
      </Header>
      <ContentWrapper>
        <InfoWrapper ref={headerRef}>
          <Tooltip visible={titleTooltip} placement="bottom" overlay={<span>{playlist.name}</span>}>
            <Title ref={titleRef}>{playlist.name}</Title>
          </Tooltip>
          {playlist.description && (
            <Tooltip visible={descriptionTooltip} placement="bottom" overlay={playlist.description}>
              {isSonarRecommended && (
                <RecommendedDescriptionContainer>
                  <AvatarLetter
                    avatar={playlist?.created_for?.avatar}
                    name={playlist?.created_for?.first_name}
                    rounded={true}
                  />
                  {<RecommendedDescription>{playlist.description}</RecommendedDescription>}
                </RecommendedDescriptionContainer>
              )}
              {!isSonarRecommended && (
                <Description
                  ref={descriptionRef}
                  style={{
                    WebkitLineClamp: descriptionMaxLines,
                  }}
                >
                  <div ref={descriptionTextRef}>{playlist.description}</div>
                </Description>
              )}
            </Tooltip>
          )}
        </InfoWrapper>
        <PlaylistInfo>
          <DateContainer>
            <span>
              <strong>Created: </strong>
              {createdTime}
            </span>
            {modifiedTime && (
              <span>
                <strong>Modified: </strong>
                {modifiedTime}
              </span>
            )}
          </DateContainer>
          <Content>
            <div>{contentLength(playlist.content || [], playlist.content_count)}</div>
            {alreadyAssigned ? (
              <AssignedMessage>
                <Icon icon="Check2" size="small" stroke={'#009D6F'} />
                <span>{assignedLabel}</span>
              </AssignedMessage>
            ) : (
              <AssignedMessage>{assignedLabel}</AssignedMessage>
            )}
          </Content>
        </PlaylistInfo>
        <Divider flat={bothMaxHeight} />
        <ActionButtons
          isSonar={isSonarRecommended}
          alreadyAssigned={alreadyAssigned}
          playlist={playlist}
          onAddUsers={onAddUsers}
          onDeletePlaylist={onDeletePlaylist}
          onEditPlaylist={onEditPlaylist}
          onClonePlaylist={onClonePlaylist}
          onAssignUser={onAssignUser}
          onUnassignUser={onUnassignUser}
          hideClone={hideClone}
        />
      </ContentWrapper>
    </Container>
  );
};

export default PlaylistCard;
