import { ActivityLogFiltersContainer } from '../styles';
import ActivityLogDatesFilter from './ActivityLogDatesFilter';
import ActivityLogTypesFilter from './ActivityLogTypesFilter';
import ActivityLogUsersFilter from './ActivityLogUsersFilter';

const ActivityLogFilters = (): JSX.Element => {
  return (
    <ActivityLogFiltersContainer>
      <ActivityLogDatesFilter />
      <ActivityLogTypesFilter />
      <ActivityLogUsersFilter />
    </ActivityLogFiltersContainer>
  );
};

export default ActivityLogFilters;
