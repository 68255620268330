import styled from 'styled-components';
import { screenMd } from 'constants/styles/breakpoints';
import { PopoverTitle } from 'components/popover';

export const AvatarContainer = styled.span`
  padding-right: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${screenMd} {
    padding: 0;
    padding-bottom: 40px;
  }
  > div,
  > div > div {
    max-width: 34px;
    max-height: 34px;
    ${screenMd} {
      max-width: 57px;
      max-height: 57px;
    }
  }
`;

export const CustomStylesPopover = styled.div`
  .ant-popover-arrow,
  .ant-popover-arrow-content {
    width: 16px;
    height: 16px;
  }
  .ant-popover-placement-rightBottom .ant-popover-arrow {
    bottom: 24px;
  }
  .ant-popover-content {
    box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.15);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CustomPopoverTitle = styled(PopoverTitle)`
  margin-bottom: 5px;
`;
