import { screenLg, screenMd, screenSm } from 'constants/styles/breakpoints';
import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  padding: 10px;
  margin-bottom: 20px;
  background-color: #fff;
  margin-top: 5px;

  ${screenSm} {
    margin: 1px 0 0 0;
  }
  ${screenLg} {
    border-radius: 12px;
    padding: 30px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  justify-content: space-between;
  grid-gap: 10px;

  ${screenMd} {
    grid-gap: 20px;
    align-items: center;
    flex-direction: row;
  }

  button {
    padding: 0 24px !important;
  }
`;

export const Content = styled.div<{ grow?: number }>`
  flex-grow: ${({ grow }) => grow};
`;

export const Title = styled.h4`
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  margin: 0;
  color: ${({ theme }) => theme.default.colors.palette.Business_DarkThemeGeneralBackground};
`;

export const SelectedCounts = styled.p`
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin: 0;
  color: ${({ theme }) => theme.default.colors.palette.Business_LightThemeGrayDark};
`;

export const ActionButton = styled.div`
  position: fixed;
  bottom: 80px;
  left: 1rem;
  right: 1rem;
  z-index: 900 !important;
  button {
    width: 100%;
  }

  ${screenMd} {
    position: static;
    bottom: auto;
    left: auto;
    right: auto;
    z-index: 0 !important;
    .button {
      width: auto;
    }
  }
`;
