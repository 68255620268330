import { useContext } from 'react';
import { isBrowser, isMobile } from 'react-device-detect';
import { TeamsContext } from 'contexts/teams';
import Button from 'components/button/Button';
import SearchFilter from './Search';
import {
  ActionButton,
  Container,
  Content,
  Header,
  SelectedCounts,
  Title,
} from 'components/layout/tableContentHeader/styles';
import { SessionContext } from 'contexts/session';
import { ROLES } from 'constants/roles';
import useManageTeam from '../hooks/useManageTeam';
import useInviteToTeam from '../hooks/useInviteToTeam';
import { DeepTeams, Team } from 'contexts/teams/types';

const UserHeaderFilters: React.FC = (): JSX.Element => {
  const { selectedItems, actions: teamsActions } = useContext(TeamsContext);
  const {
    userRoleIds,
    teams,
    actions: sessionActions,
    account,
    licenses_available,
  } = useContext(SessionContext);
  const { manageTeamModal, closeManageTeamModal, openManageTeamModal } = useManageTeam();
  const { inviteToTeamModal, openInviteToTeamModal, closeInviteToTeamModal } = useInviteToTeam();
  const isManager = userRoleIds.includes(ROLES.TEAM_MANAGER);
  const countRows = selectedItems?.length || 0;

  const handleRemoveTeams = () => {
    teamsActions.onRemoveTeam(selectedItems);
  };

  const handleCreateTeam = () => {
    const handleCloseCreateTeam = (teamData) => {
      if (teamData) {
        closeManageTeamModal();
        sessionActions.updateAccountTeams((prev) => [...prev, teamData]);
        teamsActions.onForceTeamsRequest();
        handleInviteToTeam(teamData);
      }
      closeManageTeamModal();
    };

    openManageTeamModal({
      team: null,
      existingTeams: teams,
      onClose: handleCloseCreateTeam,
    });
  };

  const handleInviteToTeam = (team: Team | DeepTeams) => {
    const handleInviteFinish = (afterInvite) => {
      if (afterInvite) {
        if (!licenses_available) {
          sessionActions.refreshSession();
        }
        teamsActions.onForceTeamsRequest();
      }
      closeInviteToTeamModal();
    };
    openInviteToTeamModal({
      onClose: handleInviteFinish,
      team: team,
      account: account,
      isInvite: false,
    });
  };

  return (
    <>
      <Header>
        <Container>
          {isBrowser && (
            <Content>
              <Title>Teams</Title>
              {!isMobile && <SelectedCounts>{countRows} teams selected</SelectedCounts>}
            </Content>
          )}

          <Content grow={2}>
            <SearchFilter />
          </Content>

          {countRows > 0 && (
            <Content>
              <Button
                $secondary
                $full={isMobile}
                size={isMobile ? 'small' : null}
                onClick={handleRemoveTeams}
              >
                Remove team(s)
              </Button>
            </Content>
          )}

          <ActionButton>
            <Button $primary={true} disabled={isManager} onClick={handleCreateTeam} icon={'Plus'}>
              Create team
            </Button>
          </ActionButton>

          {manageTeamModal}
          {inviteToTeamModal}
        </Container>
      </Header>
    </>
  );
};

export default UserHeaderFilters;
