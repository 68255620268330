import { Space, Typography } from 'antd';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import ContentTypeTag from '../../../components/AddContent/ContentTypeTag';
import { screenSm } from 'constants/styles/breakpoints';
import { durationFromSecs } from 'utils/helpers';
import {
  BottomDataWrapper,
  GroupItemDataWrapper,
  GroupItemTitle,
  VerticalDivider,
} from '../styles';
import { IContentItem } from 'contexts/playlists/types';
import { CourseLearningArea } from '../../styles';

interface ILearningPathItemData {
  data: IContentItem;
  toggleDetails?(): void;
}

const CourseDifficulty = styled(Typography.Text)`
  display: none;

  ${screenSm} {
    display: initial;

    text-transform: capitalize;
    font-weight: 450;
    font-size: 12px;
    line-height: 14px;
    color: #57575c;
  }
`;

const LearningPathItemData = ({ data, toggleDetails }: ILearningPathItemData): JSX.Element => {
  const {
    content_type,
    difficulty,
    name,
    duration_in_seconds,
    main_learning_area,
    learning_areas,
    instructors,
  } = data;
  const learningAreaName = main_learning_area || learning_areas?.[0];
  const instructorName = instructors?.[0]?.name || '';

  const handleMobileToogle = () => {
    isMobile && toggleDetails();
  };

  return (
    <>
      <GroupItemDataWrapper onClick={handleMobileToogle}>
        <Space>
          <ContentTypeTag value={content_type} />
          <CourseDifficulty>{difficulty}</CourseDifficulty>
        </Space>
        <GroupItemTitle>{name}</GroupItemTitle>
        <BottomDataWrapper>
          <CourseLearningArea value={learningAreaName}>{learningAreaName}</CourseLearningArea>
          <VerticalDivider />
          {instructorName}
          <VerticalDivider />
          {durationFromSecs(duration_in_seconds)}
        </BottomDataWrapper>
      </GroupItemDataWrapper>
    </>
  );
};

export default LearningPathItemData;
