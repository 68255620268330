import { createContext } from 'react';
import { FCProps } from 'types/FCProps';

import { IReportsDataCtx } from './types';
import { reportsInitialValues } from './utils';

import useReportingContent from 'hooks/useReportingContent';

export const ReportsContext = createContext<IReportsDataCtx>(reportsInitialValues);

const ReportsProvider = ({ children }: FCProps): JSX.Element => {
  const {
    fetching,
    fetchingUsers,
    reports,
    users,
    activeFilters,
    setActiveFilters,
    getReports,
    generateNewReport,
    getReportsFilteringPreferences,
    saveReportsFiltersPreferences,
    getUsers,
    error,
  } = useReportingContent();

  return (
    <ReportsContext.Provider
      value={{
        fetching,
        fetchingUsers,
        reports,
        users,
        activeFilters,
        setActiveFilters,
        getReports,
        generateNewReport,
        getReportsFilteringPreferences,
        saveReportsFiltersPreferences,
        getUsers,
        error,
      }}
    >
      {children}
    </ReportsContext.Provider>
  );
};

export default ReportsProvider;
