import React, { useEffect, useState } from 'react';

import Modal from 'components/modal';
import AddByEmails from './AddByEmails';
import ToggleButtons from 'components/toggleButtons/toggleButtons';
import { BodyContainer, HeaderContainer, InnerContainer, Title } from './styles';
import { MODAL_SIZES } from 'components/modal/types';
import 'react-multi-email/style.css';
import { isMobile } from 'react-device-detect';
import AddByLink from './AddByLink/AddByLink';
import { Invite } from './AddByEmails/invitesReducer';
import { Domains } from 'contexts/accounts/types';

interface IInviteUsers {
  isEditingInvite?: boolean;
  onClose: (result?: 'ok' | 'error', invites?: Invite[]) => void;
  inviteAccOwner?: boolean;
  account: AccountInvite;
}

export interface AccountInvite {
  id: string;
  licenses_available?: number | boolean;
  email_domains?: Domains[];
  name?: string;
}

enum InviteType {
  BY_EMAIL = 'by_email',
  BY_LINK = 'by_link',
}

const options = {
  firstOption: { value: 'Invite by Email', id: InviteType.BY_EMAIL },
  secondOption: { value: 'Invite by Link', id: InviteType.BY_LINK },
};

const InviteUsers: React.FC<IInviteUsers> = ({
  isEditingInvite,
  onClose,
  inviteAccOwner,
  account,
}) => {
  const [step, setStep] = useState(0);
  const [inviteType, setInviteType] = useState<InviteType>(InviteType.BY_EMAIL);
  const title = inviteAccOwner
    ? `Invite users to join ${account?.name} account`
    : 'Invite users to join INE Business';

  const modalSize =
    (step === 0 && MODAL_SIZES.SMALL) ||
    (step === 1 && MODAL_SIZES.LARGE) ||
    (step === 2 && MODAL_SIZES.LARGE) ||
    (step === 3 && MODAL_SIZES.SMALL);

  useEffect(() => {
    setStep(0);
  }, []);

  const handleInvitesSubmmit = (ok: boolean, invites?: Invite[]) => {
    onClose(ok ? 'ok' : 'error', invites);
  };

  return (
    <Modal
      centered
      onCancel={() => onClose()}
      visible={isEditingInvite}
      fullscreen={isMobile}
      footer={null}
      closeIconColor="#FFF"
      width={isMobile ? '100%' : modalSize}
    >
      <InnerContainer>
        <HeaderContainer>
          <Title>{title} </Title>
          {!inviteAccOwner && (
            <ToggleButtons
              {...options}
              onChange={(option) => setInviteType(option as InviteType)}
            />
          )}
        </HeaderContainer>
        <BodyContainer>
          {inviteType === InviteType.BY_EMAIL && (
            <AddByEmails
              onStepChange={(step) => setStep(step)}
              onInvitesSubmit={handleInvitesSubmmit}
              inviteAccOwner={inviteAccOwner}
              account={account}
            />
          )}
          {inviteType === InviteType.BY_LINK && <AddByLink handleBackPress={() => onClose()} />}
        </BodyContainer>
      </InnerContainer>
    </Modal>
  );
};

export default InviteUsers;
