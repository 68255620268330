import { useState } from 'react';
import {
  CopySonarLinkBtn,
  EmptySkillSubTitle,
  EmptySkillTitle,
  EmptySkillsImg,
  SkillsEmptyContainer,
  SkillsEmptyWrapper,
} from './styles';
import EmptySkillsSvg from '../../../../assets/icons/skills/skills_empty.svg';
import Icon from 'components/icons';
import Tooltip from 'components/tooltip';

const SkillsEmpty = (): JSX.Element => {
  const [btnClicked, setBtnClicked] = useState(false);

  const handleCopyBtnClick = () => {
    if (!btnClicked) {
      navigator.clipboard.writeText(`${process.env.REACT_APP_PUBLIC_URL}/sonar`);
      setBtnClicked(true);
      setTimeout(() => {
        setBtnClicked(false);
      }, 2000);
    }
  };

  return (
    <SkillsEmptyContainer>
      <SkillsEmptyWrapper>
        <div>
          <EmptySkillsImg src={EmptySkillsSvg} />
        </div>
        <EmptySkillTitle>Where do your team’s skills stack up?</EmptySkillTitle>
        <EmptySkillSubTitle>
          Skill Sonar detects your team’s knowledge strengths and gaps through short hands-on
          assessments providing the insight needed to develop the skills that are lacking.
        </EmptySkillSubTitle>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Tooltip
            placement="topRight"
            destroyTooltipOnHide
            title={btnClicked ? 'Copied!' : 'Copy'}
          >
            <CopySonarLinkBtn $secondary onClick={handleCopyBtnClick}>
              <div className="linkContainer">
                <span>Copy link</span> : https://my.ine.com/sonar
              </div>
              <div
                className={
                  btnClicked ? 'copyIconContainer copyIconContainerClicked' : 'copyIconContainer'
                }
              >
                <Icon icon={btnClicked ? 'Check3' : 'Copy'} />
              </div>
            </CopySonarLinkBtn>
          </Tooltip>
        </div>
      </SkillsEmptyWrapper>
    </SkillsEmptyContainer>
  );
};

export default SkillsEmpty;
