import { useContext } from 'react';
import { isMobile } from 'react-device-detect';
import FiltersModal from './FiltersModal';
import UsersAsideFiltersStatus from 'components/usersAsideFilters/Status';
import { SessionContext } from 'contexts/session';
import ButtonIcon from 'components/buttonIcon';
import { UsersContext } from 'contexts/users';

const UserMobileTopBar = (): JSX.Element => {
  const { showDrawer, setVisibiltyDrawer } = useContext(SessionContext);
  const { filters } = useContext(UsersContext);

  const withFilters =
    filters.license.length > 0 || filters.roles.length > 0 || filters.team.length > 0;

  return (
    <div
      className="d-flex d-md-none align-items-center justify-content-between mt-2 mb-4"
      style={{ padding: '2px 5px' }}
    >
      <UsersAsideFiltersStatus direction="row" />

      <ButtonIcon
        $secondary
        $active={withFilters}
        icon={'Filter'}
        size={'small'}
        onClick={() => setVisibiltyDrawer(true)}
      />
      {isMobile && <FiltersModal open={showDrawer} close={() => setVisibiltyDrawer(false)} />}
    </div>
  );
};

export default UserMobileTopBar;
