import {
  AvatarContainer,
  ProfileData,
  ProfileHeaderActions,
  ProfileHeaderCard,
  ProfileHeaderGradient,
} from 'components/layout/profile/profileHeader/styles';
import { AccountUsersHeaderProps } from 'contexts/accounts/types';
import AccountDefaultLogo from 'assets/images/account-avatar.svg';
import { formatDateInUtc } from 'utils/helpers';

const AccountUsersHeader = ({ account }: AccountUsersHeaderProps): JSX.Element => {
  const initAt = formatDateInUtc(new Date(account.start_date), 'P');

  return (
    <ProfileHeaderCard>
      <ProfileHeaderGradient>
        <AvatarContainer>
          <img src={account.logo_url || AccountDefaultLogo} />
        </AvatarContainer>
        <ProfileData>
          <h2>{account.name}</h2>
          <p>Plan started at: {initAt}</p>
        </ProfileData>
      </ProfileHeaderGradient>
      <ProfileHeaderActions></ProfileHeaderActions>
    </ProfileHeaderCard>
  );
};

export default AccountUsersHeader;
