export const ROLES = {
  ACCOUNT_OWNER: '3b1f8e3d-0d0b-42be-93fb-459116fd2a24',
  TEAM_MANAGER: '7450cda1-7bbc-4cd5-a161-34b253813f94',
  SALES: '083d6d1c-5569-47d1-8ae9-f3aee2a119f5',
  SUPPORT: '7e70c521-45a1-45c1-8dd0-a186070ec44c',
  ADMIN: 'c71e91fd-f127-403f-a7c1-b8963db8efc8',
  STAFF: 'c835ccfa-e7a5-41ce-afd0-9cd4916cd28f',
};

export const TEAM_MANAGER = 'Team Manager';
export const END_USER = 'END_USER';
export const ACC_OWNER = 'Account Owner';
export const SUPPORT = 'Support';
export const STAFF = 'Staff';
export const ADMIN = 'Admin';
export const SALES = 'Sales';
