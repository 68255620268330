import React, { useState } from 'react';
import { ReactComponent as HamburguerIcon } from 'assets/icons/hamburger-menu.svg';
import styled from 'styled-components';
import SimpleDropdown from 'components/simpleDropdown';
import { isMobile } from 'react-device-detect';
import AvatarMenuItem from 'components/avatarMenuItem';
import { toolbarMenuItems } from './utils';
import { removeAllTokens } from '@internetworkexpert/js-common';
import { INE_URL } from 'constants/domains';
const BottomMenuItemContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 100%;
  background: linear-gradient(89.99deg, #863ad2 3.58%, #5936e4 97.14%);
  a,
  span {
    color: #fff;
    text-align: center;
    font-weight: bold;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
    cursor: pointer;
    &:hover {
      background: rgba(0, 0, 0, 0.2);
    }
  }
`;

const BottomMenuItem = ({ link, label }: { link?: string; label?: string }): JSX.Element => {
  const removeAccessCookie = () => {
    removeAllTokens();
    window.location.replace(link);
  };
  return (
    <BottomMenuItemContainer>
      <span onClick={removeAccessCookie}>{label}</span>
    </BottomMenuItemContainer>
  );
};

const ItemLink = styled.a`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  width: 100%;
  padding: 7.5px 20px;
  font-family: 'Circular-Std';
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${({ theme }) => theme.default.colors.palette.Secondary_LightBlueGray};
  cursor: pointer;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    background-color: ${({ theme }) => theme.default.colors.palette.Business_LightThemeGreyDarker};
    color: ${({ theme }) => theme.default.colors.palette.Secondary_LightBlueGray};
    text-decoration: none;
  }
`;

const ItemLinkContainer = styled.div`
  padding-bottom: 17px;
`;

const ProfileMenuItems = (): JSX.Element => {
  return (
    <ItemLinkContainer>
      {toolbarMenuItems.map((item, index) => (
        <ItemLink href={item.href} key={index}>
          {item.text}
        </ItemLink>
      ))}
    </ItemLinkContainer>
  );
};

const ProfileMenu = (): JSX.Element => {
  const [active, setActive] = useState(false);

  const onClickProfile = ({ active }) => {
    setActive(active);
  };

  const ButtonProfile = (
    <button className={'btn btn-menu btn-menu-light ' + (active ? ' active' : '')}>
      <HamburguerIcon className="btn-menu-icon h-100" />
    </button>
  );

  return (
    <SimpleDropdown
      dark={true}
      ButtonElement={ButtonProfile}
      buttonOnClick={onClickProfile}
      styleElement={{
        top: '68px',
        right: '-3px',
        width: isMobile ? 'calc(100vw - 24px)' : '280px',
      }}
    >
      <AvatarMenuItem />
      <ProfileMenuItems />
      <BottomMenuItem link={INE_URL} label={'Sign out'} />
    </SimpleDropdown>
  );
};

export default ProfileMenu;
