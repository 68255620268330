import styled from 'styled-components';
import { formatDistanceToNowStrict } from 'date-fns';
import { Team } from 'contexts/teams/types';
import { IconTeam } from 'components/teamSelect/IconTeam';

interface ItemTeamProps {
  data: Team;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 15px;
  overflow: hidden;
  padding-right: 10px;
`;

const Name = styled.p`
  margin: 0;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 3px;
  color: ${({ theme }) => theme.default.colors.palette.Business_DarkThemeGeneralBackground};
  &:first-letter {
    text-transform: uppercase;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const LastActivity = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.default.colors.palette.Business_LightThemeGrayDark};
`;

const Avatar = (props: ItemTeamProps): JSX.Element => {
  const { data } = props;

  const customDiffLabel = formatDistanceToNowStrict(new Date(data.last_activity));

  return (
    <Container>
      <IconTeam iconName={data.icon?.name} color={data.icon?.color} size="default" />
      <Content>
        <Name>{data.name}</Name>
        <LastActivity>{'Last activity: ' + customDiffLabel + ' ago'}</LastActivity>
      </Content>
    </Container>
  );
};

export default Avatar;
