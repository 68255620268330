import styled from 'styled-components';

export const ImageDiv = styled.div<{ urlImage: string; fixed: boolean }>`
  width: 50px;
  height: 50px;
  background-image: url(${({ urlImage }) => urlImage});
  border-radius: 50%;
  background-size: cover;
  background-position: 50% 50%;
  ${({ fixed }) => fixed && 'position: fixed'}
`;

export const ChartLabel = styled.text`
  font-family: Circular-Std;
  color: #57575c;
  width: 100%;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-top: 5px;
`;

export const ImageAndNameContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
