import styled from 'styled-components';

export const StepsContainer = styled.div`
  display: flex;
`;

export const Step = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  flex: 1 1 50%;
  margin-bottom: 20px;
  border-bottom: 5px solid ${({ active }) => (active ? 'var(--lilac)' : 'var(--grey-lighter)')};
  transition: border-bottom 0.3s;
  span {
    background: ${({ active }) =>
      active ? 'var(--secondary-promo-purple)' : 'var(--light-blue-gray)'};
  }
`;

export const StepNumber = styled.span`
  font-size: 12px;
  line-height: 14px;
  display: flex;
  color: var(--white);
  width: 17px;
  height: 16px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding-left: 0.5px;
`;

export const StepTitle = styled.div`
  color: var(--black);
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 10px;
`;
