import { FC } from 'react';
import styled from 'styled-components';
import { screenMd } from 'constants/styles/breakpoints';
import Tooltip from 'components/tooltip';
import Icon from 'components/icons';

const ItemCount = styled.div<{ unlimited: boolean }>`
  position: relative;
  border: 1px solid #e9e9ed;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  min-width: 95px;

  ${screenMd} {
    min-width: 106px;
    padding: 10px;
    &:first-child {
      display: flex;
    }
  }
  > .item-count {
    font-size: 42px;
    font-weight: 700;
    color: #000;
    line-height: 38px;
    ${({ unlimited }) =>
      unlimited &&
      `
			font-family: sans-serif !important;
			font-weight: bolder !important;
		`}
  }
  > .item-label {
    font-size: 15px;
    font-weight: 400;
    color: ${({ theme }) => theme.default.colors.fonts.darkGray};
  }
  > .item-tooltip {
    position: absolute;
    top: 4px;
    right: 4px;
  }
`;

type LicenseDataItemProps = {
  count: number | string;
  label: string;
  tooltip?: string;
};

const LicenseDataItem: FC<LicenseDataItemProps> = ({ count, label, tooltip }) => (
  <ItemCount unlimited={count === '∞'}>
    {tooltip && (
      <Tooltip
        className="item-tooltip"
        placement="right"
        title={tooltip}
        overlayInnerStyle={{ textAlign: 'center' }}
      >
        <div>
          <Icon data-testid="info-icon" icon="Info" fill={'var(--lilac)'} size="smaller" />
        </div>
      </Tooltip>
    )}
    <span className="item-count">{count}</span>
    <span className="item-label">{label}</span>
  </ItemCount>
);

export default LicenseDataItem;
