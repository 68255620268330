import { useState } from 'react';
import { Radio as AntDRadio, Col, Row } from 'antd';
import Radio from 'components/radio';
import PlanType from './PlanType';
import { Label } from 'components/modal/styles';
import { PricingPlan } from 'contexts/pricingPlans/types';

interface AccountsPlansProps {
  onPricingPlanChange(plan: PricingPlan): void;
  error: boolean;
  pricingPlan: string;
  isEditing?: boolean;
  pricingPlans: PricingPlan[];
}

const AccountsPlans: React.FC<AccountsPlansProps> = ({
  onPricingPlanChange,
  pricingPlan,
  isEditing,
  error,
  pricingPlans,
}) => {
  const defaultCheckedPlan = pricingPlan
    ? pricingPlans.find((plan) => plan.name === pricingPlan)
    : null;
  const [active, setActive] = useState(defaultCheckedPlan?.id);

  const handlePlanSelected = (id: string) => {
    const plan = pricingPlans.find((plan) => plan.id === id);
    onPricingPlanChange(plan);
  };

  return (
    <AntDRadio.Group
      onChange={(e) => handlePlanSelected(e.target.value)}
      defaultValue={defaultCheckedPlan?.id}
      style={{ width: '100%' }}
    >
      <Label>Plan</Label>
      <Row gutter={[20, 20]}>
        {pricingPlans.map((plan) => (
          <Col span={isEditing ? 24 : 12} onClick={() => setActive(plan.id)} key={plan.id}>
            <Row>
              <Radio id={plan.id} value={plan.id} />
              <PlanType
                error={error}
                type={plan.code}
                active={active === plan.id}
                label={plan.name}
              />
            </Row>
          </Col>
        ))}
      </Row>
    </AntDRadio.Group>
  );
};

export default AccountsPlans;
