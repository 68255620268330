import { FC, useEffect, useState } from 'react';
import { getAgregationAction } from 'contexts/analytics/actions';
import { TabWrapper } from 'pages/Analytics/components/MetricTabs/styles';
import {
  DataContainer,
  DataDescription,
  DataNumber,
  IconWrapper,
  TabButton,
  TabIcon,
  TabsContainer,
  TabsTitle,
} from './styles';
import { ActivitiesChartTabsProps } from './types';

const ActivitiesChartTabs: FC<ActivitiesChartTabsProps> = ({
  aggregationTabs,
  vertical,
  title,
  onTabChanged,
}) => {
  const [counters, setCounters] = useState(null);
  const [currentTab, setCurrentTab] = useState(aggregationTabs[0].name);

  useEffect(() => {
    processAggregationsData();
  }, []);

  const processAggregationsData = async () => {
    aggregationTabs.map(async ({ name, params }) => {
      try {
        const response = await getAgregationAction(params);
        setCounters((prevState) => {
          if (response instanceof Error) {
            return { ...prevState, [name]: '-' };
          } else {
            return {
              ...prevState,
              [name]: typeof response.results === 'number' ? response.results : '-',
            };
          }
        });
      } catch (error) {
        setCounters((prevState) => ({
          ...prevState,
          [name]: '-',
        }));
      }
    });
  };
  const handleChangeTab = (tab: string) => {
    setCurrentTab(tab.toString());
    onTabChanged(tab.toString());
  };

  return (
    <TabsContainer vertical={vertical}>
      {title && <TabsTitle>{title}</TabsTitle>}
      {aggregationTabs.map(({ name, label, icon }) => (
        <TabButton
          vertical={vertical}
          key={name}
          active={currentTab === name}
          onClick={() => {
            handleChangeTab(name);
          }}
        >
          <TabWrapper>
            <IconWrapper>
              <TabIcon src={icon} />
            </IconWrapper>
            <DataContainer vertical={vertical}>
              <DataNumber>{counters?.[name] ?? '-'}</DataNumber>
              <DataDescription>{label}</DataDescription>
            </DataContainer>
          </TabWrapper>
        </TabButton>
      ))}
    </TabsContainer>
  );
};

export default ActivitiesChartTabs;
