import { createContext } from 'react';
import { ActivityLogDataCtx } from './types';
import { activitiesLogInitialValues } from './utils';
import { FCProps } from 'types/FCProps';
import useActivityLogData from 'hooks/useActivityLogData';

export const ActivityLogContext = createContext<ActivityLogDataCtx>(activitiesLogInitialValues);

const ActivityLogProvider = ({ children }: FCProps): JSX.Element => {
  const {
    fetching,
    activitiesLogTable,
    isEmpty,
    filters,
    activityLogUsers,
    onChangeFilter,
    onChangeFilters,
    onChangeRangeFilters,
    onRefreshActivities,
    onRetrieveActivityLogUsers,
    onResetActivities,
  } = useActivityLogData();

  return (
    <ActivityLogContext.Provider
      value={{
        fetching,
        isEmpty,
        table: activitiesLogTable,
        filters,
        activityLogUsers,
        actions: {
          onChangeFilter,
          onChangeFilters,
          onChangeRangeFilters,
          onRefreshActivities,
          onRetrieveActivityLogUsers,
          onResetActivities,
        },
      }}
    >
      {children}
    </ActivityLogContext.Provider>
  );
};

export default ActivityLogProvider;
