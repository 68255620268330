import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Modal from 'components/modal';

// import { InnerContainer, HeaderContainer, Title, BodyContainer } from './styles';
import { isMobile } from 'react-device-detect';
import { TeamNameInput } from './TeamNameInput';
import {
  ModalContainer,
  HeaderContainer,
  ModalContent,
  ModalSectionTitle,
  ModalSection,
  ModalActions,
} from 'components/modal/styles';
import { TeamColorSelect } from './TeamColorSelect';
import { TeamIconSelect } from './TeamIconSelect';
import { colors } from 'constants/styles/colors';
import { TeamIcon } from 'components/teamSelect/IconTeam';
import Button from 'components/button';
import { createTeam, updateTeam } from 'services/teams';
import { DeepTeams, Team } from 'contexts/teams/types';
import Notification from 'components/notification';

interface ICreateTeam {
  isVisible?: boolean;
  team?: Team | DeepTeams;
  onClose?: (team?: Team) => void;
  existingTeams?: Team[];
}

const CreateTeam: React.FC<ICreateTeam> = ({ isVisible, team, onClose, existingTeams }) => {
  const title = team ? 'Edit team' : 'Create a new team';
  const ctaLabel = team ? 'Save changes' : 'Create Team';
  const history = useHistory();
  const [name, setName] = useState(team?.name || '');
  const [color, setColor] = useState<keyof typeof colors.dataVisualization>(
    team?.icon?.color || 'purple'
  );
  const [icon, setIcon] = useState<TeamIcon>(team?.icon?.name || 'board');
  const [loading, setLoading] = useState(false);

  const updateTeamName = (value: string) => {
    value.length <= 50 && setName(value);
  };

  const updateColor = (color: keyof typeof colors.dataVisualization) => {
    setColor(color);
  };

  const updateIcon = (icon) => {
    setIcon(icon);
  };

  const handleTeamCreation = async (data: Team) => {
    setLoading(true);
    if (existingTeams?.find((team) => team.name === name.replace(/\s+$/g, ''))) {
      Notification({
        text: "There's already created another team with the same name",
        type: 'error',
      });
      setLoading(false);
    } else {
      try {
        const response = await createTeam(data);
        if (response.status === 201) {
          const notificationMsg = (
            <span>
              {`${response.data?.name} team created. `}
              <span
                className="notification-link"
                onClick={() => history.push(`/team/${response.data?.id}`)}
              >
                Go to team
              </span>
            </span>
          );
          Notification({ text: notificationMsg, type: 'success', duration: 8 });
          onClose(response.data);
          return;
        } else {
          Notification({ text: 'There has been a problem creating your team', type: 'error' });
        }
      } catch (error) {
        if (error?.response?.data?.details) {
          Notification({ text: error.response.data.details, type: 'error' });
        }
      } finally {
        setLoading(false);
      }
      onClose();
    }
  };

  const handleTeamEdit = async (data) => {
    setLoading(true);
    try {
      const response = await updateTeam({ ...data, id: team.id });
      if (response.status === 200) {
        Notification({ text: `${response.data.name} has been edited`, type: 'success' });
        onClose(response.data);
        return;
      } else {
        Notification({ text: 'There has been a problem editing your team', type: 'error' });
      }
    } catch (error) {
      Notification({ text: 'There has been a problem editing your team', type: 'error' });
    } finally {
      setLoading(false);
    }
    onClose();
  };

  const handleConfirm = () => {
    const reqData: Team = {
      name: name.replace(/\s+$/g, '') || 'No name',
      icon: {
        name: icon,
        color: color,
      },
    };
    team ? handleTeamEdit(reqData) : handleTeamCreation(reqData);
  };

  return (
    <Modal
      centered
      visible={isVisible}
      fullscreen={isMobile}
      onCancel={() => onClose()}
      width={isMobile ? '100%' : '595px'}
      footer={null}
      closeIconColor="#FFF"
      maskClosable={false}
    >
      <ModalContainer>
        <HeaderContainer>
          <h2>{title}</h2>
        </HeaderContainer>
        <ModalContent>
          {!team && <ModalSectionTitle>Customize your team</ModalSectionTitle>}
          <ModalSection>
            <TeamNameInput
              team={!!team}
              teamName={name}
              setTeamName={(value) => updateTeamName(value)}
            />
          </ModalSection>
          <ModalSection>
            <TeamColorSelect
              active={color}
              onChange={(color: keyof typeof colors.dataVisualization) => updateColor(color)}
            />
          </ModalSection>
          <ModalSection>
            <TeamIconSelect active={icon} color={color} onChange={(icon) => updateIcon(icon)} />
          </ModalSection>
        </ModalContent>
        <ModalActions>
          <Button
            $primary
            disabled={!/^(?!\s*$).+/g.test(name)}
            style={{ marginLeft: 'auto' }}
            onClick={handleConfirm}
            loading={loading}
          >
            {ctaLabel}
          </Button>
        </ModalActions>
      </ModalContainer>
    </Modal>
  );
};

export default CreateTeam;
