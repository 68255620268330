import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { useContext } from 'react';
import { TeamsContext } from 'contexts/teams';
import Avatar from './Avatar';
import Active from './Active';
import Manager from './Manager';
import Pending from './Pending';
import Checkbox from 'components/checkbox';
import Tooltip from 'components/tooltip';
import { Cell, Pagination, Row, Table, Top } from 'components/Table';
import { screenMd } from 'constants/styles/breakpoints';
import { Team } from 'contexts/teams/types';
import SearchEmptyState from 'components/searchEmptyState';
import { HeaderLoading } from 'components/loadings';
import TeamsSettings from './TeamsSettings';
import { SessionContext } from 'contexts/session';
import useManageTeam from '../hooks/useManageTeam';
import useInviteToTeam from '../hooks/useInviteToTeam';
import ButtonIcon from 'components/buttonIcon';
import { ActionButtonsContainer } from 'components/usersTable/styles';
import Icon from 'components/icons';

export const Container = styled.div<{ lock: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 140px;

  ${screenMd} {
    padding-bottom: 0;
  }

  ${({ lock }) =>
    lock &&
    `
		opacity: 0.4;
		pointer-events: none;
	`}
`;

const TeamsTable = ({ lock }: { lock: boolean }): JSX.Element => {
  const {
    table,
    actions: teamsTableActions,
    selectedItems,
    isEmpty,
    fetching,
    filters: { page_size, page },
  } = useContext(TeamsContext);
  const {
    teams,
    actions: sessionActions,
    account,
    licenses_available,
    isImpersonated,
  } = useContext(SessionContext);
  const { manageTeamModal, openManageTeamModal, closeManageTeamModal } = useManageTeam();
  const { inviteToTeamModal, openInviteToTeamModal, closeInviteToTeamModal } = useInviteToTeam();
  const countRows = selectedItems?.length || 0;
  const selectedAllRows = Boolean(countRows > 0 && selectedItems?.length === table?.items?.length);
  const intermediateSelection = Boolean(
    countRows > 0 && selectedItems?.length !== table?.items?.length
  );

  const onAddUser = (team: Team) => {
    onInviteToTeam(team);
  };

  const onEditTeam = (team: Team) => {
    const handleEditFinish = (data) => {
      if (data) {
        sessionActions.updateAccountTeams((prev) => {
          const editedIndex = prev.findIndex(({ id }) => id === data.id);
          prev[editedIndex] = data;
          return [...prev];
        });
        teamsTableActions.onForceTeamsRequest();
      }
      closeManageTeamModal();
    };

    openManageTeamModal({
      onClose: handleEditFinish,
      team: team,
      existingTeams: teams,
    });
  };

  const onInviteToTeam = (team: Team) => {
    const handleInviteFinish = (afterInvite) => {
      if (afterInvite) {
        if (!licenses_available) {
          sessionActions.refreshSession();
        }
        teamsTableActions.onForceTeamsRequest();
      }
      closeInviteToTeamModal();
    };

    openInviteToTeamModal({
      onClose: handleInviteFinish,
      team: team,
      account: account,
      isInvite: true,
    });
  };

  const deleteDisabled = (item: any) => {
    return item.active_users_count > 0 || item.pending_users_count > 0;
  };

  const teamsColumnsTableConfig = isMobile ? '40px 6fr 40px' : '40px 6fr 3fr 3fr 3fr 150px'; // 6 Columnas. cada una fraccionada.

  return (
    <Container lock={lock}>
      {!isEmpty ? (
        <Table>
          <Row
            columns={teamsColumnsTableConfig}
            transparent={isMobile}
            topSelect={isMobile}
            top={true}
            sticky
            impersonated={isImpersonated}
          >
            <Top topSelect={isMobile}>
              <Checkbox
                checked={selectedAllRows}
                indeterminate={intermediateSelection}
                onChange={() => teamsTableActions?.onSelectAllRows()}
                label={isMobile ? `${countRows} teams selected` : undefined}
                darkBackground={isMobile}
                id="allTeamSelected"
                controlled
                style={
                  isMobile
                    ? { justifyContent: 'flex-start', margin: '0 0 4px 8px', opacity: 0.6 }
                    : {}
                }
              />
            </Top>
            {!isMobile && (
              <>
                <Top>Team</Top>
                <Top>
                  Active users
                  <Tooltip
                    placement="top"
                    title="Number of users who have accepted the invite and are now part of the team"
                    overlayInnerStyle={{ textAlign: 'center' }}
                  >
                    <div style={{ marginLeft: '4px' }}>
                      <Icon
                        data-testid="info-icon"
                        icon="Info"
                        fill={'var(--lilac)'}
                        size="smaller"
                      />
                    </div>
                  </Tooltip>
                </Top>
                <Top>
                  Pending users
                  <Tooltip
                    placement="top"
                    title="Number of users who have been invited to the team but have not yet accepted the invite"
                    overlayInnerStyle={{ textAlign: 'center' }}
                  >
                    <div style={{ marginLeft: '4px' }}>
                      <Icon
                        data-testid="info-icon"
                        icon="Info"
                        fill={'var(--lilac)'}
                        size="smaller"
                      />
                    </div>
                  </Tooltip>
                </Top>
                <Top>Manager(s)</Top>
                <Top></Top>
              </>
            )}
          </Row>

          {table?.items?.map((item, index) => (
            <Row columns={teamsColumnsTableConfig} key={index}>
              <Cell>
                <Checkbox
                  checked={Boolean(selectedItems?.includes(item.id))}
                  onChange={() => teamsTableActions?.onSelectRow(item.id)}
                  controlled
                />
              </Cell>
              <Cell>
                <NavLink to={`/team/${item.id}`}>
                  <Avatar data={item} />
                </NavLink>
              </Cell>
              {!isMobile && (
                <>
                  <Cell>{item.active_users && <Active data={item.active_users} />}</Cell>
                  <Cell>{item.pending_users && <Pending data={item.pending_users} />}</Cell>
                  <Cell>{item.team_managers && <Manager data={item.team_managers} />}</Cell>
                  <Cell style={{ display: 'block', minWidth: '140px', textAlign: 'right' }}>
                    <ActionButtonsContainer>
                      <Tooltip placement="bottomRight" title={'Edit team'}>
                        <ButtonIcon
                          $secondary
                          style={{ margin: '0px 5px' }}
                          size={'small'}
                          icon={'Edit'}
                          onClick={() => onEditTeam(item)}
                        />
                      </Tooltip>
                      <Tooltip placement="bottomRight" title={'Add user'}>
                        <ButtonIcon
                          style={{ margin: '0px 5px' }}
                          $secondary
                          size={'small'}
                          icon={'UserPlus'}
                          onClick={() => onAddUser(item)}
                        />
                      </Tooltip>
                      <Tooltip
                        placement="bottomRight"
                        title={
                          deleteDisabled(item)
                            ? 'Teams can only be deleted by removing the users first. Remove the users, then delete the team.'
                            : 'Remove team'
                        }
                      >
                        <div
                          style={{
                            cursor: deleteDisabled(item) ? 'not-allowed' : 'pointer',
                          }}
                        >
                          <ButtonIcon
                            style={{ margin: '0px 5px' }}
                            $secondary
                            disabled={deleteDisabled(item)}
                            size={'small'}
                            icon={'Trash'}
                            onClick={() => teamsTableActions.onRemoveTeam([item.id])}
                          />
                        </div>
                      </Tooltip>
                    </ActionButtonsContainer>
                  </Cell>
                </>
              )}
              {isMobile && (
                <TeamsSettings data={item} onRemoveTeam={teamsTableActions.onRemoveTeam} />
              )}
            </Row>
          ))}
        </Table>
      ) : (
        <>{!fetching && <SearchEmptyState />}</>
      )}
      {table.items && !isEmpty && (
        <Pagination
          count={table.count}
          page={parseInt(page)}
          page_size={parseInt(page_size)}
          onPagesClick={(value, name) =>
            teamsTableActions && teamsTableActions.onChangeFilters({ name: name, value })
          }
        />
      )}

      {manageTeamModal}
      {inviteToTeamModal}
      <HeaderLoading portals={true} active={fetching} />
    </Container>
  );
};

export default TeamsTable;
