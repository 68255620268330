import { colors } from 'constants/styles/colors';

export const barChartConfig = {
  width: '100%',
  height: 400,
  strokeDashArray: '5',
  verticalLines: false,
  axisLine: false,
  tickLine: false,
  stroke: colors.palette.Business_LightThemeGreyLighter,
  barsConfig: {
    barSize: 15,
    radius: 4,
    minPointSize: 10,
  },
  tick: {
    stroke: colors.palette.Business_LightThemeGrayDark,
    strokeWidth: 0.5,
  },
  xAxisKey: 'date',
};
