import OverallIcon from 'assets/icons/analytics/overall-icon.svg';
import QuizzesIcon from 'assets/icons/analytics/quizzes-icon.svg';
import VideosIcon from 'assets/icons/analytics/videos-icon.svg';
import LabsIcon from 'assets/icons/analytics/labs-icon.svg';
import {
  ACTIVITIES_COUNTER,
  LABS_COUNTER,
  QUIZZES_COUNTER,
  VIDEOS_COUNTER,
} from 'constants/analytics';
import { colors } from 'constants/styles/colors';

export const USER_PROFILE_AGGREGATIONS_STATS = [
  {
    name: 'ACTIVE_COURSES',
    label: 'Active courses',
    actions: VIDEOS_COUNTER.STARTED.ACTIONS,
  },
  {
    name: 'VIDEOS_WATCHED',
    label: 'Videos watched',
    actions: VIDEOS_COUNTER.FINISHED.ACTIONS,
  },
  {
    name: 'QUIZZES_FAILED',
    label: 'Quizzes failed',
    actions: QUIZZES_COUNTER.PASSED.ACTIONS,
    metadata: 'passed:False',
  },
  {
    name: 'TOTAL_LABS',
    label: 'Total labs',
    actions: LABS_COUNTER.OVERALL.ACTIONS,
  },
];

export const USER_PROFILE_AGGREGATION_TABS = [
  {
    name: 'TOTAL_ACTIVITIES',
    label: 'Total activities',
    icon: OverallIcon,
    actions: ACTIVITIES_COUNTER.OVERALL.ACTIONS,
  },
  {
    name: 'VIDEOS_WATCHED',
    label: 'Videos watched',
    icon: VideosIcon,
    actions: null,
    external_action: VIDEOS_COUNTER.WATCHED.EXTERNAL_ACTION,
  },
  {
    name: 'TOTAL_QUIZZED',
    label: 'Total quizzes',
    icon: QuizzesIcon,
    actions: QUIZZES_COUNTER.OVERALL.ACTIONS,
  },
  {
    name: 'TOTAL_LABS',
    label: 'Total labs',
    icon: LabsIcon,
    actions: LABS_COUNTER.OVERALL.ACTIONS,
  },
];

export const USER_PROFILE_AGGRETATION_SERIES = [
  {
    name: 'TOTAL_ACTIVITIES',
    series: [
      {
        key: 'started',
        label: 'Started',
        color: colors.dataVisualization.blue.base,
        actions: ACTIVITIES_COUNTER.STARTED.ACTIONS,
      },
      {
        key: 'finished',
        label: 'Finished',
        color: colors.dataVisualization.green.dark,
        actions: ACTIVITIES_COUNTER.FINISHED.ACTIONS,
      },
    ],
  },
  {
    name: 'VIDEOS_WATCHED',
    series: [
      {
        key: 'started',
        label: 'Started',
        color: colors.dataVisualization.blue.base,
        actions: VIDEOS_COUNTER.STARTED.ACTIONS,
      },
      {
        key: 'finished',
        label: 'Finished',
        color: colors.dataVisualization.green.dark,
        actions: VIDEOS_COUNTER.FINISHED.ACTIONS,
      },
    ],
  },
  {
    name: 'TOTAL_QUIZZED',
    series: [
      {
        key: 'total',
        label: 'Quizzes per week',
        color: colors.dataVisualization.orange.dark,
        actions: QUIZZES_COUNTER.OVERALL.ACTIONS,
      },
      {
        key: 'passed',
        label: 'Passed quizzes',
        color: colors.dataVisualization.green.dark,
        actions: QUIZZES_COUNTER.PASSED.ACTIONS,
        metadata: 'passed:True',
      },
    ],
  },
  {
    name: 'TOTAL_LABS',
    series: [
      {
        key: 'started',
        label: 'Started',
        color: colors.dataVisualization.orange.dark,
        actions: LABS_COUNTER.STARTED.ACTIONS,
      },
      {
        key: 'finished',
        label: 'Finished',
        color: colors.dataVisualization.red.base,
        actions: LABS_COUNTER.FINISHED.ACTIONS,
      },
    ],
  },
];
