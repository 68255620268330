import styled from 'styled-components';
import { screenSm } from 'constants/styles/breakpoints';
import Icon from 'components/icons';

interface GroupItemImgProps extends React.HTMLAttributes<HTMLDivElement> {
  image: string;
  content_type: string;
  disabled?: boolean;
}

export const Container = styled.div<{ image?: string; disabled?: boolean }>`
  display: none;

  ${({ disabled }) =>
    disabled &&
    `
  opacity: 0.6;
`}

  ${screenSm} {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: none;
    width: 133px;
    /* height: 90px; */
    ${({ image }) =>
      image
        ? `
    background: url(${image});
    `
        : `
    background: linear-gradient(89.99deg, #863ad2 3.58%, #5936e4 97.14%);
    `}
    background-size: cover;
    border-radius: 10px 0px 0px 10px;
    cursor: pointer;
  }
`;

const iconProp = {
  course: 'Play',
  link: 'Link',
  video: 'Play',
  lab: 'Lab',
  'learning-path': 'Play',
};

const GroupItemImg = ({
  image,
  content_type,
  disabled,
  ...rest
}: GroupItemImgProps): JSX.Element => (
  <Container image={image} disabled={disabled} {...rest}>
    <Icon color="#fff" icon={iconProp[content_type]} style={{ opacity: disabled && '0.6' }} />
  </Container>
);

export default GroupItemImg;
