import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 282px;
  padding: 36px 20px;
  font-family: 'Circular-Std';
  position: relative;
`;

export const Close = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 19px;
  cursor: pointer;
  svg {
    width: 21px !important;
    height: 21px !important;
  }
`;

export const Divider = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 1px;
  margin: 10px 0px;
  background-color: ${({ theme }) => theme.default.colors.palette.Business_LightThemeGreyLighter};
`;

export const Title = styled.h4`
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.default.colors.fonts.dark};
  text-align: left;
  margin: 0;
  margin-bottom: 5px;
`;

export const Item = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.default.colors.fonts.dark};
  text-align: left;
  margin: 0;
  > b {
    font-weight: 700;
  }
  > a {
    text-decoration: none;
  }
`;

export const Link = styled.a`
  font-size: 14px;
  line-height: 23px;
  color: ${({ theme }) => theme.default.colors.dataVisualization.purple.dark};
  font-weight: 700;
  text-decoration: underline;

  &:hover {
    color: ${({ theme }) => theme.default.colors.dataVisualization.purple.dark};
  }
`;
