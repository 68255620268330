import { differenceInDays, differenceInHours } from 'date-fns';
import Tooltip from 'components/tooltip';
import { ACTION_TYPES, ActivityLog } from 'contexts/activityLog/types';
import { ActivityMailTo, ActivityNavLink, ActivityUserLabel } from '../styles';
import { formatDateInUtc } from 'utils/helpers';

const ROLES = {
  'Team Manager': 'Team Manager',
  'Account Owner': 'Account Owner',
  END_USER: 'END_USER',
};

const getGeneratedByUserLink = (generated_by, staffUser) => {
  return generated_by.is_staff && !staffUser ? (
    <ActivityUserLabel active={true}>{generated_by.name}</ActivityUserLabel>
  ) : (
    <ActivityNavLink to={`/user/${generated_by.id}`}>{generated_by.name}</ActivityNavLink>
  );
};

const getUserInvitesLabels = (user_invites, active) => {
  return user_invites.map((ui, index) => {
    const displayLabel = ui.name ? ui.name : ui.email;
    const isEmail = displayLabel.includes('@');
    return (
      <span key={index}>
        <>
          {user_invites.length > 1 && index === user_invites.length - 1 && ' and '}
          {active ? (
            isEmail ? (
              <ActivityMailTo href={`mailto:${displayLabel}`}>{displayLabel}</ActivityMailTo>
            ) : (
              <ActivityNavLink to={`/user/${ui.id}`}>{displayLabel}</ActivityNavLink>
            )
          ) : (
            <ActivityUserLabel>{displayLabel}</ActivityUserLabel>
          )}
          {index !== user_invites.length - 1 && ', '}
        </>
      </span>
    );
  });
};

const getTooltipTitle = (user_invites) => {
  return user_invites.map((ui, index) => (
    <div className="text-center" key={index}>
      {ui.name ? ui.name : ui.email}
    </div>
  ));
};

const getTooltip = (user_invites) => {
  return (
    <Tooltip placement="bottom" destroyTooltipOnHide title={getTooltipTitle(user_invites)}>
      <ActivityUserLabel active={true}>{user_invites.length} more</ActivityUserLabel>
    </Tooltip>
  );
};

const getTeamsLabels = (teams) => {
  return teams.map((team, index) => (
    <ActivityUserLabel key={index}>
      {teams.length > 1 && index === teams.length - 1 && ' and '}
      {team}
      {index !== teams.length - 1 && ', '}
    </ActivityUserLabel>
  ));
};

const getTeamsTitle = (teams) => {
  return teams.map((team, index) => (
    <div className="text-center" key={index}>
      {team}
    </div>
  ));
};

const getTeamsTooltip = (teams) => {
  return (
    <Tooltip placement="bottom" destroyTooltipOnHide title={getTeamsTitle(teams)}>
      <ActivityUserLabel active={true}>{teams.length} more</ActivityUserLabel>
    </Tooltip>
  );
};

const groupDataByRoles = (user_invites) => {
  const roles = user_invites.reduce((rolesArr, user_invite) => {
    const { role } = user_invite;
    rolesArr[role] = rolesArr[role] ?? [];
    rolesArr[role].push(user_invite);
    return rolesArr;
  }, {});
  return roles;
};

const getUserInvitesWithTooltip = (users_data, activeLabel) => {
  return (
    <span>
      <ActivityUserLabel active={activeLabel}>
        {users_data[0].name ? users_data[0].name : users_data[0].email}
      </ActivityUserLabel>
      {', '}
      <ActivityUserLabel active={activeLabel}>
        {users_data[1].name ? users_data[1].name : users_data[1].email}
      </ActivityUserLabel>
      {' and '}
      {getTooltip(users_data.slice(2))} users
    </span>
  );
};

const getRoleLabel = (role: string) => {
  return role === 'END_USER' ? 'End User' : role;
};

const renderDataByRole = (generated_by, user_invites, batchType, staffUser) => {
  const rolesData = groupDataByRoles(user_invites);
  return Object.keys(rolesData).map((key, index) => (
    <div key={index}>
      {getGeneratedByUserLink(generated_by, staffUser)}
      {batchType === 'invite' ? ' invited ' : ' changed '}
      {rolesData[key].length > 3
        ? getUserInvitesWithTooltip(rolesData[key], true)
        : getUserInvitesLabels(rolesData[key], true)}
      {batchType === 'invite'
        ? ` as ${getRoleLabel(ROLES[key])}`
        : ` from ${getRoleLabel(key)} to ${getRoleLabel(ROLES[rolesData[key][0].new_role])}`}
    </div>
  ));
};

const getDiffDaysOrHours = (created_at: string) => {
  const diff = differenceInDays(new Date(), new Date(created_at));
  if (diff > 0) {
    return `${diff} days past due`;
  } else {
    return `${differenceInHours(new Date(), new Date(created_at))} hours past due`;
  }
};

const getStringMetadata = (key: string, metadata: any, entity: string, id?: string) => {
  if (key === 'name') {
    return (
      <span>
        {`${metadata[key].label} from ${metadata[key].old} to `}
        <ActivityNavLink to={`/${entity}/${id}`}>{metadata[key].new}</ActivityNavLink>
      </span>
    );
  } else {
    return `${metadata[key].label} from ${metadata[key].old ? metadata[key].old : 'unlimited'} to ${
      metadata[key].new ? metadata[key].new : 'unlimited'
    }`;
  }
};

const getDateMetadata = (key: string, metadata: any) => {
  return `${metadata[key].label} from ${formatDateInUtc(
    new Date(metadata[key].old),
    'MM/dd/yy'
  )} to ${formatDateInUtc(new Date(metadata[key].new), 'MM/dd/yy')}`;
};

const getBooleanMetadata = (key: string, metadata: any) => {
  return `${metadata[key].label} from ${metadata[key].old ? 'enable' : 'disable'} to ${
    metadata[key].new ? 'enable' : 'disable'
  }`;
};

const getAcceptedInviteData = (user_data: any) => {
  const roleLabel = getRoleLabel(user_data.role);
  return (
    <span>
      <ActivityUserLabel active={true}>{user_data.name}</ActivityUserLabel>
      {` accepted an invite as ${roleLabel === ROLES['Team Manager'] ? 'a' : 'an'} ${roleLabel}`}
    </span>
  );
};

const getLinkMetadata = (key: string, metadata: any) => {
  return (
    <span>
      {metadata[key].label} {' from '}
      <ActivityNavLink to={`/user/${metadata[key]?.old?.id}`}>
        {metadata[key].old?.name}
      </ActivityNavLink>
      {' to '}
      <ActivityNavLink to={`/user/${metadata[key]?.new?.id}`}>
        {metadata[key].new?.name}
      </ActivityNavLink>
    </span>
  );
};

const renderPlanOrAddons = (metadata: any) => {
  let text = '';
  const { new: newAddons, old: oldAddons } = metadata.addons;
  if (newAddons.length && oldAddons.length) {
    oldAddons.map((oldAddon: any, index: number) => {
      text += `${oldAddon.name} addons licenses from ${oldAddon.count} to ${newAddons[index].count}`;
    });
  } else if (!oldAddons.length) {
    newAddons.map((newAddon: any) => {
      text += `Added ${newAddon.count} ${newAddon.name} addons licenses`;
    });
  } else if (!newAddons.length) {
    oldAddons.map((oldAddon: any) => {
      text += `Removed ${oldAddon.count} ${oldAddon.name} addons licenses`;
    });
  }
  return text;
};

export const renderActivityLogByAction = (
  activityLog: ActivityLog,
  staffUser: boolean
): JSX.Element => {
  const { action, account_id, generated_by, metadata, created_at } = activityLog;
  switch (action) {
    case ACTION_TYPES.ACCOUNT_ADD:
    case ACTION_TYPES.ACCOUNT_EXPIRE:
    case ACTION_TYPES.ACCOUNT_REACTIVATE:
      return (
        <div>
          {getGeneratedByUserLink(generated_by, staffUser)}{' '}
          {action.split('.')[1] === 'add' && ' created the account '}
          {action.split('.')[1] === 'expire' && ' expired the account '}
          {action.split('.')[1] === 'reactivate' && ' reactivated the account '}
          <ActivityNavLink to={`/account/${account_id}`}>{metadata?.name}</ActivityNavLink>
        </div>
      );
    case ACTION_TYPES.ACCOUNT_CHANGE_PLAN:
      return (
        <div>
          {getGeneratedByUserLink(generated_by, staffUser)}
          {` changed the plan: `}
          {Object.keys(metadata).map((key, index) => {
            return (
              <div key={index}>
                {key === 'plan' && ` From ${metadata.plan.old} to ${metadata.plan.new}`}
                {key === 'addons' && `${renderPlanOrAddons(metadata)}`}
                {key === 'license_count' &&
                  `Plan licenses from ${metadata.license_count.old} to ${metadata.license_count.new}`}
              </div>
            );
          })}
        </div>
      );
    case ACTION_TYPES.ACCOUNT_MODIFY:
    case ACTION_TYPES.TEAM_MODIFY:
      return (
        <div>
          {getGeneratedByUserLink(generated_by, staffUser)}
          {` changed the ${action.split('.')[0] === 'team' ? 'team' : 'account'}:`}
          {Object.keys(metadata).map((key, index) => {
            return (
              <div key={index}>
                {(metadata[key].type === 'string' || metadata[key].type === 'integer') &&
                  getStringMetadata(key, metadata, action.split('.')[0], metadata['id'])}
                {metadata[key].type === 'datetime' && getDateMetadata(key, metadata)}
                {metadata[key].type === 'boolean' && getBooleanMetadata(key, metadata)}
                {metadata[key].type === 'identifier' && getLinkMetadata(key, metadata)}
              </div>
            );
          })}
        </div>
      );
    case ACTION_TYPES.ACCOUNT_EMAIL_DOMAIN_CREATE:
    case ACTION_TYPES.ACCOUNT_EMAIL_DOMAIN_REMOVE:
      return (
        <div>
          {getGeneratedByUserLink(generated_by, staffUser)}{' '}
          {` ${action.split('.')[2] === 'create' ? 'created' : 'removed'} the domain: ${
            metadata?.email_domain
          }`}
        </div>
      );
    case ACTION_TYPES.ACCOUNT_EMAIL_DOMAIN_MODIFY:
      return (
        <div>
          {getGeneratedByUserLink(generated_by, staffUser)}{' '}
          {` changed domain from ${metadata?.old_domain} to ${metadata?.new_domain}`}
        </div>
      );
    case ACTION_TYPES.ACCOUNT_SSO_ENABLED:
    case ACTION_TYPES.ACCOUNT_SSO_DISABLED:
    case ACTION_TYPES.ACCOUNT_SSO_CONFIGURED:
      return (
        <div>
          {getGeneratedByUserLink(generated_by, staffUser)}{' '}
          {action.split('.')[2] === 'enabled' && ' enabled SSO'}
          {action.split('.')[2] === 'disabled' && ' disiabled SSO'}
          {action.split('.')[2] === 'configured' && ' configured SSO'}
        </div>
      );
    case ACTION_TYPES.ACCOUNT_PAST_DUE:
      return (
        <div>
          <ActivityNavLink to={`/account/${metadata?.account_id}`}>
            {metadata?.name}
          </ActivityNavLink>{' '}
          is {getDiffDaysOrHours(created_at)}
        </div>
      );
    case ACTION_TYPES.LICENSE_BATCH_ADD:
    case ACTION_TYPES.LICENSE_BATCH_REMOVE:
      return (
        <div>
          {getGeneratedByUserLink(generated_by, staffUser)}{' '}
          {`${
            action.split('.')[2] === 'add'
              ? ' has assigned licenses to'
              : ' has removed licenses from'
          } `}
          {metadata?.user_invites.length > 3
            ? getUserInvitesWithTooltip(metadata?.user_invites, true)
            : getUserInvitesLabels(metadata?.user_invites, true)}
        </div>
      );
    case ACTION_TYPES.LICENSE_ADDON_ASSIGN:
    case ACTION_TYPES.LICENSE_ADDON_UNASSIGN:
      return (
        <div>
          {getGeneratedByUserLink(generated_by, staffUser)}{' '}
          {`${
            action.split('.')[2] === 'assign'
              ? `has assigned ${metadata?.addon_name} license to`
              : `has removed ${metadata?.addon_name} license to`
          } `}
          <ActivityUserLabel active={true}>{metadata?.user_name}</ActivityUserLabel>
        </div>
      );
    case ACTION_TYPES.TEAM_ADD:
      return (
        <div>
          {getGeneratedByUserLink(generated_by, staffUser)} {' created the team '}
          {staffUser ? (
            <ActivityUserLabel active={false}>{metadata?.team_name}</ActivityUserLabel>
          ) : (
            <ActivityNavLink to={`/team/${metadata?.team_id}`}>
              {metadata?.team_name}
            </ActivityNavLink>
          )}
        </div>
      );
    case ACTION_TYPES.TEAM_BATCH_REMOVE:
      return (
        <div>
          {getGeneratedByUserLink(generated_by, staffUser)} {' removed '}
          {metadata?.teams_deleted.length > 3 ? (
            <span>
              <ActivityUserLabel active={false}>{metadata?.teams_deleted[0]}</ActivityUserLabel>
              {', '}
              <ActivityUserLabel active={false}>{metadata?.teams_deleted[1]}</ActivityUserLabel>
              {' and '}
              {getTeamsTooltip(metadata?.teams_deleted.slice(2))} teams
            </span>
          ) : (
            getTeamsLabels(metadata?.teams_deleted)
          )}
        </div>
      );
    case ACTION_TYPES.INVITE_TEAM_SET:
    case ACTION_TYPES.INVITE_TEAM_UNSET:
      return (
        <div>
          {getGeneratedByUserLink(generated_by, staffUser)}{' '}
          {`${action.split('.')[2] === 'set' ? ' assigned ' : ' removed '}`}
          {metadata?.user_invites.length > 3
            ? getUserInvitesWithTooltip(metadata?.user_invites, true)
            : getUserInvitesLabels(metadata?.user_invites, false)}
          {' to the team '}
          {staffUser ? (
            <ActivityUserLabel active={false}>{metadata?.team_name}</ActivityUserLabel>
          ) : (
            <ActivityNavLink to={`/team/${metadata?.team_id}`}>
              {metadata?.team_name}
            </ActivityNavLink>
          )}
        </div>
      );
    case ACTION_TYPES.NOTE_ADD:
    case ACTION_TYPES.NOTE_REMOVE:
      return (
        <div>
          {getGeneratedByUserLink(generated_by, staffUser)}{' '}
          {` ${action.split('.')[1] === 'add' ? 'created' : 'removed'} a note`}
        </div>
      );
    case ACTION_TYPES.INVITE_BATCH_EMAIL:
      return (
        <div>{renderDataByRole(generated_by, metadata?.user_invites, 'invite', staffUser)}</div>
      );
    case ACTION_TYPES.INVITE_BATCH_SET_ROLE:
      return (
        <div>{renderDataByRole(generated_by, metadata?.user_invites, 'change', staffUser)}</div>
      );
    case ACTION_TYPES.INVITE_BATCH_REMOVE:
      return (
        <div>
          {getGeneratedByUserLink(generated_by, staffUser)} {' removed '}
          {metadata?.user_invites.length > 3
            ? getUserInvitesWithTooltip(metadata?.user_invites, false)
            : getUserInvitesLabels(metadata?.user_invites, false)}
        </div>
      );
    case ACTION_TYPES.INVITE_ACCEPT:
      return (
        <div>{metadata?.user_invites && getAcceptedInviteData(metadata?.user_invites[0])}</div>
      );
    case ACTION_TYPES.SONAR_ENABLED:
    case ACTION_TYPES.SONAR_DISABLED:
      return (
        <div>
          {getGeneratedByUserLink(generated_by, staffUser)}{' '}
          {action.split('.')[2] === 'enabled' && ' enabled Sonar'}
          {action.split('.')[2] === 'disabled' && ' disabled Sonar'}
        </div>
      );
    default:
      return <span>{activityLog.action}</span>;
  }
};
