import styled, { keyframes } from 'styled-components';
import { rangeMediaQuery, screenMd, screenSm } from 'constants/styles/breakpoints';
import { Row } from 'components/Table';

const cunstomMediaQuery = rangeMediaQuery({ min: 1200, max: 1400 });

const slideAnimation = keyframes`
 0% { 
	transform: translateX(15px);
	opacity: 0;
	}
 100% { 
	transform: translate(0);
	opacity: 1;
	 }
`;

export const AllRowsCheckbox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableRow = styled.tr`
  background: #ffffff;
  max-width: 90%;
  /* animation-name: ${slideAnimation};
	animation-duration: .30s;
	animation-iteration-count: 1; */
  border-bottom: 5px solid #f2f2f7 !important;
  ${screenMd} {
    border-bottom: 2px solid #f2f2f7 !important;
  }
  &:last-of-type {
    border-bottom: 0;
  }
`;

export const TableCell = styled.td<{ first?: boolean; last?: boolean }>`
  background-color: #ffffff;
  text-align: left;
  ${({ first }) =>
    first &&
    `
			border-radius: 10px 0 0 10px;
			padding-left: 10px !important;
	`}
  ${({ last }) =>
    last &&
    `
		border-radius: 0 10px 10px 0;
		${screenMd} {
			min-width: 100px;
			padding-right: 10px !important;
		}
		`}
`;

export const UserDataContainer = styled.span`
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  ${cunstomMediaQuery} {
    max-width: 120px;
  }
  p,
  a {
    margin: 0;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    &:first-of-type {
      font-weight: 500;
      color: #1a2535;
    }
    &:nth-of-type(2) {
      color: #57575c;
    }
  }
  .badge.badge-light {
    float: left;
  }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PendingLetterAvatar = styled.div<{ color: any }>`
  flex: 0 0 33px;
  height: 33px;
  width: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  border-radius: 6px;
  background: ${({ color }) => color.lighter};
  color: ${({ color }) => color.base};
  text-transform: uppercase;
  ${screenSm} {
    height: 50px;
    width: 50px;
    flex: 0 0 50px;
  }
`;

export const PendingUserImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 6px;
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  ${screenMd} {
    position: relative;
    float: right;
  }
`;

export const OwnerStarImg = styled.img`
  width: 21px;
  height: 21px;
  position: absolute;
  left: 38px;
  bottom: -7px;
`;

export const UserAnalyticsRow = styled(Row)`
  height: auto;
  align-items: initial;
`;

export const CustomRow = styled(Row)<{ collapsed?: boolean }>`
  margin: 5px 10px;
  ${({ collapsed }) => !collapsed && 'border-bottom: none;'}
  ${screenMd} {
    margin: 0;
  }
`;

export const ContainerDesktop = styled.div`
  display: none;
  width: 100%;
  ${screenMd} {
    display: flex;
  }
`;

export const ContainerMobile = styled.div`
  display: flex;
  ${screenMd} {
    display: none;
  }
`;

export const SwitchContainer = styled.div`
  align-items: center;
  display: flex;
  margin-left: auto;
`;

export const SwitchLabel = styled.label`
  color: #aeaeb2;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  margin-right: 15px;
  text-align: left;
`;

export const DrawerContainer = styled.div`
  padding: 10px;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
`;

export const DrawerBackground = styled.div`
  background-color: #f2f2f7;
  height: 100%;
`;

export const UserTableContainer = styled.div<{ lock?: boolean }>`
  opacity: 1;
  transition: opacity 0.4s;
  padding-bottom: 140px;
  background-color: ${({ theme }) => theme.default.colors.palette.Business_LightThemeGreyLighter};
  min-height: 250px;
  ${screenSm} {
    padding-bottom: 0;
    background-color: transparent;
  }

  ${({ lock }) =>
    lock &&
    `
  opacity: 0.3;
  pointer-events: none;
`}
`;

export const Subcolumn = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 11px;
  line-height: 12px;
  font-weight: 450;
`;
