import { useEffect, useState } from 'react';
import { SkillTabs } from 'pages/Teams/components/TeamSkills/types';
import Tooltip from 'components/tooltip';
import './radar.scss';
import { AveragePill } from './styles';

interface UserSkillsRadarProps {
  skillSelected: SkillTabs;
  userScores: any;
}

const UserSkillsRadar = ({ skillSelected, userScores }: UserSkillsRadarProps): JSX.Element => {
  const [scoreAvg, setScoreAvg] = useState(0);
  const turnTime = 3;

  const setStyleProperties = () => {
    const { lowest_score, highest_score, score_average } = userScores;
    const root = document.documentElement;
    root.style.setProperty('--low-dot-turn', `${lowest_score}turn`);
    root.style.setProperty('--high-dot-turn', `${highest_score}turn`);
    root.style.setProperty('--avg-dot-turn', `${score_average}turn`);
    root.style.setProperty('--avg-dot', `${score_average * 100}`);
    root.style.setProperty('--high-dot-perc', `${highest_score * 100}%`);
    root.style.setProperty('--low-dot-perc', `${lowest_score * 100}%`);
    root.style.setProperty('--diff-perc', `${(highest_score - lowest_score) * 100}%`);
    root.style.setProperty('--high-dot-sec', `${turnTimeSecH()}s`);
    root.style.setProperty('--low-dot-sec', `${turnTimeSecL()}s`);
    root.style.setProperty('--avg-dot-sec', `${turnTimeSecA()}s`);
    root.style.setProperty('--tradar-sec', `${turnTimeSecOffset()}s`);
    root.style.setProperty('--tradar-delay', `${turnTimeSecDelay()}s`);
    setTimeout(() => {
      setScoreAvg(score_average * 100);
    }, 500);
  };

  const turnTimeSecH = () => {
    return Number(turnTime * Number(userScores?.highest_score)).toFixed(1);
  };
  const turnTimeSecL = () => {
    return Number(turnTime * Number(userScores?.lowest_score)).toFixed(1);
  };
  const turnTimeSecA = () => {
    return Number(turnTime * Number(userScores?.score_average)).toFixed(1);
  };
  const turnTimeSecDelay = () => {
    return Number(turnTime + Number(turnTimeSecH())).toFixed(1);
  };
  const turnTimeSecOffset = () => {
    return (turnTime - Number(turnTimeSecH())).toFixed(1);
  };
  const avgPercentage = () => {
    return `${Math.floor(userScores?.score_average * 100)}%`;
  };
  const highPercentage = () => {
    return `${Math.floor(userScores?.highest_score * 100)}%`;
  };
  const lowPercentage = () => {
    return `${Math.floor(userScores?.lowest_score * 100)}%`;
  };

  useEffect(() => {
    if (userScores !== null) {
      setStyleProperties();
    }
  }, [userScores]);

  return (
    <div className="sonar-progress-container">
      <div className="sonar-progress__wrapper">
        <div
          style={{ background: skillSelected.background }}
          className="sonar-progress-circle"
          data-progress={scoreAvg}
        >
          <div>
            <div className="full sonar-progress-circle__slice">
              <div className="sonar-progress-circle__fill"></div>
            </div>
            <div className="sonar-progress-circle__slice">
              <div className="sonar-progress-circle__fill"></div>
              <div className="sonar-progress-circle__fill sonar-progress-circle__bar"></div>
            </div>
          </div>
          <div className="sonar-progress-circle__overlay"></div>
        </div>
        <div className="sonar-progress__outer-circle">
          <div className="sonar-progress__dot-container sonar-progress__dot-container--low">
            <div className="sonar-progress__dot-wrapper sonar-progress__dot-wrapper--low">
              <Tooltip title={`Low: ${lowPercentage()}`}>
                <div
                  style={{ background: skillSelected.color, cursor: 'pointer' }}
                  className="sonar-progress__dot sonar-progress__dot--low"
                ></div>
              </Tooltip>
            </div>
          </div>
          <div className="sonar-progress__dot-container">
            <div className="sonar-progress__dot-wrapper sonar-progress__dot-wrapper--high">
              <Tooltip title={`High: ${highPercentage()}`}>
                <div
                  style={{ background: skillSelected.color, cursor: 'pointer' }}
                  className="sonar-progress__dot sonar-progress__dot--high"
                ></div>
              </Tooltip>
            </div>
          </div>
          <div className="sonar-progress__dot-container sonar-progress__dot-container--avg">
            <div className="sonar-progress__dot-wrapper sonar-progress__dot-wrapper--avg">
              <Tooltip title={`Avg: ${avgPercentage()}`}>
                <div
                  className="sonar-progress__dot sonar-progress__dot--avg"
                  style={{ cursor: 'pointer' }}
                ></div>
              </Tooltip>
            </div>
          </div>
          <div className="sonar-progress__green-scanner"></div>
          <div className="sonar-progress__green-scanner sonar-progress__green-scanner--dots"></div>
          <div className="sonar-progress__green-scanner sonar-progress__green-scanner--offset"></div>
          <div className="sonar-progress__green-scanner sonar-progress__green-scanner--total"></div>
          <div className="sonar-progress__graph-icon">
            <img
              src={skillSelected.icon}
              alt="skill icon"
              style={{ height: '31px', width: '25px' }}
            />
          </div>
        </div>
      </div>
      <div className="skill-progress-data">
        <span className="skill-progress-data-label">{skillSelected.label}</span>
        {/* <div className="skill-progress-data-rating">
          <span>Average expertise rating: </span>
        </div>
        <AveragePill>{avgPercentage()}</AveragePill>
        <div className="skill-progress-data-percents">{`High: ${highPercentage()} | Low: ${lowPercentage()} | Skills: ${
          userScores.skill_areas.length
        }`}</div> */}
      </div>
    </div>
  );
};

export default UserSkillsRadar;
