import { AxiosResponse } from 'axios';
import { CONTENT } from './axios';
import { config } from 'config/config.local';
import {
  IPlaylist,
  IPlaylistUserDetails,
  IPlaylistUserListItemResponse,
} from 'contexts/playlists/types';

interface IGetPlaylists {
  results: IPlaylist[];
  count: number;
}

export interface IPlaylistsFetchParams {
  page: string | number | null;
  page_size: string | number | null;
  name__icontains: string | null;
  business_account_id?: string;
}

export interface IPlaylistFetchParams {
  id: string | number | null;
  business_account_id?: string;
}

export interface IAssignUsersParams {
  business_account_id?: string;
  id: string;
  user_ids: string[];
}

export interface IDeletePlaylist {
  business_account_id?: string;
  id: string;
}

export const getPlaylists = ({
  business_account_id,
  ...params
}: IPlaylistsFetchParams): Promise<AxiosResponse<IGetPlaylists>> => {
  // @ToDo support pagination
  return CONTENT.get(
    `${config.routes.playlists.getPlaylists}?business_account_id=${business_account_id}`,
    { params }
  );
};

export const getPlaylist = (params: IPlaylistFetchParams): Promise<AxiosResponse<IPlaylist>> => {
  return CONTENT.get(
    `${config.routes.playlists.getPlaylists}/${params.id}?business_account_id=${params.business_account_id}`
  );
};

export const createPlaylist = (params: IPlaylist): Promise<AxiosResponse<IPlaylist>> => {
  return CONTENT.post(
    `${config.routes.playlists.getPlaylists}?business_account_id=${params.business_account_id}`,
    { ...params }
  );
};

export const updatePlaylist = (params: IPlaylist): Promise<AxiosResponse<IPlaylist>> => {
  return CONTENT.put(
    `${config.routes.playlists.getPlaylists}/${params.id}?business_account_id=${params.business_account_id}`,
    { ...params }
  );
};

export const editPlaylist = (params: IPlaylist): Promise<AxiosResponse<IPlaylist>> => {
  return CONTENT.patch(
    `${config.routes.playlists.getPlaylists}/${params.id}?business_account_id=${params.business_account_id}`,
    { ...params }
  );
};

export const deletePlaylist = (params: IDeletePlaylist): Promise<AxiosResponse<IPlaylist>> => {
  return CONTENT.delete(
    `${config.routes.playlists.getPlaylists}/${params.id}?business_account_id=${params.business_account_id}`
  );
};

export const assignUsers = (params: IAssignUsersParams): Promise<AxiosResponse<IPlaylist>> => {
  return CONTENT.post(
    `${config.routes.playlists.getPlaylists}/${params.id}/assign?business_account_id=${params.business_account_id}`,
    { user_ids: params.user_ids }
  );
};

export const unAssignUsers = (params: IAssignUsersParams): Promise<AxiosResponse<IPlaylist>> => {
  return CONTENT.post(
    `${config.routes.playlists.getPlaylists}/${params.id}/unassign?business_account_id=${params.business_account_id}`,
    { ...params }
  );
};

export const getPlaylistUsersList = ({
  account_id,
  playlist_id,
}: {
  account_id: string;
  playlist_id: string;
}): Promise<AxiosResponse<IPlaylistUserListItemResponse[]>> => {
  return CONTENT.get(
    `${config.routes.playlists.getPlaylists}/${playlist_id}/users?business_account_id=${account_id}`
  );
};

export const getPlaylistUserDetails = ({
  business_account_id,
  playlist_id,
  user_id,
}: {
  business_account_id: string;
  playlist_id: string;
  user_id: string;
}): Promise<AxiosResponse<IPlaylistUserDetails>> => {
  return CONTENT.get(`${config.routes.playlists.getPlaylists}/${playlist_id}/users/${user_id}`, {
    params: { business_account_id },
  });
};

export const getLearningPathDetail = (id: string): Promise<AxiosResponse<any>> => {
  return CONTENT.get(`${config.routes.playlists.getLearningPath}/${id}`);
};

export const userAssignedPlaylist = (user_id: string): Promise<AxiosResponse<any>> => {
  return CONTENT.get(`${config.routes.playlists.getPlaylists}/assigned?user_id=${user_id}`);
};

export const fetchPlaylistChangelog = (playlist_id: string): Promise<AxiosResponse<any>> => {
  return CONTENT.get(`${config.routes.playlists.getPlaylists}/${playlist_id}/content-changes`);
};
