import { screenMd } from 'constants/styles/breakpoints';
import styled from 'styled-components';

export const GraphContainter = styled.div`
  margin-top: 10px;
  min-height: 400px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ChartNoData = styled.div`
  color: #d0d2db;
  font-size: 24px;
  font-weight: 600;
`;

export const ContainerMobile = styled.div`
  width: 100%;
  display: flex;
  ${screenMd} {
    display: none;
  }
`;
