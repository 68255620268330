import { useEffect, useState } from 'react';
import { breakpoints } from 'constants/styles/breakpoints';

interface windowSizeHook {
  width: undefined | number;
  height: undefined | number;
  isScreenXs: undefined | boolean;
  isScreenSm: undefined | boolean;
  isScreenMd: undefined | boolean;
  isScreenLg: undefined | boolean;
}

const { xs, sm, md, lg } = breakpoints;

export default function useWindowSize(): windowSizeHook {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
    isScreenXs: undefined,
    isScreenSm: undefined,
    isScreenMd: undefined,
    isScreenLg: undefined,
  });

  useEffect(() => {
    function handleResize() {
      const { innerHeight: height, innerWidth: width } = window;
      const isScreenLg = width >= lg;
      const isScreenMd = isScreenLg || width >= md;
      const isScreenSm = isScreenMd || width >= sm;
      const isScreenXs = isScreenSm || width >= xs;

      setWindowSize({
        width,
        height,
        isScreenXs,
        isScreenSm,
        isScreenMd,
        isScreenLg,
      });
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}
