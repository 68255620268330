import { useContext } from 'react';
import { useScrollDirection } from 'react-use-scroll-direction';
import { NotesContext } from 'contexts/notes';
import { Table } from 'components/Table';
import { Note } from 'contexts/notes/types';
import NotesEmpty from './NotesEmpty';
import { NotesTableColumn, NotesTableContainer, NotesTableHeader } from './styles';
import SortTable from 'components/sortTable';
import NotesTableRow from './NotesTableRow';

const notesTableColumnsConfig = '4fr 5fr 4fr 128px';

const isInViewport = (el) => {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
  );
};

interface INotesTableProps {
  onEditNote(note: Note): void;
  onDeleteNoteConfirmation(note: Note): void;
  onAddNote(): void;
}

const NotesTable = ({
  onEditNote,
  onDeleteNoteConfirmation,
  onAddNote,
}: INotesTableProps): JSX.Element => {
  const {
    table,
    actions,
    fetching,
    filters: { page, ordering },
    isEmpty,
  } = useContext(NotesContext);
  const { isScrollingDown, scrollTargetRef } = useScrollDirection();

  const handleScroll = (e) => {
    if (
      isScrollingDown &&
      !fetching &&
      table.items.length !== table.count &&
      isInViewport(e.target.firstChild.lastChild)
    ) {
      actions.onChangeFilter({ name: 'page', value: (parseInt(page) + 1).toString() });
    }
  };

  return (
    <NotesTableContainer onScroll={handleScroll} ref={scrollTargetRef}>
      {!isEmpty ? (
        <Table>
          <NotesTableHeader columns={notesTableColumnsConfig} sticky>
            <>
              <NotesTableColumn>GENERATED BY</NotesTableColumn>
              <NotesTableColumn>NOTES</NotesTableColumn>
              <NotesTableColumn>
                DATE
                <SortTable
                  title="Sort by"
                  name="ordering"
                  position="left"
                  items={[
                    {
                      label: 'ASC',
                      value: 'created_at',
                      selected: ordering === 'created_at',
                    },
                    {
                      label: 'DESC',
                      value: '-created_at',
                      selected: ordering === '-created_at',
                    },
                  ]}
                  onSelect={(e) => actions.onChangeFilter(e)}
                />
              </NotesTableColumn>
            </>
          </NotesTableHeader>
          {table?.items?.map((item, index) => {
            return (
              <NotesTableRow
                note={item}
                key={index}
                notesTableConfig={notesTableColumnsConfig}
                onEditNote={onEditNote}
                onDeleteNoteConfirmation={onDeleteNoteConfirmation}
              />
            );
          })}
        </Table>
      ) : (
        <NotesEmpty onAddNote={onAddNote} />
      )}
    </NotesTableContainer>
  );
};

export default NotesTable;
