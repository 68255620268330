import { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { formatDistanceToNowStrict } from 'date-fns';
import Switch from 'components/switch';
import { UserInvites } from 'contexts/users/types';
import Tooltip from 'components/tooltip';
import { StickyFooter } from 'components/cards';
import ModalDrawer from 'components/microLayouts/drawer';
import { LitleContainer } from 'components/cards';
import { DEFAULT_IMG_USERS } from 'constants/urls';
import AvatarHeader from 'components/avatarHeader';
import { SessionContext } from 'contexts/session';
import { Team } from 'contexts/teams/types';
import Button from 'components/button/Button';
import ButtonIcon from 'components/buttonIcon';
import { DrawerBackground as Background, DrawerContainer as Container } from './styles';
import { SwitchLicenseActions } from './types';
import LittleTeamsCard from 'components/cards/LittleTeamsCard';
import { UserTeamsPayload } from './types';
import TeamSelectorMobile from 'components/TeamSelectMobile';

interface UserSettingsProps {
  data: UserInvites;
  teams: Team[] | [];
  disableManager: boolean;
  disableLicense: boolean;
  onSwitchManager: (user_invite_id: string, teamsData: UserTeamsPayload[]) => void;
  onSwitchLicense: ({ ids, checked }: SwitchLicenseActions) => void;
  onRemoveUser: (ids: string[], name: string[], lastName: string[]) => void;
  onSelectTeam: (id: string, newTeams: string[]) => void;
  onResendInvite?: () => void;
  onCancelInvite?: () => void;
  showTeamSelectorButton?: boolean;
}

const UserSettingsDrawer = ({
  data,
  teams,
  disableManager,
  disableLicense,
  onSwitchManager,
  onSwitchLicense,
  onRemoveUser,
  onSelectTeam,
  onResendInvite,
  onCancelInvite,
  showTeamSelectorButton = true,
}: UserSettingsProps): JSX.Element => {
  const [selectedUser, setSelectedUser] = useState(null);
  const { showDrawer, setVisibiltyDrawer } = useContext(SessionContext);
  const loginOrInvitedDate = data.last_login || data.invited_at;

  const handleDrawer = ({ user }: { user: string }) => {
    setSelectedUser(user);
    setVisibiltyDrawer(!showDrawer);
  };

  const close = () => {
    setVisibiltyDrawer(false);
    setSelectedUser(null);
  };

  const handleSwitchManager = (teamsData: UserTeamsPayload[]) => {
    onSwitchManager(data.id, teamsData);
  };

  useEffect(() => {
    !showDrawer && setSelectedUser(null);
  }, [showDrawer]);

  return (
    <>
      <Tooltip placement="left" title="Go to user profile">
        <ButtonIcon
          $terciary
          icon="ChevronRight"
          size="small"
          onClick={() => {
            handleDrawer({ user: data.id });
          }}
        />
      </Tooltip>

      <ModalDrawer
        closable={false}
        placement="bottom"
        visible={showDrawer && selectedUser === data.id}
        onClose={close}
        height="calc(100% - 64px)"
        destroyOnClose
      >
        <Background>
          <AvatarHeader
            avatar={
              data.uaa_data && data.uaa_data.profile.data.avatar !== DEFAULT_IMG_USERS
                ? data.uaa_data.profile.data.avatar
                : null
            }
            email={data.email}
          />
          <Container>
            <h5 style={{ textAlign: 'center', fontSize: '20px' }}>
              {data.uaa_data &&
                `${data.uaa_data?.profile?.data?.first_name} ${data.uaa_data?.profile?.data?.last_name}`}
            </h5>
            <p style={{ textAlign: 'center' }}>{data.email}</p>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <LitleContainer title="LAST ACTIVE" width="80">
                <div style={{ padding: '10px', fontWeight: '500' }}>
                  {loginOrInvitedDate &&
                    formatDistanceToNowStrict(new Date(loginOrInvitedDate), { addSuffix: true })}
                </div>
              </LitleContainer>
              <Switch
                style={{ marginLeft: '10px', marginTop: '10px' }}
                label="LICENSE"
                disabled={disableLicense}
                defaultChecked={Boolean(data.license)}
                onChange={(checked: boolean) => onSwitchLicense({ ids: [data.id], checked })}
              />
            </div>
            <br />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <LittleTeamsCard
                teams={data.teams}
                onSwitchManager={handleSwitchManager}
                disableManager={disableManager}
                flat={false}
              />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '15px',
                  marginLeft: '10px',
                }}
              >
                <TeamSelectorMobile
                  accountTeams={teams}
                  teamsSelected={data.teams}
                  onTeamChange={(teamIds) => onSelectTeam(data.id, teamIds)}
                  withEditBtn={showTeamSelectorButton}
                />
              </div>
            </div>
            <br />
            <div
              style={{
                marginBottom: '0.5em',
                padding: '0.5em',
                display: 'flex',
                flexDirection: 'row',
                gap: '40px',
              }}
            ></div>
          </Container>
          <StickyFooter>
            {data.is_active ? (
              <>
                <NavLink to={`user/${data.user}`} className={'settings-bottom-button'}>
                  <Tooltip placement="left" title={'Go to user profile'}>
                    <Button $full $secondary size={'small'} icon={'ChevronRight'}>
                      See user
                    </Button>
                  </Tooltip>
                </NavLink>
                <Tooltip placement="left" title={'Remove user'}>
                  <Button
                    $full
                    $secondary
                    size={'small'}
                    icon={'Trash'}
                    className={'settings-bottom-button'}
                    onClick={() =>
                      onRemoveUser(
                        [data.id],
                        [data.uaa_data?.profile?.data.first_name || data.email],
                        [data.uaa_data?.profile?.data.last_name || '']
                      )
                    }
                  >
                    Remove User
                  </Button>
                </Tooltip>
              </>
            ) : (
              <>
                <Button
                  $full
                  $secondary
                  size={'small'}
                  icon={'ChevronRight'}
                  className={'settings-bottom-button'}
                  onClick={onResendInvite}
                >
                  Resend invitation
                </Button>
                <Button
                  $full
                  $secondary
                  size={'small'}
                  icon={'Trash'}
                  className={'settings-bottom-button'}
                  onClick={onCancelInvite}
                >
                  Cancel invitation
                </Button>
              </>
            )}
          </StickyFooter>
        </Background>
      </ModalDrawer>
    </>
  );
};

export default UserSettingsDrawer;
