import { useContext, useEffect, useState } from 'react';
import { Content, Count, Filter, FilterContainer, Title } from '../styles';
import { AccountsContext } from 'contexts/accounts';
import { isAllPricing } from './utils';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import Checkbox from 'components/checkbox';
import { getPricingCount } from 'pages/Accounts/components/AccountsFilters/Filters/utils';

const PricingPlan: React.FC = (): JSX.Element => {
  const { filters, actions, counts } = useContext(AccountsContext);
  const [allPricing, setAllPricing] = useState(isAllPricing({ plan: filters.pricing_plan }));

  useEffect(() => {
    const isAll = isAllPricing({ plan: filters.pricing_plan });
    setAllPricing(isAll);
  }, [filters.pricing_plan]);

  const setFilter = (e: React.ChangeEvent<HTMLInputElement> | CheckboxChangeEvent) => {
    const name = e.target.name;
    if (name) {
      const value = e.target.value;
      const isNotAll = value !== 'pricing_all';
      const isMultipleValue = ['pricing_plan'].includes(name) && isNotAll;
      actions &&
        actions.onChangeFilters({
          name: name,
          value: value,
          multiple: isMultipleValue,
        });
      actions.clearSelectedItems();
    }
  };

  return (
    <FilterContainer>
      <Title>Pricing plan</Title>
      <Content>
        <Filter>
          <Checkbox
            label="All"
            value="pricing_all"
            id="pricing_all"
            name="pricing_plan"
            onChange={setFilter}
            defaultChecked={allPricing}
            checked={allPricing}
            controlled
          />
        </Filter>
        <Count>{getPricingCount('all', counts)}</Count>
      </Content>
      <Content>
        <Filter>
          <Checkbox
            label="Professional"
            value="professional"
            id="pricing_professional"
            name="pricing_plan"
            onChange={setFilter}
            defaultChecked={filters.pricing_plan.includes('professional')}
            checked={filters.pricing_plan.includes('professional')}
            controlled
          />
        </Filter>
        <Count>{getPricingCount('Professional', counts)}</Count>
      </Content>
      <Content>
        <Filter>
          <Checkbox
            label="Enterprise"
            value="enterprise"
            id="pricing_enterprise"
            name="pricing_plan"
            onChange={setFilter}
            defaultChecked={filters.pricing_plan.includes('enterprise')}
            checked={filters.pricing_plan.includes('enterprise')}
            controlled
          />
        </Filter>
        <Count>{getPricingCount('Enterprise', counts)}</Count>
      </Content>
    </FilterContainer>
  );
};

export default PricingPlan;
