import Button from 'components/button';
import { useMemo } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  height: 34px;
  margin-bottom: 23px;
`;

const PanelLabel = styled.div`
  font-size: 14px;
  line-height: 20px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  button {
    height: 24px;
    text-transform: uppercase;
  }
`;

const ActionButtonContainer = styled.div`
  width: 150px;
`;

interface FCProps {
  dataDisplay: { key: string; checked?: boolean; text: string; label: string }[];
  togglePanelOption: (key: string) => void;
  actionButton?: string;
  onClickactionButton?: () => void;
}

const PanelHeader = ({
  togglePanelOption,
  dataDisplay,
  actionButton,
  onClickactionButton,
}: FCProps): JSX.Element => {
  const label = useMemo(() => {
    const option = dataDisplay.find((item) => item.checked);
    return option?.text;
  }, [dataDisplay]);

  const hasPanelOptions = useMemo(() => {
    return dataDisplay.length > 1;
  }, [dataDisplay]);

  const btnStyles = {
    fontSize: '12px',
    padding: '5px 10px',
    height: '24px',
    lineHeight: '14px',
    borderRadius: '10px',
  } as React.CSSProperties;

  return (
    <Container>
      <PanelLabel dangerouslySetInnerHTML={{ __html: label }}></PanelLabel>
      {actionButton && (
        <ActionButtonContainer>
          <Button $secondary onClick={onClickactionButton} size="small" $full>
            {actionButton}
          </Button>
        </ActionButtonContainer>
      )}
      {hasPanelOptions && (
        <ButtonsContainer>
          {dataDisplay.map(({ key, label, checked }) => {
            return (
              <Button
                key={`type-${key}`}
                $primary={checked}
                $terciary={!checked}
                size="small"
                style={{ ...btnStyles, fontWeight: `${checked ? '700' : '500'}` }}
                onClick={() => {
                  togglePanelOption(key);
                }}
              >
                {label}
              </Button>
            );
          })}
        </ButtonsContainer>
      )}
    </Container>
  );
};
export default PanelHeader;
