import { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { NoticeTopBar } from './index';
import ImpersonateNotice from './ImpersonateNotice';
import { SessionContext } from 'contexts/session';
import { MIGRATED_NOTICE_HEIGHT, MIGRATED_NOTICE_HEIGHT_MOB } from 'constants/settings';
import { isMobile } from 'react-device-detect';

const TopContainer = styled.div<{ active?: boolean; topHeight: number }>`
  display: flex;
  position: fixed;
  top: ${({ topHeight }) => `${topHeight}px`};

  left: 0;
  right: 0;
  width: 100%;
  z-index: 901;

  transform: translateY(-100%);
  visibility: hidden;
  opacity: 0;
  transition: all 0.6s;

  ${({ active }) =>
    active &&
    `
		visibility: visible;
		opacity: 1;
		transform: translateY(0%);
`}
`;

const ImpersonatedNotification = ({
  onActive,
  onClose,
}: {
  onActive: ({ height }) => void;
  onClose: () => void;
}): JSX.Element => {
  const ref = useRef();
  const [activeTopBar, setActiveTopBar] = useState(false);
  const { user, account, actions, isImpersonated } = useContext(SessionContext);

  const showMigratedModal = () => {
    return user.should_show_migration_info;
  };

  const topHeight = showMigratedModal()
    ? isMobile
      ? MIGRATED_NOTICE_HEIGHT_MOB
      : MIGRATED_NOTICE_HEIGHT
    : 0;

  const handleEndImpersonation = () => {
    onClose();
    setActiveTopBar(false);
    actions.impersonate();
  };

  const setActive = ({ ref }) => {
    setActiveTopBar(true);
    onActive({ height: ref.current ? ref.current.clientHeight : 0 });
  };

  useEffect(() => {
    isImpersonated && setActive({ ref });
  }, [isImpersonated]);

  return (
    <TopContainer ref={ref} active={activeTopBar} topHeight={topHeight}>
      <NoticeTopBar>
        <ImpersonateNotice user={user} account={account} onClose={handleEndImpersonation} />
      </NoticeTopBar>
    </TopContainer>
  );
};

export default ImpersonatedNotification;
