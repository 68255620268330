export const ACCOUNT_COLORS = {
  LILAC: '#A793F6',
  BLUE: '#3E6496',
  ORANGE: '#F19E5B',
  RED: '#D73F47',
  GREEN: '#65AAA3',
  YELLOW: '#E8BF39',
  PURPLE: '#9F6490',
  PINK: '#FD8897',
  BEIGE: '#ABA09C',
};
