import { useEffect, useState } from 'react';
import { Skeleton } from 'antd';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { Card } from 'components/layout/profile/profileContent/styles';
import { CardHeaderSection } from '../TeamSharedStyles';
import {
  DataNumber,
  TabButton,
  TabWrapper,
  TabsContainer,
} from 'pages/Analytics/components/MetricTabs/styles';
import { TEAM_SKILLS_TABS } from './configs';
import { MIN_COLUMNS_TO_SHOW, SkillTabs } from './types';
import { IconWrapper } from 'pages/Analytics/components/MetricTabs/styles';
import { TabIcon } from 'pages/Analytics/components/MetricTabs/styles';
import { DataContainer } from 'pages/Analytics/components/MetricTabs/styles';
import { DataDescription } from 'pages/Analytics/components/MetricTabs/styles';
import ButtonIcon from 'components/buttonIcon';
import {
  AverageLabel,
  EmtpyTeamSkillsCol,
  GroupFilterContainer,
  GruopsFilterRow,
  SkillGridContent,
  TeamSkillsCol,
  TeamSkillsColHeader,
  TeamSkillsColUser,
  TeamSkillsColUserHeader,
  TeamSkillsContainer,
  TeamSkillsRow,
  TeamSkillsRowHeader,
  UserLabel,
} from './styles';
import SkillSelector from './SkillSelector';
import { PendingLetterAvatar } from 'components/usersTable/styles';
import { PendingUserImage } from 'components/usersTable/styles';
import { randomColor } from 'utils/colors';
import { getInvitationsAction } from 'contexts/users/actions';
import { getSonarScoresAction } from 'contexts/sonar/actions';
import useTeamUsersSearch from '../TeamUsersTable/useTeamUsersSearch';
import { DEFAULT_IMG_USERS } from 'constants/urls';
import SkillsEmpty from './SkillsEmpty';
import { ContainerMobile } from '../TeamTopUsersAnalytics/styles';
import InputSelect from 'components/inputs/inputSelect';
import { LEARNING_AREAS_TYPES } from 'constants/playlists';
import { SonarScoresFetchParams } from 'contexts/sonar/types';
import { capitalize } from 'utils/helpers';
import ComingSoon from './ComingSoon';
import { StyledRadio } from 'components/radio/styles';

interface TeamSkillsProps {
  teamSlug: string;
}

const TeamSkills = ({ teamSlug }: TeamSkillsProps): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState<SkillTabs>(TEAM_SKILLS_TABS[0]);
  const [groupSelected, setGroupSelected] = useState<string>(selectedTab?.groups[0]);
  const [skillsResponse, setSkillsResponse] = useState<any[]>(null);
  const [skillsHeader, setSkillsHeader] = useState<string[]>([]);
  const [skillsHeaderSelected, setSkillsHeaderSelected] = useState<string[]>([]);
  const [skillData, setSkillData] = useState<any[]>([]);
  const [sorting, setSorting] = useState<string>('desc');
  const [activeUsers, setActiveUsers] = useState<any[]>(null);
  const [fetching, setFetching] = useState(false);
  const { requestData } = useTeamUsersSearch({ teamSlug });
  const history = useHistory();

  const mobileOptions = TEAM_SKILLS_TABS.map(({ icon, label, item }) => {
    return {
      value: item,
      label: label,
      icon: icon,
    };
  });

  const getUsers = async () => {
    setFetching(true);
    requestData.page_size = 0; //retrieve all result pages to populate the skills
    const usersResponse = await getInvitationsAction(requestData);
    if (usersResponse instanceof Error) {
      setFetching(false);
      setActiveUsers([]);
    } else {
      setFetching(false);
      setActiveUsers(usersResponse.results);
    }
  };

  const getSonarScores = async (scoresPayload: SonarScoresFetchParams) => {
    setFetching(true);
    const scoresResponse = await getSonarScoresAction(scoresPayload);
    if (scoresResponse instanceof Error) {
      setFetching(false);
      setSkillData([]);
    } else {
      setSkillsResponse(scoresResponse);
      processResponse(scoresResponse);
      setFetching(false);
    }
  };

  const processResponse = (scoresResponse: any) => {
    if (scoresResponse?.results.length > 0) {
      const skillsDataArr = [];
      const skillsDataColumns = [];
      scoresResponse.results.map((skill) => {
        const skillUser = activeUsers.find((user) => user.uaa_id === skill.uaa_id);
        const skillDataItem = {
          user: {
            name: skillUser?.uaa_data?.profile?.data
              ? `${skillUser?.uaa_data?.profile?.data?.first_name} ${skillUser?.uaa_data?.profile?.data?.last_name}`
              : 'Unknown',
            avatar: skillUser?.uaa_data?.profile?.data?.avatar,
            level: skill.scores?.level,
            id: skillUser?.user,
          },
        };
        skill.scores.skill_areas.map((skillArea) => {
          if (skillArea.group === groupSelected) {
            const { level } = skillArea?.user_status;
            skillsDataColumns.indexOf(skillArea.name) === -1 &&
              skillsDataColumns.push(skillArea.name);
            skillDataItem[skillArea.name] = level;
          }
        });
        skillsDataArr.push(skillDataItem);
      });
      skillsDataArr.sort((a, b) =>
        sorting === 'asc' ? a.user.level - b.user.level : b.user.level - a.user.level
      );
      setSkillsHeader(skillsDataColumns);
      setSkillData(skillsDataArr);
    } else {
      setSkillData([]);
    }
  };

  const handleSelectTab = (item) => {
    const skillTab = TEAM_SKILLS_TABS.find(({ item: thisTab }) => thisTab === item);
    setSelectedTab(skillTab);
    setGroupSelected(skillTab.groups[0]);
  };

  const handleSkillSelected = (skill: string) => {
    if (skillsHeaderSelected.includes(skill)) {
      setSkillsHeaderSelected(skillsHeaderSelected.filter((s) => s !== skill));
    } else {
      setSkillsHeaderSelected([...skillsHeaderSelected, skill]);
    }
  };

  const handleSort = (sort: string) => {
    setSkillData(
      skillData.sort((a, b) =>
        sort === 'asc' ? a.user.level - b.user.level : b.user.level - a.user.level
      )
    );
    setSorting(sort);
  };

  const handleGroupSelected = (group: string) => {
    setGroupSelected(group);
  };

  useEffect(() => {
    if (activeUsers && activeUsers.length > 0) {
      const short_uaa_ids = activeUsers.map((user) => user.uaa_id.split('-')[0]).toString();
      const scoresPayload = {
        uaa_ids: short_uaa_ids,
        learning_area_id: LEARNING_AREAS_TYPES[selectedTab.key]?.id,
        deep_details: 0,
      };
      getSonarScores(scoresPayload);
    }
  }, [activeUsers, selectedTab]);

  useEffect(() => {
    setSkillsHeaderSelected(skillsHeader.slice(0, MIN_COLUMNS_TO_SHOW));
  }, [skillsHeader]);

  useEffect(() => {
    skillsResponse && processResponse(skillsResponse);
  }, [groupSelected]);

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <Card>
      <CardHeaderSection style={{ justifyContent: 'flex-start', gap: '5px' }}>
        <h5>Skill Sonar</h5>
      </CardHeaderSection>
      <TabsContainer skillTabs>
        {
          <ContainerMobile>
            <InputSelect
              value={`${selectedTab.label}`}
              icon={selectedTab.icon}
              options={mobileOptions}
              onChange={(selectValue) => handleSelectTab(selectValue)}
            />
          </ContainerMobile>
        }
        {TEAM_SKILLS_TABS.map(({ item, label, icon, soon }) => (
          <TabButton
            key={item}
            active={item === selectedTab?.item}
            onClick={() => handleSelectTab(item)}
          >
            <TabWrapper>
              <IconWrapper style={{ background: 'transparent' }}>
                {<TabIcon src={icon} />}
              </IconWrapper>
              <DataContainer>
                <DataNumber>{label}</DataNumber>
                <DataDescription>{soon && 'Coming soon'}</DataDescription>
              </DataContainer>
            </TabWrapper>
          </TabButton>
        ))}
      </TabsContainer>
      <TeamSkillsContainer>
        {selectedTab?.soon ? (
          <ComingSoon skillName={selectedTab.label} />
        ) : fetching ? (
          <Skeleton
            active={fetching}
            avatar
            paragraph={{ rows: 0 }}
            style={{ marginTop: '10px' }}
          />
        ) : skillData.length === 0 ? (
          <SkillsEmpty />
        ) : (
          <>
            <GruopsFilterRow>
              {selectedTab?.groups?.map((group, index) => (
                <GroupFilterContainer key={index}>
                  <StyledRadio
                    checked={groupSelected === group}
                    defaultChecked={groupSelected === group}
                    name={group}
                    value={group}
                    onClick={() => handleGroupSelected(group)}
                  />
                  {group}
                </GroupFilterContainer>
              ))}
            </GruopsFilterRow>
            <TeamSkillsRowHeader>
              <TeamSkillsColUserHeader>
                <ButtonIcon
                  style={{ margin: '0px 4px', minWidth: '30px', boxShadow: 'none' }}
                  $terciary
                  size={'small'}
                  icon={sorting === 'desc' ? 'Sort' : 'SortDown'}
                  onClick={() => handleSort(sorting === 'asc' ? 'desc' : 'asc')}
                />
                USER LEVEL
              </TeamSkillsColUserHeader>
              {skillsHeaderSelected.map((skill, index) => (
                <TeamSkillsColHeader widthValue={100 / skillsHeaderSelected.length} key={index}>
                  {skill}
                </TeamSkillsColHeader>
              ))}
              {!isMobile && (
                <SkillSelector
                  skills={skillsHeader}
                  skillsSelected={skillsHeaderSelected}
                  onSkillSelected={handleSkillSelected}
                />
              )}
            </TeamSkillsRowHeader>
            {skillData &&
              skillData.map((skill, index) => (
                <TeamSkillsRow key={index}>
                  <TeamSkillsColUser key={index}>
                    {!isMobile && skill.user.image && skill.user.image !== DEFAULT_IMG_USERS ? (
                      <PendingUserImage
                        src={skill.user.avatar}
                        alt={skill.user.name}
                        style={{ borderRadius: '50%' }}
                      />
                    ) : (
                      !isMobile && (
                        <PendingLetterAvatar
                          color={randomColor(skill.user.name)}
                          style={{ borderRadius: '50%' }}
                        >
                          {skill.user.name[0]}
                        </PendingLetterAvatar>
                      )
                    )}
                    <div>
                      <UserLabel onClick={() => history.push(`/user/${skill.user.id}`)}>
                        {skill.user.name}
                      </UserLabel>
                      {/* <AverageLabel>{skill.user?.level || 'Novice'}</AverageLabel> */}
                    </div>
                  </TeamSkillsColUser>
                  {skillsHeaderSelected.map((skillHeader, index) => (
                    <TeamSkillsCol widthValue={100 / skillsHeaderSelected.length} key={index}>
                      <SkillGridContent key={index} dataValue={skill[skillHeader]}>
                        {!isMobile && (capitalize(skill[skillHeader]) || 'No Data')}
                      </SkillGridContent>
                    </TeamSkillsCol>
                  ))}
                  <EmtpyTeamSkillsCol />
                </TeamSkillsRow>
              ))}
          </>
        )}
      </TeamSkillsContainer>
    </Card>
  );
};

export default TeamSkills;
