import { useContext } from 'react';
import { isMobile } from 'react-device-detect';
import Checkbox from 'components/checkbox';
import { UsersContext } from 'contexts/users';
import { Account } from 'contexts/accounts/types';
import { FilterCount, FilterWrapper } from './styles';
import { FilterChangeEvents } from 'components/usersAsideFilters/types';

const AccountUsersAsideFilterRoles = ({ account }: { account: Account }): JSX.Element => {
  const { usersCounts, filters, actions } = useContext(UsersContext);
  const active_count = usersCounts?.active_count;

  const handleFilter = (e: FilterChangeEvents) => {
    actions.onChangeFilters(
      {
        name: 'roles',
        value: e.target.value,
      },
      e.target.value !== '' ? true : false,
      true
    );
  };
  return (
    <>
      <h3 className="mt-16 mb-5 fs-1 card-title">Role</h3>
      <div className="users-aside-filters">
        <FilterWrapper>
          <Checkbox
            value=""
            id="all"
            name="roles"
            onChange={handleFilter}
            defaultChecked={!filters.roles.length}
            checked={!filters.roles.length}
            label="All"
            controlled={!isMobile}
          />
          <FilterCount>
            {filters.active === 'true' ? active_count?.active : active_count?.pending}
          </FilterCount>
        </FilterWrapper>
        <FilterWrapper>
          <Checkbox
            value="account_owner"
            id="account_owner"
            name="roles"
            onChange={handleFilter}
            defaultChecked={filters.roles.includes('account_owner')}
            checked={filters.roles.includes('account_owner')}
            label="Account Owner"
            controlled={!isMobile}
          />
          <FilterCount>{account.roles_count.account_owner}</FilterCount>
        </FilterWrapper>
        <FilterWrapper>
          <Checkbox
            value="team_manager"
            id="team_manager"
            name="roles"
            defaultChecked={filters.roles.includes('team_manager')}
            checked={filters.roles.includes('team_manager')}
            onChange={handleFilter}
            label="Team Manager"
            controlled={!isMobile}
          />
          <FilterCount>{account.roles_count.team_manager}</FilterCount>
        </FilterWrapper>
      </div>
    </>
  );
};

export default AccountUsersAsideFilterRoles;
