import { screenLg, screenMd, screenSm } from 'constants/styles/breakpoints';
import { camelize } from 'utils/helpers';
import Button from 'components/button/Button';
import styled from 'styled-components';

export const MainContent = styled.main<{ lock: boolean }>`
  margin-bottom: 120px;
  min-height: calc(100vh - 160px);

  ${screenMd} {
    padding: 0;
    margin-bottom: 0;
  }
  ${({ lock }) =>
    lock &&
    `
		opacity: 0.4;
		pointer-events: none;
	`}
`;

export const Header = styled.div<{
  isSonar: boolean;
  sonarBackground: string;
}>`
  display: flex;
  align-items: flex-end;
  position: relative;
  background: linear-gradient(89.99deg, #863ad2 3.58%, #5936e4 97.14%);
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #fff;
  height: 100px;
  padding: 10px;
  border-radius: 8px 8px 0 0;
  ${screenLg} {
    height: 125px;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    padding: 0 0 16px 22px;
    border-radius: 12px 12px 0 0;
    ${({ isSonar }) => isSonar && 'padding: 0 23px;'};
  }
  ${({ isSonar, sonarBackground }) =>
    isSonar &&
    `
    background: var(${sonarBackground});
    align-items: center;
    `};
  img {
    width: 170px;
    height: 60px;
    box-sizing: content-box;
    padding-right: 23px;
    margin-right: 23px;
    border-right: 1px solid var(--butterfly-bush);
  }
`;

export const SubHeader = styled.div`
  display: flex;
  align-items: center;
  background: var(--white);
  color: var(--icon-default);
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding: 10px;
  border-radius: 0 0 8px 8px;
  ${screenSm} {
    font-weight: 450;
    font-size: 16px;
    line-height: 24px;
  }
  ${screenLg} {
    border-radius: 0 0 12px 12px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    padding: 10px 20px;
    font-weight: 450;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Description = styled.span`
  font-weight: 450;
  font-size: 12px;
  line-height: 14px;
  flex: 1;
`;

export const SonarHeaderInfo = styled.div`
  width: 100%;
  font-weight: 450;
  font-size: 16px;
  line-height: 24px;
  color: var(--light-gray-darker);
`;

export const SonarHeaderActivity = styled.div`
  align-self: end;
  font-size: 14px;
  line-height: 20px;
  font-weight: 450;
  color: var(--light-gray-darker);
  b {
    color: var(--black-dark);
  }
  span {
    cursor: pointer;
    color: var(--lilac-dark);
  }
`;

export const ContentWrapper = styled.div`
  padding: 10px;
  background: #fff;

  ${screenSm} {
    margin: 15px 0px;
    border-radius: 10px;
    padding: 30px;
  }
`;

export const ContentHeaderWrapper = styled.div`
  display: none;
  ${screenLg} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
`;

export const ContentTitleWrapper = styled.div<{
  isSonar: boolean;
}>`
  display: flex;
  ${({ isSonar }) =>
    isSonar
      ? `
    flex-direction: column;
    gap: 10px;
    `
      : `
    justify-content: space-between;
    width: 100%;
    align-items: center;
  `};
`;

export const ContentTitleDescription = styled.div`
  font-size: 16px;
  line-height: 24px;
`;

export const ContentActivity = styled.div`
  display: flex;
`;

export const ContentTitle = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #1a2535;
`;

export const SectionTitle = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #1a2535;
  gap: 15px;
  display: flex;
  align-items: center;
`;

export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  position: relative;
`;

export const SectionContentWrapper = styled.div`
  padding: 30px 20px;
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 10px;

  /* ${screenSm} {
    margin: 10px 0px;
  } */
`;

export const SmallInfo = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #aeaeb2;
  bottom: -10px;
`;

export const GroupItemRow = styled.div`
  display: flex;
  align-self: stretch;
  height: auto;
`;

export const TabsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  background: #fff;

  ${screenSm} {
    padding-right: 10px;
  }

  ${screenLg} {
    display: none;
  }
`;
export const FixedBottomButton = styled(Button)`
  position: fixed;
  bottom: 80px;
  left: 10px;
  right: 10px;
  z-index: 1;

  ${screenSm} {
    margin: 10px;
    position: initial;
  }
`;

export const GroupItemsContainer = styled.div<{
  isDraggingOver: boolean;
  isEmpty: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  min-height: 80px;
  ${screenSm} {
    min-height: 92px;
    margin-top: 40px;
  }

  ${({ isEmpty }) => isEmpty && 'border: 1px dashed #A793F6'};
  ${({ isDraggingOver }) => isDraggingOver && 'background: #F3ECFF'};
  border-radius: 10px;
`;

export const GroupItemsEmpty = styled.text`
  font-weight: 450;
  font-size: 18px;
  line-height: 24px;
  color: #aeaeb2;

  > span {
    cursor: pointer;
    color: #a793f6;
    text-decoration: underline;
  }
`;

export const GroupItemMainWrapper = styled.div`
  display: flex;
  align-self: stretch;
  flex: 1;
  padding: 10px;
  column-gap: 10px;
  align-items: center;
  background: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #f2f2f7;
  border-radius: 10px;

  ${screenSm} {
    border-radius: 0px 10px 10px 0px;
    border-left-width: 0px;
    padding: 12.5px 20px 12.5px 30px;
  }
`;

export const GroupItemDataRow = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
export const GroupItemDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  flex: 1;
`;
export const GroupItemButtonsWrapper = styled.div`
  display: flex;
  padding: 10px;
`;

export const CourseDifficulty = styled.div`
  display: none;

  ${screenSm} {
    display: initial;

    text-transform: capitalize;
    font-weight: 450;
    font-size: 12px;
    line-height: 14px;
    color: #57575c;
  }
`;
export const GroupItemTitle = styled.p`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.default.colors.palette.Business_DarkThemeGeneralBackground};
  margin: 0;
  width: 100%;
  overflow: hidden;
  word-break: break-word;

  ${screenSm} {
    margin: 5px 0px;
  }
`;
export const CourseDataBottom = styled.div`
  display: none;

  ${screenSm} {
    display: flex;
    column-gap: 10px;
    font-weight: 450;
    font-size: 12px;
    line-height: 14px;
    color: #57575c;
  }
`;
export const CourseDataDivider = styled.div`
  border-left: 1px solid #888;
  align-self: stretch;
`;
export const ButtonsWrapper = styled.div<{ gap?: number }>`
  display: flex;
  align-items: center;
  gap: ${({ gap }) => (gap ? `${gap}px` : '10px')};
`;

export const ButtonWrapper = styled.div`
  cursor: pointer;
`;

export const CourseLearningArea = styled.span<{ value: string }>`
  &:before {
    content: '';
    width: 8px;
    height: 8px;
    background: ${({ theme, value }) =>
      value && theme.default.colors.learningArea[camelize(value).toLocaleLowerCase()]?.base};
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
  }
`;
