import { BarLabels } from 'components/stats/CustomBarChart/types';
import { Label } from 'pages/Analytics/components/AnalyticsCounters/styles';
import { FC } from 'react';
import styled from 'styled-components';

interface HeaderData {
  title: string;
  labels: BarLabels[];
}

const AnalyticsUserHeaderContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  .title {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    margin-left: 40px;
  }
  .labels-container {
    display: flex;
    gap: 10px;
    position: absolute;
    right: 80px;
  }
`;

const AnalyticsUserHeader: FC<HeaderData> = ({ title, labels }) => {
  return (
    <AnalyticsUserHeaderContainer>
      <span className="title">{title}</span>
      <div className="labels-container">
        {labels.map(({ key, label, color }) => (
          <Label key={key} color={color}>
            {label}
          </Label>
        ))}
      </div>
    </AnalyticsUserHeaderContainer>
  );
};

export default AnalyticsUserHeader;
