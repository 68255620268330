import React, { FormEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import Icon from 'components/icons';
import { Input } from 'components/modal/styles';
import { getInvitations } from 'services/users';
import Notification from 'components/notification';
import UserSuggestion from './UserSuggestionItem';
import { screenMd } from 'constants/styles/breakpoints';
import { Spin } from 'components/loadings/Spinner';
import SpinnerIMG from 'assets/images/spinner.png';

const InputContainer = styled.div`
  position: relative;
  > input {
    padding-left: 40px;
    font-size: 15px;
  }
  > svg {
    position: absolute;
    top: 10px;
    left: 10px;
  }
`;

const SuggestionsContainer = styled.div`
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  margin-top: 8px;
  padding-bottom: 60px;
  ${screenMd} {
    height: 150px;
    padding-bottom: 0;
  }
`;

let historySuggestions = null;

const UsersSuggestions: React.FC<{
  onSelect: (data) => void;
  checkeds: string[];
  areSuggestions;
  teamId: string;
}> = ({ onSelect, checkeds, areSuggestions, teamId }) => {
  const [suggestions, setSuggestions] = useState(historySuggestions);
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    const getNoTeamUsers = async () => {
      try {
        const response = await getInvitations({
          active: 'true',
          license: null,
          not_in_teams: teamId,
          roles: null,
          page: 1,
          page_size: Number.MAX_SAFE_INTEGER,
          search: null,
        });
        if (response.status === 200) {
          if (response.data.results?.length > 0) {
            historySuggestions = response.data.results;
            setSuggestions(historySuggestions);
          } else {
            setSuggestions(null);
            areSuggestions(false);
          }
        }
      } catch (error) {
        Notification({ text: 'There has been a problem', type: 'error' });
      }
      setFetching(false);
    };
    getNoTeamUsers();
  }, []);

  const handleSearchInput = (e: FormEvent<HTMLInputElement>): void => {
    const value = e.currentTarget.value;
    const newSuggestions = historySuggestions.filter((item) => item.email.includes(value));
    setSuggestions(newSuggestions);
  };

  return (
    <>
      {!suggestions && fetching && (
        <div style={{ position: 'relative', minHeight: '70px' }}>
          <Spin src={SpinnerIMG} style={{ width: '57px', height: '57px' }} />
        </div>
      )}

      {suggestions && (
        <>
          <InputContainer>
            <Input placeholder="Search" onChange={handleSearchInput} />
            <Icon icon={'Search'} />
          </InputContainer>
          {suggestions?.length > 0 && (
            <SuggestionsContainer>
              {suggestions.map((suggestion) => {
                return (
                  <UserSuggestion
                    key={suggestion.id}
                    {...suggestion}
                    checked={checkeds.includes(suggestion.id)}
                    onCheck={onSelect}
                  />
                );
              })}
            </SuggestionsContainer>
          )}
        </>
      )}
    </>
  );
};

export default UsersSuggestions;
