import { AxiosResponse } from 'axios';
import {
  RecommendedFetchParams,
  RecommendedPlaylistsFetchParams,
  SonarScoresFetchParams,
} from 'contexts/sonar/types';
import { TEAMS_API, TEAMS_API_V2 } from './axios';
import { config } from 'config/config.local';

export const getSonarScores = (params: SonarScoresFetchParams): Promise<AxiosResponse<any>> => {
  return TEAMS_API.get(config.routes.sonar.scores, { params });
};

export const getRecommendedContent = (
  params: RecommendedFetchParams
): Promise<AxiosResponse<any>> => {
  return TEAMS_API.get(config.routes.sonar.recommended, { params });
};

export const getRecommendedContentPlaylists = (
  params: RecommendedPlaylistsFetchParams
): Promise<AxiosResponse<any>> => {
  return TEAMS_API_V2.get(config.routes.sonar.recommended, { params });
};
