import { Modal as AntModal } from 'antd';
import Icon from 'components/icons';
import { ModalProps } from 'antd/lib/modal/Modal';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { screenMd } from 'constants/styles/breakpoints';
import styled from 'styled-components/macro';

export enum ModalSize {
  'default' = 'default',
  'small' = 'small',
}
export interface IModalProps extends ModalProps {
  onlyBody?: boolean;
  children?: React.ReactNode;
  size?: ModalSize;
  closeIconColor?: string;
  fullscreen?: boolean;
}

const modalDefault = ({ children, ...rest }: IModalProps) => {
  return <AntModal {...rest}>{children}</AntModal>;
};

const StyledModal = styled(modalDefault)<IModalProps>`
  max-width: 100% !important;
  margin: 0;
  padding-bottom: 0;
  .ant-modal-body {
    height: auto;
    width: auto;
    padding: 0;
  }
  .ant-modal-content {
    ${screenMd} {
      border-radius: 20px;
    }
    .ant-modal-close-x {
      color: white;
    }
  }

  ${({ fullscreen }) =>
    fullscreen &&
    `
		.ant-modal-body {
			height: 100vh;
			width: 100%;
			padding: 0;
		}
		.ant-modal-content {
			align-items: center;
			display: block;
			position: relative;
		}
	`}
`;

const Modal = ({
  children,
  onlyBody,
  width,
  fullscreen,
  closeIconColor = '#000',
  size = ModalSize.default,
  ...rest
}: IModalProps): JSX.Element => {
  const footer = onlyBody ? null : rest.footer;

  return (
    <StyledModal
      width={width}
      fullscreen={fullscreen}
      footer={footer}
      size={size}
      centered
      zIndex={1500}
      closeIcon={
        <Icon
          icon="Close"
          color={closeIconColor}
          style={
            isMobile
              ? { position: 'absolute', right: 12, top: 12, fill: 'var(--white)' }
              : { position: 'absolute', right: 22, top: 22, fill: 'var(--white)' }
          }
        />
      }
      maskStyle={{ background: 'rgb(0,0,0,0.8)', backdropFilter: 'blur(5px)', zIndex: 1100 }}
      {...rest}
    >
      {children}
    </StyledModal>
  );
};

export default Modal;
