import { IUserPlaylist } from 'contexts/playlists/types';
import styled from 'styled-components';
import { AvatarLetter } from 'components/avatar';

interface ItemUserProps {
  user: IUserPlaylist;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  overflow: hidden;
  padding-right: 10px;
`;

const UserName = styled.p`
  display: block;
  margin: 0;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #1a2535;
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const EmailData = styled.div`
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #57575c;
`;

const Avatar = (props: ItemUserProps): JSX.Element => {
  const { user } = props;
  const fullName = `${user.first_name} ${user.last_name}`;

  return (
    <Container>
      <AvatarLetter avatar={user.avatar} name={fullName} email={user.email} />
      <NameContainer>
        <UserName>{fullName}</UserName>
        <EmailData>{user.email}</EmailData>
      </NameContainer>
    </Container>
  );
};

export default Avatar;
