import React, { useEffect, useState } from 'react';

import Modal from 'components/modal';

import { isMobile } from 'react-device-detect';
import {
  Description,
  HeaderContainer,
  ModalActions,
  ModalContainer,
  ModalContent,
  ModalSection,
  ModalSectionTitle,
} from 'components/modal/styles';
import {
  ContentButton,
  ContentDate,
  ContentHeader,
  ContentItemWrapper,
  ContentLabel,
  ContentName,
  ContentSection,
  ContentWrapper,
  EmptyState,
  ItemSection,
} from './styles';
import Button from 'components/button';
import { IPlaylist } from 'contexts/playlists/types';
import Notification from 'components/notification';
import { getPlaylistChangelogAction } from 'contexts/playlists/actions';
import { Spinner } from 'components/loadings';
import {
  AlgoliaContent,
  IPlaylistChangelogContent,
  IPlaylistContent,
} from 'contexts/content/types';
import { setupAlgoliaSearchInstance } from 'utils/searchAlgolia';
import ContentTypeTag from 'pages/Playlists/components/AddContent/ContentTypeTag';
import { formatDistanceToNowStrict } from 'date-fns';
import { ReactComponent as ExternalLinkIcon } from 'assets/icons/external-link.svg';
import { buildContentLink } from 'contexts/content/utils';

interface IPlaylistChangelog {
  isVisible?: boolean;
  playlist: IPlaylist;
  userName: string;
  onClose?: () => void;
}

const PlaylistChangelogModal: React.FC<IPlaylistChangelog> = ({
  isVisible,
  playlist,
  userName,
  onClose,
}) => {
  const [loading, setLoading] = useState(false);
  const [changelogData, setChangelogData] = useState([]);

  useEffect(() => {
    setChangelogData([]);
    getChangelogData();
  }, []);

  const getChangelogData = async () => {
    setLoading(true);
    try {
      const { results } = await getPlaylistChangelogAction(playlist.id);
      const algoliaResults = await getAlgoliaItems(results);
      processData(results, algoliaResults);
    } catch (error) {
      if (error?.response?.data?.details) {
        Notification({ text: error.response.data.details, type: 'error' });
      }
      onClose();
    } finally {
      setLoading(false);
    }
  };

  const getContentIds = (results: IPlaylistChangelogContent[]) => {
    const ids = results?.map((group) => {
      const added = group?.added.map((c) => c.uuid);
      const removed = group?.removed.map((c) => c.uuid);
      return [...added, ...removed];
    });
    return [...new Set(ids.flat())];
  };

  const getAlgoliaItems = async (results: IPlaylistChangelogContent[]) => {
    const algoliaSearchIndex = setupAlgoliaSearchInstance();
    const contentIds = getContentIds(results);
    if (contentIds?.length) {
      const { results } = await algoliaSearchIndex?.algoliaIndex?.getObjects(contentIds);
      return results?.filter((c: AlgoliaContent) => c !== null);
    }
  };

  const processData = (content: IPlaylistChangelogContent[], algoliaResults: AlgoliaContent[]) => {
    content?.forEach((sa: any) => {
      const skillArea = sa;
      skillArea.added = replaceContent(sa.added, algoliaResults);
      skillArea.removed = replaceContent(sa.removed, algoliaResults);
      setChangelogData((data) => [...data, skillArea]);
    });
  };
  const replaceContent = (content: IPlaylistContent[], algoliaResults: AlgoliaContent[]) => {
    return content
      ?.map((c) => {
        const content = algoliaResults.find((aItem) => aItem.objectID === c.uuid);
        return content || null;
      })
      .filter((c) => c !== null);
  };

  const handleOpenitem = (item: AlgoliaContent) => {
    const url = buildContentLink(item);
    if (!url) return;
    window.open(url, '_blank');
  };
  return (
    <Modal
      centered
      visible={isVisible}
      fullscreen={isMobile}
      onCancel={() => onClose()}
      width={isMobile ? '100%' : '620px'}
      footer={null}
      closeIconColor="#FFF"
      maskClosable={false}
    >
      <ModalContainer>
        <HeaderContainer isSonar={true}>
          <h2>{playlist.name}</h2>
        </HeaderContainer>
        <ModalContent style={{ padding: '30px 30px 20px 30px' }}>
          <ModalSectionTitle customMargin="0" style={{ padding: '0 10px 0 0' }}>
            Change log
          </ModalSectionTitle>

          <ModalSection style={{ padding: '0' }} customMargin="0 10px 20px 0">
            <Description customMargin="20px 0">
              As your teams skills develop, the playlist content will adapt to match. This ensures
              that training always aligns with current skill levels, providing an optimal training
              experience.
            </Description>
          </ModalSection>
          <ItemSection>
            {loading ? (
              <EmptyState>
                <div>
                  <Spinner active={loading} top={'15px'} position="relative" size="50" />
                </div>
              </EmptyState>
            ) : changelogData.length ? (
              changelogData.map((item, index) => {
                return (
                  <ContentWrapper key={`section-${index}`}>
                    <ContentHeader>
                      <ContentLabel>{`${userName}'s ${item.reason}`}</ContentLabel>
                      <ContentDate>
                        {formatDistanceToNowStrict(new Date(item.date), { addSuffix: true })}
                      </ContentDate>
                    </ContentHeader>
                    {item.added.length > 0 && (
                      <ContentSection>
                        <ContentLabel style={{ marginBottom: '4px' }}>
                          Training items added:
                        </ContentLabel>
                        {item.added.map((content, index) => {
                          return (
                            <div key={`content-added-${index}`}>
                              <li>
                                <ContentItemWrapper>
                                  <ContentTypeTag value={content.content_type}></ContentTypeTag>
                                  <ContentName>{content.name}</ContentName>
                                  <ContentButton>
                                    <ExternalLinkIcon onClick={() => handleOpenitem(content)} />
                                  </ContentButton>
                                </ContentItemWrapper>
                              </li>
                            </div>
                          );
                        })}
                      </ContentSection>
                    )}
                    {item.removed.length > 0 && (
                      <ContentSection>
                        <ContentLabel style={{ marginBottom: '4px' }}>
                          Training items removed:
                        </ContentLabel>
                        {item?.removed.map((content, index) => {
                          return (
                            <div key={`content-removed-${index}`}>
                              <ContentItemWrapper>
                                <ContentTypeTag value={content.content_type}></ContentTypeTag>
                                <ContentName>{content.name}</ContentName>
                                <ContentButton>
                                  <ExternalLinkIcon onClick={() => handleOpenitem(content)} />
                                </ContentButton>
                              </ContentItemWrapper>
                            </div>
                          );
                        })}
                      </ContentSection>
                    )}
                  </ContentWrapper>
                );
              })
            ) : (
              <EmptyState>No changes logged yet</EmptyState>
            )}
          </ItemSection>
        </ModalContent>
        <ModalActions style={{ marginTop: '20px' }}>
          <Button $primary style={{ marginLeft: 'auto' }} onClick={onClose}>
            Close
          </Button>
        </ModalActions>
      </ModalContainer>
    </Modal>
  );
};

export default PlaylistChangelogModal;
