import styled, { css } from 'styled-components';
import { screenMd } from 'constants/styles/breakpoints';
import { colors } from 'constants/styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 10px 10px 10px;
  padding-bottom: 140px;

  ${screenMd} {
    padding: 20px;
  }
`;

const cellStyles = css`
  &&& {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    align-items: center;
    font-weight: 450;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const AccountCell = styled.div`
  ${cellStyles}

  color: ${({ theme }) => theme.default.colors.palette.Business_DarkThemeGeneralBackground};
`;

export const AccountCellLink = styled.div`
  ${cellStyles}

  font-weight: 700;
  cursor: pointer;
  color: ${({ theme }) => theme.default.colors.palette.Business_DataVizLilacDark};
`;

export const AccountStatusTag = styled.div<{ bgColor: string }>`
  display: inline-block;
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 10px;
  padding: 4px 8px;
  margin-bottom: 3px;

  color: #fff;
  font-weight: 450;
  font-size: 12px;
  line-height: 14px;
`;

export const AccountStatusContainer = styled.div`
  display: flex;
  span {
    font-size: 12px;
    color: #777777;
  }
`;

export const AccountStatusDot = styled.div<{ bgColor: string }>`
  width: 8px;
  height: 8px;
  margin-top: 5px;
  margin-right: 5px;
  background: ${({ bgColor }) => bgColor};
  border-radius: 20px;
`;

export const TypeLabel = styled.div`
  display: inline-block;
  color: #000;
  line-height: 14px;
  padding: 1px 6px;
  font-size: 10px;
  font-weight: 400;
  background: linear-gradient(
    112.2deg,
    rgba(187, 188, 199, 0.4) -46.39%,
    rgba(187, 188, 199, 0) 112.38%
  );
  border-radius: 6px;
`;

export const DateToNowSpan = styled.span`
  font-weight: 50;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.fonts.darkGray} !important;
`;
