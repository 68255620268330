import styled from 'styled-components';
import { screenLg } from 'constants/styles/breakpoints';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  ${screenLg} {
    flex-direction: row;
  }
`;

export const RaxNotification = styled.div`
  display: flex;
  flex-direction: column;
  a {
    color: var(--white);
    text-decoration: underline;
    &:focus {
      text-decoration: underline !important;
    }
  }
`;

export const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex: 1 1 50%;
  flex-direction: column;
  gap: 10px;
`;
export const Header = styled.div`
  width: 100%;
  display: flex;
`;

export const Title = styled.h2`
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
`;
