import { useEffect, useState } from 'react';
import { getNotesAction, getNotesUsersAction } from 'contexts/notes/actions';
import { FilterItem, FilterProps, FilterRange, NotesDataCtx } from 'contexts/notes/types';
import { notesInitialValues } from 'contexts/notes/utils';
import { deleteNote } from 'services/notes';
import Notification from 'components/notification';

interface NotesHook {
  fetching: boolean;
  filters: FilterProps;
  isEmpty: boolean;
  notesTable: NotesDataCtx['table'];
  error: string | boolean;
  noteUsers: NotesDataCtx['noteUsers'];
  onChangeFilter: (filter: FilterItem) => void;
  onChangeFilters: (filters: FilterItem[]) => void;
  onChangeRangeFilters: (range: FilterRange) => void;
  onNotesRefresh: () => void;
  onDeleteNote: (id: string) => void;
  onRefreshNoteUsers: () => void;
}

const useNotesData = (): NotesHook => {
  const [notesTable, setNotesTable] = useState(notesInitialValues.table);
  const [fetching, setFetching] = useState(false);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const [filters, setFilters] = useState<FilterProps>(notesInitialValues.filters);
  const [noteUsers, setNoteUsers] = useState([]);

  const getPaginatedNotes = async ({ params }) => {
    setFetching(true);
    try {
      const notesData = await getNotesAction(params);
      if (notesData instanceof Error) {
        setNotesTable((prevState) => ({ ...prevState, error: notesData?.message }));
      } else {
        setNotesTable((prevState) => {
          return {
            ...prevState,
            count: notesData?.results?.count,
            items: filters.append_results
              ? [...prevState.items, ...notesData?.results?.results]
              : notesData?.results?.results,
          };
        });
        setIsEmpty(notesData.results?.results.length === 0);
      }
      setFetching(false);
    } catch (error) {
      setFetching(false);
    }
  };

  const handleReqData = () => {
    return {
      page_size: parseInt(filters['page_size']),
      page: parseInt(filters['page']),
      search: filters['search'] || null,
      from_date: filters['from_date'] ? `${filters['from_date']}T00:00:00` : null,
      to_date: filters['to_date'] ? `${filters['to_date']}T23:59:59` : null,
      content: filters['content'] || null,
      content_id: filters['content_id'] || null,
      ordering: filters['ordering'] || null,
      created_by: filters['created_by'] || null,
    };
  };

  const retrieveNoteUsers = async () => {
    const noteUsersResponse = await getNotesUsersAction({
      content: filters.content,
      content_id: filters.content_id,
    });
    if (noteUsersResponse instanceof Error) {
      setNoteUsers([]);
    } else {
      setNoteUsers(
        noteUsersResponse?.results?.results.map(({ uaa_id, name }) => {
          return {
            label: name,
            value: uaa_id,
            key: uaa_id,
          };
        })
      );
    }
  };

  const onChangeFilter = (filter: FilterItem) => {
    const name = filter.name.toString();
    const value = filter.value.toString();
    const resetPage = name !== 'page';
    setFilters((prevState) => {
      return resetPage
        ? {
            ...prevState,
            page: '1',
            [name]: value,
            append_results: false,
          }
        : {
            ...prevState,
            [name]: value,
            append_results: true,
          };
    });
  };

  const onChangeFilters = (filters: FilterItem[]) => {
    const newFilters = filters.reduce(
      (acc, filter) => ({ ...acc, [filter.name]: filter.value }),
      {}
    );
    setFilters((prevState) => ({
      ...prevState,
      ...newFilters,
    }));
  };

  const onChangeRangeFilters = (dates: FilterRange) => {
    const fromName = dates.fromName;
    const fromValue = dates.fromValue;
    const toName = dates.toName;
    const toValue = dates.toValue;

    setFilters((prevState) => ({
      ...prevState,
      page: '1',
      [fromName]: fromValue,
      [toName]: toValue,
      append_results: false,
    }));
  };

  useEffect(() => {
    filters.content && getPaginatedNotes({ params: handleReqData() });
  }, [filters]);

  useEffect(() => {
    filters.content && retrieveNoteUsers();
  }, [filters.content]);

  const onDeleteNote = async (id: string) => {
    setFetching(true);
    try {
      await deleteNote(id);
      Notification({ type: 'success', text: 'Note successfully deleted' });
      onNotesRefresh();
      retrieveNoteUsers();
    } catch (error) {
      Notification({ type: 'error', text: `Note couldn't be deleted` });
    }
  };

  const onNotesRefresh = () => {
    setFilters((prevState) => ({
      ...prevState,
      page: '1',
      append_results: false,
    }));
  };

  return {
    fetching: fetching,
    notesTable: notesTable,
    error: false,
    isEmpty,
    filters,
    noteUsers: noteUsers,
    onChangeFilter,
    onChangeFilters,
    onChangeRangeFilters,
    onDeleteNote,
    onRefreshNoteUsers: () => retrieveNoteUsers(),
    onNotesRefresh: onNotesRefresh,
  };
};

export default useNotesData;
