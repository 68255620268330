import React, { useContext, useEffect, useState } from 'react';
import Modal from 'components/modal';
import { isMobile } from 'react-device-detect';

import { AddUsersWrapper, ModalContentWrapper, SubTitle, Title, UserFilterWrapper } from './styles';
import {
  HeaderContainer,
  ModalActions,
  ModalContainer,
  ModalSection,
} from 'components/modal/styles';
import Button from 'components/button';
import { IAssignUsersParams, assignUsers } from 'services/playlists';
import { IPlaylist, IPlaylistUserListItemResponse } from 'contexts/playlists/types';
import Notification from 'components/notification';
import { Spinner } from 'components/loadings';
import UserSuggestionItem from 'pages/Teams/components/InviteToTeam/UserSuggestionItem';
import { SessionContext } from 'contexts/session';
import SearchFilter from './SearchFilter';

import { getInvitations } from 'services/users';
import { UserInvites } from 'contexts/users/types';

interface IAddUsersPlaylist {
  isVisible?: boolean;
  playlist: IPlaylist;
  playlistUsers?: IPlaylistUserListItemResponse[];
  onCancel: () => void;
  onConfirm?: () => void;
}

const AssignUsers: React.FC<IAddUsersPlaylist> = ({
  isVisible,
  playlist,
  playlistUsers,
  onCancel,
  onConfirm,
}) => {
  const title = 'Add users to your playlist';
  const ctaLabel = 'Add users';
  const [query, setQuery] = useState<string>('');
  const [usersIds, setUsersIds] = useState<string[]>([]);
  const [fetching, setFetching] = useState<boolean>(false);
  const { account } = useContext(SessionContext);
  const business_account_id = account?.id;

  const [users, setUsers] = useState([]);

  const getUsers = async (query: string) => {
    setFetching(true);
    try {
      const response = await getInvitations({
        active: 'true',
        license: null,
        teams: null,
        roles: null,
        page: 1,
        page_size: 9999,
        search: query || '',
      });
      let currentUsers: string[] = [];
      let filteredUsers: UserInvites[] = [];

      if (response.status === 200) {
        if (playlistUsers && playlistUsers.length) {
          currentUsers = playlistUsers.reduce((acc, user) => {
            acc.push(user.user.id);
            return acc;
          }, []);
          filteredUsers = response.data.results.filter((u) => !currentUsers.includes(u.uaa_id));
        } else {
          currentUsers = playlist.assigned_user_ids || [];
          filteredUsers = response.data.results.filter((u) => !currentUsers.includes(u.uaa_id));
        }

        setUsers(filteredUsers);
      }
    } catch (error) {
      Notification({ text: 'There has been a problem', type: 'error' });
    }
    setFetching(false);
  };

  const handleConfirm = () => {
    const reqData: IAssignUsersParams = {
      id: playlist.id,
      user_ids: usersIds,
      business_account_id: business_account_id,
    };
    handleAssignUsers(reqData);
  };

  const handleAssignUsers = async (data) => {
    setFetching(true);
    try {
      const response = await assignUsers(data);
      if (response.status === 200) {
        Notification({
          text: `You've assigned users to ${playlist.name} playlist`,
          type: 'success',
        });
        if (onConfirm) onConfirm();
        else onCancel();
      } else {
        Notification({ text: 'There has been a problem assigning users', type: 'error' });
      }
    } catch (error) {
      if (error?.response?.data?.details) {
        Notification({ text: error.response.data.details, type: 'error' });
      }
    }
    setFetching(false);
  };

  useEffect(() => {
    getUsers(query);
  }, [query]);

  const onSelectUser = (user) => {
    if (usersIds.includes(user.uaa_data.id)) {
      setUsersIds((prevState) => prevState.filter((id) => id !== user.uaa_data.id));
    } else {
      setUsersIds((prevState) => [...prevState, user.uaa_data.id]);
    }
  };

  return (
    <Modal
      centered
      visible={isVisible}
      fullscreen={isMobile}
      onCancel={onCancel}
      width={isMobile ? '100%' : '595px'}
      footer={null}
      maskClosable={false}
      closeIconColor="#FFF"
      className={'assing-users'}
    >
      <ModalContainer lock={fetching}>
        <HeaderContainer>
          <h2>{title}</h2>
        </HeaderContainer>
        <ModalContentWrapper>
          <ModalSection>
            <Title>{playlist.name}</Title>
            <SubTitle>Assign users to your playlist</SubTitle>
          </ModalSection>
        </ModalContentWrapper>
        <ModalContentWrapper>
          <AddUsersWrapper>
            <ModalSection>
              <SearchFilter onChangeQuery={(query) => setQuery(query)} />
              <UserFilterWrapper>
                {users.map((user) => {
                  return (
                    <UserSuggestionItem
                      key={user.uaa_data.id}
                      {...user}
                      checked={usersIds.includes(user.uaa_data.id)}
                      onCheck={onSelectUser}
                    />
                  );
                })}
              </UserFilterWrapper>
            </ModalSection>
          </AddUsersWrapper>
        </ModalContentWrapper>
        <ModalActions>
          <Button $primary style={{ marginLeft: 'auto' }} onClick={handleConfirm}>
            {ctaLabel}
          </Button>
        </ModalActions>
        <Spinner active={fetching} top={isMobile ? '250px' : '300px'} />
      </ModalContainer>
    </Modal>
  );
};

export default AssignUsers;
