import { isMobile } from 'react-device-detect';
import { AnalyticsContext } from 'contexts/analytics';
import { useContext, useEffect, useMemo, useState } from 'react';
import { format, sub } from 'date-fns';
import { CUSTOM_RANGE, DATE_PERIODS, DATE_PERIODS_MAP, DATE_RANGES } from 'constants/filters';
import Icon from 'components/icons';
import {
  BottomContainer,
  CustomGridWrapper,
  IntervalWrapper,
  Label,
  PillsWrapper,
  RangeDatePicker,
  StyledInput,
  TopContainer,
} from './styles';
import PillButton from './PillButton';
import { default as DateIntervalSelect } from './InputTypeSelect';
import { formatDateInUtc } from 'utils/helpers';
import AnalyticsTeamsFilter from '../AnalyticsTeamsFilter/AnalyticsTeamsFilter';
import { defaultAnalyticsRange } from 'contexts/analytics/utils';

const customLabelDateFormat = 'MMM do yyyy';

const AnalyticsFilters = (): JSX.Element => {
  const { actions, filters } = useContext(AnalyticsContext);
  const [filterValue, setFilterValue] = useState(filters);
  const [isCustom, setCustom] = useState(false);
  const [selectedRange, setSelectedRange] = useState(defaultAnalyticsRange.VALUE);
  const [enabledPeriods, setEnabledPeriods] = useState(
    DATE_PERIODS_MAP[defaultAnalyticsRange.VALUE]
  );

  const handleFilterUpdate = () => {
    if ((isCustom && filterValue.date_from && filterValue.date_to) || !isCustom) {
      actions && actions.onChangeFilters(filterValue);
    }
  };

  const updatePeriods = (range) => {
    setEnabledPeriods(DATE_PERIODS_MAP[range]);
  };

  const updateSelectedRange = (range) => {
    setSelectedRange(range);
    if (range !== CUSTOM_RANGE.VALUE) {
      setCustom(false);
      const newRange = DATE_RANGES.find((OBJ) => OBJ.VALUE === range);
      const dateTo = new Date();
      const dateFrom = sub(dateTo, {
        [newRange.PERIOD]: newRange.VALUE,
      });
      const newPeriod = DATE_PERIODS_MAP[range].includes(filterValue.period)
        ? filterValue.period
        : DATE_PERIODS_MAP[range][0];
      setCustomDates([format(dateFrom, 'yyyy-LL-dd'), format(dateTo, 'yyyy-LL-dd')], newPeriod);
    } else {
      setCustom(true);
      setFilterValue((prev) => ({ ...prev, date_from: null, date_to: null }));
    }
    updatePeriods(range);
  };
  const setSelectedPeriod = (value) => {
    setFilterValue((prev) => ({ ...prev, period: value }));
  };

  const setCustomDates = (dates, newPeriod?) => {
    newPeriod
      ? setFilterValue((prev) => ({
          ...prev,
          date_from: dates[0],
          date_to: dates[1],
          period: newPeriod,
        }))
      : setFilterValue((prev) => ({ ...prev, date_from: dates[0], date_to: dates[1] }));
  };
  const isDateRangeSelected = (value) => {
    return selectedRange === value;
  };

  const customDateMemo = useMemo(() => {
    const { date_from, date_to } = filterValue;

    if (isCustom && date_from && date_to) {
      return `${formatDateInUtc(new Date(date_from), customLabelDateFormat)} - ${formatDateInUtc(
        new Date(date_to),
        customLabelDateFormat
      )}`;
    } else return 'Custom';
  }, [isCustom, filterValue.date_from, filterValue.date_to]);

  useEffect(() => {
    handleFilterUpdate();
  }, [filterValue]);

  return (
    <div className="analytics-filters w-100">
      <TopContainer>
        <PillsWrapper>
          {DATE_RANGES.map(({ LABEL: label, VALUE: value }) => {
            const isSelected = isDateRangeSelected(value);
            return (
              <PillButton
                selected={isSelected}
                label={label}
                key={`range-${value}`}
                onClick={() => updateSelectedRange(value)}
              />
            );
          })}
          {!isMobile && (
            <IntervalWrapper>
              <DateIntervalSelect
                value={filterValue.period}
                options={DATE_PERIODS.map((p) => ({
                  label: p.LABEL,
                  value: p.VALUE,
                  disabled: !enabledPeriods.includes(p.VALUE),
                }))}
                onChange={(v) => setSelectedPeriod(v)}
              />
            </IntervalWrapper>
          )}
        </PillsWrapper>
        <AnalyticsTeamsFilter />
      </TopContainer>
      {isCustom && (
        <BottomContainer>
          <CustomGridWrapper>
            <Label>Date range</Label>
            <StyledInput type={'text'} value={customDateMemo} disabled />
            <RangeDatePicker
              suffixIcon={<Icon icon="Calendar" size="small" />}
              onChange={(date, dates) => setCustomDates(dates)}
            />
          </CustomGridWrapper>
        </BottomContainer>
      )}
    </div>
  );
};

export default AnalyticsFilters;
