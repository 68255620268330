import { Card } from 'antd';
import { colors } from 'constants/styles/colors';
import styled from 'styled-components';

export const AggregationCardContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
`;

export const AggregationCard = styled(Card)`
  border-radius: 16px;
  width: 20%;
  .ant-card-body {
    padding: 10px;
  }
  .stat-container {
    display: flex;
    gap: 5px;
  }
  .stat-quantity {
    font-weight: 700;
    font-size: 54px;
    line-height: 66px;
  }
  .stat-variation-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .stat-variation {
    font-weight: 450;
    font-size: 12px;
    line-height: 14px;
    color: ${colors.palette.Business_LightThemeGreyDarker};
  }
  .stat-footer {
    font-size: 12px;
    color: ${({ theme }) => theme.default.colors.palette.Business_LightThemeGreyDarker};
  }
`;

export const AggregationStatsCardLabel = styled.div`
  margin-top: 1%;
  margin-left: 5%;
  font-size: 12px;
  line-height: 14px;
  font-weight: 450;
  color: ${colors.palette.Business_LightThemeGreyDarker};
`;
