import { Tag } from 'antd';
import { screenMd } from 'constants/styles/breakpoints';
import { colors } from 'constants/styles/colors';
import styled from 'styled-components/macro';

export const Container = styled.div``;

export const InnerContainer = styled.div`
  width: 100%;
  height: calc(100% - 175px);
  position: relative;
  ${screenMd} {
    min-height: auto;
    width: 100%;
  }
`;

export const HeaderContainer = styled.div`
  align-items: center;
  background: linear-gradient(45.99deg, #863ad2 30%, #5936e4 70%);
  display: flex;
  flex-direction: column;
  padding-top: 62px;
  ${screenMd} {
    border-radius: 20px 20px 0 0;
    padding-top: 61px;
    width: 100%;
    background: linear-gradient(45deg, #ef7d22 1%, #863ad2 30%, #190da0),
      linear-gradient(90deg, transparent 1%, #3c1aef 80%);
  }
`;
export const Title = styled.div`
  color: white;
  text-align: center;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 23px;
  font-weight: bold;
  max-width: 90%;
  ${screenMd} {
    font-weight: 400;
    font-size: 24px;
    margin-bottom: 40px;
    line-height: 32px;
  }
`;

export const BodyContainer = styled.div`
  padding: 20px 0 20px 0;
  background: white;
  border-radius: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  ${screenMd} {
    padding: 28px 10px 20px 10px;
    border-radius: 0 0 22px 22px;
    justify-content: space-between;
    padding: 30px;
    min-height: 272px;
  }
`;

export const BodyTitle = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0px;
  margin-bottom: 26px;
  text-align: left;
`;

export const BodySubtitle = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  margin-bottom: 10px;
  text-align: left;
  color: ${({ theme }) => theme.default.colors.palette.Business_LightThemeGreyDarker};
`;

export const LicenseAvailables = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  margin-top: 10px;
  text-align: left;
  margin-bottom: 30px;
  > span {
    color: ${({ theme }) => theme.default.colors.palette.Business_LightThemeGreyDarker};
  }
`;

export const InputTypeSelect = styled.div`
  background: rgb(242, 242, 247);
  border-radius: 10px;
  border-color: #f2f2f7;
  flex-grow: 0;
  min-height: 44px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 3.5px 10px;
  font-size: 12px;
  cursor: pointer;
  flex-wrap: wrap;
  gap: 3px;
  > p {
    margin-right: 10px;
    margin-bottom: 0;
  }
  > div,
  img {
    width: 37px;
    height: 37px;
    margin-right: 10px;
  }
  > svg {
    margin-left: auto;
  }
`;

export const AdByemailContainer = styled.div`
  padding: 0 10px;
  ${screenMd} {
    padding: 0;
  }
`;

export const TeamPill = styled(Tag)<{ bgcolor?: string }>`
  background-color: ${({ bgcolor }) =>
    bgcolor ? colors.dataVisualization[bgcolor].lighter : 'white'};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  border-width: 0;
`;
