import 'react-multi-email/style.css';
import { BodySubtitle, Container } from '../styles';
import Icon from 'components/icons';
import InputMultiEmails from 'components/inputMultiEmails';
import React from 'react';
import Tooltip from 'components/tooltip';

interface IAddEmails {
  emails: string[];
  setEmails: (emails: string[]) => void;
  title: string;
  placeholder?: string;
}

const EmailInput: React.FC<IAddEmails> = ({ emails, setEmails, title, placeholder }) => {
  return (
    <Container>
      <div style={{ display: 'flex' }}>
        <BodySubtitle>{title}</BodySubtitle>
        <Tooltip
          placement="right"
          title="Press the Tab key or click outside the box to add the user."
          zIndex={9999}
        >
          <div>
            <Icon icon="Informational" fill="#57575c" size="small" />
          </div>
        </Tooltip>
      </div>

      <InputMultiEmails emails={emails} setEmails={setEmails} placeholder={placeholder} />
    </Container>
  );
};

export default EmailInput;
