import PlaylistsContent from './PlaylistsContent';
import PlaylistsProvider from 'contexts/playlists';
import styled from 'styled-components';

const PageContent = styled.div`
  position: relative;
  background: '#F2F2F7';
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const Playlists = (): JSX.Element => {
  return (
    <PlaylistsProvider>
      <PageContent>
        <PlaylistsContent />
      </PageContent>
    </PlaylistsProvider>
  );
};

export default Playlists;
