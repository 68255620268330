import { useContext, useState } from 'react';
import { Space } from 'antd';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { formatDistanceToNowStrict } from 'date-fns';
import Checkbox from 'components/checkbox';
import TeamsSelector from 'components/teamSelect';
import { Cell, EmptyCell, Row } from 'components/Table';
import OwnerStarSvg from 'assets/images/ownerStar.png';
import UserSettingsDrawer from './UserSettingsDrawer';

import {
  ActionButtonsContainer,
  OwnerStarImg,
  PendingLetterAvatar,
  PendingUserImage,
  UserDataContainer,
} from './styles';

import Switch from 'components/switch';
import ButtonIcon from 'components/buttonIcon';
import Tooltip from 'components/tooltip';
import { UserInvites } from 'contexts/users/types';
import { Team } from 'contexts/teams/types';
import { SessionContext } from 'contexts/session';
import { DEFAULT_IMG_USERS } from 'constants/urls';
import { randomColor } from 'utils/colors';
import { ACC_OWNER, ROLES } from 'constants/roles';
import { screenMd } from 'constants/styles/breakpoints';
import { USER_PROFILE_AGGREGATION_TABS, USER_PROFILE_AGGRETATION_SERIES } from 'pages/Users/helper';
import { MONTH_PERIOD, WEEK_PERIOD } from 'constants/filters';
import UserRowAnalytics from './UserRowAnalytics';
import { SwitchLicenseActions } from './types';
import { isRoleManager } from './helper';
import { UserTeamContainer } from 'pages/Users/components/InviteUsers/AdjustUsersSettings/styles';
import { UserTeamsPayload } from './types';

export interface UserRowProps extends UserInvites {
  onSelectTeam: (id: string, newTeams: UserTeamsPayload[]) => void;
  onSwitchManager: (user_invite_id: string, teamsData: UserTeamsPayload[]) => void;
  onSwitchLicense: ({ ids, checked }: SwitchLicenseActions) => void;
  onSelectRow: (id: string) => void;
  onCancelInvitation: (ids: string[], name: string[]) => void;
  onResendInvitation: (ids: string[]) => void;
  onRemoveUser: (ids: string[], name: string[], lastName: string[]) => void;
  onSwitchAddon: (assigned_to?: string, addon?: string, license_addon_id?: string) => void;
  rowSelected: boolean;
  active?: boolean;
  accountTeams: Team[] | [];
  role: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  uaa_data: any; // update with uaa data types,
  updated_at: string;
  row: UserInvites;
  tableGridConfig: string;
  showTeamSelectorButton?: boolean;
}

export const CustomRow = styled(Row)<{ collapsed?: boolean }>`
  margin: 5px 10px;
  ${({ collapsed }) => !collapsed && 'border-bottom: none;'}
  ${screenMd} {
    margin: 0;
  }
  cursor: pointer;
`;

export const ContainerDesktop = styled.div`
  display: none;
  width: 100%;
  ${screenMd} {
    display: flex;
  }
`;

export const ContainerMobile = styled.div`
  display: flex;
  ${screenMd} {
    display: none;
  }
`;

const UserRow = ({
  id,
  email,
  last_login,
  role,
  license,
  onCancelInvitation,
  onSelectRow,
  onSelectTeam,
  onSwitchManager,
  onSwitchLicense,
  onResendInvitation,
  onRemoveUser,
  onSwitchAddon,
  rowSelected,
  is_active,
  accountTeams,
  uaa_data,
  user,
  row,
  uaa_id,
  tableGridConfig,
  account,
  invited_at,
  teams,
  showTeamSelectorButton = true,
  account_available_addons,
  license_addons,
}: UserRowProps): JSX.Element => {
  const { licenses_available, user: userSession, allows, userRoleIds } = useContext(SessionContext);
  const { can_delete_user, can_unnasign_license } = allows;
  const [collapsed, setCollapsed] = useState(true);

  const accOwnerLoggedIn = userRoleIds.includes(ROLES.ACCOUNT_OWNER);
  const isItself = uaa_id ? uaa_id === userSession.uaa_id : email === userSession.email;
  const isAccountOwner = uaa_data
    ? uaa_data.roles.data.find((role) => role.name === ACC_OWNER) !== undefined
    : role === ACC_OWNER;
  const isManager = isRoleManager(role, teams);

  const getRoleSwitchStatus = () => {
    const switchStatus = {
      disabled: false,
      tooltip: 'Team Manager role',
    };
    if (isManager && isItself) {
      switchStatus.disabled = true;
      switchStatus.tooltip = `Team manager cannot revoke it's own role`;
    } else if (isAccountOwner || teams.length === 0) {
      switchStatus.disabled = true;
      switchStatus.tooltip = isAccountOwner
        ? `An Account Owner cannot be set as a Team Manager`
        : 'User cannot be set as a Team Manager';
    }
    return switchStatus;
  };
  const roleSwitchStatus = getRoleSwitchStatus();
  const canRemoveUser = email === userSession?.email || isAccountOwner || !can_delete_user;

  const getLicenseSwitchStatus = () => {
    const hasSkillDive = license_addons?.length > 0;
    const switchStatus = {
      disabled: false,
      tooltip: !license
        ? 'Assign License'
        : hasSkillDive
        ? `If the user's plan license is removed, the Skill Dive license will also be removed`
        : 'Remove License',
    };
    if (!license) {
      if (!licenses_available) {
        switchStatus.disabled = true;
        switchStatus.tooltip = `You don't have licenses available`;
      } else if (isAccountOwner) {
        switchStatus.disabled = !isItself;
        switchStatus.tooltip = !isItself
          ? `You cannot change the account owner's license`
          : `Assign License`;
      } else if (isManager) {
        switchStatus.disabled = !accOwnerLoggedIn && !isItself;
        switchStatus.tooltip = !isItself
          ? `You cannot change another team manager's license`
          : `Assign License`;
      }
    } else {
      if (!can_unnasign_license) {
        switchStatus.disabled = true;
        switchStatus.tooltip = `Professional plan accounts don't have permission to Remove licenses. Contact INE to upgrade.`;
      } else if (isAccountOwner) {
        switchStatus.disabled = !isItself;
        switchStatus.tooltip = !isItself
          ? `You cannot change the account owner's license`
          : hasSkillDive
          ? `If the user's plan license is removed, the Skill Dive license will also be removed`
          : 'Remove License';
      } else if (isManager) {
        switchStatus.disabled = !accOwnerLoggedIn && !isItself;
        switchStatus.tooltip = !isItself
          ? `You cannot change another team manager's license`
          : hasSkillDive
          ? `If the user's plan license is removed, the Skill Dive license will also be removed`
          : 'Remove License';
      }
    }
    return switchStatus;
  };

  const isNotManagerFromTeam = (teamId: string): boolean => {
    return accOwnerLoggedIn
      ? false
      : Boolean(!userSession.teams.find((team) => team.team_id === teamId)?.is_manager);
  };

  const licenseSwitchStatus = getLicenseSwitchStatus();

  const loginOrInvitedDate = last_login || invited_at;

  const roleSwitchTooltipWarning = <p className="text-center m-0">{roleSwitchStatus.tooltip}</p>;

  const licenseSwitchTooltipWarning = (
    <p className="text-center m-0">{licenseSwitchStatus.tooltip}</p>
  );

  const getAddonsSwitchStatus = () => {
    const addonsStatusSwitch = {
      disabled: true,
      tooltip: '',
    };
    if (account_available_addons?.length > 0) {
      // If user's account has addons on its plan allow to add them
      if (!license) {
        //If user has no license assigned, check if there are available licenses
        addonsStatusSwitch.disabled = true;
        addonsStatusSwitch.tooltip =
          'User must have a plan license assigned to this user to add Skill Dive';
      } else {
        // If user has a license check if it has addons license assigned
        if (license_addons?.length > 0) {
          // If user has a license addon allow to remove it
          addonsStatusSwitch.disabled = can_unnasign_license ? false : true;
          addonsStatusSwitch.tooltip = can_unnasign_license
            ? 'Remove Skill Dive license'
            : 'Skill Dive licenses are non-transferable after assigning to a user';
        } else if (account_available_addons[0].count > 0) {
          // If user has no license addon, check if there are available addons count
          addonsStatusSwitch.disabled = false;
          addonsStatusSwitch.tooltip = can_unnasign_license
            ? 'Assign Skill Dive license'
            : 'Skill Dive licenses are non-transferable after assigning to a user';
        } else {
          addonsStatusSwitch.disabled = true;
          addonsStatusSwitch.tooltip =
            'Your account has no remaining Skill Dive Licenses available. Contact Sales to add additional licenses';
        }
      }
    } else {
      addonsStatusSwitch.disabled = true;
      addonsStatusSwitch.tooltip =
        'Skill Dive is not enabled for your account, please contact your account manager';
    }
    return addonsStatusSwitch;
  };

  const addonsSwitchStatus = getAddonsSwitchStatus();

  const addonsSwitchTooltipWarning = (
    <p className="text-center m-0">{addonsSwitchStatus.tooltip}</p>
  );

  const getAggregationTabsData = () => {
    const aggregationTabs = [];
    USER_PROFILE_AGGREGATION_TABS.map((at) => {
      aggregationTabs.push({
        name: at.name,
        label: at.label,
        icon: at.icon,
        params: {
          period: MONTH_PERIOD.VALUE,
          user_id: userSession.uaa_id,
          account_id: account,
          actions: at.actions,
          external_action: at.external_action,
          business_user_id: uaa_id,
        },
      });
    });
    return aggregationTabs;
  };

  const getAggregationSeriesData = () => {
    const aggregationSeries = [];
    USER_PROFILE_AGGRETATION_SERIES.map((as) => {
      aggregationSeries.push({
        name: as.name,
        series: as.series.map((s) => ({
          key: s.key,
          color: s.color,
          label: s.label,
          params: {
            period: WEEK_PERIOD.VALUE,
            date_from: null,
            date_to: null,
            user_id: userSession.uaa_id,
            account_id: account,
            actions: s.actions,
            metadata_filter: s.metadata,
            business_user_id: uaa_id,
          },
        })),
      });
    });
    return aggregationSeries;
  };

  const handleTeamsChange = (id: string, teamIds: string[]) => {
    const newTeams = [];
    teamIds.forEach((ti) => {
      const is_manager = teams.find((ut) => ut.team_id === ti)?.is_manager || false;
      newTeams.push({ team_id: ti, is_manager: is_manager });
    });
    onSelectTeam(id, newTeams);
  };

  const handleStatsCollapse = () => {
    is_active && setCollapsed((prevValue) => !prevValue);
  };

  const handleSwitchAddon = () => {
    //TODO change this method to support multiple addons
    if (license_addons.length > 0) {
      onSwitchAddon(null, null, license_addons[0].id);
    } else {
      onSwitchAddon(user, account_available_addons[0].id, null);
    }
  };

  return (
    <>
      <CustomRow columns={tableGridConfig} collapsed={collapsed} onClick={handleStatsCollapse}>
        {/* Checkbox */}
        <Cell>
          <Checkbox
            checked={rowSelected}
            onClick={(e) => e.stopPropagation()}
            onChange={() => onSelectRow(id)}
            controlled
          />
        </Cell>

        {/* User personal info */}
        <Cell>
          <div className="d-flex align-items-center justiy-left position-relative">
            {isAccountOwner && (
              <Tooltip placement="right" title="Account owner">
                <OwnerStarImg src={OwnerStarSvg} />
              </Tooltip>
            )}
            {is_active &&
            uaa_data?.profile?.data?.avatar &&
            uaa_data?.profile?.data?.avatar !== DEFAULT_IMG_USERS ? (
              <NavLink to={`/user/${user}`} onClick={(e) => e.stopPropagation()}>
                <PendingUserImage src={uaa_data?.profile?.data?.avatar} alt={email} />
              </NavLink>
            ) : (
              <PendingLetterAvatar color={randomColor(email)}>
                {email && email[0].toUpperCase()}
              </PendingLetterAvatar>
            )}
            <UserDataContainer>
              {is_active && (
                <NavLink to={`/user/${user}`} onClick={(e) => e.stopPropagation()}>
                  <p>{`${uaa_data?.profile?.data.first_name} ${uaa_data?.profile?.data.last_name}`}</p>
                  <p>{email}</p>
                </NavLink>
              )}
              {!is_active && <p>{email}</p>}
              <ContainerMobile>
                {Boolean(license) && <span className="badge badge-light">LICENSE</span>}
              </ContainerMobile>
            </UserDataContainer>
          </div>
        </Cell>
        {!isMobile && (
          <Cell onClick={(e) => e.stopPropagation()}>
            <TeamsSelector
              accountTeams={accountTeams}
              userTeams={teams}
              onTeamChange={(teamIds) => handleTeamsChange(id, teamIds)}
              withEditBtn={showTeamSelectorButton}
            />
            <div style={{ width: '100%' }}>
              {teams.map((team, index) => (
                <UserTeamContainer
                  key={team.team_id}
                  borderBottom={index < teams.length - 1}
                  width="85"
                >
                  <div>{team.name}</div>
                  <Tooltip
                    placement="bottomLeft"
                    destroyTooltipOnHide
                    title={
                      isNotManagerFromTeam(team.team_id)
                        ? `You can only edit users' roles if they are on your team.`
                        : roleSwitchTooltipWarning
                    }
                  >
                    <span style={{ cursor: roleSwitchStatus.disabled ? 'not-allowed' : 'auto' }}>
                      <Switch
                        defaultChecked={team.is_manager}
                        disabled={roleSwitchStatus.disabled || isNotManagerFromTeam(team.team_id)}
                        onChange={(checked) =>
                          onSwitchManager(id, [{ team_id: team.team_id, is_manager: checked }])
                        }
                      />
                    </span>
                  </Tooltip>
                </UserTeamContainer>
              ))}
              {teams.length === 0 && <span className="text-truncate">Unassigned teams</span>}
            </div>
          </Cell>
        )}

        {/*  License switch  */}
        <ContainerDesktop>
          <Cell>
            <Tooltip placement="bottom" destroyTooltipOnHide title={licenseSwitchTooltipWarning}>
              <span
                style={{ cursor: licenseSwitchStatus.disabled ? 'not-allowed' : 'auto' }}
                onClick={(e) => e.stopPropagation()}
              >
                <Switch
                  defaultChecked={Boolean(license)}
                  style={{ pointerEvents: licenseSwitchStatus.disabled ? 'none' : 'auto' }}
                  disabled={licenseSwitchStatus.disabled}
                  onChange={(checked: boolean) => onSwitchLicense({ ids: [id], checked })}
                />
              </span>
            </Tooltip>
          </Cell>
        </ContainerDesktop>

        {/* Addons License switch */}
        {is_active && (
          <ContainerDesktop>
            <Cell>
              <Tooltip placement="bottom" destroyTooltipOnHide title={addonsSwitchTooltipWarning}>
                <span
                  style={{ cursor: addonsSwitchStatus.disabled ? 'not-allowed' : 'auto' }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Switch
                    defaultChecked={Boolean(license_addons?.length > 0)}
                    checked={Boolean(license_addons?.length > 0)}
                    style={{ pointerEvents: addonsSwitchStatus.disabled ? 'none' : 'auto' }}
                    disabled={addonsSwitchStatus.disabled}
                    onChange={handleSwitchAddon}
                  />
                </span>
              </Tooltip>
            </Cell>
          </ContainerDesktop>
        )}

        {/*  Last active */}
        <ContainerDesktop>
          <Cell>
            <p className="mb-0 text-left" style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
              {loginOrInvitedDate ? (
                formatDistanceToNowStrict(new Date(loginOrInvitedDate), { addSuffix: true })
              ) : (
                <EmptyCell />
              )}
            </p>
          </Cell>
        </ContainerDesktop>
        {/* Actions on users */}
        <Cell>
          <ActionButtonsContainer>
            <Space>
              {is_active && (
                <>
                  <ContainerDesktop>
                    <Tooltip
                      placement="bottomRight"
                      title={
                        !can_delete_user
                          ? 'Your account does not allow to remove users'
                          : 'Remove user'
                      }
                      destroyTooltipOnHide
                    >
                      <div
                        style={{ cursor: canRemoveUser ? 'not-allowed' : 'pointer' }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <ButtonIcon
                          $secondary
                          size="small"
                          icon="Trash"
                          style={{ pointerEvents: canRemoveUser ? 'none' : 'all' }}
                          disabled={
                            email === userSession?.email || isAccountOwner || !can_delete_user
                          }
                          onClick={() =>
                            onRemoveUser(
                              [id],
                              [uaa_data?.profile?.data.first_name || email],
                              [uaa_data?.profile?.data.last_name || '']
                            )
                          }
                        />
                      </div>
                    </Tooltip>
                  </ContainerDesktop>
                  <ContainerDesktop>
                    <ButtonIcon
                      $terciary
                      size="small"
                      icon={collapsed ? 'ChevronUp' : 'ChevronDown'}
                    />
                  </ContainerDesktop>
                </>
              )}
              {!is_active && (
                <>
                  <ContainerDesktop>
                    <Tooltip placement="bottomRight" title="Resend invitation">
                      <ButtonIcon
                        $secondary
                        size="small"
                        icon="Resend"
                        onClick={() => onResendInvitation([id])}
                      />
                    </Tooltip>
                  </ContainerDesktop>
                  <ContainerDesktop>
                    <Tooltip placement="bottomRight" title="Cancel invitation">
                      <ButtonIcon
                        $secondary
                        size="small"
                        icon="Cancel"
                        disabled={isAccountOwner}
                        onClick={() => onCancelInvitation([id], [email])}
                      />
                    </Tooltip>
                  </ContainerDesktop>
                </>
              )}
              <ContainerMobile>
                <UserSettingsDrawer
                  data={row}
                  teams={accountTeams}
                  disableManager={roleSwitchStatus.disabled}
                  disableLicense={licenseSwitchStatus.disabled}
                  onSwitchManager={onSwitchManager}
                  onSwitchLicense={onSwitchLicense}
                  onRemoveUser={onRemoveUser}
                  onSelectTeam={handleTeamsChange}
                  onCancelInvite={() => onCancelInvitation([id], [email])}
                  onResendInvite={() => onResendInvitation([id])}
                  showTeamSelectorButton={showTeamSelectorButton}
                />
              </ContainerMobile>
            </Space>
          </ActionButtonsContainer>
        </Cell>
      </CustomRow>
      {!collapsed && (
        <UserRowAnalytics
          aggregationTabs={getAggregationTabsData()}
          aggregationSeries={getAggregationSeriesData()}
        />
      )}
    </>
  );
};

export default UserRow;
