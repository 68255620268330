import { useContext } from 'react';
import { BrowserView } from 'react-device-detect';
import { UsersContext } from 'contexts/users';
import { SessionContext } from 'contexts/session';
import { validateRole } from 'contexts/session/utilities';
import UsersAsideFiltersActive from './Active';
import UsersAsideFiltersLicense from './License';
import UsersAsideFiltersRoles from './Roles';
import UsersAsideFiltersTeams from './Teams';
import UsersAsideFiltersAccounts from './Accounts';
import UsersAsideFiltersAddons from './UsersAsideFiltersAddons';
import { FilterChangeEvents } from './types';
import Button from 'components/button';

const UsersAsideFilters = ({ autoApply }: { autoApply: boolean }): JSX.Element => {
  const { filters, actions } = useContext(UsersContext);
  const { user } = useContext(SessionContext);
  const active = filters.active === 'true';

  const handleFilterChange = (e: FilterChangeEvents) => {
    const { name, value } = e.target;
    const filterConcat =
      ['roles', 'team', 'license', 'license_addons', 'no_license_addons'].includes(name) &&
      value !== 'all_teams';

    actions.onChangeFilters({ name, value }, filterConcat, autoApply);
  };

  const props = {
    active,
    autoApply,
    onFilterChange: handleFilterChange,
  };

  const showAccountsManagement =
    user &&
    validateRole({
      roles: user?.uaa_data.roles.data.map(({ id }) => id),
      email: user?.email,
    });
  return (
    <>
      <div className="users-aside-filters">
        <UsersAsideFiltersActive
          onFilterChange={handleFilterChange}
          {...props}
          filter={filters.active}
        />
        {showAccountsManagement && (
          <UsersAsideFiltersAccounts
            onFilterChange={handleFilterChange}
            {...props}
            filter={filters.accounts}
          />
        )}
        <UsersAsideFiltersLicense
          onFilterChange={handleFilterChange}
          {...props}
          filter={filters.license}
          isActiveUsers={filters.active}
        />
        <UsersAsideFiltersAddons
          onFilterChange={handleFilterChange}
          license_addons={filters.license_addons}
          no_license_addons={filters.no_license_addons}
          isActiveUsers={filters.active}
          {...props}
        />
        {!showAccountsManagement && (
          <UsersAsideFiltersTeams
            onFilterChange={handleFilterChange}
            {...props}
            filter={filters.team}
          />
        )}
        <UsersAsideFiltersRoles
          onFilterChange={handleFilterChange}
          {...props}
          filter={filters.roles}
        />
      </div>
      <BrowserView>
        <Button $terciary onClick={actions?.onClearSearch} style={{ fontWeight: 450 }}>
          Clear Filters
        </Button>
      </BrowserView>
    </>
  );
};

export default UsersAsideFilters;
