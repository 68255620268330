/* eslint-disable @typescript-eslint/no-var-requires */
import { useContext, useEffect, useRef, useState } from 'react';
import { Radio } from 'antd';
import Icon from 'components/icons';
import { AccountsContext } from 'contexts/accounts';
import {
  generateCloseToExpirationValues,
  getAccountStatusCount,
  getPricingCount,
  isAllPricing,
  isCloseToExpiration,
} from '../AccountsFilters/Filters/utils';
import './StatsCardGroup.scss';
import { StatCardContainer } from './styles';
const { theme } = require('constants/styles/theme');

interface StatCard {
  label: string;
  value: number;
  percentage: number;
  statUp?: boolean;
  checked?: boolean;
  filter?: {
    name: string;
    value: string;
  };
  onFilterChange?: (filterData: StatCard['filter']) => void;
}

const StatCard = ({ label, value, percentage, checked, filter, onFilterChange }: StatCard) => {
  const ref = useRef();
  const [loaded, setLoaded] = useState(false);

  function animateValue({ obj, start, end }) {
    if (obj && !loaded && end > 0) {
      let startTimestamp = null;
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / 1200, 1);
        obj.innerHTML = Math.floor(progress * (end - start) + start);
        if (progress < 1) {
          window.requestAnimationFrame(step);
        }
      };
      window.requestAnimationFrame(step);
      setLoaded(true);
    }
  }

  return (
    <StatCardContainer
      className="col-2 card card-body"
      onClick={() => onFilterChange({ name: filter.name, value: filter.value })}
    >
      <div className="d-flex align-items-center">
        <p ref={ref} className="stat-text fw-bolder mb-0">
          {' '}
          {animateValue({ obj: ref.current, start: 0, end: value })} {0}{' '}
        </p>
        {percentage && (
          <div className={'ms-md-4'}>
            {percentage > 0 ? (
              <Icon icon="ArrowUp" color={theme.default.colors.performance.positive} />
            ) : (
              <Icon icon="ArrowDown" color={theme.default.colors.performance.negative} />
            )}
            <p className="d-none d-md-block mb-0">{percentage}%</p>
          </div>
        )}
      </div>
      <p className="label-user mb-0 text-muted">
        {filter && (
          <Radio
            checked={checked}
            name={filter?.name}
            value={filter?.value}
            onClick={() => onFilterChange({ name: filter.name, value: filter.value })}
          />
        )}
        {label}
      </p>
    </StatCardContainer>
  );
};

const StatsCardGroup = (): JSX.Element => {
  const { filters, counts, actions } = useContext(AccountsContext);
  const [allPricing, setAllPricing] = useState(isAllPricing({ plan: filters.pricing_plan }));
  const [closeToExpiration, setCloseToExpiration] = useState(
    isCloseToExpiration(filters.end_date_from, filters.end_date_to)
  );

  useEffect(() => {
    const isAll = isAllPricing({ plan: filters.pricing_plan });
    setAllPricing(isAll);
  }, [filters.pricing_plan]);

  useEffect(() => {
    setCloseToExpiration(isCloseToExpiration(filters.end_date_from, filters.end_date_to));
  }, [filters.end_date_from, filters.end_date_to]);

  const handleFilterChange = ({ name, value }) => {
    if (name === 'pricing_plan') {
      const isNotAll = value !== 'pricing_all';
      const isMultipleValue = ['pricing_plan'].includes(name) && isNotAll;
      actions.onChangeFilters({
        name: name,
        value: value,
        multiple: isMultipleValue,
      });
    } else if (name === 'close_to_expiration') {
      let from = '';
      let to = '';
      if (!closeToExpiration) {
        [from, to] = generateCloseToExpirationValues();
      }
      actions.onChangeFilters({
        name: 'end_date_from',
        value: from,
      });
      actions.onChangeFilters({
        name: 'end_date_to',
        value: to,
      });
    } else if (name === 'status') {
      actions.onChangeFilters({
        name: name,
        value: value.toString(),
        multiple: true,
      });
    } else {
      actions.onChangeFilters({
        name: name,
        value: value.toString(),
      });
    }
    actions.clearSelectedItems();
  };

  return (
    <div className="container stats-card-group pb-4">
      <div className="row justify-content-center gap-2 gap-md-4 py-4">
        <StatCard
          label={'Total accounts'}
          value={getPricingCount('all', counts)}
          percentage={null}
          checked={allPricing}
          filter={{ name: 'pricing_plan', value: 'pricing_all' }}
          onFilterChange={handleFilterChange}
        />
        <StatCard
          label={'Past Due'}
          value={getAccountStatusCount('past_due', counts)}
          percentage={null}
          checked={filters.status.includes('past_due')}
          filter={{ name: 'status', value: 'past_due' }}
          onFilterChange={handleFilterChange}
        />
        <StatCard
          label={'Professional plans'}
          value={getPricingCount('Professional', counts)}
          percentage={null}
          checked={filters.pricing_plan.includes('professional')}
          filter={{ name: 'pricing_plan', value: 'professional' }}
          onFilterChange={handleFilterChange}
        />
        <StatCard
          label={'Enterprise plans'}
          value={getPricingCount('Enterprise', counts)}
          percentage={null}
          checked={filters.pricing_plan.includes('enterprise')}
          filter={{ name: 'pricing_plan', value: 'enterprise' }}
          onFilterChange={handleFilterChange}
        />
        <StatCard
          label={'Unlimited accounts'}
          value={counts?.license_count?.unlimited}
          percentage={null}
          checked={filters.is_license_unlimited === '1'}
          filter={{ name: 'is_license_unlimited', value: '1' }}
          onFilterChange={handleFilterChange}
        />
        <StatCard
          label={'Free accounts'}
          value={counts?.free_count}
          percentage={null}
          checked={filters.is_free_trial === 'true'}
          filter={{ name: 'is_free_trial', value: 'true' }}
          onFilterChange={handleFilterChange}
        />
        <StatCard
          label={'Expires in 30 days'}
          value={counts?.close_to_expiration_count}
          percentage={null}
          checked={closeToExpiration}
          filter={{ name: 'close_to_expiration', value: 'true' }}
          onFilterChange={handleFilterChange}
        />
      </div>
      <br />
    </div>
  );
};

export default StatsCardGroup;
