import {
  ACCOUNT,
  COURSES,
  TEAM_ACTIVITY,
  USER_ACTIVITY,
  coursesColumnName,
  skillDiveColumnName,
  skillSonarColumnName,
  teamColumnName,
  usersColumnName,
} from 'constants/reporting';

export const dateFilterOptions = [
  {
    value: 30,
    label: 'last 30 days',
  },
  {
    value: 90,
    label: 'last 90 days',
  },
  {
    value: 365,
    label: 'last year',
  },
  {
    value: 'custom',
    label: 'custom',
  },
];

interface FilterProps {
  name: string;
  label: string;
  default: boolean;
  showTo: Array<string>;
  mandatory: boolean;
  tooltip?: Record<string, string>;
}

interface FilterGroupProps {
  name: string;
  showTo: Array<string>;
  columnDisabledTooltip?: string;
  filters: Array<FilterProps>;
}

const defaultFilters = [
  {
    name: usersColumnName,
    showTo: [ACCOUNT, USER_ACTIVITY, COURSES],
    filters: [
      {
        name: 'user_name',
        label: 'User name',
        default: true,
        showTo: [USER_ACTIVITY, COURSES],
        mandatory: true,
        tooltip: {
          [USER_ACTIVITY]: 'Full name of the user associated with this account',
          [COURSES]: 'Full name of the user associated with this account',
        },
      },
      {
        name: 'user_email',
        label: 'User email address',
        default: true,
        showTo: [USER_ACTIVITY, COURSES],
        mandatory: true,
        tooltip: {
          [USER_ACTIVITY]: 'Email address linked to this account',
          [COURSES]: 'Email addresses of team members with any course activity',
        },
      },
      {
        name: 'user_team_name',
        label: 'Team(s) name(s)',
        default: true,
        showTo: [USER_ACTIVITY],
        mandatory: true,
        tooltip: { [USER_ACTIVITY]: 'Teams this user is a part of' },
      },
      {
        name: 'user_has_license',
        label: 'Has plan license',
        default: false,
        showTo: [USER_ACTIVITY],
        mandatory: false,
        tooltip: { [USER_ACTIVITY]: 'Whether the user has a plan license assigned' },
      },
      {
        name: 'has_sd_license',
        label: 'Has Skill Dive license',
        default: false,
        showTo: [USER_ACTIVITY],
        mandatory: false,
        tooltip: { [USER_ACTIVITY]: 'Whether the user has a Skill Dive license assigned' },
      },
      {
        name: 'user_last_activity',
        label: 'Last activity date',
        default: false,
        showTo: [USER_ACTIVITY],
        mandatory: false,
        tooltip: { [USER_ACTIVITY]: "User's latest interaction with the platform (UTC)" },
      },
      {
        name: 'user_last_login_date',
        label: 'Last login date',
        default: false,
        showTo: [USER_ACTIVITY],
        mandatory: false,
        tooltip: { [USER_ACTIVITY]: "User's most recent login (UTC)" },
      },
      {
        name: 'user_invited_at',
        label: 'Date invited',
        default: false,
        showTo: [USER_ACTIVITY],
        mandatory: false,
        tooltip: { [USER_ACTIVITY]: 'Date the user was invited to join the account (UTC)' },
      },
      {
        name: 'active_users',
        label: 'Users with activity',
        default: false,
        showTo: [ACCOUNT],
        mandatory: false,
        tooltip: { [ACCOUNT]: 'Number of users within the account' },
      },
      {
        name: 'pending_users',
        label: 'Pending users',
        default: false,
        showTo: [ACCOUNT],
        mandatory: false,
        tooltip: {
          [ACCOUNT]:
            'Number of users who have not yet accepted their invitations and are pending activation',
        },
      },
    ],
  },
  {
    name: teamColumnName,
    showTo: [TEAM_ACTIVITY],
    filters: [
      {
        name: 'team_name',
        label: 'Team(s) name(s)',
        default: true,
        mandatory: true,
        showTo: [TEAM_ACTIVITY],
        tooltip: {
          [TEAM_ACTIVITY]: 'Assigned name for the team',
        },
      },
      {
        name: 'team_total_users',
        label: 'Total users',
        default: true,
        mandatory: true,
        showTo: [TEAM_ACTIVITY],
        tooltip: {
          [TEAM_ACTIVITY]: 'Total number of users on the team',
        },
      },
      {
        name: 'team_user_emails',
        label: 'User email address',
        default: true,
        mandatory: true,
        showTo: [TEAM_ACTIVITY],
        tooltip: {
          [TEAM_ACTIVITY]: 'Email addresses of team members',
        },
      },
      {
        name: 'team_manager_email',
        label: 'Team manager(s)',
        default: true,
        mandatory: true,
        showTo: [TEAM_ACTIVITY],
        tooltip: {
          [TEAM_ACTIVITY]: 'Email addresses of team manager(s)',
        },
      },
      {
        name: 'activities_last_date',
        label: 'Last activity date',
        default: false,
        showTo: [TEAM_ACTIVITY],
        mandatory: false,
        tooltip: {
          [TEAM_ACTIVITY]: 'The last date a team member was active in the platform (UTC)',
        },
      },
      {
        name: 'activities_active_users',
        label: 'Users with activity',
        default: true,
        showTo: [TEAM_ACTIVITY],
        mandatory: true,
        tooltip: {
          [TEAM_ACTIVITY]:
            'Total number of team members currently engaged in accessing content from my.ine',
        },
      },
    ],
  },
  {
    name: skillDiveColumnName,
    columnDisabledTooltip:
      'Skill Dive is not enabled for your account; please contact your account manager to add this feature',
    showTo: [USER_ACTIVITY, TEAM_ACTIVITY],
    filters: [
      {
        name: 'sd_active_users',
        label: 'Users with activity',
        default: false,
        showTo: [TEAM_ACTIVITY],
        mandatory: false,
        tooltip: {
          [TEAM_ACTIVITY]: 'Total number of team members currently accessing Skill Dive content',
        },
      },
      {
        name: 'sd_total_active_time_labs',
        label: 'Total active time',
        default: false,
        showTo: [USER_ACTIVITY, TEAM_ACTIVITY],
        mandatory: false,
        tooltip: {
          [USER_ACTIVITY]: 'Total time spent in Skill Dive labs, expressed in hh:mm:ss',
          [TEAM_ACTIVITY]: 'Total time spent by the team in Skill Dive labs, expressed in hh:mm:ss',
        },
      },
      {
        name: 'sd_labs_breakdown',
        label: 'Labs breakdown',
        default: false,
        showTo: [TEAM_ACTIVITY],
        mandatory: false,
        tooltip: {
          [TEAM_ACTIVITY]:
            'List of labs initiated by the team, including details on learning area and difficulty level',
        },
      },
      {
        name: 'sd_active_per_la',
        label: 'Active time per lab',
        default: false,
        showTo: [USER_ACTIVITY],
        mandatory: false,
        tooltip: {
          [USER_ACTIVITY]: 'Time spent in each Skill Dive lab, expressed in hh:mm:ss',
        },
      },
      {
        name: 'sd_lab_status',
        label: 'Lab status',
        default: false,
        showTo: [USER_ACTIVITY],
        mandatory: false,
        tooltip: {
          [USER_ACTIVITY]: 'Status of the Skill Dive lab, whether started or marked as finished',
        },
      },
      {
        name: 'usage_per_la',
        label: 'Usage per learning area (%)',
        default: false,
        showTo: [USER_ACTIVITY, TEAM_ACTIVITY],
        mandatory: false,
        tooltip: {
          [USER_ACTIVITY]:
            'Percentage breakdown of usage across learning areas (Cybersecurity, Networking, and Cloud)',
          [TEAM_ACTIVITY]:
            'Percentage breakdown of usage across learning areas (Cybersecurity, Networking, and Cloud) averaged for the team',
        },
      },
      {
        name: 'usage_per_difficulty',
        label: 'Usage per difficulty (%)',
        default: false,
        showTo: [USER_ACTIVITY, TEAM_ACTIVITY],
        mandatory: false,
        tooltip: {
          [USER_ACTIVITY]:
            'Percentage breakdown of usage by difficulty levels (Novice, Professional, Advanced, and Expert)',
          [TEAM_ACTIVITY]:
            'Percentage breakdown of usage by difficulty levels (Novice, Professional, Advanced, and Expert) averaged for the team',
        },
      },
    ],
  },
  {
    name: skillSonarColumnName,
    columnDisabledTooltip:
      'Skill Sonar is only available for Enterprise plans; please contact your account owner for more information on this offering',
    showTo: [USER_ACTIVITY, TEAM_ACTIVITY],
    filters: [
      {
        name: 'sonar_active_users',
        label: 'Users with activity',
        default: false,
        showTo: [TEAM_ACTIVITY],
        mandatory: false,
        tooltip: {
          [TEAM_ACTIVITY]: 'Number of team members currently accessing a Skill Sonar assessment',
        },
      },
      {
        name: 'total_attempts',
        label: 'Total attempts',
        default: false,
        showTo: [USER_ACTIVITY, TEAM_ACTIVITY],
        mandatory: false,
        tooltip: {
          [USER_ACTIVITY]: 'Number of assessment attempts made by the user',
          [TEAM_ACTIVITY]: 'Number of assessment attempts made by the team',
        },
      },
      {
        name: 'attempts_status',
        label: 'Attempts status',
        default: false,
        showTo: [USER_ACTIVITY],
        mandatory: false,
        tooltip: {
          [USER_ACTIVITY]: 'Number of passed, failed, and cancelled assessment attempts',
          [TEAM_ACTIVITY]: 'Number of passed, failed, and cancelled assessment attempts',
        },
      },
      {
        name: 'sonar_submitted_assessmentes',
        label: 'Most submitted assessments',
        default: false,
        showTo: [TEAM_ACTIVITY],
        mandatory: false,
        tooltip: {
          [TEAM_ACTIVITY]: 'Top 5 most submitted assessments within the team',
        },
      },
    ],
  },
  {
    name: 'Teams',
    showTo: [ACCOUNT],
    filters: [
      {
        name: 'teams_names',
        label: 'Team(s) name(s)',
        default: true,
        mandatory: true,
        showTo: [ACCOUNT],
        tooltip: {
          [ACCOUNT]: 'List of teams created in the account',
        },
      },
      {
        name: 'team_managers',
        label: 'Team manager(s)',
        default: true,
        mandatory: true,
        showTo: [ACCOUNT],
        tooltip: {
          [ACCOUNT]: 'Email addresses of team manager(s)',
        },
      },
    ],
  },
  {
    name: 'Plan Licenses',
    showTo: [ACCOUNT],
    filters: [
      {
        name: 'total_licenses',
        label: 'Total licenses',
        default: true,
        mandatory: true,
        showTo: [ACCOUNT],
        tooltip: {
          [ACCOUNT]: 'Total number of licenses an account has purchased',
        },
      },
      {
        name: 'active_licenses',
        label: 'Assigned licenses',
        default: true,
        mandatory: true,
        showTo: [ACCOUNT],
        tooltip: {
          [ACCOUNT]: 'Total number of assigned licenses',
        },
      },
      {
        name: 'available_licenses',
        label: 'Available licenses',
        default: true,
        mandatory: true,
        showTo: [ACCOUNT],
        tooltip: {
          [ACCOUNT]: 'Total number of unassigned licenses',
        },
      },
    ],
  },
  {
    name: 'Skill Dive Licenses',
    showTo: [ACCOUNT],
    columnDisabledTooltip:
      'Skill Dive is only available for Enterprise plans; please contact your account owner for more information on this offering',
    filters: [
      {
        name: 'sd_total_licenses',
        label: 'Total licenses',
        default: true,
        mandatory: true,
        showTo: [ACCOUNT],
        tooltip: {
          [ACCOUNT]: 'Total number of Skill Dive licenses an account has purchased',
        },
      },
      {
        name: 'sd_active_licenses',
        label: 'Assigned licenses',
        default: true,
        mandatory: true,
        showTo: [ACCOUNT],
        tooltip: {
          [ACCOUNT]: 'Total number of assigned Skill Dive licenses',
        },
      },
      {
        name: 'sd_available_licenses',
        label: 'Available licenses',
        default: true,
        mandatory: true,
        showTo: [ACCOUNT],
        tooltip: {
          [ACCOUNT]: 'Total number of unassigned Skill Dive licenses',
        },
      },
    ],
  },
  {
    name: 'Overall usage',
    showTo: [ACCOUNT],
    filters: [
      {
        name: 'usage_active_users',
        label: 'Users with activity',
        default: true,
        mandatory: true,
        showTo: [ACCOUNT],
        tooltip: {
          [ACCOUNT]:
            'Number of users who have performed at least one activity per day within the chosen timeframe',
        },
      },
      {
        name: 'overall_activities',
        label: 'Overall activities',
        default: true,
        mandatory: true,
        showTo: [ACCOUNT],
        tooltip: {
          [ACCOUNT]: 'Total number of initiated activities within the account',
        },
      },
      {
        name: 'finished_activities',
        label: 'Finished activities',
        default: true,
        mandatory: true,
        showTo: [ACCOUNT],
        tooltip: {
          [ACCOUNT]: 'Number of finished activities within the account',
        },
      },
    ],
  },
  {
    name: skillDiveColumnName,
    columnDisabledTooltip:
      'Skill Dive is only available for Enterprise plans; please contact your account owner for more information on this offering',
    showTo: [ACCOUNT],
    filters: [
      {
        name: 'sd_active_on_labs',
        label: 'Active time on labs',
        default: false,
        showTo: [ACCOUNT],
        mandatory: false,
        tooltip: {
          [ACCOUNT]: 'Time spent by the account members in Skill Dive labs, expressed in hh:mm:ss',
        },
      },
      {
        name: 'sd_active_users',
        label: 'Users with activity (#)',
        default: false,
        showTo: [ACCOUNT],
        mandatory: false,
        tooltip: {
          [ACCOUNT]: 'Number of users currently engaged in accessing content from Skill Dive',
        },
      },
      {
        name: 'sd_active_users_percentage',
        label: 'Users with activity (%)',
        default: false,
        showTo: [ACCOUNT],
        mandatory: false,
        tooltip: {
          [ACCOUNT]:
            'Percentage of users with Skill Dive license currently engaged in accessing content from Skill Dive',
        },
      },
    ],
  },
  {
    name: skillSonarColumnName,
    showTo: [ACCOUNT],
    columnDisabledTooltip:
      'Skill Sonar is only available for Enterprise plans; please contact your account owner for more information on this offering',
    filters: [
      {
        name: 'sonar_active_users',
        label: 'Users with activity (#)',
        default: false,
        showTo: [ACCOUNT],
        mandatory: false,
        tooltip: { [ACCOUNT]: 'Number of users currently accessing to Skill Sonar assessment' },
      },
      {
        name: 'sonar_active_users_percentage',
        label: 'Users with activity (%)',
        default: false,
        showTo: [ACCOUNT],
        mandatory: false,
        tooltip: {
          [ACCOUNT]: 'Percentage of users currently engaged in accessing content from Skill Sonar',
        },
      },
    ],
  },
  {
    name: 'Playlists',
    showTo: [USER_ACTIVITY, ACCOUNT],
    filters: [
      {
        name: 'assinged_playlists',
        label: 'Assigned playlists',
        default: false,
        showTo: [USER_ACTIVITY],
        mandatory: false,
        tooltip: { [USER_ACTIVITY]: "User's playlists assignments" },
      },
      {
        name: 'playlists_created',
        label: 'Created playlists',
        default: false,
        showTo: [ACCOUNT],
        mandatory: false,
        tooltip: { [ACCOUNT]: 'List of playlists created in the account' },
      },
      {
        name: 'playlists_overall_completion',
        label: 'Overall completion (%)',
        default: false,
        showTo: [USER_ACTIVITY, ACCOUNT],
        mandatory: false,
        tooltip: {
          [USER_ACTIVITY]: 'Percentage of completed playlist content',
          [ACCOUNT]: 'Percentage of completion for each playlist',
        },
      },
      {
        name: 'playlists_content_progress',
        label: 'Content-specific progress (%)',
        default: false,
        showTo: [USER_ACTIVITY],
        mandatory: false,
        tooltip: {
          [USER_ACTIVITY]:
            "Percentage breakdown of completion for each playlist's content, including details on content learning area and difficulty level",
        },
      },
    ],
  },
  {
    name: 'Learning paths',
    showTo: [USER_ACTIVITY, TEAM_ACTIVITY],
    filters: [
      {
        name: 'lp_completion',
        label: 'Overall completion (%)',
        default: false,
        showTo: [USER_ACTIVITY, TEAM_ACTIVITY],
        mandatory: false,
        tooltip: {
          [USER_ACTIVITY]: 'Percentage of overall learning path completion',
          [TEAM_ACTIVITY]: 'Percentage of overall learning path completion',
        },
      },
      {
        name: 'lp_content_progress',
        label: 'Content-specific progress (%)',
        default: false,
        showTo: [USER_ACTIVITY],
        mandatory: false,
        tooltip: {
          [USER_ACTIVITY]:
            'Percentage breakdown of completion for each element in a learning path (labs, quizzes, etc.), broken down by content type',
        },
      },
    ],
  },
  {
    name: coursesColumnName,
    showTo: [TEAM_ACTIVITY, COURSES, USER_ACTIVITY],
    filters: [
      {
        name: 'course_completion',
        label: 'Overall completion (%)',
        default: false,
        mandatory: false,
        showTo: [USER_ACTIVITY],
        tooltip: {
          [USER_ACTIVITY]: 'Percentage of overall completion for the entire course content',
        },
      },
      {
        name: 'courses_unique_active',
        label: 'Active courses',
        default: true,
        mandatory: false,
        showTo: [TEAM_ACTIVITY],
        tooltip: {
          [TEAM_ACTIVITY]: 'List of courses initiated by team members',
        },
      },
      {
        name: 'courses_average_completion',
        label: 'Average course completion',
        default: true,
        mandatory: false,
        showTo: [TEAM_ACTIVITY],
        tooltip: {
          [TEAM_ACTIVITY]: 'Percentage of overall completion for started courses by team members',
        },
      },
      {
        name: 'courses_title',
        label: 'Course title',
        default: true,
        mandatory: false,
        showTo: [COURSES],
        tooltip: {
          [COURSES]: 'Name of the course',
        },
      },
      {
        name: 'courses_learning_area',
        label: 'Learning area',
        default: false,
        mandatory: false,
        showTo: [COURSES, USER_ACTIVITY],
        tooltip: {
          [USER_ACTIVITY]:
            'Primary learning area covered by the course (Networking, Cloud, or Cyber Security)',
          [COURSES]:
            'Primary learning area affiliated with the course (Networking, Cloud, or Cyber Security)',
        },
      },
      {
        name: 'courses_difficulty',
        label: 'Difficulty',
        default: false,
        mandatory: false,
        showTo: [USER_ACTIVITY],
        tooltip: {
          [USER_ACTIVITY]:
            'Difficulty associated with the course content (Novice, Professional, Advanced, or Expert)',
        },
      },
      {
        name: 'courses_difficulty',
        label: 'Difficulty level',
        default: false,
        mandatory: false,
        showTo: [COURSES],
        tooltip: {
          [COURSES]:
            'Difficulty associated with the course content (Novice, Professional, Advanced, or Expert)',
        },
      },
      {
        name: 'courses_duration',
        label: 'Course Duration',
        default: false,
        mandatory: false,
        showTo: [COURSES],
        tooltip: { [COURSES]: 'Duration of course content, expressed in hh:mm:ss' },
      },
      {
        name: 'courses_percentage_completed',
        label: 'Completed courses (%)',
        default: false,
        mandatory: false,
        showTo: [COURSES],
        tooltip: {
          [COURSES]: 'Percentage of courses completed out of started courses by the account users',
        },
      },
    ],
  },
  {
    name: 'Hands-on Labs',
    showTo: [TEAM_ACTIVITY, USER_ACTIVITY],
    filters: [
      {
        name: 'user_time_spent_in_labs',
        label: 'Active time on labs',
        default: false,
        mandatory: false,
        showTo: [TEAM_ACTIVITY, USER_ACTIVITY],
        tooltip: {
          [USER_ACTIVITY]: 'Total time spent in standard hands-on labs, expressed in hh:mm:ss',
          [TEAM_ACTIVITY]: 'Total time spent in standard hands-on labs, expressed in hh:mm:ss',
        },
      },
      {
        name: 'user_time_spent_per_labs',
        label: 'Active time per lab',
        default: false,
        mandatory: false,
        showTo: [USER_ACTIVITY],
        tooltip: {
          [USER_ACTIVITY]: 'Total time spent in each hands-on lab, expressed in hh:mm:ss',
        },
      },
      {
        name: 'lab_status',
        label: 'Lab status',
        default: false,
        mandatory: false,
        showTo: [USER_ACTIVITY],
        tooltip: {
          [USER_ACTIVITY]: 'Status of the hands-on lab, whether started or marked as finished',
        },
      },
      {
        name: 'labs_started',
        label: 'Started labs',
        default: false,
        mandatory: false,
        showTo: [TEAM_ACTIVITY, USER_ACTIVITY],
        tooltip: {
          [USER_ACTIVITY]: 'Total number of labs initiated by the user',
          [TEAM_ACTIVITY]: 'Total number of labs initiated by the team',
        },
      },
    ],
  },
  {
    name: 'Videos',
    showTo: [TEAM_ACTIVITY, COURSES, USER_ACTIVITY],
    filters: [
      {
        name: 'videos_total_watched_time',
        label: 'Total video watched time',
        default: true,
        mandatory: false,
        showTo: [TEAM_ACTIVITY, COURSES, USER_ACTIVITY],
        tooltip: {
          [USER_ACTIVITY]: 'Total duration of time spent watching videos, measured in hh:mm:ss',
          [TEAM_ACTIVITY]:
            'Total duration of time spent by the team members watching videos, measured in hh:mm:ss',
          [COURSES]:
            'Duration of time spent by the team members watching videos, measured in hh:mm:ss',
        },
      },
      {
        name: 'videos_course_total',
        label: 'Total videos in courses',
        default: false,
        mandatory: false,
        showTo: [COURSES],
        tooltip: { [COURSES]: 'Number of videos inside each course' },
      },
      {
        name: 'videos_started',
        label: 'Started videos',
        default: false,
        mandatory: false,
        showTo: [TEAM_ACTIVITY, COURSES, USER_ACTIVITY],
        tooltip: {
          [USER_ACTIVITY]: 'Total number of videos initiated by the user',
          [TEAM_ACTIVITY]: 'Total number of videos initiated by the team',
          [COURSES]: 'Number of started videos by the user',
        },
      },
      {
        name: 'videos_percentage_started',
        label: 'Started videos (%)',
        default: false,
        mandatory: false,
        showTo: [COURSES],
        tooltip: {
          [COURSES]: 'Percentage of videos started by the user',
        },
      },
      {
        name: 'videos_finished',
        label: 'Finished videos',
        default: false,
        mandatory: false,
        showTo: [TEAM_ACTIVITY, COURSES, USER_ACTIVITY],
        tooltip: {
          [USER_ACTIVITY]: 'Total number of videos viewed to completion by the user',
          [TEAM_ACTIVITY]: 'Total number of videos viewed to completion by the team',
          [COURSES]: 'Number of videos viewed to completion by the users',
        },
      },
      {
        name: 'videos_percentage_finished',
        label: 'Finished videos (%)',
        default: false,
        mandatory: false,
        showTo: [COURSES],
        tooltip: { [COURSES]: 'Percentage of videos viewed to completion by the users' },
      },
    ],
  },
  {
    name: 'Quizzes',
    showTo: [TEAM_ACTIVITY, COURSES, USER_ACTIVITY],
    filters: [
      {
        name: 'quizzes_course_total',
        label: 'Total quizzes in course',
        default: false,
        mandatory: false,
        showTo: [COURSES],
        tooltip: {
          [COURSES]: 'Number of quizzes in each course',
        },
      },
      {
        name: 'quizzes_started_attempt',
        label: 'Started quizzes attempt',
        default: true,
        mandatory: false,
        showTo: [TEAM_ACTIVITY, USER_ACTIVITY],
        tooltip: {
          [USER_ACTIVITY]: 'Total number of quizzes initiated by the user',
          [TEAM_ACTIVITY]: 'Total number of quizzes initiated by the team',
        },
      },
      {
        name: 'quizzes_started_attempt',
        label: 'Started quizzes (#)',
        default: true,
        mandatory: false,
        showTo: [COURSES],
        tooltip: {
          [COURSES]: 'Total number of quizzes initiated by the user',
        },
      },
      {
        name: 'quizzes_percentage_started_attempt',
        label: 'Started quizzes (%)',
        default: false,
        mandatory: false,
        showTo: [COURSES],
        tooltip: {
          [COURSES]: 'Percentage of quizzes initiated by the user',
        },
      },
      {
        name: 'quizzes_passed_attempt',
        label: 'Passed quizzes attempts',
        default: true,
        mandatory: false,
        showTo: [TEAM_ACTIVITY, USER_ACTIVITY],
        tooltip: {
          [USER_ACTIVITY]: 'Number of quizzes successfully completed by the user',
          [TEAM_ACTIVITY]: 'Number of quizzes successfully completed by the team',
        },
      },

      {
        name: 'quizzes_passed_attempt',
        label: 'Passed quizzes (#)',
        default: true,
        mandatory: false,
        showTo: [COURSES],
        tooltip: {
          [COURSES]: 'Total number of quizzes successfully completed by the user',
        },
      },
      {
        name: 'quizzes_percentage_finished',
        label: 'Finished quizzes (%)',
        default: false,
        mandatory: false,
        showTo: [COURSES],
        tooltip: {
          [COURSES]: 'Total number of quizzes finished by the users',
        },
      },
      {
        name: 'quizzes_average_score',
        label: 'Average quizzes score',
        default: true,
        mandatory: false,
        showTo: [TEAM_ACTIVITY, USER_ACTIVITY],
        tooltip: {
          [USER_ACTIVITY]:
            'Average performance across all quizzes that have been completed successfully',
          [TEAM_ACTIVITY]:
            'Average performance across all quizzes that have been completed successfully',
        },
      },
      {
        name: 'quizzes_average_score',
        label: 'Average passed quizzes score',
        default: true,
        mandatory: false,
        showTo: [COURSES],
        tooltip: {
          [COURSES]: 'Average performance across all quizzes that have been completed successfully',
        },
      },
    ],
  },
  {
    name: 'Hands-on Labs',
    showTo: [COURSES],
    filters: [
      {
        name: 'labs_course_total',
        label: 'Total labs in course',
        default: false,
        mandatory: false,
        showTo: [COURSES],
        tooltip: { [COURSES]: 'Number of labs in each course' },
      },
      {
        name: 'user_time_spent_in_labs',
        label: 'Active time on labs',
        default: false,
        mandatory: false,
        showTo: [TEAM_ACTIVITY, USER_ACTIVITY],
        tooltip: {
          [USER_ACTIVITY]: 'Total time spent in standard hands-on labs, expressed in hh:mm:ss',
          [TEAM_ACTIVITY]: 'Time spent in standard hands-on labs, expressed in hh:mm:ss',
        },
      },
      {
        name: 'labs_started',
        label: 'Started labs (#)',
        default: false,
        mandatory: false,
        showTo: [COURSES],
        tooltip: {
          [COURSES]: 'Total number of labs initiated by the users',
        },
      },
      {
        name: 'labs_percentage_started',
        label: 'Started labs (%)',
        default: false,
        mandatory: false,
        showTo: [COURSES],
        tooltip: {
          [COURSES]: 'Percentage of labs initiated by the users',
        },
      },
      {
        name: 'labs_finished',
        label: 'Finished labs (#)',
        default: false,
        mandatory: false,
        showTo: [COURSES],
        tooltip: {
          [COURSES]: 'Total number of labs finished by the users',
        },
      },
      {
        name: 'labs_percentage_finished',
        label: 'Finished labs (%)',
        default: false,
        mandatory: false,
        showTo: [COURSES],
        tooltip: {
          [COURSES]: 'Percentage of quizzes finished by the users',
        },
      },
    ],
  },
  {
    name: 'Slides',
    showTo: [TEAM_ACTIVITY, COURSES, USER_ACTIVITY],
    filters: [
      {
        name: 'slides_course_total',
        label: 'Total slides in course',
        default: false,
        mandatory: false,
        showTo: [COURSES],
        tooltip: {
          [COURSES]: 'Number of slides inside each course',
        },
      },
      {
        name: 'slides_started',
        label: 'Started slides (#)',
        default: false,
        mandatory: false,
        showTo: [COURSES],
        tooltip: {
          [COURSES]: 'Total number of slides initiated by the users',
        },
      },
      {
        name: 'slides_percentage_started',
        label: 'Started slides (%)',
        default: false,
        mandatory: false,
        showTo: [COURSES],
        tooltip: {
          [COURSES]: 'Percentage of slides started by the users',
        },
      },
      {
        name: 'slides_finished',
        label: 'Finished slides',
        default: false,
        mandatory: false,
        showTo: [TEAM_ACTIVITY, USER_ACTIVITY],
        tooltip: {
          [USER_ACTIVITY]: 'Total number of slides completed by the user',
          [TEAM_ACTIVITY]: 'Total number of slides completed by the team',
        },
      },

      {
        name: 'slides_finished',
        label: 'Finished slides (#)',
        default: false,
        mandatory: false,
        showTo: [COURSES],
        tooltip: {
          [COURSES]: 'Total number of slides finished by the users',
        },
      },
      {
        name: 'slides_percentage_finished',
        label: 'Finished slides (%)',
        default: false,
        mandatory: false,
        showTo: [COURSES],
        tooltip: {
          [COURSES]: 'Percentage of slides finished by the users',
        },
      },
    ],
  },
  {
    name: 'Exercises',
    showTo: [USER_ACTIVITY, TEAM_ACTIVITY],
    filters: [
      {
        name: 'started_exercises',
        label: 'Started exercises',
        default: false,
        mandatory: false,
        showTo: [USER_ACTIVITY, TEAM_ACTIVITY],
        tooltip: {
          [USER_ACTIVITY]: 'Total number of exercises initiated by the user',
          [TEAM_ACTIVITY]: 'Total number of exercises initiated by the team',
        },
      },
      {
        name: 'finished_exercises',
        label: 'Finished exercises',
        default: false,
        mandatory: false,
        showTo: [USER_ACTIVITY],
        tooltip: { [USER_ACTIVITY]: 'Total number of exercises finished by the user' },
      },
    ],
  },
];

const handsOnLabsLabsUserOrTeams = [
  {
    name: 'user_time_spent_in_labs',
    label: 'Active time on labs',
    default: false,
    mandatory: false,
    showTo: [TEAM_ACTIVITY, USER_ACTIVITY],
    tooltip: {
      [USER_ACTIVITY]: 'Total time spent in standard hands-on labs, expressed in hh:mm:ss',
      [TEAM_ACTIVITY]: 'Total time spent in standard hands-on labs, expressed in hh:mm:ss',
    },
  },
  {
    name: 'user_time_spent_per_labs',
    label: 'Active time per lab',
    default: false,
    mandatory: false,
    showTo: [USER_ACTIVITY],
    tooltip: {
      [USER_ACTIVITY]: 'Total time spent in each hands-on lab, expressed in hh:mm:ss',
    },
  },
  {
    name: 'labs_started',
    label: 'Total started labs',
    default: false,
    mandatory: false,
    showTo: [TEAM_ACTIVITY, USER_ACTIVITY],
    tooltip: {
      [USER_ACTIVITY]: 'Total number of labs started by the user (including CTF and standard labs)',
      [TEAM_ACTIVITY]: 'Total number of labs started by the user (including CTF and standard labs)',
    },
  },
  {
    name: 'finished_ctf_labs',
    label: 'Finished CTF labs',
    default: false,
    mandatory: false,
    showTo: [TEAM_ACTIVITY, USER_ACTIVITY],
    tooltip: {
      [USER_ACTIVITY]: 'Total number of capture the flag labs completed by the user',
      [TEAM_ACTIVITY]: 'Total number of capture the flag labs completed by the user',
    },
  },
  {
    name: 'finished_ctf_labs_percentage',
    label: 'Finished CTF labs (%)',
    default: false,
    mandatory: false,
    showTo: [TEAM_ACTIVITY, USER_ACTIVITY],
    tooltip: {
      [USER_ACTIVITY]: 'Percentage of capture the flag labs completed by the user',
      [TEAM_ACTIVITY]: 'Percentage of capture the flag labs completed by the user',
    },
  },
];

const handsOnLabsLabsCourses = [
  {
    name: 'labs_course_total',
    label: 'Total labs in course',
    default: false,
    mandatory: false,
    showTo: [COURSES],
    tooltip: {
      [COURSES]: 'Total number of labs within the course (including CTF and standard labs)',
    },
  },
  {
    name: 'user_time_spent_in_labs',
    label: 'Active time on labs',
    default: false,
    mandatory: false,
    showTo: [TEAM_ACTIVITY, USER_ACTIVITY],
    tooltip: {
      [USER_ACTIVITY]: 'Total time spent in standard hands-on labs, expressed in hh:mm:ss',
      [TEAM_ACTIVITY]: 'Time spent in standard hands-on labs, expressed in hh:mm:ss',
    },
  },
  {
    name: 'labs_started',
    label: 'Started labs (#)',
    default: false,
    mandatory: false,
    showTo: [COURSES],
    tooltip: {
      [COURSES]: 'Total number of labs initiated by the users',
    },
  },
  {
    name: 'labs_percentage_started',
    label: 'Started labs (%)',
    default: false,
    mandatory: false,
    showTo: [COURSES],
    tooltip: {
      [COURSES]: 'Percentage of labs initiated by the users',
    },
  },
  {
    name: 'labs_finished',
    label: 'Finished CTF labs',
    default: false,
    mandatory: false,
    showTo: [COURSES],
    tooltip: {
      [COURSES]: 'Total number of capture the flag labs completed within the course',
    },
  },
  {
    name: 'labs_percentage_finished',
    label: 'Finished CTF labs (%)',
    default: false,
    mandatory: false,
    showTo: [COURSES],
    tooltip: {
      [COURSES]: 'Percentage of capture the flag labs completed within the course',
    },
  },
];

const skillDiveLabsUserOrTeams = [
  {
    name: 'sd_active_users',
    label: 'Active users',
    default: false,
    showTo: [TEAM_ACTIVITY],
    mandatory: false,
    tooltip: {
      [TEAM_ACTIVITY]: 'Total number of team members currently accessing Skill Dive content',
    },
  },
  {
    name: 'sd_total_active_time_labs',
    label: 'Total active time',
    default: false,
    showTo: [USER_ACTIVITY, TEAM_ACTIVITY],
    mandatory: false,
    tooltip: {
      [USER_ACTIVITY]: 'Total time spent in Skill Dive labs, expressed in hh:mm:ss',
      [TEAM_ACTIVITY]: 'Total time spent by the team in Skill Dive labs, expressed in hh:mm:ss',
    },
  },
  {
    name: 'sd_labs_breakdown',
    label: 'Labs breakdown',
    default: false,
    showTo: [TEAM_ACTIVITY],
    mandatory: false,
    tooltip: {
      [TEAM_ACTIVITY]:
        'List of labs initiated by the team, including details on learning area and difficulty level',
    },
  },
  {
    name: 'sd_active_per_la',
    label: 'Active time per lab',
    default: false,
    showTo: [USER_ACTIVITY],
    mandatory: false,
    tooltip: {
      [USER_ACTIVITY]: 'Time spent in each Skill Dive lab, expressed in hh:mm:ss',
    },
  },
  {
    name: 'usage_per_la',
    label: 'Usage per learning area (%)',
    default: false,
    showTo: [USER_ACTIVITY, TEAM_ACTIVITY],
    mandatory: false,
    tooltip: {
      [USER_ACTIVITY]:
        'Percentage breakdown of usage across learning areas (Cybersecurity, Networking, and Cloud)',
      [TEAM_ACTIVITY]:
        'Percentage breakdown of usage across learning areas (Cybersecurity, Networking, and Cloud) averaged for the team',
    },
  },
  {
    name: 'usage_per_difficulty',
    label: 'Usage per difficulty (%)',
    default: false,
    showTo: [USER_ACTIVITY, TEAM_ACTIVITY],
    mandatory: false,
    tooltip: {
      [USER_ACTIVITY]:
        'Percentage breakdown of usage by difficulty levels (Novice, Professional, Advanced, and Expert)',
      [TEAM_ACTIVITY]:
        'Percentage breakdown of usage by difficulty levels (Novice, Professional, Advanced, and Expert) averaged for the team',
    },
  },
  {
    name: 'sd_finished_ctf_labs',
    label: 'Finished CTF labs',
    default: false,
    showTo: [USER_ACTIVITY, TEAM_ACTIVITY],
    mandatory: false,
    tooltip: {
      [USER_ACTIVITY]: 'Total number of capture the flag labs completed by the user',
      [TEAM_ACTIVITY]: 'Total number of capture the flag labs completed by the user',
    },
  },
];

const modifyFilterForMarkAsCompleted = (filters) => {
  const newFilters = JSON.parse(JSON.stringify(filters));

  const slidesIndex = newFilters.findIndex((filter) => filter.name === 'Slides');
  if (slidesIndex !== -1) {
    newFilters[slidesIndex].showTo = [COURSES];
    newFilters[slidesIndex].filters = newFilters[slidesIndex].filters.filter(
      (filter) => filter.name !== 'slides_percentage_finished' && filter.name !== 'slides_finished'
    );
  }

  const handsOnLabsUserIndex = newFilters.findIndex(
    (filter) =>
      filter.name === 'Hands-on Labs' &&
      (filter.showTo.includes(USER_ACTIVITY) || filter.showTo.includes(TEAM_ACTIVITY))
  );
  const skillDiveLabsUserIndex = newFilters.findIndex(
    (filter) =>
      filter.name === skillDiveColumnName &&
      (filter.showTo.includes(USER_ACTIVITY) || filter.showTo.includes(TEAM_ACTIVITY))
  );

  const handsOnLabsLabsCoursesIndex = newFilters.findIndex(
    (filter) => filter.name === 'Hands-on Labs' && filter.showTo.includes(COURSES)
  );

  if (handsOnLabsUserIndex !== -1) {
    newFilters[handsOnLabsUserIndex].filters = handsOnLabsLabsUserOrTeams;
  }

  if (handsOnLabsLabsCoursesIndex !== -1) {
    newFilters[handsOnLabsLabsCoursesIndex].filters = handsOnLabsLabsCourses;
  }

  if (skillDiveLabsUserIndex !== -1) {
    newFilters[skillDiveLabsUserIndex].filters = skillDiveLabsUserOrTeams;
  }

  return newFilters;
};

export const filters = (
  filtersType: string,
  isMarkAsCompleted?: boolean
): Array<FilterGroupProps> => {
  if (isMarkAsCompleted) {
    return modifyFilterForMarkAsCompleted(defaultFilters);
  }

  return defaultFilters;
};

const filtersTab = (filtersType: string): Array<FilterGroupProps> => {
  const tabFilters = filters(filtersType).filter((filter) => filter.showTo.includes(filtersType));
  const result = [];
  tabFilters.forEach((filter) => {
    result.push(...(filter?.filters?.filter((f) => f.showTo.includes(filtersType)) || []));
  });
  return result;
};

export const filtersByTab = {
  [USER_ACTIVITY]: filtersTab(USER_ACTIVITY)?.map((f) => f.name) || [],
  [TEAM_ACTIVITY]: filtersTab(TEAM_ACTIVITY)?.map((f) => f.name) || [],
  [COURSES]: filtersTab(COURSES)?.map((f) => f.name) || [],
  [ACCOUNT]: filtersTab(ACCOUNT)?.map((f) => f.name) || [],
};
