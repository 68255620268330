import React, { Fragment } from 'react';
import FirstLetterEmail from 'components/firstLetterEmail/firstLetterEmail';
import Icon from 'components/icons';
import { Container, BodyTitle, RowContainer, LabelEmail, LabelError } from './styles';
import { Space } from 'antd';

interface IRemoveUsers {
  wrongEmails: { email: string; error: string }[];
}

const RemoveUsers: React.FC<IRemoveUsers> = ({ wrongEmails }) => {
  return (
    <Fragment>
      <BodyTitle>{`There are errors with ${wrongEmails.length} users marked below`}</BodyTitle>
      <Container>
        <Space direction={'vertical'} style={{ width: '100%' }}>
          {wrongEmails.map(
            (error) =>
              error && (
                <RowContainer key={error?.email}>
                  <FirstLetterEmail email={error?.email} />
                  <div className="error-data-container">
                    <LabelEmail>{error?.email}</LabelEmail>
                    <LabelError>
                      <Icon icon="AlertCircle" size="small" />
                      {error?.error}
                    </LabelError>
                  </div>
                </RowContainer>
              )
          )}
        </Space>
      </Container>
    </Fragment>
  );
};

export default RemoveUsers;
