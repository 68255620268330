import { useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import Avatar from 'components/avatar';
import Popover from 'components/popover';
import { PopOverContentContainer, PopoverItem } from 'components/popover';
import Icon from 'components/icons';
import useOnClickOutside from 'hooks/useOutsideClick';
import { AccountSummary } from 'types/users';
import {
  AvatarContainer,
  Container,
  CustomPopoverTitle,
  CustomStylesPopover,
} from './Business.styles';
import AccountDefaultLogo from 'assets/images/account-avatar.svg';
import Button from 'components/button';

const PopoverContent = ({ onClose, account }: { onClose(): void; account: AccountSummary }) => {
  const ref = useRef();
  useOnClickOutside(ref, onClose); // Close popover on click outsides

  return (
    <PopOverContentContainer ref={ref}>
      <span className={'close-btn'} onClick={onClose}>
        <Icon icon="Close" size={'default'} />
      </span>

      <Container>
        <CustomPopoverTitle>{account?.name}</CustomPopoverTitle>
        <PopoverItem>
          {account?.users_count} Users
          <span className={'muted-text'}> ({account?.users_pending} pending)</span>
        </PopoverItem>
        <PopoverItem>{account?.licenses_available} Licenses available</PopoverItem>
        <PopoverItem>{account?.teams_count} Teams</PopoverItem>
      </Container>
      <br />
      <Link to={`/account/${account.id}`} onClick={onClose}>
        <Button $secondary icon={'MenuConfig'} size={'small'} style={{ margin: '0 0 0 auto' }}>
          Go to account
        </Button>
      </Link>
    </PopOverContentContainer>
  );
};

const AsideBusiness = ({ account }: { account: AccountSummary }): JSX.Element => {
  const [showAccountInfo, setShowAccountInfo] = useState(false);

  if (!account?.id) {
    return null;
  }

  return (
    <CustomStylesPopover>
      <Popover
        placement={!isMobile ? 'rightBottom' : 'topRight'}
        content={<PopoverContent account={account} onClose={() => setShowAccountInfo(false)} />}
        getPopupContainer={(trigger) => trigger.parentElement}
        trigger="click"
        arrowPointAtCenter
        visible={showAccountInfo}
      >
        <AvatarContainer onClick={() => setShowAccountInfo(!isMobile)}>
          <Avatar image={account?.logo_url || AccountDefaultLogo} size={isMobile ? 'sm' : 'lg'} />
        </AvatarContainer>
      </Popover>
    </CustomStylesPopover>
  );
};

export default AsideBusiness;
