import { AxiosResponse } from 'axios';
import { CONTENT } from './axios';
import { config } from 'config/config.local';
import { ICategory } from 'contexts/categories/types';

interface IGetCategories {
  results: ICategory[];
  count: number;
}

export interface ICategoriesFetchParams {
  page?: string | number;
  page_size?: string | number;
  company?: string;
  ids?: string[];
}

export interface ICategoryFetchParams {
  id: string;
}

export const getCategories = (
  params: ICategoriesFetchParams
): Promise<AxiosResponse<IGetCategories>> =>
  CONTENT.get(`${config.routes.playlists.getCategories}`, { params });

export const getCategory = (params: ICategoryFetchParams): Promise<AxiosResponse<ICategory>> =>
  CONTENT.get(`${config.routes.playlists.getCategories}/${params.id}`);
