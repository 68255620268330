import { AxiosResponse } from 'axios';
import { CONTENT } from './axios';
import { config } from 'config/config.local';
import { IPlaylistLink } from 'contexts/playlists/types';

export const bulkCreateLinks = ({
  links,
  business_account_id,
}: {
  links: IPlaylistLink[];
  business_account_id: string;
}): Promise<AxiosResponse<IPlaylistLink[]>> => {
  return CONTENT.post(
    `${config.routes.links}/bulk?business_account_id=${business_account_id}`,
    links
  );
};

export const patchLink = ({
  link,
  business_account_id,
}: {
  link: IPlaylistLink;
  business_account_id: string;
}): Promise<AxiosResponse<IPlaylistLink>> => {
  return CONTENT.patch(
    `${config.routes.links}/${link.id}?business_account_id=${business_account_id}`,
    link
  );
};
