import {
  InputIcon,
  InputSelectOptions,
  InputTypeSelect,
  SelectDropdown,
} from 'components/inputs/styles';
import Icon from 'components/icons';
import { Dropdown } from 'antd';
import { useState } from 'react';

interface InputSelectProps {
  value: string;
  icon?: string;
  onChange?: (selected: string | number) => void;
  options?: {
    label: string;
    value: string | number;
    icon?: string;
  }[];
  defaultOpen?: boolean;
  placeHolder?: string;
  error?: boolean;
  disabled?: boolean;
  maxDropdownHeight?: string;
}

const InputSelect: React.FC<InputSelectProps> = ({
  value,
  icon,
  onChange,
  options,
  defaultOpen,
  placeHolder,
  error,
  disabled,
  maxDropdownHeight,
}) => {
  const [open, setOpen] = useState(defaultOpen);
  const handleSelection = (value: string | number) => {
    onChange(value);
    setOpen(false);
  };

  return (
    <Dropdown
      overlay={
        <SelectDropdown maxHeight={maxDropdownHeight}>
          {options.map(({ label, value, icon }) => {
            return (
              <InputSelectOptions key={value} onClick={() => handleSelection(value)}>
                {icon && <InputIcon src={icon}></InputIcon>}
                {label}
              </InputSelectOptions>
            );
          })}
        </SelectDropdown>
      }
      trigger={['click']}
      visible={open}
      disabled={disabled}
      onVisibleChange={setOpen}
    >
      <InputTypeSelect error={error} disabled={disabled}>
        {icon && <InputIcon src={icon}></InputIcon>}
        {value || placeHolder}
        <Icon icon={'ChevronDown'} size={'smaller'} />
      </InputTypeSelect>
    </Dropdown>
  );
};

export default InputSelect;
