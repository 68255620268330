import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { isDesktop } from 'react-device-detect';
import { Space } from 'antd';
import ButtonIcon from 'components/buttonIcon';
import { ActionsContainer } from 'components/layout/profile/profileHeader/styles';
import Tooltip from 'components/tooltip';
import Button from 'components/button';
import { DeepTeams, Team } from 'contexts/teams/types';
import { SessionContext } from 'contexts/session';
import { SecureModal } from 'components/modal';
import { removeTeams } from 'services/teams';
import Notification from 'components/notification';
import useManageTeam from '../hooks/useManageTeam';
import useInviteToTeam from '../hooks/useInviteToTeam';
import useModal from 'hooks/useModal';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const HeaderActions = ({
  team,
  refreshData,
}: {
  team: DeepTeams;
  refreshData(): void;
}): JSX.Element => {
  const { account, actions: sessionActions, licenses_available } = useContext(SessionContext);
  const { modal, setModal } = useModal();
  const { manageTeamModal, openManageTeamModal, closeManageTeamModal } = useManageTeam();
  const { inviteToTeamModal, openInviteToTeamModal, closeInviteToTeamModal } = useInviteToTeam();

  const history = useHistory();

  const removeDisabled = team.active_users_count > 0 || team.pending_users_count > 0;

  const handleAddUser = () => {
    const onClose = (afterInvite: boolean) => {
      if (afterInvite) {
        refreshData();
        if (!licenses_available) {
          sessionActions.refreshSession();
        }
      }
      closeInviteToTeamModal();
    };
    openInviteToTeamModal({
      isInvite: true,
      team: team,
      account: account,
      onClose: onClose,
    });
  };

  const handleEditTeam = () => {
    const onClose = (data: Team) => {
      if (data) {
        sessionActions.updateAccountTeams((prev) => {
          const editedIndex = prev.findIndex(({ id }) => id === data.id);
          prev[editedIndex] = data;
          return [...prev];
        });
        refreshData();
      }
      closeManageTeamModal();
    };
    openManageTeamModal({
      team: team,
      onClose: onClose,
    });
  };

  const handleRemoveTeam = () => {
    const onAccept = async () => {
      try {
        const response = await removeTeams({ ids: [team.id] });
        if (response.status >= 200) {
          Notification({ type: 'success', text: 'Successfully remove team' });
          history.push('/teams/');
        } else return new Error();
      } catch (error) {
        Notification({ type: 'error', text: 'Couldn´t remove team' });
      }
    };
    setModal(
      <SecureModal
        title={'Remove team'}
        primarylabel={'Remove team'}
        active={true}
        onCancel={() => setModal(null)}
        onAccept={onAccept}
      />
    );
  };

  return (
    <ActionsContainer>
      {isDesktop && (
        <>
          <Space size={10} align={'end'}>
            <>
              <Button $primary size={'small'} icon={'UserPlus'} onClick={handleAddUser}>
                Add user
              </Button>
              <Tooltip placement="bottomRight" title={'Edit team'}>
                <ButtonIcon $secondary size={'small'} icon={'Edit'} onClick={handleEditTeam} />
              </Tooltip>
              <Tooltip
                placement="bottomRight"
                title={
                  removeDisabled
                    ? 'Teams can only be deleted by removing the users first. Remove the users, then delete the team.'
                    : 'Remove team'
                }
                destroyTooltipOnHide
              >
                <div>
                  <ButtonIcon
                    $secondary
                    disabled={removeDisabled}
                    size={'small'}
                    icon={'Trash'}
                    onClick={handleRemoveTeam}
                  />
                </div>
              </Tooltip>
            </>
          </Space>
        </>
      )}
      {manageTeamModal}
      {inviteToTeamModal}
      {modal}
    </ActionsContainer>
  );
};

export default HeaderActions;
