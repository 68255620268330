import { getPaginatedTeams, removeTeams } from 'services/teams';
import { TeamsParams } from './types';
import { handleErrors } from '../helpers';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getTeamsAction = async (
  params: TeamsParams
): Promise<{ results: any; count: number } | Error> => {
  try {
    const { data } = await getPaginatedTeams(params);
    return {
      results: data,
      count: data.count,
    };
  } catch (error) {
    return handleErrors(error);
  }
};

export const getRemoveTeamsAction = async ({
  ids,
}: {
  ids: string[];
}): Promise<{ results: boolean; count: number } | Error> => {
  try {
    const { data } = await removeTeams({ ids });
    return {
      results: data.result,
      count: 0,
    };
  } catch (error) {
    return handleErrors(error);
  }
};
