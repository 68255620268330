import styled from 'styled-components';
import Button from 'components/button';

export const FilterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
`;

export const FilterCount = styled.div<{ disabled?: boolean }>`
  padding: 0 0 0 12px;
  color: ${({ disabled, theme }) =>
    disabled ? theme.default.colors.fonts.lightGray : theme.default.colors.fonts.dark};
  font-weight: 500;
  font-size: 13px;
  align-self: end;
  justify-self: end;
`;

export const ClearFiltersButton = styled(Button)`
  font-weight: 450;
`;
