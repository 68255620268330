import { getSessionData } from 'services/session';
import { getAccountData } from 'services/accounts';
import { UsersMe } from 'types/users';
import { Team } from '../teams/types';
import { getTeams } from 'services/teams';
import { Account } from 'contexts/accounts/types';
import { handleErrors } from '../helpers';

export const getSessionDataAction = async (): Promise<UsersMe | Error> => {
  try {
    const { data } = await getSessionData();
    return data;
  } catch (error) {
    return handleErrors(error);
  }
};

export const getAccountsDataAction = async ({ id }: { id: string }): Promise<Account | Error> => {
  try {
    const { data } = await getAccountData({ id });
    return data;
  } catch (error) {
    return handleErrors(error);
  }
};

export const getTeamsAction = async (): Promise<Team[] | Error> => {
  try {
    const { data } = await getTeams();
    return data;
  } catch (error) {
    return handleErrors(error);
  }
};
