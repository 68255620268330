import './styles.scss';
import { useEffect, useMemo, useRef, useState } from 'react';
import Icon from 'components/icons';
import Tooltip from 'components/tooltip';
import cx from 'classnames';

type Props = {
  inputClass?: string;
  value: string;
  maxLenght?: number;
  minLenght?: number;
  placeholder?: string;
  onSave?: (value: string) => void;
};

const ContentEditable = ({
  value,
  onSave,
  maxLenght,
  minLenght,
  placeholder,
  inputClass,
}: Props): JSX.Element => {
  const [isEditing, setIsEditing] = useState(false);
  const fieldRef = useRef(null);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  const toggleEditing = () => {
    setIsEditing((prevValue) => !prevValue);
  };

  const saveChanges = () => {
    const localValue = fieldRef.current.textContent;

    if (minLenght && localValue.length < minLenght) return;

    fieldRef.current.focus();
    toggleEditing();
    onSave(localValue);
  };

  const discardChanges = () => {
    fieldRef.current.textContent = value;
    toggleEditing();
  };

  const onKeyDown = (e) => {
    switch (e.key) {
      case 'Enter':
        e.preventDefault();
        saveChanges();
        break;
      case 'Escape':
        discardChanges();
        break;
    }
  };

  const onValueChange = () => {
    const localValue = fieldRef.current.textContent;

    if (maxLenght && localValue.length > maxLenght) {
      fieldRef.current.textContent = localValue.slice(0, maxLenght);
      cursorToEnd();
    }

    setIsSaveDisabled(minLenght && localValue.length < minLenght);
  };

  const showPlaceholder = useMemo(() => !isEditing && !value, [isEditing, value]);

  const cursorToEnd = () => selectRange(true);
  const selectAll = () => selectRange(false);

  const selectRange = (collapse) => {
    const range = document.createRange();
    range.selectNodeContents(fieldRef.current);

    if (collapse) range.collapse(false);

    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  };

  useEffect(() => {
    fieldRef.current.textContent = value;
  }, [value]);

  useEffect(() => {
    if (isEditing) selectAll();
  }, [isEditing]);

  return (
    <div className="content-editable">
      <p
        className={cx('content-editable__field', inputClass, {
          'content-editable__field--editing': isEditing,
          'content-editable__field--hide': showPlaceholder,
          [`${inputClass}--editing`]: inputClass && isEditing,
        })}
        contentEditable={isEditing}
        ref={fieldRef}
        onInput={onValueChange}
        onKeyDown={onKeyDown}
        suppressContentEditableWarning
      />

      <span
        className={cx('content-editable__button-wrapper', 'content-editable__placeholder-button', {
          [`${inputClass}-placeholder`]: inputClass,
          'content-editable__placeholder-button--hide': !showPlaceholder,
        })}
        onClick={toggleEditing}
      >
        {placeholder}
      </span>

      {isEditing ? (
        <>
          <Tooltip placement="top" title="Save">
            <span
              className={cx('content-editable__button-wrapper', 'content-editable__save-button', {
                'content-editable__button-wrapper--disabled': isSaveDisabled,
              })}
              onClick={saveChanges}
            >
              <Icon icon="Check2" size="small" />
            </span>
          </Tooltip>
          <Tooltip placement="top" title="Discard">
            <span
              className="content-editable__button-wrapper content-editable__discard-button"
              onClick={discardChanges}
            >
              <Icon icon="Cancel2" size="small" />
            </span>
          </Tooltip>
        </>
      ) : (
        !showPlaceholder && (
          <Tooltip placement="top" title="Edit">
            <span
              className={'content-editable__button-wrapper content-editable__edit-button'}
              onClick={toggleEditing}
            >
              <Icon icon="Edit2" size="small" />
            </span>
          </Tooltip>
        )
      )}
    </div>
  );
};

export default ContentEditable;
