import { ButtonProps } from 'antd/lib/button/button';
import Icon, { ICONS } from 'components/icons';
import { StyledButton } from './styles';

export interface IButtonProps extends ButtonProps {
  $primary?: boolean;
  $secondary?: boolean;
  $terciary?: boolean;
  $white?: boolean;
  $dark?: boolean;
  icon?: keyof typeof ICONS;
  dark?: boolean;
  size?: 'small';
  $full?: boolean;
  $active?: boolean;
}

const Button = ({
  children,
  icon,
  $primary,
  $secondary,
  $terciary,
  $dark,
  $white,
  size,
  $active,
  loading,
  ...rest
}: IButtonProps): JSX.Element => {
  const getStyle = ({ $primary, $secondary, $terciary }: IButtonProps): string => {
    if ($primary) return 'primary';
    if ($secondary) return 'secondary';
    if ($terciary) return 'terciary';
    return 'primary';
  };

  const getTheme = ({ $dark, $white }: IButtonProps): string => {
    if ($dark) return 'dark';
    if ($white) return 'white';
    return 'default';
  };

  const style = getStyle({ $primary, $secondary, $terciary });
  const theme = getTheme({ $dark, $white });

  return (
    <StyledButton
      $btnStyle={style}
      $btnSize={size || 'default'}
      $btnTheme={theme}
      $active={$active}
      loading={loading}
      {...rest}
    >
      {icon && !loading && <Icon icon={icon} />}
      {children}
    </StyledButton>
  );
};

export default Button;
