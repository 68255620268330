import { FC, useContext } from 'react';
import styled from 'styled-components';
import CirclesInfoDisplay from 'components/circlesInfoDisplay/CirclesInfoDisplay';
import { teamsIconsArray } from 'components/circlesInfoDisplay/utilities';
import {
  CardContent,
  CardRowContainer,
  CustomLink,
} from 'components/layout/profile/profileContent/styles';
import { AccountTeamsData, AccountUsersData } from 'contexts/accounts/types';
import Icon from 'components/icons';
import { SessionContext } from 'contexts/session';
import { TeamsContext } from 'contexts/teams';
import { ROLES } from 'constants/roles';
import EmptyTeamsCard from './EmptyTeams';
import useManageTeam from '../../../Teams/components/hooks/useManageTeam';
import useInviteToTeam from '../../../Teams/components/hooks/useInviteToTeam';
import AccountDefaultLogo from 'assets/images/account-avatar.svg';
import Button from 'components/button';
import { screenMd } from 'constants/styles/breakpoints';
import { DeepTeams, Team } from 'contexts/teams/types';
import { IconTeam } from 'components/teamSelect/IconTeam';
import { RolePill, TeamData } from 'pages/Users/components/teamCard';
import useAccountPlanData from 'hooks/useAccountPlanData';

const CustomCard = styled(CardRowContainer)<{ dashboard?: boolean }>`
  ${({ dashboard }) => (dashboard ? '' : 'padding: 30px;')};
  grid-gap: 40px;
`;

const CustomCardContent = styled(CardContent)<{ dashboard?: boolean }>`
  span {
    ${screenMd} {
      ${({ dashboard }) =>
        dashboard &&
        `
				grid-gap: 10px;
				flex-direction: row;
			`};
    }
  }
  .teams-card__create-team-btn {
    display: none;
    margin-top: 20px;
    ${screenMd} {
      display: initial;
    }
  }
`;

const AccountTeamsCard: FC<{
  teamsData: AccountTeamsData;
  usersData: AccountUsersData;
  dashboard?: boolean;
  team?: DeepTeams;
}> = ({ teamsData, usersData, dashboard, team }) => {
  const { teams, count } = teamsData;
  const { total: totalUsers, pending_without_team } = usersData;
  const usersWithTeam = totalUsers - pending_without_team;
  const { actions: teamsActions } = useContext(TeamsContext);
  const {
    userRoleIds,
    isTeamManager,
    isAccountOwner,
    actions: sessionActions,
    licenses_available,
    account,
  } = useContext(SessionContext);
  const isOwnerOrManager = isTeamManager || isAccountOwner;
  const { isExpired } = useAccountPlanData({ account });
  const { TEAM_MANAGER }: { TEAM_MANAGER: string } = ROLES;
  const isManager = userRoleIds.includes(TEAM_MANAGER);
  const { manageTeamModal, closeManageTeamModal, openManageTeamModal } = useManageTeam();
  const { inviteToTeamModal, openInviteToTeamModal, closeInviteToTeamModal } = useInviteToTeam();

  if (count < 1) return <EmptyTeamsCard />;

  const handleCreateTeam = () => {
    const handleCloseCreateTeam = (teamData) => {
      if (teamData) {
        closeManageTeamModal();
        sessionActions.updateAccountTeams((prev) => [...prev, teamData]);
        teamsActions.onForceTeamsRequest();
        handleInviteToTeam(teamData);
      }
      sessionActions.refreshAccounts();
      closeManageTeamModal();
    };

    openManageTeamModal({
      team: null,
      existingTeams: teams,
      onClose: handleCloseCreateTeam,
    });
  };

  const handleInviteToTeam = (team: Team | DeepTeams) => {
    const handleInviteFinish = (afterInvite) => {
      if (afterInvite) {
        if (!licenses_available) {
          sessionActions.refreshSession();
        }
        teamsActions.onForceTeamsRequest();
      }
      closeInviteToTeamModal();
    };
    openInviteToTeamModal({
      onClose: handleInviteFinish,
      team: team,
      account: account,
      isInvite: false,
    });
  };

  const teamsItems = teamsIconsArray(teams);

  if (teamsItems.length < 2) teamsItems.push(<img src={AccountDefaultLogo} />);

  return (
    <>
      {!isManager ? (
        <CustomCard>
          <CirclesInfoDisplay elements={teamsItems} count={count - teams.length} />
          <CustomCardContent className="teams-card__content" dashboard={dashboard}>
            <h5 className="teams-card__title">{count} Teams</h5>
            <span className="teams-card__counters">
              <p>Users with team: {usersWithTeam}</p>
              {dashboard && <div className="divider" />}
              <p>Users without team: {pending_without_team} </p>
            </span>
            {dashboard && (
              <div className="teams-card__create-team-btn">
                <Button
                  size="small"
                  icon="Plus"
                  style={{ padding: '0px 25px' }}
                  disabled={isManager || isExpired}
                  onClick={handleCreateTeam}
                >
                  Create team
                </Button>
              </div>
            )}
          </CustomCardContent>
          {isOwnerOrManager && (
            <CustomLink to="/teams">
              <Icon icon="ArrowRight" size={'small'} />
            </CustomLink>
          )}
        </CustomCard>
      ) : (
        <CustomCard>
          <IconTeam iconName={team?.icon?.name} color={team?.icon?.color} size={'large'} />
          <TeamData>
            <h5>{team?.name}</h5>
            <span>
              Users: {team?.active_users_count + team?.pending_users_count || 0}
              {team?.pending_users_count > 0 && <span> ({team?.pending_users_count} pending)</span>}
            </span>
            <RolePill>
              <span>
                <p>Team manager</p>
              </span>
            </RolePill>
          </TeamData>
          <CustomLink to={'/team/' + team?.id}>
            <Icon icon="ArrowRight" size={'small'} />
          </CustomLink>
        </CustomCard>
      )}
      {!isManager && manageTeamModal}
      {inviteToTeamModal}
    </>
  );
};

export default AccountTeamsCard;
