import { isMobile } from 'react-device-detect';
import { Account } from 'contexts/accounts/types';
import NotesProvider from 'contexts/notes';
import AccountNotesContent from './AccountNotesContent';
import AccountNotesContentMobile from './AccountNotesContentMobile';

interface IAccountNotesProps {
  account: Account;
}

const AccountNotes = ({ account }: IAccountNotesProps): JSX.Element => {
  return (
    <>
      <NotesProvider>
        {!isMobile ? (
          <AccountNotesContent account={account} />
        ) : (
          <AccountNotesContentMobile account={account} />
        )}
      </NotesProvider>
    </>
  );
};

export default AccountNotes;
