export const SEARCH = {
  CONTEXTS: ['Courses', 'Learning Paths', 'Videos', 'Labs'],
  FEATURES: [
    'is_popular',
    'is_trending',
    'has_quizzes',
    'has_labs',
    'has_cc',
    'has_sample_content',
    'has_certifications',
  ],
  FILTER_MAP: {
    'learning-path': 'Learning Path',
    content_type: 'Content Type',
    feature: 'Features',
    course: 'Course',
    video: 'Video',
    lab: 'Lab',
    has_quizzes: 'Has Quizzes',
    has_labs: 'Hands-on content',
    is_popular: 'Popular',
    is_trending: 'Trending',
    has_cc: 'Has closed captioning',
    'learning_areas.name': 'Learning Areas',
    'categories.name': 'Categories',
    'instructors.name': 'Instructors',
    'instructor.name': 'Instructor',
    difficulty: 'Difficulties',
    has_sample_content: 'Included with Starter Pass',
    has_certifications: 'Has certification',
  },
  AVAILABLE_FACETS: {
    CONTENT: [
      'content_type',
      'learning_areas.name',
      'categories.name',
      'instructors.name',
      'difficulty',
      'has_labs',
      'has_cc',
      'has_certifications',
      'is_lab_experience',
    ],
    Courses: [
      'learning_areas.name',
      'categories.name',
      'instructors.name',
      'difficulty',
      'is_popular',
      'is_trending',
      'has_quizzes',
      'has_labs',
      'has_cc',
      'has_sample_content',
    ],
    'Learning Paths': [
      'learning_areas.name',
      'categories.name',
      'instructors.name',
      'difficulty',
      'has_certifications',
    ],
    Videos: ['instructor.name', 'learning_areas.name', 'categories.name', 'difficulty'],
    Labs: ['instructor.name', 'learning_areas.name', 'categories.name', 'difficulty'],
  },
  ALGOLIA_KEYS: {
    appKey: process.env.REACT_APP_ALGOLIA_APP_KEY,
    appId: process.env.REACT_APP_ALGOLIA_APP_ID,
    searchIndex: process.env.REACT_APP_ALGOLIA_SEARCH_INDEX,
    searchIndexNameAsc: process.env.REACT_APP_ALGOLIA_SEARCH_INDEX_NAME_ASC,
    searchIndexNameDesc: process.env.REACT_APP_ALGOLIA_SEARCH_INDEX_NAME_DESC,
    coursesIndex: process.env.REACT_APP_ALGOLIA_COURSES_INDEX,
    learningPathsIndex: process.env.REACT_APP_ALGOLIA_PLAYLISTS_INDEX,
    videosIndex: process.env.REACT_APP_ALGOLIA_VIDEOS_INDEX,
    labsIndex: process.env.REACT_APP_ALGOLIA_LABS_INDEX,
  },
  PARENTS: {
    course: 'Courses',
    learning_path: 'Learning Paths',
    videos: 'Videos',
    lab: 'Labs',
  },
};
