import { isMobile } from 'react-device-detect';
import { useContext, useState } from 'react';
import Checkbox from 'components/checkbox';
import { HeaderLoading, Spinner } from 'components/loadings';
import { ActionModal } from 'components/modal';
import Notification from 'components/notification';
import SearchEmptyState from 'components/searchEmptyState';
import { Pagination, Row, Top } from 'components/Table';
import { ACC_OWNER, END_USER } from 'constants/roles';
import { SessionContext } from 'contexts/session';
import { UsersContext } from 'contexts/users';
import {
  assignAddonLicense,
  resendUserInvites,
  setTeamManager,
  setUserLicense,
  setUserRole,
  setUserTeams,
  unassignAddonLicense,
} from './actions';
import { UserTeamsPayload } from './types';
import { IGenericUsersTableProps, getUsersTableConfig } from './helper';
import StaffUserRow from './StaffUserRow';
import { Subcolumn, UserTableContainer } from './styles';
import UserRow, { ContainerDesktop } from './UserRow';
import { SwitchActions, SwitchLicenseActions } from './types';
import Tooltip from 'components/tooltip';
import Icon from 'components/icons';

const GenericUsersTable = ({ accountPage, refreshData }: IGenericUsersTableProps): JSX.Element => {
  const { table, actions, selectedRows, loading, fetching, filters } = useContext(UsersContext);
  const {
    teams: accountTeams,
    updateLicensesAvailables,
    isImpersonated,
    isStaff,
  } = useContext(SessionContext);

  const usersTableConfig = getUsersTableConfig(isStaff, accountPage, filters.active === 'true');

  const [actionsFetching, setActionsFetching] = useState(false);
  const [actionModalOpen, setActionModalOpen] = useState(false);
  const [actionModalOptions, setActionModalOptions] = useState({
    title: '',
    description: '',
    buttons: [],
    onCancel: null,
  });

  const countRows = selectedRows?.length || 0;
  const selectedAllRows = Boolean(countRows > 0 && selectedRows?.length === table?.rows?.length);
  const intermediateSelection = Boolean(
    countRows > 0 && selectedRows?.length !== table?.rows?.length
  );

  const handleSelectRow = (id: string) => actions?.onSelectRow(id);
  const handleSelectAllRows = () => actions?.onSelectAllRows();

  const handleSwitchLicense = async ({ ids, checked }: SwitchLicenseActions) => {
    setActionsFetching(true);
    const response = await setUserLicense({ ids, license: checked });
    setActionsFetching(false);
    if (!(response instanceof Error) && response.result) {
      actions.onRefreshData();
      updateLicensesAvailables(checked, true).then(() => {
        Notification({ text: 'License updated', type: 'success' });
      });
      actions.onRefreshData();
    } else {
      Notification({ text: 'License couldn’t be updated', type: 'error' });
    }
  };

  const handleSwitchAddon = async (
    assigned_to?: number | string,
    addon?: string,
    license_addon_id?: string
  ) => {
    setActionsFetching(true);
    const is_assigning = !license_addon_id;
    let response = null;
    if (is_assigning) {
      response = await assignAddonLicense({ assigned_to, addon });
    } else {
      response = await unassignAddonLicense({ license_addon_id });
    }
    setActionsFetching(false);
    if (!(response instanceof Error) && response) {
      actions.onRefreshData();
      Notification({
        text: `Skill Dive license ${is_assigning ? 'assigned' : 'removed'}`,
        type: 'success',
      });
    } else {
      Notification({
        text: `Skill Dive license couldn’t be ${is_assigning ? 'assigned' : 'removed'}`,
        type: 'error',
      });
    }
  };

  const handleSelectTeam = async (id: string, newTeams: UserTeamsPayload[]) => {
    setActionsFetching(true);
    const response = await setUserTeams({ user_invite_id: id, teams: newTeams });
    setActionsFetching(false);
    if (!(response instanceof Error) && response.result) {
      actions.onRefreshData();
      Notification({ text: 'Teams updated', type: 'success' });
    } else {
      Notification({ text: 'Teams couldn’t be updated', type: 'error' });
    }
  };

  const handleSwitchManager = async (user_invite_id: string, teamsData: UserTeamsPayload[]) => {
    setActionsFetching(true);
    const response = await setTeamManager({
      user_invite_id: user_invite_id,
      teams: teamsData,
    });
    setActionsFetching(false);
    if (!(response instanceof Error) && response.result) {
      actions.onRefreshData();
      Notification({ text: 'Role updated', type: 'success' });
    } else {
      Notification({ text: 'Role couldn’t be updated', type: 'error' });
    }
  };

  const handleSwitchOwner = async ({ id, checked }: SwitchActions) => {
    setActionsFetching(true);
    const response = await setUserRole({ id, role: checked ? ACC_OWNER : END_USER });
    setActionsFetching(false);
    if (!(response instanceof Error) && response.result) {
      actions.onRefreshData();
      Notification({ text: 'Role updated', type: 'success' });
      accountPage && refreshData();
    } else {
      Notification({ text: 'Role couldn’t be updated', type: 'error' });
    }
  };

  const handleCancelInvitation = (ids: string[], name: string[]) => {
    CancelInviteModal({
      name: name[0],
      onAccept: () => actions?.onRemoveTableItem(ids, 'Invitation successfully cancelled'),
    });
  };

  const CancelInviteModal = ({ name, onAccept }) => {
    setActionModal({
      title: 'Cancel Invite',
      description: `Are you sure you want to cancel ${name} invite?`,
      onCancel: () => {
        setActionModalOpen(false);
      },
      buttons: [
        { label: 'Cancel', type: 'secondary', onClick: () => setActionModalOpen(false) },
        {
          label: 'Remove Invite',
          type: 'primary',
          onClick: () => {
            onAccept();
            setActionModalOpen(false);
          },
        },
      ],
    });
  };

  const handleRemoveActiveUser = (ids: string[], name: string[]) => {
    RemoveUserModal({
      name: name[0],
      onAccept: () => actions?.onRemoveTableItem(ids, 'Successfully removed user'),
    });
  };

  const RemoveUserModal = ({ name, onAccept }) => {
    setActionModal({
      title: 'Remove User',
      description: `Are you sure you want to remove ${name}?`,
      onCancel: () => {
        setActionModalOpen(false);
      },
      buttons: [
        { label: 'Cancel', type: 'secondary', onClick: () => setActionModalOpen(false) },
        {
          label: 'Remove user',
          type: 'primary',
          onClick: () => {
            onAccept();
            setActionModalOpen(false);
          },
        },
      ],
    });
  };

  const handleResendInvitation = async (ids: string[]) => {
    setActionsFetching(true);
    const response = await resendUserInvites({ ids });
    setActionsFetching(false);
    if (!(response instanceof Error) && response.result) {
      Notification({ text: 'Invitation successfully resent', type: 'success' });
    } else {
      Notification({ text: 'Invitation couldn’t be updated', type: 'error' });
    }
  };

  const setActionModal = ({ title, description, buttons, onCancel }) => {
    setActionModalOpen(true);
    setActionModalOptions({
      title: title,
      description: description,
      buttons: buttons,
      onCancel: onCancel,
    });
  };

  if (table?.rows?.length < 1)
    return (
      <>
        {fetching ? (
          <Spinner active={fetching} top={isMobile ? '270px' : '240px'} />
        ) : (
          <SearchEmptyState />
        )}
      </>
    );
  return (
    <>
      <Spinner active={fetching} top={isMobile ? '270px' : '240px'} />
      <UserTableContainer lock={fetching || actionsFetching}>
        <Row
          columns={usersTableConfig.columnsTableConfig}
          transparent={isMobile}
          topSelect={isMobile}
          top={true}
          sticky
          impersonated={isImpersonated}
        >
          <Top topSelect={isMobile}>
            <Checkbox
              checked={selectedAllRows}
              indeterminate={intermediateSelection}
              id="allUserSelected"
              onChange={handleSelectAllRows}
              label={isMobile ? `${countRows} users selected` : undefined}
              controlled
              darkBackground={isMobile}
              style={{ display: 'inline-flex', opacity: '0.6', marginLeft: isMobile ? '10px' : '' }}
            />
          </Top>
          {usersTableConfig.columns.map((column, index) => (
            <ContainerDesktop key={index}>
              <Top hasSubcolumn={column.subcolumns !== null && filters.active === 'true'}>
                <span>{column.name}</span>
                {column.tooltip && (
                  <Tooltip
                    placement="top"
                    title={column.tooltip}
                    overlayInnerStyle={{ textAlign: 'center' }}
                  >
                    <div style={{ marginLeft: '4px' }}>
                      <Icon
                        data-testid="info-icon"
                        icon="Info"
                        fill={'var(--lilac)'}
                        size="smaller"
                      />
                    </div>
                  </Tooltip>
                )}

                {filters.active === 'true' && column.subcolumns && (
                  <Subcolumn>
                    {column.subcolumns?.map((subcolumn, index) => (
                      <span key={index}>{subcolumn}</span>
                    ))}
                  </Subcolumn>
                )}
              </Top>
            </ContainerDesktop>
          ))}
        </Row>
        {table?.rows?.map((row) =>
          !isStaff ? (
            <UserRow
              key={row.id}
              onSelectTeam={handleSelectTeam}
              onSwitchManager={handleSwitchManager}
              onSwitchLicense={handleSwitchLicense}
              onSelectRow={handleSelectRow}
              onCancelInvitation={handleCancelInvitation}
              onResendInvitation={handleResendInvitation}
              onRemoveUser={handleRemoveActiveUser}
              onSwitchAddon={handleSwitchAddon}
              rowSelected={selectedRows?.includes(row.id)}
              active={filters.active === 'true'}
              accountTeams={accountTeams}
              row={row}
              tableGridConfig={usersTableConfig.rowTableConfig}
              {...row}
            />
          ) : (
            <StaffUserRow
              key={row.id}
              onSwitchManager={handleSwitchManager}
              onSwitchOwner={handleSwitchOwner}
              onSwitchLicense={handleSwitchLicense}
              onSelectRow={handleSelectRow}
              onRemoveUser={handleRemoveActiveUser}
              onCancelInvitation={handleCancelInvitation}
              onResendInvitation={handleResendInvitation}
              onSwitchAddon={handleSwitchAddon}
              rowSelected={Boolean(selectedRows?.includes(row.id))}
              active={filters.active === 'true'}
              row={row}
              tableGridConfig={usersTableConfig.rowTableConfig}
              accountPage={accountPage}
              {...row}
            />
          )
        )}
        {table.rows && (
          <Pagination
            count={table.count}
            page={parseInt(filters.page)}
            page_size={parseInt(filters.page_size)}
            onPagesClick={(value, name) =>
              actions && actions.onChangeFilters({ name: name, value }, false, true)
            }
          />
        )}
        <ActionModal
          title={actionModalOptions.title}
          description={actionModalOptions.description}
          active={actionModalOpen}
          buttons={actionModalOptions.buttons}
          handleCancel={actionModalOptions.onCancel}
        />
        <HeaderLoading portals={true} active={loading} />
      </UserTableContainer>
    </>
  );
};

export default GenericUsersTable;
