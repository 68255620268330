import { Fragment, useEffect, useState } from 'react';
import { Divider } from 'antd';
import FeatherIcon from 'feather-icons-react';
import Icon from 'components/icons';
import { IconContainer, TeamsMenuItem } from 'components/teamSelect/styles';
import { Team, UserTeam } from 'contexts/teams/types';
import Checkbox from 'components/checkbox';
import { IconTeam } from 'components/teamSelect/IconTeam';
import Button from 'components/button/Button';
import { TeamMobileFooterContainer, TeamSelectorModal } from './styles';

interface TeamsSelectorMobileProps {
  accountTeams: Team[];
  teamsSelected?: UserTeam[];
  onTeamChange(teams: string[]): void;
  withEditBtn?: boolean;
}

const TeamSelectorMobile = ({
  accountTeams,
  teamsSelected,
  onTeamChange,
  withEditBtn = true,
}: TeamsSelectorMobileProps): JSX.Element => {
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [prevSelected, setPrevSelected] = useState([]);

  const handleCancel = () => {
    setSelected(prevSelected);
    setOpenModal(false);
  };

  const handleTeamChangeSubmit = () => {
    onTeamChange(selected);
    setPrevSelected(selected);
    setOpenModal(false);
  };

  const handleTeamSelection = (id: string) => {
    if (id === 'no-team') {
      setSelected([]);
    } else {
      setSelected((prevState) => {
        if (prevState.includes(id)) {
          return prevState.filter((ps) => ps !== id);
        } else {
          return [...prevState, id];
        }
      });
    }
  };

  useEffect(() => {
    if (teamsSelected && teamsSelected.length > 0) {
      const currentTeams = teamsSelected.reduce((acc, team) => {
        acc.push(team.team_id);
        return acc;
      }, []);
      setSelected((prevState) => {
        return [...prevState, ...currentTeams];
      });
      setPrevSelected((prevState) => {
        return [...prevState, ...currentTeams];
      });
    }
  }, []);

  return (
    <>
      {withEditBtn && (
        <div onClick={() => setOpenModal(!openModal)}>
          <FeatherIcon icon={'edit'} size="15" color="#A793F6" style={{ cursor: 'pointer' }} />
        </div>
      )}
      <TeamSelectorModal
        zIndex={9999}
        visible={openModal}
        closable={false}
        onCancel={handleCancel}
        onOk={handleTeamChangeSubmit}
        maskClosable={false}
        bodyStyle={{ borderRadius: '20px' }}
        footer={
          <TeamMobileFooterContainer>
            <Button $secondary={true} onClick={handleCancel}>
              Cancel
            </Button>
            <Button $primary disabled={!selected} onClick={handleTeamChangeSubmit}>
              Update team
            </Button>
          </TeamMobileFooterContainer>
        }
      >
        <div>
          <TeamsMenuItem
            key={'no-team'}
            type={'button'}
            onClick={() => handleTeamSelection('no-team')}
          >
            <Checkbox style={{ marginRight: '10px' }} checked={selected.length === 0} controlled />
            <IconContainer size={'smaller'}>
              <span className="remove-team">
                <Icon icon={'Block'} />
              </span>
            </IconContainer>
            <span className={'teams-label'}>{'Remove Teams'}</span>
          </TeamsMenuItem>
          <Divider style={{ margin: 0, width: 'calc(100% - 10px)', minWidth: 'auto' }} />
          {accountTeams?.map((item) => (
            <Fragment key={item.id}>
              <TeamsMenuItem
                key={item.id}
                type={'button'}
                onClick={() => handleTeamSelection(item.id)}
              >
                <Checkbox
                  style={{ marginRight: '10px' }}
                  checked={selected.length === 0 ? item.id === '' : selected.includes(item.id)}
                  controlled
                />
                <IconTeam iconName={item.icon?.name} color={item.icon?.color} size={'smaller'} />
                <span className={'teams-label'}>{item.name}</span>
              </TeamsMenuItem>
              <Divider style={{ margin: 0, width: 'calc(100% - 10px)', minWidth: 'auto' }} />
            </Fragment>
          ))}
        </div>
      </TeamSelectorModal>
    </>
  );
};

export default TeamSelectorMobile;
