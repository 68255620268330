import { SwitchProps } from 'antd/lib/switch';

import { StyledSwitch, SwitchLabel, SwitchContainer } from './styles';

export interface ISwitchProps extends SwitchProps {
  label?: string;
}

const Switch = ({ label, style, ...props }: ISwitchProps): JSX.Element => {
  return (
    <SwitchContainer style={style}>
      {label && <SwitchLabel>{label}</SwitchLabel>}
      <StyledSwitch {...props} />
    </SwitchContainer>
  );
};

export default Switch;
