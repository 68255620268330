import styled, { css } from 'styled-components';
import { Radio } from 'antd';
import { screenMd } from 'constants/styles/breakpoints';
import 'antd/dist/antd.css';

const radioWidth = css`
  width: 1.75rem;
  height: 1.75rem;
`;

const antdRadio = css`
  ${radioWidth}
  top: 0;
`;

const antdRadioAfter = css`
  border: 1px solid ${({ theme }) => theme.default.colors.dataVisualization.purple.base};
`;

const antRadioInner = css`
  ${radioWidth}
  border: none;
  display: none;
`;

const labelSpan = css`
  color: #1a2535;
  font-weight: 400;
  font-size: 13px;
`;

const antdRadioBackground = css<{ darkBackground: boolean }>`
  background-color: ${({ theme, darkBackground }) =>
    !darkBackground
      ? theme.default.colors.dataVisualization.gray.light
      : theme.default.colors.dataVisualization.gray.dark};
`;

const checkedStyles = css`
  border: 1px solid transparent;
  &:checked {
    background-color: ${({ theme }) => theme.default.colors.dataVisualization.gray.light};
    border-color: ${({ theme }) => theme.default.colors.dataVisualization.gray.light};
    position: relative;
    &:after {
      width: 10px;
      height: 10px;
      content: '';
      position: absolute;
      background-color: ${({ theme }) => theme.default.colors.dataVisualization.purple.base};
      top: 5px;
      left: 5px;
      z-index: 9;
      border-radius: 50%;
    }
  }
`;

const antdRadioInput = css`
  ${radioWidth}
  ${antdRadioBackground}
	background-repeat: no-repeat;
  cursor: pointer;
  opacity: 1;
  appearance: none;
  transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out,
    border 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: 1px solid transparent;
  &:focus-active {
    border: none !important;
    outline: none !important;
  }
  ${screenMd} {
    &:hover {
      ${antdRadioAfter}
    }
  }
  &:disabled {
    ${antdRadioAfter}
    background-color: ${({ theme }) => theme.default.colors.dataVisualization.gray.dark} !important;
    cursor: not-allowed;
    &:hover {
      border-color: transparent !important;
    }
  }
  &:focus-visible {
    outline: none;
  }
`;

export const StyledRadio = styled(Radio)<{ darkBackground?: boolean }>`
  &&& {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 1.5rem;
    cursor: pointer;
    > span:not(.ant-radio) {
      ${labelSpan}
    }
    > .ant-radio {
      ${antdRadio}
      border-radius: 50%;
      > .ant-radio-input {
        border-radius: 50%;
        ${antdRadioInput}
        ${checkedStyles}
      }
      &.ant-radio-checked::after {
        ${antdRadioAfter}
      }
      > .ant-radio-inner {
        ${antRadioInner}
      }
    }
  }
`;

// ${({theme}) =>  theme.default.colors.fonts.dark}
