import { useHistory } from 'react-router-dom';
import { useContext } from 'react';
import Icon from 'components/icons';
import {
  AssignedPlaylistDot,
  AssignedPlaylistItemNameWrapper,
  AssignedPlaylistsItemContainer,
  AssignedPlaylistsItemName,
  AssignedPlaylistsItemProgress,
} from './styles';
import { SessionContext } from 'contexts/session';

interface AssignedPlaylistItemProps {
  playlist: any;
}
const AssignedPlaylistItem = ({ playlist }: AssignedPlaylistItemProps): JSX.Element => {
  const history = useHistory();
  const { isStaff } = useContext(SessionContext);

  const goToPlaylist = (playlistId: string) => {
    history.push(`/playlist/${playlistId}`);
  };

  return (
    <AssignedPlaylistsItemContainer>
      <AssignedPlaylistDot color={playlist?.color} />
      <div style={{ width: '88%' }}>
        <AssignedPlaylistItemNameWrapper>
          <AssignedPlaylistsItemName>{playlist?.name}</AssignedPlaylistsItemName>
          {!isStaff && (
            <Icon
              icon="ArrowRight"
              size={'small'}
              style={{ cursor: 'pointer' }}
              onClick={() => goToPlaylist(playlist?.id)}
            />
          )}
        </AssignedPlaylistItemNameWrapper>
        <AssignedPlaylistsItemProgress>
          {Math.round(playlist?.user_status?.progress * 100)}% completed
        </AssignedPlaylistsItemProgress>
      </div>
    </AssignedPlaylistsItemContainer>
  );
};

export default AssignedPlaylistItem;
