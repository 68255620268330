import { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import AccountsContent from './AccountsContent';
import AccountsProvider from 'contexts/accounts';
import { SessionContext } from 'contexts/session';

const PageContent = styled.div`
  background: '#F2F2F7';
`;

const Accounts = (): JSX.Element => {
  const { isStaff } = useContext(SessionContext);

  return (
    <>
      {!isStaff ? (
        <Redirect to="/users" />
      ) : (
        <AccountsProvider>
          <PageContent>
            <AccountsContent />
          </PageContent>
        </AccountsProvider>
      )}
    </>
  );
};

export default Accounts;
