import { RadioPropTypes } from './types';
import { StyledRadio } from './styles';
// const CheckboxGroup = Checkbox.Group; use for intermediate seleccion on top of the table.
const Radio = ({
  checked,
  disabled,
  onChange,
  label,
  controlled,
  darkBackground,
  ...rest
}: RadioPropTypes): JSX.Element => {
  const checkboxProps = controlled ? { checked: checked } : {};

  return (
    <StyledRadio
      onChange={onChange}
      disabled={disabled}
      {...checkboxProps}
      {...rest}
      darkBackground={darkBackground}
    >
      {label}
    </StyledRadio>
  );
};

export default Radio;
