import { useState } from 'react';
import { Account } from 'contexts/accounts/types';
import { DeepTeams, Team } from 'contexts/teams/types';
import InviteToTeam from '../InviteToTeam';

interface IInviteToTeamHook {
  inviteToTeamModal: JSX.Element;
  closeInviteToTeamModal(): void;
  openInviteToTeamModal: (params: OpenInviteToTeamModal) => void;
}

interface OpenInviteToTeamModal {
  onClose: (data: boolean) => void;
  team: Team | DeepTeams | null;
  account: Account;
  isInvite: boolean;
}

const useInviteToTeam = (): IInviteToTeamHook => {
  const [modal, setModal] = useState<JSX.Element>(null);

  const handleOpenModal = ({ onClose, team, account, isInvite }) => {
    setModal(
      <InviteToTeam
        onClose={onClose}
        team={team}
        account={account}
        isVisible={true}
        isInvite={isInvite}
      />
    );
  };

  return {
    inviteToTeamModal: modal,
    closeInviteToTeamModal: () => setModal(null),
    openInviteToTeamModal: handleOpenModal,
  };
};

export default useInviteToTeam;
