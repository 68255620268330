import styled from 'styled-components';

export const AvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 20px 10px 20px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
`;

export const UserInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  align-items: flex-start;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  flex-shrink: 2;
  padding-left: 10px;
  box-sizing: border-box;
`;

export const Name = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  width: 100%;
  color: ${({ theme }) => theme.default.colors.palette.Secondary_LightBlueGray};
`;

export const Email = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  width: 100%;
  color: ${({ theme }) => theme.default.colors.palette.Secondary_LightBlueGray};
`;

export const Divider = styled.span`
  display: flex;
  width: 100%;
  padding: 15px 20px 10px 20px;
  box-sizing: border-box;
  &:before {
    content: ' ';
    display: inline-block;
    height: 1px;
    width: 100%;
    background-color: ${({ theme }) => theme.default.colors.palette.Business_LightThemeGreyDarker};
    box-sizing: border-box;
  }
`;
