export interface SsoDataState {
  companyName?: string;
  providerName?: string;
  samlIssuerId?: string;
  loginUrl?: string;
  certificate?: string;
  givenName?: string;
  familyName?: string;
  email?: string;
}

export interface IAccountSsoProviderError {
  key: string;
  msg: string;
}

export interface FormReducer {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}
export const formDataReducer = (state: SsoDataState, action: FormReducer): SsoDataState => {
  switch (action.type) {
    case 'SET_COMPANY_NAME':
      return { ...state, companyName: action.value };
    case 'SET_PROVIDER_NAME':
      return { ...state, providerName: action.value };
    case 'SET_SAML_ISSUER_ID':
      return {
        ...state,
        samlIssuerId: action.value,
      };
    case 'SET_LOGIN_URL':
      return {
        ...state,
        loginUrl: action.value,
      };
    case 'SET_CERTIFICATE':
      return { ...state, certificate: action.value };
    case 'SET_GIVEN_NAME':
      return { ...state, givenName: action.value };
    case 'SET_FAMILY_NAME':
      return { ...state, familyName: action.value };
    case 'SET_EMAIL':
      return { ...state, email: action.value };
    default:
      return state;
  }
};

export const providerOptions = [
  { label: 'Azure ID', value: 'Azure ID' },
  { label: 'Okta', value: 'Okta' },
  { label: 'Auth0', value: 'Auth0' },
];

export const SsoFormFields = [
  {
    key: 'SET_COMPANY_NAME',
    name: 'companyName',
    type: 'input',
    label: 'Company Name *',
    placeholder: 'Enter your company name',
  },
  {
    key: 'SET_PROVIDER_NAME',
    name: 'providerName',
    type: 'select',
    label: 'Provider Name *',
    placeholder: 'Select one',
    options: providerOptions,
  },
  {
    key: 'SET_SAML_ISSUER_ID',
    name: 'samlIssuerId',
    type: 'input',
    label: 'SAML Issuer ID *',
    placeholder: 'e.g. https//saml.recurly.com',
  },
  {
    key: 'SET_LOGIN_URL',
    name: 'loginUrl',
    type: 'input',
    label: 'Login URL *',
    placeholder: 'e.g. https//saml.recurly.com',
    hint: 'This is where we will direct users login if they are required to use single sign-on to login.',
  },
  {
    key: 'SET_CERTIFICATE',
    name: 'certificate',
    type: 'textarea',
    label: 'Certificate *',
    placeholder: null,
    hint: 'This is your Identity Provider`s public certificate used to validate the signature.',
  },
  {
    key: 'SET_GIVEN_NAME',
    name: 'givenName',
    type: 'input',
    label: 'Attribute Names *',
    placeholder: 'Given Name',
  },
  {
    key: 'SET_FAMILY_NAME',
    name: 'familyName',
    type: 'input',
    placeholder: 'Family Name',
  },
  { key: 'SET_EMAIL', name: 'email', type: 'input', placeholder: 'Email' },
];
