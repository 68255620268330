import { isMobile } from 'react-device-detect';
import ActivityLogProvider from 'contexts/activityLog';
import ActivityLogContent from './ActivityLogContent';
import ActivityLogContentMobile from './ActivityLogContentMobile';

const ActivityLog = (): JSX.Element => {
  return (
    <ActivityLogProvider>
      {!isMobile ? <ActivityLogContent /> : <ActivityLogContentMobile />}
    </ActivityLogProvider>
  );
};

export default ActivityLog;
