import { AxiosResponse } from 'axios';
import { PricingPlan } from 'contexts/pricingPlans/types';
import { config } from 'config/config.local';
import { BUSINESS_PYTHON } from './axios';

interface GetPricingPlans {
  results: PricingPlan[];
  count: number;
}

export const getPricingPlans = (): Promise<AxiosResponse<GetPricingPlans>> => {
  return BUSINESS_PYTHON.get(config.routes.pricingPlans.getPricingPlans);
};
