import styled from 'styled-components';
import { screenMd } from 'constants/styles/breakpoints';
import { IPlaylist, IPlaylistUserListItemResponse } from 'contexts/playlists/types';
import {
  EmptySonarStateContainer,
  NoUsersTitle,
  SonarEmptyStateSubtitle,
  SonarEmptyStateTitle,
} from './styles';
import UsersTable from '../UsersTable';
import { Spinner } from 'components/loadings';
import AssignPlaylistButton from 'pages/Playlists/PlaylistDetail/AssignPlaylistButton';

export const Container = styled.div<{ lock: boolean }>`
  position: relative;
  width: 100%;
  padding-bottom: 140px;
  row-gap: 20px;
  column-gap: 20px;
  grid-template-columns: auto auto auto;
  justify-content: start;

  ${screenMd} {
    padding-bottom: 0;
  }

  ${({ lock }) =>
    lock &&
    `
		opacity: 0.4;
		pointer-events: none;
	`}
`;

interface IUsersAssignedParams {
  lock: boolean;
  fetchingUsers: boolean;
  playlist: IPlaylist;
  playlistUsers: IPlaylistUserListItemResponse[];
  callRemoveUser: (item: IPlaylistUserListItemResponse) => void;
  isSonar: boolean;
  setFetching: (fetching: boolean) => void;
  refreshPlaylist: () => void;
}

const EmptyState = (props: any): JSX.Element => {
  const { isSonar, playlist, setFetching, refreshPlaylist } = props;
  const username = `${playlist?.created_for?.first_name} ${playlist?.created_for?.last_name}`;
  return isSonar ? (
    <EmptySonarStateContainer>
      <SonarEmptyStateTitle>Ready to assign playlist?</SonarEmptyStateTitle>
      <SonarEmptyStateSubtitle>
        This playlist was created for <strong>{username}</strong>, but it hasn’t been assigned yet.
        Assign it now to send tailored training recommendations.
      </SonarEmptyStateSubtitle>
      <AssignPlaylistButton
        playlist={playlist}
        setFetching={setFetching}
        refreshPlaylist={refreshPlaylist}
      />
    </EmptySonarStateContainer>
  ) : (
    <NoUsersTitle>You haven’t added any users yet</NoUsersTitle>
  );
};

const UsersAssigned = ({
  playlistUsers,
  fetchingUsers,
  callRemoveUser,
  playlist,
  isSonar,
  setFetching,
  refreshPlaylist,
}: IUsersAssignedParams): JSX.Element => {
  return (
    <Container lock={fetchingUsers}>
      {fetchingUsers ? (
        <Spinner active={fetchingUsers} />
      ) : playlistUsers.length > 0 ? (
        <UsersTable
          fetching={fetchingUsers}
          lock={fetchingUsers}
          playlistUsers={playlistUsers}
          playlist={playlist}
          removeUser={callRemoveUser}
          isSonar={isSonar}
        />
      ) : (
        <EmptyState
          isSonar={isSonar}
          playlist={playlist}
          setFetching={setFetching}
          refreshPlaylist={refreshPlaylist}
        />
      )}
    </Container>
  );
};

export default UsersAssigned;
