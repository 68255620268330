import { FC } from 'react';
import { CustomCard } from '../../AccountUsersCard/AccountUsersCard';
import { Account } from 'contexts/accounts/types';
import { Title } from '../../AccountsHeader/styles';
import Switch from 'components/switch';
import Button from 'components/button';
import { RoundedTag } from '../../AccountSsoCard/styles';
import { ToggleContainer, ToggleSwitchWrapper, ToggleWrapper } from '../styles';

interface IAccountSsoStaffCard {
  account: Account;
  ssoToggled(value: boolean): void;
  ssoAccownersReminder(): void;
}

const AccountSsoStaffCard: FC<IAccountSsoStaffCard> = ({
  account,
  ssoToggled,
  ssoAccownersReminder,
}) => {
  const renderTag = () => {
    if (account?.sso_enabled) {
      return account?.sso_configured ? (
        <RoundedTag color="#49E0B3">Configured</RoundedTag>
      ) : (
        <RoundedTag color="#f8b369">Not configured yet</RoundedTag>
      );
    }
  };

  return (
    <CustomCard dashboard={true}>
      <ToggleContainer>
        <ToggleWrapper>
          <Title>SSO/SAML</Title>
          <ToggleSwitchWrapper>
            {renderTag()}
            <Switch
              checked={account?.sso_enabled}
              disabled={account?.sso_enabled && account?.sso_configured}
              onChange={(e) => ssoToggled(e)}
              style={{ marginRight: '10px' }}
            />
          </ToggleSwitchWrapper>
        </ToggleWrapper>
        {account?.sso_enabled && !account?.sso_configured && (
          <div className="mt-5">
            <Button
              $secondary
              size="small"
              style={{ padding: '0px 15px' }}
              onClick={() => ssoAccownersReminder()}
            >
              Send a reminder to the account owner
            </Button>
          </div>
        )}
      </ToggleContainer>
    </CustomCard>
  );
};

export default AccountSsoStaffCard;
