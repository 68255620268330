import styled from 'styled-components';

export const StickyFooterContainer = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1em;
  position: sticky;
  top: 100vh;
  height: 76px;
  align-items: center;
  grid-gap: 15px;
  .settings-bottom-button {
    width: 50% !important;
  }
`;

export const Litle = styled.div<{ noMargin?: boolean; noPadding?: boolean; width?: string }>`
  ${({ noMargin }) => (noMargin ? 'margin-bottom: 0px;' : 'margin-bottom: 3px')};
  ${({ noPadding }) => (noPadding ? 'padding: 0px;' : 'padding: 3px;')};
  ${({ width }) => (width ? `width: ${width}%;` : 'width: 100%;')};
`;

export const Content = styled.div<{ noMargin?: boolean; noPadding?: boolean; flat?: boolean }>`
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0px 5px 18px rgba(0, 0, 0, 0.1);
  ${({ flat }) =>
    flat
      ? `
    background: none;
    box-shadow: none;
    `
      : `
    background-color: #fff;
    box-shadow: 0px 5px 18px rgba(0, 0, 0, 0.1);
    `};
  ${({ noMargin }) => (noMargin ? 'margin-bottom: 0px;' : 'margin-bottom: 3px')};
  ${({ noPadding }) => (noPadding ? 'padding: 0px;' : 'padding: 3px')};
`;

export const TitlesContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
