import { format } from 'date-fns';
import { useContext } from 'react';
import { ACTION_TYPES, ActivityLog } from 'contexts/activityLog/types';
import {
  ActivityLogItemRow,
  ItemDateContainer,
  ItemDateLabel,
  ItemTimeLabel,
  TimelineDot,
  TimelineTail,
} from '../styles';
import { renderActivityLogByAction } from './utils';

import { SessionContext } from 'contexts/session';

interface IActivityLogItemProps {
  activity: ActivityLog;
}

const ActivityLogItem = ({ activity }: IActivityLogItemProps): JSX.Element => {
  const { isStaff } = useContext(SessionContext);

  // Account owners accepted invites will only shown for staff users
  const canRenderInviteAccept = (item: ActivityLog) => {
    if (item.action === ACTION_TYPES.INVITE_ACCEPT && item.metadata?.user_invites) {
      if (!isStaff) return item.metadata?.user_invites[0].role !== 'Account Owner';
      return true;
    }
    return true;
  };

  return (
    <div>
      {canRenderInviteAccept(activity) && (
        <ActivityLogItemRow>
          <ItemDateContainer>
            <ItemDateLabel>{format(new Date(activity.created_at), 'MM/dd/yy')}</ItemDateLabel>
            <ItemTimeLabel>{format(new Date(activity.created_at), 'HH:mm')}</ItemTimeLabel>
          </ItemDateContainer>
          <div>
            <TimelineDot />
            <TimelineTail />
          </div>
          <div>{renderActivityLogByAction(activity, isStaff)}</div>
        </ActivityLogItemRow>
      )}
    </div>
  );
};

export default ActivityLogItem;
