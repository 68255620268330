import { FC } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import Button from 'components/button';
import InputSelect from 'components/inputs/inputSelect';
import { Input, Label, ModalContent, ModalSection, Textarea } from 'components/modal/styles';
import { FormReducer, SsoDataState, SsoFormFields } from './helper';
import { IAccountSsoProviderError } from './helper';

const CustomModalSection = styled(ModalSection)`
  margin-top: 0;
  margin-bottom: 20px;
`;

interface ISsoProviderFormProps {
  errors: IAccountSsoProviderError[];
  formData: SsoDataState;
  loading: boolean;
  onFieldChange(data: FormReducer): void;
  onCancel(): void;
  onSubmit(): void;
}

const SsoProviderForm: FC<ISsoProviderFormProps> = ({
  errors,
  formData,
  loading,
  onFieldChange,
  onCancel,
  onSubmit,
}) => {
  return (
    <ModalContent style={{ padding: '0px' }}>
      {SsoFormFields.map((field) => (
        <CustomModalSection key={field.key}>
          {field.label && <Label>{field.label}</Label>}
          {field.type === 'input' && (
            <Input
              error={errors.some((e) => e.key === field.key)}
              placeholder={field.placeholder}
              value={formData[field.name]}
              onChange={(e) =>
                onFieldChange({
                  type: field.key,
                  value: e.currentTarget.value,
                })
              }
            />
          )}
          {field.type === 'select' && (
            <InputSelect
              error={errors.some((e) => e.key === field.key)}
              value={formData[field.name]}
              placeHolder={field.placeholder}
              options={field.options}
              maxDropdownHeight={'500px'}
              onChange={(e) =>
                onFieldChange({
                  type: field.key,
                  value: e,
                })
              }
            />
          )}
          {field.type === 'textarea' && (
            <Textarea
              error={errors.some((e) => e.key === field.key)}
              value={formData[field.name]}
              onChange={(e) =>
                onFieldChange({
                  type: field.key,
                  value: e.currentTarget.value,
                })
              }
            />
          )}
          <div className="d-grid">
            {errors.some((e) => e.key === field.key) && (
              <small style={{ color: 'red' }}>{errors.find((e) => e.key === field.key).msg}</small>
            )}
            {field.hint && <small>{field.hint}</small>}
          </div>
        </CustomModalSection>
      ))}
      <CustomModalSection className="d-flex">
        <Button
          $secondary
          disabled={loading}
          style={{ margin: isMobile ? 'auto 10px 0 auto' : '0 0 0 auto' }}
          icon={'Cancel2'}
          onClick={onCancel}
        >
          {'Cancel'}
        </Button>
        <Button
          $primary
          disabled={loading}
          style={{ margin: isMobile ? 'auto 10px 0 auto' : '0 0 0 auto' }}
          icon={'Save'}
          onClick={onSubmit}
        >
          {'Save and publish'}
        </Button>
      </CustomModalSection>
    </ModalContent>
  );
};

export default SsoProviderForm;
