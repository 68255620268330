import './statics.scss';
import Button from 'components/button';
import { INE_URL } from 'constants/domains';
import { durationFromSecs } from 'utils/helpers';
import { HideOnMobile } from './styles';

interface IPreviewData {
  objectID: string;
  slug: string;
  description: string;
  difficulty: string;
  duration: number;
  instructors: { name: string }[];
  summary: {
    total_exercises: number;
    total_groups: number;
    total_iframes: number;
    total_labs: number;
    total_quizzes: number;
    total_videos: number;
  };
}

const PreviewCourse = (props: { data: IPreviewData }): JSX.Element => {
  const { objectID, description, difficulty, duration, instructors, slug, summary } = props.data;
  const { total_exercises, total_iframes, total_labs, total_quizzes, total_videos } = summary;

  const handleDetails = () => {
    window.open(`${INE_URL}/course/${slug}/${objectID}`);
  };

  return (
    <div className="preview-tooltip">
      <p className="preview-tooltip__description">
        {description.length > 600 ? description.slice(0, 600) + '...' : description}
      </p>

      <div className="preview-tooltip__info-container">
        <div className="preview-tooltip__info-row">
          <div className="preview-tooltip__info-column">DIFFICULTY</div>
          <div className="preview-tooltip__info-column preview-tooltip__text-capitalize">
            {difficulty}
          </div>
        </div>
        <div className="preview-tooltip__info-row">
          <div className="preview-tooltip__info-column">DURATION</div>
          <div className="preview-tooltip__info-column">
            {duration ? durationFromSecs(duration, 'minutes') : 0}
          </div>
        </div>
        {instructors.length > 0 && (
          <div className="preview-tooltip__info-row">
            <div className="preview-tooltip__info-column">INSTRUCTOR</div>
            <div className="preview-tooltip__info-column">{instructors[0]?.name}</div>
          </div>
        )}
        <HideOnMobile>
          <div className="preview-tooltip__info-row">
            <div className="preview-tooltip__info-column">ACTIVITIES</div>
            <div className="preview-tooltip__info-row">
              {total_iframes && (
                <div className="preview-tooltip__activity-amount-tag">SLIDES:{total_iframes}</div>
              )}
              <div className="preview-tooltip__activity-amount-tag">VIDEOS:{total_videos}</div>
              <div className="preview-tooltip__activity-amount-tag">QUIZZES:{total_quizzes}</div>
              <div className="preview-tooltip__activity-amount-tag">LABS:{total_labs}</div>
              <div className="preview-tooltip__activity-amount-tag">
                EXERCISES:{total_exercises}
              </div>
            </div>
          </div>
        </HideOnMobile>
      </div>

      <div className="preview-tooltip__button-container">
        <Button icon="ExternalLink" $secondary size="small" onClick={handleDetails}>
          Preview course
        </Button>
      </div>
    </div>
  );
};

export default PreviewCourse;
