import { Space } from 'antd';
import NumericInput from 'components/inputs/inputNumber';
import { Label } from 'components/modal/styles';
import Checkbox from 'components/checkbox/Checkbox';
import { MAX_LICENSES_ACCOUNT } from 'constants/licenses';

interface AccountLicensesProps {
  onChange(value: number): void;
  value: number;
  minValue?: number;
  maxValue?: number;
  error?: boolean;
  unlimited?: boolean;
}

const AccountLicenses: React.FC<AccountLicensesProps> = ({
  onChange,
  value,
  minValue = 0,
  maxValue = MAX_LICENSES_ACCOUNT,
  error,
  unlimited,
}) => {
  const handleUnlimited = (e) => onChange(e.target.checked ? null : minValue);

  return (
    <Space direction="vertical">
      <Label>Licenses</Label>
      <Space size={10}>
        <NumericInput
          error={error}
          unlimited={unlimited}
          minValue={minValue}
          maxValue={maxValue}
          value={value}
          onChange={onChange}
          placeholder={'0'}
        />
        <Checkbox onChange={handleUnlimited} defaultChecked={unlimited} />
        <Label style={{ margin: 0 }}>Unlimited licenses</Label>
      </Space>
    </Space>
  );
};

export default AccountLicenses;
