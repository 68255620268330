import { useContext } from 'react';
import { FilterProps } from './types';
import { UsersContext } from 'contexts/users';
import Tooltip from 'components/tooltip';
import Icon from 'components/icons';

const UsersAsideFiltersItem = ({
  label,
  count,
  tooltipTitle,
  filterValue,
  onFilterChange,
  autoApply,
  filter,
}: {
  label: string;
  count: number;
  tooltipTitle: string;
  filterValue: string;
  onFilterChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  autoApply: boolean;
  filter: string | string[];
}): JSX.Element => {
  return (
    <div className="form-check mb-6">
      <input
        className="form-check-input"
        type="radio"
        name="active"
        id={filterValue}
        value={filterValue}
        onChange={onFilterChange}
        defaultChecked={!autoApply ? filter === filterValue : undefined}
        checked={autoApply ? filter === filterValue : undefined}
      />
      <label
        className="form-check-label fw-bold d-flex justify-content-between"
        htmlFor={filterValue}
      >
        <span>{label}</span>
        <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
          <span>{count}</span>
          <Tooltip
            placement="right"
            title={tooltipTitle}
            overlayInnerStyle={{ textAlign: 'center' }}
          >
            <div style={{ position: 'absolute', right: '-18px', marginBottom: '3px' }}>
              <Icon data-testid="info-icon" icon="Info" fill={'var(--lilac)'} size="smaller" />
            </div>
          </Tooltip>
        </div>
      </label>
    </div>
  );
};

const UsersAsideFiltersActive = ({
  autoApply,
  onFilterChange,
  filter,
}: FilterProps): JSX.Element => {
  const { usersCounts } = useContext(UsersContext);

  return (
    <>
      <h3 className="mb-8 fs-1 card-title">Total Users</h3>
      <form className="users-aside-filters mb-8">
        <UsersAsideFiltersItem
          label="Active"
          count={usersCounts?.active_count?.active || 0}
          tooltipTitle="Number of users within the account who have accepted the invite and are part of the account"
          filterValue="true"
          onFilterChange={onFilterChange}
          autoApply={autoApply}
          filter={filter}
        />

        <UsersAsideFiltersItem
          label="Pending"
          count={usersCounts?.active_count?.pending || 0}
          tooltipTitle="Number of users within the account who have not accepted the invite yet"
          filterValue="false"
          onFilterChange={onFilterChange}
          autoApply={autoApply}
          filter={filter}
        />
      </form>
    </>
  );
};

export default UsersAsideFiltersActive;
