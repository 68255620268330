import ContentTypeTag from 'pages/Playlists/components/AddContent/ContentTypeTag';
import {
  GroupContainer,
  GroupContent,
  GroupContentTagWrapper,
  GroupItemTitle,
  GroupRow,
  GroupRowLeftWrapper,
  GroupTitle,
  LearningPathDetailContainer,
} from './styles';

interface ILearningPathDetailProps {
  data: any;
}

const LearningPathDetail = ({ data }: ILearningPathDetailProps): JSX.Element => {
  return (
    <>
      <LearningPathDetailContainer>
        {data?.content.map((group, index) => (
          <GroupContainer key={index}>
            <GroupTitle>{group.name}</GroupTitle>
            <GroupContent>
              {group.content.map((groupItem, index) => (
                <GroupRow key={index}>
                  <GroupRowLeftWrapper>
                    <GroupContentTagWrapper>
                      <ContentTypeTag
                        value={
                          groupItem.content_type === 'iframe' ? 'slides' : groupItem.content_type
                        }
                      />
                    </GroupContentTagWrapper>
                    <GroupItemTitle>{groupItem.name}</GroupItemTitle>
                  </GroupRowLeftWrapper>
                </GroupRow>
              ))}
            </GroupContent>
          </GroupContainer>
        ))}
      </LearningPathDetailContainer>
    </>
  );
};

export default LearningPathDetail;
