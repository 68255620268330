import styled from 'styled-components/macro';
import BasicButton from 'components/button';
import { screenMd } from 'constants/styles/breakpoints';

export const Container = styled.div`
  align-items: center;
  background: transparent;
  border-width: 2px;
  border-color: ${({ theme }) => theme.default.colors.secondaryPallete.purple.light};
  border-style: solid;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  width: 320px;
  margin-bottom: 23px;
  ${screenMd} {
    width: 372px;
    margin-bottom: 23px;
    > button {
      width: 160px;
    }
  }
`;

export const Button = styled(BasicButton)<{ $isActive: boolean }>`
  &&& {
    border-radius: 6px;
    height: 40px;
    width: 186px;
    border-width: 0px;
    &:hover {
      background-color: ${({ $isActive }) => (!$isActive ? 'transparent' : '#FFFFFF')};
      > div {
        color: ${({ $isActive }) => (!$isActive ? '#FFF' : 'none')};
        opacity: 1;
      }
    }
    &:active {
      background: #e9e9ed;
      > div {
        color: #000000 !important;
      }
      box-shadow: 10px 0 0 0 #e9e9ed;
      &:nth-child(2) {
        box-shadow: -10px 0 0 0 #e9e9ed;
      }
    }
    ${({ $isActive }) => `
			background-color: ${!$isActive ? 'inherit' : '#FFFFFF'};
			z-index: ${$isActive ? 3 : 2};
			box-shadow: ${$isActive ? 'none !important' : 'inherit'};
		`}
  }
`;

export const Option = styled.div<{ toggled: boolean }>`
  color: ${({ toggled }) => (toggled ? '#000000' : '#FFFFFF')};
  font-family: 'Helvetica Neue';
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  opacity: 0.8;
`;
