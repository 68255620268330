import { FC, useContext, useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { Title } from '../AccountsHeader/styles';
import { CustomCard } from '../AccountUsersCard/AccountUsersCard';
import { RoundedTag, SsoTagContainer } from './styles';
import Button from 'components/button';
import { INE_CONTACT_SALES, SSO_DOCUMENTATION_URL } from 'constants/settings';
import AccountSsoProviderCreate from '../AccountSsoProviderCreate';
import { Account, SsoProvider } from 'contexts/accounts/types';
import { PLAN_PROFESSIONAL } from 'constants/plans_config';
import { SessionContext } from 'contexts/session';

interface IAccountSsoCard {
  account: Account;
  ssoProvider?: SsoProvider;
  refreshData(): void;
}
const AccountSsoCard: FC<IAccountSsoCard> = ({ account, ssoProvider, refreshData }) => {
  const [showSsoModal, setShowSsoModal] = useState(false);
  const { account: accountLogged } = useContext(SessionContext);

  const renderTag = () => {
    if (account?.sso_enabled) {
      return account?.sso_configured ? (
        <RoundedTag color="#49E0B3">Configured</RoundedTag>
      ) : (
        <RoundedTag color="#f8b369">Not configured yet</RoundedTag>
      );
    }
    return <RoundedTag color="#f8b369">Disabled</RoundedTag>;
  };
  const contactSales = () => {
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.href = `mailto:${INE_CONTACT_SALES},${accountLogged?.sales_contact?.email}`;
    link.click();
    document.body.removeChild(link);
  };

  const downloadDocumentation = () => {
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.href = SSO_DOCUMENTATION_URL;
    link.target = '_blank';
    link.click();
    document.body.removeChild(link);
  };

  const handleSsoModalResponse = (result) => {
    setShowSsoModal(false);
    result === 'ok' && refreshData();
  };
  return (
    <>
      <CustomCard dashboard={true}>
        <div>
          <div className="d-flex">
            <Title>SSO/SAML</Title>
            <SsoTagContainer>
              {renderTag()}
              {account?.sso_enabled && account?.sso_configured && (
                <FeatherIcon
                  icon={'edit'}
                  style={{
                    width: '15px',
                    marginRight: '10px',
                    color: '#A793F6',
                    cursor: 'pointer',
                  }}
                  onClick={() => setShowSsoModal(true)}
                />
              )}
            </SsoTagContainer>
          </div>
          <div className="mt-2 d-grid mb-3">
            {!account?.sso_enabled && (
              <span className="fw-bolder mb-1">Upgrade for advanced security, SAML & SCIM</span>
            )}
            <span>
              {account?.sso_enabled
                ? account?.sso_configured
                  ? 'Single Sign-on is enabled to your account.'
                  : 'Single Sign-on is enabled but you haven’t configured it yet.'
                : account?.pricing_plan === PLAN_PROFESSIONAL
                ? 'Upgrade to the Enterprise Plan for advanced security settings, and SAML single sign-on.'
                : 'SSO/SAML is an add-on enabled by request. Contact us to learn more and enable it for your account.'}
            </span>
          </div>
          {!account?.sso_enabled ? (
            <Button size="small" style={{ padding: '0px 15px' }} onClick={() => contactSales()}>
              Contact Sales
            </Button>
          ) : account?.sso_configured ? (
            <Button
              size="small"
              icon={'Download'}
              style={{ padding: '0px 15px' }}
              onClick={() => downloadDocumentation()}
            >
              Download Documentation
            </Button>
          ) : (
            <Button
              size="small"
              style={{ padding: '0px 15px' }}
              onClick={() => setShowSsoModal(true)}
            >
              Configure now
            </Button>
          )}
        </div>
      </CustomCard>
      {showSsoModal && (
        <AccountSsoProviderCreate
          isModalOpen={showSsoModal}
          ssoProvider={ssoProvider}
          accountId={account?.id}
          onClose={handleSsoModalResponse}
        />
      )}
    </>
  );
};

export default AccountSsoCard;
