import { useContext, useEffect, useState } from 'react';
import { differenceInDays, format, subDays } from 'date-fns';
import { NotesContext } from 'contexts/notes';
import InputSelect from 'components/inputs/inputSelect';
import { Content, DateLabel, DateWrapper } from 'pages/Accounts/components/AccountsFilters/styles';
import Icon from 'components/icons';
import Button from 'components/button';
import { NotesDatesSelectContainer } from './styles';
import { formatDateInUtc } from 'utils/helpers';
import { NotesRangeDatePicker } from '../styles';
import { FilterItem } from 'contexts/notes/types';

interface INoteDatesFilter {
  modalScreen?: boolean;
  datesFilterChange?: (filters: FilterItem[]) => void;
}

const NoteDatesFilter = ({ modalScreen, datesFilterChange }: INoteDatesFilter): JSX.Element => {
  const { actions, filters } = useContext(NotesContext);
  const datesOptions = [
    { label: 'All', value: 'all' },
    { label: 'Last 30 days', value: 30 },
    { label: 'Last 60 days', value: 60 },
    { label: 'Custom', value: 'custom' },
  ];
  const [dateSelectValue, setDateSelectValue] = useState(datesOptions[0]);
  const [dateRangeSelected, setDateRangeSelected] = useState(false);
  const [dateRangeValue, setDateRangeValue] = useState(null);
  const labelFormat = 'dd LLL';

  const [dateFilter, setDateFilter] = useState({
    from: null,
    to: null,
  });

  const onDateSelectChange = (selectValue: string | number) => {
    setDateRangeSelected(false);
    setDateRangeValue(null);
    setDateSelectValue(datesOptions.find((opt) => opt.value === selectValue));
    if (selectValue !== 'custom' && selectValue !== 'all') {
      modalScreen
        ? datesFilterChange([
            {
              name: 'from_date',
              value: format(subDays(new Date(), Number(selectValue)), 'yyyy-MM-dd'),
            },
            { name: 'to_date', value: format(new Date(), 'yyyy-MM-dd') },
          ])
        : setDateFilter({
            from: format(subDays(new Date(), Number(selectValue)), 'yyyy-MM-dd'),
            to: format(new Date(), 'yyyy-MM-dd'),
          });
    } else if (selectValue === 'all') {
      modalScreen
        ? datesFilterChange([
            {
              name: 'from_date',
              value: null,
            },
            { name: 'to_date', value: null },
          ])
        : setDateFilter({
            from: null,
            to: null,
          });
    } else {
      setDateRangeSelected(true);
    }
  };

  const onDateFilterChange = (value: [string, string], date?: any) => {
    modalScreen
      ? datesFilterChange([
          {
            name: 'from_date',
            value: value[0],
          },
          { name: 'to_date', value: value[1] },
        ])
      : setDateFilter({
          from: value[0],
          to: value[1],
        });
    setDateSelectValue({
      value: 'custom',
      label: `${formatDateInUtc(new Date(value[0]), labelFormat)} - ${formatDateInUtc(
        new Date(value[1]),
        labelFormat
      )}`,
    });
    setDateRangeValue(date);
  };

  useEffect(() => {
    if (modalScreen) {
      if (!filters.from_date) {
        setDateSelectValue(datesOptions[0]);
      } else {
        const diffDays = differenceInDays(new Date(filters.to_date), new Date(filters.from_date));
        if (diffDays === 30 || diffDays === 60) {
          setDateSelectValue(datesOptions.find((opt) => opt.value === diffDays));
        } else {
          setDateSelectValue({
            value: 'custom',
            label: `${formatDateInUtc(
              new Date(filters.from_date),
              labelFormat
            )} - ${formatDateInUtc(new Date(filters.to_date), labelFormat)}`,
          });
          setDateRangeSelected(true);
        }
      }
    }
  }, [modalScreen]);

  useEffect(() => {
    !modalScreen &&
      actions.onChangeRangeFilters({
        fromName: 'from_date',
        fromValue: dateFilter.from,
        toName: 'to_date',
        toValue: dateFilter.to,
      });
  }, [dateFilter]);

  return (
    <Content>
      <DateWrapper>
        <DateLabel>Date range</DateLabel>
        <NotesDatesSelectContainer showCustom={dateSelectValue.value === 'custom'}>
          <InputSelect
            value={dateSelectValue.label}
            placeHolder={'Select a date'}
            options={datesOptions}
            onChange={(selectValue) => onDateSelectChange(selectValue)}
          />
          {dateRangeSelected && (
            <Button $secondary $full size={'small'}>
              <NotesRangeDatePicker
                value={dateRangeValue}
                suffixIcon={<Icon icon="Calendar" size="medium" />}
                onChange={(date, dateString) => onDateFilterChange(dateString, date)}
              />
            </Button>
          )}
        </NotesDatesSelectContainer>
      </DateWrapper>
    </Content>
  );
};

export default NoteDatesFilter;
