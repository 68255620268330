import { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { isBrowser } from 'react-device-detect';
import {
  ActionButton,
  Container,
  Content,
  Header,
  Title,
} from 'components/layout/tableContentHeader/styles';
import Button from 'components/button/Button';
import SearchFilter from './Search';
import PlaylistFilters from './PlaylistsFilters';
import ThreeStepsCreation from '../ThreeStepsCreation';

const PlaylistsHeader: React.FC<{ refreshPlaylist: () => void }> = ({
  refreshPlaylist,
}): JSX.Element => {
  const [isCreatingPlaylist, setIsCreatingPlaylist] = useState(null);
  const search = useLocation();

  const hasFinished = (refresh: boolean) => {
    if (refresh) {
      refreshPlaylist();
    }
    setIsCreatingPlaylist(false);
  };

  useEffect(() => {
    const searchSplited = search.search.split('?');
    searchSplited.length === 2 && searchSplited[1] === 'openModal' && setIsCreatingPlaylist(true);
  }, [search]);

  return (
    <>
      <Header>
        <Container>
          {isBrowser && (
            <Content>
              <Title>Playlists</Title>
            </Content>
          )}

          <Content grow={2}>
            <SearchFilter />
          </Content>

          <ActionButton>
            <Button $primary={true} onClick={() => setIsCreatingPlaylist(true)} icon={'Plus'}>
              Create playlist
            </Button>
          </ActionButton>
          {isCreatingPlaylist && (
            <ThreeStepsCreation hasFinished={(refresh) => hasFinished(refresh)} />
          )}
        </Container>
      </Header>
      <PlaylistFilters />
    </>
  );
};

export default PlaylistsHeader;
