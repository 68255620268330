import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg';

const Pages = ({
  pages,
  handleClick,
  current,
}: {
  pages: number[];
  handleClick: (page: string, name: string) => void;
  current: number;
}): JSX.Element | null => {
  if (pages.length < 1) return null;

  return (
    <>
      {pages.map((page, i) => {
        if (i > 3 || current > 4) return null;
        return (
          <li className={`page-item ${page + 1 === current ? 'active' : ''}`} key={i}>
            <button
              className="page-link"
              onClick={() => handleClick((page + 1).toString(), 'page')}
            >
              {page + 1}
            </button>
          </li>
        );
      })}
      {current > 4 && (
        <>
          <li className="page-item">
            <button
              className="page-link"
              aria-label="Next"
              onClick={() => handleClick((current - 1).toString(), 'page')}
            >
              <span aria-hidden="true">
                <ChevronRight className="text-primary" style={{ transform: 'rotate(180deg)' }} />
              </span>
            </button>
          </li>
          <li className="page-item">
            <div className="dropdown dropup">
              <button
                className="page-link dropdown-toggle caret-off"
                type="button"
                id="dropdownMenuButton2"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                ....
              </button>
              <ul
                className="dropdown-menu dropdown-menu-dark dropdown-menu-top"
                aria-labelledby="dropdownMenuButton2"
              >
                {pages.map(
                  (thisPage, index) =>
                    index + 1 < current &&
                    current !== thisPage && (
                      <li key={index}>
                        <button
                          className="dropdown-item text-decoration-underline"
                          onClick={() => handleClick((thisPage + 1).toString(), 'page')}
                        >
                          Page {thisPage + 1}
                        </button>
                      </li>
                    )
                )}
              </ul>
            </div>
          </li>
          <li className={'page-item  active'}>
            <button className="page-link">{current}</button>
          </li>
        </>
      )}
    </>
  );
};

const Pagination = ({
  count,
  onPagesClick,
  page,
  page_size,
  rowsPerPageList = ['9', '18', '27', '36', '45'],
  className = '',
}: {
  count: number;
  onPagesClick: (page: string, name: string) => void;
  page: number;
  page_size: number;
  rowsPerPageList?: string[];
  className?: string;
}): JSX.Element | null => {
  const pages = Math.ceil(count / page_size);
  const arrPages = [...Array(pages).keys()];

  return (
    <div className={`d-flex justify-content-between pt-9 ${className}`}>
      <div className="d-flex align-items-center ps-4">
        <p className="me-2 mb-0 d-none d-md-block">Results per page</p>
        <div className="dropdown dropup">
          <button
            className="page-link dropdown-toggle caret-off rounded bg-light"
            type="button"
            id="dropdownMenuButton2"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {page_size}
            <ChevronDown className="ms-2" />
          </button>
          <ul className="dropdown-menu dropdown-menu-top" aria-labelledby="dropdownMenuButton2">
            {rowsPerPageList.map((page, index) => (
              <li key={index} data-value={page}>
                <button
                  className="dropdown-item"
                  onClick={() => onPagesClick(page, 'page_size')}
                  data-value={page}
                >
                  {page}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <nav aria-label="Table navigation" className="d-flex align-items-center">
        <p className="d-none d-md-block mb-0 me-4">
          {`Showing ${page_size * (page - 1) + 1} - ${
            page_size * page < count ? page_size * page : count
          } results out of ${count}`}
        </p>
        <ul className="pagination justify-content-end pe-4">
          <Pages current={page} pages={arrPages} handleClick={onPagesClick} />
          {pages > 3 && pages > page && (
            <>
              <li className="page-item">
                <div className="dropdown dropup">
                  <button
                    className="page-link dropdown-toggle caret-off"
                    type="button"
                    id="dropdownMenuButton2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    ....
                  </button>
                  <ul
                    className="dropdown-menu dropdown-menu-dark dropdown-menu-top"
                    aria-labelledby="dropdownMenuButton2"
                  >
                    {arrPages.map(
                      (thisPage, index) =>
                        index > 3 &&
                        page !== thisPage + 1 && (
                          <li key={index}>
                            <button
                              className="dropdown-item text-decoration-underline"
                              onClick={() => onPagesClick((thisPage + 1).toString(), 'page')}
                            >
                              Page {thisPage + 1}
                            </button>
                          </li>
                        )
                    )}
                  </ul>
                </div>
              </li>
              <li className="page-item">
                <button
                  className="page-link"
                  aria-label="Next"
                  onClick={() => onPagesClick((page + 1).toString(), 'page')}
                >
                  <span aria-hidden="true">
                    <ChevronRight className="text-primary" />
                  </span>
                </button>
              </li>
            </>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
