import React, { FormEvent, ReactNode, useState } from 'react';
import { Buttons, Container, Description, Info, SecureInput, Title } from './styles';
import Button from 'components/button';
import Modal from 'components/modal';
import { Spin } from 'components/loadings/Spinner';
import SpinnerIMG from 'assets/images/spinner.png';

export interface ConfirmModalProps {
  title: string;
  description?: string | ReactNode;
  open: boolean;
  onCancel: () => void;
  onAccept: () => void;
  acceptLabel?: string;
  cancelLabel?: string;
  loading?: boolean;
  secureText?: string;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  open,
  title,
  description,
  onCancel,
  onAccept,
  acceptLabel,
  cancelLabel,
  loading,
  secureText,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [inputError, setInputError] = useState(false);

  const clearInput = () => {
    setInputValue('');
    setInputError(false);
  };

  const handleOnCancel = () => {
    clearInput();
    onCancel();
  };

  const handleOnAccept = () => {
    if (secureText && secureText !== inputValue) {
      setInputError(true);
      return;
    }

    clearInput();
    onAccept();
  };

  const handleInput = (e: FormEvent<HTMLInputElement>): void => {
    setInputError(false);
    setInputValue(e.currentTarget.value);
  };

  return (
    <Modal
      centered
      onCancel={handleOnCancel}
      visible={open}
      footer={null}
      width="400px"
      destroyOnClose
      bodyStyle={{ borderRadius: '3px !important' }}
    >
      <Container
        onSubmit={(e) => {
          e.preventDefault();
          handleOnAccept();
        }}
      >
        <Info>
          <Title>{title}</Title>
          {loading ? (
            <div style={{ position: 'relative', margin: '20px 0 40px 0' }}>
              <Spin src={SpinnerIMG} size="50" />
            </div>
          ) : (
            <>
              <Description>{description}</Description>
              {secureText && (
                <SecureInput
                  type="text"
                  className="form-control"
                  placeholder={secureText}
                  aria-label="secure"
                  onChange={handleInput}
                  value={inputValue}
                  error={inputError}
                />
              )}
            </>
          )}
        </Info>

        <Buttons>
          <Button $secondary onClick={handleOnCancel} size="small" $full disabled={loading}>
            {cancelLabel || 'No'}
          </Button>
          <Button $primary onClick={handleOnAccept} size="small" $full disabled={loading}>
            {acceptLabel || 'Yes'}
          </Button>
        </Buttons>
      </Container>
    </Modal>
  );
};

export default ConfirmModal;
