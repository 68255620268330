import { ColumnContainer, Container } from 'pages/RaxPage/styles';
import AssignedProductCard from './components/AssignedProductCard';
import { useEffect, useMemo, useState } from 'react';
import { getInvitationsAction } from 'contexts/users/actions';
import { Spinner } from 'components/loadings';
import CalendarContainer from './components/CalendarContainer';
import RemoveBookingModal from './components/RemoveBookingModal';
import { IBooking, IBookingsParams, IProduct } from 'contexts/rax/types';

interface IAccOwnerProps {
  products: IProduct[];
  bookings: IBooking[];
  onScheduleBooking: (params: IBookingsParams) => void;
  onRemoveBooking: (id: string) => void;
}

const userParams = {
  license: null,
  roles: null,
  page: 1,
  page_size: 9999,
  search: null,
  active: 'true',
};

const AccOwnerRaxPage = ({
  products,
  bookings,
  onScheduleBooking,
  onRemoveBooking,
}: IAccOwnerProps): JSX.Element => {
  const [fetching, setFetching] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setFetching(true);
    const usersResponse = await getInvitationsAction(userParams);
    if (usersResponse instanceof Error) {
      setFetching(false);
      setUsers([]);
    } else {
      setFetching(false);
      setUsers(usersResponse.results);
    }
  };

  const currentProducts = useMemo(() => {
    return products?.map((product) => {
      const currentProduct = { ...product };
      currentProduct.bookings = currentProduct.bookings.map((book) => {
        const user = users.find((user) => user.user === book.user.id);
        const active = user !== undefined;
        const avatar = user?.uaa_data?.profile?.data?.avatar || null;
        const name = `${user?.uaa_data?.profile?.data?.first_name} ${user?.uaa_data?.profile?.data?.last_name}`;

        book.user = {
          ...book.user,
          avatar,
          active,
          name,
        };
        return book;
      });
      return currentProduct;
    });
  }, [products, users]);

  const [showRemoveBookingModal, setShowRemoveBookingModal] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);

  const onRemoveBookingHandler = (id: string) => {
    setSelectedBooking(id);
    setShowRemoveBookingModal(true);
  };

  const handleModalConfirm = () => {
    onRemoveBooking(selectedBooking);
    setShowRemoveBookingModal(false);
  };

  return (
    <Container>
      {fetching || !users.length ? (
        <Spinner active={fetching} top={'300px'} />
      ) : (
        <>
          <ColumnContainer>
            <RemoveBookingModal
              visible={showRemoveBookingModal}
              onCancel={() => setShowRemoveBookingModal(false)}
              onOk={handleModalConfirm}
            />
            {users.length &&
              currentProducts.map((product, index) => (
                <AssignedProductCard
                  key={`apc-${index}`}
                  {...product}
                  editable
                  users={users}
                  onScheduleBooking={onScheduleBooking}
                  onRemoveBooking={onRemoveBookingHandler}
                />
              ))}
          </ColumnContainer>
          <ColumnContainer>
            <CalendarContainer
              bookings={bookings}
              isInteractable={false}
              borders={false}
              eventsWithUsername={true}
              fetchAvailability={false}
            />
          </ColumnContainer>
        </>
      )}
    </Container>
  );
};

export default AccOwnerRaxPage;
