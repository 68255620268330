import { AxiosResponse } from 'axios';
import { config } from 'config/config.local';
import {
  IGetReportsFetchParams,
  IGetReportsResponse,
  INewReportParams,
  IReport,
  IReportDL,
  IReportPreferencesResponse,
} from 'contexts/reporting/types';
import { BUSINESS_PYTHON } from './axios';

export const getReports = ({
  from_date,
  to_date,
  type,
  sorting,
  page,
  page_size,
}: IGetReportsFetchParams): Promise<AxiosResponse<IGetReportsResponse>> => {
  let paramString = `from_date=${from_date}&to_date=${to_date}&type=${type}`;
  if (sorting) {
    const currentSort = sorting.filter((sorter) => sorter.active)[0];
    paramString = `${paramString}&ordering=${currentSort.asc ? '' : '-'}${currentSort.sortKey}`;
  }
  if (page) {
    paramString = `${paramString}&page=${page}`;
  }
  if (page_size) {
    paramString = `${paramString}&page_size=${page_size}`;
  }

  return BUSINESS_PYTHON.get(`${config.routes.reporting.reports}?${paramString}`);
};

export const newReport = ({
  type,
  datesFilter,
  activeFilters,
  uaa_ids,
  team_ids,
}: INewReportParams): Promise<AxiosResponse<IReport>> => {
  const paramsParser = {
    type,
    from_date: datesFilter.from,
    to_date: datesFilter.to,
    columns: activeFilters,
    uaa_ids: uaa_ids,
    team_ids: team_ids,
  };
  return BUSINESS_PYTHON.post(`${config.routes.reporting.reports}`, { ...paramsParser });
};

export const downloadReport = (id: string): Promise<AxiosResponse<IReportDL>> => {
  return BUSINESS_PYTHON.get(`${config.routes.reporting.reportsWithId}/${id}/download/`);
};

export const getReportPreferences = (
  reportType?: string
): Promise<AxiosResponse<{ results: IReportPreferencesResponse[] }>> => {
  const queryParams = reportType ? `/?type=${reportType}` : '';
  return BUSINESS_PYTHON.get(`${config.routes.reporting.checkboxes}${queryParams}`);
};

export const saveReportPreferences = (
  checkboxes: string[],
  reportType: string
): Promise<AxiosResponse<IReportPreferencesResponse>> => {
  const payload = {
    checkboxes,
    type: reportType,
  };
  return BUSINESS_PYTHON.post(`${config.routes.reporting.checkboxes}`, { ...payload });
};
