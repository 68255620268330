import { FC, useContext, useState } from 'react';
import styled from 'styled-components';
import CirclesInfoDisplay from 'components/circlesInfoDisplay/CirclesInfoDisplay';
import { userImagesArray } from 'components/circlesInfoDisplay/utilities';
import {
  CardContent,
  CardRowContainer,
  CustomLink,
} from 'components/layout/profile/profileContent/styles';
import { AccountUsersData } from 'contexts/accounts/types';
import Icon from 'components/icons';
import Button from 'components/button';
import { SessionContext } from 'contexts/session';
import EmptyUsersCard from './EmptyUsers';
import AccountDefaultLogo from 'assets/images/account-avatar.svg';
import { UserInvites } from 'contexts/users/types';
import { screenMd } from 'constants/styles/breakpoints';
import InviteUsers from 'pages/Users/components/InviteUsers';
import useAccountPlanData from 'hooks/useAccountPlanData';

export const CustomCard = styled(CardRowContainer)<{ dashboard?: boolean }>`
  ${({ dashboard }) => (dashboard ? '' : 'padding: 30px; cursor: pointer;')};
  grid-gap: 40px;
`;

export const CustomCardContent = styled(CardContent)<{ dashboard?: boolean }>`
  span {
    ${screenMd} {
      ${({ dashboard }) =>
        dashboard &&
        `
				grid-gap: 10px;
				flex-direction: row;
			`};
    }
  }
  .users-card__add-user-btn {
    display: none;
    margin-top: 20px;
    ${screenMd} {
      display: initial;
    }
  }
`;

const AccountUsersCard: FC<{
  usersData: AccountUsersData;
  pendings?: UserInvites[];
  dashboard?: boolean;
  showUsersPage?(): void;
}> = ({ usersData, dashboard, showUsersPage, pendings = [] }) => {
  const { isTeamManager, isAccountOwner, account } = useContext(SessionContext);
  const isOwnerOrManager = isTeamManager || isAccountOwner;
  const { isExpired } = useAccountPlanData({ account });
  const { total, users, active, pending } = usersData;
  const [isEditingInvite, setEditingInvite] = useState<boolean>(false);

  if (total < 1) return <EmptyUsersCard />;

  const userItems = userImagesArray([...users, ...pendings]);

  if (userItems.length < 2) userItems.push(<img src={AccountDefaultLogo} />);

  const goToUsers = () => {
    if (!isOwnerOrManager) showUsersPage();
  };

  return (
    <>
      <CustomCard dashboard={dashboard} className="users-card" onClick={goToUsers}>
        <CirclesInfoDisplay elements={userItems} count={total - userItems.length} />
        <CustomCardContent className="users-card__content" dashboard={dashboard}>
          <h5 className="users-card__title">{total} Users</h5>
          <span className="users-card__counters">
            <p>Active users: {active}</p>
            {dashboard && <div className="divider" />}
            <p>Pending users: {pending} </p>
          </span>
          {dashboard && (
            <div className="users-card__add-user-btn">
              <Button
                size="small"
                icon="Plus"
                style={{ padding: '0px 25px' }}
                disabled={isExpired}
                onClick={() => setEditingInvite(true)}
              >
                Add user
              </Button>
            </div>
          )}
        </CustomCardContent>

        <CustomLink to="/users">
          <Icon icon="ArrowRight" size={'small'} />
        </CustomLink>
      </CustomCard>
      {isEditingInvite && (
        <InviteUsers
          account={account}
          isEditingInvite={isEditingInvite}
          onClose={() => setEditingInvite(false)}
        />
      )}
    </>
  );
};

export default AccountUsersCard;
