import { FormEvent, useState } from 'react';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';

interface ISearchInput {
  onChangeQuery?: (query: string) => void;
}

const SearchFilter: React.FC<ISearchInput> = ({ onChangeQuery }) => {
  const [value, setValue] = useState('');

  const handleSearchInput = (e: FormEvent<HTMLInputElement>): void => {
    setValue(e.currentTarget.value);
  };

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onChangeQuery(value);
  };

  return (
    <form
      className="col input-group input-group-solid align-items-center ps-4 rounded"
      onSubmit={onSearchSubmit}
      style={{ backgroundColor: '#F2F2F7', flexGrow: 1 }}
    >
      <SearchIcon />
      <input
        type="text"
        className="form-control"
        placeholder="Search"
        aria-label="search user"
        aria-describedby="basic-search"
        onChange={handleSearchInput}
        value={value}
      />
    </form>
  );
};

export default SearchFilter;
