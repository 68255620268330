import styled from 'styled-components';
import { screenMd } from 'constants/styles/breakpoints';

import ReportsProvider from 'contexts/reporting';

import ReportingContent from './ReportingContent';

const PageContent = styled.div`
  position: relative;
  background: #f2f2f7;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 70px;
  ${screenMd} {
    margin-top: unset;
    margin-bottom: unset;
  }
`;

const Reporting = (): JSX.Element => {
  return (
    <ReportsProvider>
      <PageContent>
        <ReportingContent />
      </PageContent>
    </ReportsProvider>
  );
};

export default Reporting;
