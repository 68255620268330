import { useContext } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import TeamsTable from './components/TeamsTable';
import TeamsHeader from './components/TeamsHeader';
import TeamsEmpty from './components/TeamsEmpty';
import { Spinner } from 'components/loadings';
import { TeamsContext } from 'contexts/teams';
import { SessionContext } from 'contexts/session';
import { screenSm } from 'constants/styles/breakpoints';

const ContentTable = styled.div`
  display: flex;
  padding: 10px;
  margin-top: 0px;
  opacity: 1;
  transition: opacity 0.4s;
  height: 100%;

  ${screenSm} {
    background-color: #fff;
    padding: 20px;
    margin-top: 20px;
  }
`;

const TeamsContent = (): JSX.Element => {
  const { fetching } = useContext(TeamsContext);
  const { teams } = useContext(SessionContext);

  return (
    <>
      <TeamsHeader />
      <ContentTable>
        {fetching ? (
          <Spinner active top={isMobile ? '250px' : '350px'} />
        ) : teams?.length > 0 ? (
          <TeamsTable lock={false} />
        ) : (
          <TeamsEmpty />
        )}
      </ContentTable>
    </>
  );
};

export default TeamsContent;
