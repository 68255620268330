import OverallIcon from 'assets/icons/analytics/overall-icon.svg';
import QuizzesIcon from 'assets/icons/analytics/quizzes-icon.svg';
import VideosIcon from 'assets/icons/analytics/videos-icon.svg';
import LabsIcon from 'assets/icons/analytics/labs-icon.svg';
import {
  ACTIVITIES_COUNTER,
  LABS_COUNTER,
  QUIZZES_COUNTER,
  VIDEOS_COUNTER,
} from 'constants/analytics';
import { TeamActivitiesTabs } from './types';

export const TOP_USER_ACTIVITY_TABS: TeamActivitiesTabs[] = [
  {
    item: 'OVERALL_ACTIVITIES',
    label: 'Overal activities',
    icon: OverallIcon,
    actions: ACTIVITIES_COUNTER.OVERALL.ACTIONS,
  },
  {
    item: 'VIDEOS_WATCHED',
    label: 'Video watched time',
    icon: VideosIcon,
    actions: VIDEOS_COUNTER.FINISHED.ACTIONS,
    counter_external_actions: VIDEOS_COUNTER.WATCHED.EXTERNAL_ACTION,
    external_actions: VIDEOS_COUNTER.TOP_WATCHED.EXTERNAL_ACTION,
  },
  {
    item: 'PASSED_QUIZZES',
    label: 'Passed quizzes',
    icon: QuizzesIcon,
    actions: QUIZZES_COUNTER.PASSED.ACTIONS,
    metadata: QUIZZES_COUNTER.PASSED.METADATA,
  },
  {
    item: 'FINISHED_LABS',
    label: 'Finished hands-on labs',
    icon: LabsIcon,
    actions: LABS_COUNTER.FINISHED.ACTIONS,
  },
];

export const CHART_BAR_COLORS = {
  OVERALL_ACTIVITIES: '#A793F6',
  VIDEOS_WATCHED: '#3E6496',
  PASSED_QUIZZES: '#F19E5B',
  FINISHED_LABS: '#D73F47',
  FINISHED_SLIDES: '#65AAA3',
};
