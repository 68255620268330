import { useContext } from 'react';
import cx from 'classnames';
import { UsersContext } from 'contexts/users';

interface UsersAsideFiltersStatusProps {
  direction: 'column' | 'row';
}

const UsersAsideFiltersStatus = ({ direction }: UsersAsideFiltersStatusProps): JSX.Element => {
  const {
    usersCounts,
    actions,
    filters: { active },
  } = useContext(UsersContext);

  const handleActiveChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    actions && actions.onChangeFilters({ name: e.target.name, value: e.target.value }, false, true);
  };

  return (
    <div className={cx('users-aside-filters d-flex', `flex-${direction}`)}>
      <div className="form-check me-4">
        <input
          className="form-check-input"
          type="radio"
          name="active"
          id="isActiveMobile"
          value="true"
          onChange={handleActiveChange}
          checked={active !== 'false'}
        />
        <label
          className="form-check-label fz-14"
          style={{ fontSize: '14px' }}
          htmlFor="isActiveMobile"
        >
          Active (<span className="fw-bolder">{usersCounts?.active_count?.active || 0}</span>)
        </label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="active"
          id="isPendingMobile"
          value="false"
          onChange={handleActiveChange}
          checked={active === 'false'}
        />
        <label className="form-check-label" style={{ fontSize: '14px' }} htmlFor="isPendingMobile">
          Pending (<span className="fw-bolder">{usersCounts?.active_count?.pending || 0}</span>)
        </label>
      </div>
    </div>
  );
};

export default UsersAsideFiltersStatus;
