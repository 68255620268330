import { addDays, format, subYears } from 'date-fns';

import { IGetReportsFetchParams, IReportsDataCtx } from './types';

export const reportsInitialValues: IReportsDataCtx = {
  reports: null,
  users: null,
  error: null,
  fetching: false,
  fetchingUsers: false,
};

export const sortingInitialState = [
  { sortKey: 'created_at', asc: false, active: true },
  { sortKey: 'filename', asc: false, active: false },
];

export const reportsInitialParams: IGetReportsFetchParams = {
  from_date: format(subYears(new Date(), 1), 'yyyy-MM-dd'),
  to_date: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
  type: 'user',
  page: 1,
  page_size: 20,
  sorting: sortingInitialState,
};

export const reportStatus: {
  pending: string;
  completed: string;
  error: string;
  partialFinished: string;
} = {
  pending: 'pending',
  completed: 'finished',
  error: 'error',
  partialFinished: 'partial_finished',
};
