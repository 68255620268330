import { colors } from 'constants/styles/colors';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';

export const Container = styled.div`
  /* align-items: center;
	background: linear-gradient(45deg, #ef7d22 1%, #863ad2 30%, #190da0),
		linear-gradient(90deg, transparent 1%, #3c1aef 80%);
	border-top-left-radius: 22px;
	border-top-right-radius: 22px;
	display: flex;
	flex-direction: column;
	height: 228px;
	padding-top: 61px;
	width: 100%; */
`;

export const CustomNavLink = styled(NavLink)`
  color: ${colors.dataVisualization.purple.dark};
  font-size: 15px;
  line-height: 20px;

  &:hover {
    color: ${colors.dataVisualization.purple.dark};
  }
`;
