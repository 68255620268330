import { FC } from 'react';
import styled from 'styled-components';
import { useRef } from 'react';
import useOnClickOutside from 'hooks/useOutsideClick';

const ActionsContainer = styled.ul`
  width: 189px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 0;
  border-radius: 10px;
  margin: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #222222;
`;

const ActionItem = styled.li`
  line-height: 36px;
  list-style: none;
  color: #fff;
`;

interface MobileAccountActionsProps {
  onEdit: () => void;
  onManagePlan: () => void;
  onCreateDomain: () => void;
  onClose: () => void;
}

const MobileAccountActions: FC<MobileAccountActionsProps> = ({
  onEdit,
  onManagePlan,
  onCreateDomain,
  onClose,
}) => {
  const ref = useRef();
  useOnClickOutside(ref, onClose);

  return (
    <ActionsContainer ref={ref}>
      <ActionItem onClick={onEdit}>Edit account</ActionItem>
      <ActionItem onClick={onManagePlan}>Manage plan</ActionItem>
      <ActionItem onClick={onCreateDomain}>Create domain</ActionItem>
    </ActionsContainer>
  );
};

export default MobileAccountActions;
