export const USER_ACTIVITY = 'user';
export const TEAM_ACTIVITY = 'team';
export const COURSES = 'content';
export const ACCOUNT = 'account';
export const skillDiveColumnName = 'Skill Dive';
export const skillSonarColumnName = 'Skill Sonar';
export const usersColumnName = 'Users';
export const coursesColumnName = 'Courses';
export const teamColumnName = 'Team';
export const noUserOption = 'no-user';
