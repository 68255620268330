import styled from 'styled-components';
import { screenMd } from 'constants/styles/breakpoints';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${screenMd} {
  }
`;

export const FilterContainer = styled.div`
  padding-bottom: 40px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Title = styled.h5`
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.default.colors.palette.Business_DarkThemeGeneralBackground};
`;

export const Filter = styled.div`
  margin: 10px 0px;
`;

export const FilterSlider = styled.div`
  width: 100%;
  margin: 10px 0px;
`;

export const Count = styled.div`
  margin-left: auto;
`;

export const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const DateLabel = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.default.colors.palette.Business_DarkThemeCardBackground};
`;
