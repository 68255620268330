import styled from 'styled-components';
import { screenMd } from 'constants/styles/breakpoints';

export const TeamsTableItem = styled.span<{ active?: boolean; reverse: boolean }>`
  display: flex;
  flex-flow: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  align-items: center;
  justify-content: ${({ reverse }) => (reverse ? 'flex-end' : 'flex-start')};
  height: 43px;
  border-radius: 5px;
  cursor: pointer;
  box-sizing: border-box;
  background: transparent;
  border: none;
  padding: 8px;
  &:focus-active {
    outline: none;
  }
  ${({ active, theme }) =>
    active &&
    `
		outline: ${theme.default.colors.buttons.blackGray6} 1px solid !important;
	`}
  > span {
    color: #1a2535;
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 500;
    white-space: nowrap;
    padding-top: 2px;
  }
  > svg {
    min-width: 15px;
  }
`;

export const TeamsMenuContainer = styled.div<{ withSubmit?: boolean; full?: boolean }>`
  width: auto;
  ${({ full }) => full && 'width: 100%'};
  padding: 20px 5px 85px 18px;
  background: #ffffff;
  box-shadow: 0px 30px 48px rgba(0, 0, 0, 0.2);
  position: relative;
  border-radius: 6px;
  > .teams-options-container {
    max-height: 230px;
    overflow-y: scroll;
  }
  ${({ withSubmit }) =>
    withSubmit &&
    `
		padding-bottom: 20px;
	`}
  ${screenMd} {
    min-width: 350px;
  }
`;

export const TeamsMenuItem = styled.button`
  width: calc(100% - 10px);
  height: 55px;
  display: flex;
  justify-content: start;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  box-sizing: border-box;
  border: none;
  background: #ffffff;
  &:focus,
  &:hover {
    background: ${({ theme }) => theme.default.colors.buttons.blackGray7};
  }
  &:active {
    background: ${({ theme }) => theme.default.colors.buttons.blackGray6};
  }
  > .teams-icon {
    margin-right: 10px;
  }
  > .teams-label {
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.default.colors.buttons.blackGray1};
    margin-left: 10px;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const TeamsDropdownCTA = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  padding: 20px;
  border-radius: 6px;
  > button {
    flex-grow: 1;
    &:first-of-type {
      margin-right: 10px;
    }
  }
`;

enum sizes {
  default = '60px',
  large = '80px',
  small = '54px',
  smaller = '44px',
}

export const IconContainer = styled.div<{ color?: string; size: string }>`
  border-radius: 6px;
  background-color: ${({ color, theme }) =>
    theme.default.colors.dataVisualization[color]?.lighter || 'lightGray'};
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => sizes[size]};
  height: ${({ size }) => sizes[size]};
  > img,
  span,
  svg {
    width: ${({ size }) => sizes[size]};
    height: ${({ size }) => sizes[size]};
    border-radius: 6px;
    &.default {
      background-color: ${({ theme }) => theme.default.colors.dataVisualization['purple'].lighter};
    }
    &.remove-team {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      border: 1px solid ${({ theme }) => theme.default.colors.dataVisualization['purple'].lighter};
    }
  }
  > svg {
    stroke: ${({ color, theme }) => theme.default.colors.dataVisualization[color]?.base} !important;
    fill: ${({ color, theme }) => theme.default.colors.dataVisualization[color]?.base} !important;
    stroke-width: 0px;
  }
`;

export const UserRowTeamsContainer = styled.div`
  display: grid;
`;
