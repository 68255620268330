/* eslint-disable no-undef */
// Data visualization pallet

const colors = {
  body: {
    bg: '#F2F2F7',
  },
  header: {
    bg: '#FFFFFF',
  },
  aside: {
    bg: '#1A2535',
    menu: {
      bg: '#111A27',
      icons: '#BB85FF',
      labels: '#FFFFFF',
    },
  },
  dataVisualization: {
    purple: {
      dark: '#7965C6',
      base: '#A793F6',
      light: '#C4B7F9',
      lighter: '#D3C9FA',
    },
    blue: {
      dark: '#0F305C',
      base: '#3E6496',
      light: '#95B2D7',
      lighter: '#C1D8F8',
    },
    orange: {
      dark: '#D0803F',
      base: '#F19E5B',
      light: '#F6BE92',
      lighter: '#F8CEAD',
    },
    red: {
      dark: '#A43238',
      base: '#D73F47',
      light: '#DE5F66',
      lighter: '#EB9FA3',
    },
    green: {
      dark: '#4A857F',
      base: '#65AAA3',
      light: '#98C6C2',
      lighter: '#B2D4D1',
    },
    yellow: {
      dark: '#AA8F37',
      base: '#E8BF39',
      light: '#EFD47B',
      lighter: '#F3DF9C',
    },
    violet: {
      dark: '#69325B',
      base: '#9F6490',
      light: '#BF97B5',
      lighter: '#CFB1C7',
    },
    pink: {
      dark: '#BD717B',
      base: '#FD8897',
      light: '#FEAFBA',
      lighter: '#FEC3CB',
    },
    beige: {
      dark: '#83746F',
      base: '#ABA09C',
      light: '#C7BFBD',
      lighter: '#D5CFCD',
    },
    black: {
      dark: '#111A27',
      light: '#1A2535',
    },
    gray: {
      dark: '#D0D2DB',
      light: '#F2F2F7',
      dusty: '#E9E9ED',
    },
  },
  secondaryPallete: {
    blue: {
      base: '#190DA0',
    },
    purple: {
      base: '#5B4B9B',
      light: '#A793F6',
      lighter: '#9989EE',
    },
    blueGray: {
      base: '#797B8C',
    },
  },
  learningArea: {
    networking: {
      base: '#FAD740',
      light: '#FAD740',
    },
    cloud: {
      base: '#88A9FF',
      light: '#88A9FF',
    },
    datascience: {
      base: '#66C19A',
      light: '#66C19A',
    },
    cybersecurity: {
      base: '#bb85ff',
      light: '#e9d8fd',
    },
    itessentials: {
      base: '#F647C0',
      light: '#F647C0',
    },
    development: {
      base: '#D73F47',
      light: '#D73F47',
    },
  },
  performance: {
    positive: '#00DA99',
    positiveLight: '#00DC9B',
    positiveDark: '#009D6F',
    positiveGradient: '#70FFD4', // todo: define how to use gradient here.
    negative: '#E52A2A',
    negativeLight: '#FF5E5E',
    negativeGradient: '#FF5E5E', // todo: define how to use gradient here.
  },
  buttons: {
    blackGray: '#1E1E1E',
    blackGray2: '#9B9B9B',
    blackGray3: '#AAAAAA',
    blackGray4: '#AFB0BF',
    blackGray5: '#C0C0C0',
    blackGray6: '#E0E0E0',
    blackGray7: '#EEEEEE',
    blackGray8: '#FFFFFF',
    lightGray: '#D0D2DB',
    darkGray: '#57575C',
    dark: '#1A2535',
    white: '#FFF',
    darkPurple: '#A793F6',
    lightPurple: '#C4B7F9',
    lighter: '#F2F2F7',
  },
  fonts: {
    dark: '#1A2535',
    darkGray: '#57575C',
    lightGray: '#AEAEB2',
    white: '#FFFFFF',
    lightPurple: '#C4B7F9',
  },
  iconButtons: {
    purple: {
      dark: '#A793F6',
      light: '#DCD4FC',
    },
    gray: {
      dark: '#D0D2DB',
      light: '#D5D5D6',
      lighter: '#F2F2F7',
    },
    blueGray: {
      dark: '#1A2535',
    },
    white: '#FFFFFF',
  },
  checkbox: {
    purple: {
      dark: '#A793F6',
      light: '#DCD4FC',
    },
    gray: {
      dark: '#D0D2DB',
      light: '#D5D5D6',
      lighter: '#F2F2F7',
    },
  },
  palette: {
    Primary_INEBlack: '#1E1E1E',
    Secondary_LightBlueGray: '#AFB0BF',
    Business_LightThemeGreyDarker: '#57575C',
    Business_DarkThemeGeneralBackground: '#1A2535',
    Business_LightThemeGrayDark: '#AEAEB2',
    Business_LightThemeGreyLighter: '#F2F2F7',
    Business_DataVizLilacDark: '#7965C6',
    Business_DarkThemeCardBackground: '#111A27',
    Business_DataVizLilacLighter: '#A793F6',
  },
  sonar: {
    performance: {
      positive: '#49E0B3',
    },
  },
};

module.exports.colors = colors;
