import { css } from 'styled-components';

export const breakpoints = {
  xxs: 350,
  xs: 480,
  sm: 768,
  md: 992,
  lg: 1200,
  xlg: 1400,
};

// [WARNING] breakpoints on scss are different than breakpoints on styled-components
// $grid-breakpoints: (
//   xs: 0,
//   sm: 576px,
//   md: 768px,
//   lg: 992px,
//   xl: 1200px,
//   xxl: 1400px,
// ) !default;

export const applyStylesOn = Object.keys(breakpoints).reduce((accumulator, label) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  accumulator[label] = (literals: TemplateStringsArray, ...args: any[]) => css`
    @media (min-width: ${breakpoints[label]}px) {
      ${css.call(literals, ...args)};
    } ;
  `;
  return accumulator;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}, {} as Record<keyof typeof breakpoints, (l: TemplateStringsArray, ...p: any[]) => string>);

const customMediaQuery = (maxWidth: number) => `@media (min-width: ${maxWidth}px)`;

export const rangeMediaQuery = ({ min, max }: { min: number; max: number }): string =>
  `@media (min-width:${min}px) and (max-width:${max}px)`;

export const screenXxs = customMediaQuery(breakpoints['xxs']);
export const screenXs = customMediaQuery(breakpoints['xs']);
export const screenSm = customMediaQuery(breakpoints['sm']);
export const screenMd = customMediaQuery(breakpoints['md']);
export const screenLg = customMediaQuery(breakpoints['lg']);
export const screenXlg = customMediaQuery(breakpoints['xlg']);
