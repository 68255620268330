import { useContext, useState } from 'react';
import { isMobile } from 'react-device-detect';

import { AnalyticsContext } from 'contexts/analytics';
import SlideButtonsGroup from '../SlideButtonsGroup';
import ChartDataSelector from '../ChartDataSelector';
import { Container } from './styles';
import './statics.scss';
import ActiveUsersChart from '../ActiveUsersChart';
import { Spinner } from 'components/loadings';
import { addWeeks, format } from 'date-fns';
import { handleErrors } from 'contexts/helpers';

const chartTypes = [
  { icon: 'LineChart', value: 'AreaChart' },
  { icon: 'BarChart', value: 'BarChart' },
];

interface Props {
  title?: string;
  dataDisplay: { key: string; checked?: boolean }[];
  toggleCheckbox: (key: string) => void;
  hideChartTypeSelector?: boolean;
}

const AnalyticsActivitiesChart = ({
  toggleCheckbox,
  dataDisplay,
  hideChartTypeSelector,
  title,
}: Props): JSX.Element => {
  const [chartType, setChartType] = useState('AreaChart');
  const { activities, currentChart, filters } = useContext(AnalyticsContext);

  const chartItems = activities?.items?.map(({ date, count }) => {
    const finished = activities.finished?.find((i: { date: string }) => i.date === date);
    const passed = activities.passed?.find((i: { date: string }) => i.date === date);
    const started = activities.started?.find((i: { date: string }) => i.date === date);
    const watched = activities.watched?.find((i: { date: string }) => i.date === date);
    const labs = activities.labs?.find((i: { date: string }) => i.date === date);
    const videos = activities.videos?.find((i: { date: string }) => i.date === date);
    const quizzes = activities.quizzes?.find((i: { date: string }) => i.date === date);
    const total = activities.total?.find((i: { date: string }) => i.date === date);
    const licensed = activities.licensed?.find((i: { date: string }) => i.date === date);
    const percentage = activities.withActivity?.find((i: { date: string }) => i.date === date);
    const standard = activities.standard?.find((i: { date: string }) => i.date === date);
    const skill_dive = activities.skill_dive?.find((i: { date: string }) => i.date === date);
    return {
      date,
      count,
      finished: finished ? finished.count : 0,
      started: started ? started.count : 0,
      passed: passed ? passed.count : 0,
      watched: watched ? watched.count : 0,
      labs: labs ? labs.count : 0,
      videos: videos ? videos.count : 0,
      quizzes: quizzes ? quizzes.count : 0,
      total: total ? total.count : 0,
      licensed: licensed ? licensed.count : 0,
      percentage: percentage ? percentage.count : 0,
      standard: standard ? standard.count : 0,
      skill_dive: skill_dive ? skill_dive.count : 0,
      currentChart,
    };
  });

  const formatXAxisLabel = (date: string, index: number) => {
    let dateObj;
    try {
      dateObj = new Date(date);

      switch (filters.period) {
        case 'day':
          return format(dateObj, 'EEE, MMM do');
        case 'week':
          return `WEEK ${++index}\n${format(dateObj, 'do')} - ${format(
            addWeeks(dateObj, 1),
            'do, MMM'
          )}`;
        case 'month':
          return `MONTH ${++index}\n${format(dateObj, 'MMMM yyyy')}`;
        case 'year':
          return `YEAR ${++index}\n${format(dateObj, 'yyyy')}`;
        default:
          return date;
      }
    } catch (error) {
      return handleErrors(error);
    }
  };

  const updateChartType = (value: string) => {
    setChartType(value);
  };
  return (
    <>
      <Spinner
        active={activities.loading}
        top={isMobile ? '250px' : '50%'}
        left="50%"
        position="absolute"
      />
      <Container lock={activities.loading}>
        <div className="activities-chart__options-container">
          <span className="activities-chart__options-container-title">{title}</span>
          <div className="activities-chart__options-container-buttons">
            {dataDisplay.length > 1 && (
              <ChartDataSelector
                className="desktop-only"
                toggleCheckbox={toggleCheckbox}
                options={dataDisplay}
                chartType={chartType}
              />
            )}
            {!hideChartTypeSelector && (
              <SlideButtonsGroup
                action={updateChartType}
                options={chartTypes}
                current={chartType}
              />
            )}
          </div>
        </div>
        <ActiveUsersChart
          items={chartItems}
          chartType={chartType}
          chartDataType={currentChart}
          Xaxis="date"
          Yaxis="count"
          dataDisplay={dataDisplay}
          formatXAxisLabel={formatXAxisLabel}
        />
        <div className="activities-chart__options-container activities-chart__options-container-mob">
          <ChartDataSelector
            className="mobile-only"
            toggleCheckbox={toggleCheckbox}
            options={dataDisplay}
            chartType={chartType}
          />
        </div>
      </Container>
    </>
  );
};

export default AnalyticsActivitiesChart;
