import {
  ILearningAreaFetchParams,
  ILearningAreasFetchParams,
  getLearningArea,
  getLearningAreas,
} from 'services/learningAreas';
import { ILearningArea } from './types';
import { handleErrors } from '../helpers';

export const getLearningAreasAction = async (
  params?: ILearningAreasFetchParams
): Promise<{ results: ILearningArea[]; count: number } | Error> => {
  try {
    const { data } = await getLearningAreas(params);
    return {
      results: data.results,
      count: data.count,
    };
  } catch (error) {
    return handleErrors(error);
  }
};

export const getLearningAreaAction = async (
  params: ILearningAreaFetchParams
): Promise<{ LearningArea: ILearningArea } | Error> => {
  try {
    const { data } = await getLearningArea(params);
    return {
      LearningArea: data,
    };
  } catch (error) {
    return handleErrors(error);
  }
};
