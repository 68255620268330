import React, { useState } from 'react';
import { Container, Button, Option } from './styles';

export type Options = {
  value: string;
  id: string;
};
export interface IToggleButtons {
  firstOption: Options;
  secondOption: Options;
  onChange: (option: string) => void;
}

const ToggleButtons: React.FC<IToggleButtons> = ({ firstOption, secondOption, onChange }) => {
  const [toggle, setToggle] = useState(true);

  const triggerToggle = (option: string) => {
    setToggle(!toggle);
    onChange(option);
  };

  return (
    <Container>
      <Button
        size="small"
        $terciary
        onClick={() => triggerToggle(firstOption.id)}
        $isActive={toggle}
      >
        <Option toggled={toggle}>{firstOption.value}</Option>
      </Button>
      <Button
        size="small"
        $terciary
        onClick={() => triggerToggle(secondOption.id)}
        $isActive={!toggle}
      >
        <Option toggled={!toggle}>{secondOption.value}</Option>
      </Button>
    </Container>
  );
};

export default ToggleButtons;
