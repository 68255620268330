import { useContext, useState } from 'react';
import Button from 'components/button';
import { StickyFooter } from 'components/cards';
import ModalDrawer from 'components/microLayouts/drawer';
import { DrawerContainer } from 'components/usersTable/styles';
import { NotesContext } from 'contexts/notes';
import { FilterItem } from 'contexts/notes/types';
import NoteDatesFilter from '../NotesFilters/NoteDatesFilter';
import NoteUsersFilter from '../NotesFilters/NoteUsersFilter';
import SearchNotesFilter from '../NotesFilters/SearchNotesFilter';

export interface NotesFiltersProps {
  created_by: string;
  from_date: string;
  to_date: string;
  search: string;
}

interface INotesFiltersModal {
  isModalOpen: boolean;
  onClose(): void;
  onClearFilters(): void;
  onApplyFilters: (filters: NotesFiltersProps) => void;
}

const NotesFiltersModal = ({
  isModalOpen,
  onClose,
  onClearFilters,
  onApplyFilters,
}: INotesFiltersModal): JSX.Element => {
  const { filters } = useContext(NotesContext);
  const [modalFilters, setModalFilters] = useState<NotesFiltersProps>({
    created_by: filters.created_by,
    from_date: filters.from_date,
    to_date: filters.to_date,
    search: filters.search,
  });

  const handleFilterChange = (filter: FilterItem) => {
    setModalFilters((prevState) => ({ ...prevState, [filter.name]: filter.value.toString() }));
  };

  const handleDateChages = (filters: FilterItem[]) => {
    setModalFilters((prevState) => ({
      ...prevState,
      from_date: filters[0]?.value?.toString() || null,
      to_date: filters[1]?.value?.toString() || null,
    }));
  };
  return (
    <>
      <ModalDrawer
        closable={false}
        placement="bottom"
        visible={isModalOpen}
        onClose={onClose}
        height="92%"
        destroyOnClose
      >
        <DrawerContainer style={{ padding: '0px', paddingTop: '0px', gap: '20px' }}>
          <SearchNotesFilter modalScreen={true} searchFilterChange={handleFilterChange} />
          <NoteDatesFilter modalScreen={true} datesFilterChange={handleDateChages} />
          <NoteUsersFilter
            modalScreen={true}
            modalValues={modalFilters.created_by}
            usersFilterChange={handleFilterChange}
          />
        </DrawerContainer>
        <StickyFooter>
          <Button className="w-100" $secondary onClick={() => onClearFilters()}>
            Clear filters
          </Button>
          <Button className="w-100" $primary onClick={() => onApplyFilters(modalFilters)}>
            Apply filters
          </Button>
        </StickyFooter>
      </ModalDrawer>
    </>
  );
};

export default NotesFiltersModal;
