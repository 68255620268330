import { useEffect, useState } from 'react';
import { Skeleton } from 'antd';
import { getUserAssignedPlaylistAction } from 'contexts/playlists/actions';
import AssignedPlaylistItem from './AssignedPlaylistItem';
import { AssignedPlaylistsContainer, AssignedPlaylistsList } from './styles';

interface AssignedPlaylistsCardProps {
  uaa_id: string;
}

const AssignedPlaylistsCard = ({ uaa_id }: AssignedPlaylistsCardProps): JSX.Element => {
  const [fetching, setFetching] = useState(false);
  const [asssignedPlaylistsData, setAssignedPlaylistsData] = useState(null);

  const getAssignedPlaylists = async () => {
    setFetching(true);
    const data = await getUserAssignedPlaylistAction(uaa_id);
    if (!(data instanceof Error)) {
      setAssignedPlaylistsData(data);
      setFetching(false);
    } else {
      setFetching(false);
    }
  };

  useEffect(() => {
    if (uaa_id) {
      getAssignedPlaylists();
    }
  }, [uaa_id]);
  return (
    <AssignedPlaylistsContainer>
      <h1>Playlists assigned</h1>
      {fetching ? (
        <Skeleton />
      ) : (
        <>
          {asssignedPlaylistsData && asssignedPlaylistsData.results.length > 0 ? (
            <AssignedPlaylistsList>
              {asssignedPlaylistsData.results.map((playlist, index) => {
                return <AssignedPlaylistItem key={index} playlist={playlist} />;
              })}
            </AssignedPlaylistsList>
          ) : (
            <div>No playlists assigned.</div>
          )}
        </>
      )}
    </AssignedPlaylistsContainer>
  );
};

export default AssignedPlaylistsCard;
