import { useContext, useState, FormEvent, useEffect } from 'react';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { UsersContext } from 'contexts/users';

const SearchFilter = (): JSX.Element => {
  const { actions, filters } = useContext(UsersContext);
  const [value, setValue] = useState(filters.search);

  useEffect(() => {
    setValue(filters.search);
  }, [filters.search]);

  const handleSearchInput = (e: FormEvent<HTMLInputElement>): void => {
    setValue(e.currentTarget.value);
  };

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (value !== filters.search) {
      actions && actions.onChangeFilters({ name: 'search', value: value }, false, true);
    }
  };

  return (
    <form
      className="col input-group input-group-solid align-items-center ps-4 rounded"
      onSubmit={onSearchSubmit}
      style={{ backgroundColor: '#F2F2F7', flexGrow: 1, margin: '0 4px' }}
    >
      <SearchIcon />
      <input
        type="text"
        className="form-control"
        placeholder="Search"
        aria-label="search user"
        aria-describedby="basic-search"
        onChange={handleSearchInput}
        value={value}
      />
    </form>
  );
};

export default SearchFilter;
