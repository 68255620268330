import { TEAM_MANAGER } from 'constants/roles';
import { UserTeam } from 'contexts/teams/types';

export interface IGenericUsersTableProps {
  accountPage?: boolean;
  refreshData?(): void;
}

interface ITableColumn {
  name: string;
  subcolumns: string[];
  tooltip?: string;
}

export interface IUsersTableConfig {
  columnsTableConfig: string;
  rowTableConfig: string;
  columns: ITableColumn[];
}

export const getUsersTableConfig = (
  staffUser: boolean,
  accountPage: boolean,
  filterActive: boolean
): IUsersTableConfig => {
  if (!staffUser) {
    return {
      columnsTableConfig: filterActive ? '5% 30% 15% 10% 10% 10% 10%' : '5% 30% 15% 10% 10% 0% 10%',
      rowTableConfig: '5% 30% 25% 10% 10% 10% 10%',
      columns: [
        { name: 'Users', subcolumns: null },
        { name: 'Teams', subcolumns: null },
        { name: 'Manager', subcolumns: null },
        { name: 'License', subcolumns: null },
        filterActive && { name: 'Skill Dive', subcolumns: null },
        {
          name: filterActive ? 'Last login' : 'Invited',
          subcolumns: null,
          tooltip: "User's last login to the platform",
        },
      ],
    };
  } else if (staffUser && !accountPage) {
    return {
      columnsTableConfig: '5% 30% 15% 10% 10% 8% 10%',
      rowTableConfig: '5% 30% 15% 10% 18% 10% 10%',
      columns: [
        { name: 'Users', subcolumns: null },
        { name: 'Account', subcolumns: null },
        { name: 'Owner', subcolumns: null },
        { name: 'Teams', subcolumns: null },
        { name: 'Manager', subcolumns: null },
        { name: 'License', subcolumns: ['Plan', 'Skill Dive'] },
      ],
    };
  } else {
    return {
      columnsTableConfig: '5% 30% 10% 10% 10% 12% 10%',
      rowTableConfig: '5% 30% 10% 20% 12% 10% 10%',
      columns: [
        { name: 'Users', subcolumns: null },
        { name: 'Owner', subcolumns: null },
        { name: 'Teams', subcolumns: null },
        { name: 'Manager', subcolumns: null },
        { name: 'License', subcolumns: ['Plan', 'Skill Dive'] },
        {
          name: filterActive ? 'Last login' : 'Invited',
          subcolumns: null,
          tooltip: "User's last login to the platform",
        },
      ],
    };
  }
};

export const isRoleManager = (role: string, teams: UserTeam[]): boolean => {
  return role === TEAM_MANAGER && Boolean(teams.length > 0);
};
