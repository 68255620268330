import styled from 'styled-components';
import { screenSm } from 'constants/styles/breakpoints';

export const HideOnMobile = styled.div`
  display: none;

  ${screenSm} {
    display: initial;
  }
`;
