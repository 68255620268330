import { BottomDataWrapper, GroupItemDataWrapper, GroupItemTitle, VerticalDivider } from './styles';
import ContentTypeTag from '../../components/AddContent/ContentTypeTag';
import { screenSm } from 'constants/styles/breakpoints';
import { Space, Typography } from 'antd';
import { camelize } from 'utils/helpers';
import { durationFromSecs } from 'utils/helpers';

import styled from 'styled-components';
import { CourseLearningArea } from '../styles';

interface ICourseItemData {
  data: any;
}

const CourseDifficulty = styled(Typography.Text)`
  display: none;

  ${screenSm} {
    display: initial;

    text-transform: capitalize;
    font-weight: 450;
    font-size: 12px;
    line-height: 14px;
    color: #57575c;
  }
`;

const CourseItemData = ({ data }: ICourseItemData): JSX.Element => {
  const {
    content_type,
    difficulty,
    name,
    duration_in_seconds,
    main_learning_area,
    learning_areas,
    instructors,
  } = data;
  const learningAreaName = main_learning_area || learning_areas?.[0];
  const instructorName = instructors?.[0]?.name || '';

  return (
    <GroupItemDataWrapper>
      <Space>
        <ContentTypeTag value={content_type} />
        <CourseDifficulty>{difficulty}</CourseDifficulty>
      </Space>
      <GroupItemTitle>{name}</GroupItemTitle>
      <BottomDataWrapper>
        <CourseLearningArea value={learningAreaName}>{learningAreaName}</CourseLearningArea>
        <VerticalDivider />
        {instructorName}
        <VerticalDivider />
        {durationFromSecs(duration_in_seconds)}
      </BottomDataWrapper>
    </GroupItemDataWrapper>
  );
};

export default CourseItemData;
