import { useContext } from 'react';
import AccountsTable from './components/AccountsTable';
import AccountsHeader from './components/AccountsHeader';
import { AccountsContext } from 'contexts/accounts';
import styled from 'styled-components';
import { screenSm } from 'constants/styles/breakpoints';
import { isMobile } from 'react-device-detect';
import AccountsStats from './components/AccountsStats';
import AccountsFilters from './components/AccountsFilters';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  grid-gap: 20px;
`;

const ContentTable = styled.div<{ fetching: boolean }>`
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  opacity: 1;
  transition: opacity 0.4s;
  width: 100%;
  border-radius: 12px;

  ${screenSm} {
    background-color: #fff;
  }

  ${({ fetching }) =>
    fetching &&
    `
		opacity: 0.6;
		pointer-events: none;
	`}
`;

const ContentFilters = styled.div<{ fetching: boolean }>`
  display: flex;
  padding: 20px;
  margin-top: 0px;
  opacity: 1;
  border-radius: 12px;
  transition: opacity 0.4s;
  box-sizing: border-box;
  width: 100%;

  ${screenSm} {
    background-color: #fff;
    padding: 26px;
    min-width: 276px;
    max-width: 276px;
  }

  ${({ fetching }) =>
    fetching &&
    `
		opacity: 0.6;
		pointer-events: none;
	`}
`;

const AccountsContent = (): JSX.Element => {
  const { fetching } = useContext(AccountsContext);

  return (
    <>
      <AccountsStats />
      <Container>
        {!isMobile && (
          <ContentFilters fetching={fetching}>
            <AccountsFilters />
          </ContentFilters>
        )}

        <ContentTable fetching={fetching}>
          <AccountsHeader />
          <AccountsTable />
        </ContentTable>
      </Container>
    </>
  );
};

export default AccountsContent;
