import {
  AvatarContainer,
  ProfileData,
  ProfileHeaderActions,
  ProfileHeaderCard,
  ProfileHeaderGradient,
} from 'components/layout/profile/profileHeader/styles';
import HeaderActions from './HeaderActions';
import { UserDetail } from 'types/users';
import FirstLetterEmail from 'components/firstLetterEmail/firstLetterEmail';
import { DEFAULT_IMG_USERS } from 'constants/urls';
import { UserTeam } from 'contexts/teams/types';

interface UserProfileHeaderProps {
  user: UserDetail;
  user_invite: { id: string; role: string };
  onTeamsEdited: (userTeams: UserTeam[]) => void;
}

const UserProfileHeader = ({
  user,
  user_invite,
  onTeamsEdited,
}: UserProfileHeaderProps): JSX.Element => {
  return (
    <ProfileHeaderCard>
      <ProfileHeaderGradient>
        <AvatarContainer>
          {user?.uaa_data.profile.data.avatar &&
          user?.uaa_data.profile.data.avatar !== DEFAULT_IMG_USERS ? (
            <img src={user?.uaa_data.profile.data.avatar} />
          ) : (
            <FirstLetterEmail email={user?.uaa_data.email} />
          )}
        </AvatarContainer>
        <ProfileData>
          <h2>
            {user?.uaa_data.profile.data.first_name} {user?.uaa_data.profile.data.last_name}
          </h2>
          <p>{user?.uaa_data.email}</p>
        </ProfileData>
      </ProfileHeaderGradient>
      <ProfileHeaderActions>
        <HeaderActions user={user} user_invite={user_invite} onTeamsEdited={onTeamsEdited} />
      </ProfileHeaderActions>
    </ProfileHeaderCard>
  );
};

export default UserProfileHeader;
