import styled from 'styled-components';
import { DatePicker as AntdDatePicker } from 'antd';
const { RangePicker } = AntdDatePicker;

export const RangeDatePicker = styled(RangePicker)`
  background-color: transparent;
  border-color: transparent;
  box-shadow: unset;
  &:hover {
    border-color: transparent;
  }
  .ant-picker-range-separator,
  .ant-picker-input,
  .ant-picker-clear {
    display: none;
  }
  .ant-picker-suffix {
    svg path {
      stroke: #a793f6;
    }
  }
`;
