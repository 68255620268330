import { screenMd } from 'constants/styles/breakpoints';
import styled from 'styled-components';
import { Label } from 'components/modal/styles';

const Container = styled.div`
  margin-top: 20px;
  position: relative;
  ${screenMd} {
    padding: 0px;
  }
`;

const Textarea = styled.textarea`
  resize: none;
  font-size: 12px;
  padding: 15px 10px;
  margin: 0;
  background: #f2f2f7;
  width: 100%;
  border: none;
  border-radius: 10px;
  min-height: 100px;
  font-family: Circular-Std;
  color: #1e1e1e;
  &:focus {
    outline: none;
  }
`;

interface IPlaylistDescription {
  playlistDescription: string;
  setPlaylistDescription: (value: string) => void;
  playlist: boolean;
}

export const PlaylistDescriptionInput = ({
  playlistDescription,
  setPlaylistDescription,
}: IPlaylistDescription): JSX.Element => {
  return (
    <Container>
      <Label>Playlist description</Label>
      <Textarea
        placeholder="Describe the purpose of your playlist. Example: “Company wide cyber security training”"
        value={playlistDescription}
        onChange={(e) => setPlaylistDescription(e.currentTarget.value)}
      />
    </Container>
  );
};
