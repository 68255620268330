import { FC } from 'react';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { BarChartProps } from './types';
import { ChartTooltip } from './styles';
import { capitalize } from 'utils/helpers';

const CustomBarChart: FC<BarChartProps> = ({ chartConfig, barData }) => {
  const CustomTooltip = (payload: any) => {
    const { payload: payloadData } = payload;
    if (payloadData.active && payloadData.payload && payloadData.payload.length) {
      const dataKey = payloadData.payload[0].dataKey;
      const dataValue = payloadData.payload[0].payload[dataKey];
      return <ChartTooltip>{`${dataValue} ${capitalize(dataKey)}`}</ChartTooltip>;
    }
    return null;
  };
  return (
    <ResponsiveContainer width={chartConfig.width} height={chartConfig.height}>
      <BarChart data={barData.items}>
        <CartesianGrid
          strokeDasharray={chartConfig.strokeDashArray}
          vertical={chartConfig.verticalLines}
          horizontal={chartConfig.horizontalLines}
          stroke={chartConfig.stroke}
        />
        <XAxis
          dataKey={chartConfig.xAxisKey}
          axisLine={chartConfig.axisLine}
          tickLine={chartConfig.tickLine}
        />
        <YAxis
          dataKey={chartConfig.yAxisKey}
          axisLine={chartConfig.axisLine}
          tickLine={chartConfig.tickLine}
          tick={chartConfig.tick}
        />
        <YAxis />
        {barData.labels.map(({ key, color }) => (
          <>
            <Bar key={key} dataKey={key} {...chartConfig.barsConfig} fill={color} />
            <Tooltip
              key={key}
              shared={false}
              allowEscapeViewBox={{ x: true, y: false }}
              content={(payload) => <CustomTooltip key={key} payload={payload} />}
            />
          </>
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default CustomBarChart;
