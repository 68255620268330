import { useContext, useState } from 'react';
import { isPast } from 'date-fns';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { AccountsContext } from 'contexts/accounts';
import Checkbox from 'components/checkbox';
import Tooltip from 'components/tooltip';
import ButtonIcon from 'components/buttonIcon';
import { Cell, Pagination, Row, Table, Top } from 'components/Table';
import { AccountCell, AccountCellLink, Container, TypeLabel } from './styles';
import SortTable from 'components/sortTable';
import AccountsEmpty from '../AccountsEmpty';
import { SessionContext } from 'contexts/session';
import AccountsSettings from './AccountsSettings';
import DateCell from './DateCell';
import Switch from 'components/switch';

const accountsTableColumnsConfig = isMobile ? '40px 6fr 40px' : '4% 17% 10% 12% 23% 9% 12% 13%'; // 9 Columnas. cada una fraccionada.

const AccountsTable = (): JSX.Element => {
  const {
    table,
    actions,
    selectedItems,
    filters: { page_size, page, ordering },
    isEmpty,
  } = useContext(AccountsContext);
  const { actions: sessionActions, isImpersonated } = useContext(SessionContext);
  const [isImpersonating, setIsImpersonating] = useState('');

  const countRows = selectedItems?.length || 0;
  const selectedAllRows = Boolean(countRows > 0 && selectedItems?.length === table?.items?.length);
  const intermediateSelection = Boolean(
    countRows > 0 && selectedItems?.length !== table?.items?.length
  );

  const sso_toggled = (e: boolean, id: string) => {
    actions.onSsoToggled(e, id);
  };

  return (
    <Container>
      {!isEmpty ? (
        <Table>
          <Row
            columns={accountsTableColumnsConfig}
            transparent={isMobile}
            topSelect={isMobile}
            top={true}
            sticky
          >
            <Top topSelect={isMobile}>
              <Checkbox
                checked={selectedAllRows}
                indeterminate={intermediateSelection}
                onChange={() => actions?.onSelectAllRows()}
                label={isMobile ? `${countRows} accounts(s) selected` : undefined}
                darkBackground={isMobile}
                id="allAccountsSelected"
                controlled
                style={
                  isMobile
                    ? { justifyContent: 'flex-start', margin: '0 0 4px 8px', opacity: 0.6 }
                    : {}
                }
              />
            </Top>
            {!isMobile && (
              <>
                <Top>
                  ACCOUNTS
                  <SortTable
                    title="Sort by"
                    name="ordering"
                    position="left"
                    items={[
                      { label: 'ASC', value: 'name', selected: ordering === 'name' },
                      { label: 'DESC', value: '-name', selected: ordering === '-name' },
                    ]}
                    onSelect={actions.onChangeFilters}
                  />
                </Top>
                <Top>LICENSES</Top>
                <Top>SSO ENABLED</Top>
                <Top>REPRESENTATIVE</Top>
                <Top>TYPE</Top>
                <Top>
                  END DATE
                  <span>
                    <SortTable
                      title="Sort by"
                      name="ordering"
                      position="right"
                      items={[
                        {
                          label: 'ASC',
                          value: 'end_date',
                          selected: ordering === 'end_date',
                        },
                        { label: 'DESC', value: '-end_date', selected: ordering === '-end_date' },
                      ]}
                      onSelect={actions.onChangeFilters}
                    />
                  </span>
                </Top>
              </>
            )}
          </Row>
          {table?.items?.map((item, index) => {
            const isExpired = !!item.expire_date;
            const isPastDue = isPast(new Date(item.end_date));

            return (
              <Row columns={accountsTableColumnsConfig} key={index} disabled={isExpired}>
                <Cell>
                  <Checkbox
                    checked={isExpired ? false : Boolean(selectedItems?.includes(item.id))}
                    onChange={() => actions?.onSelectRow(item.id)}
                    controlled
                    disabled={isExpired}
                  />
                </Cell>
                <Cell
                  style={{
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                  }}
                >
                  <Link to={`/account/${item.id}`}>
                    <AccountCellLink>
                      <div>{item.name}</div>
                      <div style={{ fontWeight: '450' }}>{item.contacts?.[0]}</div>
                    </AccountCellLink>
                  </Link>
                  {isMobile && <TypeLabel>{item.pricing_plan}</TypeLabel>}
                </Cell>
                {!isMobile && (
                  <>
                    <Cell>
                      <AccountCell>
                        {item.licenses_details?.count === null ? (
                          <span style={{ fontSize: '15px', fontFamily: 'sans-serif' }}>∞</span>
                        ) : (
                          `${item.licenses_details.used} / ${item.licenses_details.count}`
                        )}
                      </AccountCell>
                    </Cell>
                    <Cell>
                      <AccountCell>
                        <Switch
                          checked={item.sso_enabled}
                          disabled={item.sso_enabled && item.sso_configured}
                          onChange={(e) => sso_toggled(e, item.id)}
                          style={{ marginRight: '10px' }}
                        />
                        {item.sso_enabled && !item.sso_configured && (
                          <small className="text-muted">*Not configured</small>
                        )}
                      </AccountCell>
                    </Cell>
                    <Cell>
                      <AccountCell>
                        <b>Sales: </b>
                        {item.representative.sales}
                        <br />
                        <b>Support: </b>
                        {item.representative.support}
                      </AccountCell>
                    </Cell>
                    <Cell>
                      <AccountCell>{item.pricing_plan}</AccountCell>
                    </Cell>
                    <Cell>
                      <DateCell
                        end_date={item.end_date}
                        status={isExpired ? 'expired' : isPastDue ? 'past_due' : 'active'}
                      />
                    </Cell>
                    <Cell style={{ minWidth: '131px', textAlign: 'end', gap: '10px' }}>
                      <>
                        <Tooltip placement="bottomRight" title={'Impersonate as account owner'}>
                          <div
                            style={{
                              cursor: item?.contacts.length <= 0 ? 'not-allowed' : 'pointer',
                            }}
                          >
                            <ButtonIcon
                              style={{
                                pointerEvents: item?.contacts.length <= 0 ? 'none' : 'all',
                              }}
                              disabled={item?.contacts.length <= 0 || isImpersonated}
                              $secondary
                              size={'small'}
                              icon={'Key'}
                              onClick={() => {
                                setIsImpersonating(item.contacts[0]);
                                sessionActions.impersonate(item.contacts[0]);
                              }}
                              loading={isImpersonating === item.contacts[0] && isImpersonated}
                            />
                          </div>
                        </Tooltip>
                        <Tooltip placement="bottomRight" title={'Plan management'}>
                          <div style={{ cursor: 'pointer' }}>
                            <ButtonIcon
                              $secondary
                              size={'small'}
                              icon={'Edit'}
                              onClick={() => {
                                actions.onAccountCreateEdit({ type: 'managePlan', account: item });
                              }}
                            />
                          </div>
                        </Tooltip>
                        <Tooltip placement="bottomRight" title={'Expire account'}>
                          <div
                            style={{
                              cursor: isExpired ? 'not-allowed' : 'pointer',
                            }}
                          >
                            <ButtonIcon
                              style={{
                                pointerEvents: isExpired ? 'none' : 'all',
                              }}
                              $secondary
                              size={'small'}
                              disabled={isExpired}
                              onClick={() => actions.onExpireAccount(item.id)}
                              icon={'Trash'}
                            />
                          </div>
                        </Tooltip>
                      </>
                    </Cell>
                  </>
                )}
                {isMobile && (
                  <AccountsSettings
                    data={item}
                    onExpireAccount={actions.onExpireAccount}
                    isExpired={isExpired}
                  />
                )}
              </Row>
            );
          })}
        </Table>
      ) : (
        <AccountsEmpty />
      )}

      {table.items && !isEmpty && (
        <Pagination
          count={table.count}
          page={parseInt(page)}
          page_size={parseInt(page_size)}
          onPagesClick={(value, name) => actions && actions.onChangeFilters({ name: name, value })}
        />
      )}
    </Container>
  );
};

export default AccountsTable;
