/**
 * `Card Container`
 * @param {ReactNode} children optionals components.
 * @returns {JSX.Element} Component.
 */

import { ReactNode } from 'react';
import { HeaderLabel } from 'components/layout/profile/profileHeader/styles';
import { Content, Litle } from './styles';

type Props = {
  children: ReactNode;
  title: string;
  flat?: boolean;
  containerMargin?: boolean;
  containerPadding?: boolean;
  contentMargin?: boolean;
  contentPadding?: boolean;
  width?: string;
};

const LitleContainer = ({
  children,
  title,
  flat,
  containerMargin,
  containerPadding,
  contentMargin,
  contentPadding,
  width,
}: Props): JSX.Element => (
  <Litle noMargin={containerMargin} noPadding={containerPadding} width={width}>
    <HeaderLabel>{title}</HeaderLabel>
    <Content noMargin={contentMargin} noPadding={contentPadding} flat={flat}>
      {children}
    </Content>
  </Litle>
);

export default LitleContainer;
