import { AxiosResponse } from 'axios';
import { config } from 'config/config.local';
import {
  AcitivityLogParams,
  ActivityLog,
  ActivityLogUsers,
  ActivityLogUsersParams,
} from 'contexts/activityLog/types';
import { BUSINESS_PYTHON } from './axios';

interface GetActivitiesLog {
  results: ActivityLog[];
  count: number;
}

interface GetActivityLogUsers {
  results: ActivityLogUsers[];
}

export interface BasicResponse {
  result?: boolean;
  status?: boolean;
}

export const getActivityLogs = (
  params: AcitivityLogParams
): Promise<AxiosResponse<GetActivitiesLog>> => {
  return BUSINESS_PYTHON.get(config.routes.activityLogs.getActivityLogs, { params });
};

export const getActivityLogUsers = (
  params?: ActivityLogUsersParams
): Promise<AxiosResponse<GetActivityLogUsers>> => {
  return BUSINESS_PYTHON.get(config.routes.activityLogs.getUsers, { params });
};
