import { Team } from 'contexts/teams/types';
import { IconTeam } from 'components/teamSelect/IconTeam';
import styled from 'styled-components';

interface ItemTeamProps {
  data: Team;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 15px;
  overflow: hidden;
  padding-right: 10px;
`;

const NameTeam = styled.p`
  margin: 0;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 3px;
  color: ${({ theme }) => theme.default.colors.palette.Business_DarkThemeGeneralBackground};
  &:first-letter {
    text-transform: uppercase;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const UsersInfo = styled.div`
  display: block;
  font-size: 13px;
  line-height: 16px;
  color: ${({ theme }) => theme.default.colors.palette.Business_LightThemeGrayDark};
`;

const Avatar = (props: ItemTeamProps): JSX.Element => {
  const { data } = props;

  return (
    <Container>
      <IconTeam iconName={data.icon?.name} color={data.icon?.color} size={'small'} />
      <Info>
        <NameTeam>{data.name}</NameTeam>
        {data.active_users > 0 && <UsersInfo>{'Active users: ' + data.active_users}</UsersInfo>}
        {data.pending_users > 0 && <UsersInfo>{'Pendings users: ' + data.pending_users}</UsersInfo>}
      </Info>
    </Container>
  );
};

export default Avatar;
