import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { AxisTick } from 'components/charts/common';
import { CHART_BAR_COLORS } from './configs';
import { BarsChartProps } from './types';

const TotalLabel = ({ value, x, y }: { value: number; x: number; y: number }) => {
  return (
    <g transform={`translate(${x + 60},${y + 11})`}>
      <text textAnchor="end" fill="#57575C" fontSize={'12px'} fontFamily="inherit">
        {value} items
      </text>
    </g>
  );
};

const TeamTopUsersChart = ({ data, tickImage }: BarsChartProps): JSX.Element => {
  const barConfig = {
    stackId: 'a',
    barSize: 15,
    radius: 4,
    minPointSize: 10,
  };

  return (
    <ResponsiveContainer width={'100%'} height={400}>
      <BarChart data={data} layout="vertical" margin={{ top: 5, right: 70, left: 60, bottom: 5 }}>
        <CartesianGrid vertical={false} opacity={0} />
        <XAxis tickLine={false} axisLine={false} type="number" tick={false} />
        <YAxis
          stroke="#E9E9ED"
          padding={{ top: 0, bottom: 0 }}
          dataKey="renderData"
          type="category"
          scale="band"
          axisLine={true}
          tickLine={false}
          tick={({ x, y, stroke, payload }) => (
            <AxisTick x={x} y={y} stroke={stroke} payload={payload} axis={'Y'} image={tickImage} />
          )}
        />
        <Bar dataKey="itemData" fill={CHART_BAR_COLORS[data[0].itemId]} {...barConfig} />
        {/* this bar is only for placing the label where it should be */}
        <Bar
          dataKey="label"
          stackId="a"
          label={({ value, x, y }) => <TotalLabel value={value} x={x} y={y} />}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default TeamTopUsersChart;
