import { format } from 'date-fns';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import ButtonIcon from 'components/buttonIcon';
import { SessionContext } from 'contexts/session';
import { PendingLetterAvatar } from 'components/usersTable/styles';
import { colorsArray } from 'utils/colors';
import { validateRole } from 'contexts/session/utilities';
import { getRandomInt } from 'utils/randomMax';
import Tooltip from 'components/tooltip';
import { UserInvites } from 'contexts/users/types';
import { DEFAULT_IMG_USERS } from 'constants/urls';
import { removeUsersInvites, resendInvites } from 'services/users';
import Notification from 'components/notification';
import { INE_URL } from 'constants/domains';
import { setUserRole } from 'components/usersTable/actions';
import { END_USER } from 'constants/roles';
import { getInvitationsAction } from 'contexts/users/actions';
import { colors } from 'constants/styles/colors';
import { CustomNavLink } from 'pages/Teams/components/styles';

const ItemContainer = styled.div<{ zIndex: number }>`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  width: 100%;
  z-index: ${({ zIndex }) => (zIndex ? zIndex : 'inherit')};
  > img {
    border-radius: 50%;
    margin-right: 10px;
    margin-top: -5px;
    border: 1px solid #fff;
    max-width: 50px;
    max-height: 50px;
    flex-shrink: 0;
  }
  > p {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    flex-grow: 1;
    max-width: 60%;
    padding-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: auto;
    > span {
      display: block;
      line-height: 14px;
      font-size: 12px;
      color: #d6d6d9;
    }
  }
`;

export interface AccOwnerItem extends UserInvites {
  email: string;
  first_name: string;
  last_name: string;
  id: string;
  uaa_id?: string;
  avatar?: string;
  invited_at: string;
  active?: boolean;
  zIndex?: number;
  invite_link?: string;
  accountId: string;
  operationCallback: () => void;
}

const AccountOwnerItem: FC<AccOwnerItem> = ({
  email,
  avatar,
  active,
  first_name,
  last_name,
  id,
  created_at,
  zIndex,
  invite_link,
  accountId,
  operationCallback,
}) => {
  const { user, actions: sessionActions, isImpersonated } = useContext(SessionContext);
  const [loadingBtn, setLoadingBtn] = useState<string>();
  const hasStaffRole = validateRole({
    email: user?.email,
    roles: user?.uaa_data?.roles?.data?.map(({ id }) => id),
  });
  const invitedDate = created_at && format(new Date(created_at), 'yyyy-MM-dd');

  useEffect(() => {
    setLoadingBtn(null);
  }, [isImpersonated]);

  const avatarColor = useMemo(() => {
    return colorsArray[getRandomInt(colorsArray.length)];
  }, []);

  const handleCancelInvite = async () => {
    setLoadingBtn('cancel');
    try {
      const response = await removeUsersInvites({ ids: [id] });
      response.status > 200 &&
        Notification({ type: 'success', text: 'Successfully cancel invitation' });
      operationCallback();
    } catch (error) {
      Notification({ type: 'error', text: 'Couldn´t cancel invitation' });
    } finally {
      setLoadingBtn(null);
    }
  };

  const handleReqData = () => {
    return {
      active: 'true',
      license: null,
      teams: null,
      roles: null,
      page: null,
      page_size: null,
      search: email,
      ordering: null,
      accounts: accountId,
    };
  };

  const getInvitationId = async () => {
    const invitationResponse = await getInvitationsAction(handleReqData());
    let invitationId = null;
    if (!(invitationResponse instanceof Error)) {
      invitationId = invitationResponse.results.length ? invitationResponse.results[0].id : null;
    }
    return invitationId;
  };

  const notifyError = () => {
    Notification({ text: 'Couldn´t remove Account Owner role', type: 'error' });
  };

  const handleRemoveAORole = async () => {
    try {
      setLoadingBtn('removeRole');
      const invitationId = await getInvitationId();
      if (!invitationId) {
        notifyError();
        return;
      }

      const response = await setUserRole({ id: invitationId, role: END_USER });
      if (!(response instanceof Error) && response.result) {
        Notification({ type: 'success', text: 'Account Owner role removed successfully' });
        operationCallback();
      } else {
        notifyError();
      }
    } catch (error) {
      notifyError();
    } finally {
      setLoadingBtn(null);
    }
  };

  const handleResendInvite = async () => {
    setLoadingBtn('resend');
    try {
      const response = await resendInvites({ ids: [id] });
      response.status === 200 &&
        Notification({ type: 'success', text: 'Successfully resend invitation' });
    } catch (error) {
      Notification({ type: 'error', text: 'Couldn´t resend invitation' });
    } finally {
      setLoadingBtn(null);
    }
  };

  const handleCopyInviteLink = () => {
    const urlElement = document.createElement('input');
    document.body.appendChild(urlElement);
    urlElement.value = `${INE_URL}/invite/${invite_link}`;
    urlElement.select();
    document.execCommand('copy');
    document.body.removeChild(urlElement);
    Notification({ text: 'Link copied to clipboard successfully', type: 'success' });
  };

  return (
    <ItemContainer zIndex={zIndex}>
      {active && avatar !== DEFAULT_IMG_USERS ? (
        <img src={avatar} />
      ) : (
        <PendingLetterAvatar
          key={email || first_name}
          color={avatarColor}
          style={{
            borderRadius: '50%',
            marginRight: '10px',
            marginTop: '-5px',
            border: '1px solid #FFF',
            flexShrink: 0,
          }}
        >
          {email?.[0].toUpperCase() || first_name[0].toUpperCase()}
        </PendingLetterAvatar>
      )}
      <p>
        {active ? (
          <CustomNavLink to={`/user/${id}`}>{`${first_name} ${last_name}`}</CustomNavLink>
        ) : (
          email
        )}
        {invitedDate && !active && <span>Invited {invitedDate}</span>}
      </p>
      {isDesktop && hasStaffRole && active && (
        <>
          <Tooltip placement="bottomRight" title={'Impersonate as account owner'}>
            <ButtonIcon
              style={{
                margin: '0px 10px 0 auto',
                pointerEvents: loadingBtn === 'impersonate' ? 'none' : 'all',
              }}
              loading={loadingBtn === 'impersonate'}
              disabled={isImpersonated || !hasStaffRole} // check if user is active
              $secondary
              size={'small'}
              icon={'Key'}
              onClick={() => {
                setLoadingBtn('impersonate');
                sessionActions.impersonate(email);
              }}
            />
          </Tooltip>
          <Tooltip placement="bottomRight" title={'Remove account owner role'}>
            <ButtonIcon
              style={{
                margin: '0px 10px 0 0',
                pointerEvents: loadingBtn === 'removeRole' ? 'none' : 'all',
              }}
              loading={loadingBtn === 'removeRole'} // check if user is an accountRole
              $secondary
              size={'small'}
              icon={'Trash'}
              onClick={handleRemoveAORole}
            />
          </Tooltip>
        </>
      )}
      {isDesktop && hasStaffRole && !active && (
        <>
          <Tooltip placement="bottomRight" title={'Copy link'}>
            <ButtonIcon
              style={{
                margin: '0px 10px 0 auto',
              }}
              $secondary
              size={'small'}
              icon={'Link'}
              onClick={handleCopyInviteLink}
            />
          </Tooltip>
          <Tooltip placement="bottomRight" title={'Resend invitation'}>
            <ButtonIcon
              style={{
                margin: '0px 10px 0 0',
                pointerEvents: loadingBtn === 'resend' ? 'none' : 'all',
              }}
              loading={loadingBtn === 'resend'} // check if user is active
              $secondary
              size={'small'}
              icon={'Resend'}
              onClick={handleResendInvite}
            />
          </Tooltip>
          <Tooltip placement="bottomRight" title={'Cancel invite'}>
            <ButtonIcon
              style={{
                margin: '0px 10px 0 0',
                pointerEvents: loadingBtn === 'cancel' ? 'none' : 'all',
              }}
              loading={loadingBtn === 'cancel'} // check if user is active
              $secondary
              size={'small'}
              icon={'Cancel'}
              onClick={handleCancelInvite}
            />
          </Tooltip>
        </>
      )}
    </ItemContainer>
  );
};

export default AccountOwnerItem;
