import { FC } from 'react';
import Icon from 'components/icons';
import { SSO_DOCUMENTATION_URL } from 'constants/settings';
import { RoundedTag, SsoInformationContainer } from '../AccountSsoCard/styles';

const SsoProviderInformation: FC = () => {
  return (
    <SsoInformationContainer>
      <span className="w-500">
        Create a Service Provider APP for INE in your Identity Provider (Idp) using these values:
      </span>
      <RoundedTag className="mt-5 sso-tag" color="#1A2535">
        <Icon size="small" icon="Copy" />
        ACS URL (Reply URL): https://auth.ine.com/saml2/idpresponse
      </RoundedTag>
      <RoundedTag className="mt-2 sso-tag" color="#1A2535">
        <Icon size="small" icon="Copy" />
        Entity ID: urn:amazon:cognito:sp:us-east-1_gxIiEWftA
      </RoundedTag>
      <div className="attributes-section">
        <div className="attributes-list">
          <span>
            IMPORTANT: Make sure these three fields are included in your attributes & claims:
          </span>
          <ul>
            <li>Given Name</li>
            <li>Family Name</li>
            <li>Email</li>
          </ul>
        </div>
      </div>
      <div className="documentation-section">
        For more information refer to{' '}
        <a href={SSO_DOCUMENTATION_URL} target="_blank" rel="noreferrer">
          the documentation
        </a>
      </div>
    </SsoInformationContainer>
  );
};

export default SsoProviderInformation;
