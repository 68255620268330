import React, { useMemo, useRef, useState } from 'react';
import { Select, SelectProps, Space, Spin } from 'antd';
import Checkbox from 'components/checkbox';
import { DateLabel, DateWrapper } from 'pages/Accounts/components/AccountsFilters/styles';
import { InputTypeSelectMultiple } from './styles';
import './styles.scss';
import { ReactComponent as TimesSvg } from 'assets/icons/times.svg';
import Tooltip from 'components/tooltip';
import SelectNotFoundElement from './selectNotFoundElement';
import Notification from 'components/notification';

interface InputSelectMultipleProps extends SelectProps {
  optionRender?: (option) => JSX.Element;
  customClassName?: string;
  values: string[];
  options?: {
    label: string;
    value: string;
    key: string | number;
  }[];
  inputLabel?: string;
  name?: string;
  maxDropdownHeight?: string;
  maxTagCount?: number;
  fetchOptionsFunc?: (value: string) => void;
  fetching?: boolean;
  isAllSelectedOption?: (option) => boolean;
  customFilterOptions?: (value, option) => boolean;
}

const InputSelectMultiple: React.FC<InputSelectMultipleProps> = ({
  optionRender,
  customClassName,
  values,
  inputLabel,
  name,
  options,
  maxTagCount,
  fetchOptionsFunc,
  fetching,
  isAllSelectedOption,
  customFilterOptions,
  ...rest
}) => {
  const { Option } = Select;
  const [searchQuery, setSearchQuery] = useState('');
  const [fetchingData, setFetchingData] = useState(false);

  const tagsLength = maxTagCount ?? 1;

  const getTooltipValues = () => {
    return values.slice(tagsLength).map((value, index) => {
      const option = options.find((option) => option.value === value);
      return <div key={index}>{option?.label}</div>;
    });
  };

  const CustomTagsPlaceholder = () => {
    return (
      <Tooltip placement="right" title={getTooltipValues()}>
        <div>+{values.length - tagsLength}...</div>
      </Tooltip>
    );
  };

  const OptionsPills = (): JSX.Element => {
    return (
      <>
        {currentOptions.map((option) => (
          <Option
            key={option.key}
            value={option.value}
            label={option.label}
            className={customClassName ?? ''}
          >
            <Space>
              <Checkbox
                checked={
                  values.includes(option.value) ||
                  (values.length === 0 &&
                    currentOptions.length > 0 &&
                    isAllSelectedOption &&
                    isAllSelectedOption(option))
                }
                controlled
              />
              {optionRender ? optionRender(option) : <span>{option.label}</span>}
            </Space>
          </Option>
        ))}
      </>
    );
  };

  const [currentOptions, setCurrentOptions] = useState(options);

  const timeoutRef = useRef(null);

  const logCompletedInput = async (value) => {
    if (value !== searchQuery) {
      try {
        setFetchingData(true);
        setCurrentOptions([]);
        setSearchQuery(value);
        await fetchOptionsFunc(value);
        setFetchingData(false);
      } catch (error) {
        setFetchingData(false);
        Notification({ text: 'There has been a problem fetching users', type: 'error' });
      }
    }
  };

  const handleSearch = (value) => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      logCompletedInput(value);
    }, 800);
  };

  const handleSelect = () => {
    logCompletedInput('');
  };

  useMemo(() => {
    if (fetching) return;
    setCurrentOptions(options);
  }, [options]);

  return (
    <>
      <DateWrapper>
        <DateLabel>{inputLabel}</DateLabel>
        <InputTypeSelectMultiple
          clearable={true}
          clearIcon={<TimesSvg />}
          value={values}
          mode="multiple"
          maxTagCount={maxTagCount ?? 'responsive'}
          maxTagPlaceholder={<CustomTagsPlaceholder />}
          dropdownClassName={`form-select-multiple-options ${name}-dropdown-items-wrapper`}
          optionLabelProp="label"
          filterOption={!customFilterOptions}
          onSearch={handleSearch}
          onSelect={handleSelect}
          notFoundContent={fetchingData ? <Spin size="small" /> : <SelectNotFoundElement />}
          onBlur={() => {
            logCompletedInput('');
          }}
          placement={'bottomRight'}
          {...rest}
        >
          {OptionsPills()}
        </InputTypeSelectMultiple>
      </DateWrapper>
    </>
  );
};

export default InputSelectMultiple;
