import { getCounts, getInvitations, getUsers, removeUsersInvites } from 'services/users';
import { UserInvites, UsersCounts } from './types';
import { UserFetchParams } from 'services/users';
import { handleErrors } from '../helpers';

// Get pending users list on this account.
export const getInvitationsAction = async (
  params: UserFetchParams
): Promise<{ results: UserInvites[]; count: number } | Error> => {
  try {
    const { data } = await getInvitations(params);
    return {
      results: data.results,
      count: data.count,
    };
  } catch (error) {
    return handleErrors(error);
  }
};

// Remove users invites.
export const getRemoveUsersAction = async ({
  ids,
}: {
  ids: string[];
}): Promise<{ results: boolean; count: number } | Error> => {
  try {
    const { data } = await removeUsersInvites({ ids });
    return {
      results: data.result,
      count: 0,
    };
  } catch (error) {
    return handleErrors(error);
  }
};

// Get active users list on this account.
export const getUsersAction = async (
  params: UserFetchParams
): Promise<{ results: UserInvites[]; count: number } | Error> => {
  try {
    const { data } = await getUsers(params);
    return {
      results: data.results,
      count: data.count,
    };
  } catch (error) {
    return handleErrors(error);
  }
};

// Get active and pendings user totals.
export const getUsersCounts = async ({
  active,
  account_id,
}: {
  active: boolean;
  account_id?: string;
}): Promise<{ results: UsersCounts } | Error> => {
  try {
    const { data } = await getCounts({ active, account_id });
    return data;
  } catch (error) {
    return handleErrors(error);
  }
};
