import React from 'react';
import Button from 'components/button/Button';

export interface AssignButtonProps {
  onClick: () => void;
}

const AssignButton: React.FC<AssignButtonProps> = ({ onClick, children, ...props }) => (
  <Button {...props} $secondary onClick={onClick} icon={'Plus'} style={{ padding: '8px 16px' }}>
    {children || 'Assign'}
  </Button>
);

export default AssignButton;
