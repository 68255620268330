import { NotesParams, NotesUsersParams } from './types';
import { handleErrors } from '../helpers';
import { getNotes, getNotesUsers } from 'services/notes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getNotesAction = async (
  params: NotesParams
): Promise<{ results: any; count: number } | Error> => {
  try {
    const { data } = await getNotes(params);
    return {
      results: data,
      count: data.count,
    };
  } catch (error) {
    return handleErrors(error);
  }
};

export const getNotesUsersAction = async (
  params: NotesUsersParams
): Promise<{ results: any } | Error> => {
  try {
    const { data } = await getNotesUsers(params);
    return {
      results: data,
    };
  } catch (error) {
    return handleErrors(error);
  }
};
