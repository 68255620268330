import styled from 'styled-components';

export const Header = styled.div`
  width: 100%;
  display: flex;
`;

export const Title = styled.h2`
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
`;

export const InfoColorContainer = styled.div`
  display: flex;
  gap: 5px;
`;

export const InfoColorReference = styled.div<{ bgcolor: string; borderColor: string }>`
  width: 15px;
  height: 15px;
  background-color: ${({ bgcolor }) => bgcolor || 'none'};
  border: 1px solid ${({ borderColor }) => borderColor};
  opacity: 0.9;
`;

export const FooterInfo = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 450;
  line-height: 14px;
`;

export const FooterContainer = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 450;
  line-height: 14px;
  width: 60%;
  display: flex;
  gap: 5px;
  align-items: flex-start;
  color: #777;
`;

export const InfoColorWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
