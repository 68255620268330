import { Popover as AntdPopover, PopoverProps } from 'antd';
import styled from 'styled-components';
import './styles.scss';

export const PopOverContentContainer = styled.div`
  min-width: 282px;
  max-height: 246px;
  padding: 36px 20px 20px 20px;
  position: relative;
  .close-btn {
    position: absolute;
    top: 17px;
    right: 17px;
    cursor: pointer;
  }
`;

export const PopoverTitle = styled.h4`
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.default.colors.fonts.dark};
  text-align: left;
  margin: 0;
`;

export const PopoverItem = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.default.colors.fonts.dark};
  text-align: left;
  margin: 0;
  > .muted-text {
    color: ${({ theme }) => theme.default.colors.fonts.lightGray};
  }
`;

export const PopoverLinks = styled.a<{ decoration?: boolean }>`
  font-size: 14px;
  color: ${({ theme }) => theme.default.colors.dataVisualization.purple.dark};
  font-weight: 600;
  &:hover {
    color: ${({ theme }) => theme.default.colors.dataVisualization.purple.dark};
  }
  ${({ decoration, theme }) =>
    decoration &&
    `
		padding-bottom: 2px;
    border-bottom: 1px solid ${theme.default.colors.dataVisualization.purple.dark};
	`}
`;

const Popover = ({ ...rest }: PopoverProps): JSX.Element => {
  return <AntdPopover overlayClassName={'override-popover-styles'} {...rest} />;
};

export default Popover;
