import { NavLink } from 'react-router-dom';
import { Space } from 'antd';
import { useContext, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { formatDistanceToNowStrict } from 'date-fns';
import { ACC_OWNER, TEAM_MANAGER } from 'constants/roles';
import { DEFAULT_IMG_USERS } from 'constants/urls';

import { SessionContext } from 'contexts/session';
import { UserInvites } from 'contexts/users/types';

import ButtonIcon from 'components/buttonIcon';
import Checkbox from 'components/checkbox';
import Switch from 'components/switch';
import Tooltip from 'components/tooltip';
import { Cell, EmptyCell } from 'components/Table';

import { randomColor } from 'utils/colors';
import {
  ActionButtonsContainer,
  ContainerDesktop,
  ContainerMobile,
  CustomRow,
  OwnerStarImg,
  PendingLetterAvatar,
  PendingUserImage,
  UserDataContainer,
} from './styles';
import {
  AccountCell,
  AccountStatusContainer,
  AccountStatusDot,
} from 'pages/Accounts/components/AccountsTable/styles';
import StaffUserSettingsDrawer from './StaffUserSettingsDrawer';
import { SwitchActions, SwitchLicenseActions } from './types';
import { UserTeamContainer } from 'pages/Users/components/InviteUsers/AdjustUsersSettings/styles';
import { UserTeamsPayload } from './types';
import OwnerStarSvg from 'assets/images/ownerStar.png';

export interface StaffUserRowProps extends UserInvites {
  onSwitchManager: (user_invite_id: string, teamsData: UserTeamsPayload[]) => void;
  onSwitchOwner?: ({ id, checked }: SwitchActions) => void;
  onSwitchLicense: ({ ids, checked }: SwitchLicenseActions) => void;
  onSelectRow: (id: string) => void;
  onRemoveUser: (ids: string[], name: string[]) => void;
  onCancelInvitation: (ids: string[], name: string[]) => void;
  onResendInvitation: (ids: string[]) => void;
  onSwitchAddon: (assigned_to?: string, addon?: string, license_addon_id?: string) => void;
  active?: boolean;
  role: string;
  row: UserInvites;
  rowSelected: boolean;
  tableGridConfig: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  uaa_data: any; // update with uaa data types,
  updated_at: string;
  accountPage?: boolean;
}

const StaffUserRow = ({
  onRemoveUser,
  onSelectRow,
  onSwitchLicense,
  onSwitchManager,
  onSwitchOwner,
  onCancelInvitation,
  onResendInvitation,
  onSwitchAddon,
  account,
  account_name,
  email,
  id,
  is_active,
  license,
  role,
  row,
  rowSelected,
  tableGridConfig,
  uaa_data,
  account_avaliable_licences,
  last_login,
  invited_at,
  accountPage,
  user,
  teams,
  account_available_addons,
  license_addons,
}: StaffUserRowProps): JSX.Element => {
  const { actions, isImpersonated } = useContext(SessionContext);
  const isRoleManager = role === TEAM_MANAGER;
  const isRoleAccOwner = role === ACC_OWNER;

  const loginOrInvitedDate = last_login || invited_at;

  const roleTooltipWarning = (
    <p className="text-center text-nowrap m-0">
      Users can only have one role assigned.
      <br />
      Changing a role will replace any existing roles.
    </p>
  );

  // Staff can always delete users
  const canRemoveUser = true;

  const isManagerSwitchDisabled = teams.length === 0;

  const getLicenseSwitchStatus = () => {
    const switchStatus = {
      disabled: false,
      tooltip: !license ? 'Assign License' : 'Remove License',
    };
    if (!license) {
      if (
        account_avaliable_licences !== 'unlimited' &&
        parseInt(account_avaliable_licences) === 0
      ) {
        switchStatus.disabled = true;
        switchStatus.tooltip = 'There are no licenses available on this account';
      }
    } else if (license_addons?.length > 0) {
      switchStatus.disabled = false;
      switchStatus.tooltip = `If the user's plan license is removed, the Skill Dive license will also be removed`;
    }
    return switchStatus;
  };

  const licenseSwitchStatus = getLicenseSwitchStatus();

  const licenseSwitchTooltipWarning = (
    <p className="text-center m-0">{licenseSwitchStatus.tooltip}</p>
  );

  const getAddonsSwitchStatus = () => {
    const addonsStatusSwitch = {
      disabled: true,
      tooltip: '',
    };
    if (account_available_addons?.length > 0) {
      // If user's account has addons on its plan allow to add them
      if (!license) {
        //If user has no license assigned, check if there are available licenses
        addonsStatusSwitch.disabled = true;
        addonsStatusSwitch.tooltip =
          'User must have a plan license assigned to this user to add Skill Dive';
      } else {
        // If user has a license check if it has addons license assigned
        if (license_addons?.length > 0) {
          // If user has a license addon allow to remove it
          addonsStatusSwitch.disabled = false;
          addonsStatusSwitch.tooltip = 'Remove Skill Dive license';
        } else if (account_available_addons[0]?.count > 0) {
          // If user has no license addon, check if there are available addons count
          addonsStatusSwitch.disabled = false;
          addonsStatusSwitch.tooltip = 'Assign Skill Dive license';
        } else {
          addonsStatusSwitch.disabled = true;
          addonsStatusSwitch.tooltip =
            'This account has no remaining Skill Dive Licenses available';
        }
      }
    } else {
      addonsStatusSwitch.disabled = true;
      addonsStatusSwitch.tooltip = 'This account has no Skill Dive add-on on its plan';
    }
    return addonsStatusSwitch;
  };

  const addonsSwitchStatus = getAddonsSwitchStatus();

  const addonsSwitchTooltipWarning = (
    <p className="text-center m-0">{addonsSwitchStatus.tooltip}</p>
  );

  const statusTagStates = {
    past_due: { bgColor: '#F28815', label: 'Past Due' },
    expired: { bgColor: '#EE3B00', label: 'Expired' },
    active: null,
  };
  const statusTagState = statusTagStates[row.account_status];

  const handleSwitchAddon = () => {
    //TODO change this method to support multiple addons
    if (license_addons.length > 0) {
      onSwitchAddon(null, null, license_addons[0].id);
    } else {
      onSwitchAddon(user, account_available_addons[0].id, null);
    }
  };

  useEffect(() => {
    getLicenseSwitchStatus();
  }, []);
  return (
    <>
      <CustomRow columns={tableGridConfig} collapsed={true}>
        {/* Checkbox */}
        <Cell>
          <Checkbox checked={rowSelected} onChange={() => onSelectRow(id)} controlled />
        </Cell>

        {/* User personal info */}
        <Cell>
          <div className="d-flex align-items-center justiy-left position-relative">
            {isRoleAccOwner && (
              <Tooltip placement="right" title="Account owner">
                <OwnerStarImg src={OwnerStarSvg} />
              </Tooltip>
            )}
            {is_active &&
            uaa_data?.profile?.data?.avatar &&
            uaa_data?.profile?.data?.avatar !== DEFAULT_IMG_USERS ? (
              <PendingUserImage src={uaa_data?.profile?.data?.avatar} alt={email} />
            ) : (
              <PendingLetterAvatar color={randomColor(email)}>
                {email && email[0].toUpperCase()}
              </PendingLetterAvatar>
            )}
            <UserDataContainer>
              {is_active && (
                <>
                  <NavLink to={`/user/${user}`}>
                    <p>{`${uaa_data?.profile?.data.first_name} ${uaa_data?.profile?.data.last_name}`}</p>
                    <ContainerDesktop>
                      <p>{email}</p>
                    </ContainerDesktop>
                  </NavLink>
                </>
              )}
              {!is_active && <p>{email}</p>}
              <ContainerMobile>
                {Boolean(license) && <span className="badge badge-light">LICENSE</span>}
              </ContainerMobile>
            </UserDataContainer>
          </div>
        </Cell>
        <>
          {/* Account Info */}
          {!!account_name && !accountPage && (
            <ContainerDesktop>
              <Cell
                style={{
                  flexFlow: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <NavLink
                  to={`/account/${account}`}
                  style={{
                    color: '#7965C6',
                    fontWeight: '700',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: '150px',
                  }}
                >
                  {account_name}
                </NavLink>
                <AccountCell
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'baseline',
                  }}
                >
                  {statusTagState && (
                    <AccountStatusContainer>
                      <AccountStatusDot bgColor={statusTagState.bgColor} />
                      <span>{statusTagState.label}</span>
                    </AccountStatusContainer>
                  )}
                </AccountCell>
              </Cell>
            </ContainerDesktop>
          )}

          {/* Owner switch */}
          <ContainerDesktop>
            <Cell style={{ minWidth: '60px' }} className="d-none d-md-table-cell bg-white">
              <Tooltip placement="bottomLeft" destroyTooltipOnHide title={roleTooltipWarning}>
                <span style={{ cursor: 'auto' }}>
                  <Switch
                    defaultChecked={isRoleAccOwner}
                    style={{ pointerEvents: 'auto' }}
                    onChange={(checked: boolean) => onSwitchOwner({ id: id, checked })}
                  />
                </span>
              </Tooltip>
            </Cell>
          </ContainerDesktop>

          {/* Team Info */}
          {!isMobile && (
            <Cell
              style={{
                fontWeight: '500',
                whiteSpace: 'initial',
              }}
            >
              <div style={{ width: '100%' }}>
                {teams.map((team, index) => (
                  <UserTeamContainer
                    key={team.team_id}
                    borderBottom={index < teams.length - 1}
                    width="85"
                  >
                    <div>{team.name}</div>
                    <Tooltip
                      placement="bottomLeft"
                      destroyTooltipOnHide
                      title={
                        isManagerSwitchDisabled ? 'User must belong to a team.' : roleTooltipWarning
                      }
                    >
                      <span style={{ cursor: isManagerSwitchDisabled ? 'not-allowed' : 'auto' }}>
                        <Switch
                          defaultChecked={team.is_manager}
                          disabled={isManagerSwitchDisabled}
                          onChange={(checked) =>
                            onSwitchManager(id, [{ team_id: team.team_id, is_manager: checked }])
                          }
                        />
                      </span>
                    </Tooltip>
                  </UserTeamContainer>
                ))}
                {teams.length === 0 && <span className="text-truncate">Unassigned teams</span>}
              </div>
            </Cell>
          )}

          {/* License switch  */}
          <ContainerDesktop>
            <Cell
              style={{
                minWidth: '60px',
                gap: is_active && '10px',
                padding: is_active && '0px',
                justifyContent: 'space-between',
              }}
            >
              <Tooltip placement="bottom" destroyTooltipOnHide title={licenseSwitchTooltipWarning}>
                <span style={{ cursor: licenseSwitchStatus.disabled ? 'not-allowed' : 'auto' }}>
                  <Switch
                    defaultChecked={Boolean(license)}
                    style={{ pointerEvents: licenseSwitchStatus.disabled ? 'none' : 'auto' }}
                    disabled={licenseSwitchStatus.disabled}
                    onChange={(checked: boolean) => onSwitchLicense({ ids: [id], checked })}
                  />
                </span>
              </Tooltip>
              {is_active && (
                <Tooltip placement="bottom" destroyTooltipOnHide title={addonsSwitchTooltipWarning}>
                  <span style={{ cursor: addonsSwitchStatus.disabled ? 'not-allowed' : 'auto' }}>
                    <Switch
                      defaultChecked={Boolean(license_addons?.length > 0)}
                      checked={Boolean(license_addons?.length > 0)}
                      style={{ pointerEvents: addonsSwitchStatus.disabled ? 'none' : 'auto' }}
                      disabled={addonsSwitchStatus.disabled}
                      onChange={handleSwitchAddon}
                    />
                  </span>
                </Tooltip>
              )}
            </Cell>
          </ContainerDesktop>
        </>
        {/*  Last active */}
        {accountPage && (
          <ContainerDesktop>
            <Cell>
              <p
                className="mb-0 text-left"
                style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
              >
                {loginOrInvitedDate ? (
                  formatDistanceToNowStrict(new Date(loginOrInvitedDate), { addSuffix: true })
                ) : (
                  <EmptyCell />
                )}
              </p>
            </Cell>
          </ContainerDesktop>
        )}

        {/* Actions on users */}
        <Cell style={{ justifyContent: 'flex-end' }}>
          <ActionButtonsContainer>
            <Space>
              {is_active ? (
                <ContainerDesktop>
                  <Tooltip placement="bottomRight" title={'Impersonate as this user'}>
                    <div
                      style={{
                        cursor: isImpersonated ? 'not-allowed' : 'pointer',
                        marginRight: '15px',
                      }}
                    >
                      <ButtonIcon
                        style={{ pointerEvents: isImpersonated ? 'none' : 'all' }}
                        disabled={isImpersonated}
                        $secondary
                        size={'small'}
                        icon={'Key'}
                        onClick={() => {
                          actions.impersonate(email);
                        }}
                      />
                    </div>
                  </Tooltip>
                  <Tooltip placement="bottomRight" title={'Remove user'} destroyTooltipOnHide>
                    <div style={{ cursor: canRemoveUser ? 'pointer' : 'not-allowed' }}>
                      <ButtonIcon
                        $secondary
                        size="small"
                        icon="Trash"
                        style={{ pointerEvents: canRemoveUser ? 'all' : 'none' }}
                        disabled={!canRemoveUser}
                        onClick={() =>
                          onRemoveUser([id], [uaa_data?.profile?.data.first_name || email])
                        }
                      />
                    </div>
                  </Tooltip>
                </ContainerDesktop>
              ) : (
                <>
                  <ContainerDesktop>
                    <Tooltip placement="bottomRight" title="Resend invitation">
                      <ButtonIcon
                        $secondary
                        size="small"
                        icon="Resend"
                        onClick={() => onResendInvitation([id])}
                      />
                    </Tooltip>
                  </ContainerDesktop>
                  <ContainerDesktop>
                    <Tooltip placement="bottomRight" title="Cancel invitation">
                      <ButtonIcon
                        $secondary
                        size="small"
                        icon="Cancel"
                        onClick={() => onCancelInvitation([id], [email])}
                      />
                    </Tooltip>
                  </ContainerDesktop>
                </>
              )}
              <ContainerMobile>
                <StaffUserSettingsDrawer
                  data={row}
                  disableManager={isManagerSwitchDisabled}
                  isAccOwner={isRoleAccOwner}
                  licenseSwitchStatus={licenseSwitchStatus}
                  isManager={isRoleManager}
                  onRemoveUser={onRemoveUser}
                  onSwitchLicense={onSwitchLicense}
                  onSwitchManager={onSwitchManager}
                  onSwitchOwner={onSwitchOwner}
                  roleTooltipText={roleTooltipWarning}
                  statusTagState={statusTagState}
                  onCancelInvite={() => onCancelInvitation([id], [email])}
                  onResendInvite={() => onResendInvitation([id])}
                />
              </ContainerMobile>
            </Space>
          </ActionButtonsContainer>
        </Cell>
      </CustomRow>
    </>
  );
};

export default StaffUserRow;
