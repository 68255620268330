import { FC, memo, useContext, useEffect, useState } from 'react';
import { isDesktop, isMobile } from 'react-device-detect';
import Bugsnag from '@bugsnag/js';
import useModal from 'hooks/useModal';
import { Pagination, Row, Table, Top } from 'components/Table';
import Checkbox from 'components/checkbox';
import { getInvitationsAction } from 'contexts/users/actions';
import UserRow, { ContainerDesktop } from 'components/usersTable/UserRow';
import SearchFilter from './SearchFilter';
import { SelectedCounts, Title } from 'components/layout/tableContentHeader/styles';
import StatusFilter from './StatusFilter';
import { Spinner } from 'components/loadings';
import { ActionModal } from 'components/modal';
import Notification from 'components/notification';
import { SessionContext } from 'contexts/session';
import {
  assignAddonLicense,
  resendUserInvites,
  setTeamManager,
  setUserLicense,
  setUserTeam,
  unassignAddonLicense,
} from 'components/usersTable/actions';
import useTeamUsersSearch from './useTeamUsersSearch';
import Button from 'components/button';
import { CustomTableCard, SpinerContainer, TeamUsersTableHeader } from './styles';
import EmptyState from './EmptyState';
import { SwitchLicenseActions } from 'components/usersTable/types';
import { Subcolumn } from 'components/usersTable/styles';
import Tooltip from 'components/tooltip';
import Icon from 'components/icons';

export interface UsersCount {
  pending: number;
  active: number;
}

export interface UserTeamsPayload {
  team_id: string;
  is_manager: boolean;
}

export interface TeamUsersTableProps {
  team: any;
  totalUsers: number;
  refreshData: () => void;
}

export interface TeamUsersTableBulkButtonsProps {
  active: boolean;
  onRemoveUsers(): void;
  onResendInvitation(): void;
}

export const TableBulkButtons: FC<TeamUsersTableBulkButtonsProps> = ({
  active,
  onRemoveUsers,
  onResendInvitation,
}) => {
  return (
    <>
      {!active && (
        <Button $secondary onClick={onResendInvitation}>
          Resend invitation
        </Button>
      )}
      <Button $secondary onClick={onRemoveUsers}>
        Remove users from team
      </Button>
    </>
  );
};

const TeamsUsersTable: FC<TeamUsersTableProps> = ({ team, totalUsers, refreshData }) => {
  const [fetching, setFetching] = useState(false);
  const [usersTable, setUsersTable] = useState(null);
  const [selecteds, setSelecteds] = useState([]);
  const { modal, setModal } = useModal();
  const { updateLicensesAvailables } = useContext(SessionContext);
  const { requestData, handlePagination, handleStatusFilter, handleSearchboxInput } =
    useTeamUsersSearch({ teamSlug: team.slug });

  const active = requestData.active === 'true';
  const columns = [
    { name: 'User', subcolumns: null },
    { name: 'Team', subcolumns: null },
    { name: 'Manager', subcolumns: null },
    { name: 'Plan license', subcolumns: null },
    active && { name: 'Skill Dive', subcolumns: null },
    {
      name: active ? 'Last login' : 'Invited',
      subcolumns: null,
      tooltip: "User's last login to the platform",
    },
  ];

  const tableConfig = active ? '5% 25% 20% 10% 10% 10% 10% 10%' : '5% 35% 20% 10% 10% 10% 10%';
  const rowTableConfig = active ? '5% 25% 30% 10% 10% 10% 10%' : '5% 35% 30% 10% 10% 10%';

  useEffect(() => {
    getUsers();
    setSelecteds([]);
  }, [requestData]);

  useEffect(() => {
    if (totalUsers !== usersTable?.count) getUsers();
  }, [totalUsers]);

  const getUsers = async () => {
    setFetching(true);
    const getUsersMethod = getInvitationsAction;
    const usersResponse = await getUsersMethod(requestData);
    setFetching(false);
    if (usersResponse instanceof Error) {
      Bugsnag.notify(usersResponse.message);
      setUsersTable((prevState) => ({ ...prevState, error: usersResponse.message }));
    } else {
      setUsersTable({
        users: usersResponse.results,
        count: usersResponse.count,
      });
    }
  };

  const handleCheckboxSelection = (id?: string, check?: boolean) => {
    if (id) {
      const findRow = selecteds.find((rowId) => rowId === id);
      if (findRow) setSelecteds((prevState) => prevState.filter((rowId) => rowId !== id));
      else setSelecteds((prevState) => [...prevState, id]);
    } else {
      if (check) {
        setSelecteds(usersTable?.users?.map(({ id }) => id));
      } else setSelecteds([]);
    }
  };

  const handleRemoveUser = (ids: string[], name?: string[]) => {
    const confirmMsg = name
      ? `Are you sure you want to remove ${name} from this team?`
      : `Are you sure you want to remove ${ids.length} users from this team?`;
    const onAcceptRemove = async () => {
      setFetching(true);
      setModal(null);
      const userTeamsFiltered = [];
      ids.forEach((userId) => {
        const userData = usersTable.users.find(({ id }) => id === userId);
        userTeamsFiltered.push(userData.teams.filter(({ team_id }) => team_id !== team.id));
      });

      const response = await setUserTeam({ ids, team: userTeamsFiltered });
      if (response instanceof Error) {
        Notification({ text: 'Couldn’t remove user from team', type: 'error' });
      } else {
        const licenses = usersTable.users.filter(
          ({ id, license }) => ids.includes(id) && license
        ).length;
        setUsersTable((prevState) => {
          return {
            count: prevState.count - ids.length,
            users: prevState.users.filter(({ id }) => !ids.includes(id)),
          };
        });
        licenses && updateLicensesAvailables(false, true);
        Notification({ text: 'User removed from team', type: 'success' });
        refreshData();
      }
      setFetching(false);
    };
    setModal(
      <ActionModal
        title={'Remove User'}
        description={confirmMsg}
        active={true}
        handleCancel={() => setModal(null)}
        buttons={[
          { label: 'Cancel', type: 'secondary', onClick: () => setModal(null) },
          {
            label: 'Remove user from team',
            type: 'primary',
            onClick: onAcceptRemove,
          },
        ]}
      />
    );
  };

  const handleSwitchManager = async (user_invite_id: string, teamsData: UserTeamsPayload[]) => {
    setFetching(true);
    const response = await setTeamManager({
      user_invite_id: user_invite_id,
      teams: teamsData,
    });
    setFetching(false);
    if (!(response instanceof Error) && response.result) {
      getUsers();
      Notification({ text: 'Role updated', type: 'success' });
    } else {
      Notification({ text: 'Role couldn’t be updated', type: 'error' });
    }
  };

  const handleSwitchLicense = async ({ ids, checked }: SwitchLicenseActions) => {
    setFetching(true);
    const response = await setUserLicense({ ids, license: checked });
    if (!(response instanceof Error) && response.result) {
      const newContent = getNewTableRows({ ids, type: 'license', value: checked });
      updateUsersTable(newContent);
      updateLicensesAvailables(checked, true);
      getUsers();
      Notification({ text: 'License updated', type: 'success' });
    } else {
      resetUsersTable();
      Notification({ text: 'License couldn’t be updated', type: 'error' });
    }
    setFetching(false);
  };

  const handleResendInvitation = async (ids: string[]) => {
    setFetching(true);
    const response = await resendUserInvites({ ids });
    setFetching(false);
    if (!(response instanceof Error) && response.result) {
      Notification({ text: 'Invitation successfully resent', type: 'success' });
    } else {
      Notification({ text: 'Invitation couldn’t be updated', type: 'error' });
    }
  };

  const resetUsersTable = () => {
    const originalContent = usersTable;
    setUsersTable(null);
    setUsersTable(originalContent);
  };

  const updateUsersTable = (newUsersTable) => {
    setUsersTable((prevState) => {
      return {
        ...prevState,
        users: newUsersTable,
      };
    });
  };

  const getNewTableRows = ({ type, value, ids }) => {
    const newTableRows = usersTable.users.map((item) => {
      if (ids.includes(item.id)) {
        return {
          ...item,
          [type]: value,
        };
      } else return item;
    });
    return newTableRows;
  };

  const handleSwitchAddon = async (
    assigned_to?: number | string,
    addon?: string,
    license_addon_id?: string
  ) => {
    setFetching(true);
    const is_assigning = !license_addon_id;
    let response = null;
    if (is_assigning) {
      response = await assignAddonLicense({ assigned_to, addon });
    } else {
      response = await unassignAddonLicense({ license_addon_id });
    }
    setFetching(false);
    if (!(response instanceof Error) && response) {
      Notification({
        text: `Skill Dive license ${is_assigning ? 'assigned' : 'removed'}`,
        type: 'success',
      });
      getUsers();
    } else {
      Notification({
        text: `Skill Dive license couldn’t be ${is_assigning ? 'assigned' : 'removed'}`,
        type: 'error',
      });
    }
  };

  return (
    <CustomTableCard>
      <TeamUsersTableHeader>
        <div className="d-md-block">
          {active ? (
            <Title className="mb-0">Active Users</Title>
          ) : (
            <Title className="mb-0">Pending Users</Title>
          )}
          <SelectedCounts className="text-muted d-md-block mb-0">
            {selecteds.length} users selected
          </SelectedCounts>
        </div>
        <SearchFilter onSearchSubmit={handleSearchboxInput} />
        {isDesktop && selecteds.length > 0 && (
          <TableBulkButtons
            active={active}
            onRemoveUsers={() => handleRemoveUser(selecteds)}
            onResendInvitation={() => handleResendInvitation(selecteds)}
          />
        )}
      </TeamUsersTableHeader>
      {!isDesktop && selecteds.length > 0 && (
        <TableBulkButtons
          active={active}
          onRemoveUsers={() => handleRemoveUser(selecteds)}
          onResendInvitation={() => handleResendInvitation(selecteds)}
        />
      )}
      <StatusFilter active={requestData?.active === 'true'} onStatusChange={handleStatusFilter} />

      {!usersTable ? (
        <SpinerContainer>
          <Spinner active top={'50%'} />
        </SpinerContainer>
      ) : (
        <Table>
          <Row columns={tableConfig} transparent={isMobile} topSelect={isMobile} top={true} sticky>
            <Top topSelect={isMobile}>
              <Checkbox
                checked={false}
                indeterminate={false}
                onChange={({ target }) => handleCheckboxSelection(null, target.checked)}
                label={null}
                darkBackground={isMobile}
                id="allTeamSelected"
              />
            </Top>
            {columns.map((column, index) => (
              <ContainerDesktop key={index}>
                <Top hasSubcolumn={column.subcolumns !== null && active}>
                  <span>{column.name}</span>
                  {column.tooltip && (
                    <Tooltip
                      placement="top"
                      title={column.tooltip}
                      overlayInnerStyle={{ textAlign: 'center' }}
                    >
                      <div style={{ marginLeft: '4px' }}>
                        <Icon
                          data-testid="info-icon"
                          icon="Info"
                          fill={'var(--lilac)'}
                          size="smaller"
                        />
                      </div>
                    </Tooltip>
                  )}
                  {active && column.subcolumns && (
                    <Subcolumn>
                      {column.subcolumns?.map((subcolumn, index) => (
                        <span key={index}>{subcolumn}</span>
                      ))}
                    </Subcolumn>
                  )}
                </Top>
              </ContainerDesktop>
            ))}
          </Row>

          {usersTable?.users?.length === 0 && <EmptyState />}
          {usersTable?.users?.map((row) => {
            return (
              <UserRow
                key={row.id}
                onSwitchManager={handleSwitchManager}
                onSwitchLicense={handleSwitchLicense}
                onSelectRow={handleCheckboxSelection}
                onCancelInvitation={handleRemoveUser}
                onResendInvitation={handleResendInvitation}
                onRemoveUser={handleRemoveUser}
                onSwitchAddon={handleSwitchAddon}
                rowSelected={selecteds.includes(row.id)}
                active={usersTable?.active === 'true'}
                teams={null}
                onSelectTeam={null}
                row={row}
                tableGridConfig={rowTableConfig}
                ta
                showTeamSelectorButton={false}
                {...row}
              />
            );
          })}
        </Table>
      )}

      {fetching && usersTable && (
        <SpinerContainer absolute>
          <Spinner active />
        </SpinerContainer>
      )}

      {usersTable && (
        <Pagination
          count={usersTable?.count}
          page={requestData.page}
          page_size={requestData?.page_size}
          onPagesClick={handlePagination}
          rowsPerPageList={['6', '12', '18', '24']}
        />
      )}
      {modal}
    </CustomTableCard>
  );
};

export default memo(TeamsUsersTable);
