import EmptyUsersSVG from 'assets/images/empty-users.svg';
import { EmptyCustomCard } from 'components/layout/profile/profileContent/styles';

const EmptyUsersCard = (): JSX.Element => (
  <EmptyCustomCard>
    <img src={EmptyUsersSVG} />
    <h5>You haven’t added any users yet</h5>
  </EmptyCustomCard>
);

export default EmptyUsersCard;
