import { FormEvent, useContext, useEffect, useState } from 'react';
import { NotesContext } from 'contexts/notes';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { FilterItem } from 'contexts/notes/types';

interface INoteUsersFilter {
  modalScreen?: boolean;
  modalValues?: string;
  searchFilterChange?: (filter: FilterItem) => void;
}

const SearchNotesFilter = ({ modalScreen, searchFilterChange }: INoteUsersFilter): JSX.Element => {
  const { actions, filters } = useContext(NotesContext);
  const [value, setValue] = useState(filters.search);

  const onInput = (e: FormEvent<HTMLInputElement>): void => {
    setValue(e.currentTarget.value);
    modalScreen &&
      searchFilterChange({
        name: 'search',
        value: e.currentTarget.value,
      });
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (value !== filters.search && !modalScreen) {
      actions && actions.onChangeFilter({ name: 'search', value: value });
    }
  };

  useEffect(() => {
    setValue(filters.search);
  }, [filters.search]);

  return (
    <form
      className="col input-group input-group-solid align-items-center ps-4 rounded"
      onSubmit={onSubmit}
      style={{ backgroundColor: '#F2F2F7' }}
    >
      <SearchIcon />
      <input
        type="text"
        className="form-control"
        placeholder="Search"
        aria-label="search user"
        aria-describedby="basic-search"
        onChange={onInput}
        value={value}
      />
    </form>
  );
};

export default SearchNotesFilter;
