import { useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { SessionContext } from 'contexts/session';
import { FilterProps } from './types';
import Checkbox from 'components/checkbox';
import { FilterCount, FilterWrapper } from './styles';
import { UsersContext } from 'contexts/users';
import ViewMore from 'components/viewMore';

const UsersAsideFiltersTeams = ({
  autoApply,
  onFilterChange,
  filter = [],
}: FilterProps): JSX.Element => {
  const { usersCounts } = useContext(UsersContext);
  const { teams } = useContext(SessionContext);
  const [allSelected, setAllSelected] = useState(filter[0] === '' || filter.length === 0);

  const [counts, setCounts] = useState(getCounts());

  function getCounts() {
    const teamsCount = usersCounts.teams_count || [];
    let total = 0;
    let types = [];
    for (const item in teamsCount) {
      total = teamsCount[item].slug !== 'no_team' ? total + teamsCount[item].count : total;
      types = { ...types, [teamsCount[item].slug]: teamsCount[item].count };
    }
    return { total, types };
  }

  useEffect(() => {
    const newState = filter[0] === '' || filter.length === 0;
    setAllSelected(newState);
    setCounts(getCounts());
  }, [filter]);

  const TeamItem = (item, index) => {
    return (
      counts.types[item.slug] > 0 && (
        <FilterWrapper key={index}>
          <Checkbox
            value={item.slug}
            onChange={(e) => {
              onFilterChange(e);
              setAllSelected(false);
            }}
            defaultChecked={!autoApply ? filter.includes(item.slug) : allSelected}
            checked={autoApply ? filter.includes(item.slug) : undefined}
            name="team"
            id={item.slug}
            label={item.name}
            controlled={!isMobile}
          />
          <FilterCount>{counts.types[item.slug] || 0}</FilterCount>
        </FilterWrapper>
      )
    );
  };

  const RenderTeams = () => {
    return (
      <>
        {teams &&
          teams.map &&
          teams?.map((item, index) => {
            if (index < 5) {
              return TeamItem(item, index);
            }
          })}
        {teams?.length >= 5 && (
          <ViewMore moreLabel="+ See more teams" lessLabel="- See less teams">
            <>
              {teams &&
                teams.map &&
                teams?.map((item, index) => {
                  if (index >= 6) {
                    return TeamItem(item, index);
                  }
                })}
            </>
          </ViewMore>
        )}
      </>
    );
  };

  return (
    <>
      <h3 className="mt-16 mb-5 fs-1 card-title">Teams</h3>
      <div className="users-aside-filters">
        <FilterWrapper>
          <Checkbox
            value={'all_teams'}
            onChange={(e) => {
              onFilterChange(e);
              setAllSelected(true);
            }}
            defaultChecked={!autoApply ? allSelected : undefined}
            checked={allSelected}
            name="team"
            id={'allTeams'}
            label={'All Teams'}
            controlled
          />
          <FilterCount>{counts.total || 0}</FilterCount>
        </FilterWrapper>

        <FilterWrapper>
          <Checkbox
            value={'no_team'}
            onChange={(e) => {
              onFilterChange(e);
              setAllSelected(false);
            }}
            defaultChecked={!autoApply ? filter.includes('no_team') : allSelected}
            checked={autoApply ? filter.includes('no_team') : undefined}
            name="team"
            id={'no_team'}
            label={'Unassigned Teams'}
            controlled={!isMobile}
          />
          <FilterCount>{counts.types['no_team'] || 0}</FilterCount>
        </FilterWrapper>

        <RenderTeams />
      </div>
    </>
  );
};

export default UsersAsideFiltersTeams;
