import { BrowserRouter as Router } from 'react-router-dom';
import { Routes } from 'routes';
import SessionProvider from 'contexts/session';
import 'react-multi-email/style.css';
const App = (): JSX.Element => {
  return (
    <Router>
      <SessionProvider>
        <Routes />
      </SessionProvider>
    </Router>
  );
};

export default App;
