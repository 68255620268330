import algoliasearch from 'algoliasearch';
import algoliasearchHelper from 'algoliasearch-helper';
import { SEARCH } from 'constants/algolia';

export interface IAlgoliaContext {
  facets: Array<any>;
  index: string;
  name: string;
}

export interface IAlgoliaKeys {
  appId: string;
  appKey: string;
}

interface IAlgoliaIndice {
  [key: string]: any;
}

interface IAlgoliaCreateIndices {
  keys: IAlgoliaKeys;
  contexts: Array<any>;
  resultsHandler: any | null;
  updatesHandler: any | null;
  disjunctiveFacets: boolean;
  initWithSearch: boolean;
}

export const createAlgoliaIndices = ({
  keys,
  contexts,
  resultsHandler,
  disjunctiveFacets,
}: IAlgoliaCreateIndices): any => {
  try {
    const instance = createAlgoliaSearchInstance(keys);
    const indices: IAlgoliaIndice = {};
    contexts.map((context: IAlgoliaContext) => {
      const { name, index, facets } = context;
      const selectedFacetsType = disjunctiveFacets ? { disjunctiveFacets: facets } : { facets };
      indices[name] = algoliasearchHelper(instance, index, selectedFacetsType);
      indices[name].algoliaIndex = instance.initIndex(index);
      indices[name].on('result', ({ results }) => resultsHandler(name, results));
    });

    return { instance, indices };
  } catch (error) {
    return;
  }
};

export const createAlgoliaSearchInstance = (keys: IAlgoliaKeys): any => {
  const { appId, appKey } = keys;
  const searchInstance = algoliasearch(appId, appKey);
  return searchInstance;
};

export const setupAlgoliaSearchInstance = (): any => {
  const { appKey, appId } = SEARCH.ALGOLIA_KEYS;
  const setup = {
    keys: {
      appId,
      appKey,
    },
    contexts: [
      {
        name: 'contentSearch',
        index: process.env.REACT_APP_ALGOLIA_SEARCH_INDEX,
        facets: SEARCH.AVAILABLE_FACETS,
      },
    ],
    disjunctiveFacets: true,
    initWithSearch: false,
    resultsHandler: null,
    updatesHandler: null,
  };
  const { indices } = createAlgoliaIndices(setup);
  return indices.contentSearch;
};
