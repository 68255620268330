// import ButtonIcon from 'components/buttonIcon';
import { IconTeam } from 'components/teamSelect/IconTeam';
// import Tooltip from 'components/tooltip';
import { screenMd } from 'constants/styles/breakpoints';
import { DeepTeams, Team } from 'contexts/teams/types';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

interface TeamInfo {
  team: Team | DeepTeams;
}

const TeamInfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 10px;
  ${screenMd} {
    padding: 0;
  }
  h2 {
    font-size: 24px;
    font-weight: 400;
    margin: 0 5px 0 10px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    box-sizing: border-box;
    ${screenMd} {
      font-size: 34px;
      margin: 0 10px 0 20px;
    }
  }
`;

const TeamInfo: React.FC<TeamInfo> = ({ team }) => {
  return (
    <TeamInfoContainer>
      <IconTeam
        size={isMobile ? 'small' : 'largest'}
        color={team.icon.color}
        iconName={team.icon.name}
      />
      <h2>{team.name}</h2>
      {/* <Tooltip placement="left" title={'Edit team'} zIndex={999999}>
				<ButtonIcon $terciary size={'small'} icon={'Edit'}/>
			</Tooltip> */}
    </TeamInfoContainer>
  );
};

export default TeamInfo;
