import styled from 'styled-components';
import { screenSm } from 'constants/styles/breakpoints';

export const TabsContainer = styled.div<{ vertical: boolean }>`
  display: ${({ vertical }) => (vertical ? 'block' : 'flex')};
  background-color: #f2f2f7;
  padding: ${({ vertical }) => (vertical ? '20px' : '5px 5px 0px 5px')};
  justify-content: space-evenly;
  ${({ vertical }) => vertical && 'border-radius: 20px; gap: 10px; height: 96%;'}
`;

export const TabButton = styled.div<{ active: boolean; vertical: boolean }>`
  display: none;
  background-color: ${({ active }) => (active ? '#FFFFFF' : 'transparent')};
  padding: 5px;
  margin-bottom: ${({ vertical }) => (vertical ? '5px' : '0')};

  cursor: pointer;
  border-radius: ${({ vertical }) => (vertical ? '10px' : '10px 10px 0 0')};
  ${({ vertical }) => vertical && 'height: fit-content;'}
  ${screenSm} {
    display: flex;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.5);
  }
`;

export const TabWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const TabIcon = styled.img`
  flex: 0;
`;
export const DataContainer = styled.div<{ vertical?: boolean }>`
  display: flex;
  align-items: center;
  ${({ vertical }) => !vertical && 'justify-content: space-between;'}
  flex-grow: 1;

  ${screenSm} {
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const DataNumber = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #1a2535;
`;

export const DataDescription = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #57575c;
`;

export const IconWrapper = styled.div`
  margin-right: 10px;
  background: #ffffff;
  border-radius: 50px;
  line-height: 0;
  display: flex;
  width: 28px;
  height: 28px;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

export const TabsTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin: 15px 0 15px 0;
`;
