import EmptyStateSvg from 'assets/images/empty-state-users.svg';
import { EmptyStateContainer, EmptyStateImg } from 'components/searchEmptyState/styles';

const EmptyState = (): JSX.Element => {
  return (
    <EmptyStateContainer>
      <EmptyStateImg src={EmptyStateSvg} />
      <h4>Sorry, we couldn’t find any matches</h4>
    </EmptyStateContainer>
  );
};

export default EmptyState;
