import styled from 'styled-components/macro';
import { ReactMultiEmail } from 'react-multi-email';
import { screenMd } from 'constants/styles/breakpoints';

export const StyledInputMultiEmail = styled(ReactMultiEmail)`
  &&& {
    background: rgb(242, 242, 247);
    border-radius: 10px;
    border-color: #f2f2f7;
    flex-grow: 0;
    max-height: 150px;
    overflow: auto;
    [data-placeholder] {
      color: #111a27;
      font-size: 14px;
      height: 16px;
      ${screenMd} {
        font-size: 12px;
      }
    }
    input {
      background: #f2f2f7;
      font-size: 16px;
      flex-grow: 1;
      ${screenMd} {
        font-size: 12px;
      }
    }

    [data-tag] {
      background: white;
      border-radius: 30px;
      font-size: 16px;
      font-weight: 500;
      padding: 8px 12px;
      line-height: 20px;
      ${screenMd} {
        padding: 5px 8px;
        font-size: 12px;
        line-height: 14px;
      }
      &.invalid {
        background: rgba(229, 42, 42, 0.1);
      }
    }

    [data-tag-handle] {
      color: #a793f6;
      font-size: 14px;
    }
  }
`;
