import styled from 'styled-components';
import Checkbox from 'components/checkbox/Checkbox';
import { ACC_OWNER, TEAM_MANAGER } from 'constants/roles';
import { UserInvites } from 'contexts/users/types';
import { AvatarLetter } from 'components/avatar';

const SuggestionContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid #f2f2f7;
  gap: 15px;

  > .user-data {
    font-size: 14px;
    font-weight: 400;
    > h4 {
      margin: 0;
    }
    > p {
      margin: 0;
      color: ${({ theme }) => theme.default.colors.fonts.darkGray};
    }
  }
`;

interface IUserSuggestion extends UserInvites {
  onCheck({ id, uaa_data, email, checked, has_license, is_manager, is_account_owner, teams }): void;
  checked: boolean;
}

const UserSuggestion: React.FC<IUserSuggestion> = ({
  id,
  license,
  uaa_data,
  onCheck,
  checked,
  role,
  teams,
}) => {
  const profileData = uaa_data?.profile?.data;
  const fullName = `${profileData.first_name}  ${profileData.last_name}`;
  const isAccountOwner = uaa_data
    ? uaa_data.roles.data.find((role) => role.name === ACC_OWNER) !== undefined
    : role === ACC_OWNER;
  return (
    <SuggestionContainer>
      <Checkbox
        id={`user-${id}`}
        name={`user-${id}`}
        onChange={({ target }) =>
          onCheck({
            id,
            uaa_data,
            has_license: !!license,
            is_manager: role === TEAM_MANAGER,
            email: uaa_data?.email,
            is_account_owner: isAccountOwner,
            checked: target.checked,
            teams: teams,
          })
        }
        checked={checked}
        controlled
      />
      <AvatarLetter avatar={profileData.avatar} name={fullName} email={uaa_data?.email} />
      <div className={'user-data'}>
        <h4>{fullName}</h4>
        <p>{uaa_data?.email}</p>
      </div>
    </SuggestionContainer>
  );
};

export default UserSuggestion;
