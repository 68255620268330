import React, { useEffect, useState } from 'react';
import { FilterChangeEvents } from './types';
import { FilterWrapper } from './styles';
import { getAccountsAction } from 'contexts/accounts/actions';
import InputSelectMultiple from 'components/inputs/inputSelectMultiple';
import useDebounce from 'hooks/useDebounce';

const defaultFilters = { page_size: '9', page: '1', search: null };
const defaultEmptyData = { count: 0, items: [] };

const isInViewport = (el) => {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

const UsersAsideFiltersAccounts = ({
  onFilterChange,
  filter: accountsFilter,
}: {
  onFilterChange: (e: FilterChangeEvents) => void;
  filter: string[];
}): JSX.Element => {
  const [fetching, setFetching] = useState(false);
  const [data, setData] = useState(defaultEmptyData);
  const [filters, setFilters] = useState(defaultFilters);
  const debouncedSearch = useDebounce(filters.search, 400);

  const handleSearch = (search) => {
    setFilters({ ...defaultFilters, search });
  };

  const loadAccounts = async (clearData?) => {
    setFetching(true);

    if (clearData) setData(defaultEmptyData);

    const accountsData = await getAccountsAction(filters);

    if (accountsData instanceof Error) {
      setData((prevState) => ({ ...prevState, error: accountsData?.message }));
    } else {
      setData((prevState) => ({
        count: accountsData?.results?.count,
        items: [...prevState.items, ...accountsData?.results?.results],
      }));
    }

    setFetching(false);
  };

  const handleScroll = (e) => {
    const lastChild = e.target.firstChild.firstChild.lastChild;

    if (!fetching && data.items.length !== data.count && isInViewport(lastChild))
      setFilters((prevState) => {
        let pageNumber = parseInt(prevState.page);

        pageNumber++;

        return {
          ...prevState,
          page: pageNumber.toString(),
        };
      });
  };

  useEffect(() => {
    loadAccounts();
  }, [filters.page]);
  useEffect(() => {
    loadAccounts(true);
  }, [debouncedSearch]);

  return (
    <>
      <h3 className="mt-16 mb-5">Account</h3>
      <div>
        <FilterWrapper>
          <InputSelectMultiple
            name="accounts"
            values={accountsFilter}
            placeholder={filters.search || 'All'}
            loading={fetching}
            options={data.items.map(({ id, name }) => ({
              key: id,
              value: id,
              label: name,
            }))}
            maxDropdownHeight={'150px'}
            onChange={(value) => {
              onFilterChange({ target: { value, name: 'accounts' } } as FilterChangeEvents);
            }}
            onPopupScroll={handleScroll}
            searchValue={filters.search}
            onSearch={handleSearch}
            filterOption={false}
          />
        </FilterWrapper>
      </div>
    </>
  );
};

export default UsersAsideFiltersAccounts;
