import { useContext, useEffect, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';

import { ReportsContext } from 'contexts/reporting';

import ButtonIcon from 'components/buttonIcon';
import Button from 'components/button/Button';
import Icon from 'components/icons';
import SpinnerIMG from 'assets/images/spinner.png';
import { Spin } from 'components/loadings/Spinner';
import { Pagination } from 'components/Table';

import ReportDetailsDrawer from './ReportDetailsDrawer';
import {
  Container,
  Filename,
  Header,
  HeaderCtaContainer,
  StatusContainer,
  Table,
  Title,
} from './ReportingHistoryComponents';
import { reportStatus } from 'contexts/reporting/utils';
import { ISorter } from 'contexts/reporting/types';
import { downloadReportAction } from 'contexts/reporting/actions';
import Notification from 'components/notification';

const ReportingHistoryTable = ({
  filtersType,
  sortingOptions,
  setSortingOptions,
}: {
  filtersType: string;
  sortingOptions: ISorter[];
  setSortingOptions: (value: ISorter[]) => void;
}): JSX.Element => {
  const { fetching, reports: reportsResp, getReports } = useContext(ReportsContext);
  const [hideReports, setHideReports] = useState(false);
  const [reportsQueryParams, setReportsQueryParams] = useState({});
  const [reports, setReports] = useState(reportsResp?.results);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  let setCurrentReportUrlLoading: (arg0: boolean) => void;

  // Tab change watcher
  useEffect(() => {
    setReportsQueryParams({ filtersType, sorting: sortingOptions });
    getReports({ filtersType, sorting: sortingOptions }, true);
  }, [filtersType]);

  // reportsResp change watcher
  useEffect(() => {
    setReports(reportsResp?.results);
  }, [reportsResp]);

  const onRefreshReportsList = () => {
    getReports(reportsQueryParams, true);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      reports.filter((r) => r.status === 'pending').length > 0 &&
        getReports(reportsQueryParams, false);
    }, 30000);
    return () => {
      clearInterval(intervalId);
    };
  }, [reports]);

  const StatusBlock = ({ status }: { status: string }): JSX.Element => {
    switch (status) {
      case reportStatus.completed:
        return (
          <>
            <Icon icon="StatusCompleted" size="small" /> Completed
          </>
        );
      case reportStatus.pending:
      case reportStatus.partialFinished:
        return (
          <>
            <Icon icon="StatusPending" size="small" /> Pending
          </>
        );
      default:
        return (
          <>
            <Icon icon="StatusError" size="small" /> Error
          </>
        );
    }
  };

  const onDownloadReport = async (id: string, setReportUrlLoading?: (arg0: boolean) => void) => {
    if (setReportUrlLoading) {
      setReportUrlLoading(true);
      setCurrentReportUrlLoading = setReportUrlLoading;
    }
    try {
      const reportUrlResponse = await downloadReportAction(id);
      window.open(reportUrlResponse.result.download_url);
      setCurrentReportUrlLoading && setReportUrlLoading(false);
    } catch (error) {
      setCurrentReportUrlLoading && setReportUrlLoading(false);
      Notification({
        text: 'There has been a problem generating the link to your report',
        type: 'error',
      });
    }
  };

  const DownloadReportButton = ({
    id,
    completed,
  }: {
    id: string;
    completed: boolean;
  }): JSX.Element => {
    const [reportUrlLoading, setReportUrlLoading] = useState(false);
    return (
      <ButtonIcon
        $secondary
        size="small"
        icon="Download"
        loading={reportUrlLoading}
        disabled={!completed}
        onClick={() => onDownloadReport(id, setReportUrlLoading)}
      />
    );
  };

  const onPagesClickHandler = (value: string, action: string) => {
    let queryParamsUpdate = {};
    if (action === 'page') {
      setCurrentPage(parseInt(value));
      queryParamsUpdate = Object.assign({}, reportsQueryParams, { page: parseInt(value) });
      setReportsQueryParams(queryParamsUpdate);
      getReports(queryParamsUpdate, true);
    } else {
      setPageSize(parseInt(value));
      queryParamsUpdate = Object.assign({}, reportsQueryParams, { page_size: parseInt(value) });
      setReportsQueryParams(queryParamsUpdate);
      getReports(queryParamsUpdate, true);
    }
  };

  const SortButton = ({ sortKey }: { sortKey: string }) => {
    const sortOption = sortingOptions.find((sortState) => sortState.sortKey === sortKey);
    const [sortAscIcon, setSortAscIcon] = useState(sortOption.asc);
    const SortCollection = () => {
      const currentSortingOptions = sortingOptions.map((sorter) => {
        if (sorter.sortKey === sortKey) {
          if (sorter.active) {
            sorter.asc = !sortAscIcon;
            setSortAscIcon(!sortAscIcon);
          }
          sorter.active = true;
        } else {
          sorter.active = false;
          sorter.asc = false;
          setSortAscIcon(false);
        }
        return sorter;
      });
      setSortingOptions(currentSortingOptions);
      const queryParamsUpdate = Object.assign({}, reportsQueryParams, {
        sorting: currentSortingOptions,
      });
      setReportsQueryParams(queryParamsUpdate);
      getReports(queryParamsUpdate, true);
    };

    return (
      <Button
        $primary={sortOption.active}
        $terciary={!sortOption.active}
        size="small"
        icon={sortAscIcon ? 'SortDown' : 'Sort'}
        disabled={fetching}
        onClick={() => SortCollection()}
      />
    );
  };

  return (
    <Container loading={fetching && isMobile}>
      <Header>
        <Title>Report history</Title>
        <HeaderCtaContainer>
          <ButtonIcon
            $secondary
            size="small"
            icon="Refresh"
            disabled={fetching}
            onClick={() => onRefreshReportsList()}
          />
          <ButtonIcon
            $terciary
            size="small"
            icon={hideReports ? 'ChevronDownPurple' : 'ChevronUpPurple'}
            onClick={() => setHideReports(!hideReports)}
          />
        </HeaderCtaContainer>
      </Header>
      <Table.Container hidden={hideReports}>
        <Table.Thead hidden={isTablet}>
          <Table.Tr borderBottom={true}>
            <Table.Th withSort={true}>
              File name
              <SortButton sortKey={'filename'} />
            </Table.Th>
            <Table.Th>Size</Table.Th>
            <Table.Th>Status</Table.Th>
            <Table.Th>Created by</Table.Th>
            <Table.Th withSort={true}>
              Created
              <SortButton sortKey={'created_at'} />
            </Table.Th>
            <Table.Th>Download</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {fetching && (
            <Table.Loading>
              <Table.Td>
                <Spin src={SpinnerIMG} style={{ width: '57px', height: '57px' }} />
              </Table.Td>
            </Table.Loading>
          )}
          {!fetching && reports?.length === 0 && (
            <Table.Tr>
              <Table.Td>{'You do not have reports yet.'}</Table.Td>
            </Table.Tr>
          )}
          {!fetching &&
            reports?.length > 0 &&
            reports?.map((report) => {
              const { id, filename, status, size, created_at, created_by } = report;
              return (
                <Table.Tr borderBottom={true} key={report.id} isTablet={isTablet}>
                  <Table.Td>
                    <Filename>{filename}</Filename>
                    <StatusContainer hiddenSm={true && !isTablet}>
                      <StatusBlock status={status} />
                    </StatusContainer>
                  </Table.Td>
                  <Table.Td hidden={isMobile} hiddenSm={true}>
                    {size}
                  </Table.Td>
                  <Table.Td hidden={isMobile} hiddenSm={true}>
                    <StatusContainer>
                      <StatusBlock status={status} />
                    </StatusContainer>
                  </Table.Td>
                  <Table.Td
                    hidden={isMobile}
                    hiddenSm={true}
                  >{`${created_by?.first_name} ${created_by?.last_name}`}</Table.Td>
                  <Table.Td hidden={isMobile} hiddenSm={true}>
                    {created_at}
                  </Table.Td>
                  <Table.Td centerContent={true}>
                    <span
                      style={
                        isMobile && status === reportStatus.completed
                          ? { display: 'block' }
                          : { display: 'none' }
                      }
                    >
                      <DownloadReportButton id={id} completed={status === reportStatus.completed} />
                    </span>
                    <span style={!isMobile ? { display: 'block' } : { display: 'none' }}>
                      <DownloadReportButton id={id} completed={status === reportStatus.completed} />
                    </span>
                  </Table.Td>
                  <Table.Td centerContent={true} hiddenMd={true && !isTablet}>
                    <ReportDetailsDrawer reportData={report} onDownloadClick={onDownloadReport} />
                  </Table.Td>
                </Table.Tr>
              );
            })}
        </Table.Tbody>
      </Table.Container>
      {!fetching && reports?.length > 0 && !hideReports && (
        <Pagination
          count={reportsResp.count}
          page={currentPage}
          page_size={pageSize}
          onPagesClick={onPagesClickHandler}
        />
      )}
    </Container>
  );
};

export default ReportingHistoryTable;
