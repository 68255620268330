import { getPricingPlans } from 'services/pricingPlans';
import { handleErrors } from '../helpers';
import { PricingPlan } from './types';

export const getPricingPlansActions = async (): Promise<PricingPlan[] | Error> => {
  try {
    const { data } = await getPricingPlans();
    return data.results;
  } catch (error) {
    return handleErrors(error);
  }
};
