import { screenLg, screenSm } from 'constants/styles/breakpoints';
import { useContext, useState } from 'react';
import AssignUsers from '../AssignUsers';
import CreatePlaylist from '../CreatePlaylist';
import { IPlaylist } from 'contexts/playlists/types';
import PlaylistCard from '../PlaylistCard';
import { PlaylistsContext } from 'contexts/playlists';
import { Pagination } from 'components/Table';
import { ConfirmModal } from 'components/modal';
import styled from 'styled-components';
import Notification from 'components/notification';

export const Container = styled.div<{ lock: boolean }>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 140px;
  gap: 10px;
  justify-content: center;
  ${screenSm} {
    justify-content: flex-start;
  }
  ${screenLg} {
    grid-gap: 20px 25px;
    padding-bottom: 0;
  }

  ${({ lock }) =>
    lock &&
    `
      opacity: 0.4;
      pointer-events: none;
  `}
`;

const editPlaylistState = {
  open: false,
  state: { title: null, confirmLabel: null, method: null, playlist: null },
};

const confirmModalDefaultState = {
  open: false,
  onAccept: null,
  description: '',
};

const PlaylistsCards = ({ lock }: { lock: boolean }): JSX.Element => {
  const { list, filters, actions } = useContext(PlaylistsContext);
  const [confirmModalState, setConfirmModalState] = useState(confirmModalDefaultState);

  const [editingPlaylist, setEditingPlaylist] = useState(editPlaylistState);
  const [playlistToEdit, setPlaylistToEdit] = useState<IPlaylist | null>(null);
  const [addingUsers, setAddingUsers] = useState(false);

  const handleEditPlaylist = (playlist: IPlaylist) => {
    setPlaylistToEdit(playlist);
    setEditingPlaylist({
      open: true,
      state: { title: 'Edit playlist', confirmLabel: 'Save changes', method: 'update', playlist },
    });
  };

  const handleClonePlaylist = (playlist: IPlaylist) => {
    const clonedPlaylist = { ...playlist };

    clonedPlaylist.name = `Copy of ${clonedPlaylist.name}`;
    delete clonedPlaylist.id;

    setEditingPlaylist({
      open: true,
      state: {
        title: 'Clone playlist',
        confirmLabel: 'Create playlist',
        method: 'create',
        playlist: clonedPlaylist,
      },
    });
  };

  const handleEditFinish = (newData?: any) => {
    setEditingPlaylist(editPlaylistState);
    if (newData) {
      actions.refreshPlaylists();
    }
  };

  const handleAddUsersFinish = () => {
    setAddingUsers(false);
    setPlaylistToEdit(null);
  };

  const handleAddUsersConfirm = () => {
    handleAddUsersFinish();
    actions.refreshPlaylists();
  };

  const handleAddUsers = (playlist) => {
    setPlaylistToEdit(playlist);
    setAddingUsers(true);
  };

  const handleAssignUser = async (playlist) => {
    setAddingUsers(false);
    actions.onAssignUser(playlist);
  };

  const handleUnassignUser = async (playlist) => {
    setAddingUsers(false);
    actions.onUnassignUser(playlist);
  };

  const deletePlaylist = async (playlist) => {
    const response = await actions.deletePlaylist(playlist);
    if (response.status === 204) {
      Notification({ text: `${playlist.name} has been deleted`, type: 'success' });
      actions.refreshPlaylists();
    } else Notification({ text: `Could not delete the ${playlist.name} Playlist`, type: 'error' });
    closeConfirmModal();
  };
  const handleDeletePlaylist = async (playlist) => {
    setConfirmModalState({
      open: true,
      onAccept: async () => await deletePlaylist(playlist),
      description: `Are you sure you want to perform this change? There's no way to undo this action.`,
    });
  };

  const closeConfirmModal = () => {
    setConfirmModalState(confirmModalDefaultState);
  };

  return (
    <Container lock={lock}>
      {list?.items?.map((playlist, index) => {
        return (
          <PlaylistCard
            playlist={playlist}
            key={index}
            onAddUsers={(playlist: IPlaylist) => handleAddUsers(playlist)}
            onDeletePlaylist={(playlist: IPlaylist) => handleDeletePlaylist(playlist)}
            onEditPlaylist={(playlist: IPlaylist) => handleEditPlaylist(playlist)}
            onClonePlaylist={(playlist: IPlaylist) => handleClonePlaylist(playlist)}
            onAssignUser={(playlist: IPlaylist) => handleAssignUser(playlist)}
            onUnassignUser={(playlist: IPlaylist) => handleUnassignUser(playlist)}
          />
        );
      })}

      <Pagination
        className="w-100"
        count={list.count}
        page={Number(filters.page)}
        page_size={Number(filters.page_size)}
        onPagesClick={(value, name) =>
          actions && actions.onChangeFilters({ name: name, value }, false, true)
        }
      />

      {editingPlaylist.open && (
        <CreatePlaylist
          {...editingPlaylist.state}
          isVisible={editingPlaylist.open}
          onClose={(data) => handleEditFinish(data)}
        />
      )}
      {addingUsers && (
        <AssignUsers
          isVisible={addingUsers}
          playlist={playlistToEdit}
          onCancel={handleAddUsersFinish}
          onConfirm={handleAddUsersConfirm}
        />
      )}

      <ConfirmModal {...confirmModalState} title="Are you sure?" onCancel={closeConfirmModal} />
    </Container>
  );
};

export default PlaylistsCards;
