import { ReactNode, useContext, useEffect, useState } from 'react';
import { UsersContext } from 'contexts/users';
import { SessionContext } from 'contexts/session';
import { BrowserView, MobileView, isDesktop, isMobile } from 'react-device-detect';
import SearchFilter from './Search';
import UserMobileTopBar from 'components/usersMobileTopBar';
import Button from 'components/button/Button';
import { ActionModal } from 'components/modal';
import './userHeaderFilters.scss';
import { setLicense } from 'services/users';
import Notification from 'components/notification';
import { resendUserInvites } from 'components/usersTable/actions';
import { SelectedCounts, Title } from 'components/layout/tableContentHeader/styles';
import InviteUsers from 'pages/Users/components/InviteUsers';
import { Invite } from 'pages/Users/components/InviteUsers/AddByEmails/invitesReducer';
import { validateAccountOwner } from 'contexts/session/utilities';

const UserHeaderFilters: React.FC = (): JSX.Element => {
  const {
    account,
    licenses_available,
    updateLicensesAvailables,
    allows: { can_delete_user, can_unnasign_license },
    actions: sessionActions,
    userRoleIds,
    isStaff,
  } = useContext(SessionContext);
  const isAccountOwner = validateAccountOwner(userRoleIds);
  const { selectedRows, filters, actions, table } = useContext(UsersContext);
  const countRows = selectedRows?.length || 0;
  const [withLicense, setWithLicense] = useState([]);
  const [noLicense, setNoLicense] = useState([]);
  const [actionModalOpen, setActionModalOpen] = useState<boolean>(false);
  const [blockedActions, setBlockedActions] = useState<boolean>(false);
  const [isEditingInvite, setEditingInvite] = useState<boolean>(false);
  const [actionModalOptions, setActionModalOptions] = useState<{
    title: string;
    description: string | ReactNode;
    buttons: Array<{
      disabled?: boolean;
      label: string;
      type: string;
      onClick: () => void;
    }>;
    onCancel: () => void;
  }>({
    title: '',
    description: '',
    buttons: [],
    onCancel: null,
  });

  useEffect(() => {
    setActionModalOpen(!actionModalOpen);
  }, [actionModalOptions]);

  useEffect(() => {
    if (selectedRows.length > 0) {
      setWithLicense(() =>
        table?.rows.filter((item) => selectedRows.includes(item.id) && item.license)
      );
      setNoLicense(() =>
        table?.rows.filter((item) => selectedRows.includes(item.id) && !item.license)
      );
    } else {
      setActionModalOpen(false);
    }
  }, [selectedRows]);

  const RemoveUsersModal = ({ total, onAccept }) => {
    setActionModalOptions({
      title: `Remove user${total > 1 ? 's' : ''}?`,
      description: `Are you sure you want to remove ${total} selected user${total > 1 ? 's' : ''}?`,
      onCancel: () => {
        setActionModalOpen(false);
      },
      buttons: [
        { label: 'Cancel', type: 'secondary', onClick: () => setActionModalOpen(false) },
        {
          label: `Remove user${total > 1 ? 's' : ''}`,
          type: 'primary',
          onClick: () => {
            onAccept();
            setActionModalOpen(false);
          },
        },
      ],
    });
  };

  const handleRemoveUsers = () => {
    RemoveUsersModal({
      total: selectedRows.length,
      onAccept: () => actions?.onRemoveTableItem(selectedRows, 'Successfully removed users'),
    });
  };

  const setLicenses = async ({ assign }: { assign: boolean }) => {
    if (!blockedActions) {
      setBlockedActions(true);
      const response = await setLicense({
        ids: selectedRows,
        license: assign,
      });
      if (response?.data?.result) {
        const loop: number = assign ? noLicense.length : withLicense.length;
        for (let index = 0; index < loop; index++) {
          updateLicensesAvailables(assign, true);
        }
        Notification({ text: 'Licenses updated', type: 'success' });
        const newTableRows = table.rows.map((item) => {
          if (selectedRows.includes(item.id)) {
            return {
              ...item,
              license: assign,
            };
          } else {
            return item;
          }
        });
        actions?.onTableUpdate([]);
        actions?.onTableUpdate(newTableRows);
      } else {
        Notification({ text: 'Licenses couldn’t be updated', type: 'error' });
      }
      setBlockedActions(false);
      setActionModalOpen(false);
    }
  };

  const RemoveLicensesModal = () => {
    setActionModalOptions({
      title: `Remove User${withLicense.length > 1 ? 's' : ''} Licenses`,
      description: `Are you sure you want to remove license for ${withLicense.length} selected users?`,
      onCancel: () => {
        setActionModalOpen(false);
      },
      buttons: [
        { label: 'Cancel', type: 'secondary', onClick: () => setActionModalOpen(false) },
        {
          label: 'Remove License',
          type: 'primary',
          onClick: () => {
            setLicenses({ assign: false });
          },
        },
      ],
    });
  };

  const AssignLicensesModal = () => {
    let title, description, acceptLabel;

    if (noLicense.length > 1) {
      title = `Assign Users Licenses`;
      description = `Are you sure you want to assign license for ${noLicense.length} selected users?`;
      acceptLabel = 'Assign Licenses';
    } else {
      title = `Assign User License`;
      description = `Are you sure you want to assign license for ${noLicense[0].email}?`;
      acceptLabel = 'Assign License';
    }

    setActionModalOptions({
      title,
      description,
      onCancel: () => setActionModalOpen(false),
      buttons: [
        { label: 'Cancel', type: 'secondary', onClick: () => setActionModalOpen(false) },
        {
          label: acceptLabel,
          type: 'primary',
          onClick: () => {
            setLicenses({ assign: true });
          },
        },
      ],
    });
  };

  const AssignALotLicensesModal = () => {
    setActionModalOptions({
      title: `Assign User${noLicense.length > 1 ? 's' : ''} Licenses`,
      description: `You selected ${noLicense.length} users, but you only have ${licenses_available} licenses available. Please, choose fewer than ${noLicense.length} users to continue.`,
      onCancel: () => setActionModalOpen(false),
      buttons: [{ label: 'Ok', type: 'secondary', onClick: () => setActionModalOpen(false) }],
    });
  };

  const handleManageLicenses = () => {
    if (withLicense.length > 0 && noLicense.length > 0) {
      setActionModalOptions({
        title: `Manage User${selectedRows.length > 1 ? 's' : ''} Licenses`,
        description: `Would you like to remove or assign licenses for ${selectedRows.length} selected users?`,
        onCancel: () => {
          setActionModalOpen(false);
        },
        buttons: [
          {
            label: 'Assign License',
            type: 'primary',
            onClick: () => {
              setActionModalOpen(false);
              noLicense.length > licenses_available
                ? AssignALotLicensesModal()
                : AssignLicensesModal();
            },
          },
          {
            label: 'Remove License',
            type: 'primary',
            onClick: () => {
              setActionModalOpen(false);
              RemoveLicensesModal();
            },
          },
        ],
      });
    } else {
      if (withLicense.length === selectedRows.length) {
        RemoveLicensesModal();
      } else if (noLicense.length > licenses_available) {
        AssignALotLicensesModal();
      } else {
        AssignLicensesModal();
      }
    }
  };

  const handleResendInvitations = async () => {
    const response = await resendUserInvites({ ids: selectedRows });
    if (!(response instanceof Error) && response.result) {
      Notification({ text: 'Invitations successfully resent', type: 'success' });
    } else {
      Notification({ text: 'Invitations couldn’t be updated', type: 'error' });
    }
  };

  const handleCancelInvitations = () => {
    let title, description, acceptLabel;

    if (selectedRows.length > 1) {
      title = 'Cancel Invites';
      description = `Are you sure you want to cancel the invites for these ${selectedRows.length} selected users?`;
      acceptLabel = 'Remove Invites';
    } else {
      title = 'Cancel Invite';
      description = `Are you sure you want to cancel ${
        table.rows.find((row) => row.id === selectedRows[0]).email
      } invite?`;
      acceptLabel = 'Remove Invite';
    }

    setActionModalOptions({
      title,
      description,
      onCancel: () => {
        setActionModalOpen(false);
      },
      buttons: [
        { label: 'Cancel', type: 'secondary', onClick: () => setActionModalOpen(false) },
        {
          label: acceptLabel,
          type: 'primary',
          onClick: () => {
            actions?.onRemoveTableItem(selectedRows, 'Invitations successfully cancelled');
            setActionModalOpen(false);
          },
        },
      ],
    });
  };

  const showModalInviteUsers = () => {
    setEditingInvite(true);
  };

  const handleClose = (result?: string, invites?: Invite[]) => {
    setEditingInvite(false);
    if (result === 'ok' && invites) {
      actions.onCountsUpdate();
      const newLicensesValue =
        typeof licenses_available === 'boolean'
          ? licenses_available
          : licenses_available - invites.filter(({ has_license }) => has_license).length;
      sessionActions.updateLicenses(newLicensesValue);
      actions.onChangeFilters({ name: 'active', value: false }, false, true);
    }
  };

  return (
    <div className="users-header-filters" style={isDesktop ? { marginBottom: '7px' } : {}}>
      <div className="d-flex justify-content-between mw-100 p-md-2 align-items-center">
        <BrowserView>
          <div className="d-md-block">
            {filters.active === 'true' ? (
              <Title className="mb-0">Active Users</Title>
            ) : (
              <Title className="mb-0">Pending Users</Title>
            )}
            <SelectedCounts className="text-muted d-md-block mb-0">
              {countRows} users selected
            </SelectedCounts>
          </div>
        </BrowserView>
        <div className="col ms-md-8 bg-white" style={{ padding: '14px 8px' }}>
          <MobileView>
            <UserMobileTopBar />
          </MobileView>
          <div className="d-flex flex-wrap align-items-center">
            <SearchFilter />
            {countRows > 0 && filters.active === 'true' && (
              <div className="mt-4 mt-md-0 w-100 w-md-auto d-flex">
                <Button
                  disabled={!can_unnasign_license}
                  $secondary
                  size={isMobile ? 'small' : null}
                  style={{ flexGrow: 1, margin: '0 4px' }}
                  onClick={handleManageLicenses}
                >
                  Manage license{selectedRows.length > 1 ? 's' : ''}
                </Button>
                <Button
                  disabled={!can_delete_user}
                  $secondary
                  size={isMobile ? 'small' : null}
                  style={{ flexGrow: 1, margin: '0 4px' }}
                  onClick={handleRemoveUsers}
                >
                  Remove user{selectedRows.length > 1 ? 's' : ''}
                </Button>
              </div>
            )}
            {countRows > 0 && filters.active === 'false' && (
              <>
                <div className="mt-4 mt-md-0 w-100 w-md-auto d-flex">
                  <Button
                    $secondary
                    size={isMobile ? 'small' : null}
                    onClick={handleResendInvitations}
                    style={{ flexGrow: 1, margin: '0 4px' }}
                  >
                    Resend invitation
                  </Button>
                  <Button
                    $secondary
                    size={isMobile ? 'small' : null}
                    onClick={handleCancelInvitations}
                    style={{ flexGrow: 1, margin: '0 4px' }}
                  >
                    Cancel invitation
                  </Button>
                </div>
              </>
            )}
            {isAccountOwner && (
              <div className="btn-add-user">
                <Button
                  $primary={true}
                  onClick={showModalInviteUsers}
                  icon={'Plus'}
                  style={{ flexGrow: 1, margin: '0 4px' }}
                >
                  Add users
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <ActionModal
        loading={blockedActions}
        title={actionModalOptions.title}
        description={actionModalOptions.description}
        active={actionModalOpen}
        buttons={actionModalOptions.buttons}
        handleCancel={actionModalOptions.onCancel}
      />

      {isEditingInvite && (
        <InviteUsers account={account} isEditingInvite={isEditingInvite} onClose={handleClose} />
      )}
    </div>
  );
};

export default UserHeaderFilters;
