import React, { FormEvent, useContext, useEffect, useState } from 'react';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { PlaylistsContext } from 'contexts/playlists';

const SearchFilter = (): JSX.Element => {
  const { actions, filters } = useContext(PlaylistsContext);
  const [value, setValue] = useState(filters.name);

  useEffect(() => {
    setValue(filters.name);
  }, [filters.name]);

  const handleSearchInput = (e: FormEvent<HTMLInputElement>): void => {
    setValue(e.currentTarget.value);
  };

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (value !== filters.name) {
      actions && actions.onChangeFilters({ name: 'name', value: value }, false, true);
    }
  };

  return (
    <form
      className="col input-group input-group-solid align-items-center ps-4 rounded"
      onSubmit={onSearchSubmit}
      style={{ backgroundColor: '#F2F2F7' }}
    >
      <SearchIcon />
      <input
        type="text"
        className="form-control"
        placeholder="Search"
        aria-label="search user"
        aria-describedby="basic-search"
        onChange={handleSearchInput}
        value={value}
      />
    </form>
  );
};

export default SearchFilter;
