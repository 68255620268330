import { useLocation } from 'react-router-dom';

interface PathHook {
  path: string;
  title: string;
}

function getPageTitle(path) {
  switch (path) {
    case '/users':
      return 'Users';
    case '/teams':
      return 'Teams';
    case '/accounts':
      return 'Accounts';
    case '/reporting':
      return 'Reporting';
    case '/analytics':
      return 'Analytics';
    default:
      return '';
  }
}

const usePath = (): PathHook => {
  const location = useLocation();
  const path = location.pathname;
  const title = getPageTitle(path);

  return {
    path,
    title,
  };
};

export default usePath;
