import { useState } from 'react';
import { getCategoriesAction } from 'contexts/categories/actions';
import { ICategory } from '../contexts/categories/types';

interface ICategoriesContentHook {
  fetching: boolean;
  error: string;
  categories: ICategory[];
  getCategories: () => void;
}

const useCategoriesContent = (): ICategoriesContentHook => {
  const [categories, setCategories] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState('');

  const getCategories = async () => {
    setFetching(true);

    const categoriesResponse = await getCategoriesAction();

    if (categoriesResponse instanceof Error) {
      setError(categoriesResponse.message);
      setCategories([]);
    } else {
      setError('');
      setCategories(categoriesResponse.results);
    }

    setFetching(false);
  };

  return {
    fetching,
    error,
    categories,
    getCategories,
  };
};

export default useCategoriesContent;
