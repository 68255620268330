import { AxiosResponse } from 'axios';
import { config } from 'config/config.local';
import {
  Account,
  Accounts,
  AccountsParams,
  GetCountsProps,
  SsoProvider,
  Staff,
} from 'contexts/accounts/types';
import { LICENSE_TYPES } from 'constants/plans_config';
import { BUSINESS_PYTHON } from './axios';
interface GetAccounts {
  results: Accounts[];
  count: number;
}

export interface CreateEditAccountPayload {
  name: string;
  start_date: string;
  end_date: string;
  pricing_plan?: string;
  license_type?: string;
  is_free_trial: boolean;
  is_invite_only: boolean;
  logo_url?: string;
  support: string | number;
  sales: string | number;
  addons?: AccountAddonPayload[];
}

export interface AccountAddonPayload {
  id: string;
  count: number;
}
export interface BasicResponse {
  result?: boolean;
  status?: boolean;
}

export interface CreateSsoProviderPayload {
  company_name: string;
  provider_name: string;
  saml_issuer_id: string;
  login_url: string;
  certificate: string;
  given_name: string;
  family_name: string;
  email: string;
}

export interface AccountEditPayload {
  name: string;
  is_free_trial: boolean;
  is_invite_only: boolean;
  start_date: string;
  end_date: string;
  support: string | number;
  sales: string | number;
}

export interface ChangePlanPayload {
  pricing_plan: string;
  license_count?: number;
  addons?: AccountAddonPayload[];
}

export const getAccountData = ({ id }: { id: string }): Promise<AxiosResponse<Account>> => {
  return BUSINESS_PYTHON.get(config.routes.users.getAccounts + id + '/');
};

export const getAccounts = (params: AccountsParams): Promise<AxiosResponse<GetAccounts>> => {
  return BUSINESS_PYTHON.get(config.routes.accounts.getAccounts, { params });
};

export const removeAccounts = ({
  ids,
}: {
  ids: string[];
}): Promise<AxiosResponse<BasicResponse>> => {
  return BUSINESS_PYTHON.post(config.routes.accounts.removeAccounts, { accounts_ids: ids });
};

export const expireAccount = (accountId: string): Promise<AxiosResponse<BasicResponse>> => {
  return BUSINESS_PYTHON.post(
    config.routes.accounts.getAccounts + accountId + '/' + config.routes.accounts.expire,
    {}
  );
};

export const createAccount = (data: CreateEditAccountPayload): Promise<AxiosResponse<Account>> => {
  return BUSINESS_PYTHON.post(config.routes.accounts.createAccount, data);
};

export const editAccount = (
  data: AccountEditPayload,
  id: string
): Promise<AxiosResponse<Account>> => {
  return BUSINESS_PYTHON.put(config.routes.accounts.createAccount + id + '/', data);
};

export const changeAccountPlan = (
  data: ChangePlanPayload,
  id: string
): Promise<AxiosResponse<Account>> => {
  return BUSINESS_PYTHON.patch(
    config.routes.accounts.getAccounts + id + '/' + config.routes.accounts.changePlan,
    data
  );
};

export const reactivateAccount = (
  accountId: string,
  { start_date, end_date }: { start_date: string; end_date: string }
): Promise<AxiosResponse<Account>> => {
  return BUSINESS_PYTHON.patch(
    config.routes.accounts.getAccounts + accountId + '/' + config.routes.accounts.reactivate,
    {
      start_date,
      end_date,
    }
  );
};

export const getCounts = (): Promise<AxiosResponse<GetCountsProps>> => {
  return BUSINESS_PYTHON.get(config.routes.accounts.getCounts);
};

export const getIneStaff = (): Promise<AxiosResponse<Staff[]>> => {
  return BUSINESS_PYTHON.get(config.routes.users.getStaff);
};

export const editDomains = ({
  email,
  id,
  domainId,
}: {
  email: string;
  id: string;
  domainId: string;
}): Promise<AxiosResponse<Account>> => {
  return BUSINESS_PYTHON.put(`${config.routes.accounts.emailDomains}${domainId}/`, {
    account: id,
    email_domain: email,
  });
};

export const createDomains = ({
  email,
  id,
}: {
  email: string;
  id: string;
}): Promise<AxiosResponse<Account>> => {
  return BUSINESS_PYTHON.post(`${config.routes.accounts.emailDomains}`, {
    account: id,
    email_domain: email,
  });
};

export const getDomains = ({ id }: { id: string }): Promise<AxiosResponse<unknown>> => {
  return BUSINESS_PYTHON.get(`${config.routes.accounts.emailDomains}${id}/`);
};

export const deleteDomain = ({
  domainId,
}: {
  domainId: string;
}): Promise<AxiosResponse<unknown>> => {
  return BUSINESS_PYTHON.delete(`${config.routes.accounts.emailDomains}${domainId}/`);
};

export const getSsoProvider = (accountId: string): Promise<AxiosResponse<SsoProvider>> => {
  return BUSINESS_PYTHON.get(`${config.routes.accounts.createAccount}${accountId}/sso_providers/`);
};

export const createSsoProvider = (
  data: CreateSsoProviderPayload,
  accountId: string
): Promise<AxiosResponse<SsoProvider>> => {
  return BUSINESS_PYTHON.post(
    `${config.routes.accounts.createAccount}${accountId}/sso_providers/`,
    data
  );
};

export const editSsoProvider = (
  data: CreateSsoProviderPayload,
  accountId: string,
  id: string
): Promise<AxiosResponse<SsoProvider>> => {
  return BUSINESS_PYTHON.put(
    `${config.routes.accounts.createAccount}${accountId}/sso_providers/${id}/`,
    data
  );
};

export const toggleSsoAccount = (data: boolean, id: string): Promise<AxiosResponse<Account>> => {
  return BUSINESS_PYTHON.patch(`${config.routes.accounts.createAccount}${id}/`, {
    sso_enabled: data,
  });
};

export const sendSsoReminder = (id: string): Promise<AxiosResponse<unknown>> => {
  return BUSINESS_PYTHON.get(`${config.routes.accounts.createAccount}${id}/send_email_sso`);
};

export const toggleSonarAccount = (data: boolean, id: string): Promise<AxiosResponse<Account>> => {
  return BUSINESS_PYTHON.patch(`${config.routes.accounts.createAccount}${id}/sonar-enabled/`, {
    sonar_enabled: data,
  });
};
