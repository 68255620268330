import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import Button from 'components/button';
import Icon from 'components/icons';
import Modal from 'components/modal';
import {
  HeaderContainer,
  ModalContainer,
  ModalContent,
  ModalSection,
  Textarea,
} from 'components/modal/styles';
import Notification from 'components/notification';
import { CreateNotePayload, createNote, editNote } from 'services/notes';
import { Note } from 'contexts/notes/types';
import { Account } from 'contexts/accounts/types';

interface CreateNoteProps {
  note: Note;
  isModalOpen: boolean;
  entity: Account; //The idea is to add more entities that model notes can handle e.g Users
  onClose: (result?: 'ok' | 'error') => void;
}

const CreateNote = ({ note, isModalOpen, entity, onClose }: CreateNoteProps): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [textNote, setTextNote] = useState<string>(note ? note.text : '');
  const editing = note !== null;
  const title = !editing ? `Add note to ${entity.name}` : `Edit note from ${entity.name}`;

  const handleFormValidation = () => {
    if (!textNote) {
      return false;
    }
    return true;
  };

  const handleFormChanges = (value: string) => {
    setTextNote(value);
  };

  const onSubmitNote = async () => {
    if (handleFormValidation()) {
      setLoading(true);
      const noteData = {
        text: textNote,
        content: 'account',
        content_id: entity.id,
      } as CreateNotePayload;

      try {
        editing ? await editNote(noteData, note.id) : await createNote(noteData);
        const notificationMsg = note ? 'Note edited' : 'Note created';
        Notification({ type: 'success', text: notificationMsg, duration: 8 });
        setLoading(false);
        onClose('ok');
      } catch (error) {
        const notificationMsg = note ? 'Couldn´t edit note' : 'Couldn´t create note';
        setLoading(false);
        Notification({ type: 'error', text: notificationMsg });
      }
    }
  };

  return (
    <Modal
      centered
      visible={isModalOpen}
      width={isMobile ? '100%' : '595px'}
      footer={null}
      closeIconColor="#fff"
      maskClosable={false}
      fullscreen={isMobile}
      onCancel={() => onClose()}
    >
      <ModalContainer>
        {isMobile && (
          <span
            onClick={() => onClose()}
            style={{ position: 'absolute', top: 10, left: 10, width: 18 }}
          >
            <Icon icon={'ArrowLeft'} color={'#FFF'} />
          </span>
        )}
        <HeaderContainer style={{ height: '139px' }}>
          <h2>{title}</h2>
        </HeaderContainer>
        <ModalContent grow flexColumn>
          <ModalSection>
            <Textarea
              style={{ height: isMobile ? window.innerHeight - 300 : 'auto' }}
              autoFocus={!editing}
              value={textNote}
              onChange={(e) => handleFormChanges(e.currentTarget.value)}
            />
          </ModalSection>
          <ModalSection>
            <Button
              $primary
              disabled={loading || !textNote}
              style={{ margin: isMobile ? 'auto 10px 0 auto' : '0 0 0 auto' }}
              onClick={onSubmitNote}
            >
              {!editing ? 'Add note' : 'Save changes'}
            </Button>
          </ModalSection>
        </ModalContent>
      </ModalContainer>
    </Modal>
  );
};

export default CreateNote;
