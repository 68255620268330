import { UserDetail } from 'types/users';
import { colors } from 'constants/styles/colors';
import { DEFAULT_IMG_USERS } from 'constants/urls';

interface Avatar {
  image: string;
  title: string;
}

interface Pendings {
  title: string;
  color: {
    lighter: string;
    base: string;
  };
}

export const colorsArray = Object.values(colors.dataVisualization).filter((color) => color.base);

export const pendingColor = colorsArray[Math.floor(Math.random() * colorsArray.length)];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const newPendingcolor = (): any =>
  colorsArray[Math.floor(Math.random() * colorsArray.length)];

const getPendingOrAvatar = (userData) => {
  if (
    userData.uaa_data.profile.data.avatar &&
    userData.uaa_data.profile.data.avatar !== DEFAULT_IMG_USERS
  ) {
    return {
      image: userData.uaa_data.profile.data.avatar,
      title: userData.uaa_data.profile.data.first_name,
    };
  } else {
    const color = newPendingcolor();
    return {
      title: userData.uaa_data.profile.data.first_name,
      color: {
        lighter: color.lighter,
        base: color.base,
      },
    };
  }
};

export const getAvatarItems = (data: UserDetail[]): Avatar[] => {
  let obj = [];
  const arr = data.length > 3 ? data.slice(0, 3) : data;
  arr.map((item) => {
    obj = [...obj, getPendingOrAvatar(item)];
  });
  return obj;
};

export const getPendingsItems = (data: UserDetail[]): Pendings[] => {
  let obj = [];
  const arr = data.length > 3 ? data.slice(0, 3) : data;
  arr.map((item) => {
    const color = newPendingcolor();
    const title = item?.uaa_data
      ? `${item?.uaa_data?.profile?.data?.first_name || ''} ${
          item?.uaa_data?.profile?.data?.last_name || ''
        }`
      : item.email || '';
    obj = [
      ...obj,
      {
        title,
        color: {
          lighter: color.lighter,
          base: color.base,
        },
      },
    ];
  });
  return obj;
};

const convertLetterToNumber = (str: string) => {
  let out = 0;
  const len = str.length;
  for (let pos = 0; pos < len; pos++) {
    out += (str.charCodeAt(pos) - 64) * 26 ** (len - pos - 1);
  }
  return Math.abs(out);
};

const seededRandom = (str: string) => {
  let r = convertLetterToNumber(str);
  const v = r * Math.PI;
  // eslint-disable-next-line no-bitwise
  r = v - (v | 0);
  if (r > 1) r = parseFloat(`0.${r}`);
  return r;
};

export const randomColor = (str: string) => {
  try {
    const color = colorsArray[Math.floor(seededRandom(str) * colorsArray.length)];
    return color;
  } catch (error) {
    return colorsArray[0];
  }
};
