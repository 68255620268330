import { createContext, useContext, useState } from 'react';
import { FCProps } from 'types/FCProps';

import { TeamsDataCtx, Team } from './types';
import { teamsInitialValues } from './utils';

import useSearchQuery from 'hooks/useSearchQuery';
import useTeamsTableContent from 'hooks/useTeamsTableContent';

import { SecureModal } from 'components/modal';
import { SessionContext } from 'contexts/session';

export const TeamsContext = createContext<TeamsDataCtx>(teamsInitialValues);

const TeamsProvider = ({ children }: FCProps): JSX.Element => {
  const {
    teamsTable,
    removeTableItem,
    fetching,
    onChangeFilters,
    onTeamEdition,
    isEmpty,
    onForceTeamsRequest,
  } = useTeamsTableContent();
  const { search } = useSearchQuery();
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const { actions: sessionActions } = useContext(SessionContext);

  const onSelectRow = (id: string) => {
    const findRow = selectedItems.find((rowId) => rowId === id);
    if (findRow) setSelectedItems((prevState) => prevState.filter((rowId) => rowId !== id));
    else setSelectedItems((prevState) => [...prevState, id]);
  };

  const onSelectAllRows = () => {
    const areAllRowsSelected = selectedItems.length === teamsTable?.items?.length;
    if (areAllRowsSelected) setSelectedItems([]);
    else setSelectedItems(teamsTable?.items?.map((i: Team) => i.id) || []);
  };

  const onRemoveTeam = (ids: string[]) => {
    setActionModal({
      title: 'Remove Team(s)',
      primarylabel: 'Remove Team(s)',
      onCancel: () => {
        setActionModalOpen(false);
      },
      onAccept: () => {
        removeTeams(ids);
      },
    });
  };

  const removeTeams = (ids: string[]) => {
    updateAccountTeams(ids);
    removeTableItem(ids);
    setSelectedItems([]);
    setActionModalOpen(false);
  };

  const updateAccountTeams = (ids: string[]) => {
    sessionActions.updateAccountTeams((prev) => {
      const updatedTeams = prev.filter(({ id }) => !ids.includes(id));
      return [...updatedTeams];
    });
  };

  const [actionModalOpen, setActionModalOpen] = useState(false);
  const [actionModalOptions, setActionModalOptions] = useState({
    title: '',
    primarylabel: '',
    onCancel: null,
    onAccept: null,
  });

  const setActionModal = ({ title, primarylabel, onCancel, onAccept }) => {
    setActionModalOpen(true);
    setActionModalOptions({ title, primarylabel, onCancel, onAccept });
  };

  return (
    <TeamsContext.Provider
      value={{
        fetching,
        table: teamsTable,
        selectedItems,
        isEmpty,
        filters: {
          page: search.get('page') || '1',
          page_size: search.get('page_size') || '9',
          search: search.get('search') || '',
        },
        actions: {
          onSelectRow,
          onSelectAllRows,
          onChangeFilters,
          onRemoveTeam,
          onTeamEdition,
          onForceTeamsRequest,
        },
      }}
    >
      <>
        {children}
        <SecureModal
          title={actionModalOptions.title}
          primarylabel={actionModalOptions.primarylabel}
          active={actionModalOpen}
          onCancel={actionModalOptions.onCancel}
          onAccept={actionModalOptions.onAccept}
        />
      </>
    </TeamsContext.Provider>
  );
};

export default TeamsProvider;
