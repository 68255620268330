import './statics.scss';
import {
  ACTIVITIES_TYPE,
  CHART_TYPES,
  LABS_TYPE,
  QUIZZES_TYPE,
  USERS_TYPE,
  VIDEOS_OVERALL_TYPE,
  VIDEOS_TYPE,
} from 'constants/analytics';
import {
  DataContainer,
  DataDescription,
  DataNumber,
  IconWrapper,
  TabButton,
  TabIcon,
  TabWrapper,
  TabsContainer,
} from './styles';
import ButtonIcon from 'components/buttonIcon';
import { ICounters } from 'contexts/analytics/types';
import InputSelect from 'components/inputs/inputSelect';
import LabsIcon from 'assets/icons/analytics/labs-icon.svg';
import OverallIcon from 'assets/icons/analytics/overall-icon.svg';
import QuizzesIcon from 'assets/icons/analytics/quizzes-icon.svg';
import UsersIcon from 'assets/icons/analytics/users-icon.svg';
import VideosIcon from 'assets/icons/analytics/videos-icon.svg';
import { Spinner } from 'components/loadings';
import { capitalize, durationFromSecs } from 'utils/helpers';
import { useState } from 'react';

interface IMetricTabsParams {
  isLoading?: boolean;
  counters: ICounters;
  changeTab: (tab?: string) => void;
  availableTabs?: string[];
}

const MetricTabs = (params: IMetricTabsParams): JSX.Element => {
  const { counters, changeTab, isLoading, availableTabs } = params;
  const [currentTab, setCurrentTab] = useState(USERS_TYPE.VALUE);
  const [collapsed, setCollapsed] = useState(false);

  const currentOption = () => {
    return activitiesOptions.find((option) => option.value === currentTab);
  };

  const toggleCollapse = () => {
    setCollapsed((prevValue) => !prevValue);
  };

  const loading = (counter) => {
    return counter === null;
  };

  const handleChangeTab = (tab: string | number) => {
    setCurrentTab(tab.toString());
    changeTab(tab.toString().toLowerCase());
  };

  let filteredChartTypes = CHART_TYPES.filter(({ VALUE }) => VALUE !== VIDEOS_OVERALL_TYPE.VALUE);
  if (availableTabs) {
    filteredChartTypes = CHART_TYPES.filter(({ VALUE }) =>
      availableTabs.includes(VALUE.toLocaleLowerCase())
    );
  }

  const displayData = {
    [USERS_TYPE.VALUE]: {
      icon: UsersIcon,
      tabLabel: `${capitalize(USERS_TYPE.LABEL)}`,
      counter: counters.users.withActivity,
    },
    [ACTIVITIES_TYPE.VALUE]: {
      icon: OverallIcon,
      tabLabel: `Overall ${ACTIVITIES_TYPE.LABEL}`,
      counter: counters.activities.overall,
    },
    [VIDEOS_TYPE.VALUE]: {
      icon: VideosIcon,
      tabLabel: `${capitalize(VIDEOS_TYPE.LABEL)} watched time`,
      counter: counters.videos.watched,
    },
    [VIDEOS_OVERALL_TYPE.VALUE]: {
      icon: VideosIcon,
      tabLabel: `${capitalize(VIDEOS_OVERALL_TYPE.LABEL)} watched time`,
      counter: counters.videos_overall.watched,
    },
    [QUIZZES_TYPE.VALUE]: {
      icon: QuizzesIcon,
      tabLabel: `Passed ${QUIZZES_TYPE.LABEL}`,
      counter: counters.quizzes.passed,
    },
    [LABS_TYPE.VALUE]: {
      icon: LabsIcon,
      tabLabel: `${capitalize(LABS_TYPE.LABEL)}`,
      counter: counters.labs.started,
    },
  };

  const activitiesOptions = CHART_TYPES.map(({ LABEL, VALUE }) => {
    return {
      value: VALUE,
      label: `${loading(displayData[VALUE]?.counter) ? '-' : displayData[VALUE]?.counter || 0} ${
        displayData[VALUE]?.tabLabel
      }`,
      icon: displayData[VALUE]?.icon,
    };
  });

  const dataNumberText = (type) => {
    if (type === VIDEOS_TYPE.VALUE || type === VIDEOS_OVERALL_TYPE.VALUE) {
      return loading(displayData[type]?.counter)
        ? '-'
        : (displayData[type] &&
            durationFromSecs(displayData[type].counter * 60, 'zeros').replace('h', 'h ')) ||
            durationFromSecs(0 * 60, 'zeros').replace('h', 'h ');
    } else {
      return loading(displayData[type]?.counter) ? '-' : displayData[type]?.counter || 0;
    }
  };

  return (
    <>
      <TabsContainer>
        {
          <InputSelect
            value={currentOption()?.label}
            icon={currentOption()?.icon}
            placeHolder={'Select a representative'}
            options={activitiesOptions}
            onChange={(selectValue) => handleChangeTab(selectValue)}
          />
        }
        {filteredChartTypes.map(({ LABEL, VALUE }) => {
          return (
            <TabButton
              key={LABEL}
              active={currentTab === VALUE}
              onClick={() => {
                !isLoading && handleChangeTab(VALUE);
              }}
              loading={isLoading}
              height={'81px'}
            >
              <TabWrapper>
                <IconWrapper>
                  <div style={{ position: 'absolute', opacity: '50%' }}>
                    <Spinner key={LABEL} active={isLoading} size="30" />
                  </div>
                  <TabIcon src={displayData[VALUE]?.icon} />
                </IconWrapper>
                <DataContainer>
                  {!isLoading && <DataNumber>{dataNumberText(VALUE)}</DataNumber>}
                  <DataDescription>{displayData[VALUE]?.tabLabel}</DataDescription>
                </DataContainer>
              </TabWrapper>
              <ButtonIcon
                className="metric-tabs__collapse-button"
                $terciary
                size="small"
                icon={collapsed ? 'ChevronDown' : 'ChevronUp'}
                onClick={() => toggleCollapse()}
              />
            </TabButton>
          );
        })}
      </TabsContainer>
    </>
  );
};

export default MetricTabs;
