import EmptyStateSvg from 'assets/images/playlist_empty.svg';
import { Container, EmptyStateImg } from './styles';

const PlaylistsEmptySearch: React.FC<{ searchQuery: string }> = ({ searchQuery }): JSX.Element => {
  return (
    <>
      <Container>
        <EmptyStateImg src={EmptyStateSvg} />
        <h4>Sorry, we couldn’t find any matches for {searchQuery}</h4>
        <p>Please try searching by another term</p>
        <br />
      </Container>
    </>
  );
};

export default PlaylistsEmptySearch;
