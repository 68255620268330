import { FC, useContext, useEffect, useState } from 'react';
import { SessionContext } from 'contexts/session';
import AccountStatusCard from 'components/accountStatus/AccountStatusCard';
import {
  CardsContainer,
  Columns,
  MainContent,
} from 'components/layout/profile/profileContent/styles';
import AppReadyLoading from 'components/loadings/AppReadyLoading';
import AccountLicensesCard from 'pages/Accounts/components/AccountLicensesCard/AccountLicensesCard';
import AccountUsersCard from 'pages/Accounts/components/AccountUsersCard/AccountUsersCard';
import TeamSlider from 'pages/Users/components/teamSlider';
import AccountUsersTeamManagerCard from 'pages/Accounts/components/AccountUsersCard/AccountUsersTeamManagerCard';
import { getTeamData } from 'services/teams';
import { handleErrors } from 'contexts/helpers';
import { DeepTeams } from 'contexts/teams/types';
import { CustomRow } from './styles';
import DashboardUsersActiveStats from './DashboardAnalytics/DashboardUsersActiveStats';
import ActivityLog from 'components/ActivityLog';
import { UserProfileData } from 'pages/Users/types';
import { getUserProfile } from 'services/userProfile';
import AccountTeamsCard from 'pages/Accounts/components/AccountTeamsCard/AccountTeamsCard';
import AccountAddonsCard from 'pages/Accounts/components/AccountAddonsCard/AccountAddonsCard';
import AnalyticsProvider from 'contexts/analytics';

const Dashboard: FC = () => {
  const { accounts, user, account, actions, isTeamManager } = useContext(SessionContext);
  const [teamData, setTeamData] = useState<DeepTeams>(null);
  const [userData, setUserData] = useState<UserProfileData>(null);

  const getTeam = async () => {
    try {
      const teamId = user.teams.find((team) => team.is_manager).team_id;
      const response = await getTeamData({ id: teamId });
      if (response.status === 200) {
        const data = response.data;
        response.status === 200 && setTeamData(data);
      }
    } catch (error) {
      handleErrors(error);
    }
  };

  const getUserData = async () => {
    setUserData(null);
    try {
      const response = await getUserProfile(user.id.toString());
      if (response.status === 200) {
        const data = response.data;
        if (response.status) {
          setUserData(data);
        }
      }
    } catch (error) {
      handleErrors(error);
    }
  };

  useEffect(() => {
    getUserData();
    if (isTeamManager) {
      getTeam();
    }
    actions?.refreshAccounts ? actions.refreshAccounts() : null;
  }, []);

  if (!accounts) {
    return <AppReadyLoading />;
  }

  return (
    <MainContent className="dashboard">
      <CardsContainer>
        <CustomRow>
          {accounts && (
            <>
              <Columns className="dashboard__status-card">
                <AccountStatusCard account={accounts} />
              </Columns>
              <Columns className="dashboard__licenses-card">
                <AccountLicensesCard licensesData={accounts.licenses_details} />
              </Columns>
              {accounts.account_addons?.count > 0 && (
                <Columns className="dashboard__addons-card">
                  <AccountAddonsCard accountAddon={accounts.account_addons?.addons?.[0]} />
                </Columns>
              )}
            </>
          )}
          {accounts && (
            <>
              {!isTeamManager && (
                <Columns className="dashboard__teams-card">
                  <AccountTeamsCard
                    teamsData={accounts.account_teams}
                    usersData={accounts.account_users}
                    dashboard={true}
                    team={teamData}
                  />
                </Columns>
              )}
              <Columns className="dashboard__users-card">
                {isTeamManager ? (
                  <AccountUsersTeamManagerCard
                    team={teamData}
                    dashboard={true}
                    onInvitesSent={getTeam}
                  />
                ) : (
                  <AccountUsersCard
                    usersData={accounts.account_users}
                    pendings={accounts.account_owners_pending}
                    dashboard={true}
                  />
                )}
              </Columns>
            </>
          )}
        </CustomRow>
        <div className="mt-5">
          {isTeamManager && userData && (
            <>
              <Columns className="dashboard__teams-slider">
                <TeamSlider
                  userInvite={userData.user_invite}
                  teamsData={userData.user.teams}
                  dashboard={true}
                />
              </Columns>
            </>
          )}
        </div>
        <div className="mt-5">
          <AnalyticsProvider>
            <DashboardUsersActiveStats />
          </AnalyticsProvider>
        </div>
        <div className="mt-5">
          <ActivityLog />
        </div>
      </CardsContainer>
    </MainContent>
  );
};

export default Dashboard;
