import { FC, useState } from 'react';
import { AccountHeaderProfileProps, Domains } from 'contexts/accounts/types';
import AccountDomains from './AccountDomains';
import ManageDomains from '../ManageDomains';
import { deleteDomain } from 'services/accounts';
import Notification from 'components/notification';
import { ConfirmModal } from 'components/modal';

const AccountDomainsCard: FC<AccountHeaderProfileProps> = ({
  refreshData,
  handleModal,
  account,
}) => {
  const { email_domains } = account;
  const [showDeleteDoaminModal, setShowDeleteDoaminModal] = useState(false);
  const [domainSelected, setDomainSelected] = useState(null);

  const handleRefresh = (edited?: boolean) => {
    handleModal(null);
    edited && refreshData();
  };

  const handleEditDomain = (domain: Domains) => {
    handleModal(<ManageDomains account={account} edit={domain} onClose={handleRefresh} />);
  };

  const handleDomainRemove = async () => {
    try {
      const response = await deleteDomain({ domainId: domainSelected.id });
      if (response.status === 200) {
        Notification({ type: 'success', text: 'Domain removed' });
        handleRefresh(true);
      } else return new Error();
    } catch (error) {
      Notification({
        type: 'error',
        text: error.response.data.toString()
          ? `${domainSelected.email_domain}: ${error?.response?.data}`
          : `Couldn’t remove domain ${domainSelected.email_domain}.`,
      });
    } finally {
      setShowDeleteDoaminModal(false);
      setDomainSelected(null);
    }
  };

  const openDeleteModal = (domain: Domains) => {
    setDomainSelected(domain);
    setShowDeleteDoaminModal(true);
  };

  const handleCancelDeleteModal = () => {
    setShowDeleteDoaminModal(false);
    setDomainSelected(null);
  };

  return (
    <>
      <h5>Domains</h5>
      <AccountDomains
        domains={email_domains || []}
        onDomainEdit={handleEditDomain}
        onDomainRemove={openDeleteModal}
      />
      <ConfirmModal
        open={showDeleteDoaminModal}
        title={'Delete domain'}
        description={`Are you sure you want to delete the domain ${domainSelected?.email_domain}`}
        acceptLabel={'Delete domain'}
        cancelLabel={'Cancel'}
        onAccept={handleDomainRemove}
        onCancel={handleCancelDeleteModal}
      />
    </>
  );
};

export default AccountDomainsCard;
