import Button from 'components/button';
import {
  MigratedCTA,
  MigratedNoticeContainer,
  MigratedPill,
} from 'components/migratedTopNotice/styles';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';

interface ITeamsReportNotice {
  type: string;
}

const TeamsPlaylistReportNotice = ({ type }: ITeamsReportNotice): JSX.Element => {
  const history = useHistory();

  const handleClickBtn = () => {
    return type === 'reporting'
      ? history.push({ pathname: '/reporting' })
      : history.push({ pathname: '/playlists', search: 'openModal' });
  };

  return (
    <MigratedNoticeContainer>
      <MigratedPill style={{ width: 'fit-content', padding: '5px 8px 5px 8px' }}>
        <span>NEW!</span>
      </MigratedPill>
      <div className={'titles-container'}>
        <p className={'migrated-title'}>
          {type === 'reporting'
            ? 'Now you can run reports for your teams!'
            : 'Now you can create and assign playlists for your teams!'}
        </p>
      </div>
      <MigratedCTA>
        <Button size={isMobile ? 'small' : null} $secondary $dark onClick={() => handleClickBtn()}>
          {type === 'reporting' ? 'Generate reports' : 'Create playlist'}
        </Button>
      </MigratedCTA>
    </MigratedNoticeContainer>
  );
};

export default TeamsPlaylistReportNotice;
