import { PendingLetterAvatar } from 'components/usersTable/styles';
import { newPendingcolor } from 'utils/colors';
import { AxisTickData, CustomAxisType } from './types';
import { ReactComponent as CloudIcon } from 'assets/icons/preferred_content/cloud_icon.svg';
import { ReactComponent as NetworkingIcon } from 'assets/icons/preferred_content/networking_icon.svg';
import { ReactComponent as BashIcon } from 'assets/icons/preferred_content/bash_icon.svg';
import { ReactComponent as CyberSecurityIcon } from 'assets/icons/preferred_content/cyber-security_icon.svg';
import { ReactComponent as DataScienceIcon } from 'assets/icons/preferred_content/data-science_icon.svg';
import { ReactComponent as ITEssentials } from 'assets/icons/preferred_content/it_essentials_icon.svg';
import { LEARNING_AREAS } from 'components/stats/preferredContent/configs';
import { ChartLabel, ImageDiv, ImageAndNameContainer } from './styles';
import Tooltip from 'components/tooltip';

export const colors: { quizzes: string; readings: string; labs: string; videos: string } = {
  quizzes: '#3E6496',
  videos: '#A793F6',
  labs: '#D73F47',
  readings: '#65AAA3',
};

export const AxisTick = (props: CustomAxisType): JSX.Element => {
  const {
    x,
    y,
    payload: { value },
    image,
    svg,
  } = props;

  let parsedData = {} as AxisTickData;
  try {
    if (value) {
      parsedData = JSON.parse(value);
    }
  } catch (error) {
    parsedData = null;
  }

  const getSvgIcon = (value: string) => {
    switch (value) {
      case LEARNING_AREAS.NETWORKING:
        return (
          <Tooltip placement="right" destroyTooltipOnHide title={LEARNING_AREAS.NETWORKING}>
            <NetworkingIcon style={{ position: 'fixed' }} />
          </Tooltip>
        );
      case LEARNING_AREAS.CLOUD:
        return (
          <Tooltip placement="right" destroyTooltipOnHide title={LEARNING_AREAS.CLOUD}>
            <CloudIcon style={{ position: 'fixed' }} />
          </Tooltip>
        );
      case LEARNING_AREAS.DEVELOPMENT:
        return (
          <Tooltip placement="right" destroyTooltipOnHide title={LEARNING_AREAS.DEVELOPMENT}>
            <BashIcon style={{ position: 'fixed' }} />
          </Tooltip>
        );
      case LEARNING_AREAS.CYBER_SECURITY:
        return (
          <Tooltip placement="right" destroyTooltipOnHide title={LEARNING_AREAS.CYBER_SECURITY}>
            <CyberSecurityIcon style={{ position: 'fixed' }} />
          </Tooltip>
        );
      case LEARNING_AREAS.DATA_SCIENCE:
        return (
          <Tooltip placement="right" destroyTooltipOnHide title={LEARNING_AREAS.DATA_SCIENCE}>
            <DataScienceIcon style={{ position: 'fixed' }} />
          </Tooltip>
        );
      default:
        return (
          <Tooltip placement="right" destroyTooltipOnHide title={LEARNING_AREAS.IT_ESSENTIALS}>
            <ITEssentials style={{ position: 'fixed' }} />
          </Tooltip>
        );
    }
  };

  const getComponentWithoutImage = () => {
    const color = newPendingcolor();
    const letter = parsedData.name ? parsedData.name[0] : parsedData.email[0];

    return (
      <Tooltip placement="right" destroyTooltipOnHide title={parsedData.name}>
        <foreignObject
          x={x - 100}
          y={y - 50}
          style={{
            width: 100,
            height: 100,
          }}
        >
          <ImageAndNameContainer>
            <PendingLetterAvatar color={color} style={{ borderRadius: '50%' }}>
              {letter}
            </PendingLetterAvatar>
            <ChartLabel>{parsedData.name}</ChartLabel>
          </ImageAndNameContainer>
        </foreignObject>
      </Tooltip>
    );
  };

  const getComponentWithUrlImage = () => {
    return (
      <Tooltip placement="right" destroyTooltipOnHide title={parsedData.name}>
        <foreignObject
          x={x - 100}
          y={y - 50}
          style={{
            width: 100,
            height: 100,
          }}
        >
          <ImageAndNameContainer>
            <ImageDiv urlImage={parsedData.image} fixed={false} />
            <ChartLabel>{parsedData.name}</ChartLabel>
          </ImageAndNameContainer>
        </foreignObject>
      </Tooltip>
    );
  };

  if (!parsedData) {
    return null;
  }

  if (image && parsedData.image) {
    if (parsedData.image?.includes('http')) {
      return getComponentWithUrlImage();
    } else {
      return getComponentWithoutImage();
    }
  } else if (svg && parsedData) {
    return (
      <foreignObject x={x - 50} y={y - 27} style={{ width: 50, height: 50 }}>
        {getSvgIcon(parsedData.name)}
      </foreignObject>
    );
  } else {
    return getComponentWithoutImage();
  }
};
