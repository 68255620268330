import styled from 'styled-components';
import { screenMd, screenXs } from 'constants/styles/breakpoints';
import {
  Description,
  HeaderContainer,
  ModalActions,
  ModalContent,
  ModalSection,
  ModalSectionTitle,
} from 'components/modal/styles';

export const MigratedTitle = styled.h2`
  display: flex;
  align-items: center;
  gap: 10px;

  ${screenXs} {
    align-items: flex-start;
  }
`;

export const MigratiedHeaderContainer = styled(HeaderContainer)`
  height: 116px;
`;

export const MigratedModalSection = styled(ModalSection)`
  border-radius: 20px;
  border: 1px solid #cfced33b;
  padding: 0;
  margin: 0;
  margin-bottom: 30px;

  ${screenMd} {
    margin-bottom: 20px;
  }
`;

export const MigratedModalSectionTitle = styled(ModalSectionTitle)`
  font-size: 20px;
  padding: 0;

  ${screenMd} {
    font-size: 24px;
    text-align: center;
  }
`;

export const MigratedModalDescriptionSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;

  ${screenXs} {
    padding: 30px 15px;
  }
`;

export const MigratedModalDescription = styled(Description)`
  display: flex;
  align-items: center;
  padding-top: 20px;
  font-size: 14px;
  line-height: 20px;

  ${screenMd} {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const MigratedModalActions = styled(ModalActions)`
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  .migrated-pdf-download {
    height: auto !important;
    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap;
    padding: 0 10px;
    margin-left: 0;
    &__label {
      display: inline;
      width: 85%;
      white-space: normal;
      text-align: left;
      line-height: 23px;
      padding: 10px;
    }
    ${screenMd} {
      &__label {
        display: inline;
        width: auto;
        white-space: normal;
        text-align: left;
        line-height: 23px;
        padding: 10px;
      }
    }
  }
`;

export const MigratedModalContent = styled(ModalContent)`
  padding-top: 15px;
  padding-left: 12px;
  padding-right: 12px;

  ${screenMd} {
    padding: 30px 27px;
  }
`;

export const MigratedVideoLabel = styled.span`
  position: absolute;
  height: 30px;
  background: linear-gradient(
    92.23deg,
    #ef7d22 3.65%,
    #ef741d 25.47%,
    #ee5a10 62.46%,
    #ee3b00 98.5%
  );
  font-size: 14px;
  line-height: 20px;
  padding: 5px 10px;
  color: white;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  &:after {
    content: '';
    width: 0;
    height: 0;
    border-bottom: 30px solid transparent;
    border-left: 12px solid #ee3f02;
    border-top: 0px solid #ee3f02;
    right: -12px;
    top: 0;
    position: absolute;
    clear: both;
  }
`;
