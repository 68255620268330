import { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Layout from 'components/layout';
import { routes } from './routes';
import { SessionContext } from 'contexts/session';
import useAccountPlanData from 'hooks/useAccountPlanData';

const staffRoutes = [
  '/accounts',
  '/users',
  '/user/:id',
  '/account/:id',
  '/account/:id/users',
  '/rax',
];

const Routes = (): JSX.Element => {
  const { account, isStaff } = useContext(SessionContext);
  const { isExpired } = useAccountPlanData({ account });

  return (
    <Switch>
      <Layout>
        {routes.map(({ Page, to }, index) => (
          <Route
            key={index}
            path={to}
            exact
            render={() => {
              if (isExpired && to !== '/') return <Redirect to="/" />;
              if (isStaff && !staffRoutes.includes(to)) return <Redirect to="/accounts" />;

              return <Page />;
            }}
          />
        ))}
      </Layout>
    </Switch>
  );
};

export { Routes };
