import Avatar from 'components/avatar';
import { useContext } from 'react';
import { SessionContext } from 'contexts/session';
import Button from 'components/button';
import { Link } from 'react-router-dom';
import { AvatarContainer, UserInfo, Name, Email, ButtonContainer, Divider } from './styles';
import FirstLetterEmail from 'components/firstLetterEmail/firstLetterEmail';
import { DEFAULT_IMG_USERS } from 'constants/urls';

const AvatarMenuItem = (): JSX.Element => {
  const { user } = useContext(SessionContext);

  return (
    <>
      <AvatarContainer>
        {user?.uaa_data.profile.data.avatar &&
        user?.uaa_data.profile.data.avatar !== DEFAULT_IMG_USERS ? (
          <Avatar image={user?.uaa_data.profile.data.avatar} size={'lg'} />
        ) : (
          <FirstLetterEmail email={user?.uaa_data.email} />
        )}
        <UserInfo>
          {user && (
            <>
              <Name>
                {user?.uaa_data.profile.data.first_name + ' '}
                {user?.uaa_data.profile.data.last_name}
              </Name>
              <Email>{user?.uaa_data.email}</Email>
            </>
          )}
        </UserInfo>
      </AvatarContainer>

      <ButtonContainer>
        <Button $secondary $dark $full>
          <Link to={'/user/' + user?.id}>My Profile</Link>
        </Button>
      </ButtonContainer>
      <Divider />
    </>
  );
};

export default AvatarMenuItem;
