import {
  expireAccount,
  getAccounts,
  getCounts,
  getIneStaff,
  removeAccounts,
  toggleSonarAccount,
  toggleSsoAccount,
} from 'services/accounts';
import { AccountsParams, GetCountsProps, Staff } from './types';
import { handleErrors } from '../helpers';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getAccountsAction = async (
  params: AccountsParams
): Promise<{ results: any; count: number } | Error> => {
  try {
    const { data } = await getAccounts(params);
    return {
      results: data,
      count: data.count,
    };
  } catch (error) {
    return handleErrors(error);
  }
};

export const getRemoveAccountsAction = async ({
  ids,
}: {
  ids: string[];
}): Promise<{ results: boolean; count: number } | Error> => {
  try {
    const { data } = await removeAccounts({ ids });
    return {
      results: data.result,
      count: 0,
    };
  } catch (error) {
    return handleErrors(error);
  }
};

export const getExpireAccountsAction = async (
  accountId: string
): Promise<{ results: boolean; count: number } | Error> => {
  try {
    const { data } = await expireAccount(accountId);
    return {
      results: data.result,
      count: 0,
    };
  } catch (error) {
    return error as Error;
  }
};

export const getAccountsCounts = async (): Promise<GetCountsProps | Error> => {
  try {
    const { data } = await getCounts();
    return data;
  } catch (error) {
    return handleErrors(error);
  }
};

export const getStaff = async (): Promise<Staff[] | Error> => {
  try {
    const { data } = await getIneStaff();
    return data;
  } catch (error) {
    return error as Error;
  }
};

export const handleSsoToggle = async (value: boolean, id: string): Promise<any | Error> => {
  try {
    const { data } = await toggleSsoAccount(value, id);
    return data;
  } catch (error) {
    return error as Error;
  }
};

export const handleSonarToggle = async (value: boolean, id: string): Promise<any | Error> => {
  try {
    const { data } = await toggleSonarAccount(value, id);
    return data;
  } catch (error) {
    return error as Error;
  }
};
