import { Buttons, Container, Description, Info, SecureInput, Title } from './styles';
import React, { FormEvent, useEffect, useState } from 'react';
import Button from 'components/button';
import Modal from 'components/modal';

interface ModalProps {
  title: string;
  active: boolean;
  primarylabel: string;
  onCancel: () => void;
  onAccept: () => void;
}

const SecureModal: React.FC<ModalProps> = ({ title, active, primarylabel, onCancel, onAccept }) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [inputError, setInputError] = useState(false);

  const handleOnCancel = () => {
    setOpen(false);
    clearInput();
    if (onCancel) onCancel();
  };

  const clearInput = () => {
    setInputValue('');
    setInputError(false);
  };

  const handleOnAccept = () => {
    if (inputValue === 'permanently remove') {
      setOpen(false);
      clearInput();
      if (onAccept) onAccept();
    } else {
      setInputError(true);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleOnAccept();
    }
  };

  useEffect(() => {
    setOpen(active);
  }, [active]);

  const handleInput = (e: FormEvent<HTMLInputElement>): void => {
    setInputError(false);
    setInputValue(e.currentTarget.value);
  };

  return (
    <Modal
      centered
      onCancel={handleOnCancel}
      visible={open}
      footer={null}
      width={'400px'}
      destroyOnClose={true}
      bodyStyle={{ borderRadius: '3px !important' }}
    >
      <Container>
        <Info>
          {title && <Title>{title}</Title>}
          <Description>
            To confirm the deletion, type <b>permanently remove</b> in the text input field.
          </Description>
          <SecureInput
            type="text"
            className="form-control"
            placeholder="permanently remove"
            aria-label="secure"
            onChange={handleInput}
            value={inputValue}
            error={inputError}
            onKeyPress={handleKeyPress}
          />
        </Info>

        <Buttons>
          <Button $secondary onClick={() => handleOnCancel()} size="small" $full>
            {'Cancel'}
          </Button>
          <Button $primary onClick={() => handleOnAccept()} size="small" $full>
            {primarylabel}
          </Button>
        </Buttons>
      </Container>
    </Modal>
  );
};

export default SecureModal;
