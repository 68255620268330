import { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { SetAddonsSettings, UserSettingsPayload } from 'components/usersTable/types';
import { config } from 'config/config.local';
import { UserInvites, UsersCounts } from 'contexts/users/types';
import { BUSINESS_PYTHON } from './axios';

interface GetInvitations {
  results: UserInvites[];
  count: number;
}

interface GetUserCounts {
  results: UsersCounts;
  count: number;
}

export interface UserFetchParams {
  license: string | null;
  teams?: string | null;
  roles: string | null;
  page: number | null;
  page_size: number | null;
  search: string | null;
  active?: string;
  ordering?: string | null;
  not_in_teams?: string | null;
}

export interface UserSettingsResponse {
  result?: boolean;
  status?: boolean;
}

export interface BusinessUser {
  account: string;
  roles: string;
  uaa_id: string;
  team: string;
  date_joined: string;
  first_name: string;
  last_name: string;
  email: string;
}

export interface BusinessUserResponse {
  count: number;
  next: null;
  previous: null;
  results: BusinessUser[];
}

export interface IMigrationInfo {
  should_show_migration_info?: boolean;
  migration_info_start_time?: string;
}

export const getInvitations = (params: UserFetchParams): Promise<AxiosResponse<GetInvitations>> => {
  return BUSINESS_PYTHON.get(config.routes.users.getInvites, { params });
};

export const removeUsersInvites = ({
  ids,
}: {
  ids: string[];
}): Promise<AxiosResponse<UserSettingsResponse>> => {
  return BUSINESS_PYTHON.post(config.routes.users.removeInvites, { user_invites_ids: ids });
};

export const getUsers = (params: UserFetchParams): Promise<AxiosResponse<GetInvitations>> => {
  return BUSINESS_PYTHON.get(config.routes.users.users, { params });
};

export const getCounts = (params: {
  active: boolean;
  account_id?: string;
}): Promise<AxiosResponse<GetUserCounts>> => {
  return BUSINESS_PYTHON.get(config.routes.users.getCounts, { params });
};

export const setTeam = ({
  ids,
  team,
}: {
  ids: string[];
  team: string;
}): Promise<AxiosResponse<UserSettingsResponse>> => {
  return BUSINESS_PYTHON.post(`${config.routes.users.setTeam}`, {
    teams: team,
    user_invite_id: ids,
  });
};

export const setTeams = ({
  user_invite_id,
  teams,
}: UserSettingsPayload): Promise<AxiosResponse<UserSettingsResponse>> => {
  return BUSINESS_PYTHON.post(`${config.routes.users.setTeam}`, {
    user_invite_id: user_invite_id,
    teams: teams,
  });
};

export const setUserRoleService = ({
  role,
  user_invite_id,
  teams,
}: UserSettingsPayload): Promise<AxiosResponse<UserSettingsResponse>> => {
  return BUSINESS_PYTHON.post(`${config.routes.users.setRole}`, {
    role: role,
    user_invite_id: user_invite_id,
    teams: teams,
  });
};

export const setRole = ({
  id,
  role,
}: {
  id: string;
  role: string;
}): Promise<AxiosResponse<UserSettingsResponse>> => {
  return BUSINESS_PYTHON.post(`${config.routes.users.setRole}`, {
    role: role,
    user_invite_id: id,
  });
};

export const setLicense = ({
  ids,
  license,
}: {
  ids: string[];
  license: boolean;
}): Promise<AxiosResponse<UserSettingsResponse>> => {
  return BUSINESS_PYTHON.post(`${config.routes.users.setLicense}`, {
    assign: license,
    user_invites_ids: ids,
  });
};

export const setAddonLicense = ({
  assigned_to,
  addon,
}: SetAddonsSettings): Promise<AxiosResponse<any>> => {
  return BUSINESS_PYTHON.post(`${config.routes.licenseAddon}`, { assigned_to, addon });
};

export const removeAddonLicense = ({
  license_addon_id,
}: {
  license_addon_id: string;
}): Promise<AxiosResponse<any>> => {
  return BUSINESS_PYTHON.delete(`${config.routes.licenseAddon}${license_addon_id}/`);
};

export const resendInvites = ({
  ids,
}: {
  ids: string[];
}): Promise<AxiosResponse<UserSettingsResponse>> => {
  return BUSINESS_PYTHON.post(`${config.routes.users.resendInvites}`, {
    user_invites_ids: ids,
  });
};

export const getBusinessUsers = ({
  account_id,
  ids,
}: {
  account_id: string;
  ids: string[];
}): Promise<AxiosResponse<BusinessUserResponse>> => {
  return BUSINESS_PYTHON.post(`${config.routes.users.getAccounts}${account_id}/users/`, {
    ids,
  });
};

export const setMigrationInfo = (): Promise<AxiosResponse<IMigrationInfo>> => {
  const date = format(new Date(), 'MM/dd/yyyy');
  const response = BUSINESS_PYTHON.patch(`${config.routes.users.migrationInfo}`, {
    migration_info_start_time: date,
  });
  return response;
};

export const setNotificationReaded = ({
  notification_id,
}: {
  notification_id: number;
}): Promise<AxiosResponse> => {
  return BUSINESS_PYTHON.patch(
    `${config.routes.users.notifications}${notification_id}/mark-as-read/`,
    {}
  );
};
