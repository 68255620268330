// import { Team } from 'contexts/teams/types';
import styled from 'styled-components';

// interface ItemTeamProps {
// 	data: Team,
// }

const Container = styled.div`
  display: flex;
  position: relative;
  img {
    width: 100%;
    opacity: 0.3;
  }
  p {
    font-size: 14px;
    color: #999;
    position: absolute;
    z-index: 1;
    top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Activity = (): JSX.Element => {
  // const {
  // 	data
  // } = props;

  return (
    <>
      <Container>
        <p>not available</p>
        <img src={'/activity.png'} />
      </Container>
    </>
  );
};

export default Activity;
