import React, { useMemo, useState } from 'react';
import AvatarLetter from 'components/avatar/AvatarLetter';

import {
  AssignmentTextContainer,
  Assignments,
  Card,
  CardContent,
  Category,
  CategoryText,
  Container,
  ContentBlock,
  ContentItem,
  ContentSection,
  Description,
  DescriptionAndCategory,
  Indicator,
  ItemDetailContainer,
  ItemDetailTitle,
  ItemText,
  NumberBig,
  Text,
  Title,
} from './styles/ProductCard.styles';

import {
  AssignedContainer,
  Booked,
  BookedContainer,
  CardContainer,
  DateContainer,
  ItemContainer,
  ListContainer,
  StatusContainer,
  VerticalDivider,
} from './styles/AssignedProductCard.styles';
import { format } from 'date-fns';
import Icon from 'components/icons';
import { colors } from 'constants/styles/colors';
import ButtonIcon from 'components/buttonIcon';
import Tooltip from 'components/tooltip';
import RemoveRaxSlotModal from './RemoveRaxSlotModal';
import ScheduleModalActionAO from './ScheduleModalActionAO';
import { isEmpty } from 'lodash';
import { BOOKING_STATUS } from '../utils';
import { IBooking, IBookingsParams, IRaxLab } from 'contexts/rax/types';

export interface IAssignedProductCardProps {
  id: string;
  rax_lab: IRaxLab;
  available_bookings: number;
  bookings: IBooking[];
  onRemoveRaxSlot?: (id: string) => void;
  onScheduleBooking?: (data: IBookingsParams) => void;
  onRemoveBooking?: (id: string) => void;
  loading?: boolean;
  editable?: boolean;
  showBookedFirst?: boolean;
  users?: any;
}

const AssignedProductCard: React.FC<IAssignedProductCardProps> = ({
  id,
  rax_lab,
  available_bookings,
  bookings,
  onRemoveRaxSlot,
  onScheduleBooking,
  onRemoveBooking,
  editable = false,
  showBookedFirst = false,
  users,
}) => {
  const totalAssignments = available_bookings + bookings.length;
  const datesFormatted = (booked: IBooking) => {
    if (isEmpty(booked)) return;
    const start = new Date(booked.start_date);
    const end = new Date(booked.end_date);
    const utcStart = new Date(start.getTime() + start.getTimezoneOffset() * 60000);
    const utcEnd = new Date(end.getTime() + end.getTimezoneOffset() * 60000);
    return `${format(utcStart, 'EEEE, MMM d')} - ${format(utcEnd, 'EEEE, MMM d')}`;
  };
  const isBooked = (booked: IBooking) => {
    const { ACTIVE, UPCOMING, RESERVED, FINISHED } = BOOKING_STATUS;
    return [ACTIVE, UPCOMING, RESERVED, FINISHED].includes(booked?.status);
  };
  const isUserActive = (booked: IBooking) => {
    return booked?.user?.active;
  };

  const isItemActive = (booked: IBooking) => isUserActive(booked);

  const bookedStatus = (booked: IBooking) => {
    const bookedStatus = {
      status: booked?.status,
      statusLabel: '',
      pillClass: '',
    };
    if (!isBooked(booked)) return bookedStatus;

    const { UPCOMING, ACTIVE, FINISHED } = BOOKING_STATUS;
    if ([UPCOMING, ACTIVE, FINISHED].includes(booked?.status)) {
      const { status } = booked;
      const statusLabels = {
        active: 'Active now!',
        finished: 'Past booking',
      };
      const statusLabel = statusLabels[status] || status;
      return {
        status,
        statusLabel,
        pillClass: `pill--${status}`,
      };
    }
    return bookedStatus;
  };

  const cantRemoveBooking = (booked: IBooking) => {
    const { ACTIVE, FINISHED } = BOOKING_STATUS;
    return !isUserActive(booked) || [ACTIVE, FINISHED].includes(bookedStatus(booked).status);
  };

  const trashTooltipLabel = (booked: IBooking) => {
    return isBooked(booked) ? 'You can’t remove a booked slot' : 'Remove slot';
  };
  const removeButtonTooltipLabel = (booked: IBooking) => {
    if (!isUserActive(booked))
      return 'You can’t remove a slot that is no longer associated with your account';

    if (!cantRemoveBooking(booked)) return 'Remove this booking and open the slot for rescheduling';
    const { ACTIVE } = BOOKING_STATUS;
    if (booked.status === ACTIVE) return 'You can’t remove an active slot';
    return 'You can’t remove a past booking';
  };
  const productSlots = useMemo(() => {
    const emptySlots = available_bookings ? Array(available_bookings).fill(null) : [];
    const slots = [...emptySlots, ...bookings];
    return !showBookedFirst ? slots : slots.reverse();
  }, [bookings, available_bookings]);

  const { name: product_name, id: product_id, category } = rax_lab;
  const color = category.color;
  const currentProduct = {
    id,
    product_id,
    name: product_name,
    sku: rax_lab.checkfront_sku,
    color,
    available_bookings,
    users,
  };
  const [showRemoveRaxSlotModal, setShowRemoveRaxSlotModal] = useState(false);
  const openRemoveRaxSlotModal = () => setShowRemoveRaxSlotModal(true);
  const handleModalClose = () => setShowRemoveRaxSlotModal(false);
  const handleModalConfirm = () => {
    onRemoveRaxSlot(id);
    handleModalClose();
  };

  return (
    <Container>
      <RemoveRaxSlotModal
        visible={showRemoveRaxSlotModal}
        onCancel={handleModalClose}
        onOk={handleModalConfirm}
      />
      <Card>
        <Indicator color={category.color} />
        <CardContent>
          <ContentSection style={{ gap: '8px' }}>
            <ContentBlock>
              <ContentItem>
                <Title>{product_name}</Title>
              </ContentItem>
            </ContentBlock>
            <ContentBlock>
              <ContentItem style={{ flex: '1 1 60%' }}>
                <DescriptionAndCategory>
                  <Description>{rax_lab?.description}</Description>
                  <Category>
                    <CategoryText>{category.name}</CategoryText>
                  </Category>
                </DescriptionAndCategory>
              </ContentItem>
              {!editable && (
                <ContentItem>
                  <ItemDetailContainer>
                    <ItemDetailTitle>SKU</ItemDetailTitle>
                    <ItemText>{rax_lab?.checkfront_sku}</ItemText>
                  </ItemDetailContainer>
                </ContentItem>
              )}
            </ContentBlock>
          </ContentSection>
          <ContentSection className="pt-0">
            <CardContainer editable>
              <Assignments editable>
                <NumberBig>{totalAssignments}</NumberBig>
                <AssignmentTextContainer>
                  <Text>{!editable ? 'Assigned' : 'Slots'}</Text>
                </AssignmentTextContainer>
              </Assignments>
              {!editable && (
                <ListContainer>
                  {productSlots.map((booking, index) => (
                    <ItemContainer key={`b-${index}`} data-testid="assigned-item-container">
                      {isBooked(booking) ? (
                        <>
                          <Booked>Booked</Booked>{' '}
                          <Icon
                            style={{ margin: '0 5px' }}
                            icon="CheckGreen"
                            color={colors.performance.positiveDark}
                            size="smaller"
                          />
                          | {datesFormatted(booking)} | Assigned to: {booking.user.name}
                        </>
                      ) : (
                        'Unbooked'
                      )}
                      <Tooltip
                        placement="bottom"
                        overlayInnerStyle={{ maxWidth: '180px', textAlign: 'center' }}
                        title={trashTooltipLabel(booking)}
                      >
                        <div style={{ marginLeft: 'auto' }}>
                          <ButtonIcon
                            style={{ margin: '0 5px' }}
                            $terciary
                            disabled={isBooked(booking)}
                            size={'small'}
                            icon={'Trash'}
                            onClick={openRemoveRaxSlotModal}
                          />
                        </div>
                      </Tooltip>
                    </ItemContainer>
                  ))}
                </ListContainer>
              )}
              <>
                {editable && (
                  <ListContainer>
                    {productSlots.map((booking, index) => (
                      <ItemContainer key={`${index}-slot`} data-testid="assigned-item-container">
                        {!booking ? (
                          <ScheduleModalActionAO
                            data-testid="schedule-modal-action"
                            product={{ ...currentProduct, booking }}
                            users={users}
                            onScheduleBooking={onScheduleBooking}
                          />
                        ) : (
                          <BookedContainer inactive={booking && !isItemActive(booking)}>
                            <DateContainer>
                              <div className="d-flex">
                                <Booked>Booked</Booked>{' '}
                                <Icon
                                  style={{ margin: '0 5px' }}
                                  icon="CheckGreen"
                                  color={colors.performance.positiveDark}
                                  size="smaller"
                                />
                              </div>
                              {datesFormatted(booking)}
                            </DateContainer>
                            <VerticalDivider />
                            <AssignedContainer>
                              <div
                                style={{
                                  opacity: isItemActive(booking) ? 1 : 0.6,
                                }}
                              >
                                <AvatarLetter
                                  rounded={true}
                                  size="xs"
                                  avatarSize="xs"
                                  avatar={booking?.user?.avatar}
                                  name={booking?.user?.name}
                                />
                              </div>
                              <div className="d-flex flex-column">
                                <Booked>Assigned</Booked>
                                <div className="d-flex align-items-center">
                                  <span>{booking?.user?.name}</span>
                                  {!isUserActive(booking) && (
                                    <Tooltip
                                      title={`${booking?.user?.name} is no longer associated with your account`}
                                      overlayInnerStyle={{ textAlign: 'center' }}
                                    >
                                      <div>
                                        <Icon
                                          data-testid="info-inactive-icon"
                                          style={{ marginLeft: '3px' }}
                                          icon="Info"
                                          fill={'var(--lilac)'}
                                          size="smaller"
                                        />
                                      </div>
                                    </Tooltip>
                                  )}
                                </div>
                              </div>
                            </AssignedContainer>
                            <div
                              className="product-status-cta-container d-flex align-items-center"
                              style={{ marginLeft: 'auto' }}
                            >
                              {bookedStatus(booking).status && (
                                <StatusContainer>
                                  <div
                                    style={{ width: 'fit-content', textTransform: 'capitalize' }}
                                    className={`pill ${bookedStatus(booking).pillClass}`}
                                  >
                                    {bookedStatus(booking).statusLabel}
                                  </div>
                                </StatusContainer>
                              )}
                              <Tooltip
                                title={removeButtonTooltipLabel(booking)}
                                overlayInnerStyle={{ textAlign: 'center' }}
                              >
                                <div style={{ margin: '0 5px' }}>
                                  <ButtonIcon
                                    $terciary
                                    disabled={cantRemoveBooking(booking)}
                                    size={'small'}
                                    icon={'Trash'}
                                    onClick={() => onRemoveBooking(booking.id)}
                                  />
                                </div>
                              </Tooltip>
                            </div>
                          </BookedContainer>
                        )}
                      </ItemContainer>
                    ))}
                  </ListContainer>
                )}
              </>
            </CardContainer>
          </ContentSection>
        </CardContent>
      </Card>
    </Container>
  );
};

export default AssignedProductCard;
