import { Account } from 'contexts/accounts/types';

interface AccountPLanDataTypes {
  free: boolean;
  professional: boolean;
  enterprise: boolean;
  enterprisePlus: boolean;
  labExperience: boolean;
  pricingPlan: string;
  unlimited: boolean;
  isExpired: boolean;
  isPastDue: boolean;
  status: 'unstarted' | 'active' | 'past_due' | 'expired';
  totalLicenses: number;
  usedLicenses: number;
}

const useAccountPlanData = ({ account }: { account: Account }): AccountPLanDataTypes => {
  const accountStatus = account?.status;
  const free = account?.is_free_trial;
  const unlimited = account?.licenses_details?.count === null;
  const pricingPlan = account?.pricing_plan;

  return {
    free,
    status: accountStatus,
    isExpired: accountStatus === 'expired',
    isPastDue: accountStatus === 'past_due',
    unlimited,
    pricingPlan,
    professional: pricingPlan === 'Professional',
    enterprise: pricingPlan === 'Enterprise',
    enterprisePlus: pricingPlan === 'Enterprise + Lab Experience',
    labExperience: pricingPlan === 'Lab Experience',
    totalLicenses: account?.licenses_details?.count,
    usedLicenses: account?.licenses_details?.used,
  };
};

export default useAccountPlanData;
