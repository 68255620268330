import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Space } from 'antd';
import styled from 'styled-components';
import { IconTeam } from 'components/teamSelect/IconTeam';
import Switch from 'components/switch/Switch';
import Icon from 'components/icons';
import Tooltip from 'components/tooltip';
import teamDefaultAvatar from 'assets/images/no-team.svg';
import { ROLES } from 'constants/roles';
import Notification from 'components/notification';
import { DeepTeams, Team } from 'contexts/teams/types';
import { setTeamManager } from 'components/usersTable/actions';
import { UserTeamsPayload } from 'components/usersTable/types';
import { SessionContext } from 'contexts/session';

export const TeamData = styled.div`
  color: ${({ theme }) => theme.default.colors.fonts.dark};
  > h5 {
    font-weight: 700;
    margin-bottom: 0;
    font-size: 24px;
    line-height: 26px;
    word-break: break-all;
  }
  > span {
    font-size: 14px;
    white-space: nowrap;
    > span {
      color: ${({ theme }) => theme.default.colors.fonts.lightGray};
    }
  }
`;

export const RolePill = styled.div`
  --b: 1px; /* border width*/
  --r: 20px; /* the radius */

  color: #313149;
  padding: 4px 5px;
  display: block;
  position: relative;
  z-index: 0;
  margin-top: 5px;
  max-width: 80px;
  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    inset: 0;
    padding: var(--b);
    border-radius: var(--r);
    background: linear-gradient(to right, #863ad2, #5936e4);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
  > span {
    > p {
      text-transform: uppercase;
      text-align: center;
      font-size: 12px;
      font-weight: 450;
      background: linear-gradient(to right, #863ad2, #5936e4);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: 0;
      white-space: nowrap;
    }
  }
`;

const EmptyAvatar = styled.div`
  width: 80px;
  height: 80px;
  background: ${({ theme }) => theme.default.colors.dataVisualization.gray.light};
  display: flex;
  justify-content: center;
  align-items: center;
  > svg {
    color: transparent;
    stroke: ${({ theme }) => theme.default.colors.dataVisualization.gray.dark};
    transform: scaleX(-1);
    width: 65px !important;
    height: 65px !important;
  }
`;

export const SwitchContainer = styled.div<{ smallView?: boolean }>`
  align-items: center;
  display: flex;
  margin-left: auto;
  flex-flow: ${({ smallView }) => (smallView ? 'column-reverse' : 'row')};
  gap: ${({ smallView }) => (smallView ? '5px' : '15px')};
  align-items: ${({ smallView }) => (smallView ? 'flex-end' : 'center')};
`;

export const SwitchLabel = styled.label`
  color: #57575c;
  font-size: 12px;
  line-height: 14px;
  font-style: normal;
  font-weight: 450;
  letter-spacing: 0px;
  text-align: left;
  text-transform: uppercase;
`;

const CustomLink = styled(Link)`
  position: relative;
  top: -15px;
`;

const CustomSpace = styled(Space)`
  justify-content: space-between;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  display: flex;
  gap: 15px;
`;

const UserTeamCard = ({
  team,
  user_invite,
  isManager,
  showLinkTeam,
  smallView,
  dashboard,
  userTeamAccOwner,
  isItself,
}: {
  team: Team;
  user_invite: { id: string; role: string };
  isManager: boolean;
  userTeamAccOwner?: boolean;
  showLinkTeam?: boolean;
  smallView?: boolean;
  dashboard?: boolean;
  isItself?: boolean;
}): JSX.Element => {
  const { user: userSession } = useContext(SessionContext);
  const [isManagerState, setIsManagerState] = useState(isManager);
  const [isLoading, setIsLoading] = useState(false);

  const isTeamManagerLoggedIn = userSession.uaa_data?.roles?.data?.some(
    (r) => r.id === ROLES.TEAM_MANAGER
  );

  const isNotManagerFromTeam = (teamId: string): boolean => {
    return isTeamManagerLoggedIn
      ? Boolean(!userSession.teams.find((team) => team.team_id === teamId)?.is_manager)
      : false;
  };

  const getRoleSwitchStatus = () => {
    const switchStatus = {
      disabled: false,
      tooltip: 'Team Manager role',
    };
    if (isManager && isItself) {
      switchStatus.disabled = true;
      switchStatus.tooltip = `Team manager cannot revoke it's own role`;
    } else if (userTeamAccOwner) {
      switchStatus.disabled = true;
      switchStatus.tooltip = `An Account Owner cannot be set as a Team Manager`;
    }
    return switchStatus;
  };

  const managerSwitchStatus = !dashboard && getRoleSwitchStatus();

  const handleSwitchManager = async (user_invite_id: string, teamsData: UserTeamsPayload[]) => {
    setIsLoading(true);
    const response = await setTeamManager({
      user_invite_id: user_invite_id,
      teams: teamsData,
    });
    if (!(response instanceof Error) && response.result) {
      setIsManagerState(teamsData[0].is_manager);
      Notification({ text: 'Role updated', type: 'success' });
    } else {
      Notification({ text: 'Role couldn’t be updated', type: 'error' });
    }
    setIsLoading(false);
  };

  if (!team)
    return (
      <Space align={'start'} size={15}>
        <EmptyAvatar>
          <img src={teamDefaultAvatar} />
        </EmptyAvatar>
        <TeamData>
          <h5>No Team Assigned</h5>
        </TeamData>
      </Space>
    );

  return (
    <CustomSpace align={'start'} size={15}>
      <ContentWrapper>
        <IconTeam iconName={team?.icon_name} color={team?.icon_color} size={'large'} />
        <TeamData>
          <h5>{team?.name}</h5>
          <span>
            Users: {team?.active_users + team?.pending_users}
            {team?.pending_users > 0 && <span> ({team?.pending_users} Pending)</span>}
          </span>
          {isManagerState && (
            <RolePill>
              <span>
                <p>manager</p>
              </span>
            </RolePill>
          )}
        </TeamData>
      </ContentWrapper>
      <ContentWrapper>
        {!dashboard && (
          <Tooltip
            placement="bottom"
            destroyTooltipOnHide
            title={
              isNotManagerFromTeam(team.team_id)
                ? `You can only edit users' roles if they are on your team.`
                : managerSwitchStatus.tooltip
            }
          >
            <SwitchContainer smallView={smallView}>
              <SwitchLabel>Manager</SwitchLabel>
              <Switch
                disabled={
                  managerSwitchStatus.disabled || isLoading || isNotManagerFromTeam(team.team_id)
                }
                defaultChecked={isManagerState}
                onChange={(checked) =>
                  handleSwitchManager(user_invite.id, [
                    { team_id: team.team_id, is_manager: checked },
                  ])
                }
              />
            </SwitchContainer>
          </Tooltip>
        )}
        {showLinkTeam && (
          <CustomLink to={`/team/${team?.team_id}`}>
            <Icon icon="ArrowRight" size={'small'} />
          </CustomLink>
        )}
      </ContentWrapper>
    </CustomSpace>
  );
};

export default UserTeamCard;
