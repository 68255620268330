import { useEffect, useState } from 'react';
import { Skeleton } from 'antd';
import { Row } from 'components/layout/profile/profileContent/styles';
import { CardHeaderSection } from 'pages/Teams/components/TeamSharedStyles';
import {
  DataContainer,
  DataDescription,
  DataNumber,
  IconWrapper,
  TabButton,
  TabIcon,
  TabWrapper,
  TabsContainer,
} from 'pages/Analytics/components/MetricTabs/styles';
import { TEAM_SKILLS_TABS } from 'pages/Teams/components/TeamSkills/configs';
import { LEVEL_CONSTANTS, SkillTabs } from 'pages/Teams/components/TeamSkills/types';
import {
  UserSkillsColAreas,
  UserSkillsColRadar,
  UserSkillsContainer,
  UserSkillsRow,
} from './styles';
import UserSkillsRadar from './UserSkillsRadar';
import UserSkillAreas from './UserSkillAreas';
import { getSonarScoresAction } from 'contexts/sonar/actions';
import { SonarScoresFetchParams } from 'contexts/sonar/types';
import { LEARNING_AREAS_TYPES } from 'constants/playlists';
import SkillsEmpty from 'pages/Teams/components/TeamSkills/SkillsEmpty';
import { ContainerMobile } from 'pages/Teams/components/TeamTopUsersAnalytics/styles';
import InputSelect from 'components/inputs/inputSelect';
import ComingSoon from 'pages/Teams/components/TeamSkills/ComingSoon';
import UserRecommendedContent from '../UserRecommendedContent/UserRecommendedContent';
import { isEmpty } from 'lodash';

interface UserSkillsProps {
  uaaId: string;
  userProfile: any;
}

const UserSkills = ({ uaaId, userProfile }: UserSkillsProps): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState<SkillTabs>(TEAM_SKILLS_TABS[0]);
  const [skillScores, setSkillScores] = useState<any>(null);
  const [userSkillScores, setUserSkillScores] = useState<any>(null);
  const [fetching, setFetching] = useState(false);
  const [currentLearningAreaId, setCurrentLearningAreaId] = useState<string>(
    LEARNING_AREAS_TYPES.cyberSecurity?.id
  );

  const mobileOptions = TEAM_SKILLS_TABS.map(({ icon, label, item }) => {
    return {
      value: item,
      label: label,
      icon: icon,
    };
  });

  const handleSelectTab = (item: string | number) => {
    setSelectedTab(TEAM_SKILLS_TABS.find(({ item: thisTab }) => thisTab === item));
  };

  const getSonarScores = async (scoresPayload: SonarScoresFetchParams) => {
    setFetching(true);
    const scoresResponse = await getSonarScoresAction(scoresPayload);
    if (scoresResponse instanceof Error) {
      setFetching(false);
      setSkillScores(null);
    } else {
      processResponse(scoresResponse);
      setFetching(false);
    }
  };

  const processResponse = (scoresResponse: any) => {
    let scoreSorted = {};
    if (scoresResponse?.results.length) {
      setUserSkillScores(scoresResponse?.results[0].scores);
      const skillScoresData = {};
      scoresResponse?.results[0].scores.skill_areas.map((skillArea: any) => {
        if (skillScoresData[skillArea.group]) {
          skillScoresData[skillArea.group].push(skillArea);
        } else {
          skillScoresData[skillArea.group] = [];
          skillScoresData[skillArea.group].push(skillArea);
        }
      });
      scoreSorted = sortSkillAreas(skillScoresData);
    }
    return setSkillScores(scoreSorted);
  };

  const sortSkillAreas = (skillScoresData: any) => {
    if (selectedTab.soon) return;
    Object.keys(skillScoresData).map((skillArea: any) => {
      skillScoresData[skillArea].sort((a: any, b: any) => {
        if (
          a.user_status.difficulty === LEVEL_CONSTANTS.ADVANCED &&
          (b.user_status.difficulty === LEVEL_CONSTANTS.PROFESSIONAL ||
            b.user_status.difficulty === LEVEL_CONSTANTS.NOVICE)
        ) {
          return -1;
        } else if (
          a.user_status.difficulty === LEVEL_CONSTANTS.PROFESSIONAL &&
          b.user_status.difficulty === LEVEL_CONSTANTS.NOVICE
        ) {
          return -1;
        } else if (a.user_status.difficulty === b.user_status.difficulty) {
          return b.user_status.score - a.user_status.score;
        } else {
          return 0;
        }
      });
    });
    return skillScoresData;
  };

  useEffect(() => {
    const scoresPayload = {
      uaa_ids: uaaId.split('-')[0],
      learning_area_id: LEARNING_AREAS_TYPES[selectedTab.key]?.id,
      deep_details: 1,
    };
    getSonarScores(scoresPayload);
    setCurrentLearningAreaId(LEARNING_AREAS_TYPES[selectedTab.key]?.id);
  }, [selectedTab]);

  return (
    <>
      <CardHeaderSection style={{ justifyContent: 'flex-start', gap: '5px' }}>
        <h5>Skill Sonar</h5>
      </CardHeaderSection>
      <TabsContainer skillTabs>
        {
          <ContainerMobile>
            <InputSelect
              value={`${selectedTab.label}`}
              icon={selectedTab.icon}
              options={mobileOptions}
              onChange={(selectValue) => handleSelectTab(selectValue)}
            />
          </ContainerMobile>
        }
        {TEAM_SKILLS_TABS.map(({ item, label, icon, soon }) => (
          <TabButton
            key={item}
            active={item === selectedTab?.item}
            onClick={() => handleSelectTab(item)}
          >
            <TabWrapper>
              <IconWrapper noBackground={true}>{<TabIcon src={icon} />}</IconWrapper>
              <DataContainer>
                <DataNumber>{label}</DataNumber>
                <DataDescription>{soon && 'Coming soon'}</DataDescription>
              </DataContainer>
            </TabWrapper>
          </TabButton>
        ))}
      </TabsContainer>
      <UserSkillsContainer>
        {selectedTab?.soon ? (
          <ComingSoon skillName={selectedTab.label} />
        ) : fetching ? (
          <Skeleton
            active={fetching}
            avatar
            paragraph={{ rows: 0 }}
            style={{ marginTop: '10px' }}
          />
        ) : !isEmpty(skillScores) ? (
          <UserSkillsRow>
            <UserSkillsColRadar>
              <UserSkillsRadar skillSelected={selectedTab} userScores={userSkillScores} />
            </UserSkillsColRadar>
            <UserSkillsColAreas>
              <UserSkillAreas skillAreas={skillScores} />
            </UserSkillsColAreas>
          </UserSkillsRow>
        ) : (
          <SkillsEmpty />
        )}
      </UserSkillsContainer>
      {!selectedTab?.soon && (
        <Row className="mt-2 w-100">
          <UserRecommendedContent
            uaa_id={uaaId}
            userProfile={userProfile}
            learning_area_id={currentLearningAreaId}
          />
        </Row>
      )}
    </>
  );
};

export default UserSkills;
