import { AxiosResponse } from 'axios';
import { CONTENT } from './axios';
import { config } from 'config/config.local';
import { ILearningArea } from 'contexts/learningAreas/types';

interface IGetLearningAreas {
  results: ILearningArea[];
  count: number;
}

export interface ILearningAreasFetchParams {
  page?: string | number;
  page_size?: string | number;
  company?: string;
  ids?: string[];
}

export interface ILearningAreaFetchParams {
  id: string;
}

export const getLearningAreas = (
  params: ILearningAreasFetchParams
): Promise<AxiosResponse<IGetLearningAreas>> =>
  CONTENT.get(`${config.routes.playlists.getLearningAreas}`, { params });

export const getLearningArea = (
  params: ILearningAreaFetchParams
): Promise<AxiosResponse<ILearningArea>> =>
  CONTENT.get(`${config.routes.playlists.getLearningAreas}/${params.id}`);
