import { ButtonProps } from 'antd/lib/button/button';
import Icon, { ICONS } from 'components/icons';

import { StyledButton } from './styles';

export interface IButtonProps extends ButtonProps {
  icon?: keyof typeof ICONS;
  $primary?: boolean;
  $secondary?: boolean;
  $terciary?: boolean;
  $active?: boolean;
}

const Button = ({ icon, children, ...rest }: IButtonProps): JSX.Element => (
  <StyledButton {...rest}>{children ? children : icon ? <Icon icon={icon} /> : ' '}</StyledButton>
);

export default Button;
