import { Input, Label, InputContainer } from 'components/modal/styles';

interface ITeamName {
  teamName: string;
  setTeamName: (value: string) => void;
  team: boolean;
}

export const TeamNameInput = ({ teamName, setTeamName, team }: ITeamName): JSX.Element => {
  return (
    <InputContainer>
      <Label>{team ? 'Name' : 'Add a descriptive Name'}</Label>
      <Input type={'text'} value={teamName} onChange={(e) => setTeamName(e.currentTarget.value)} />
    </InputContainer>
  );
};
