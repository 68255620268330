import { FC, useContext } from 'react';
import styled from 'styled-components';
import ButtonIcon from 'components/buttonIcon';
import { SessionContext } from 'contexts/session';
import { screenMd } from 'constants/styles/breakpoints';
import { Domains } from 'contexts/accounts/types';

export type AccountDomainProps = {
  domains: Domains[];
  onDomainEdit: (domain: Domains) => void;
  onDomainRemove: (id) => void;
};

const DomainsContainer = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  grid-gap: 15px;
  max-height: calc(100% - 30px);
  overflow: scroll;
  padding: 5px 10px 0 0;
  ${screenMd} {
    padding-right: 15px;
  }
  > li {
    list-style: none;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    color: ${({ theme }) => theme.default.colors.dataVisualization.purple.dark};
    > span {
      flex-shrink: 3;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 130px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  > p {
    font-size: 12px;
    line-height: 14px;
  }
`;

const AccountDomains: FC<AccountDomainProps> = ({ domains, onDomainEdit, onDomainRemove }) => {
  const { isImpersonated, isTeamManager, isAccountOwner } = useContext(SessionContext);
  const isOwnerOrManager = isTeamManager || isAccountOwner;

  return (
    <DomainsContainer>
      {domains.map(({ email_domain, id }) => (
        <li key={email_domain}>
          <span>{email_domain}</span>
          {!isImpersonated && !isOwnerOrManager && (
            <>
              <ButtonIcon
                $terciary
                size={'small'}
                style={{ margin: '0 8px 0 auto' }}
                onClick={() => onDomainEdit({ id, email_domain })}
                icon={'Edit'}
              />
              <ButtonIcon
                $secondary
                size={'small'}
                onClick={() => onDomainRemove({ id, email_domain })}
                icon={'Trash'}
              />
            </>
          )}
        </li>
      ))}
      {domains.length === 0 && <p>This account doesn’t have any domains yet.</p>}
    </DomainsContainer>
  );
};

export default AccountDomains;
