import { useRef, useState } from 'react';

import { IPlaylistContentItem } from 'contexts/playlists/types';
import Icon from 'components/icons';
import Tooltip from 'components/TooltipV2';
import { colors } from 'constants/styles/colors';
import { screenSm } from 'constants/styles/breakpoints';
import styled from 'styled-components';

export const ContentProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #57575c;
  gap: 10px;
  margin-left: 20px;
  text-transform: capitalize;
  ${screenSm} {
    margin: 0px;
  }
`;

const BarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 160px;
  height: 4px;
  position: relative;
  background: #f2f2f7;
  border-radius: 30px;
`;

const StatusLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100px;
`;

const Bar = styled.div<{ progress?: number }>`
  width: ${({ progress = 0 }) => progress}%;
  height: 4px;
  position: absolute;
  top: 0;
  left: 0;
  background: #a793f6;
  border-radius: 50px;
`;

const Step = styled.div<{ active: boolean; position?: number }>`
  position: absolute;
  left: ${({ position = 0 }) => position}%;
  transform: translateX(-50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${({ active }) => (active ? '#a793f6' : '#f2f2f7')};
`;

const txtLabelsMap = {
  common: {
    user_status: {
      unstarted: 'Not started',
      started: 'Started',
      finished: 'Completed',
    },
  },
  lab: {
    tooltip: {
      unstarted: 'Lab not started',
      started: 'Lab started',
      finished: 'All flags correctly captured',
      non_trackable:
        'This lab only tracks ‘Not started’ and ‘Started’ statuses and doesn’t count towards playlist completion',
    },
  },
  course: {
    tooltip: {
      unstarted: 'Course not started',
      started: 'Course started',
      finished: 'All course content complete',
      non_trackable:
        'This course only tracks ‘Not started’ and ‘Started’ statuses and doesn’t count towards playlist completion',
    },
  },
  video: {
    tooltip: {
      unstarted: 'Video not started',
      started: 'Video started',
      finished: 'Video complete',
    },
  },
  'learning-path': {
    tooltip: {
      unstarted: 'Learning path not started',
      started: 'Learning path started',
      finished: 'Learning path complete',
    },
  },
};

interface IContentProgressBar {
  item: IPlaylistContentItem;
}

const ContentProgressBar: React.FC<IContentProgressBar> = ({ item }) => {
  const progressBarRef = useRef<HTMLDivElement>(null);

  let isTrackable;

  let isStarted;
  let isUnstarted;
  let isCompleted;
  let progress;

  let statusText;
  let userStatusKey;
  let infoTooltipText;

  switch (item.content_type) {
    case 'video':
      userStatusKey = item.user_status as string;

      isStarted = userStatusKey === 'started';
      isUnstarted = userStatusKey === 'unstarted';
      isCompleted = userStatusKey === 'finished';

      statusText = txtLabelsMap.common.user_status[userStatusKey];
      infoTooltipText = txtLabelsMap.video.tooltip[userStatusKey];

      isTrackable = item.is_trackable;

      progress = item.watched_percentage > 100 ? 100 : item.watched_percentage;

      break;
    case 'lab': {
      userStatusKey = item.user_status as string;

      isUnstarted = userStatusKey === 'unstarted';
      isStarted = userStatusKey === 'started';

      isTrackable = item.is_trackable;
      statusText = txtLabelsMap.common.user_status[userStatusKey];

      if (isTrackable) {
        progress = item.flags?.length ? (100 / item.flags.length) * item.correct_flags : 0;

        if (isStarted && item.correct_flags) {
          infoTooltipText = `${item.correct_flags}/${item.flags.length} flags captured`;
        } else {
          infoTooltipText = txtLabelsMap.lab.tooltip[userStatusKey];
        }
      } else {
        infoTooltipText = txtLabelsMap.lab.tooltip.non_trackable;
      }

      break;
    }
    case 'learning-path':
    case 'course': {
      progress = typeof item.user_status === 'object' ? item.user_status.progress * 100 : 0;

      isUnstarted = progress === 0;
      isStarted = progress > 0;
      isCompleted = progress === 100;
      isTrackable = item.has_trackable_content;

      if (isUnstarted) {
        userStatusKey = 'unstarted';
      } else if (isCompleted) {
        userStatusKey = 'finished';
      } else {
        userStatusKey = 'started';
      }

      statusText = txtLabelsMap.common.user_status[userStatusKey];
      infoTooltipText =
        txtLabelsMap[item.content_type].tooltip[isTrackable ? userStatusKey : 'non_trackable'];

      break;
    }
    case 'link':
      isCompleted = item.user_status === 'visited';
      isUnstarted = item.user_status === 'unvisited';
      isTrackable = item.is_trackable;

      if (isCompleted) {
        statusText = 'Opened';
        infoTooltipText = 'URL has been opened';
      } else {
        statusText = 'Not opened';
        infoTooltipText = 'URL has not been opened';
      }

      break;
    case 'quiz':
    case 'exercise':
      isTrackable = item.is_trackable;
      break;
  }

  const BarChildComponents = () => {
    switch (item.content_type) {
      case 'video':
      case 'iframe':
      case 'learning-path':
      case 'course':
      case 'lab': {
        return (
          <>
            <Tooltip content={`${progress}%`} triggerRef={progressBarRef}>
              <Step active position={progress} />
            </Tooltip>
            <Bar progress={progress} />
          </>
        );
      }
      case 'link': {
        return (
          <>
            <Step active={isCompleted} position={0} />
            <Step active={isCompleted} position={100} />
            <Bar progress={isCompleted ? 100 : 0} />
          </>
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <ContentProgressBarContainer>
      {isTrackable && (
        <BarContainer ref={progressBarRef}>{!isUnstarted && <BarChildComponents />}</BarContainer>
      )}
      <StatusLabelContainer>
        <span>{statusText}</span>
        <Tooltip content={infoTooltipText} placement="topRight">
          <div>
            <Icon
              fill={isTrackable ? colors.iconButtons.purple.dark : '#F8B369'}
              icon="Info"
              size="small"
            />
          </div>
        </Tooltip>
      </StatusLabelContainer>
    </ContentProgressBarContainer>
  );
};

export default ContentProgressBar;
