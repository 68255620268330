import { CardRowContainer } from 'components/layout/profile/profileContent/styles';
import { screenMd } from 'constants/styles/breakpoints';
import styled from 'styled-components';

export const TeamCardContainer = styled(CardRowContainer)`
  padding-top: 25px;
  padding-bottom: 15px;
  ${screenMd} {
    padding-top: 35px;
    padding-bottom: 27px;
  }
`;

export const CardHeaderSection = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  > h5 {
    font-weight: 700;
    font-size: 24px;
    margin: 0;
  }
  > p {
    font-size: 12px;
    margin: 0;
    color: #57575c;
  }
`;
