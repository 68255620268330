import 'assets/sass/style.scss';
import 'assets/sass/style.react.scss';
import './index.scss';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'antd/dist/antd.css';
import './App.less';

import App from './App';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import MaintenancePage from 'pages/Maintenance/Maintenance';
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import reportWebVitals from './reportWebVitals';
import { setupJsCommon } from '@internetworkexpert/js-common';
import { getSessionData } from 'services/session';
import { handleErrors } from '../src/contexts/helpers';

/* eslint-disable @typescript-eslint/no-var-requires */
const { theme } = require('constants/styles/theme');

const appVersion = '0.54.1';

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_KEY,
  releaseStage: process.env.REACT_APP_ENV,
  plugins: [new BugsnagPluginReact()],
  autoDetectErrors: false,
  enabledReleaseStages: ['production', 'staging', 'development'],
  appVersion,
});
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
const ErrorView = () => (
  <div>
    <p>
      Error in the component tree. This may refer to a code error or an unexpected API response.
    </p>
  </div>
);

setupJsCommon(process.env.REACT_APP_ENV || 'production');

const isUnderMaintenance = process.env.REACT_APP_UNDER_MAINTENANCE === 'true';

(async () => {
  try {
    const {
      data: { user },
    } = await getSessionData();

    const profileData = user?.uaa_data.profile.data;

    Bugsnag.addOnError((event) => {
      event.setUser(user.uaa_id, user.email, `${profileData.first_name} ${profileData.last_name}`);
    });

    ReactDOM.render(
      <ErrorBoundary FallbackComponent={ErrorView}>
        <ThemeProvider theme={theme}>
          {isUnderMaintenance ? <MaintenancePage /> : <App />}
        </ThemeProvider>
      </ErrorBoundary>,
      document.getElementById('root')
    );
  } catch (error) {
    handleErrors(error);
    ReactDOM.render(
      <ErrorBoundary FallbackComponent={ErrorView}>
        <ThemeProvider theme={theme}>
          <MaintenancePage />
        </ThemeProvider>
      </ErrorBoundary>,
      document.getElementById('root')
    );
  }
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
