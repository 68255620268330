import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import Button from 'components/button';
import { screenMd } from 'constants/styles/breakpoints';
import { AccountSummary, UserDetail } from 'types/users';

const ImpersonateContainer = styled.div`
  background: linear-gradient(90deg, #b67cff 0%, #9885e6 100%);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 19px 12px;
  ${screenMd} {
    padding: 15px 20px;
  }
  > p {
    margin-bottom: 0;
    font-size: 13px;
    line-height: 17px;
    font-weight: 700;
    color: #fff;
    ${screenMd} {
      font-size: 16px;
    }
    > span,
    a {
      color: inherit;
      border-bottom: 1px solid #fff;
    }
  }
`;

const ImpersonateNotice = ({
  user,
  account,
  onClose,
}: {
  user: UserDetail;
  account: AccountSummary;
  onClose(): void;
}): JSX.Element => {
  const label = isMobile ? 'Impersonated as' : 'You are impersonated as';

  return (
    <ImpersonateContainer>
      <p>
        {label}
        <Link
          to={`/user/${user?.id}`}
        >{` ${user?.uaa_data?.profile?.data?.first_name} ${user?.uaa_data?.profile?.data?.last_name}`}</Link>
        , {user?.uaa_data?.roles?.data?.[0].name}.
        <Link to={`/account/${account?.id}`}>{` ${account?.name}`}</Link>
      </p>
      <Button $primary size={'small'} onClick={onClose}>
        Stop impersonate
      </Button>
    </ImpersonateContainer>
  );
};

export default ImpersonateNotice;
