import styled from 'styled-components';
import { screenMd } from 'constants/styles/breakpoints';
import { colors } from 'constants/styles/colors';

export const Title = styled.h5`
  display: flex;
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.default.colors.palette.Business_DarkThemeGeneralBackground};
`;

export const Description = styled.div<{ customMargin?: string }>`
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  color: ${({ theme }) => theme.default.colors.palette.Business_LightThemeGreyDarker};

  p {
    margin: 0;
  }
  ${({ customMargin }) =>
    customMargin &&
    `
		margin: ${customMargin};
	`}
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: auto;
  grid-gap: 10px;
`;

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  padding: 30px;
`;

export const SecureInput = styled.input<{ error: boolean }>`
  margin: 20px 0 0 0;
  font-weight: 400;

  ${({ error }) =>
    error &&
    `
    border: 1px solid red;
  `}
`;

export const ModalContainer = styled.div<{ lock?: boolean }>`
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100%;
  ${screenMd} {
    min-height: none;
    display: block;
    overflow: hidden;
    border-radius: 18px;
  }
  ${({ lock }) =>
    lock &&
    `
		opacity: 0.4;
		pointer-events: none;
	`}
`;

export const HeaderContainer = styled.div<{ isSonar?: boolean; isRax?: boolean }>`
  width: 100%;
  min-height: 127px;
  height: 155px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(45.99deg, #863ad2 30%, #ef7d22 70%);
  flex-shrink: 0;
  padding: 10%;
  ${screenMd} {
    height: 155px;
    background: linear-gradient(45deg, #ef7d22 1%, #863ad2 30%, #190da0),
      linear-gradient(90deg, transparent 1%, #3c1aef 80%);
    flex-direction: column;
  }
  ${({ isSonar }) =>
    isSonar &&
    `
    padding: 30px;
    background: linear-gradient(45deg, #b44995 1%, #863ad2 22%, #190da0),
      linear-gradient(90deg, transparent 1%, #3c1aef 80%) !important;
	`}
  ${({ isRax }) =>
    isRax &&
    `
    background: var(--secondary-promo-purple-2) !important;
	`}
  
  > h2 {
    color: #ffffff;
    font-size: 20px;
    font-weight: 500;
    margin: 0;
    white-space: pre-wrap;
    ${screenMd} {
      font-size: 24px;
      font-weight: 400;
    }
  }
`;

export const ModalContent = styled.div<{
  grow?: boolean;
  flexColumn?: boolean;
  sidePadding?: boolean;
}>`
  padding: 36px 0px 20px 0px;
  height: 100%;
  ${({ sidePadding }) =>
    sidePadding &&
    `
    padding: 36px 20px 20px;
	`}
  ${screenMd} {
    padding: 30px;
  }
  ${({ grow }) =>
    grow &&
    `
		flex-grow: 1;
	`}
  ${({ flexColumn }) =>
    flexColumn &&
    `
		display: flex;
		flex-direction: column;
	`}
`;

export const ModalSectionTitle = styled.h2<{ customMargin?: string }>`
  margin: 0 0 6px 0;
  padding: 0 10px;
  color: ${({ theme }) => theme.default.colors.fonts.dark};
  font-size: 20px;
  font-weight: 500;
  ${screenMd} {
    font-weight: 700;
    padding: 0;
  }
  ${({ customMargin }) =>
    customMargin &&
    `
		margin: ${customMargin};
	`}
`;

export { ModalSectionTitle as ModalLabels };

export const Input = styled.input<{ error?: boolean }>`
  height: 44px;
  font-size: 12px;
  padding: 15px 10px;
  display: block;
  border: none;
  border-radius: 10px;
  width: 100%;
  background: ${({ theme }) => theme.default.colors.palette.Business_LightThemeGreyLighter};
  &:focus,
  &:active {
    outline: none;
  }
  &::placeholder {
    color: ${({ theme }) => theme.default.colors.fonts.darkGray};
  }
  ${({ error }) =>
    error &&
    `
				box-shadow: 0px 0px 0px 1px red;
			`}
`;

export const Textarea = styled.textarea<{ error?: boolean }>`
  resize: none;
  font-size: 12px;
  padding: 15px 10px;
  margin: 0;
  background: ${({ theme }) => theme.default.colors.palette.Business_LightThemeGreyLighter};
  width: 100%;
  border: none;
  border-radius: 10px;
  min-height: 100px;
  font-family: Circular-Std;
  color: #1e1e1e;
  &:focus {
    outline: none;
  }
  ${({ error }) =>
    error &&
    `
				box-shadow: 0px 0px 0px 1px red;
			`}
`;

export const InputContainer = styled.div`
  padding: 0px;
  ${screenMd} {
    padding: 0;
  }
`;

export const Label = styled.label`
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 20px;
  color: ${({ theme }) => theme.default.colors.palette.Business_LightThemeGreyDarker};
`;

export const InputHelper = styled.label`
  font-size: 12px;
  line-height: 14px;
  margin-top: 10px;
  color: ${({ theme }) => theme.default.colors.palette.Business_LightThemeGreyDarker};
`;

export const ModalSection = styled.div<{
  grow?: boolean;
  flexColumn?: boolean;
  toBottom?: boolean;
  customMargin?: string;
}>`
  margin: 15px 0;
  padding: 0 10px;
  ${screenMd} {
    padding: 0;
  }
  ${({ grow }) =>
    grow &&
    `
		flex-grow:
	`}
  ${({ flexColumn }) =>
    flexColumn &&
    `
		display: flex;
		flex-direction: column;
	`}
	${({ toBottom }) =>
    toBottom &&
    `
		margin-top: auto;
	`}
	${({ customMargin }) =>
    customMargin &&
    `
		margin: ${customMargin};
	`}
`;

export const ModalActions = styled.div`
  padding: 20px 10px;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: auto;
  > button {
    margin-left: 12px;
  }
  ${screenMd} {
    padding: 0px 30px 30px 30px;
  }
`;

export const FooterLeftText = styled.div`
  font-size: 10px;
  line-height: 12px;
  font-weight: 450;
  width: 60%;
  display: flex;
  gap: 5px;
  align-items: center;
  span {
    color: ${colors.fonts.darkGray};
  }
`;
