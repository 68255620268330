import { IconTeam, TeamIcon } from 'components/teamSelect/IconTeam';
import { isMobile } from 'react-device-detect';
import { TeamIconWrapper, TeamIconsContainer } from './styles';
import { Label } from 'components/modal/styles';
import { colors } from 'constants/styles/colors';

interface ITeamIconSelect {
  active: TeamIcon;
  onChange(icon): void;
  color: keyof typeof colors.dataVisualization;
}

const ICONS = [
  'board',
  'chat',
  'cloud-net',
  'connect',
  'file-target',
  'final',
  'graph-bars',
  'group',
  'light-bulb',
  'network',
  'path-check',
  'pie-graph',
  'profile-config',
];

export const TeamIconSelect = ({ active, onChange, color }: ITeamIconSelect): JSX.Element => {
  return (
    <>
      <Label>Select an avatar</Label>
      <TeamIconsContainer>
        {ICONS.map((icon) => {
          return (
            <TeamIconWrapper key={icon} selected={icon === active} onClick={() => onChange(icon)}>
              <IconTeam
                iconName={icon as TeamIcon}
                color={color}
                size={isMobile ? 'small' : 'default'}
              />
            </TeamIconWrapper>
          );
        })}
      </TeamIconsContainer>
    </>
  );
};
