export interface SkillTabs {
  key: string;
  item: SkillTypes;
  label: string;
  soon: boolean;
  icon: string;
  color: string;
  background: string;
  groups: string[];
}

export type SkillTypes = 'CYBER_SECURITY' | 'CLOUD' | 'NETWORKING';

export const SKILL_TYPES_CONST = {
  CYBER_SECURITY: 'CYBER_SECURITY',
  CLOUD: 'CLOUD',
  NETWORKING: 'NETWORKING',
};

export const MIN_COLUMNS_TO_SHOW = 6;

export const LEVEL_CONSTANTS = {
  NOVICE: 'novice',
  PROFESSIONAL: 'professional',
  ADVANCED: 'advanced',
};

export const SKILL_GROUPS = {
  RED_TEAM: 'Red Team',
  BLUE_TEAM: 'Blue Team',
  YELLOW_TEAM: 'Yellow Team',
};

export const CyberSecurityGroups = ['Red Team', 'Blue Team', 'Yellow Team'];
export const NetworkingGroups = ['Enterprise Infrastructure', 'Network Security'];
export const CloudGroups = ['Cloud Infrastructure'];
