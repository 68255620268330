import styled from 'styled-components';

import { screenLg, screenMd, screenSm, screenXlg, screenXs } from 'constants/styles/breakpoints';

import Checkbox from 'components/checkbox';

import { Container } from './ReportingHistoryComponents';
import { COURSES, TEAM_ACTIVITY, USER_ACTIVITY } from 'constants/reporting';

import UserSelect from './UserSelect/index';
import TeamSelect from '../../Users/components/InviteUsers/TeamSelect';

export const Filters = {
  Container: styled(Container)`
    margin-bottom: 10px;
    height: unset;

    ${screenXs} {
      border-radius: 0;
    }

    ${screenMd} {
      border-radius: 10px;
    }
  `,
  BasicView: styled.div<{ isTablet?: boolean }>`
    display: block;
    ${screenMd} {
      margin-bottom: unset;
      display: grid;
      grid-template-columns: 60% 40%;
      ${({ isTablet }) =>
        isTablet &&
        `
        grid-template-columns: 100%;
      `};
    }
    ${screenLg} {
      margin-bottom: unset;
      display: grid;
      grid-template-columns: 70% 30%;
    }
  `,
  AdvancedView: styled.div<{ show?: boolean }>`
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    gap: 10px;
    justify-content: flex-start;
    ${({ show }) =>
      !show &&
      `
      display: none;
    `}
    ${screenXlg} {
      margin-top: 0;
      display: grid;
      grid-template-columns: repeat(6, fit-content(200px));
      column-gap: 10px;
    }
  `,
  AdvancedViewHeader: styled.div<{ show?: boolean; isTablet?: boolean }>`
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    gap: 30px;
    ${({ show }) =>
      !show &&
      `
      display: none;
    `}
    ${screenMd} {
      margin-top: 30px;
      flex-direction: row;
      align-items: center;
    }
  `,
  TeamFilterContainer: styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: space-between;
    font-size: 14px;
    color: var(--light-gray-darker);
    font-weight: 400;
    ${screenMd} {
      gap: 10px;
      font-size: 15px;
      min-width: 402px;
    }
  `,
  Buttons: styled.div<{ isMobile?: boolean; isTablet?: boolean; marginTop?: boolean }>`
    display: flex;
    justify-content: space-between;
    ${({ marginTop, isMobile, isTablet }) =>
      marginTop &&
      (isTablet || isMobile) &&
      `
      margin-top: 10px;
    `}
    ${screenMd} {
      justify-content: flex-end;
    }
    button {
      height: 43px !important;
      ${screenMd} {
        ${({ isTablet }) =>
          !isTablet &&
          `
          padding: 10px 24px !important;
          width: unset !important;
        `}
      }
      &:first-of-type {
        margin-right: 10px;
      }
      &:last-of-type {
        &:hover {
          box-shadow: 0px 4px 20px rgba(18, 18, 18, 0.4) !important;
        }
        ${screenMd} {
          margin-right: 10px;
          ${({ isTablet }) =>
            isTablet &&
            `
            margin-right: unset;
          `}
        }
      }
    }
  `,
  IdsFilterContainer: styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    font-size: 14px;
    color: var(--light-gray-darker);
    font-weight: 400;
    ${screenMd} {
      font-size: 15px;
    }
  `,
};

export const AdvanceTitle = styled.div`
  font-family: 'Circular-Std';
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  grid-column: 1/3;
  color: ${({ theme }) => theme.default.colors.fonts.darkGray} !important;
  ${screenMd} {
    font-size: 14px;
    grid-column: 1/6;
    grid-row: 1/2;
  }
`;

export const AdvanceSubtitle = styled.div`
  font-family: 'Circular-Std';
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 5px;
  font-weight: 500;
  color: ${({ theme }) => theme.default.colors.fonts.dark} !important;
  width: 100%;
  ${screenMd} {
    margin-top: 30px;
    grid-column: 1/7;
  }
`;

export const DateFilter = styled.div<{ isTablet?: boolean }>`
  font-size: 15px;
  line-height: 19px;
  display: grid;
  grid-template-columns: 30% 70%;
  align-items: center;
  margin-bottom: 10px;
  label {
    color: var(--light-gray-darker);
    font-weight: 400;
    margin-right: 10px;
  }
  ${screenMd} {
    display: flex;
    margin-bottom: 0;
    ${({ isTablet }) =>
      isTablet &&
      `
      display: grid;
    `};
  }
`;

export const UsersSelector = styled(UserSelect)``;

export const TeamsSelector = styled(TeamSelect)``;

export const ClearInput = styled.div`
  position: absolute;
  div {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    margin: 10px 0px 0 190px;
    border-radius: 50%;
    transition: background-color 0.3s;
    ${screenXs} {
      margin: 10px 0px 0 210px;
    }
    ${screenSm} {
      margin: 10px 0px 0 57vw;
    }
    ${screenMd} {
      margin: 10px 0px 0 190px;
    }
    &:hover {
      background-color: #fff;
    }
  }
`;
export const SelectContainer = styled.div<{ showCustom?: boolean; isTablet?: boolean }>`
  display: grid;
  grid-template-columns: ${({ showCustom }) => (showCustom ? '85% 15%' : '100%')};
  .ant-picker-suffix svg {
    width: 18px;
    height: 18px;
  }
  button {
    height: 43px !important;
  }
  div:first-of-type {
    ${({ showCustom }) => showCustom && 'margin-right: 5px;'};
  }
  ${screenMd} {
    width: 100%;
    max-width: 250px;
    grid-template-columns: ${({ showCustom }) => (showCustom ? '250px 20%' : '100%')};
    ${({ isTablet }) =>
      isTablet &&
      `
      max-width: unset;
      grid-template-columns: 100%;
    `};
  }
`;

export const ButtonsContainer = styled.div<{ desktop?: boolean }>`
  display: ${({ desktop }) => (desktop ? 'none' : 'block')};
  padding-top: 30px;

  ${screenMd} {
    display: ${({ desktop }) => (desktop ? 'block' : 'none')};
    padding-top: 0;
  }
`;

export const Filter = {
  Container: styled.div<{ endOfRow?: boolean; filtersType?: string }>`
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    color: ${({ theme }) => theme.default.colors.fonts.dark} !important;
    ${screenMd} {
      ${({ endOfRow, filtersType }) => {
        if (endOfRow) {
          switch (filtersType) {
            case USER_ACTIVITY:
              return 'grid-column: span 6;';
            case TEAM_ACTIVITY:
              return 'grid-column: span 6;';
            case COURSES:
              return 'grid-column: span 5;';
            default:
              break;
          }
        }
      }}
    }
  `,
  Title: styled.div`
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  `,
  Checkbox: styled(Checkbox)<{ mandatory?: boolean }>`
    margin-left: unset !important;
    margin-bottom: 10px;
    font-size: 14px !important;
    &:last-of-type {
      margin-bottom: unset;
    }

    > span:not(.ant-checkbox) {
      width: 149px;
      white-space: pre-line !important;
    }
    .ant-checkbox {
      align-self: flex-start !important;
    }
    ${({ mandatory }) =>
      mandatory &&
      `
      opacity: 0.7;
      pointer-events: none;
    `}
    ${screenMd} {
      margin-bottom: 15px;
      > span:not(.ant-checkbox) {
        width: unset;
      }
    }
  `,
  Group: styled.div<{ twoColumns?: boolean }>`
    gap: 8px;

    display: grid;
    grid-template-columns: ${({ twoColumns }) => (twoColumns ? '1fr 1fr' : '1fr')};
    ${screenMd} {
      gap: '15px';
      display: inline-flex;
      flex-flow: row;
      align-items: flex-start;
    }
  `,
  List: styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    flex-flow: column;
    gap: 10px;
    ${screenMd} {
      width: 180px;
    }
    ${screenLg} {
      width: 200px;
    }
  `,
  Item: styled.div<{ greyed?: boolean }>`
    display: flex;
    align-items: 'center';
    width: 100%;
    justify-content: space-between;
    align-items: center;
    ${({ greyed }) => greyed && `opacity: 50%; pointer-events: none;`}
    > svg {
      display: none;
      min-width: 14px;
    }

    ${screenMd} {
      > svg {
        display: block;
        &:hover {
          color: var(--secondary-purple);
        }
      }
    }
  `,
};
