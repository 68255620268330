import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { AxisTick } from 'components/charts/common';
import { BarsChartProps, CHART_BAR_COLORS } from './configs';

const TotalLabel = ({ value, x, y }: { value: number; x: number; y: number }) => {
  return (
    <g transform={`translate(${x + 50},${y + 11})`}>
      <text textAnchor="end" fill="#57575C" fontSize={'12px'} fontFamily="inherit">
        {value} {value === 1 ? 'item' : 'items'}
      </text>
    </g>
  );
};

const PreferredContentChart = ({ data, tickImage, svg }: BarsChartProps): JSX.Element => {
  const barConfig = {
    stackId: 'a',
    barSize: 15,
    radius: 4,
    minPointSize: 10,
  };

  return (
    <ResponsiveContainer width={'100%'} height={400}>
      <BarChart data={data} layout="vertical" margin={{ top: 5, right: 60, left: 20, bottom: 5 }}>
        <CartesianGrid vertical={false} opacity={0} />
        <XAxis tickLine={false} axisLine={false} type="number" tick={false} />
        <YAxis
          dataKey={'renderData'}
          type="category"
          axisLine={null}
          tickLine={false}
          tick={({ x, y, stroke, payload }) => (
            <AxisTick
              x={x}
              y={y}
              stroke={stroke}
              payload={payload}
              axis={'Y'}
              image={tickImage}
              svg={svg}
            />
          )}
        />
        <Bar dataKey="itemData" fill={CHART_BAR_COLORS.OVERALL_ACTIVITIES} {...barConfig}>
          <LabelList dataKey="label" position="right" />
        </Bar>
        {/* this bar is only for placing the label where it should be */}
        <Bar
          dataKey="label"
          stackId="a"
          label={({ value, x, y }) => <TotalLabel value={value} x={x} y={y} />}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default PreferredContentChart;
