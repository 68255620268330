import React, { useContext, useEffect, useReducer, useState } from 'react';

import { isDesktop, isMobile } from 'react-device-detect';
import { isEmail } from 'react-multi-email';
import FeatherIcon from 'feather-icons-react';
import 'react-multi-email/style.css';
import Notification from 'components/notification';
import { getDomain } from 'constants/utilities/emails';
import { Team, UserTeam } from 'contexts/teams/types';
import { getAlreadyInvited } from 'services/alreadyInviteds';
import { sendAccountOwnerInvites, sendUserInvites } from 'services/userInvites';
import { AccountInvite } from '..';
import AddMessage from '../AddMessage';
import AdjustUsersSettings from '../AdjustUsersSettings';
import EmailInput from '../EmailInput';
import Footer from '../Footer';
import RemoveUsers from '../RemoveUsers/RemoveUsers';
import { AdByemailContainer, BodyTitle, LicenseAvailables } from '../styles';
import TeamSelect from '../TeamSelect';
import { Invite, InvitesBatch, invitesReducer } from './invitesReducer';
import { UserTeamsPayload } from 'components/usersTable/types';

interface IAddEmails {
  onStepChange: (step: number) => void;
  onInvitesSubmit: (ok: boolean, invites?: Invite[]) => void;
  inviteAccOwner?: boolean;
  account?: AccountInvite;
}
const emailBodyTitle = 'Add email(s)';
const emailBodySubtitle = 'Add multiple emails separated by a comma (,).';

const licensesAvailablesLabel = 'Licenses available: ';

const TOTAL_STEPS = 4;

const AddEmails: React.FC<IAddEmails> = ({
  onStepChange,
  onInvitesSubmit,
  inviteAccOwner,
  account,
}) => {
  const [step, setStep] = useState(0); // we should pass some prop to launch specific step
  const [emails, setEmails] = useState<string[]>([]);
  const [errors, setErrors] = useState([]);
  const [inviteEditMobile, setinviteEditMobile] = useState(false);
  const [teams, setTeams] = useState<UserTeamsPayload[]>([]);
  const [userTeams, setUserTeams] = useState<UserTeam[]>([]);
  const [disabled, setDisabled] = useState(false);
  const [invitesData, setInvitesData] = useReducer(invitesReducer, {
    message: null,
    account: inviteAccOwner ? account.id : undefined,
  } as InvitesBatch);
  const infiniteLicenses = account.licenses_available === true;

  const accountDomains = account?.email_domains;

  const setupDefaultLicense = (email: string, newInvites) => {
    const exist = invitesData?.invites.find(({ email: inviteEmail }) => inviteEmail === email);
    if (exist) return exist.has_license;
    if (infiniteLicenses) return true;
    if (account.licenses_available > newInvites.filter(({ has_license }) => has_license).length)
      return true;
    return false;
  };

  const isAccountDomain = (email: string) => {
    const domain = getDomain(email);
    return accountDomains.some(({ email_domain }) => email_domain === domain);
  };

  const validateAreadyInvited = async (callback: { (alreadyInviteds): void }) => {
    const validEmails = invitesData.invites.map(({ email }) => email);
    setDisabled(true);
    if (inviteAccOwner) {
      callback(false);
    } else {
      const response = await getAlreadyInvited(validEmails);
      if (response?.status === 200) {
        const alreadyInvited = response.data.already_invited.length > 0;
        if (alreadyInvited) {
          handleAlreadyInvited(response.data.already_invited);
        }
        callback(alreadyInvited);
      } else {
        // @ToDo: handle errors properly console.log('error api call');
      }
    }
    setDisabled(false);
  };

  const updateInvites = (newInvites: unknown[]) => {
    setInvitesData({
      type: 'SET_EMAIL_INVITES',
      value: newInvites,
    });
  };

  const sendInvites = async () => {
    setDisabled(true);
    const inviteService = inviteAccOwner ? sendAccountOwnerInvites : sendUserInvites;
    try {
      const response = await inviteService(invitesData);
      if (response?.status >= 200) {
        Notification({ text: 'Invitation sent successfully', type: 'success' });
        onInvitesSubmit(true, invitesData.invites);
      }
    } catch (error) {
      const msg = error.response.data.detail
        ? error.response.data.detail
        : 'Something went wrong, if it repeats please contact support.';
      Notification({ text: msg, type: 'error' });
    } finally {
      setDisabled(false);
    }
  };

  const handleNextPress = () => {
    const invitesWithLicenses = invitesData.invites.filter(({ has_license }) => has_license).length;
    const insufficientLicenses =
      !infiniteLicenses && invitesWithLicenses > account.licenses_available;
    if (step === 0 && emails.length > 0) {
      setUserTeamsToInvites();
      validateAreadyInvited((alreadyInviteds) => {
        alreadyInviteds || errors.length > 0 ? setStep(step + 1) : setStep(step + 2);
      });
    } else if (step === 2 && invitesData?.invites.length > 0 && !insufficientLicenses) {
      setStep(3);
    } else if (step === 3) {
      setTeamsPayloadToInvites();
      sendInvites();
    }
  };

  const handleAlreadyInvited = (alreadyInvited: unknown[]) => {
    const initialErrors = [];
    const invitationErrors = alreadyInvited.map(
      (invitation: {
        account: { id: string };
        is_active: boolean;
        teams: { team_id: string; name: string; is_manager: boolean }[];
        email: string;
      }) => {
        let error = '';
        if (invitation.account?.id !== account.id) {
          error =
            'Failed: This email address is associated with another account, check if the email is already associated with an account';
        } else {
          if (invitation.is_active) {
            error =
              invitation.teams.length > 0
                ? `Failed: This email address is already associated to teams ${invitation.teams
                    .map((team) => team.name)
                    .join(', ')}`
                : 'Failed: This email address is already associated with this account';
          } else {
            error =
              invitation.teams.length > 0
                ? `Failed: This email address has already been invited to teams ${invitation.teams
                    .map((team) => team.name)
                    .join(', ')}`
                : 'Failed: This email address has already been invited checks if you have another invite active';
          }
        }
        if (
          !initialErrors.includes(invitation.email) &&
          !errors
            .filter((item) => item)
            .map(({ email }) => email)
            .includes(invitation.email)
        ) {
          initialErrors.push(invitation.email);
          return {
            email: invitation.email,
            error: error,
          };
        }
      }
    );
    setErrors((prevState) => [...prevState, ...invitationErrors]);
  };

  const handleBackPress = () => {
    if (step === 0) {
      onInvitesSubmit(false);
    } else if (step === 2 && inviteEditMobile) {
      setinviteEditMobile(false);
    } else if (step === 2 && errors.length === 0) {
      setStep(0);
    } else {
      setStep(step <= 0 ? 0 : step - 1);
    }
  };

  const handleRemoveUsers = () => {
    const emailErrors = errors.map(({ email }) => email);
    const newEmails = emails.filter((email) => !emailErrors.includes(email));
    if (newEmails.length === 0) {
      setStep(0);
      setEmails(newEmails);
    } else {
      setEmails(newEmails);
      setErrors([]);
      setStep(step + 1);
    }
  };

  const handleRemoveInvitation = (email: string) => {
    const newEmails = emails.filter((inviteEmail) => !inviteEmail.includes(email));
    setEmails(newEmails);
    if (invitesData.invites.length === 1) {
      updateInvites([]);
      setStep(0);
    }
  };

  const handleTeamChange = (newTeams: Team[]) => {
    if (isDesktop) {
      setTeams(
        newTeams.reduce((acc, team) => {
          acc.push({ team_id: team.id, is_manager: false });
          return acc;
        }, [])
      );
      setUserTeams(
        newTeams.reduce((acc, team) => {
          acc.push({ team: team, is_manager: false });
          return acc;
        }, [])
      );
    }
  };

  const handleUpdateMessage = (message: string) => {
    setInvitesData({
      type: 'SET_MESSAGE',
      value: message || null,
    });
  };

  const setUserTeamsToInvites = () => {
    if (userTeams.length > 0) {
      const invitesUpdated = [];
      invitesData.invites.map((i) => {
        i.userTeams = userTeams;
        invitesUpdated.push(i);
      });
      updateInvites(invitesUpdated);
    }
  };

  const setTeamsPayloadToInvites = () => {
    if (userTeams.length > 0) {
      const invitesUpdated = [];
      invitesData.invites.map((i) => {
        i.teams = i.userTeams.reduce((acc, userTeam) => {
          acc.push({ team_id: userTeam.team.id, is_manager: userTeam.is_manager });
          return acc;
        }, []);
        invitesUpdated.push(i);
      });
      updateInvites(invitesUpdated);
    }
  };

  useEffect(() => {
    onStepChange(step);
  }, [step]);

  useEffect(() => {
    let newInvites = [];
    let newErrors = [];
    emails.map((email) => {
      const inviteds = newInvites?.map(({ email }) => email) || [];
      const withErrors = newErrors?.map(({ email }) => email) || [];
      newInvites =
        isEmail(email) && isAccountDomain(email) && !inviteds.includes(email)
          ? [
              ...newInvites,
              {
                email,
                is_manager: false,
                has_license: setupDefaultLicense(email, newInvites),
                is_account_owner: inviteAccOwner || false,
                userTeams: userTeams,
                teams: [],
              },
            ]
          : [...newInvites];
      newErrors =
        !isAccountDomain(email) && isEmail(email) && !withErrors.includes(email)
          ? [
              ...newErrors,
              {
                email: email,
                error: "Failed: The email address doesn't match with account valid domains",
              },
            ]
          : [...newErrors];
    });
    setErrors(newErrors);
    updateInvites(newInvites);
  }, [emails]);

  return (
    <>
      {isMobile && (
        <FeatherIcon
          icon={'arrow-left'}
          style={{ position: 'absolute', top: 20, left: 10 }}
          color={'#FFF'}
          onClick={handleBackPress}
        />
      )}
      {step === 0 && (
        <AdByemailContainer>
          <BodyTitle>{emailBodyTitle}</BodyTitle>
          <EmailInput title={emailBodySubtitle} emails={emails} setEmails={setEmails} />
          <LicenseAvailables>
            <span>{licensesAvailablesLabel}</span>
            <b>{infiniteLicenses ? '∞' : account.licenses_available}</b>
          </LicenseAvailables>
          {isDesktop && <TeamSelect teamsSelected={userTeams} onTeamChange={handleTeamChange} />}
        </AdByemailContainer>
      )}
      {step === 1 && errors.length > 0 && <RemoveUsers wrongEmails={errors} />}
      {step === 2 && (
        <AdjustUsersSettings
          hasTeam={Boolean(teams.length > 0)}
          invites={invitesData.invites}
          onInvitesUpdate={updateInvites}
          onInviteRemove={handleRemoveInvitation}
          onEdit={setinviteEditMobile}
          isEditing={inviteEditMobile}
          account={account}
          inviteAccOwner={inviteAccOwner}
        />
      )}
      {step === 3 && <AddMessage onMessageUpdate={handleUpdateMessage} />}
      {!inviteEditMobile && (
        <Footer
          disabled={
            (!(invitesData?.invites?.length > 0) &&
              !(emails.filter((email) => isEmail(email)).length > 0)) ||
            disabled
          }
          hasBackButton={step !== 0}
          steps={TOTAL_STEPS}
          stepActive={step}
          onNextPress={step === 1 ? handleRemoveUsers : handleNextPress}
          onBackPress={handleBackPress}
          nextLabel={step === 1 ? 'Remove Users' : step === 3 ? 'Send invites' : 'Next'}
        />
      )}
    </>
  );
};

export default AddEmails;
