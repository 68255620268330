import { AxiosResponse } from 'axios';
import { config } from 'config/config.local';
import { InvitesBatch } from 'pages/Users/components/InviteUsers/AddByEmails/invitesReducer';
import { BUSINESS_PYTHON } from './axios';

export const sendUserInvites = (invite: InvitesBatch): Promise<AxiosResponse<InvitesBatch>> => {
  return BUSINESS_PYTHON.post(`${config.routes.users.sendUserInvites}`, {
    ...invite,
  });
};

export const sendAccountOwnerInvites = (
  invite: InvitesBatch
): Promise<AxiosResponse<InvitesBatch>> => {
  return BUSINESS_PYTHON.post(`${config.routes.users.sendAccountOwnerInvites}`, {
    ...invite,
  });
};
