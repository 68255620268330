import { setupClients } from '@internetworkexpert/js-common';

const env = process.env.REACT_APP_ENV || 'development';
const {
  API,
  BUSINESS,
  CONTENT,
  STATISTICS,
  SUBSCRIPTIONS,
  UAA,
  BUSINESS_PYTHON,
  BUSINESS_PYTHON_ANONYMOUS,
  REPORTING,
  TEAMS_API,
  TEAMS_API_V2,
} = setupClients(env);

export {
  API,
  BUSINESS,
  CONTENT,
  STATISTICS,
  SUBSCRIPTIONS,
  UAA,
  BUSINESS_PYTHON,
  BUSINESS_PYTHON_ANONYMOUS,
  REPORTING,
  TEAMS_API,
  TEAMS_API_V2,
};
