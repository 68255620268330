import {
  UserSettingsResponse,
  removeAddonLicense,
  removeUsersInvites,
  resendInvites,
  setAddonLicense,
  setLicense,
  setRole,
  setTeams,
  setUserRoleService,
} from 'services/users';
import {
  SetAddonsSettings,
  SetUserBulkSettings,
  SetUserSettings,
  UserSettingsPayload,
} from './types';

export const setUserTeam = async ({ ids, team }): Promise<UserSettingsResponse | Error> => {
  try {
    let response;
    if (ids.length > 1) {
      const updates = [];
      for (let index = 0; index < ids.length; index++) {
        updates.push(setTeams({ user_invite_id: ids[index], teams: team[index] }));
      }
      Promise.all(updates).then((values) => {
        response = values[0];
      });
    } else {
      response = await setTeams({ user_invite_id: ids[0], teams: team[0] });
    }
    return response?.data;
  } catch (error) {
    return new Error(error);
  }
};

export const setUserTeams = async ({
  user_invite_id,
  teams,
}: UserSettingsPayload): Promise<UserSettingsResponse | Error> => {
  try {
    const response = await setTeams({ user_invite_id, teams });
    return response?.data;
  } catch (error) {
    return new Error(error);
  }
};

export const setUserLicense = async ({
  ids,
  license,
}: SetUserBulkSettings): Promise<UserSettingsResponse | Error> => {
  try {
    const response = await setLicense({ ids, license });
    return response?.data;
  } catch (error) {
    return new Error(error);
  }
};

export const assignAddonLicense = async ({
  assigned_to,
  addon,
}: SetAddonsSettings): Promise<any | Error> => {
  try {
    const response = await setAddonLicense({ assigned_to, addon });
    return response?.data;
  } catch (error) {
    return new Error(error);
  }
};

export const unassignAddonLicense = async ({
  license_addon_id,
}: {
  license_addon_id: string;
}): Promise<any | Error> => {
  try {
    const response = await removeAddonLicense({ license_addon_id });
    return response;
  } catch (error) {
    return new Error(error);
  }
};

export const resendUserInvites = async ({
  ids,
}: SetUserBulkSettings): Promise<UserSettingsResponse | Error> => {
  try {
    const response = await resendInvites({ ids });
    return response?.data;
  } catch (error) {
    return new Error(error);
  }
};

export const setUserRole = async ({
  id,
  role,
}: SetUserSettings): Promise<UserSettingsResponse | Error> => {
  try {
    const response = await setRole({ id, role });
    return response?.data;
  } catch (error) {
    return new Error(error);
  }
};

export const setTeamManager = async ({
  user_invite_id,
  teams,
}: UserSettingsPayload): Promise<UserSettingsResponse | Error> => {
  try {
    const response = await setUserRoleService({ user_invite_id, teams });
    return response?.data;
  } catch (error) {
    return new Error(error);
  }
};

export const removeUser = async ({
  ids,
}: SetUserBulkSettings): Promise<UserSettingsResponse | Error> => {
  try {
    const response = await removeUsersInvites({ ids });
    return response?.data;
  } catch (error) {
    return error as Error;
  }
};
