import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';
import styled from 'styled-components';
import useModal from 'hooks/useModal';
import { getAccountData, getSsoProvider, sendSsoReminder } from 'services/accounts';
import { getAccountRaxLabsAction } from 'contexts/rax/actions';
import { Account, SsoProvider } from 'contexts/accounts/types';
import AppReadyLoading from 'components/loadings/AppReadyLoading';
import AccountProfileHeader from './components/AccountProfileHeader';
import NoAccountOwner from './components/AccountOwnersCard/AccountNoAccOwner';
import {
  Card,
  CardsContainer,
  Columns,
  MainContent,
  Row,
} from 'components/layout/profile/profileContent/styles';
import AccountOnwersCard from './components/AccountOwnersCard/AccountOwnerCard';
import AccountDomains from './components/AccountDomainsCard/AccountDomainsCard';
import AccountLicensesCard from './components/AccountLicensesCard/AccountLicensesCard';
import AccountStatusCard from 'components/accountStatus/AccountStatusCard';
import { screenLg, screenMd, screenSm } from 'constants/styles/breakpoints';
import { SessionContext } from 'contexts/session';
import AccountUsersCard from './components/AccountUsersCard/AccountUsersCard';
import AccountTeamsCard from './components/AccountTeamsCard/AccountTeamsCard';
import AccountSsoCard from './components/AccountSsoCard/AccountSsoCard';
import AccountSonarStaffCard from './components/AccountsToggleCards/AccountSonarStaffCard/AccountSonarStaffCard';
import { ROLES } from 'constants/roles';
import AccountSsoStaffCard from './components/AccountsToggleCards/AccountSsoStaffCard/AccountSsoStaffCard';
import { handleSonarToggle, handleSsoToggle } from 'contexts/accounts/actions';
import Notification from 'components/notification';
import AccountNotes from './components/AccountNotes';
import ActivityLog from 'components/ActivityLog';
import { PLAN_ENTERPRISE, PLAN_ENTERPRISE_PLUS_LAB } from 'constants/plans_config';
import AccountAddonsCard from './components/AccountAddonsCard/AccountAddonsCard';
import AccountRax from './components/AccountRax';
import { removeRaxSlotAction } from 'contexts/rax/actions';

const CustomCardsContainer = styled(CardsContainer)<{ hasAddons?: boolean }>`
  .account-plan {
    order: 1
    padding: 0;
    min-width: 100%;
    ${screenMd} {
      flex-grow: ${({ hasAddons }) => (hasAddons ? '1' : '2')};
      min-width: ${({ hasAddons }) => (hasAddons ? '35%' : '40%')};
    }
  }
  .account-licenses {
    order: 2;
    min-width: 100%;
    min-height: 180px;
    ${screenMd} {
      min-width: ${({ hasAddons }) => (hasAddons ? '25%' : 'auto')};
    }
  }
  ${({ hasAddons }) =>
    hasAddons &&
    `
    .account-addons {
      order: 3;
      min-width: 100%;
      min-height: 180px;
      ${screenMd} {
        min-width: 25%;
      }`};
  }
  .account-domains {
    order: ${({ hasAddons }) => (hasAddons ? '3' : '4')};
    flex-grow: 1;
    ${screenSm} {
      min-width: 30%;
    }
    ${screenMd} {
      max-height: 100%;
    }
  }
  .account-owners {
    order: 6;
    padding: 0;
    ${screenMd} {
      order: 3;
      flex-grow: 1;
    }
    ${screenLg} {
      min-width: 40%;
    }
    &_empty {
      order: 7;
      min-width: 100%;
    }
  }
  .account-users {
    order: 4;
    justify-content: start;
    ${screenSm} {
      min-width: 33%;
      flex-grow: 1;
    }
  }
  .account-teams {
    order: 5;
    justify-content: start;
    flex-grow: 1;
    width: 100%;
    ${screenMd} {
      order: 6;
    }
    ${screenSm} {
      flex-grow: 1;
    }
  }
  .account-sso,
  .account-sonar {
    order: 7;
    justify-content: start;
    flex-grow: 1;
    ${screenLg} {
      order: 7;
      max-width: 30%;
    }
    ${screenMd} {
      order: 7;
    }
    ${screenSm} {
      flex-grow: 1;
    }
  }
  .account-sonar {
    order: 8;
  }
`;

const AccountProfile = (): JSX.Element => {
  const isRaxEnvEnabled = process.env.REACT_APP_RAX_RENTALS_ENABLED === 'True';
  const { id } = useParams<{ id: string }>();
  const [accountData, setAccountData] = useState<Account>(null);
  const [ssoProviderData, setSsoProviderData] = useState<SsoProvider>(null);
  const [raxData, setRaxData] = useState(null);
  const { modal, setModal } = useModal();
  const { userRoleIds, isStaff } = useContext(SessionContext);
  const [fetching, setFetching] = useState(false);
  const history = useHistory();
  const isAccOwner = userRoleIds.includes(ROLES.ACCOUNT_OWNER);

  const getProfileAccountData = async () => {
    setFetching(true);
    try {
      const response = await getAccountData({ id });
      setFetching(false);
      setAccountData(response.data);
      if (isAccOwner && response.data.sso_configured) await getSsoProviderData();
      if (isStaff && isRaxEnvEnabled && response.data.rax_enabled) await getRaxData();
    } catch (error) {
      setFetching(false);
      Notification({ text: 'Error retrieving Account information', type: 'error' });
    }
  };

  const getSsoProviderData = async () => {
    try {
      const response = await getSsoProvider(id);
      setSsoProviderData(response.data);
    } catch (error) {
      Notification({ text: 'Error retrieving SSO provider information', type: 'error' });
    }
  };

  const getRaxData = async () => {
    try {
      const params = { deep_details: true, account: id, status: 'available' };
      const response = await getAccountRaxLabsAction(params);
      setRaxData(response.data.results);
      return response;
    } catch (error) {
      Notification({ text: 'Error retrieving Rax information', type: 'error' });
    }
  };

  const handleModal = (newModal: JSX.Element) => {
    setModal(newModal);
  };

  const ssoToggled = async (value: boolean) => {
    setFetching(true);
    const response = await handleSsoToggle(value, id);
    if (response instanceof Error) {
      setFetching(false);
      const notificationMsg = value ? `Couldn't enable SSO` : `Couldn't disable SSO`;
      Notification({ text: notificationMsg, type: 'error' });
    } else {
      setFetching(false);
      const notificationMsg = value ? 'SSO successfully enabled' : 'SSO successfully disabled';
      Notification({ text: notificationMsg, type: 'success' });
      getProfileAccountData();
    }
  };

  const sonarToggled = async (value: boolean) => {
    setFetching(true);
    const response = await handleSonarToggle(value, id);
    if (response instanceof Error) {
      setFetching(false);
      const notificationMsg = value ? `Couldn't enable Sonar` : `Couldn't disable Sonar`;
      Notification({ text: notificationMsg, type: 'error' });
    } else {
      setFetching(false);
      const notificationMsg = value ? 'Sonar successfully enabled' : 'Sonar successfully disabled';
      Notification({ text: notificationMsg, type: 'success' });
      getProfileAccountData();
    }
  };

  const handleSendReminder = async () => {
    setFetching(true);
    try {
      const response = await sendSsoReminder(id);
      setFetching(false);
      response.status === 200 &&
        Notification({ text: 'Reminder successfully sent to accont owners', type: 'success' });
    } catch (error) {
      setFetching(false);
      Notification({ text: `Couldn't sent reminders to account owners`, type: 'error' });
    }
  };

  const onRemoveRaxSlot = async (id: string) => {
    setFetching(true);
    await removeRaxSlotAction(id);
    await getRaxData();
    setFetching(false);
  };

  const goToAccountUsersPage = () => {
    history.push(`/account/${id}/users`);
  };

  useEffect(() => {
    getProfileAccountData();
  }, [isRaxEnvEnabled]);

  if (!accountData) {
    return <AppReadyLoading />;
  }
  return (
    <>
      <MobileView>
        <AccountProfileHeader
          account={accountData}
          handleModal={handleModal}
          refreshData={getProfileAccountData}
        />
      </MobileView>
      <MainContent fetching={fetching}>
        <BrowserView>
          <AccountProfileHeader
            account={accountData}
            handleModal={handleModal}
            refreshData={getProfileAccountData}
          />
        </BrowserView>
        <CustomCardsContainer hasAddons={accountData.account_addons?.count > 0}>
          <Row>
            <Columns className="account-plan">
              <AccountStatusCard account={accountData} />
            </Columns>
            <Columns className="account-licenses">
              <AccountLicensesCard licensesData={accountData.licenses_details} />
            </Columns>
            {accountData.account_addons?.count > 0 && (
              <Columns className="account-addons">
                <AccountAddonsCard accountAddon={accountData.account_addons?.addons?.[0]} />
              </Columns>
            )}
            <Columns className="account-domains">
              <Card>
                <AccountDomains
                  account={accountData}
                  handleModal={handleModal}
                  refreshData={getProfileAccountData}
                />
              </Card>
            </Columns>

            {(accountData.account_owners.length > 0 ||
              accountData.account_owners_pending.length > 0) && (
              <Columns className="account-owners">
                <AccountOnwersCard
                  users={accountData.account_owners}
                  pendings={accountData.account_owners_pending}
                  account={accountData}
                  handleModal={handleModal}
                  refreshData={getProfileAccountData}
                />
              </Columns>
            )}

            <Columns className="account-users">
              <AccountUsersCard
                usersData={accountData.account_users}
                pendings={accountData.account_owners_pending}
                showUsersPage={goToAccountUsersPage}
              />
            </Columns>
            <Columns className="account-teams">
              <AccountTeamsCard
                teamsData={accountData.account_teams}
                usersData={accountData.account_users}
              />
            </Columns>
            <Columns className="account-sso">
              {isAccOwner ? (
                <AccountSsoCard
                  account={accountData}
                  ssoProvider={ssoProviderData}
                  refreshData={getProfileAccountData}
                />
              ) : (
                <AccountSsoStaffCard
                  account={accountData}
                  ssoToggled={ssoToggled}
                  ssoAccownersReminder={handleSendReminder}
                />
              )}
            </Columns>
            <Columns className="account-sonar">
              {isStaff &&
                (accountData.pricing_plan === PLAN_ENTERPRISE ||
                  accountData.pricing_plan === PLAN_ENTERPRISE_PLUS_LAB) && (
                  <AccountSonarStaffCard account={accountData} sonarToggled={sonarToggled} />
                )}
            </Columns>

            {!(accountData.contacts.length > 0) &&
              !(accountData.account_owners_pending.length > 0) && (
                <Columns className="account-owners_empty">
                  <NoAccountOwner
                    account={accountData}
                    handleModal={handleModal}
                    refreshData={getProfileAccountData}
                  />
                </Columns>
              )}
          </Row>
          {isStaff && (
            <>
              {isRaxEnvEnabled && !!raxData?.length && (
                <div className="mt-5">
                  <AccountRax
                    products={raxData}
                    showBookedFirst={true}
                    onRemoveRaxSlot={onRemoveRaxSlot}
                  />
                </div>
              )}
              <div className="mt-5">
                <AccountNotes account={accountData} />
              </div>
              <div className="mt-5">
                <ActivityLog />
              </div>
            </>
          )}
        </CustomCardsContainer>
      </MainContent>
      {modal}
    </>
  );
};

export default AccountProfile;
