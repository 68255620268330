import { colors } from 'constants/styles/colors';
import styled from 'styled-components';

export const StatCardContainer = styled.div`
  &&& {
    flex: initial;
    max-width: 100%;
    min-width: 160px;
    width: auto;
    padding: 15px 27px;
    cursor: pointer;
  }
  &:hover {
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.200691);
  }
  &:active {
    border: 1px solid ${colors.palette.Business_DataVizLilacLighter};
  }
`;
