import { useState, FormEvent, FC } from 'react';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';

interface TeamUserSearchFilterProps {
  onSearchSubmit(value: string): void;
}

const SearchFilter: FC<TeamUserSearchFilterProps> = ({ onSearchSubmit }) => {
  const [value, setValue] = useState(null);

  const onInput = (e: FormEvent<HTMLInputElement>): void => {
    setValue(e.currentTarget.value);
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSearchSubmit(value);
  };

  return (
    <form
      className="col input-group input-group-solid align-items-center ps-4 rounded ml-20"
      onSubmit={onSubmit}
      style={{ backgroundColor: '#F2F2F7' }}
    >
      <SearchIcon />
      <input
        type="text"
        className="form-control"
        aria-label="search user"
        aria-describedby="basic-search"
        onChange={onInput}
        value={value}
      />
    </form>
  );
};

export default SearchFilter;
