import { formatDistanceToNowStrict } from 'date-fns';
import { colors } from 'constants/styles/colors';

export const USERS_TYPE = {
  LABEL: 'users with activity',
  VALUE: 'USERS',
};
export const ACTIVITIES_TYPE = {
  LABEL: 'activities',
  VALUE: 'ACTIVITIES',
};
export const VIDEOS_TYPE = {
  LABEL: 'video',
  VALUE: 'VIDEOS',
};
export const VIDEOS_OVERALL_TYPE = {
  LABEL: 'videos',
  VALUE: 'VIDEOS_OVERALL',
};
export const QUIZZES_TYPE = {
  LABEL: 'quizzes',
  VALUE: 'QUIZZES',
};
export const SLIDES_TYPE = {
  LABEL: 'slides',
  VALUE: 'SLIDES',
};
export const LABS_TYPE = {
  LABEL: 'hands-on labs',
  VALUE: 'LABS',
};

export const CHART_TYPES = [USERS_TYPE, ACTIVITIES_TYPE, VIDEOS_TYPE, QUIZZES_TYPE, LABS_TYPE];

export const CHART_TYPES_LABELS = {
  VIDEOS: {
    SINGULAR: 'video',
    PLURAL: 'videos',
  },
  QUIZZES: {
    SINGULAR: 'quizz',
    PLURAL: 'quizzes',
  },
  ACTIVITIES: {
    SINGULAR: 'activity',
    PLURAL: 'activities',
  },
  LABS: {
    SINGULAR: 'lab',
    PLURAL: 'labs',
  },
  USERS: {
    SINGULAR: 'user',
    PLURAL: 'users',
  },
};
export const STARTED_STATUS = {
  LABEL: 'Started',
  VALUE: 'STARTED',
};
export const FINISHED_STATUS = {
  LABEL: 'Finished',
  VALUE: 'FINISHED',
};
export const WATCHED_STATUS = {
  LABEL: 'Time Watched',
  VALUE: 'WATCHED',
};
export const PASSED_STATUS = {
  LABEL: 'Passed',
  VALUE: 'PASSED',
};
export const OVERALL_STATUS = {
  LABEL: 'Overall',
  VALUE: 'OVERALL',
};
export const LABS_PERCENTAGE = {
  LABEL: 'Hands-on labs',
  VALUE: 'LABS',
};
export const VIDEOS_PERCENTAGE = {
  LABEL: 'Videos',
  VALUE: 'VIDEOS',
};
export const QUIZZES_PERCENTAGE = {
  LABEL: 'Quizzes',
  VALUE: 'QUIZZES',
};

export const LICENSED_STATUS = {
  LABEL: 'Licensed',
  VALUE: 'LICENSED',
};

export const TOTAL_STATUS = {
  LABEL: 'Total',
  VALUE: 'TOTAL',
};

export const STANDARD_STATUS = {
  LABEL: 'Standard',
  VALUE: 'STANDARD',
};

export const SKILL_DIVE_STATUS = {
  LABEL: 'Skill Dive',
  VALUE: 'SKILL_DIVE',
};

const VIDEO_STARTED_ACTIONS = 'video.media.requested';
const QUIZ_STARTED_ACTIONS = 'quiz.attempt.created';
const LAB_STARTED_ACTIONS = 'lab.status.started';

export const ACTIVITIES_COUNTER = {
  TYPE: ACTIVITIES_TYPE.VALUE,
  STARTED: {
    ACTIONS: 'video.media.requested,quiz.attempt.created,lab.status.started,iframe.media.requested',
    STATUS: STARTED_STATUS.VALUE,
  },
  FINISHED: {
    ACTIONS:
      'video.status.finished,quiz.attempt.finished,lab.status.finished,iframe.status.finished',
    STATUS: FINISHED_STATUS.VALUE,
  },
  OVERALL: {
    ACTIONS: 'video.media.requested,quiz.attempt.created,lab.status.started',
    STATUS: OVERALL_STATUS.VALUE,
  },
  LABS: {
    ACTIONS: LAB_STARTED_ACTIONS,
    STATUS: LABS_PERCENTAGE.VALUE,
  },
  VIDEOS: {
    ACTIONS: VIDEO_STARTED_ACTIONS,
    STATUS: VIDEOS_PERCENTAGE.VALUE,
  },
  QUIZZES: {
    ACTIONS: QUIZ_STARTED_ACTIONS,
    STATUS: QUIZZES_PERCENTAGE.VALUE,
  },
};
export const VIDEOS_COUNTER = {
  TYPE: VIDEOS_TYPE.VALUE,
  ICON: 'videocam',
  STARTED: {
    LABEL: 'Videos Started',
    ACTIONS: VIDEO_STARTED_ACTIONS,
    STATUS: STARTED_STATUS.VALUE,
  },
  FINISHED: {
    LABEL: 'Videos Finished',
    ACTIONS: 'video.status.finished',
    STATUS: FINISHED_STATUS.VALUE,
  },
  WATCHED: {
    LABEL: 'Time Watched',
    EXTERNAL_ACTION: 'videos_watched_time_counter',
    STATUS: WATCHED_STATUS.VALUE,
  },
  OVERALL: {
    LABEL: 'Videos Overall',
    ACTIONS: 'video.media.requested,video.status.finished',
    STATUS: OVERALL_STATUS.VALUE,
  },
  TOP_WATCHED: {
    LABEL: 'Time Watched',
    EXTERNAL_ACTION: 'videos_watched_time_users',
    STATUS: WATCHED_STATUS.VALUE,
  },
};

export const VIDEOS_OVERALL_COUNTER = {
  TYPE: VIDEOS_OVERALL_TYPE.VALUE,
  ICON: 'videocam',
  STARTED: {
    LABEL: 'Videos Started',
    ACTIONS: VIDEO_STARTED_ACTIONS,
    STATUS: STARTED_STATUS.VALUE,
  },
  FINISHED: {
    LABEL: 'Videos Finished',
    ACTIONS: 'video.status.finished',
    STATUS: FINISHED_STATUS.VALUE,
  },
  WATCHED: {
    LABEL: 'Time Watched',
    EXTERNAL_ACTION: 'videos_watched_time_counter',
    STATUS: WATCHED_STATUS.VALUE,
  },
  OVERALL: {
    LABEL: 'Videos Overall',
    EXTERNAL_ACTION: 'videos_watched_time_series',
    STATUS: OVERALL_STATUS.VALUE,
  },
  TOP_WATCHED: {
    LABEL: 'Time Watched',
    EXTERNAL_ACTION: 'videos_watched_time_users',
    STATUS: WATCHED_STATUS.VALUE,
  },
};
export const QUIZZES_COUNTER = {
  TYPE: QUIZZES_TYPE.VALUE,
  ICON: 'history_edu',
  STARTED: {
    LABEL: 'Quizzes Started',
    ACTIONS: QUIZ_STARTED_ACTIONS,
    STATUS: STARTED_STATUS.VALUE,
  },
  FINISHED: {
    LABEL: 'Quizzes Finished',
    ACTIONS: 'quiz.attempt.finished',
    STATUS: FINISHED_STATUS.VALUE,
  },
  PASSED: {
    LABEL: 'Passed',
    ACTIONS: 'quiz.attempt.finished',
    METADATA: 'passed:True',
    STATUS: PASSED_STATUS.VALUE,
  },
  OVERALL: {
    ACTIONS: 'quiz.attempt.created,quiz.attempt.finished',
    LABEL: 'Quizzes Overall',
    STATUS: OVERALL_STATUS.VALUE,
  },
};
export const SLIDES_COUNTER = {
  TYPE: SLIDES_TYPE.VALUE,
  ICON: 'visibility',
  STARTED: {
    LABEL: 'Slides Started',
    ACTIONS: 'iframe.media.requested',
    STATUS: STARTED_STATUS.VALUE,
  },
  FINISHED: {
    LABEL: 'Slides Finished',
    ACTIONS: 'iframe.status.finished',
    STATUS: FINISHED_STATUS.VALUE,
  },
  OVERALL: {
    LABEL: 'Slides Overall',
    ACTIONS: 'iframe.media.requested,iframe.status.finished',
    STATUS: OVERALL_STATUS.VALUE,
  },
};
export const LABS_COUNTER = {
  TYPE: LABS_TYPE.VALUE,
  ICON: 'shield',
  STARTED: {
    LABEL: 'Labs Started',
    ACTIONS: LAB_STARTED_ACTIONS,
    STATUS: STARTED_STATUS.VALUE,
  },
  FINISHED: {
    LABEL: 'Labs Finished',
    ACTIONS: 'lab.status.finished',
    STATUS: FINISHED_STATUS.VALUE,
  },
  OVERALL: {
    LABEL: 'Labs Overall',
    ACTIONS: 'lab.status.started,lab.status.finished',
    STATUS: OVERALL_STATUS.VALUE,
  },
};

export const USERS_COUNTER = {
  TYPE: USERS_TYPE.VALUE,
  ICON: 'people',
  TOTAL: {
    ACTIONS: null,
    STATUS: 'Total users',
  },
  LICENCED: {
    ACTIONS: null,
    STATUS: 'Licensed Users',
  },
  PERCENTAGE: {
    ACTIONS: null,
    STATUS: 'licensed users with activity',
  },
};

export const QUERIES_BY_TYPE = {
  activities: ACTIVITIES_COUNTER,
  videos: VIDEOS_COUNTER,
  videos_overall: VIDEOS_OVERALL_COUNTER,
  quizzes: QUIZZES_COUNTER,
  slides: SLIDES_COUNTER,
  labs: LABS_COUNTER,
  users: USERS_COUNTER,
};

export const USERS_ENTITY = {
  LABEL: 'Users',
  VALUE: 'users',
};
export const TEAMS_ENTITY = {
  LABEL: 'Teams',
  VALUE: 'teams',
};
export const COURSES_ENTITY = {
  LABEL: 'Courses',
  VALUE: 'courses',
};
export const STATISTICS_ENTITIES = {
  [USERS_TYPE.VALUE]: [USERS_ENTITY],
  [ACTIVITIES_TYPE.VALUE]: [USERS_ENTITY, TEAMS_ENTITY],
  [LABS_TYPE.VALUE]: [USERS_ENTITY, TEAMS_ENTITY],
  DEFAULT: [USERS_ENTITY, TEAMS_ENTITY, COURSES_ENTITY],
};

export const ENTITY_COLUMN_ACTIONS = {
  [USERS_ENTITY.VALUE]: [
    {
      key: 'column1',
      actions: 'STARTED',
    },
    {
      key: 'column2',
      actions: 'FINISHED',
    },
  ],
  [TEAMS_ENTITY.VALUE]: [
    {
      key: 'column1',
      actions: 'STARTED',
    },
    {
      key: 'column2',
      actions: 'FINISHED',
    },
  ],
  [COURSES_ENTITY.VALUE]: [
    {
      key: 'column1',
      actions: 'STARTED',
    },
    {
      key: 'column2',
      actions: 'FINISHED',
    },
  ],
};
export const ENTITIES_ORDER_BY = {
  [USERS_ENTITY.VALUE]: 'first_name',
  [TEAMS_ENTITY.VALUE]: 'name',
  [COURSES_ENTITY.VALUE]: 'name',
};
export const COLOR_CHART_STATUSES = {
  [OVERALL_STATUS.VALUE]: {
    fill: colors.dataVisualization.pink.base,
    stroke: colors.dataVisualization.pink.dark,
    counter: colors.dataVisualization.pink.base,
  },
  [FINISHED_STATUS.VALUE]: {
    fill: '#4A857F',
    stroke: '#4A857F',
  },
  [PASSED_STATUS.VALUE]: {
    fill: '#F19E5B',
    stroke: '#F19E5B',
  },
  [STARTED_STATUS.VALUE]: {
    fill: '#C1D8F8',
    stroke: '#3E6496',
  },
  [WATCHED_STATUS.VALUE]: {
    fill: '#C1D8F8',
    stroke: '#3E6496',
  },
  [LABS_PERCENTAGE.VALUE]: {
    fill: colors.dataVisualization.purple.base,
    stroke: colors.dataVisualization.purple.dark,
    counter: colors.dataVisualization.purple.base,
  },
  [VIDEOS_PERCENTAGE.VALUE]: {
    fill: colors.dataVisualization.blue.base,
    stroke: colors.dataVisualization.blue.dark,
    counter: colors.dataVisualization.blue.base,
  },
  [QUIZZES_PERCENTAGE.VALUE]: {
    fill: colors.dataVisualization.orange.base,
    stroke: colors.dataVisualization.orange.dark,
    counter: colors.dataVisualization.orange.base,
  },
  [LICENSED_STATUS.VALUE]: {
    fill: '#D3C9FA',
    stroke: '#A793F6',
  },
  [TOTAL_STATUS.VALUE]: {
    fill: '#3E6496',
    stroke: '#3E6496',
  },
  [STANDARD_STATUS.VALUE]: {
    fill: '#A793F6',
    stroke: '#A793F6',
  },
  [SKILL_DIVE_STATUS.VALUE]: {
    fill: '#F19E5B',
    stroke: '#F19E5B',
  },
};
export const ENTITIES_COLUMNS = {
  [USERS_TYPE.VALUE]: {
    [USERS_ENTITY.VALUE]: [
      {
        name: 'users-column0',
        label: 'User',
        tooltip: `Users with activity in the selected timeframe, whether they have a license or not`,
        value: (data: any): string => {
          return `${data?.user?.first_name} ${data?.user?.last_name}`;
        },
        scndValue: (data: any): string => {
          return `${data?.user?.email}`;
        },
        id: (data: any): string => {
          return `${data?.user?.business_id}`;
        },
      },
      {
        name: 'users-column1',
        tooltip: `User's latest interaction with any activity on the platform (UTC)`,
        label: 'Last activity date',
        value: (data: any): string => {
          return data?.last_activity_date
            ? formatDistanceToNowStrict(new Date(data?.last_activity_date), { addSuffix: true })
            : 'N/A';
        },
      },
      {
        name: 'users-column2',
        label: 'Last activity',
        tooltip: 'The most recent Learning Path or Course the user interacted with',
        type: (data: any): string => {
          return data?.content_type;
        },
        value: (data: any): string => {
          return data?.content_name;
        },
      },
      {
        name: 'users-column3',
        label: 'Started activities',
        tooltip:
          'Total number of initiated activities per user, including videos, hands-on labs, and quizzes within the selected timeframe',
        value: (data: any): string => {
          return data?.started_activities;
        },
      },
    ],
    [TEAMS_ENTITY.VALUE]: [
      {
        name: 'users-column0',
        label: 'User',
        value: (data: any): string => {
          return data?.user ? `${data?.user.first_name} ${data?.user?.last_name}` : null;
        },
        scndValue: (data: any): string => {
          return `${data?.user?.email}`;
        },
        id: (data: any): string => {
          return `${data?.user?.business_id}`;
        },
      },
      {
        name: 'users-column1',
        label: 'Last Activity Date',
        value: (data: any): string => {
          return data?.last_activity_date
            ? formatDistanceToNowStrict(new Date(data?.last_activity_date), { addSuffix: true })
            : 'N/A';
        },
      },
    ],
    [COURSES_ENTITY.VALUE]: [
      {
        name: 'users-column0',
        label: 'User',
        value: (data: any): string => {
          return `${data?.user?.first_name} ${data?.user?.last_name}`;
        },
        scndValue: (data: any): string => {
          return `${data?.user?.email}`;
        },
        id: (data: any): string => {
          return `${data?.user?.business_id}`;
        },
      },
      {
        name: 'users-column1',
        label: 'Last Activity Date',
        value: (data: any): string => {
          return data?.last_activity_date
            ? formatDistanceToNowStrict(new Date(data?.last_activity_date), { addSuffix: true })
            : 'N/A';
        },
      },
    ],
  },
  [LABS_TYPE.VALUE]: {
    [USERS_ENTITY.VALUE]: [
      {
        name: 'users-column0',
        label: 'User',
        value: (data: any): string => {
          return `${data.user.first_name} ${data.user.last_name}`;
        },
        scndValue: (data: any): string => {
          return `${data.user.email}`;
        },
        id: (data: any): string => {
          return `${data.user.business_id}`;
        },
      },
      {
        name: 'users-column1',
        label: 'Started labs',
        tooltip:
          'Total number of labs initiated within the team (including standard labs and Skill Dive labs)',
        value: (data: any): string => {
          return data.standard_count + data.skill_dive_count;
        },
      },
      {
        name: 'users-column2',
        label: 'Standard labs',
        tooltip: 'Total number of standard labs initiated within the team',
        value: (data: any): string => {
          return data.standard_count;
        },
      },
      {
        name: 'users-column3',
        label: 'Skill Dive labs',
        tooltip: 'Total number of Skill Dive labs initiated within the team',
        value: (data: any): string => {
          return data.skill_dive_count;
        },
      },
      {
        name: 'users-column4',
        label: 'Active time on standard labs',
        tooltip:
          'Total time spent in hands-on standard labs, expressed in hh:mm:ss, within the team',
        value: (data: any): string => {
          return data.standard_active_time;
        },
      },
      {
        name: 'users-column5',
        label: 'Active time on Skill Dive',
        tooltip: 'Total time spent on Skill Dive, expressed in hh:mm:ss, within the team',
        value: (data: any): string => {
          return data.skill_dive_active_time;
        },
      },
    ],
    [TEAMS_ENTITY.VALUE]: [
      {
        name: 'teams-column0',
        label: 'Team',
        value: (data: any): string => {
          return `${data.team.name}`;
        },
      },
      {
        name: 'teams-column1',
        label: 'Started Labs',
        value: (data: any): string => {
          return data.column1;
        },
      },
      {
        name: 'teams-column2',
        label: 'Finished Labs',
        value: (data: any): string => {
          return data.column2;
        },
      },
    ],
  },
  [VIDEOS_TYPE.VALUE]: {
    [USERS_ENTITY.VALUE]: [
      {
        name: 'users-column0',
        label: 'User',
        value: (data: any): string => {
          return `${data.user.first_name} ${data.user.last_name}`;
        },
        scndValue: (data: any): string => {
          return `${data.user.email}`;
        },
        id: (data: any): string => {
          return `${data.user.business_id}`;
        },
      },
      {
        name: 'users-column1',
        label: 'Started Videos',
        tooltip: 'Total number of times videos were initiated within the account',
        value: (data: any): string => {
          return data.column1;
        },
      },
      {
        name: 'users-column2',
        label: 'Finished Videos',
        tooltip: 'Total number of times videos were finished within the account',
        value: (data: any): string => {
          return data.column2;
        },
      },
      {
        name: 'users-column3',
        label: 'Time Watched',
        tooltip: 'Total watched time videos within the account',
        value: (data: { column_external_action: string }): string => {
          return `${data.column_external_action}m`;
        },
      },
    ],
    [TEAMS_ENTITY.VALUE]: [
      {
        name: 'teams-column0',
        label: 'Team',
        value: (data: any): string => {
          return `${data.team.name}`;
        },
      },
      {
        name: 'teams-column1',
        label: 'Started Videos',
        value: (data: any): string => {
          return data.column1;
        },
      },
      {
        name: 'teams-column2',
        label: 'Finished Videos',
        value: (data: any): string => {
          return data.column2;
        },
      },
      {
        name: 'teams-column3',
        label: 'Time Watched',
        value: (data: { column_external_action: string }): string => {
          return `${data.column_external_action}m`;
        },
      },
    ],
    [COURSES_ENTITY.VALUE]: [
      {
        name: 'courses-column0',
        label: 'Course',
        value: (data: { course: { name: string } }): string => {
          return `${data.course.name}`;
        },
      },
      {
        name: 'courses-column1',
        label: 'Started Videos',
        value: (data: any): string => {
          return data.column1;
        },
      },
      {
        name: 'courses-column2',
        label: 'Finished Videos',
        value: (data: any): string => {
          return data.column2;
        },
      },
      {
        name: 'courses-column3',
        label: 'Time Watched',
        value: (data: { column_external_action: string }): string => {
          return `${data.column_external_action}m`;
        },
      },
    ],
  },
  [VIDEOS_OVERALL_TYPE.VALUE]: {
    [USERS_ENTITY.VALUE]: [
      {
        name: 'users-column0',
        label: 'User',
        value: (data: any): string => {
          return `${data.user.first_name} ${data.user.last_name}`;
        },
        scndValue: (data: any): string => {
          return `${data.user.email}`;
        },
        id: (data: any): string => {
          return `${data.user.business_id}`;
        },
      },
      {
        name: 'users-column1',
        label: 'Started Videos',
        value: (data: any): string => {
          return data.column1;
        },
      },
      {
        name: 'users-column2',
        label: 'Finished Videos',
        value: (data: any): string => {
          return data.column2;
        },
      },
      {
        name: 'users-column3',
        label: 'Time Watched',
        value: (data: { column_external_action: string }): string => {
          return `${data.column_external_action}m`;
        },
      },
    ],
    [TEAMS_ENTITY.VALUE]: [
      {
        name: 'teams-column0',
        label: 'Team',
        value: (data: any): string => {
          return `${data.team.name}`;
        },
      },
      {
        name: 'teams-column1',
        label: 'Started Videos',
        value: (data: any): string => {
          return data.column1;
        },
      },
      {
        name: 'teams-column2',
        label: 'Finished Videos',
        value: (data: any): string => {
          return data.column2;
        },
      },
      {
        name: 'teams-column3',
        label: 'Time Watched',
        value: (data: { column_external_action: string }): string => {
          return `${data.column_external_action}m`;
        },
      },
    ],
    [COURSES_ENTITY.VALUE]: [
      {
        name: 'courses-column0',
        label: 'Course',
        value: (data: { course: { name: string } }): string => {
          return `${data.course.name}`;
        },
      },
      {
        name: 'courses-column1',
        label: 'Started Videos',
        value: (data: any): string => {
          return data.column1;
        },
      },
      {
        name: 'courses-column2',
        label: 'Finished Videos',
        value: (data: any): string => {
          return data.column2;
        },
      },
      {
        name: 'courses-column3',
        label: 'Time Watched',
        value: (data: { column_external_action: string }): string => {
          return `${data.column_external_action}m`;
        },
      },
    ],
  },
  [QUIZZES_TYPE.VALUE]: {
    [USERS_ENTITY.VALUE]: [
      {
        name: 'users-column0',
        label: 'User',
        value: (data: any): string => {
          return `${data.user.first_name} ${data.user.last_name}`;
        },
        scndValue: (data: any): string => {
          return `${data.user.email}`;
        },
        id: (data: any): string => {
          return `${data.user.business_id}`;
        },
      },
      {
        name: 'users-column1',
        label: 'Started Quizzes',
        tooltip: 'Total number of initiated quizzes per user',
        value: (data: { started_quizzes: string }): string => {
          return data.started_quizzes;
        },
      },
      {
        name: 'users-column2',
        label: 'Finished Quizzes',
        tooltip: 'Total number of finished quizzes by the user',
        value: (data: { finished_quizzes: string }): string => {
          return data.finished_quizzes;
        },
      },
      {
        name: 'users-column3',
        label: 'Passed quizzes',
        tooltip: 'Total number of quizzes successfully completed by the user',
        value: (data: { passed_quizzes: string }): string => {
          return data.passed_quizzes;
        },
      },
      {
        name: 'users-column4',
        label: '% Passed quizzes',
        tooltip: 'Percentage of quizzes successfully completed within the account',
        value: (data: { passed_quizzes_percentage: string }): string => {
          return `${data.passed_quizzes_percentage}%`;
        },
      },
      {
        name: 'users-column5',
        label: 'Average quizzes score',
        tooltip: 'Average performance across all quizzes that have been finished',
        value: (data: { average_score: string }): string => {
          return `${data.average_score}%`;
        },
      },
    ],
    [TEAMS_ENTITY.VALUE]: [
      {
        name: 'teams-column0',
        label: 'Team',
        value: (data: any): string => {
          return `${data.team.name}`;
        },
      },
      {
        name: 'teams-column1',
        label: 'Started Quizzes',
        value: (data: any): string => {
          return data.column1;
        },
      },
      {
        name: 'teams-column2',
        label: 'Finished Quizzes',
        value: (data: any): string => {
          return data.column2;
        },
      },
      {
        name: 'users-column3',
        label: 'Quizzes Passed',
        value: (data: { column_metadata: string }): string => {
          return data.column_metadata;
        },
      },
    ],
    [COURSES_ENTITY.VALUE]: [
      {
        name: 'courses-column0',
        label: 'Course',
        value: (data: { course: { name: string } }): string => {
          return `${data.course.name}`;
        },
      },
      {
        name: 'courses-column1',
        label: 'Started Quizzes',
        value: (data: any): string => {
          return data.column1;
        },
      },
      {
        name: 'courses-column2',
        label: 'Finished Quizzes',
        value: (data: any): string => {
          return data.column2;
        },
      },
      {
        name: 'courses-column3',
        label: 'Quizzes Passed',
        value: (data: { column_metadata: string }): string => {
          return data.column_metadata;
        },
      },
    ],
  },
  [ACTIVITIES_TYPE.VALUE]: {
    [USERS_ENTITY.VALUE]: [
      {
        name: 'users-column0',
        label: 'User',
        value: (data: any): string => {
          return `${data.user.first_name} ${data.user.last_name}`;
        },
        scndValue: (data: any): string => {
          return `${data.user.email}`;
        },
        id: (data: any): string => {
          return `${data.user.business_id}`;
        },
      },
      {
        name: 'users-column1',
        label: 'Total started activities',
        tooltip:
          'Total number of initiated activities per user including videos, hands-on labs, and quizzes',
        value: (data: any): string => {
          return data.total_started_activities;
        },
      },
      {
        name: 'users-column2',
        label: 'Started hands-on labs',
        tooltip: 'Total number of initiated hands-on labs per user',
        value: (data: any): string => {
          return data.started_labs;
        },
      },
      {
        name: 'users-column3',
        label: 'Started videos',
        tooltip: 'Total number of initiated videos per user',
        value: (data: any): string => {
          return data.started_videos;
        },
      },
      {
        name: 'users-column4',
        label: 'Started quizzes',
        tooltip: 'Total number of initiated quizzes per user',
        value: (data: any): string => {
          return data.started_quizzes;
        },
      },
    ],
    [TEAMS_ENTITY.VALUE]: [
      {
        name: 'teams-column0',
        label: 'Team',
        value: (data: any): string => {
          return `${data.team.name}`;
        },
      },
      {
        name: 'teams-column1',
        label: 'Started Activities',
        value: (data: any): string => {
          return data.column1;
        },
      },
      {
        name: 'teams-column2',
        label: 'Finished Activities',
        value: (data: any): string => {
          return data.column2;
        },
      },
    ],
    [COURSES_ENTITY.VALUE]: [
      {
        name: 'courses-column0',
        label: 'Course',
        value: (data: { course: { name: string } }): string => {
          return `${data.course.name}`;
        },
      },
      {
        name: 'courses-column1',
        label: 'Started Activities',
        value: (data: any): string => {
          return data.column1;
        },
      },
      {
        name: 'courses-column2',
        label: 'Finished Activities',
        value: (data: any): string => {
          return data.column2;
        },
      },
    ],
  },
};
