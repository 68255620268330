import { useEffect, useMemo, useState } from 'react';
import { ACCOUNT_COLORS } from 'constants/rax';
import Calendar from 'components/calendar/Calendar';
import { getRaxBookingsAction } from 'contexts/rax/actions';
import Notification from 'components/notification';
import { Spinner } from 'components/loadings';
import { getRaxCalendarAvailabilityAction } from 'contexts/rax/actions';
import { availabilityDatesParamsV2, dateRangeHelper } from '../utils';
import { BOOKING_STATUS } from '../utils';
import { IBooking, IDatesParams } from 'contexts/rax/types';
import { format } from 'date-fns';

const CalendarContainer = ({
  bookings = [],
  isInteractable,
  borders = true,
  flat = false,
  eventsWithUsername = false,
  raxLabId = null,
  accountId = null,
  fetchData = false,
  fetchAvailability = true,
  isModal = false,
  isLarge = false,
  onSelectDates,
}: {
  bookings?: Array<IBooking>;
  isInteractable: boolean;
  borders?: boolean;
  flat?: boolean;
  eventsWithUsername?: boolean;
  raxLabId?: string;
  accountId?: string;
  fetchData?: boolean;
  fetchAvailability?: boolean;
  isModal?: boolean;
  isLarge?: boolean;
  onSelectDates?: (value: IDatesParams) => void;
}): JSX.Element => {
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [unavailableDates, setUnavailableDates] = useState([]);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    handleEvents();
    if (fetchData) {
      const dateRange = dateRangeHelper(new Date().toDateString());
      onChangeMonthHandler(dateRange);
    }
  }, []);

  const handleEvents = (fetchedBookings = []) => {
    const currentBookings = fetchedBookings?.length ? fetchedBookings : bookings;
    const events = assignColorsToAccounts(currentBookings);
    setCalendarEvents(events);
  };

  const assignColorsToAccounts = (events) => {
    const colorValues = Object.values(ACCOUNT_COLORS);
    const accountIdColorMap = {};
    let colorIndex = 0;
    events.forEach((event) => {
      if (!accountIdColorMap[event.id]) {
        accountIdColorMap[event.id] = colorValues[colorIndex % colorValues.length];
        colorIndex++;
      }
      event.color = accountIdColorMap[event.id];
    });

    return events;
  };

  const onChangeMonthHandler = async (date_range: string) => {
    try {
      setFetching(true);
      setUnavailableDates([]);
      const promises = [];
      if (fetchAvailability) promises.push(handleFetchAvailability(date_range));
      if (!isInteractable) promises.push(handleFetchBookings(date_range));
      await Promise.allSettled(promises);
    } finally {
      setFetching(false);
    }
  };

  const handleFetchAvailability = async (date_range) => {
    try {
      const dateParams = availabilityDatesParamsV2(date_range);
      const params = { ...dateParams, ...(raxLabId ? { rax_lab_id: raxLabId } : {}) };
      const response = await getRaxCalendarAvailabilityAction(params);
      setUnavailableDates(response.data);
      return response;
    } catch (error) {
      Notification({ text: 'Error retrieving Rax information', type: 'error' });
    }
  };

  const handleFetchBookings = async (start_date_range) => {
    const { ACTIVE, UPCOMING, RESERVED } = BOOKING_STATUS;
    const status = `${ACTIVE},${UPCOMING},${RESERVED}`;
    try {
      const params = {
        start_date_range,
        status,
        ...(accountId ? { account: accountId } : {}),
        ...(raxLabId ? { rax_lab: raxLabId } : {}),
      };
      const response = await getRaxBookingsAction(params);
      handleEvents(response.data.results);
      return response;
    } catch (error) {
      Notification({ text: 'Error retrieving Rax information', type: 'error' });
    }
  };

  const dateTodayUnavailable = useMemo(() => format(new Date(), 'yyyy-MM-dd'), []);

  const disableDates = useMemo(() => {
    const dates = unavailableDates.filter((date) => Object.values(date)[0] === 0);
    const disabledDates = dates?.map((date) => Object.keys(date)[0]);
    if (!disabledDates.includes(dateTodayUnavailable)) disabledDates.push(dateTodayUnavailable);
    return disabledDates;
  }, [unavailableDates]);

  return (
    <div
      style={{
        width: '100%',
        overflowY: 'auto',
        position: 'relative',
        ...(isModal ? { maxHeight: '525px' } : {}),
        ...(fetching ? { opacity: '0.5' } : {}),
      }}
    >
      {fetching && <Spinner active top={'40%'} zIndex="10" />}
      <Calendar
        events={calendarEvents}
        eventsWithUsername={eventsWithUsername}
        isInteractable={isInteractable}
        disabledDates={disableDates}
        borders={borders}
        flat={flat}
        isFetching={fetching}
        isModal={isModal}
        isLarge={isLarge}
        onSelectDates={onSelectDates}
        onChangeMonth={onChangeMonthHandler}
      />
    </div>
  );
};

export default CalendarContainer;
