import { EmptyStateImg, EmptyStateContainer } from './styles';
import EmptyStateSvg from 'assets/images/empty-state-users.svg';
import useSearchQuery from 'hooks/useSearchQuery';

const SearchEmptyState = (): JSX.Element => {
  const { getFilter, getLastFilter } = useSearchQuery();

  const lastFilter = getLastFilter();
  const filterValue = getFilter(lastFilter);

  return (
    <EmptyStateContainer>
      <EmptyStateImg src={EmptyStateSvg} />
      <>
        <h4>
          Sorry, we couldn’t find any matches for{' '}
          {lastFilter === 'search' ? filterValue : `'${lastFilter}' filter`}.
        </h4>
        <p>Please try searching by another {lastFilter === 'search' ? 'term' : 'filter'}.</p>
      </>
    </EmptyStateContainer>
  );
};

export default SearchEmptyState;
