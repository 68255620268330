import styled from 'styled-components';
import { Row } from 'components/layout/profile/profileContent/styles';
import { screenMd, screenSm } from 'constants/styles/breakpoints';

export const CustomRow = styled(Row)`
  .dashboard {
    &__status-card {
      order: 1;
      min-width: 33%;
    }
    &__licenses-card {
      order: 2;
      min-width: 33%;
    }
    &__addons-card {
      order: 3;
      min-width: 33%;
    }
    &__teams-card {
      order: 4;
    }
    &__users-card {
      order: 5;
    }
    &__analytics-card {
      order: 2;
      min-width: 80%;
    }
    &__counts-card {
      order: 1;
    }
    ${screenMd} {
      &__status-card {
        min-width: 33%;
      }
      &__licenses-card {
        order: 2;
        min-width: 30%;
      }
      &__addons-card {
        order: 3;
        min-width: 30%;
      }
      &__teams-card {
        order: 4;
      }
      &__users-card {
        order: 5;
      }
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  background-color: #f2f2f7;
  padding: 10px;

  ${screenSm} {
    flex-direction: column;
    width: 202px;
    padding: 15px;
  }
`;

export const CounterCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Label = styled.label`
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #57575c;
  display: flex;
  align-items: center;
  text-transform: capitalize;
`;

export const Title = styled.h2`
  font-size: 18px;
  text-align: center;
`;
