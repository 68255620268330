import { useReducer } from 'react';
import { UserFetchParams } from 'services/users';

export interface UserTableFetchActions {
  type: 'SET_STATUS' | 'SET_ROLE' | 'SET_PAGE' | 'SET_SEARCH' | 'SET_PAGE_SIZE';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}

export const usersTableDataFetchReducer = (
  state: UserFetchParams,
  action: UserTableFetchActions
): UserFetchParams => {
  if (action.type === 'SET_STATUS') {
    return { ...state, active: action.value, page: 1 };
  } else if (action.type === 'SET_PAGE_SIZE') {
    return { ...state, page_size: action.value, page: 1 };
  } else if (action.type === 'SET_PAGE') {
    return { ...state, page: action.value };
  } else if (action.type === 'SET_SEARCH') {
    return { ...state, search: action.value };
  } else {
    return state;
  }
};

interface TeamUserSearchHook {
  requestData: UserFetchParams;
  handlePagination: (page: string, name: string) => void;
  handleSearchboxInput: (value: string) => void;
  handleStatusFilter: (value: string) => void;
}

const useTeamUsersSearch = ({ teamSlug }: { teamSlug: string }): TeamUserSearchHook => {
  const [requestData, setRequestData] = useReducer(usersTableDataFetchReducer, {
    active: 'true',
    license: null,
    teams: teamSlug,
    roles: null,
    page: 1,
    page_size: 6,
    search: null,
  });

  const handlePagination = (page: string, name: string) => {
    if (name === 'page_size') {
      setRequestData({ type: 'SET_PAGE_SIZE', value: page });
    } else if (name === 'page') {
      setRequestData({ type: 'SET_PAGE', value: page });
    }
  };

  const handleSearchboxInput = (value: string) => {
    if (value !== requestData.search) {
      setRequestData({ type: 'SET_SEARCH', value });
    }
  };

  const handleStatusFilter = (value: string) => {
    setRequestData({ type: 'SET_STATUS', value: value });
  };

  return {
    handlePagination,
    handleSearchboxInput,
    handleStatusFilter,
    requestData,
  };
};

export default useTeamUsersSearch;
