/**
 * `Card Container`
 * @param {ReactNode} children optionals components.
 * @returns {JSX.Element} Component.
 */

import { useContext } from 'react';
import { HeaderLabel } from 'components/layout/profile/profileHeader/styles';
import { UserTeam } from 'contexts/teams/types';
import { Content, Litle, TitlesContainer } from './styles';
import Switch from 'components/switch';
import { UserTeamContainer } from 'pages/Users/components/InviteUsers/AdjustUsersSettings/styles';
import { UserTeamsPayload } from 'components/usersTable/types';
import { SessionContext } from 'contexts/session';
import { ACC_OWNER, ROLES, TEAM_MANAGER } from 'constants/roles';
import Tooltip from 'components/tooltip';
import { UserDetail } from 'types/users';

type Props = {
  teams: UserTeam[];
  disableManager?: boolean;
  onSwitchManager: (teamsData: UserTeamsPayload[]) => void;
  width?: string;
  flat?: boolean;
  user?: UserDetail;
};

const LittleTeamsCard = ({
  teams,
  onSwitchManager,
  disableManager,
  width,
  flat,
  user,
}: Props): JSX.Element => {
  const { user: userSession } = useContext(SessionContext);
  const isTeamManagerLoggedIn = userSession.uaa_data?.roles?.data?.some(
    (r) => r.id === ROLES.TEAM_MANAGER
  );

  const isNotManagerFromTeam = (teamId: string): boolean => {
    return isTeamManagerLoggedIn
      ? Boolean(!userSession.teams.find((team) => team.team_id === teamId)?.is_manager)
      : false;
  };

  const isUserManager = user?.uaa_data?.roles?.data?.some((role) => role.name === TEAM_MANAGER);
  const isUserAccowner = user?.uaa_data?.roles?.data?.some((role) => role.name === ACC_OWNER);
  const isItself = user?.email === userSession?.email;

  const getRoleSwitchStatus = () => {
    const switchStatus = {
      disabled: false,
      tooltip: 'Team Manager role',
    };
    if (isUserManager && isItself) {
      switchStatus.disabled = true;
      switchStatus.tooltip = `Team manager cannot revoke it's own role`;
    } else if (isUserAccowner) {
      switchStatus.disabled = true;
      switchStatus.tooltip = `An Account Owner cannot be set as a Team Manager`;
    }
    return switchStatus;
  };

  const managerSwitchStatus = getRoleSwitchStatus();

  return (
    <Litle width={width ? width : '90'}>
      <TitlesContainer>
        <HeaderLabel>TEAM</HeaderLabel>
        <HeaderLabel>MANAGER</HeaderLabel>
      </TitlesContainer>
      <Content flat={flat} style={{ padding: '10px' }}>
        {teams.map((team, index) => (
          <UserTeamContainer
            style={{ width: '100%' }}
            key={index}
            borderBottom={index < teams.length - 1}
          >
            <div style={{ fontWeight: '500' }}>{team.name}</div>
            <Tooltip
              zIndex={9999}
              placement="bottom"
              destroyTooltipOnHide
              title={
                isNotManagerFromTeam(team.team_id)
                  ? `You can only edit users' roles if they are on your team.`
                  : managerSwitchStatus.tooltip
              }
            >
              <span
                style={{
                  cursor:
                    managerSwitchStatus.disabled || isNotManagerFromTeam(team.team_id)
                      ? 'not-allowed'
                      : 'auto',
                }}
              >
                <Switch
                  defaultChecked={team.is_manager}
                  disabled={
                    disableManager ||
                    managerSwitchStatus.disabled ||
                    isNotManagerFromTeam(team.team_id)
                  }
                  onChange={(checked) =>
                    onSwitchManager([{ team_id: team.team_id, is_manager: checked }])
                  }
                />
              </span>
            </Tooltip>
          </UserTeamContainer>
        ))}
        {teams.length === 0 && <span style={{ fontWeight: '500' }}>No Teams Selected</span>}
      </Content>
    </Litle>
  );
};

export default LittleTeamsCard;
