import { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { format, formatDistanceToNowStrict } from 'date-fns';
import { SessionContext } from 'contexts/session';
import { Account } from 'contexts/accounts/types';
import { StickyFooter } from 'components/cards';
import ModalDrawer from 'components/microLayouts/drawer';
import Button from 'components/button/Button';
import ButtonIcon from 'components/buttonIcon';
import Tooltip from 'components/tooltip';
import AvatarHeader from 'components/avatarHeader';
import { AccountCell, AccountCellLink, TypeLabel } from './styles';

interface AccountsSettingsProps {
  data: Account;
  isExpired: boolean;
  onExpireAccount?: (accountId: string) => void;
}

const Container = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 14px 0px;
  width: 100%;
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.default.colors.palette.Business_LightThemeGreyDarker};
  span {
    color: ${({ theme }) => theme.default.colors.palette.Business_LightThemeGrayDark};
  }
`;

const Background = styled.div`
  position: relative;
  background-color: #f2f2f7;
  min-height: 100%;
`;

const AccountsSettings = ({
  data,
  onExpireAccount,
  isExpired,
}: AccountsSettingsProps): JSX.Element => {
  const [id, setId] = useState(null);
  const { showDrawer, setVisibiltyDrawer } = useContext(SessionContext);
  const { actions: sessionActions } = useContext(SessionContext);
  const [isImpersonating, setIsImpersonating] = useState('');
  const expireDiff = formatDistanceToNowStrict(new Date(data.expire_date), { addSuffix: true });
  const formatedEndDate = format(new Date(data.expire_date), 'PP');

  useEffect(() => {
    !showDrawer && setId(null);
  }, [showDrawer]);

  const handlerDrawer = ({ id }: { id: string }) => {
    setId(id);
    setVisibiltyDrawer(!showDrawer);
  };

  const close = () => {
    setVisibiltyDrawer(false);
  };

  return (
    <>
      <Tooltip placement="left" title={'Go to account settings'}>
        <ButtonIcon
          $terciary
          icon="ChevronRight"
          size="small"
          onClick={() => {
            handlerDrawer({ id: data.id });
          }}
        />
      </Tooltip>

      <ModalDrawer
        closable={false}
        placement="bottom"
        visible={showDrawer && data.id === id}
        onClose={close}
        height="calc(100% - 64px)"
        destroyOnClose
      >
        {showDrawer && (
          <Background>
            <AvatarHeader avatar={data.logo_url || null} email={data.name} />
            <Container>
              <Content>
                <h5
                  style={{
                    textAlign: 'center',
                    fontSize: '20px',
                    padding: '20px 0 0 0',
                    margin: '0',
                  }}
                >
                  {data.name}
                </h5>
                <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                  <TypeLabel>{data.pricing_plan}</TypeLabel>
                </div>
                <Label>CONTACT</Label>
                <AccountCellLink style={{ fontSize: '16px' }}>{data.contacts?.[0]}</AccountCellLink>
                <div style={{ textAlign: 'center', margin: '10px 0px' }}>
                  <Button
                    $secondary
                    size={'small'}
                    icon={'UserPlus'}
                    loading={isImpersonating === data.contacts[0]}
                    disabled={data?.contacts.length <= 0 || isExpired}
                    onClick={() => {
                      setIsImpersonating(data.contacts[0]);
                      sessionActions.impersonate(data.contacts[0]);
                    }}
                  >
                    Impersonate as account owner
                  </Button>
                </div>
              </Content>
              <Content>
                <Label>LICENSES</Label>
                {data.licenses_details.count ? (
                  `${data.licenses_details.used}  / ${data.licenses_details.count}`
                ) : (
                  <span style={{ fontSize: '15px', fontFamily: 'sans-serif' }}>∞</span>
                )}
                <span style={{ marginTop: '3px', fontSize: '16px' }}>
                  {data.licenses_details.used} / {data.licenses_details.count}
                </span>
              </Content>
              <Content>
                <Label>REPRESENTATIVE</Label>
                <AccountCell style={{ marginTop: '6px', fontSize: '16px' }}>
                  <b>Sales: </b>
                  {data.representative.sales}
                  <br />
                  <b>Support: </b>
                  {data.representative.support}
                </AccountCell>
              </Content>
              <Content>
                <Label>END DATE</Label>
                <AccountCell style={{ marginTop: '6px', fontSize: '16px' }}>
                  <b>{formatedEndDate}</b>
                  {`   (${expireDiff})`}
                </AccountCell>
              </Content>
            </Container>
            <StickyFooter>
              <NavLink to={`account/${data.id}`} className={'settings-bottom-button'}>
                <Tooltip placement="left" title={'Go to account profile'}>
                  <Button $full $secondary size={'small'} icon={'ChevronRight'}>
                    See account
                  </Button>
                </Tooltip>
              </NavLink>
              <Tooltip placement="left" title={'Expire account'}>
                <Button
                  $full
                  $secondary
                  size={'small'}
                  icon={'Trash'}
                  className={'settings-bottom-button'}
                  onClick={() => onExpireAccount(data.id)}
                  disabled={isExpired}
                >
                  Expire Account
                </Button>
              </Tooltip>
            </StickyFooter>
          </Background>
        )}
      </ModalDrawer>
    </>
  );
};

export default AccountsSettings;
