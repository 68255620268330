import { useContext } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { SessionContext } from 'contexts/session';
import Icon from 'components/icons';

interface HeaderInfoProps {
  title: string;
}

const Container = styled.div``;

const Title = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  font-weight: 700;
  font-size: 17px;
  line-height: 17px;
  color: ${({ theme }) => theme.default.colors.palette.Business_DarkThemeGeneralBackground};
`;

const License = styled.div`
  width: 100%;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.default.colors.palette.Business_LightThemeGreyDarker};
  span {
    font-weight: 700;
    color: ${({ theme }) => theme.default.colors.palette.Business_DarkThemeGeneralBackground};
  }
`;

const HeaderInfo = ({ title }: HeaderInfoProps): JSX.Element => {
  const {
    licenses_available,
    showDrawer,
    setVisibiltyDrawer,
    showNotesDrawer,
    setShowNotesDrawer,
    showActivityLogDrawer,
    setShowActivityLogDrawer,
  } = useContext(SessionContext);

  const handleCloseDrawer = () => {
    showDrawer && setVisibiltyDrawer(!showDrawer);
    showNotesDrawer && setShowNotesDrawer(!showNotesDrawer);
    showActivityLogDrawer && setShowActivityLogDrawer(!showActivityLogDrawer);
  };

  return (
    <Container>
      {(showDrawer || showNotesDrawer || showActivityLogDrawer || title) && (
        <Title>
          {(showDrawer || showNotesDrawer || showActivityLogDrawer) && (
            <Icon
              icon="ArrowLeft"
              size="medium"
              style={{ marginRight: 5 }}
              color={'#A793F6'}
              onClick={handleCloseDrawer}
            />
          )}
          {title || null}
        </Title>
      )}
      {!isMobile && licenses_available !== undefined && (
        <License>
          Licenses available:
          <span>{licenses_available === true ? 'Unlimited' : licenses_available}</span>
        </License>
      )}
    </Container>
  );
};

export default HeaderInfo;
