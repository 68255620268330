import styled from 'styled-components';
import { colors } from 'constants/styles/colors';

function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)},${parseInt(result[3], 16)}`
    : null;
}

export const ContentWrapper = styled.div`
  padding: 30px;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: ${colors.fonts.dark}
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const NoUsersTitle = styled.h1`
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: ${colors.fonts.dark}
  margin-top: 70px;
  text-align: center;
`;

export const EmptySonarStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SonarEmptyStateTitle = styled.h5`
  color: ${colors.fonts.dark};
  margin-bottom: 10px;
  text-align: center;
  font-family: Circular-Std;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
`;

export const SonarEmptyStateSubtitle = styled.span`
  color: ${colors.fonts.darkGray};
  margin-bottom: 20px;
  text-align: center;
  font-family: Circular-Std;
  font-size: 16px;
  font-style: normal;
`;
