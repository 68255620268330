import { handleErrors } from 'contexts/helpers';
import { getActivityLogUsers, getActivityLogs } from 'services/activityLog';
import { AcitivityLogParams, ActivityLogUsersParams } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getActivitiesLogAction = async (
  params: AcitivityLogParams
): Promise<{ results: any; count: number } | Error> => {
  try {
    const { data } = await getActivityLogs(params);
    return {
      results: data,
      count: data.count,
    };
  } catch (error) {
    return handleErrors(error);
  }
};

export const getActivityLogUsersAction = async (
  params?: ActivityLogUsersParams
): Promise<{ results: any } | Error> => {
  try {
    const { data } = await getActivityLogUsers(params);
    return {
      results: data,
    };
  } catch (error) {
    return handleErrors(error);
  }
};
