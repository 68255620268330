import { useState, useContext } from 'react';
import { isDesktop } from 'react-device-detect';
import styled from 'styled-components';

import { ReportsContext } from 'contexts/reporting';
import { SessionContext } from 'contexts/session';
import { USER_ACTIVITY } from 'constants/reporting';

import { Title } from 'components/layout/tableContentHeader/styles';
import ReportingContentFilter from './components/ReportingContentFilter';
import ReportingFilters from './components/ReportingFilters';
import ReportingHistoryTable from './components/ReportingHistoryTable';
import { ISorter } from 'contexts/reporting/types';
import { sortingInitialState } from 'contexts/reporting/utils';

const ReportingTitle = styled(Title)`
  font-size: 24px;
  margin-left: 10px;
`;
const ReportingContent = styled.div<{ isDesktop: boolean }>`
  ${({ isDesktop }) =>
    isDesktop &&
    `
    height: 78vh;
  `}
`;

const ReportingComponent = (): JSX.Element => {
  const { fetching, saveReportsFiltersPreferences } = useContext(ReportsContext);
  const { accounts } = useContext(SessionContext);
  const [activeContent, setActiveContent] = useState(USER_ACTIVITY);
  const [sortingOptions, setSortingOptions] = useState<ISorter[]>(
    JSON.parse(JSON.stringify(sortingInitialState))
  );

  const onContentChangeHandler = (id: string) => {
    saveReportsFiltersPreferences(activeContent);
    setSortingOptions(JSON.parse(JSON.stringify(sortingInitialState)));
    setActiveContent(id);
  };

  return (
    <ReportingContent isDesktop={isDesktop && fetching}>
      <ReportingTitle>Reporting</ReportingTitle>
      <ReportingContentFilter onContentChange={onContentChangeHandler} />
      <ReportingFilters
        filtersType={activeContent}
        hasSkillDive={accounts?.account_addons?.count > 0}
        sonarEnabled={accounts?.sonar_enabled}
      />
      <ReportingHistoryTable
        filtersType={activeContent}
        sortingOptions={sortingOptions}
        setSortingOptions={(value: ISorter[]) => setSortingOptions(value)}
      />
    </ReportingContent>
  );
};

export default ReportingComponent;
