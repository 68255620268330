import EmptySkillsSvg from '../../../../assets/icons/skills/skills_empty.svg';
import {
  ComingSoonSubtitle,
  EmptySkillTitle,
  EmptySkillsImg,
  SkillsEmptyContainer,
  SkillsEmptyWrapper,
} from './styles';
import { LEARNING_AREAS_TYPES } from 'constants/playlists';

interface ComingSoonProps {
  skillName: string;
}

const ComingSoon = ({ skillName }: ComingSoonProps): JSX.Element => {
  const emptyLabel =
    skillName === LEARNING_AREAS_TYPES['cloud'].name
      ? 'Cloud skills assessments coming in early 2024'
      : `${skillName} Sonar Skill assessments coming late summer 2023!`;
  return (
    <SkillsEmptyContainer>
      <SkillsEmptyWrapper>
        <div>
          <EmptySkillsImg src={EmptySkillsSvg} />
        </div>
        <EmptySkillTitle>{emptyLabel}</EmptySkillTitle>
        <ComingSoonSubtitle>
          We’re adding new assessments in <span>{skillName}</span>. Stay tuned for the latest
          updates!
        </ComingSoonSubtitle>
      </SkillsEmptyWrapper>
    </SkillsEmptyContainer>
  );
};

export default ComingSoon;
