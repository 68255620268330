import { useContext, useEffect, useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { DeepTeams } from 'contexts/teams/types';
import { getTeamData } from 'services/teams';
import {
  CardsContainer,
  Columns,
  MainContent,
  Row,
} from 'components/layout/profile/profileContent/styles';
import TeamProfileHeader from './components/TeamsProfileHeader';
import AppReadyLoading from 'components/loadings/AppReadyLoading';
import TeamUsersCard from './components/TeamUsersCard';
import { handleErrors } from 'contexts/helpers';
import TeamManagersCard from './components/TeamManagersCard';
import { screenMd } from 'constants/styles/breakpoints';
import TeamsUsersTable from './components/TeamUsersTable';
import TeamTopUsersAnalytics from './components/TeamTopUsersAnalytics/TeamTopUsersAnalytics';
import PreferredContent from 'components/stats/preferredContent';
import { SessionContext } from 'contexts/session';

import { MONTH_PERIOD } from 'constants/filters';
import TeamSkills from './components/TeamSkills/TeamSkills';

const CustomCardsContainer = styled(CardsContainer)`
  display: flex;
  flex-direction: column;
  grid-gap: 30px;
  .team-managers {
    width: 100%;
    ${screenMd} {
      width: 50%;
    }
  }
  .team-users {
    width: 100%;
    ${screenMd} {
      width: 50%;
    }
  }
`;

const TeamsProfile = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const [teamData, setTeamData] = useState<DeepTeams>(null);
  const { user, account, accounts } = useContext(SessionContext);

  useEffect(() => {
    getTeam();
  }, []);

  const getTeam = async () => {
    try {
      const response = await getTeamData({ id });
      if (response.status === 200) {
        const data = response.data;
        response.status === 200 && setTeamData(data);
      }
    } catch (error) {
      handleErrors(error);
    }
  };

  const getPreferredContentParams = () => {
    return {
      date_from: null,
      date_to: null,
      period: MONTH_PERIOD.VALUE,
      user_id: user.uaa_id,
      account_id: account?.id,
      team_ids: id,
    };
  };

  if (!teamData) {
    return <AppReadyLoading />;
  }

  return (
    <>
      <MobileView>
        <TeamProfileHeader team={teamData} refreshData={getTeam} />
      </MobileView>
      <MainContent>
        <BrowserView>
          <TeamProfileHeader team={teamData} refreshData={getTeam} />
        </BrowserView>
        <CustomCardsContainer>
          <Row>
            <Columns className={'team-managers'}>
              <TeamManagersCard
                teamSlug={teamData.slug}
                total={teamData.team_managers_count}
                managers={teamData.team_managers}
              />
            </Columns>

            <Columns className={'team-users'}>
              <TeamUsersCard
                teamSlug={teamData.slug}
                totalActives={teamData.active_users_count}
                totalPendings={teamData.pending_users_count}
                teamUsers={[...teamData?.active_users, ...teamData?.pending_users]}
              />
            </Columns>
          </Row>
          <Row>
            <TeamsUsersTable
              team={teamData}
              refreshData={getTeam}
              totalUsers={teamData.active_users_count + teamData.pending_users_count}
            />
          </Row>
          {accounts?.sonar_enabled && (
            <Row>
              <TeamSkills teamSlug={teamData.slug} />
            </Row>
          )}
          <Row>
            <TeamTopUsersAnalytics />
          </Row>
          <Row>
            <PreferredContent params={getPreferredContentParams()} />
          </Row>
        </CustomCardsContainer>
      </MainContent>
    </>
  );
};

export default TeamsProfile;
