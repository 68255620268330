import { FC, useEffect, useState } from 'react';
import { Card } from 'components/layout/profile/profileContent/styles';
import { Spinner } from 'components/loadings';
import { getPreferredContentAction } from 'contexts/analytics/actions';
import { CardHeaderSection } from 'pages/Teams/components/TeamSharedStyles';
import { ChartNoData, GraphContainter } from 'pages/Teams/components/TeamTopUsersAnalytics/styles';
import { PreferredContentParams } from 'contexts/analytics/types';
import { getInitialPreferredContentValues } from './configs';
import PreferredContentChart from './PreferredContentChart';

const PreferredContent: FC<{ params: PreferredContentParams }> = ({ params }) => {
  const [fetching, setFetching] = useState(true);
  const [graphData, setGraphData] = useState(null);
  const [itemsSeen, setItemsSeen] = useState(0);

  const contentWithZeroProgress = graphData?.every(({ itemData }) => itemData === 0);

  useEffect(() => {
    getPreferredContentData();
  }, []);

  const getPreferredContentData = async () => {
    const data = await getPreferredContentAction(params);
    if (!(data instanceof Error)) {
      setItemsSeen(
        data.results
          ?.filter(({ name }) => name !== 'default')
          .reduce((acc, content) => acc + content.count, 0)
      );
      const newGraphData = buildGraphData(data);
      setGraphData(newGraphData);
      setFetching(false);
    } else {
      return setGraphData([]);
    }
  };

  const buildGraphData = (responseData) => {
    let graphData = getInitialPreferredContentValues();
    responseData.results.map(({ name, count }) => {
      if (name !== 'default') graphData.find((gd) => gd.name === name).itemData = count;
    });
    graphData = graphData.sort((a, b) => b.itemData - a.itemData);
    graphData = graphData.map((item) => ({ ...item, renderData: JSON.stringify(item) }));
    return graphData;
  };

  return (
    <Card>
      <CardHeaderSection>
        <h5>Preferred content</h5>
        <p>
          Last month activities: <b>{itemsSeen} item(s) seen</b>
        </p>
      </CardHeaderSection>
      <GraphContainter>
        {fetching ? (
          <Spinner active top={'50%'} />
        ) : graphData && !contentWithZeroProgress ? (
          <PreferredContentChart tickImage={false} svg={true} data={graphData} />
        ) : (
          <ChartNoData>No data</ChartNoData>
        )}
      </GraphContainter>
    </Card>
  );
};

export default PreferredContent;
