import { screenLg } from 'constants/styles/breakpoints';
import styled from 'styled-components';

function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)},${parseInt(result[3], 16)}`
    : null;
}

export const Container = styled.div`
  background-color: #ffffff;
  border-radius: 12px;
  height: 478px;
  width: 360px;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);

  ${screenLg} {
    width: 420px;
  }
`;

export const Header = styled.div<{ color?: string; isSonar: boolean }>`
  position: relative;
  background: ${({ isSonar, color }) =>
    isSonar
      ? 'linear-gradient(90deg, #2f0559 4.2%, #863ad2 52.87%, #b736e4 97.03%)'
      : `rgba(${hexToRgb(color)}, 0.5)`};
  width: 100%;
  height: 136px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-start-start-radius: 12px;
  border-start-end-radius: 12px;

  ${screenLg} {
    height: 158px;
  }
`;

export const DurationPill = styled.span<{ bgColor: string }>`
  position: absolute;
  top: 10px;
  right: 10px;

  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  display: flex;
  padding: 5px 8px;
  background: ${({ bgColor }) => bgColor || '#000'};
  color: ${({ theme }) => theme.default.colors.fonts.white};
  box-shadow: 0px 0px 10px rgba(167, 147, 246, 0.5);
  border-radius: 30px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 320px;
  padding: 25px 15px 15px;
  ${screenLg} {
    padding: 35px;
  }
`;

export const Title = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #1a2535;
  margin: 0 0 10px 0;
  max-height: 48px;
`;

export const Description = styled.div`
  font-style: normal;
  font-size: 16px;
  color: #aeaeb2;
  line-height: 24px;
  color: #aeaeb2;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  max-height: 68px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DateContainer = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #57575c;
  display: flex;
  flex-direction: column;
  flex: 1 1 50%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: bold;
  color: #57575c;
  flex: 1 1 50%;
`;

export const PlaylistInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  gap: 0 13px;
  ${screenLg} {
    gap: 0 25px;
  }
`;

export const Divider = styled.div<{ flat?: boolean }>`
  border-radius: 6px;
  height: 1px;
  width: 100%;
  display: block;
  margin: ${({ flat }) => (!flat ? ' 25px 0 20px' : '25px 0 0')};
  background: ${({ flat }) => (!flat ? '#d0d2db' : 'transparent')};
`;

export const ButtonsGapWrapper = styled.div<{ big: boolean }>`
  display: grid;
  grid-template-columns: ${({ big }) => (big ? '6fr 1fr' : '2fr 1fr')};
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 0px;
`;

export const RecommendedDescription = styled.div`
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: #aeaeb2;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80%;
`;

export const RecommendedDescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  margin: 0 0 auto;
`;

export const AssignedMessage = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  gap: 1px;
  span {
    font-size: 14px;
    margin-left: 1px;
    color: ${({ theme }) => theme.default.colors.performance.positiveDark};
  }
`;

export const SeeDetailsContainer = styled.div`
  width: 100%;
`;

export const IconButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    margin-left: 7px;
  }
`;
