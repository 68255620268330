import Avatar from 'components/avatar';
import { SessionContext } from 'contexts/session';
import { useContext } from 'react';
import styled from 'styled-components';
import { screenMd } from 'constants/styles/breakpoints';
import FirstLetterEmail from 'components/firstLetterEmail/firstLetterEmail';
import { DEFAULT_IMG_USERS } from 'constants/urls';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 10px;
`;

const UserInfo = styled.div`
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding-right: 15px;

  ${screenMd} {
    display: flex;
  }
`;

const Name = styled.span`
  font-size: 16px;
  text-align: right;
  line-height: 23px;
  color: ${({ theme }) => theme.default.colors.palette.Business_DarkThemeGeneralBackground};
  white-space: nowrap;
`;

const Role = styled.span`
  font-size: 12px;
  text-align: right;
  line-height: 14px;
  color: ${({ theme }) => theme.default.colors.palette.Business_LightThemeGrayDark};
  white-space: nowrap;
`;

const AvatarHeaderProfile = (): JSX.Element => {
  const { user, account } = useContext(SessionContext);
  //const { teams } = useContext(TeamsContext);

  //const team = teams?.filter(({id}) => id === user?.team)?.[0]?.name;

  const accountName = account?.name;

  const roles = user?.uaa_data.roles?.data?.filter(({ is_primary }) => is_primary === 0);

  const role = roles?.map(({ name }) => {
    if (name === 'Admin') {
      return name;
    } else if (name === 'Sales') {
      return name;
    } else if (name === 'Support') {
      return name;
    } else if (name === 'Account Owner') {
      return name;
    } else if (name === 'Team Manager') {
      return name;
    } else return null;
  });

  const getAcountInfo = () => {
    let text = '';
    if (role) text = text + role;
    if (accountName && role) text = text + ', ';
    if (accountName) text = text + accountName;
    return text;
  };
  return (
    <Container>
      {user && (
        <UserInfo>
          <Name>
            {user?.uaa_data.profile.data.first_name + ' '}
            {user?.uaa_data.profile.data.last_name}
          </Name>
          <Role>{getAcountInfo()}</Role>
        </UserInfo>
      )}

      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        {user?.uaa_data.profile.data.avatar &&
        user?.uaa_data.profile.data.avatar !== DEFAULT_IMG_USERS ? (
          <Avatar image={user?.uaa_data.profile.data.avatar} size={'sm'} />
        ) : (
          <FirstLetterEmail email={user?.uaa_data.email} />
        )}
      </div>
    </Container>
  );
};

export default AvatarHeaderProfile;
