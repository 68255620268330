import { FC, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Card } from 'components/layout/profile/profileContent/styles';
import { Spinner } from 'components/loadings';
import Notification from 'components/notification';
import ActivitiesChartTabs from 'components/stats/activitiesChartTabs';
import CustomBarChart from 'components/stats/CustomBarChart';
import { getSeriesByTypeAction } from 'contexts/analytics/actions';
import { AggregationStatsData } from 'contexts/analytics/types';
import AnalyticsUserHeader from './AnalyticsUserHeader';
import { barChartConfig } from './configs';

interface UserAnalyticsProps {
  aggregationTabs: AggregationStatsData[];
  aggregationSeries: any[];
}

const UserAnalytics: FC<UserAnalyticsProps> = ({ aggregationTabs, aggregationSeries }) => {
  const [fetching, setFetching] = useState(false);
  const [aggregationSelected, setAggregationSelected] = useState(aggregationTabs[0]);
  const [graphData, setGraphData] = useState({
    name: null,
    items: [],
    labels: [],
  });

  useEffect(() => {
    getSeriesData(aggregationSelected);
  }, []);

  const getSeriesData = async (aggregation: AggregationStatsData) => {
    const aggregationSerie = aggregationSeries.find((as) => as.name === aggregation.name);
    setGraphData((prevState) => ({ ...prevState, name: aggregationSerie.name }));
    aggregationSerie.series.map(async (s) => {
      setFetching(true);
      setGraphData((prevState) => ({
        ...prevState,
        labels: [...prevState.labels, { key: s.key, label: s.label, color: s.color }],
      }));
      try {
        const response = await getSeriesByTypeAction(s.params);
        setFetching(false);
        if (response instanceof Error) {
          Notification({ text: `Error retrieving ${s.label} stat`, type: 'error' });
        } else {
          setGraphData((prevState) => {
            if (prevState.items.length) {
              return {
                ...prevState,
                items: prevState.items.map((it, index) => ({
                  ...it,
                  [s.key]: response.results[index].count,
                })),
              };
            } else {
              return {
                ...prevState,
                items: response.results.map((r, index) => ({
                  date: `Week ${index + 1}`,
                  [s.key]: r.count,
                })),
              };
            }
          });
        }
      } catch (error) {
        setFetching(false);
        Notification({ text: `Error retrieving ${s.label} stat`, type: 'error' });
      }
    });
  };

  const handleTabChanged = (name: string) => {
    setGraphData({
      name: null,
      items: [],
      labels: [],
    });
    const aggregation = aggregationTabs.find((ag) => ag.name === name);
    setAggregationSelected(aggregation);
    getSeriesData(aggregation);
  };
  return (
    <Card>
      <Spinner
        active={fetching}
        top={isMobile ? '250px' : '300px'}
        left="50%"
        position="relative"
      />
      <h5>User stats</h5>
      <ActivitiesChartTabs aggregationTabs={aggregationTabs} onTabChanged={handleTabChanged} />
      <AnalyticsUserHeader title={aggregationSelected.label} labels={graphData.labels} />
      <CustomBarChart chartConfig={barChartConfig} barData={graphData} />
    </Card>
  );
};

export default UserAnalytics;
