import { /* ProgressBar */ AvatarImage, Status } from './styles';

export interface AvatarProps {
  size?: 'xs' | 'sm' | 'md' | 'lg';
  image?: string;
  alt?: string;
  withOutline?: boolean;
  isOnline?: boolean;
}

export const Avatar = ({
  size = 'sm',
  image,
  withOutline,
  isOnline = false,
}: AvatarProps): JSX.Element => {
  return (
    <AvatarImage size={size} outlined={withOutline} src={image} data-testid={'AVATAR_IMAGE'}>
      {isOnline && <Status size={size} />}
    </AvatarImage>
  );
};

export default Avatar;
