import {
  ProfileHeaderCard,
  ProfileHeaderActions,
  ProfileHeaderGradient,
  ProfileData,
  AvatarContainer,
} from 'components/layout/profile/profileHeader/styles';
import { DeepTeams } from 'contexts/teams/types';
import { IconTeam } from 'components/teamSelect/IconTeam';
import HeaderActions from './HeaderActions';

const TeamProfileHeader = ({
  team,
  refreshData,
}: {
  team: DeepTeams;
  refreshData(): void;
}): JSX.Element => {
  return (
    <ProfileHeaderCard>
      <ProfileHeaderGradient>
        <AvatarContainer>
          <IconTeam color={team.icon.color} iconName={team.icon.name} size={'large'} />
        </AvatarContainer>
        <ProfileData>
          <h2>{team.name}</h2>
          <p></p>
        </ProfileData>
      </ProfileHeaderGradient>
      <ProfileHeaderActions>
        <HeaderActions team={team} refreshData={refreshData} />
      </ProfileHeaderActions>
    </ProfileHeaderCard>
  );
};

export default TeamProfileHeader;
