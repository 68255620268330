import { useState } from 'react';
import { Dropdown } from 'antd';
import styled from 'styled-components';
import ButtonIcon from 'components/buttonIcon';
import SkillsMenu from './SkillsMenu';

interface SkillSelectorProps {
  skills: string[];
  skillsSelected: string[];
  onSkillSelected: (skill: string) => void;
}

const CustomDropdown = styled(Dropdown)<{ maxWidth: string }>`
  max-width: ${({ maxWidth }) => maxWidth};
`;

const SkillSelector = ({
  skills,
  skillsSelected,
  onSkillSelected,
}: SkillSelectorProps): JSX.Element => {
  const [isVisible, setIsVisible] = useState(false);

  const handleSkillSelected = (skill: string) => {
    onSkillSelected(skill);
  };

  return (
    <>
      <CustomDropdown
        maxWidth="50px"
        overlayStyle={{ maxWidth: 'calc(100% - 20px)', margin: '0 0px' }}
        overlay={
          <SkillsMenu
            skills={skills}
            skillsSelected={skillsSelected}
            onSkillSelected={handleSkillSelected}
          />
        }
        trigger={['click']}
        visible={isVisible}
        onVisibleChange={setIsVisible}
      >
        <ButtonIcon
          size="small"
          $secondary
          icon="Plus"
          style={{ padding: '0px 8px' }}
          onClick={() => setIsVisible(!isVisible)}
        />
      </CustomDropdown>
    </>
  );
};

export default SkillSelector;
