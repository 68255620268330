import styled from 'styled-components';
import { DEFAULT_IMG_USERS } from 'constants/urls';
import { randomColor } from 'utils/colors';

const SIZES = {
  xs: { wh: 25, fs: 12 },
  sm: { wh: 45, fs: 24 },
  md: { wh: 50, fs: 24 },
  lg: { wh: 75, fs: 24 },
};

const LetterContainer = styled.div<{ color: any; rounded: boolean; size: keyof typeof SIZES }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ rounded }) => (rounded ? '50%' : '6px')};
  height: ${({ size }) => SIZES[size].wh}px;
  width: ${({ size }) => SIZES[size].wh}px;
  font-weight: 700;
  font-size: ${({ size }) => SIZES[size].fs}px;
  line-height: ${({ size }) => (SIZES[size].fs / 3) * 4}px;
  background: ${({ color }) => color.lighter};
  color: ${({ color }) => color.base};
  text-transform: uppercase;
`;

export interface ProfileImgProps {
  avatar?: string;
  name: string;
  email?: string;
  rounded?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  avatarSize?: 'xs' | 'sm' | 'md' | 'lg';
}

export const AvatarLetter = ({
  avatar,
  name,
  email,
  rounded,
  size = 'md',
  avatarSize,
}: ProfileImgProps): JSX.Element => {
  if (avatar && avatar !== DEFAULT_IMG_USERS)
    return (
      <img
        src={avatar}
        width={avatarSize ? SIZES[size].wh : '50px'}
        height={avatarSize ? SIZES[size].wh : '50px'}
        style={{ borderRadius: rounded ? '50%' : '6px' }}
      />
    );
  else
    return (
      <LetterContainer rounded={rounded} size={size} color={randomColor(email ? email : name)}>
        {name?.[0]}
      </LetterContainer>
    );
};

export default AvatarLetter;
