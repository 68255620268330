import styled from 'styled-components/macro';
import Button from 'components/button';
import { IButtonProps } from 'components/button/Button';
import { screenMd } from 'constants/styles/breakpoints';

export const AdviseContainer = styled.div`
  background: linear-gradient(
    112.2deg,
    rgba(187, 188, 199, 0.4) -46.39%,
    rgba(187, 188, 199, 0) 112.38%
  );
  border-radius: 10px;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  padding: 15px;
  margin: 10px;
  > svg {
    width: 20px !important;
    height: 20px !important;
  }

  ${screenMd} {
    margin: 0 0 20px 0;
    > .advice-inner-container {
      margin-left: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
    }
  }
`;

export const AdviseInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 23px;
  margin: 0 10px 10px 0;

  ${screenMd} {
    margin-bottom: 0;
  }
`;

export const GetMoreLicensesButton = styled(Button)<IButtonProps>`
  &&& {
    background-color: ${({ theme }) => theme.default.buttons.primary.iddle.background};
    color: ${({ theme }) => theme.default.buttons.primary.iddle.color};
    &:active {
      background-color: ${({ theme }) => theme.default.buttons.primary.pressed.background};
      border-color: ${({ theme }) => theme.default.buttons.primary.pressed.background};
    }
    &:disabled {
      background-color: ${({ theme }) => theme.default.buttons.primary.disabled.background};
      color: ${({ theme }) => theme.default.buttons.primary.disabled.color};
    }
    width: 214px;
  }
`;

export const Container = styled.div`
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  background: #f2f2f7;
  /* max-height: calc(100% - 260px); */
  overflow: auto;
  ${screenMd} {
    padding: 20px;
    max-height: 250px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 10px;
  ${screenMd} {
    padding: 0;
  }
`;

export const BodyTitle = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 12px;
  ${screenMd} {
    margin-bottom: 26px;
  }
`;

export const RowContainer = styled.div`
  align-items: center;
  background: white;
  border-radius: 10px;
  border-width: 0px;
  border-style: solid;
  padding: 10px;
  display: grid;
  grid-template-columns: 60px 1fr 1fr 1fr;
  justify-content: start;

  > svg {
    margin-left: auto;
  }
  .actions-container {
    display: inline-flex;
    justify-content: flex-end;
    margin-left: auto;
    grid-gap: 30px;
  }
`;

export const HeaderRowContainer = styled.div`
  display: grid;
  grid-template-columns: 42% 20% 20% 18%;
  margin-bottom: 10px;
  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #57575c;
  }
`;

export const LabelEmail = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  ${screenMd} {
    min-width: 250px;
  }
`;

export const SwitchContainer = styled.div`
  align-items: center;
  display: flex;
  margin-left: auto;
`;

export const SwitchManagerContainer = styled.div`
  align-items: center;
  display: flex;
  margin-left: auto;
`;

export const UserTeamContainer = styled.div<{ borderBottom?: boolean; width?: string }>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  border-bottom: ${({ borderBottom }) => (borderBottom ? '1px solid #e9e9ed;' : 'none;')}
  padding-bottom: 2px;
  padding-top: 2px;
  ${({ width }) => (width ? `width: ${width}%;` : 'width: 100%;')};
`;

export const SwitchLabel = styled.label`
  color: #aeaeb2;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  margin-right: 15px;
  text-align: left;
`;

export const DeleteButtonContainer = styled.div``;

export const DeleteButton = styled.button`
  align-items: center;
  background: ${({ theme }) => theme.default.colors.dataVisualization.gray.lighter};
  border-width: 0;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  height: 36px;
  width: 36px;
  margin-left: auto;
`;

export const EditContainer = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.default.colors.body.bg};
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex-grow: 2;
  height: calc(100vh - 235px);
  > .invite-info {
    // letter and email
    display: flex;
    padding: 25px 10px;
    align-items: center;
    > span {
      font-weight: 500;
      font-size: 20px;
    }
  }
  > .invite-team {
    // custom team selector
    padding: 5px 10px;
    margin-bottom: 30px;
    max-width: 100%;
    svg {
      margin-left: auto !important;
    }
    > .ant-dropdown-trigger {
      background: #fff;
      box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
    }
  }
  > .invite-switchs {
    display: flex;
    width: 100%;
    padding: 10px;
    > div {
      margin-right: 50px;
      max-width: 120px;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: flex-start;
    }
  }
  > .invite-edit-footer {
    height: 83px;
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 0 10px;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #fff;
    grid-gap: 10px;
  }
`;

export const EditFieldsLabel = styled.label`
  line-height: 14px;
  font-size: 16px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.default.colors.fonts.darkGray};
  margin-bottom: 10px;
`;
