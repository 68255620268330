/**
 * `Micro Layout Container`
 * @param {ReactNode} children optionals components.
 * @returns {JSX.Element} Component wrapper.
 */

import { Container, DivFirstLetterEmail, IMG } from './style';

type Props = {
  avatar?: string;
  email?: string;
};

const AvatarHeader = ({ avatar, email }: Props): JSX.Element => (
  <Container>
    <div
      style={{
        background: 'linear-gradient(89.99deg, #863AD2 3.58%, #5936E4 97.14%)',
        height: '100px',
        width: '100%',
      }}
    />
    {avatar ? (
      <IMG src={avatar} />
    ) : (
      <DivFirstLetterEmail>{email.toUpperCase().charAt(0)}</DivFirstLetterEmail>
    )}
    <div style={{ height: '100px', width: '100%' }} />
  </Container>
);

export default AvatarHeader;
