import { FC, useContext } from 'react';
import styled from 'styled-components';
import { screenMd } from 'constants/styles/breakpoints';
import LicenseDataItem from './LicenseDataItem';
import { Card } from 'components/layout/profile/profileContent/styles';

import { INE_CONTACT_SALES } from 'constants/settings';
import { SessionContext } from 'contexts/session';

export const DataContainer = styled.div`
  display: inline-flex;
  grid-gap: 10px;
  justify-content: center;
  align-content: center;
  width: 100%;
`;

export const Disclaimer = styled.span`
  display: block;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-top: auto;
  > a {
    color: ${({ theme }) => theme.default.colors.palette.Business_DataVizLilacDark};
    text-decoration: underline;
  }
`;

export const CustomCard = styled(Card)`
  ${screenMd} {
    padding: 30px;
  }
`;

const AccountLicensesCard: FC<{ licensesData: { count: number; used: number } }> = ({
  licensesData,
}) => {
  const { isStaff, account } = useContext(SessionContext);
  const unlimited = licensesData.count === null;

  const mailtoUrl = isStaff
    ? `mailto:${INE_CONTACT_SALES}`
    : `mailto:${INE_CONTACT_SALES},${account?.sales_contact?.email}`;

  return (
    <CustomCard>
      <h5>Plan Licenses</h5>
      <DataContainer>
        <LicenseDataItem
          count={unlimited ? '∞' : licensesData?.count}
          label="Total"
          tooltip="Total number of plan licenses purchased"
        />
        <LicenseDataItem
          count={licensesData?.used ?? '-'}
          label="Assigned"
          tooltip="Number of plan licenses assigned to active or pending users within the account"
        />
        <LicenseDataItem
          count={unlimited ? '∞' : licensesData?.count - licensesData?.used}
          label="Available"
          tooltip="Number of plan licenses still available to assign"
        />
      </DataContainer>
      <Disclaimer style={{ marginTop: 10 }}>
        Need more licenses? <a href={mailtoUrl}>Contact sales.</a>
      </Disclaimer>
    </CustomCard>
  );
};

export default AccountLicensesCard;
