import '../components/AddContent/LinkItem/styles.scss';

import {
  ButtonWrapper,
  ButtonsWrapper,
  ContentHeaderWrapper,
  ContentTitle,
  ContentTitleWrapper,
  ContentWrapper,
  Description,
  FixedBottomButton,
  GroupItemMainWrapper,
  GroupItemRow,
  GroupItemsContainer,
  GroupItemsEmpty,
  Header,
  MainContent,
  SectionContentWrapper,
  SectionHeader,
  SectionTitle,
  SmallInfo,
  SonarHeaderActivity,
  SonarHeaderInfo,
  SubHeader,
  TabsWrapper,
} from './styles';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { IContentGroup, IPlaylist } from 'contexts/playlists/types';
import { PLAN_ENTERPRISE_PLUS_LAB, PLAN_LAB_EXPERIENCE } from 'constants/plans_config';
import { durationFromSecs, validUUID } from 'utils/helpers';
import { useContext, useEffect, useMemo, useState } from 'react';

import AddContent from '../components/AddContent';
import AddEditCategory from '../components/AddEditSection';
import AssignPlaylistButton from './AssignPlaylistButton';
import AssignUsers from '../components/AssignUsers';
import Button from 'components/button/Button';
import ButtonIcon from 'components/buttonIcon';
import { CONTENT_TYPES } from 'constants/playlists';
import { ConfirmModal } from 'components/modal';
import CourseItemData from './GroupItem/CourseItemData';
import CreatePlaylist from '../components/CreatePlaylist';
import { DurationPill } from 'pages/Playlists/components/PlaylistCard/styles';
import GroupItemImg from './GroupItem/GroupItemImg';
import { INE_URL } from 'constants/domains';
import { INE_UUID } from 'constants/settings';
import Icon from 'components/icons';
import LabItemData from './GroupItem/LabItemData';
import LearningPathDetail from './GroupItem/LearningPath/LearningPathDetail';
import LearningPathItemData from './GroupItem/LearningPath/LearningPathItemData';
import LinkItemData from './GroupItem/LinkItemData';
import Notification from 'components/notification';
import { PLAYLIST_TYPE } from 'constants/playlists';
import PlaylistChangelogModal from './PlaylistChangelog/PlaylistChangelogModal';
import PlaylistsProvider from 'contexts/playlists';
import { SessionContext } from 'contexts/session';
import { Skeleton } from 'antd';
import SonarPlaylistLogo from 'assets/images/sonar-playlist.png';
import { Spinner } from 'components/loadings';
import Tabs from 'components/tabs';
import Tooltip from 'components/tooltip';
import UsersAssigned from '../components/UsersAssigned';
import VideoItemData from './GroupItem/VideoItemData';
import cloneDeep from 'lodash.clonedeep';
import { colors } from 'constants/styles/colors';
import formatDistance from 'date-fns/formatDistance';
import { isMobile } from 'react-device-detect';
import { patchLink } from 'services/links';
import usePlaylistData from 'hooks/usePlaylistData';
import useWindowSize from 'hooks/useWindowSize';

const editPlaylistState = {
  open: false,
  state: { title: null, confirmLabel: null, method: null, playlist: null },
};

const confirmModalDefaultState = {
  open: false,
  onAccept: null,
  description: '',
  acceptLabel: 'Yes',
  cancelLabel: 'No',
};

const PlaylistDetail = (): JSX.Element => {
  const {
    playlist,
    fetching,
    setFetching,
    fetchingUsers,
    playlistUsers,
    refreshPlaylist,
    callEditPlaylist,
    callDeletePlaylist,
    callGetUsers,
    callRemoveUser,
    setPlaylist,
  } = usePlaylistData();

  const isSonar = useMemo(() => {
    return [PLAYLIST_TYPE.SONAR_RECOMMENDED, PLAYLIST_TYPE.SONAR_GOAL].includes(playlist.type);
  }, [playlist]);

  const isSonarRecommended = useMemo(() => {
    return PLAYLIST_TYPE.SONAR_RECOMMENDED === playlist.type;
  }, [playlist]);

  const isSonarGoal = useMemo(() => {
    return PLAYLIST_TYPE.SONAR_GOAL === playlist.type;
  }, [playlist]);

  const [showChangelog, setShowChangelog] = useState(false);

  const tabItems = [
    { name: 'Playlist Content', value: 'content' },
    { name: !isSonar ? 'Playlist Users' : 'Playlist Progress', value: 'users' },
  ];

  const [confirmModalState, setConfirmModalState] = useState(confirmModalDefaultState);
  const [addContentVisible, setAddContentVisible] = useState(false);
  const [addingUsers, setAddingUsers] = useState(false);
  const [dropType, setDropType] = useState('');
  const { isScreenSm, isScreenLg } = useWindowSize();
  const [categoryToAddContent, setCategoryToAddContent] = useState('');
  const [selectedTab, setSelectedTab] = useState(tabItems[0].value);

  const [editingPlaylist, setEditingPlaylist] = useState(editPlaylistState);

  const [addingSection, setAddingSection] = useState(false);

  const [groupToEdit, setGroupToEdit] = useState<IContentGroup | null>(null);

  const [collapsed, setCollapsed] = useState(false);

  const [learningPathCollapsed, setLearningPathCollapsed] = useState(null);

  const sonarBackground = isSonarGoal ? '--goal-green-glass' : '--business-purple-glass';

  const username = useMemo(() => {
    if (!playlist?.created_for) return '';
    const { first_name, last_name } = playlist?.created_for;
    return `${first_name} ${last_name}`;
  }, [playlist]);

  const showItemLink = (groupItem: any) => {
    return isSonar || ['course', 'link', 'lab', 'learning-path'].includes(groupItem.content_type);
  };

  const modifiedAgo = playlist.modified
    ? formatDistance(new Date(playlist.modified), new Date(), {
        addSuffix: true,
      })
    : null;

  const { account } = useContext(SessionContext);
  const business_account_id = account?.id;

  const has_skill_dive =
    account?.pricing_plan === PLAN_LAB_EXPERIENCE ||
    account?.pricing_plan === PLAN_ENTERPRISE_PLUS_LAB ||
    account?.account_available_addons?.filter((ad) => ad.name === CONTENT_TYPES.SKILL_DIVE).length >
      0;

  const checkLabExperience = (content_type: string, is_lab_experience?: boolean) => {
    if (content_type === 'lab' && is_lab_experience) {
      return has_skill_dive;
    }
    return true;
  };

  const generateContentFilteringGroupItem = (course, group) => {
    const normalizedContent = cloneDeep(playlist.content);

    // search for the category and adapt payload
    for (let index = 0; index < normalizedContent.length; index++) {
      const groupElement = normalizedContent[index];
      if (groupElement.name === group.name) {
        groupElement.content = groupElement.content.filter((c) => c.uuid !== course.uuid);
      }
      for (let j = 0; j < groupElement.content.length; j++) {
        const { uuid, content_type } = groupElement.content[j];
        groupElement.content[j] = { uuid, content_type };
      }
    }
    return normalizedContent;
  };

  const generateContentFilteringSection = (group) => {
    let normalizedContent = cloneDeep(playlist.content);

    normalizedContent = normalizedContent.filter((s) => s.name !== group.name);

    for (let index = 0; index < normalizedContent.length; index++) {
      const groupElement = normalizedContent[index];
      for (let j = 0; j < groupElement.content.length; j++) {
        const { uuid, content_type } = groupElement.content[j];

        groupElement.content[j] = { uuid, content_type };
      }
    }

    return normalizedContent;
  };

  const handlePatchLink = async (uuid: string, field: string, value: string) => {
    setFetching(true);
    try {
      const response = await patchLink({ link: { id: uuid, [field]: value }, business_account_id });
      if (response.status === 200) {
        Notification({ text: `${response.data.name} link has been updated`, type: 'success' });
        refreshPlaylist();
        setFetching(false);
      } else {
        Notification({ text: 'There has been a problem editing your link', type: 'error' });
      }
    } catch (error) {
      if (error?.response?.data?.details) {
        Notification({ text: error.response.data.details, type: 'error' });
      }
    }
    setFetching(false);
  };

  const handleAddContentFinished = () => {
    setAddContentVisible(false);
    refreshPlaylist();
  };

  const setIsCreatingCategory = () => {
    setAddingSection(true);
    setGroupToEdit(null);
  };

  const handleAddContent = (group: IContentGroup) => {
    setCategoryToAddContent(group.name);
    setAddContentVisible(true);
  };

  const handleClonePlaylist = () => {
    const clonedPlaylist = { ...playlist };

    clonedPlaylist.name = `Copy of ${clonedPlaylist.name}`;
    delete clonedPlaylist.id;

    setEditingPlaylist({
      open: true,
      state: {
        title: 'Clone playlist',
        confirmLabel: 'Create playlist',
        method: 'create',
        playlist: clonedPlaylist,
      },
    });
  };

  const handleEditPlaylist = () => {
    setEditingPlaylist({
      open: true,
      state: { title: 'Edit playlist', confirmLabel: 'Save changes', method: 'update', playlist },
    });
  };

  const handleEditFinish = (newData?: any) => {
    setEditingPlaylist(editPlaylistState);
    if (newData) {
      refreshPlaylist();
    }
  };

  const handleAddEditSecctionFinish = async (content) => {
    setAddingSection(false);

    const reqData: IPlaylist = {
      id: playlist.id,
      name: playlist.name,
      color: playlist.color,
      content: minimalizeContent(content),
      business_account_id,
      company: INE_UUID, // INE always
    };

    await callEditPlaylist(reqData);
    await refreshPlaylist();
  };

  const handleEditSection = (group) => {
    setGroupToEdit(group);
    setAddingSection(true);
  };

  const handleAddUsersFinish = () => {
    setAddingUsers(false);
  };
  const handleAddUsersConfirm = () => {
    handleAddUsersFinish();
    refreshPlaylist();
  };

  const removeGroupItem = async (groupItem, group: IContentGroup) => {
    const reqData: IPlaylist = {
      id: playlist.id,
      name: playlist.name,
      color: playlist.color,
      content: generateContentFilteringGroupItem(groupItem, group),
      business_account_id,
      company: INE_UUID, // INE always
    };
    await callEditPlaylist(reqData);
    await refreshPlaylist();
  };

  const handleRemoveGroupItem = (groupItem, group: IContentGroup) => {
    setConfirmModalState({
      ...confirmModalDefaultState,
      open: true,
      description: `Are you sure you want to perform this change? There's no way to undo this action.`,
      onAccept: async () => {
        await removeGroupItem(groupItem, group);
        closeConfirmModal();
      },
    });
  };

  const handleOpenGroupItem = (groupItem) => {
    let url;
    switch (groupItem.content_type) {
      case 'course':
        url = `${INE_URL}/course/${groupItem.slug}/${groupItem.uuid}`;
        break;
      case 'link':
        url = groupItem.link_url;
        break;
      case 'lab':
        if (
          !groupItem.metadata ||
          !groupItem.metadata.courses ||
          !groupItem.metadata.courses.length
        ) {
          Notification({ type: 'info', text: 'This lab has no courses associated' });
          break;
        } else {
          // @ToDo Get the parent Course and use the Course slug to build the correct URL
          url = `${INE_URL}/course/${groupItem.lab_type}/${groupItem.metadata.courses[0]}/lab/${groupItem.uuid}`;
          break;
        }
      case 'learning-path':
        url = `${INE_URL}/path/${groupItem.uuid}`;
        break;
      case 'video':
        url = `${INE_URL}/search?search=${groupItem.name}&Content+Type=video`;
        break;
    }
    if (!url) return;
    window.open(url, '_blank');
  };

  const removeSection = async (group: IContentGroup) => {
    const reqData: IPlaylist = {
      id: playlist.id,
      name: playlist.name,
      color: playlist.color,
      content: generateContentFilteringSection(group),
      business_account_id,
      company: INE_UUID, // INE always
    };
    await callEditPlaylist(reqData);
    await refreshPlaylist();
  };

  const handleRemoveSection = async (group: IContentGroup) => {
    setConfirmModalState({
      ...confirmModalDefaultState,
      open: true,
      description: `Are you sure you want to perform this change? There's no way to undo this action.`,
      onAccept: async () => {
        await removeSection(group);
        closeConfirmModal();
      },
    });
  };

  const closeConfirmModal = () => {
    setConfirmModalState(confirmModalDefaultState);
  };

  const handleDeletePlaylist = async () => {
    setConfirmModalState({
      ...confirmModalDefaultState,
      open: true,
      description: `Are you sure you want to perform this change? There's no way to undo this action.`,
      onAccept: async () => {
        await callDeletePlaylist(playlist);
        closeConfirmModal();
      },
    });
  };

  const minimalizeContent = (content) => {
    const minimalizedContent = [];

    for (const group of content) {
      minimalizedContent.push({
        ...group,
        content: group.content.map(({ uuid, content_type }) => ({ uuid, content_type })),
      });
    }

    return minimalizedContent;
  };

  const handleRemoveUser = async (user) => {
    setConfirmModalState({
      ...confirmModalDefaultState,
      open: true,
      description: 'Are you sure you want to unassign this user from the playlist?',
      acceptLabel: 'Unassign user',
      cancelLabel: 'No',
      onAccept: () => {
        callRemoveUser(user);
        closeConfirmModal();
        refreshPlaylist();
      },
    });
  };

  const handleDragStart = async ({ type }) => {
    setDropType(type);
  };

  const generateContentSortingGroupItems = (source, destination) => {
    const clonedContent = cloneDeep(playlist.content);

    const sourceGroupIndex = clonedContent.findIndex(
      ({ name }: { name: string }) => name === source.droppableId
    );

    const sourceGroup = clonedContent[sourceGroupIndex];

    const groupItem = sourceGroup.content.splice(source.index, 1)[0];

    let destinationGroup;

    if (destination.droppableId === source.droppableId) destinationGroup = sourceGroup;
    else {
      const destinationGroupIndex = clonedContent.findIndex(
        ({ name }: { name: string }) => name === destination.droppableId
      );

      destinationGroup = clonedContent[destinationGroupIndex];
    }

    destinationGroup.content.splice(destination.index, 0, groupItem);

    return clonedContent;
  };

  const generateContentSortingGroups = (source, destination) => {
    const clonedContent = cloneDeep(playlist.content);

    const group = clonedContent.splice(source.index, 1)[0];

    clonedContent.splice(destination.index, 0, group);

    return clonedContent;
  };

  const handleDragEnd = async (payload) => {
    setCollapsed(false);
    const { source, destination } = payload;

    if (
      !destination ||
      (source.droppableId === destination.droppableId && source.index === destination.index)
    )
      return;

    const { id, name, description, color } = playlist;

    const contentSorted =
      payload.type === 'group'
        ? generateContentSortingGroups(payload.source, payload.destination)
        : generateContentSortingGroupItems(payload.source, payload.destination);

    setPlaylist((prevState) => ({ ...prevState, content: contentSorted }));

    const reqData: IPlaylist = {
      id,
      name,
      description,
      color,
      content: minimalizeContent(contentSorted),
      business_account_id,
      company: INE_UUID, // INE always
    };

    await callEditPlaylist(reqData);
    await refreshPlaylist();

    setDropType('');
  };

  const handleBeforeDrag = (draggableData) => {
    if (!validUUID(draggableData.draggableId)) setCollapsed(true);
  };

  const handleCollapsed = (id: string, value: boolean) => {
    setLearningPathCollapsed((prevState) => ({
      ...prevState,
      [id]: !value,
    }));
  };

  useEffect(() => {
    refreshPlaylist();
  }, []);

  useEffect(() => {
    if (playlist?.content?.length) {
      let learnings = {};
      playlist.content.forEach((contentGroup) => {
        contentGroup.content.map((c) => {
          if (c.content_type === 'learning-path') {
            learnings = {
              ...learnings,
              [c.uuid]: false,
            };
          }
        });
      });
      setLearningPathCollapsed(learnings);
    }
  }, [playlist.content]);

  useEffect(() => {
    callGetUsers(isSonar);
  }, [playlist.assigned_user_ids]);

  const getContentCount = (length: number) => {
    return length === 1 ? `${length} Item` : `${length} Items`;
  };

  return (
    <PlaylistsProvider>
      <MainContent lock={fetching}>
        <Header isSonar={isSonar} sonarBackground={sonarBackground}>
          {isSonarRecommended && <img src={SonarPlaylistLogo} alt="Sonar Playlist" />}
          {playlist.name}
          {playlist.duration_in_seconds > 0 && (
            <DurationPill bgColor="#1A2535">
              {durationFromSecs(playlist.duration_in_seconds)}
            </DurationPill>
          )}
        </Header>
        <SubHeader>
          {!isSonar ? (
            <Description>{playlist.description}</Description>
          ) : (
            <SonarHeaderInfo>
              {fetchingUsers ? (
                <Skeleton paragraph={{ rows: 0 }} style={{ margin: '-12px 0px -20px' }} />
              ) : (
                <span>Description: {playlist?.description}</span>
              )}
            </SonarHeaderInfo>
          )}
          <ButtonsWrapper>
            <Tooltip placement="top" title="Duplicate playlist">
              <ButtonIcon
                $secondary
                className="playlist__button-icon"
                size="small"
                icon="Duplicate"
                onClick={handleClonePlaylist}
              />
            </Tooltip>
            {!isSonar && (
              <>
                <Tooltip placement="top" title="Edit playlist">
                  <ButtonIcon
                    $secondary
                    className="playlist__button-icon"
                    size="small"
                    icon="Edit"
                    onClick={handleEditPlaylist}
                  />
                </Tooltip>
                <Tooltip placement="top" title="Delete playlist">
                  <ButtonIcon
                    $secondary
                    className="playlist__button-icon"
                    size="small"
                    icon="Trash"
                    onClick={handleDeletePlaylist}
                  />
                </Tooltip>
              </>
            )}
            {isSonar && !playlist.assigned_user_ids?.length && (
              <AssignPlaylistButton
                playlist={playlist}
                setFetching={setFetching}
                refreshPlaylist={refreshPlaylist}
              />
            )}
          </ButtonsWrapper>
        </SubHeader>

        <TabsWrapper>
          <Tabs
            items={tabItems}
            selected={selectedTab}
            $flex={!isScreenSm ? 'fill' : null}
            onTabChange={(tab) => setSelectedTab(tab)}
          />
          {!isSonar && (
            <FixedBottomButton onClick={setIsCreatingCategory} size={'small'} icon={'Plus'}>
              Add section
            </FixedBottomButton>
          )}
        </TabsWrapper>
        {isScreenLg || selectedTab === 'content' ? (
          <ContentWrapper>
            <ContentHeaderWrapper>
              <ContentTitleWrapper isSonar={isSonar}>
                <ContentTitle>Playlist Content</ContentTitle>
                {!isSonar ? (
                  <Button onClick={setIsCreatingCategory} icon={'Plus'}>
                    Add section
                  </Button>
                ) : (
                  isSonarRecommended && (
                    <SonarHeaderInfo>
                      {fetchingUsers ? (
                        <Skeleton paragraph={{ rows: 0 }} style={{ margin: '-12px 0' }} />
                      ) : (
                        <>
                          As <b style={{ color: 'var(--black-dark)' }}>{username}</b> completes
                          Skill Sonar assessments, the content in this playlist will update
                          automatically.
                        </>
                      )}
                    </SonarHeaderInfo>
                  )
                )}
              </ContentTitleWrapper>
              {isSonar && (
                <SonarHeaderActivity>
                  Last modified <b> {modifiedAgo}</b>.
                  <span
                    onClick={() => {
                      setShowChangelog(true);
                    }}
                  >
                    See change log
                  </span>
                </SonarHeaderActivity>
              )}
            </ContentHeaderWrapper>
            <DragDropContext
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
              onBeforeCapture={handleBeforeDrag}
            >
              <Droppable
                droppableId="main-content"
                type="group"
                isDropDisabled={dropType !== 'group'}
              >
                {(droppableProvided) => (
                  <div
                    className="group-list"
                    ref={droppableProvided.innerRef}
                    {...droppableProvided.droppableProps}
                  >
                    {playlist.content && playlist.content.length
                      ? playlist.content.map((group, index) => (
                          <Draggable
                            key={group.name}
                            draggableId={group.name}
                            index={index}
                            isDragDisabled={isSonar}
                          >
                            {(draggableProvided) => (
                              <SectionContentWrapper
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                              >
                                <SectionHeader>
                                  <div>
                                    <SectionTitle>
                                      {group.name}
                                      {!isSonar && (
                                        <ButtonWrapper onClick={() => handleEditSection(group)}>
                                          <Icon
                                            size="small"
                                            icon="Edit"
                                            color={colors.buttons.darkPurple}
                                          />
                                        </ButtonWrapper>
                                      )}
                                    </SectionTitle>
                                    <SmallInfo>{getContentCount(group.content.length)}</SmallInfo>
                                  </div>

                                  <ButtonsWrapper gap={20}>
                                    {!isSonar && isScreenSm && (
                                      <Button
                                        $secondary
                                        onClick={() => handleAddContent(group)}
                                        icon={'Plus'}
                                      >
                                        Add content
                                      </Button>
                                    )}
                                    {!isSonar && (
                                      <ButtonIcon
                                        $secondary
                                        size="small"
                                        icon="Trash"
                                        onClick={() => handleRemoveSection(group)}
                                      />
                                    )}

                                    <div {...draggableProvided.dragHandleProps}>
                                      <Icon
                                        icon="HamburgerMenu"
                                        color={colors.fonts.darkGray}
                                        size="large"
                                        style={{ display: isSonar ? 'none' : 'block' }}
                                      />
                                    </div>
                                  </ButtonsWrapper>
                                </SectionHeader>
                                {!collapsed && (
                                  <Droppable
                                    droppableId={group.name}
                                    type="group-item"
                                    isDropDisabled={dropType !== 'group-item'}
                                  >
                                    {(droppableGroupItemProvided, droppableGroupItemSnapshot) => (
                                      <GroupItemsContainer
                                        isDraggingOver={droppableGroupItemSnapshot.isDraggingOver}
                                        isEmpty={!group.content.length}
                                        ref={droppableGroupItemProvided.innerRef}
                                        {...droppableGroupItemProvided.droppableProps}
                                      >
                                        {group.content?.length
                                          ? group.content.map((groupItem, index) => (
                                              <Draggable
                                                key={groupItem.uuid}
                                                draggableId={groupItem.uuid}
                                                index={index}
                                                isDragDisabled={isSonar}
                                              >
                                                {(draggableGroupItemProvided) => (
                                                  <>
                                                    <GroupItemRow
                                                      ref={draggableGroupItemProvided.innerRef}
                                                      {...draggableGroupItemProvided.draggableProps}
                                                    >
                                                      <GroupItemImg
                                                        image={
                                                          groupItem.images?.small_image_url ||
                                                          groupItem.logo_image_url
                                                        }
                                                        content_type={groupItem.content_type}
                                                        disabled={
                                                          groupItem.is_lab_experience &&
                                                          !has_skill_dive
                                                        }
                                                        onClick={() =>
                                                          handleOpenGroupItem(groupItem)
                                                        }
                                                      />
                                                      <GroupItemMainWrapper>
                                                        {groupItem.content_type === 'course' && (
                                                          <CourseItemData data={groupItem} />
                                                        )}
                                                        {groupItem.content_type === 'link' && (
                                                          <LinkItemData
                                                            data={groupItem}
                                                            onSave={handlePatchLink}
                                                          />
                                                        )}
                                                        {groupItem.content_type === 'video' && (
                                                          <VideoItemData data={groupItem} />
                                                        )}
                                                        {groupItem.content_type === 'lab' && (
                                                          <LabItemData data={groupItem} />
                                                        )}
                                                        {groupItem.content_type ===
                                                          'learning-path' && (
                                                          <LearningPathItemData
                                                            data={groupItem}
                                                            toggleDetails={() =>
                                                              handleCollapsed(
                                                                groupItem.uuid,
                                                                learningPathCollapsed[
                                                                  groupItem.uuid
                                                                ]
                                                              )
                                                            }
                                                          />
                                                        )}
                                                        <ButtonsWrapper>
                                                          {groupItem.content_type ===
                                                            'learning-path' &&
                                                            !isSonar &&
                                                            !isMobile && (
                                                              <ButtonIcon
                                                                $secondary
                                                                size="small"
                                                                icon={
                                                                  learningPathCollapsed &&
                                                                  learningPathCollapsed[
                                                                    groupItem.uuid
                                                                  ]
                                                                    ? 'ChevronUp'
                                                                    : 'ChevronDown'
                                                                }
                                                                onClick={() =>
                                                                  handleCollapsed(
                                                                    groupItem.uuid,
                                                                    learningPathCollapsed[
                                                                      groupItem.uuid
                                                                    ]
                                                                  )
                                                                }
                                                              />
                                                            )}
                                                          {showItemLink(groupItem) &&
                                                            checkLabExperience(
                                                              groupItem.content_type,
                                                              groupItem.is_lab_experience
                                                            ) && (
                                                              <ButtonIcon
                                                                $secondary
                                                                size="small"
                                                                icon="ExternalLink"
                                                                onClick={() =>
                                                                  handleOpenGroupItem(groupItem)
                                                                }
                                                              />
                                                            )}
                                                          {!isSonar && (
                                                            <>
                                                              <ButtonIcon
                                                                $secondary
                                                                size="small"
                                                                icon="Trash"
                                                                onClick={() =>
                                                                  handleRemoveGroupItem(
                                                                    groupItem,
                                                                    group
                                                                  )
                                                                }
                                                              />

                                                              <div
                                                                {...draggableGroupItemProvided.dragHandleProps}
                                                              >
                                                                <Icon
                                                                  icon="HamburgerMenu"
                                                                  color={colors.fonts.darkGray}
                                                                  size="large"
                                                                />
                                                              </div>
                                                            </>
                                                          )}
                                                        </ButtonsWrapper>
                                                      </GroupItemMainWrapper>
                                                    </GroupItemRow>
                                                    {learningPathCollapsed &&
                                                      learningPathCollapsed[groupItem.uuid] && (
                                                        <LearningPathDetail data={groupItem} />
                                                      )}
                                                  </>
                                                )}
                                              </Draggable>
                                            ))
                                          : !isSonar && (
                                              <GroupItemsEmpty>
                                                <span onClick={() => handleAddContent(group)}>
                                                  Add
                                                </span>{' '}
                                                or drag and drop content here
                                              </GroupItemsEmpty>
                                            )}

                                        {!isScreenSm && !isSonar && (
                                          <Button
                                            $full
                                            $secondary
                                            size="small"
                                            onClick={() => handleAddContent(group)}
                                            icon={'Plus'}
                                          >
                                            Add content
                                          </Button>
                                        )}
                                      </GroupItemsContainer>
                                    )}
                                  </Droppable>
                                )}
                              </SectionContentWrapper>
                            )}
                          </Draggable>
                        ))
                      : null}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </ContentWrapper>
        ) : null}
        {isScreenLg || selectedTab === 'users' ? (
          <ContentWrapper>
            {!isSonar && isScreenLg && (
              <ContentTitleWrapper isSonar={isSonar}>
                <ContentTitle>Playlist Users</ContentTitle>
                <Button $secondary onClick={() => setAddingUsers(true)} icon={'Plus'}>
                  Add users
                </Button>
              </ContentTitleWrapper>
            )}
            {playlist?.id ? (
              <UsersAssigned
                lock={fetching}
                playlistUsers={playlistUsers}
                playlist={playlist}
                fetchingUsers={fetchingUsers}
                isSonar={isSonar}
                callRemoveUser={(user) => handleRemoveUser(user)}
                setFetching={setFetching}
                refreshPlaylist={refreshPlaylist}
              />
            ) : null}
          </ContentWrapper>
        ) : null}
        {editingPlaylist.open && (
          <CreatePlaylist
            {...editingPlaylist.state}
            isVisible={editingPlaylist.open}
            onClose={(data) => handleEditFinish(data)}
          />
        )}
        {!!addContentVisible && (
          <AddContent
            playlist={playlist}
            category={categoryToAddContent}
            isVisible={addContentVisible}
            onClose={() => handleAddContentFinished()}
          />
        )}
        {!!addingUsers && (
          <AssignUsers
            playlistUsers={playlistUsers}
            isVisible={addingUsers}
            playlist={playlist}
            onCancel={handleAddUsersFinish}
            onConfirm={handleAddUsersConfirm}
          />
        )}
        {!!addingSection && (
          <AddEditCategory
            isVisible={addingSection}
            playlist={playlist}
            group={groupToEdit}
            onClose={() => setAddingSection(false)}
            onConfirm={handleAddEditSecctionFinish}
          />
        )}
        <ConfirmModal {...confirmModalState} title="Are you sure?" onCancel={closeConfirmModal} />
        {fetching && <Spinner active={fetching} top={'250'} />}
        {showChangelog && (
          <PlaylistChangelogModal
            isVisible={showChangelog}
            playlist={playlist}
            userName={username}
            onClose={() => {
              setShowChangelog(false);
            }}
          />
        )}
      </MainContent>
    </PlaylistsProvider>
  );
};

export default PlaylistDetail;
