import { createContext } from 'react';
import useNotesData from 'hooks/useNotesData';
import { FCProps } from 'types/FCProps';
import { NotesDataCtx } from './types';
import { notesInitialValues } from './utils';

export const NotesContext = createContext<NotesDataCtx>(notesInitialValues);

const NotesProvider = ({ children }: FCProps): JSX.Element => {
  const {
    fetching,
    notesTable,
    isEmpty,
    filters,
    noteUsers,
    onChangeFilter,
    onChangeFilters,
    onChangeRangeFilters,
    onNotesRefresh,
    onDeleteNote,
    onRefreshNoteUsers,
  } = useNotesData();

  return (
    <NotesContext.Provider
      value={{
        fetching,
        isEmpty,
        table: notesTable,
        filters,
        noteUsers,
        actions: {
          onChangeFilter,
          onChangeFilters,
          onChangeRangeFilters,
          onNotesRefresh,
          onDeleteNote,
          onRefreshNoteUsers,
        },
      }}
    >
      {children}
    </NotesContext.Provider>
  );
};

export default NotesProvider;
