import { UsersDataCtx, UsersStats } from './types';

export const statsInitialValues: UsersStats = {
  active_user: {
    total: 0,
    variation: 0,
  },
  pending_users: {
    total: 0,
    variation: 0,
  },
  quizzes_completed: {
    total: 0,
    variation: 0,
  },
  videos_watched: {
    total: 0,
    variation: 0,
  },
};

export const usersInitialValues: UsersDataCtx = {
  table: {
    rows: null,
    pageSize: 8,
    page: 1,
    loading: false,
    count: 0,
    error: 'false',
  },
  filters: {
    license: [],
    team: [],
    roles: [],
    active: 'true',
    page: '1',
    page_size: '9',
    search: '',
    accounts: [],
    ordering: '',
  },
  usersCounts: {},
};
