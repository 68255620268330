import React from 'react';
import styled from 'styled-components/';
import SpinnerIMG from 'assets/images/spinner.png';

const SpinnerContainer = styled.div<{ top?: string; left?: string; position?: string }>`
  position: ${({ position = 'absolute' }) => position};
  left: ${({ left = '50%' }) => left};
  top: ${({ top = '35%' }) => top};
  transform-origin: top left;
  transform: translate(-50%, -50%);
`;

export const Spin = styled.img<{ size?: string }>`
  width: ${({ size = 76 }) => `${size}px`};
  height: ${({ size = 76 }) => `${size}px`};
  position: absolute;
  left: 50%;
  top: 50%;
  transform-origin: top left;
  transform: translate(-50%, -50%);
  animation: spinner 1.2s linear infinite;

  @keyframes spinner {
    0% {
      transform: rotate(0deg) translate(-50%, -50%);
    }
    100% {
      transform: rotate(360deg) translate(-50%, -50%);
    }
  }
`;

const Spinner = ({
  active,
  top,
  left,
  position,
  size,
  zIndex,
}: {
  active: boolean;
  top?: string;
  left?: string;
  position?: string;
  size?: string;
  zIndex?: string;
}): JSX.Element => {
  if (active) {
    return (
      <SpinnerContainer
        top={top}
        left={left}
        position={position}
        style={{
          ...(zIndex ? { zIndex: 10 } : {}),
        }}
      >
        <Spin src={SpinnerIMG} size={size} />
      </SpinnerContainer>
    );
  } else return null;
};

export default Spinner;
