import { AxiosResponse } from 'axios';
import { config } from 'config/config.local';
import { REPORTING } from './axios';
import {
  AnalyticsUserActiveTotalParams,
  AnalyticsUserTableParams,
  PreferredContentParams,
} from 'contexts/analytics/types';

export const getUsersTable = (
  params: AnalyticsUserTableParams
): Promise<AxiosResponse<unknown>> => {
  return REPORTING.get(config.routes.analytics.usersTable, { params });
};
export const getTeamsTable = (
  params: AnalyticsUserTableParams
): Promise<AxiosResponse<unknown>> => {
  return REPORTING.get(config.routes.analytics.teamsTable, { params });
};
export const getCoursesTable = (
  params: AnalyticsUserTableParams
): Promise<AxiosResponse<unknown>> => {
  return REPORTING.get(config.routes.analytics.coursesTable, { params });
};
export const getSeries = (params: AnalyticsUserTableParams): Promise<AxiosResponse<unknown>> => {
  return REPORTING.get(config.routes.analytics.series, { params });
};
export const getAgregation = (
  params: AnalyticsUserTableParams
): Promise<AxiosResponse<unknown>> => {
  return REPORTING.get(config.routes.analytics.aggregation, { params });
};
export const getActiveUsers = (
  params: AnalyticsUserTableParams
): Promise<AxiosResponse<unknown>> => {
  return REPORTING.get(config.routes.analytics.activeUsers, { params });
};

export const getLabs = (params: AnalyticsUserTableParams): Promise<AxiosResponse<unknown>> => {
  return REPORTING.get(config.routes.analytics.labs.series, { params });
};

export const getPreferredContent = (
  params: PreferredContentParams
): Promise<AxiosResponse<unknown>> => {
  return REPORTING.get(config.routes.analytics.preferredContent, { params });
};

export const getUsersQuizzTable = (
  params: AnalyticsUserTableParams
): Promise<AxiosResponse<unknown>> => {
  return REPORTING.get(config.routes.analytics.quizzes.usersQuizzTable, { params });
};

export const getUsersLabsTable = (
  params: AnalyticsUserTableParams
): Promise<AxiosResponse<unknown>> => {
  return REPORTING.get(config.routes.analytics.labs.usersTable, { params });
};

export const getUsersWithActivityUsersTable = (
  params: AnalyticsUserTableParams
): Promise<AxiosResponse<unknown>> => {
  return REPORTING.get(config.routes.analytics.users.usersWithActivity, { params });
};

export const getUsersWithActivityTotal = (
  params: AnalyticsUserActiveTotalParams
): Promise<AxiosResponse<unknown>> => {
  return REPORTING.get(config.routes.analytics.users.usersWithActivityTotal, { params });
};

export const getUsersWithActivityActivitiesTable = (
  params: AnalyticsUserTableParams
): Promise<AxiosResponse<unknown>> => {
  return REPORTING.get(config.routes.analytics.activities.usersWithActivity, { params });
};
