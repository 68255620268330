import React, { useContext, useEffect, useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { Space } from 'antd';
import { isDesktop, isMobile } from 'react-device-detect';
import cloneDeep from 'lodash.clonedeep';
import { AvatarLetter } from 'components/avatar';
import Icon from 'components/icons';
import { INE_CONTACT_SUPPORT } from 'constants/settings';
import Switch from 'components/switch/Switch';
import Tooltip from 'components/tooltip';
import {
  AdviseContainer,
  AdviseInfo,
  BodyTitle,
  Container,
  DeleteButton,
  EditContainer,
  EditFieldsLabel,
  HeaderRowContainer,
  LabelEmail,
  RowContainer,
  SwitchContainer,
  TitleContainer,
  UserTeamContainer,
} from './styles';
import Button from 'components/button';
import { SessionContext } from 'contexts/session';
import { Invite } from '../AddByEmails/invitesReducer';
import ButtonIcon from 'components/buttonIcon';
import { AccountInvite } from '..';
import { UserTeam } from 'contexts/teams/types';
import { UserTeamsPayload } from 'components/usersTable/types';
import LittleTeamsCard from 'components/cards/LittleTeamsCard';
import TeamSelectorMobile from 'components/TeamSelectMobile';

interface IAdjustUsersSettings {
  footer?: boolean;
  invites?: Invite[];
  activeUserInvites?: Invite[];
  onInvitesUpdate?: (invites: Invite[]) => void;
  onEdit: (open: boolean) => void;
  isEditing: boolean;
  onInviteRemove: (id: string) => void;
  hasTeam: boolean;
  onLicenseExcess?: (usedLicenses: number) => void;
  account: AccountInvite;
  inviteAccOwner?: boolean;
}

const Advise: React.FC = () => (
  <AdviseContainer>
    <Icon stroke="#F28815" icon="AlertCircle" />
    <div className={'advice-inner-container'}>
      <AdviseInfo>
        <span>
          <b>You have assigned all of your available licenses.</b>
        </span>
        <p className="m-0">
          You can still invite users without a license. However, they will not have access to
          training content.
        </p>
      </AdviseInfo>
      <Button
        $secondary
        onClick={() => {
          window.location.href = `mailto:${INE_CONTACT_SUPPORT}`;
        }}
      >
        Get more licenses
      </Button>
    </div>
  </AdviseContainer>
);

const EditInvite: React.FC<{
  footer: boolean;
  email: string;
  onInviteUpdate: ({ data, inviteEmail }) => void;
  onCancelInvite: () => void;
  onRemoveInvite: (email: string) => void;
  isManager: boolean;
  hasLicense: boolean;
  userTeams: UserTeam[];
  licenses_available: number | boolean;
}> = ({
  footer,
  email,
  onCancelInvite,
  onInviteUpdate,
  onRemoveInvite,
  hasLicense,
  isManager,
  userTeams,
  licenses_available,
}) => {
  const { teams: accountTeams } = useContext(SessionContext);
  const [data, setData] = useState<{
    userTeams?: UserTeam[];
    has_license?: boolean;
    is_manager?: boolean;
  }>({
    userTeams,
    has_license: hasLicense,
    is_manager: isManager,
  });
  const handleChange = (value: any, type: string) => {
    setData((prevState) => {
      return {
        ...prevState,
        [type]: value,
      };
    });
  };

  const handleCancel = () => {
    onCancelInvite();
  };
  const handleUpdateInvite = () => {
    onInviteUpdate({ data, inviteEmail: email });
  };

  const handleSwitchManager = (teamsData: UserTeamsPayload[]) => {
    const userTeamIndex = data.userTeams.findIndex(
      ({ team_id }) => team_id === teamsData[0].team_id
    );
    const updatedUserTeams = [...data.userTeams];
    updatedUserTeams[userTeamIndex].is_manager = teamsData[0].is_manager;
    setData((prevState) => {
      return {
        ...prevState,
        userTeams: updatedUserTeams,
      };
    });
  };

  const onSelectTeam = (teamIds: string[]) => {
    const newUserTeams = [];
    teamIds.forEach((ti) => {
      const userTeam = accountTeams.find((t) => t.id === ti);
      newUserTeams.push({ name: userTeam.name, team_id: ti, is_manager: false });
    });
    setData((prevState) => {
      return {
        ...prevState,
        userTeams: newUserTeams,
      };
    });
  };

  return (
    <EditContainer>
      <div className={'invite-info'}>
        <div style={{ marginRight: '15px' }}>
          <AvatarLetter name={email} />
        </div>
        <LabelEmail>{email}</LabelEmail>
        <ButtonIcon
          $terciary
          size={'small'}
          icon={'Trash'}
          style={{ margin: 'auto' }}
          onClick={() => onRemoveInvite(email)}
        />
      </div>
      <div className={'invite-switchs'}>
        <div>
          <EditFieldsLabel>License</EditFieldsLabel>
          <Switch
            onChange={(checked: boolean) => handleChange(checked, 'has_license')}
            disabled={licenses_available <= 0 && !hasLicense}
            defaultChecked={hasLicense}
          />
        </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
        <LittleTeamsCard
          width="85"
          teams={data.userTeams}
          onSwitchManager={handleSwitchManager}
          disableManager={false}
          flat={false}
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '15px',
            marginLeft: '10px',
          }}
        >
          <TeamSelectorMobile
            accountTeams={accountTeams}
            teamsSelected={userTeams}
            onTeamChange={(teamIds) => onSelectTeam(teamIds)}
          />
        </div>
      </div>
      {footer && (
        <div className={'invite-edit-footer'}>
          <Button $secondary onClick={handleCancel}>
            Cancel
          </Button>
          <Button $primary onClick={handleUpdateInvite}>
            Save
          </Button>
        </div>
      )}
    </EditContainer>
  );
};

const AdjustUsersSettings: React.FC<IAdjustUsersSettings> = ({
  invites = [],
  activeUserInvites = [],
  onInvitesUpdate,
  onEdit,
  isEditing,
  onInviteRemove,
  hasTeam,
  account,
  inviteAccOwner,
}) => {
  const allInvites = [...invites, ...activeUserInvites];
  const invitesWithPrevLicenses = invites.filter(({ has_license }) => has_license).length;
  const [usedLicenses, setUsedLicenses] = useState(invitesWithPrevLicenses);
  const licensesRemaining =
    typeof account.licenses_available === 'number'
      ? account.licenses_available - usedLicenses
      : '∞';
  const [inviteEdit, setInviteEdit] = useState<Invite>(null);

  const handleRemoveInvite = (toRemove: string) => {
    onInviteRemove(toRemove);
    onEdit(false);
  };

  const handleSwitch = ({ checked, inviteEmail, type }) => {
    const inviteIndex = allInvites.findIndex(({ email }) => email === inviteEmail);
    const newInvites = [...allInvites];
    newInvites[inviteIndex][type] = checked;
    onInvitesUpdate(newInvites);
  };

  const handleManagerSwitch = ({ checked, inviteEmail, teamId }) => {
    const inviteToUpdateIndex = allInvites.findIndex(({ email }) => email === inviteEmail);
    const inviteToUpdate = cloneDeep(allInvites.find(({ email }) => email === inviteEmail));
    const teamToUpdate = inviteToUpdate.userTeams.find((ut) => ut.team.id === teamId);
    teamToUpdate.is_manager = checked;
    const newInvites = cloneDeep(allInvites);
    newInvites[inviteToUpdateIndex] = inviteToUpdate;
    onInvitesUpdate(newInvites);
  };

  const handleRowClick = (selected: string) => {
    if (isMobile) {
      onEdit(true);
      setInviteEdit(allInvites.filter(({ email }) => email === selected)?.[0]);
    }
  };

  const handleUpdateEdit = ({ inviteEmail, data: { userTeams, has_license } }) => {
    const inviteIndex = allInvites.findIndex(({ email }) => email === inviteEmail);
    const newInvites = [...allInvites];
    newInvites[inviteIndex] = {
      ...newInvites[inviteIndex],
      userTeams: userTeams,
      teams: userTeams.reduce((acc, userTeam) => {
        acc.push({ team_id: userTeam.team_id, is_manager: userTeam.is_manager });
        return acc;
      }, []),
      has_license: has_license || false,
    };
    onInvitesUpdate(newInvites);
    onEdit(false);
  };

  const updateUsedLicenses = (checked) => {
    if (licensesRemaining === '∞') return;
    const newUsedLicenses = checked ? usedLicenses - 1 : usedLicenses + 1;
    setUsedLicenses(newUsedLicenses);
  };

  useEffect(() => {
    setUsedLicenses(invites.filter(({ has_license }) => has_license).length);
  }, [allInvites]);

  return (
    <>
      <TitleContainer>
        <BodyTitle>Adjust users settings</BodyTitle>
        {isDesktop && (
          <div>
            <span>Licenses available: </span>
            <b style={{ color: typeof licensesRemaining === 'number' && '#F28815' }}>
              {licensesRemaining}
            </b>
          </div>
        )}
      </TitleContainer>
      {!(typeof account.licenses_available === 'boolean' && account.licenses_available === true) &&
        account.licenses_available < allInvites.length &&
        !isEditing && <Advise />}
      {!isEditing && (
        <Container>
          {!isMobile && (
            <HeaderRowContainer>
              <span></span>
              <span>{hasTeam && 'TEAM'}</span>
              <span>{inviteAccOwner ? 'OWNER' : hasTeam && 'MANAGER'}</span>
              <span>LICENSE</span>
            </HeaderRowContainer>
          )}
          <Space direction={'vertical'} style={{ width: '100%' }}>
            {allInvites.map(({ email, has_license, is_account_owner, userTeams }) => (
              <RowContainer key={email} onClick={() => handleRowClick(email)}>
                <div style={{ marginRight: '15px' }}>
                  <AvatarLetter name={email} />
                </div>
                <LabelEmail>{email}</LabelEmail>
                <div>
                  {hasTeam &&
                    userTeams.map((userTeam, index) => (
                      <UserTeamContainer key={index} borderBottom={index < userTeams.length - 1}>
                        <div>{userTeam?.team?.name || userTeam?.name}</div>
                        <Switch
                          disabled={is_account_owner}
                          defaultChecked={userTeam.is_manager}
                          onChange={(checked) =>
                            handleManagerSwitch({
                              checked,
                              inviteEmail: email,
                              teamId: userTeam?.team?.id || userTeam?.team_id,
                            })
                          }
                        />
                      </UserTeamContainer>
                    ))}
                  {inviteAccOwner && (
                    <SwitchContainer style={{ justifyContent: 'flex-end', marginRight: '5%' }}>
                      <Switch defaultChecked={is_account_owner} disabled />
                    </SwitchContainer>
                  )}
                </div>
                <div className={'actions-container'}>
                  {isDesktop && (
                    <>
                      <SwitchContainer>
                        <Tooltip
                          placement="bottom"
                          title={
                            !has_license && !licensesRemaining ? (
                              <p className="m-0 text-center text-nowrap">
                                You have assigned all of your available licenses. <br />
                                Contact INE to get more.
                              </p>
                            ) : null
                          }
                          zIndex={9999}
                        >
                          <div>
                            <Switch
                              defaultChecked={has_license}
                              disabled={!has_license && !licensesRemaining}
                              onChange={(checked) => {
                                handleSwitch({ checked, inviteEmail: email, type: 'has_license' });
                                updateUsedLicenses(checked);
                              }}
                            />
                          </div>
                        </Tooltip>
                      </SwitchContainer>
                      <DeleteButton onClick={() => handleRemoveInvite(email)}>
                        <Icon icon="CircledClose" size="medium" />
                      </DeleteButton>
                    </>
                  )}
                  {isMobile && <FeatherIcon icon={'chevron-right'} color={'#A793F6'} />}
                </div>
              </RowContainer>
            ))}
          </Space>
        </Container>
      )}
      {isEditing && (
        <EditInvite
          footer={true}
          onInviteUpdate={handleUpdateEdit}
          onCancelInvite={() => onEdit(false)}
          onRemoveInvite={handleRemoveInvite}
          email={inviteEdit.email}
          userTeams={inviteEdit.userTeams}
          hasLicense={inviteEdit.has_license}
          isManager={inviteEdit.is_manager}
          licenses_available={account.licenses_available}
        />
      )}
    </>
  );
};

export default AdjustUsersSettings;
