import styled from 'styled-components/macro';

export const Container = styled.div`
  align-items: center;
  background: #e7efee;
  border-radius: 6px;
  display: flex;
  min-height: 50px;
  justify-content: center;
  margin-right: 15px;
  min-width: 50px;
`;

export const StyledFirstLetterEmail = styled.div`
  color: #65aaa3;
  font-family: Circular-Std;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: center;
`;

const FirstLetterEmail: React.FC<{ email: string }> = ({ email }) => (
  <Container>
    {email && <StyledFirstLetterEmail>{email.toUpperCase().charAt(0)}</StyledFirstLetterEmail>}
  </Container>
);

export default FirstLetterEmail;
