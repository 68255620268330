import { Col, Row, Space } from 'antd';
import { useEffect, useState } from 'react';
import { Label } from 'components/modal/styles';
import PlanType from './PlanType';
import Checkbox from 'components/checkbox/Checkbox';
import NumericInput from 'components/inputs/inputNumber';
import { Addon } from 'contexts/pricingPlans/types';
import { AccountAddonPayload } from 'services/accounts';
import { AccountAddon } from 'contexts/accounts/types';

interface AccountAddonsSelectorProps {
  onChange(addonSelected: AccountAddonPayload[]): void;
  licensesSelected: number;
  unlimited: boolean;
  addons: Addon[];
  accountAddons: AccountAddon[];
}

const AccountAddonsSelector = ({
  onChange,
  licensesSelected,
  unlimited,
  addons,
  accountAddons,
}: AccountAddonsSelectorProps): JSX.Element => {
  const [maxValue, setMaxValue] = useState<number>(0);
  const [addonsSelected, setAddonsSelected] = useState<{ id: string; count: number }[]>([]);
  const [addonsChecked, setAddonsChecked] = useState<string[]>([]);

  useEffect(() => {
    if (licensesSelected && licensesSelected > 0) {
      setMaxValue(licensesSelected);
    } else {
      setMaxValue(unlimited ? Number.MAX_SAFE_INTEGER : 0);
    }
    if (addonsSelected.length > 0 && accountAddons && accountAddons.length === 0) {
      setAddonsSelected(
        addonsSelected.map((addon) => ({
          id: addon.id,
          count: unlimited ? 1 : addon.count,
        }))
      );
    }
  }, [licensesSelected]);

  useEffect(() => {
    onChange(addonsSelected);
  }, [addonsSelected]);

  useEffect(() => {
    if (accountAddons && accountAddons.length > 0) {
      const addonsSelected = accountAddons.map((addon) => ({ id: addon.id, count: addon.count }));
      const addonsChecked = accountAddons.map((addon) => addon.id);
      setAddonsSelected(addonsSelected);
      setAddonsChecked(addonsChecked);
    }
  }, [accountAddons]);

  const onChangeCheck = (addonId: string) => {
    const isChecked = addonsSelected.find((addon) => addon.id === addonId);
    if (isChecked) {
      setAddonsSelected(addonsSelected.filter((addon) => addon.id !== addonId));
      setAddonsChecked(addonsChecked.filter((id) => id !== addonId));
    } else {
      setAddonsSelected([
        ...addonsSelected,
        {
          id: addonId,
          count: accountAddons
            ? accountAddons.find((addon) => addon.id === addonId)?.count
            : licensesSelected
            ? licensesSelected
            : 1,
        },
      ]);
      setAddonsChecked([...addonsChecked, addonId]);
    }
  };

  return (
    <Row>
      <Col span={24}>
        <Label>Add-ons</Label>
      </Col>
      <Row>
        {addons.map((addon) => (
          <Col span={24} key={addon.id}>
            <Row>
              <Space size={10}>
                <Checkbox
                  onChange={() => onChangeCheck(addon.id)}
                  controlled
                  checked={addonsChecked.includes(addon.id)}
                />
                <PlanType
                  error={
                    !unlimited &&
                    addonsSelected.find((ad) => ad.id === addon.id)?.count > licensesSelected
                  }
                  type={addon.code}
                  active={addonsChecked.includes(addon.id)}
                  onClick={() => onChangeCheck(addon.id)}
                />
                {addonsSelected.find((ad) => ad.id === addon.id) && (
                  <div>
                    <NumericInput
                      error={
                        !unlimited &&
                        addonsSelected.find((ad) => ad.id === addon.id)?.count > licensesSelected
                      }
                      unlimited={false}
                      minValue={0}
                      maxValue={maxValue}
                      value={addonsSelected.find((ad) => ad.id === addon.id)?.count}
                      onChange={(value) => setAddonsSelected([{ id: addon.id, count: value }])}
                      placeholder="0"
                    />
                  </div>
                )}
              </Space>
            </Row>
          </Col>
        ))}
      </Row>
    </Row>
  );
};

export default AccountAddonsSelector;
