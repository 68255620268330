/**
 * `Micro Layout Container`
 * @param {ReactNode} children optionals components.
 * @returns {JSX.Element} Component wrapper.
 */

import { ReactNode } from 'react';
import { Drawer, DrawerProps } from 'antd';

interface Props extends DrawerProps {
  closable?: boolean;
  children: ReactNode;
}

const ModalDrawer = ({
  placement,
  closable,
  visible,
  onClose,
  children,
  height,
}: Props): JSX.Element => (
  <Drawer
    destroyOnClose={true}
    closable={closable}
    placement={placement}
    visible={visible}
    onClose={onClose}
    height={height}
    contentWrapperStyle={{ boxShadow: 'none', borderTop: '4px solid #F2F2F2' }}
    maskStyle={{ opacity: 0, background: 'transparent' }}
    style={{ backgroundColor: 'red !important' }}
  >
    {children}
  </Drawer>
);

export default ModalDrawer;
