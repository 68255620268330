import { useContext } from 'react';
import { isMobile } from 'react-device-detect';
import { FilterProps } from './types';
import Checkbox from 'components/checkbox';
import { FilterCount, FilterWrapper } from './styles';
import { UsersContext } from 'contexts/users';

const UsersAsideFiltersRoles = ({ onFilterChange, filter = [] }: FilterProps): JSX.Element => {
  const { usersCounts, filters } = useContext(UsersContext);
  const active_count = usersCounts?.active_count;

  return (
    <>
      <h3 className="mt-16 mb-5 fs-1 card-title">Role</h3>
      <div className="users-aside-filters">
        <FilterWrapper>
          <Checkbox
            value="all_roles"
            id="all_roles"
            name="roles"
            onChange={onFilterChange}
            defaultChecked={!filter.length}
            checked={!filter.length}
            label="All users"
            controlled={!isMobile}
          />
          <FilterCount>
            {filters.active === 'true' ? active_count?.active : active_count?.pending}
          </FilterCount>
        </FilterWrapper>
        <FilterWrapper>
          <Checkbox
            value="account_owner"
            id="account_owner"
            name="roles"
            onChange={onFilterChange}
            defaultChecked={filter.includes('account_owner')}
            checked={filter.includes('account_owner')}
            label="Account owner"
            controlled={!isMobile}
          />
          <FilterCount>{usersCounts?.role_count?.account_owner || 0}</FilterCount>
        </FilterWrapper>
        <FilterWrapper>
          <Checkbox
            value="team_manager"
            id="team_manager"
            name="roles"
            defaultChecked={filter.includes('team_manager')}
            checked={filter.includes('team_manager')}
            onChange={onFilterChange}
            label="Manager"
            controlled={!isMobile}
          />
          <FilterCount>{usersCounts?.role_count?.manager || 0}</FilterCount>
        </FilterWrapper>
      </div>
    </>
  );
};

export default UsersAsideFiltersRoles;
