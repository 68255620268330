import { useState, useContext } from 'react';
import styled from 'styled-components';
import { screenMd } from 'constants/styles/breakpoints';

import { Container as ContainerTemplate } from './ReportingHistoryComponents';

import { ReportsContext } from 'contexts/reporting';
import { ACCOUNT, COURSES, TEAM_ACTIVITY, USER_ACTIVITY } from 'constants/reporting';
import { SessionContext } from 'contexts/session';

const ReportingContentFilter = ({
  onContentChange,
}: {
  onContentChange: (id: string) => void;
}): JSX.Element => {
  const { fetching } = useContext(ReportsContext);
  const [activeContent, setActiveContent] = useState(USER_ACTIVITY);

  const { isAccountOwner } = useContext(SessionContext);

  const onClickFlapHandler = (id: string) => {
    if (!fetching) {
      setActiveContent(id);
      onContentChange(id);
    }
  };

  const Container = styled(ContainerTemplate)`
    background-color: transparent;
    display: flex;
    padding: 10px 0 5px 0;
    height: unset;
    ${screenMd} {
      padding: 15px 0;
    }
  `;

  const Flap = styled.div<{ active?: boolean; fetching?: boolean }>`
    font-size: 14px;
    font-weight: 450;
    line-height: 20px;
    padding: 5px;
    border-bottom: ${({ active }) => (active ? '#A793F6 solid 5px' : '#F2F2F7 solid 5px')};
    width: fit-content;
    cursor: ${({ fetching }) => (fetching ? 'progress' : 'pointer')};
    &:hover {
      border-bottom: #d0d2db solid 5px;
    }
    ${screenMd} {
      font-size: 16px;
      line-height: 23px;
      padding: 10px;
      font-weight: 500;
    }
  `;

  return (
    <Container>
      {isAccountOwner && (
        <Flap
          fetching={fetching}
          active={activeContent === ACCOUNT}
          onClick={() => onClickFlapHandler(ACCOUNT)}
        >
          Account activity
        </Flap>
      )}
      <Flap
        fetching={fetching}
        active={activeContent === USER_ACTIVITY}
        onClick={() => onClickFlapHandler(USER_ACTIVITY)}
      >
        User activity
      </Flap>
      <Flap
        fetching={fetching}
        active={activeContent === TEAM_ACTIVITY}
        onClick={() => onClickFlapHandler(TEAM_ACTIVITY)}
      >
        Teams activity
      </Flap>
      <Flap
        fetching={fetching}
        active={activeContent === COURSES}
        onClick={() => onClickFlapHandler(COURSES)}
      >
        Courses
      </Flap>
    </Container>
  );
};

export default ReportingContentFilter;
