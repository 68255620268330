import { AxiosResponse } from 'axios';
import {
  IAccountLabParams,
  IBookingsParams,
  IGetCalendarAvailability,
  IGetProductPayload,
  IGetRaxBookings,
} from '../contexts/rax/types';
import { config } from 'config/config.local';
import { BUSINESS_PYTHON } from './axios';

const formatParams = (params: any) => {
  let get_params = '';
  for (const key in params) {
    get_params += `${key}=${params[key]}&`;
  }
  if (Object.keys(params).length) get_params = get_params.slice(0, -1);
  return get_params;
};

export const getRaxProducts = (params: IGetProductPayload): Promise<AxiosResponse<any>> => {
  const get_params = formatParams(params);
  return BUSINESS_PYTHON.get(`${config.routes.rax.labs.get}?${get_params}`);
};

export const getAccountRaxLabs = (params: IGetProductPayload): Promise<AxiosResponse<any>> => {
  const get_params = formatParams(params);
  return BUSINESS_PYTHON.get(`${config.routes.rax.accountRaxLabs.get}?${get_params}`);
};

export const postAccountRaxLab = (params: IAccountLabParams): Promise<AxiosResponse<any>> => {
  return BUSINESS_PYTHON.post(config.routes.rax.accountRaxLabs.post, params);
};

export const getRaxBookings = (params: IGetRaxBookings = {}): Promise<AxiosResponse<any>> => {
  const get_params = formatParams(params);
  return BUSINESS_PYTHON.get(`${config.routes.rax.bookings.get}?${get_params}`);
};

export const postRaxBooking = (params: IBookingsParams): Promise<AxiosResponse<any>> => {
  return BUSINESS_PYTHON.post(config.routes.rax.bookings.post, params);
};

export const removeRaxBooking = (account_rax_lab_id: string): Promise<AxiosResponse<any>> => {
  return BUSINESS_PYTHON.delete(`${config.routes.rax.bookings.delete}${account_rax_lab_id}/`);
};

export const removeRaxSlot = async (account_rax_lab_id: string): Promise<AxiosResponse> => {
  return BUSINESS_PYTHON.get(
    `${config.routes.rax.accountRaxLabs.get}/${account_rax_lab_id}/remove_seat/`
  );
};

export const getRaxCalendarAvailability = async (
  params: IGetCalendarAvailability
): Promise<AxiosResponse<any>> => {
  const get_params = formatParams(params);
  return BUSINESS_PYTHON.get(`${config.routes.rax.labs.getAvailability}?${get_params}`);
};
