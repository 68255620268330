import { SKILL_DIVE_EXPERIENCE_TEXT } from 'constants/playlists';
import { Tag } from '../../components/AddContent/ContentTypeTag';
import Button from 'components/button';
import { INE_CONTACT_SALES } from 'constants/settings';

interface IPreviewProps {
  salesContactEmail: string;
}

const SkillDiveLabInfo = ({ salesContactEmail }: IPreviewProps): JSX.Element => {
  const handleContact = () => {
    window.location.href = `mailto:${INE_CONTACT_SALES},${salesContactEmail}`;
  };

  return (
    <div className="preview-tooltip">
      <p>{<Tag value={'lab'}>{SKILL_DIVE_EXPERIENCE_TEXT}</Tag>}</p>
      <p>Your account has no longer access to Skill dive. Contact sales to upgrade</p>
      <div className="preview-tooltip__button-container">
        <Button icon="Envelope" $secondary size="small" onClick={handleContact}>
          Contact sales to get access
        </Button>
      </div>
    </div>
  );
};

export default SkillDiveLabInfo;
