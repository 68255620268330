import styled from 'styled-components';
import { useState } from 'react';
import { screenMd } from 'constants/styles/breakpoints';
import { BodyTitle } from '../RemoveUsers/styles';
import { colors } from 'constants/styles/colors';

const Container = styled.div`
  padding: 10px;
  ${screenMd} {
    padding: 0px;
  }
`;

const Title = styled(BodyTitle)`
  margin: 10px 0;
`;

const Textarea = styled.textarea`
  resize: none;
  font-size: 12px;
  padding: 15px 10px;
  margin: 0;
  background: #f2f2f7;
  width: 100%;
  border: none;
  border-radius: 10px;
  min-height: 100px;
  font-family: Circular-Std;
  color: #1e1e1e;
  &:focus {
    outline: none;
  }
`;

const CharCounter = styled.div`
  font-size: 10px;
  line-height: 12px;
  font-weight: 450;
  color: ${colors.fonts.lightGray};
  position: absolute;
  bottom: 24%;
  right: 6%;
`;

interface AddMessage {
  onMessageUpdate(message: string): void;
}

const AddMessageComponent: React.FC<AddMessage> = ({ onMessageUpdate }) => {
  const [counter, setCounter] = useState(0);
  const maxLength = 255;
  const handleMessageChange = (e) => {
    onMessageUpdate(e.target.value);
    setCounter(e.target.value.length);
  };

  return (
    <Container>
      <Title>Add a custom message to your invitations</Title>
      <Textarea maxLength={maxLength} onChange={handleMessageChange} />
      <CharCounter>
        {counter}/{maxLength}
      </CharCounter>
    </Container>
  );
};

export default AddMessageComponent;
