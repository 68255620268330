import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { screenMd } from 'constants/styles/breakpoints';

export const cardStyles = css`
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  ${screenMd} {
    padding: 30px;
    height: 100%;
  }
`;

export const MainContent = styled.main<{ fetching?: boolean }>`
  margin: 0 0 55px 0;
  padding: 0 12px;
  min-height: calc(100vh - 160px);
  ${screenMd} {
    padding: 0;
    margin-bottom: 0;
  }
  ${({ fetching }) =>
    fetching &&
    `
		opacity: 0.6;
		pointer-events: none;
	`}
`;

export const CardsContainer = styled.div`
  min-height: 50vh;
  margin-top: 15px;
  padding-bottom: 40px;
  ${screenMd} {
    ${cardStyles}
    margin-top: 0;
    padding: 30px;
  }
`;

export const ContentLayout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  ${screenMd} {
    display: flex;
    flex-direction: row;
    height: 50vh;
  }
  > div {
    ${cardStyles}
    margin-bottom: 10px;
    ${screenMd} {
      margin-bottom: 0;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  flex-wrap: wrap;
  ${screenMd} {
    flex-direction: row;
    grid-gap: 30px;
  }
`;

export const Columns = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: start;
  grid-gap: 10px;
  ${screenMd} {
    flex-direction: row;
    flex: 1;
  }
`;

export const Card = styled.div`
  ${cardStyles}
  flex-direction: column;
  width: 100%;
  > h5 {
    font-weight: 700;
    font-size: 20px;
    ${screenMd} {
      text-align: left;
      font-size: 24px;
    }
  }
`;

export const EmptyCustomCard = styled(Card)`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  border: 1px dashed ${({ theme }) => theme.default.colors.palette.Business_DataVizLilacLighter};
  background: transparent;
  height: 100%;
  box-shadow: none;
  background-color: transparent;
  > img {
    max-height: 110px;
  }
  > h5 {
    font-size: 20px;
  }
`;

export const CustomLink = styled(Link)`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 9;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: nowrap;
  h5 {
    font-weight: 700;
    font-size: 24px;
    line-height: 26px;
    margin: 0px;
  }
  span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      margin: 0;
    }
    .divider {
      display: none;
      ${screenMd} {
        display: initial;
      }
      height: 20px;
      border-right: 2px solid #8b8b8b;
    }
  }
`;

export const CardRowContainer = styled(Card)`
  display: flex;
  flex-direction: row;
  position: relative;
  grid-gap: 20px;
  height: 100%;
`;
