import { useContext } from 'react';
import { isMobile } from 'react-device-detect';
import Checkbox from 'components/checkbox';
import { UsersContext } from 'contexts/users';
import { FilterCount, FilterWrapper } from './styles';
import { FilterChangeEvents } from 'components/usersAsideFilters/types';

const AccountUsersAsideFilterLicense = (): JSX.Element => {
  const { filters, actions, usersCounts } = useContext(UsersContext);

  const handleFilter = (e: FilterChangeEvents) => {
    actions.onChangeFilters(
      {
        name: 'license',
        value: e.target.value,
      },
      true,
      true
    );
  };

  return (
    <>
      <h3 className="mt-16 mb-5 fs-1 card-title">License</h3>
      <FilterWrapper>
        <Checkbox
          label={'License'}
          value="license"
          id="license"
          name="license"
          onChange={handleFilter}
          defaultChecked={filters.license.includes('license')}
          checked={filters.license.includes('license')}
          controlled={!isMobile}
        />
        <FilterCount>{usersCounts.license_count?.license || 0}</FilterCount>
      </FilterWrapper>
      <FilterWrapper>
        <Checkbox
          label={'No license'}
          value="no_license"
          id="no_license"
          name="license"
          onChange={handleFilter}
          defaultChecked={filters.license.includes('no_license')}
          checked={filters.license.includes('no_license')}
          controlled={!isMobile}
        />
        <FilterCount>{usersCounts.license_count?.no_license || 0}</FilterCount>
      </FilterWrapper>
    </>
  );
};

export default AccountUsersAsideFilterLicense;
