/**
 * `ActionButtons`
 * @param {IPlaylist} playlist optionals components.
 * @returns {JSX.Element} Component.
 */

import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import Tooltip from 'components/tooltip';
import Button from 'components/button';
import ButtonIcon from 'components/buttonIcon';

import { ButtonsGapWrapper, IconButtonsContainer, SeeDetailsContainer } from './styles';
import { IPlaylist } from 'contexts/playlists/types';

const SonarRecommendeActionButtons = ({
  playlistId,
  handleGoToPlaylist,
  onAssignUser,
  onUnassignUser,
  onClonePlaylist,
  playlist,
  hideClone,
  alreadyAssigned,
}): JSX.Element => {
  const fullUserName = useMemo(() => {
    const { first_name, last_name } = playlist?.created_for;
    return `${first_name} ${last_name}`;
  }, [playlist]);

  return (
    <ButtonsGapWrapper big={true}>
      <SeeDetailsContainer>
        <Button size={'small'} $full $secondary onClick={() => handleGoToPlaylist(playlistId)}>
          See more details
        </Button>
      </SeeDetailsContainer>
      <IconButtonsContainer>
        {!hideClone && (
          <Tooltip placement="top" title="Duplicate playlist">
            <ButtonIcon
              $secondary
              size="small"
              icon="Duplicate"
              onClick={() => onClonePlaylist(playlist)}
            />
          </Tooltip>
        )}
        {alreadyAssigned ? (
          <Tooltip placement="top" title={`Unassign ${fullUserName}`}>
            <ButtonIcon
              $secondary
              size="small"
              icon="UserMinus"
              onClick={() => onUnassignUser(playlist)}
            />
          </Tooltip>
        ) : (
          <Tooltip
            placement="top"
            overlayStyle={{
              width: '208px',
            }}
            title={`This playlist can only be assigned to ${fullUserName}. Click to assign.`}
          >
            <ButtonIcon
              $secondary
              size="small"
              icon="UserPlus"
              onClick={() => onAssignUser(playlist)}
            />
          </Tooltip>
        )}
      </IconButtonsContainer>
    </ButtonsGapWrapper>
  );
};

const B2BActionButtons = ({
  playlist,
  playlistId,
  handleGoToPlaylist,
  onClonePlaylist,
  onEditPlaylist,
  onAddUsers,
  onDeletePlaylist,
}): JSX.Element => (
  <ButtonsGapWrapper big={false}>
    <SeeDetailsContainer>
      <Button size="small" $secondary $full onClick={() => handleGoToPlaylist(playlistId)}>
        See more details
      </Button>
    </SeeDetailsContainer>
    <IconButtonsContainer>
      <Tooltip placement="top" title="Duplicate playlist">
        <ButtonIcon
          $secondary
          size="small"
          icon="Duplicate"
          onClick={() => onClonePlaylist(playlist)}
        />
      </Tooltip>
      <Tooltip placement="top" title="Edit playlist">
        <ButtonIcon $secondary size="small" icon="Edit" onClick={() => onEditPlaylist(playlist)} />
      </Tooltip>
      <Tooltip placement="top" title="Add users">
        <ButtonIcon $secondary size="small" icon="UserPlus" onClick={() => onAddUsers(playlist)} />
      </Tooltip>
      <Tooltip placement="top" title="Delete playlist">
        <ButtonIcon
          $secondary
          size="small"
          icon="Trash"
          onClick={() => onDeletePlaylist(playlist)}
        />
      </Tooltip>
    </IconButtonsContainer>
  </ButtonsGapWrapper>
);

type Props = {
  playlist: IPlaylist;
  onAddUsers: (playlist: IPlaylist) => void;
  onDeletePlaylist: (playlist: IPlaylist) => void;
  onEditPlaylist: (playlist: IPlaylist) => void;
  onClonePlaylist: (playlist: IPlaylist) => void;
  onAssignUser: (playlist: IPlaylist) => void;
  onUnassignUser: (playlist: IPlaylist) => void;
  isSonar: boolean;
  hideClone?: boolean;
  alreadyAssigned: boolean;
};

const ActionButtons = ({
  playlist,
  onAddUsers,
  onDeletePlaylist,
  onEditPlaylist,
  onClonePlaylist,
  onAssignUser,
  onUnassignUser,
  isSonar,
  hideClone,
  alreadyAssigned,
}: Props): JSX.Element => {
  const history = useHistory();

  function handleGoToPlaylist(id) {
    history.push(`/playlist/${id}`);
  }

  const commonProps = {
    playlistId: playlist.id,
    handleGoToPlaylist,
  };

  const sonarRecommendedProps = {
    ...commonProps,
    playlist,
    onAssignUser,
    onUnassignUser,
    onClonePlaylist,
    hideClone,
    alreadyAssigned,
  };

  const b2bProps = {
    ...commonProps,
    playlist,
    onAddUsers,
    onDeletePlaylist,
    onEditPlaylist,
    onClonePlaylist,
    isSonar,
  };
  if (isSonar) return <SonarRecommendeActionButtons {...sonarRecommendedProps} />;
  else return <B2BActionButtons {...b2bProps} />;
};

export default ActionButtons;
