import { useState } from 'react';
import { getLearningAreasAction } from 'contexts/learningAreas/actions';
import { ILearningArea } from '../contexts/learningAreas/types';

interface ILearningAreasContentHook {
  fetching: boolean;
  error: string;
  learningAreas: ILearningArea[];
  getLearningAreas: () => void;
}

const useLearningAreasContent = (): ILearningAreasContentHook => {
  const [learningAreas, setLearningAreas] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState('');

  const getLearningAreas = async () => {
    setFetching(true);

    const learningAreasResponse = await getLearningAreasAction();

    if (learningAreasResponse instanceof Error) {
      setError(learningAreasResponse.message);
      setLearningAreas([]);
    } else {
      setError('');
      setLearningAreas(learningAreasResponse.results);
    }

    setFetching(false);
  };

  return {
    fetching,
    error,
    learningAreas,
    getLearningAreas,
  };
};

export default useLearningAreasContent;
