import { AccountsDataCtx } from './types';

export const accountsInitialValues: AccountsDataCtx = {
  table: {
    items: [],
    pageSize: 9,
    page: 1,
    loading: false,
    count: 0,
    error: 'false',
  },
  selectedItems: [],
  isEmpty: false,
  filters: {
    page_size: '9',
    page: '1',
    search: '',
    pricing_plan: [],
    status: ['active', 'past_due'],
    is_free_trial: 'false',
    end_date_from: '',
    end_date_to: '',
    licenses_from: '0',
    licenses_to: '0',
    is_license_unlimited: 'false',
    ordering: '',
    support: '',
    sales: '',
    addons: [],
  },
  counts: {
    pricing_plan_count: [{ name: 'professional', count: 0 }],
    free_count: 0,
    status_count: {
      active: 0,
      past_due: 0,
      expire: 0,
      unstarted: 0,
    },
    license_count: {
      all: 0,
      unlimited: 0,
    },
    close_to_expiration_count: 0,
    addons_count: [{ id: '', name: '', count: 0 }],
  },
  staff: [],
  error: null,
  fetching: false,
};
