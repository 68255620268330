import { DeepTeams, Team } from 'contexts/teams/types';
import { useState } from 'react';
import CreateTeam from '../CreateTeams';

interface IManageTeamsHook {
  manageTeamModal: JSX.Element;
  closeManageTeamModal(): void;
  openManageTeamModal: (params: OpenManageTeamsModal) => void;
}

interface OpenManageTeamsModal {
  onClose: (data: Team | DeepTeams | null) => void;
  team: Team | DeepTeams | null;
  existingTeams?: Team[];
}

const useManageTeam = (): IManageTeamsHook => {
  const [modal, setModal] = useState<JSX.Element>(null);

  const handleOpenModal = ({ onClose, team, existingTeams }) => {
    setModal(
      <CreateTeam isVisible={true} team={team} onClose={onClose} existingTeams={existingTeams} />
    );
  };

  return {
    manageTeamModal: modal,
    closeManageTeamModal: () => setModal(null),
    openManageTeamModal: handleOpenModal,
  };
};

export default useManageTeam;
