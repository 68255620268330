import { ReactComponent as RepeatIcon } from 'assets/icons/repeat.svg';
//import NotificationsToolBar from './Notifications';
import AvatarHeaderProfile from './AvatarHeaderProfile';
import ProfileMenu from './ProfileMenu';
import './header-toolbar.scss';
import styled from 'styled-components';
import { INE_URL } from 'constants/domains';
import Tooltip from 'components/tooltip';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  font-family: 'Circular-Std';
`;

const HeaderToolBar = (): JSX.Element => {
  return (
    <Container>
      {/* <NotificationsToolBar /> */}
      <Tooltip placement="bottom" title="Go to the learning platform">
        <a
          href={INE_URL}
          target="blank"
          className="btn btn-menu btn-menu-light"
          style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}
        >
          <RepeatIcon className="btn-menu-icon h-100" />
        </a>
      </Tooltip>
      <AvatarHeaderProfile />

      <ProfileMenu />
    </Container>
  );
};

export default HeaderToolBar;
