import React, { useMemo, useState } from 'react';
import { Card, CardContent, Container } from './styles/ProductCard.styles';

import { InputContainer, UserRow, UsersContainer } from './styles/AssignEditUser.styles';
import AvatarLetter from 'components/avatar/AvatarLetter';
import { Input } from 'antd';

import Icon from 'components/icons';
export interface AssignUserProps {
  users: any;
  onSelectUser: (value: number) => void;
}

const AssignUser: React.FC<AssignUserProps> = ({ users, onSelectUser }) => {
  const [userSelected, setUserSelected] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);

  const isUserSelected = (value: number) => {
    return userSelected === value;
  };

  const selectUser = (value: number) => {
    setUserSelected(value);
    onSelectUser(value);
  };

  const currentUsers = useMemo(
    () =>
      users
        .map(({ user, uaa_data, email }) => {
          const firstName = uaa_data?.profile?.data?.first_name || '';
          const lastName = uaa_data?.profile?.data?.last_name || '';
          const avatar = uaa_data?.profile?.data?.avatar || null;
          return {
            name: `${firstName} ${lastName}`,
            email,
            avatar,
            user,
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name)),
    []
  );

  const filteredUsers = useMemo(() => {
    if (!searchQuery) return currentUsers;
    return currentUsers.filter(
      (user) =>
        user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.email.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [searchQuery]);

  return (
    <Container className="d-flex h-100 flex-column">
      <Card className="p-0 border-0">
        <CardContent>
          <InputContainer>
            <Input
              size="large"
              placeholder="Search"
              prefix={<Icon icon={'Search'} size="small" />}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </InputContainer>
          <UsersContainer>
            {filteredUsers.map(({ user, name, email, avatar }) => (
              <UserRow key={user} onClick={() => selectUser(user)}>
                <input
                  className="form-check-input"
                  type="radio"
                  name="active"
                  id="isActive"
                  value="true"
                  checked={isUserSelected(user)}
                  onChange={() => null}
                />

                <AvatarLetter size="sm" name={email} avatar={avatar} avatarSize="sm" />
                <div className="d-flex flex-column">
                  <div style={{ color: 'var( --black-dark)', fontWeight: 500 }}>{name}</div>
                  <div style={{ color: 'var( --light-gray-darker)' }}>{email}</div>
                </div>
              </UserRow>
            ))}
          </UsersContainer>
        </CardContent>
      </Card>
    </Container>
  );
};

export default AssignUser;
