import { ReactNode, useContext, useEffect, useState } from 'react';

import { DEFAULT_IMG_USERS } from 'constants/urls';

import { SessionContext } from 'contexts/session';
import { UserInvites } from 'contexts/users/types';

import AvatarHeader from 'components/avatarHeader';
import Button from 'components/button/Button';
import ButtonIcon from 'components/buttonIcon';
import ModalDrawer from 'components/microLayouts/drawer';
import Switch from 'components/switch';
import Tooltip from 'components/tooltip';
import { HeaderLabel } from 'components/layout/profile/profileHeader/styles';
import { LitleContainer } from 'components/cards';
import { StickyFooter } from 'components/cards';

import { AccountCell, AccountStatusTag } from 'pages/Accounts/components/AccountsTable/styles';

import { DrawerBackground as Background, DrawerContainer as Container } from './styles';
import { LicenseSwitchStatus, SwitchActions, SwitchLicenseActions } from './types';
import LittleTeamsCard from 'components/cards/LittleTeamsCard';
import { UserTeamsPayload } from './types';

interface UserSettingsProps {
  data: UserInvites;
  disableManager: boolean;
  isAccOwner: boolean;
  licenseSwitchStatus: LicenseSwitchStatus;
  isManager: boolean;
  onRemoveUser: (ids: string[], name: string[]) => void;
  onSwitchLicense: ({ ids, checked }: SwitchLicenseActions) => void;
  onSwitchManager: (user_invite_id: string, teamsData: UserTeamsPayload[]) => void;
  onSwitchOwner: ({ id, checked }: SwitchActions) => void;
  roleTooltipText: ReactNode;
  statusTagState: { label: string; bgColor: string };
  onResendInvite?: () => void;
  onCancelInvite?: () => void;
}

const StaffUserSettingsDrawer = ({
  data,
  disableManager,
  isAccOwner,
  isManager,
  onRemoveUser,
  onSwitchLicense,
  onSwitchManager,
  onSwitchOwner,
  roleTooltipText,
  statusTagState,
  licenseSwitchStatus,
  onResendInvite,
  onCancelInvite,
}: UserSettingsProps): JSX.Element => {
  const [selectedUser, setSelectedUser] = useState(null);
  const {
    actions: sessionActions,
    showDrawer,
    isImpersonated,
    setVisibiltyDrawer,
  } = useContext(SessionContext);
  const { id, uaa_data, email, account_name, license } = data;

  // Staff can always delete users
  const canRemoveUser = true;

  const handleDrawer = ({ user }: { user: string }) => {
    setSelectedUser(user);
    setVisibiltyDrawer(!showDrawer);
  };

  const close = () => {
    setVisibiltyDrawer(false);
    setSelectedUser(null);
  };

  const handleSwitchManager = (teamsData: UserTeamsPayload[]) => {
    onSwitchManager(data.id, teamsData);
  };

  useEffect(() => {
    !showDrawer && setSelectedUser(null);
  }, [showDrawer]);

  return (
    <>
      <Tooltip placement="left" title="Go to user profile">
        <ButtonIcon
          $terciary
          icon="ChevronRight"
          size="small"
          onClick={() => {
            handleDrawer({ user: id });
          }}
        />
      </Tooltip>

      <ModalDrawer
        closable={false}
        placement="bottom"
        visible={showDrawer && selectedUser === id}
        onClose={close}
        height="calc(100% - 64px)"
        destroyOnClose
      >
        <Background>
          <AvatarHeader
            avatar={
              uaa_data && uaa_data.profile.data.avatar !== DEFAULT_IMG_USERS
                ? uaa_data.profile.data.avatar
                : null
            }
            email={email}
          />
          <Container>
            <h5 style={{ textAlign: 'center', fontSize: '20px' }}>
              {uaa_data &&
                `${uaa_data?.profile?.data?.first_name} ${uaa_data?.profile?.data?.last_name}`}
            </h5>
            <p style={{ textAlign: 'center' }}>{email}</p>

            <LitleContainer title="ACCOUNT" flat={true}>
              <div
                style={{
                  color: '#A793F6',
                  fontWeight: '700',
                }}
              >
                {account_name}
              </div>
              <AccountCell
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'baseline',
                }}
              >
                {statusTagState && (
                  <AccountStatusTag bgColor={statusTagState.bgColor}>
                    {statusTagState.label}
                  </AccountStatusTag>
                )}
              </AccountCell>
            </LitleContainer>
            <br />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <LittleTeamsCard
                teams={data.teams}
                onSwitchManager={handleSwitchManager}
                disableManager={disableManager}
                flat={false}
              />
            </div>
            <br />
            <div
              style={{
                marginBottom: '0.5em',
                padding: '0.5em',
                display: 'flex',
                flexDirection: 'row',
                gap: '40px',
              }}
            >
              <div>
                <HeaderLabel>OWNER</HeaderLabel>
                <Tooltip
                  placement="bottomLeft"
                  destroyTooltipOnHide
                  title={isManager ? 'User cannot be set as a Account Owner' : roleTooltipText}
                >
                  <span style={{ cursor: 'auto' }}>
                    <Switch
                      defaultChecked={isAccOwner}
                      style={{ pointerEvents: 'auto' }}
                      disabled={isManager}
                      onChange={(checked: boolean) => onSwitchOwner({ id: id, checked })}
                    />
                  </span>
                </Tooltip>
              </div>
              <div>
                <HeaderLabel>LICENSE</HeaderLabel>
                <Tooltip
                  placement="bottomLeft"
                  destroyTooltipOnHide
                  title={licenseSwitchStatus.tooltip}
                >
                  <span style={{ cursor: licenseSwitchStatus.disabled ? 'not-allowed' : 'auto' }}>
                    <Switch
                      defaultChecked={license}
                      style={{ pointerEvents: licenseSwitchStatus.disabled ? 'none' : 'auto' }}
                      disabled={licenseSwitchStatus.disabled}
                      onChange={(checked: boolean) => onSwitchLicense({ ids: [id], checked })}
                    />
                  </span>
                </Tooltip>
              </div>
            </div>
          </Container>
          <StickyFooter>
            {data.is_active ? (
              <>
                <Tooltip placement="bottomRight" title={'Impersonate as this user'}>
                  <Button
                    style={{ pointerEvents: isImpersonated ? 'none' : 'all' }}
                    disabled={isImpersonated}
                    $full
                    $secondary
                    size={'small'}
                    icon={'Key'}
                    className={'settings-bottom-button'}
                    onClick={() => {
                      sessionActions.impersonate(email);
                    }}
                  >
                    Impersonate user
                  </Button>
                </Tooltip>
                <Tooltip placement="left" title={'Remove user'}>
                  <Button
                    $full
                    $secondary
                    size={'small'}
                    icon={'Trash'}
                    className={'settings-bottom-button'}
                    disabled={!canRemoveUser}
                    onClick={() =>
                      onRemoveUser([id], [uaa_data?.profile?.data.first_name || email])
                    }
                  >
                    Remove User
                  </Button>
                </Tooltip>
              </>
            ) : (
              <>
                <Button
                  $full
                  $secondary
                  size={'small'}
                  icon={'ChevronRight'}
                  className={'settings-bottom-button'}
                  onClick={onResendInvite}
                >
                  Resend invitation
                </Button>
                <Button
                  $full
                  $secondary
                  size={'small'}
                  icon={'Trash'}
                  className={'settings-bottom-button'}
                  onClick={onCancelInvite}
                >
                  Cancel invitation
                </Button>
              </>
            )}
          </StickyFooter>
        </Background>
      </ModalDrawer>
    </>
  );
};

export default StaffUserSettingsDrawer;
