import { useState } from 'react';
import Modal from 'components/modal';
import Button from 'components/button';
import { isMobile } from 'react-device-detect';
import { ModalContainer } from 'components/modal/styles';
import {
  MigratedModalActions,
  MigratedModalContent,
  MigratedModalDescription,
  MigratedModalDescriptionSection,
  MigratedModalSection,
  MigratedModalSectionTitle,
  MigratedTitle,
  MigratedVideoLabel,
  MigratiedHeaderContainer,
} from './styles';

import CongratsIcon from 'assets/images/congrats-icon.svg';
import { breakpoints } from 'constants/styles/breakpoints';

interface IMigratedModal {
  isVisible?: boolean;
  onClose?: () => void;
}

const MigratedModal: React.FC<IMigratedModal> = ({ isVisible, onClose }) => {
  const [downloading, setDownloading] = useState(false);
  const [pdfDownloaded, setPdfDownloaded] = useState(false);
  const handleDownload = async () => {
    setDownloading(true);
    window.location.href =
      'https://assets-ine-com.s3.amazonaws.com/pdf/INE%20Business%20Platform%20User%20Guide.pdf';
    setDownloading(false);
    setPdfDownloaded(true);
  };
  const handleClose = () => {
    onClose();
  };

  const videoSizes = () => {
    const size = window.innerWidth;
    if ((size && size > breakpoints.sm) || !isMobile) {
      return { width: 640, height: 360 };
    }
    return { width: 350, height: 196 };
  };
  return (
    <Modal
      centered
      visible={isVisible}
      fullscreen={isMobile}
      onCancel={() => handleClose()}
      width={isMobile ? '100%' : '697px'}
      footer={null}
      closeIconColor="#FFF"
      maskClosable={false}
    >
      <ModalContainer>
        <MigratiedHeaderContainer>
          <MigratedTitle>
            <img src={CongratsIcon}></img>
            Welcome to Business Dashboard!
          </MigratedTitle>
        </MigratiedHeaderContainer>
        <MigratedModalContent>
          <MigratedModalSection>
            <MigratedVideoLabel>What is new</MigratedVideoLabel>
            <iframe
              src="https://cdn.jwplayer.com/players/oRmuwUV6-8W9qrj1S.html"
              width={videoSizes().width}
              height={videoSizes().height}
              title="Vid-21-vod-5367 Ine-4-biz-v1 (Original)"
            ></iframe>
            <MigratedModalDescriptionSection>
              <MigratedModalSectionTitle>
                INE released a brand new Team Management Platform!
              </MigratedModalSectionTitle>
              <MigratedModalDescription>
                We are sharing a virtual video walkthrough that provides more details about these
                new features, how to use them, and where to find them in the new portal.
              </MigratedModalDescription>
            </MigratedModalDescriptionSection>
          </MigratedModalSection>
          <MigratedModalActions>
            <Button
              $full
              $secondary={!downloading}
              $primary={downloading}
              onClick={handleDownload}
              icon={pdfDownloaded ? 'Check3' : 'Download'}
              loading={downloading}
              className="migrated-pdf-download"
            >
              <span className="migrated-pdf-download__label">
                Download a PDF guide with more details!
              </span>
            </Button>
          </MigratedModalActions>
        </MigratedModalContent>
      </ModalContainer>
    </Modal>
  );
};

export default MigratedModal;
