import { useState } from 'react';
import { MigratedCTA, MigratedCongratsIcon, MigratedNoticeContainer, MigratedPill } from './styles';
import Button from 'components/button/Button';
import CongratsIcon from 'assets/images/congrats-icon.svg';
import MigratedModal from './MigratedModal';
import { isMobile } from 'react-device-detect';

const MigratedTopNotice = (): JSX.Element => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const onClose = () => setShowModal(false);

  const handleOpenModal = () => setShowModal(true);
  return (
    <MigratedNoticeContainer>
      <MigratedPill>
        <span>WELCOME!</span>
      </MigratedPill>
      <div className={'titles-container'}>
        <p className={'migrated-title'}>We are glad to have you on Business Dashboard!</p>
        <p className={'migrated-subtitle'}>See our new platform improvements.</p>
      </div>
      <MigratedCTA>
        <MigratedCongratsIcon src={CongratsIcon}></MigratedCongratsIcon>
        <Button size={isMobile ? 'small' : null} $secondary $dark onClick={handleOpenModal}>
          Check out what&apos;s new
        </Button>
      </MigratedCTA>
      {showModal && <MigratedModal isVisible={showModal} onClose={onClose} />}
    </MigratedNoticeContainer>
  );
};

export default MigratedTopNotice;
