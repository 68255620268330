import { ReactComponent as BoardIcon } from 'assets/icons/teams/teams_icon-board.svg';
import { ReactComponent as ChatIcon } from 'assets/icons/teams/teams_icon-chat.svg';
import { ReactComponent as CloudNetIcon } from 'assets/icons/teams/teams_icon-cloud-net.svg';
import { ReactComponent as ConnectIcon } from 'assets/icons/teams/teams_icon-connect.svg';
import { ReactComponent as FileTargetIcon } from 'assets/icons/teams/teams_icon-file-target.svg';
import { ReactComponent as FinalIcon } from 'assets/icons/teams/teams_icon-final.svg';
import { ReactComponent as GraphBarsIcon } from 'assets/icons/teams/teams_icon-graph-bars.svg';
import { ReactComponent as GroupIcon } from 'assets/icons/teams/teams_icon-group.svg';
import { ReactComponent as LightBulbIcon } from 'assets/icons/teams/teams_icon-light-bulb.svg';
import { ReactComponent as NetworkIcon } from 'assets/icons/teams/teams_icon-network.svg';
import { ReactComponent as PathCheckIcon } from 'assets/icons/teams/teams_icon-path-check.svg';
import { ReactComponent as PieGrapIcon } from 'assets/icons/teams/teams_icon-pie-graph.svg';
import { ReactComponent as ProfileConfigIcon } from 'assets/icons/teams/teams_icon-profile-config.svg';
import { colors } from 'constants/styles/colors';
import { IconContainer } from './styles';
import AccountDefaultLogo from 'assets/images/account-avatar.svg';

export type BusinessTeamIcons = Record<TeamIcon, JSX.Element>;

export type TeamIcon =
  | 'board'
  | 'chat'
  | 'cloud-net'
  | 'connect'
  | 'file-target'
  | 'final'
  | 'graph-bars'
  | 'group'
  | 'light-bulb'
  | 'network'
  | 'path-check'
  | 'pie-graph'
  | 'profile-config';

export interface IconTeamProps {
  color?: string;
  size?: 'default' | 'large' | 'largest' | 'small' | 'smaller';
  iconName?: TeamIcon;
  className?: string;
  fallbackIcon?: string;
}

export const IconTeam = ({
  iconName,
  color,
  size = 'default',
  className,
  fallbackIcon,
}: IconTeamProps): JSX.Element => {
  const handleErrorImg = (e) => {
    e.target.src = AccountDefaultLogo;
  };

  if (!Object.keys(colors.dataVisualization).includes(color))
    return (
      <IconContainer color={color} size={size} className={className}>
        <img
          className="team-icon default"
          src={fallbackIcon || AccountDefaultLogo}
          onError={handleErrorImg}
        />
      </IconContainer>
    );

  const icons: BusinessTeamIcons = {
    board: <BoardIcon className="team-icon" stroke={colors.dataVisualization[color].base} />,
    chat: <ChatIcon className="team-icon" stroke={colors.dataVisualization[color].base} />,
    'cloud-net': (
      <CloudNetIcon className="team-icon" stroke={colors.dataVisualization[color].base} />
    ),
    connect: <ConnectIcon className="team-icon" stroke={colors.dataVisualization[color].base} />,
    'file-target': (
      <FileTargetIcon className="team-icon" stroke={colors.dataVisualization[color].base} />
    ),
    final: <FinalIcon className="team-icon" stroke={colors.dataVisualization[color].base} />,
    'graph-bars': (
      <GraphBarsIcon className="team-icon" stroke={colors.dataVisualization[color].base} />
    ),
    group: <GroupIcon className="team-icon" stroke={colors.dataVisualization[color].base} />,
    'light-bulb': (
      <LightBulbIcon className="team-icon" stroke={colors.dataVisualization[color].base} />
    ),
    network: <NetworkIcon className="team-icon" stroke={colors.dataVisualization[color].base} />,
    'path-check': (
      <PathCheckIcon className="team-icon" stroke={colors.dataVisualization[color].base} />
    ),
    'pie-graph': (
      <PieGrapIcon className="team-icon" stroke={colors.dataVisualization[color].base} />
    ),
    'profile-config': (
      <ProfileConfigIcon className="team-icon" stroke={colors.dataVisualization[color].base} />
    ),
  };

  return (
    <IconContainer color={color} size={size} className={className}>
      {icons[iconName] || null}
    </IconContainer>
  );
};
