import EmptyTeamsSVG from 'assets/images/empty-teams.svg';
import { EmptyCustomCard } from 'components/layout/profile/profileContent/styles';

const EmptyTeamsCard = (): JSX.Element => (
  <EmptyCustomCard>
    <img src={EmptyTeamsSVG} />
    <h5>You haven’t added any teams yet</h5>
  </EmptyCustomCard>
);

export default EmptyTeamsCard;
