import { format, isAfter, isBefore, parseISO, subDays } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import DatePicker from 'components/datepicker';
import Icon from 'components/icons';
import { AccountsContext } from 'contexts/accounts';

import {
  Content,
  DateLabel /* , Count */,
  DateWrapper,
  Filter,
  FilterContainer,
  Title,
} from '../styles';

const DateFilter = styled(Filter)`
  display: flex;
  align-items: center;
  .ant-picker-clear {
    border-radius: 50%;
    background-color: white;
  }
`;

const DateIcon = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 20px !important;
    margin-left: 10px;
    min-width: 24px;
    height: 24px;
    margin-top: 14px;
    background: transparent;
  }
`;

const PlanExpiration: React.FC = (): JSX.Element => {
  const { filters, actions } = useContext(AccountsContext);

  const initialDateFrom = filters.end_date_from ? filters.end_date_from : undefined;
  const initialDateTo = filters.end_date_to ? filters.end_date_to : undefined;

  const [dateFrom, setDateFrom] = useState(initialDateFrom);
  const [dateTo, setDateTo] = useState(initialDateTo);

  const disableBefore = (date) => {
    return isBefore(new Date(date), new Date(dateFrom));
  };

  const disableAfter = (date) => {
    return isAfter(subDays(new Date(date), 1), new Date(dateTo));
  };

  useEffect(() => {
    setDateFrom(initialDateFrom);
    setDateTo(initialDateTo);
  }, [filters.end_date_from, filters.end_date_to]);

  const onChangeFromDate = (moment) => {
    setDateFrom(moment);
    setFilter({ name: 'end_date_from', moment });
  };

  const onChangeToDate = (moment) => {
    setDateTo(moment);
    setFilter({ name: 'end_date_to', moment });
  };

  const setFilter = ({ name, moment }) => {
    const formatValue = moment ? format(moment, 'yyyy-MM-dd') : '';
    actions &&
      actions.onChangeFilters({
        name: name,
        value: formatValue,
      });
    actions.clearSelectedItems();
  };

  return (
    <FilterContainer>
      <Title>Plan expiration</Title>
      <Content>
        <DateFilter>
          <DateWrapper>
            <DateLabel>From</DateLabel>
            <DatePicker
              suffixIcon={<></>}
              value={dateFrom ? parseISO(dateFrom) : null}
              hideIcon={true}
              placeholder="day/month"
              small
              style={{ marginRight: '10px' }}
              onChange={onChangeFromDate}
              disabledDate={(date) => disableAfter(date)}
              format="DD/MMM"
            />
          </DateWrapper>
          <DateWrapper>
            <DateLabel>To</DateLabel>
            <DatePicker
              suffixIcon={<></>}
              value={dateTo ? parseISO(dateTo) : null}
              hideIcon={true}
              placeholder="day/month"
              small
              onChange={onChangeToDate}
              disabledDate={(date) => disableBefore(date)}
              format="DD/MMM"
            />
          </DateWrapper>
          <DateIcon>
            <Icon icon="Calendar" />
          </DateIcon>
        </DateFilter>
      </Content>
    </FilterContainer>
  );
};

export default PlanExpiration;
