import InputSelectMultiple from 'components/inputs/inputSelectMultiple';
import { SALES, SUPPORT } from 'constants/roles';
import { AccountsContext } from 'contexts/accounts';
import { useContext, useEffect, useState } from 'react';
import { Content, FilterContainer, Title } from '../styles';

const Representative: React.FC = (): JSX.Element => {
  const { staff, actions, filters } = useContext(AccountsContext);
  const [supportOptions, setSupportOptions] = useState([]);
  const [salesOptions, setSalesOptions] = useState([]);

  const initialSupportValues = filters.support ? filters.support.split(',') : [];
  const initialSalesValues = filters.sales ? filters.sales.split(',') : [];

  useEffect(() => {
    setSupportOptions(
      staff
        .filter(({ staff_role }) => staff_role === SUPPORT)
        .map(({ first_name, last_name, email, id }) => {
          return {
            label: `${first_name} ${last_name}`,
            value: email,
            key: id,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label))
    );
    setSalesOptions(
      staff
        .filter(({ staff_role }) => staff_role === SALES)
        .map(({ first_name, last_name, email, id }) => {
          return {
            label: `${first_name} ${last_name}`,
            value: email,
            key: id,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label))
    );
  }, [staff]);

  const onChangeSupport = (value: string[] | number[]) => {
    actions &&
      actions.onChangeFilters({
        name: SUPPORT.toLowerCase(),
        value: value.toString(),
      });
    actions.clearSelectedItems();
  };

  const onChangeSales = (value: string[] | number[]) => {
    actions &&
      actions.onChangeFilters({
        name: SALES.toLowerCase(),
        value: value.toString(),
      });
    actions.clearSelectedItems();
  };

  return (
    <FilterContainer>
      <Title>Representative</Title>
      <Content className="mb-5">
        <InputSelectMultiple
          values={initialSupportValues}
          inputLabel={SUPPORT}
          placeholder="All"
          optionFilterProp={'label'}
          options={supportOptions}
          maxDropdownHeight={'150px'}
          onChange={onChangeSupport}
        />
      </Content>
      <Content>
        <InputSelectMultiple
          values={initialSalesValues}
          inputLabel={SALES}
          placeholder="All"
          optionFilterProp={'label'}
          options={salesOptions}
          maxDropdownHeight={'150px'}
          onChange={onChangeSales}
        />
      </Content>
    </FilterContainer>
  );
};

export default Representative;
