import { screenMd, screenSm } from 'constants/styles/breakpoints';
import styled from 'styled-components';

export const ToggleContainer = styled.div`
  width: 100%;
`;

export const ToggleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
`;

export const ToggleSwitchWrapper = styled.div`
  display: flex;
  align-items: center;
`;
