import styled from 'styled-components';
import { screenSm } from 'constants/styles/breakpoints';
import { colors } from 'constants/styles/colors';

export const LearningPathDetailContainer = styled.div`
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid #f2f2f7;
  border-top: 0;
  background: rgba(242, 242, 247, 0.3);
  min-height: 50px;
  width: 100%;
  padding-left: 15px;
`;

export const GroupContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  overflow: auto;
  text-overflow: ellipsis;
  align-items: center;

  border-bottom: 1px solid ${colors.dataVisualization.gray.dark};
  margin-bottom: 20px;
  padding-bottom: 10px;

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: none;
  }
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const GroupTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: ${colors.dataVisualization.black.light};
  width: 100%;
`;

export const GroupContent = styled.ul`
  padding: 0;
  margin: 0;
  width: 100%;
`;

export const GroupRow = styled.li`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid ${colors.dataVisualization.gray.light};

  ${screenSm} {
    flex-direction: row;
    justify-content: space-between;
  }
  &:last-child {
    border-bottom: none;
  }
`;

export const GroupRowLeftWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const GroupContentTagWrapper = styled.div`
  width: 45px;
`;

export const GroupItemTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.dataVisualization.black.light};
`;
