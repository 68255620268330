import React from 'react';
import { ConfirmModal } from 'components/modal';

interface RemoveRaxSlotModalProps {
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
}

const RemoveRaxSlotModal: React.FC<RemoveRaxSlotModalProps> = ({ visible, onOk, onCancel }) => {
  const RemoveRaxSlotModalState = {
    open: visible,
    onAccept: onOk,
    acceptLabel: 'Remove slot',
    cancelLabel: 'Cancel',
    title: 'Remove slot',
    description: `Are you sure you want to remove this slot from the account?`,
    onCancel,
  };

  return <ConfirmModal {...RemoveRaxSlotModalState} />;
};

export default RemoveRaxSlotModal;
