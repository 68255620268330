import { handleErrors } from 'contexts/helpers';
import {
  RecommendedFetchParams,
  RecommendedPlaylistsFetchParams,
  SonarScoresFetchParams,
} from './types';
import {
  getRecommendedContent,
  getRecommendedContentPlaylists,
  getSonarScores,
} from 'services/sonar';

export const getSonarScoresAction = async (
  params: SonarScoresFetchParams
): Promise<any | Error> => {
  try {
    const { data } = await getSonarScores(params);
    return {
      results: data.results,
      count: data.count,
    };
  } catch (error) {
    return handleErrors(error);
  }
};

export const getRecommendedAction = async (
  params: RecommendedFetchParams
): Promise<any | Error> => {
  try {
    const { data } = await getRecommendedContent(params);
    return {
      results: data,
      count: data.count,
    };
  } catch (error) {
    return handleErrors(error);
  }
};

export const getRecommendedContentPlaylistsAction = async (
  params: RecommendedPlaylistsFetchParams
): Promise<any | Error> => {
  try {
    const { data } = await getRecommendedContentPlaylists(params);
    return {
      results: data.results,
      count: data.count,
    };
  } catch (error) {
    return handleErrors(error);
  }
};
