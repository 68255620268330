import { useContext } from 'react';
import './statics.scss';
import Button from 'components/button';
import { stripHtml } from 'utils/helpers';
import { INE_URL } from 'constants/domains';
import { Tag } from '../AddContent/ContentTypeTag';
import { SessionContext } from 'contexts/session';
import { PLAN_ENTERPRISE_PLUS_LAB, PLAN_LAB_EXPERIENCE } from 'constants/plans_config';
import { INE_CONTACT_SALES } from 'constants/settings';
import {
  CONTENT_TYPES,
  LAB_EXPERIENCE_TEXT,
  SKILL_DIVE_EXPERIENCE_TEXT,
} from 'constants/playlists';

export interface IPreviewData {
  objectID: string;
  slug: string;
  description: string;
  lab_type: string;
  flags: [];
  is_lab_experience: boolean;
  metadata: { courses: string[] };
}

interface IPreviewProps {
  data: IPreviewData;
}

const PreviewLab = (props: IPreviewProps): JSX.Element => {
  const {
    objectID,
    slug,
    lab_type,
    flags,
    description,
    is_lab_experience,
    metadata: { courses },
  } = props.data;
  const { account } = useContext(SessionContext);
  const stripDescription = description ? stripHtml(description) : '';

  const has_lab_expericence =
    account?.pricing_plan === PLAN_LAB_EXPERIENCE ||
    account?.pricing_plan === PLAN_ENTERPRISE_PLUS_LAB;

  const has_skill_dive =
    account?.account_available_addons?.filter((ad) => ad.name === CONTENT_TYPES.SKILL_DIVE).length >
    0;

  const handleDetails = () => {
    // @ToDo Get the parent Course and use the Course slug to build the correct URL
    window.open(`${INE_URL}/course/${slug}/${courses[0]}/lab/${objectID}`);
  };

  const handleContact = () => {
    window.location.href = `mailto:${INE_CONTACT_SALES},${account?.sales_contact?.email}`;
  };

  return (
    <div className="preview-tooltip">
      <p>
        {is_lab_experience && (
          <Tag value={'lab'}>
            {has_lab_expericence ? LAB_EXPERIENCE_TEXT : SKILL_DIVE_EXPERIENCE_TEXT}
          </Tag>
        )}
      </p>
      {stripDescription && (
        <p className="preview-tooltip__description">
          <b>Overview: </b>
          {stripDescription.length > 600
            ? stripDescription.slice(0, 600) + '...'
            : stripDescription}
        </p>
      )}

      <div className="preview-tooltip__info-container">
        <div className="preview-tooltip__info-row">
          <div className="preview-tooltip__info-column">LAB TYPE</div>
          <div className="preview-tooltip__info-column preview-tooltip__text-capitalize">
            {lab_type}
          </div>
        </div>
        <div className="preview-tooltip__info-row">
          <div className="preview-tooltip__info-column">ACTIVITIES</div>
          <div className="preview-tooltip__info-row">
            <div className="preview-tooltip__activity-amount-tag">FLAGS:{flags.length}</div>
          </div>
        </div>
      </div>
      <div className="preview-tooltip__button-container">
        {is_lab_experience && !has_lab_expericence && !has_skill_dive ? (
          <Button icon="Envelope" $secondary size="small" onClick={handleContact}>
            Contact sales to get access
          </Button>
        ) : (
          <Button
            icon="ExternalLink"
            disabled={!courses || (courses && !courses.length)}
            $secondary
            size="small"
            onClick={handleDetails}
          >
            Preview lab
          </Button>
        )}
      </div>
    </div>
  );
};

export default PreviewLab;
