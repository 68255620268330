import { FC } from 'react';

interface StatusFilterProps {
  active: boolean;
  onStatusChange: (status: string) => void;
}

const StatusFilter: FC<StatusFilterProps> = ({ active, onStatusChange }) => {
  const handleStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onStatusChange(e.target.value);
  };

  return (
    <div className={'d-flex'}>
      <div className="form-check me-12">
        <input
          className="form-check-input"
          type="radio"
          name="active"
          value="true"
          onChange={handleStatusChange}
          checked={active}
        />
        <label
          className="form-check-label fz-14"
          style={{ fontSize: '14px' }}
          htmlFor="isActiveMobile"
        >
          Active
        </label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="pending"
          value="false"
          onChange={handleStatusChange}
          checked={!active}
        />
        <label className="form-check-label" style={{ fontSize: '14px' }} htmlFor="isPendingMobile">
          Pending
        </label>
      </div>
    </div>
  );
};

export default StatusFilter;
