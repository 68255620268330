import styled from 'styled-components';
import Button from 'components/button';
import { screenMd } from 'constants/styles/breakpoints';

export const Container = styled.div`
  align-items: center;
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: auto;
  padding: 0 16px;
  ${screenMd} {
    margin-top: 30px;
    padding: 0;
  }
`;

export const NextButton = styled(Button)`
  &&& {
    /* background-color: ${({ theme }) => theme.default.buttons.primary.iddle.background}; */
    color: ${({ theme }) => theme.default.buttons.primary.iddle.color};
    height: 44px;
    width: ${({ size }) => (size === 'small' ? '64px' : '154px')};
    &:active {
      background-color: ${({ theme }) => theme.default.buttons.primary.pressed.background};
      border-color: ${({ theme }) => theme.default.buttons.primary.pressed.background};
    }
    &:disabled {
      background-color: ${({ theme }) => theme.default.buttons.primary.disabled.background};
      color: ${({ theme }) => theme.default.buttons.primary.disabled.color};
    }
  }
`;

export const BackButtonContainer = styled.div`
  display: flex;
  justify-content: left;
`;
export const EllipsisContainer = styled.div`
  justify-content: center;
  display: flex;
  width: 100%;
`;

export const SkipButtonContainer = styled.div`
  margin-right: 10px;
`;

export const SkipButton = styled(Button)`
  &&& {
    height: 44px;
    margin-right: 12px;
    width: 115px;
  }
`;

export const NextButtonsContainer = styled.div`
  display: flex;
  justify-content: right;
`;
