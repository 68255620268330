import { BottomDataWrapper, GroupItemDataWrapper, GroupItemTitle, VerticalDivider } from './styles';
import ContentTypeTag from '../../components/AddContent/ContentTypeTag';
import { Space } from 'antd';
import { durationFromSecs } from 'utils/helpers';

interface ICourseItemData {
  data: any;
}

const VideoItemData = ({ data }: ICourseItemData): JSX.Element => {
  const { content_type, name, duration_in_seconds, instructor_name } = data;

  return (
    <GroupItemDataWrapper>
      <Space>
        <ContentTypeTag value={content_type} />
      </Space>
      <GroupItemTitle>{name}</GroupItemTitle>
      <BottomDataWrapper>
        {instructor_name}
        <VerticalDivider />
        {durationFromSecs(duration_in_seconds)}
      </BottomDataWrapper>
    </GroupItemDataWrapper>
  );
};

export default VideoItemData;
