import { useEffect, useState } from 'react';
import {
  SkillLevel,
  SkillNameLabel,
  SkillProgressBarItem,
  SkillProgressBarItemFill,
  SkillProgressContainer,
} from './styles';
import { LEVEL_CONSTANTS } from 'pages/Teams/components/TeamSkills/types';
import { capitalize } from 'utils/helpers';

interface SkillProgressBarProps {
  skillName: string;
  value: number;
  difficulty: string;
  level: string;
}

const SkillProgressBar = ({
  skillName,
  value,
  difficulty,
  level,
}: SkillProgressBarProps): JSX.Element => {
  const [progressBars, setProgressBars] = useState<number[]>([0, 0, 0]);
  const [fillBars, setFillBars] = useState<boolean>(false);
  const [showLevel, setShowLevel] = useState<boolean>(false);

  const processScore = (score: number, difficulty: string) => {
    if (difficulty === LEVEL_CONSTANTS.NOVICE) {
      return [score, 0, 0];
    } else if (difficulty === LEVEL_CONSTANTS.PROFESSIONAL) {
      return [100, score, 0];
    } else if (difficulty === LEVEL_CONSTANTS.ADVANCED) {
      return [100, 100, score];
    } else {
      return [0, 0, 0];
    }
  };

  useEffect(() => {
    if (value) {
      const bars = processScore(value, difficulty);
      setProgressBars(bars);
      setTimeout(() => {
        setFillBars(true);
      }, 500);
    }
  }, []);
  return (
    <SkillProgressContainer
      onMouseEnter={() => setShowLevel(true)}
      onMouseLeave={() => setShowLevel(false)}
    >
      <SkillNameLabel>{skillName}</SkillNameLabel>
      {showLevel && <SkillLevel>{capitalize(level)}</SkillLevel>}
      <div style={{ position: 'relative', width: '33%' }}>
        <SkillProgressBarItem first />
        {fillBars && (
          <SkillProgressBarItemFill
            first
            style={{
              width: `${progressBars[0]}%`,
              borderRadius: progressBars[0] < 100 ? '0px 2px 2px 0px' : '0px',
            }}
          />
        )}
      </div>
      <div style={{ position: 'relative', width: '33%' }}>
        <SkillProgressBarItem />
        {fillBars && (
          <SkillProgressBarItemFill
            second
            style={{
              width: `${progressBars[1]}%`,
              borderRadius: progressBars[1] < 100 ? '0px 2px 2px 0px' : '0px',
            }}
          />
        )}
      </div>
      <div style={{ position: 'relative', width: '33%' }}>
        <SkillProgressBarItem last />
        {fillBars && (
          <SkillProgressBarItemFill
            last
            style={{
              width: `${progressBars[2]}%`,
              borderRadius: progressBars[2] < 100 ? '0px 2px 2px 0px' : '0px',
            }}
          />
        )}
      </div>
    </SkillProgressContainer>
  );
};

export default SkillProgressBar;
