import { useContext } from 'react';
import { Content, Count, Filter, FilterContainer, Title } from '../styles';
import { AccountsContext } from 'contexts/accounts';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import Checkbox from 'components/checkbox';
import Switch from 'components/switch';
import { getAccountStatusCount } from 'pages/Accounts/components/AccountsFilters/Filters/utils';

const AccountStatus: React.FC = (): JSX.Element => {
  const { filters, actions, counts } = useContext(AccountsContext);

  const setFilter = (e: React.ChangeEvent<HTMLInputElement> | CheckboxChangeEvent) => {
    const { name, value } = e.target;
    actions.onChangeFilters({
      name: name,
      value: value,
      multiple: true,
    });
    actions.clearSelectedItems();
  };

  const setSwitchFilter = ({ name, value }) => {
    actions.onChangeFilters({
      name: name,
      value: value.toString(),
    });
    actions.clearSelectedItems();
  };

  return (
    <FilterContainer>
      <Title>Account status</Title>
      <Content>
        <Filter>
          <Checkbox
            label="Active"
            value="active"
            id="status_active"
            name="status"
            onChange={setFilter}
            defaultChecked={filters.status.includes('active')}
            checked={filters.status.includes('active')}
            controlled
          />
        </Filter>
        <Count>{getAccountStatusCount('active', counts)}</Count>
      </Content>
      <Content>
        <Filter>
          <Checkbox
            label="Past due"
            value="past_due"
            id="status_past_due"
            name="status"
            onChange={setFilter}
            defaultChecked={filters.status.includes('past_due')}
            checked={filters.status.includes('past_due')}
            controlled
          />
        </Filter>
        <Count>{getAccountStatusCount('past_due', counts)}</Count>
      </Content>
      <Content>
        <Filter>
          <Checkbox
            label="Expired"
            value="expired"
            id="status_expired"
            name="status"
            onChange={setFilter}
            defaultChecked={filters.status.includes('expired')}
            checked={filters.status.includes('expired')}
            controlled
          />
        </Filter>
        <Count>{getAccountStatusCount('expire', counts)}</Count>
      </Content>
      <br />
      <Content>
        <Filter>
          <Switch
            defaultChecked={filters.is_free_trial === 'true'}
            checked={filters.is_free_trial === 'true'}
            onChange={(value) => setSwitchFilter({ value, name: 'is_free_trial' })}
            style={{ marginRight: '10px' }}
          />
        </Filter>
        Free account
        <Count>{counts?.free_count || 0}</Count>
      </Content>
    </FilterContainer>
  );
};

export default AccountStatus;
