import { Fragment } from 'react';
import { SkillsMenuContainer } from './styles';
import Checkbox from 'components/checkbox/Checkbox';
import { MIN_COLUMNS_TO_SHOW } from './types';

interface SkillMenuProps {
  skills: string[];
  skillsSelected: string[];
  onSkillSelected: (skill: string) => void;
}

const SkillsMenu = ({ skills, skillsSelected, onSkillSelected }: SkillMenuProps): JSX.Element => {
  const handleSkillSelected = (skill: string) => {
    if (skillsSelected.length <= MIN_COLUMNS_TO_SHOW) {
      if (!skillsSelected.includes(skill)) {
        onSkillSelected(skill);
      }
    } else {
      onSkillSelected(skill);
    }
  };

  return (
    <SkillsMenuContainer>
      {skills.map((skill, index) => (
        <Fragment key={index}>
          <div
            style={{
              display: 'flex',
              cursor:
                skillsSelected.length <= MIN_COLUMNS_TO_SHOW && skillsSelected.includes(skill)
                  ? 'not-allowed'
                  : 'pointer',
              padding: '5px',
            }}
            onClick={() => handleSkillSelected(skill)}
          >
            <Checkbox
              disabled={
                skillsSelected.length <= MIN_COLUMNS_TO_SHOW && skillsSelected.includes(skill)
              }
              style={{ marginRight: '10px' }}
              checked={skillsSelected.includes(skill)}
              controlled
            />
            <span>{skill}</span>
          </div>
        </Fragment>
      ))}
    </SkillsMenuContainer>
  );
};

export default SkillsMenu;
