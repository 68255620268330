import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useEffect, useMemo, useRef, useState } from 'react';
import './calendar.scss';
import './dayTooltip.scss';
import { addDays, eachDayOfInterval, format, isWithinInterval, parseISO } from 'date-fns';
import { IBooking } from 'contexts/rax/types';

const Calendar = ({
  events,
  isInteractable,
  disabledDates,
  borders = true,
  dateRangeSelection = { min: 5, max: 5 },
  flat = false,
  eventsWithUsername = false,
  isFetching = false,
  isModal = false,
  isLarge = false,
  onSelectDates,
  onChangeMonth,
}: {
  events?: Array<IBooking>;
  isInteractable: boolean;
  disabledDates: Array<string>;
  borders?: boolean;
  dateRangeSelection?: { min: number; max: number };
  flat?: boolean;
  eventsWithUsername?: boolean;
  isFetching?: boolean;
  isModal?: boolean;
  isLarge?: boolean;
  onSelectDates?: (value: any) => void;
  onChangeMonth: (date: string) => void;
}): JSX.Element => {
  const plugins = useMemo(() => {
    const interactablePlugin = isInteractable ? interactionPlugin : null;
    return [dayGridPlugin, interactablePlugin].filter((plugin) => plugin);
  }, []);

  const initialDatesValues = { start_date: null, end_date: null };

  const [isInitialized, setIsInitialized] = useState(false);
  const [listeners, setListeners] = useState([]);
  const [tooltipContent, setTooltipContent] = useState('');
  const [selectedDates, setSelectedDates] = useState(initialDatesValues);

  const calendarRef = useRef(null);

  useEffect(() => {
    setIsInitialized(true);
    if (calendarRef && isInteractable) {
      const calendarBody = document.querySelector('.fc-rax-interactable .fc-scrollgrid-sync-table');
      const dayTooltip = document.getElementsByClassName('day-tooltip')[0];
      if (calendarBody && dayTooltip) {
        calendarBody.appendChild(dayTooltip);
        const mouseMoveListener = calendarBody.addEventListener('mousemove', () => {
          dayTooltip.classList.remove('day-tooltip-visible');
        });
        const mouseWheelListener = calendarBody.addEventListener('wheel', () => {
          dayTooltip.classList.remove('day-tooltip-visible');
        });
        setListeners([mouseMoveListener, mouseWheelListener]);
      }
    }
    return () => {
      document.removeEventListener('mousemove', listeners[0]);
      document.removeEventListener('wheel', listeners[1]);
    };
  }, []);

  useEffect(() => {
    const calendarApi = calendarRef.current.getApi();
    const start_date = selectedDates?.start_date;
    const end_date = selectedDates?.end_date;
    start_date && end_date ? calendarApi.select(start_date, end_date) : setSelectedDates(null);
  }, [selectedDates, isFetching]);

  const currentDisabledDates = useMemo(() => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const currentMonth = yesterday.getMonth();
    const currentYear = yesterday.getFullYear();
    const lastDay = new Date(currentYear, currentMonth + 1, 0);
    const disabledDatesArray = [];
    for (let i = 1; i <= lastDay.getDate(); i++) {
      const date = new Date(currentYear, currentMonth, i);
      if (date < yesterday) {
        disabledDatesArray.push(format(date, 'yyyy-MM-dd'));
      }
    }
    return [...disabledDates, ...disabledDatesArray];
  }, [disabledDates]);

  const today = useMemo(() => new Date().setHours(0, 0, 0, 0), []);

  const handleDateClick = (arg) => {
    const argDate = new Date(arg.date).getTime();
    let dayStatus = '';
    if (argDate < today) dayStatus = 'past';
    if (argDate === today) dayStatus = 'today';
    const startDate = arg.date;
    const endDate = addDays(startDate, dateRangeSelection.min - 1);
    const start_date = format(startDate, 'yyyy-MM-dd');
    const end_date = format(endDate, 'yyyy-MM-dd');
    if (isValidDateRange(start_date, end_date, arg)) return selectDates(start_date, end_date);
    selectDates(null, null);
    fireTooltip(arg, dayStatus);
  };

  const isValidDateRange = (start_date, end_date, arg) => {
    const isDisabledDateInRange = disabledDates.some((date) => {
      const disabledDate = parseISO(date);
      const start = parseISO(start_date);
      const end = parseISO(end_date);
      return isWithinInterval(disabledDate, { start, end });
    });
    return !isDisabledDateInRange;
  };

  const fireTooltip = (arg, dayStatus = '') => {
    if (!isInteractable) return null;
    const el = arg.dayEl;
    const tooltip = document.getElementById('day-tooltip');
    const tooltipTopOffset = !dayStatus ? 90 : 67;
    tooltip.classList.add('day-tooltip-visible');
    const rect = el.getBoundingClientRect();
    const offsetTop = rect.top;
    const offsetLeft = rect.left;
    tooltip.style.top = `${offsetTop - tooltipTopOffset}px`;
    tooltip.style.left = `${offsetLeft + 52}px`;
    const labels = {
      past: "You can't select a past date",
      today: 'Select a date starting from tomorrow',
    };
    const tooltipLabel = labels[dayStatus] || 'You must select five consecutive days available';
    setTooltipContent(tooltipLabel);
  };

  const selectDates = (start_date, end_date) => {
    end_date = start_date ? format(addDays(parseISO(end_date), 1), 'yyyy-MM-dd') : null;
    setSelectedDates({ start_date, end_date });
    onSelectDates({ start_date, end_date });
  };

  const selectAllow = ({ start }) => {
    const endDate = addDays(start, dateRangeSelection.min - 1);
    const daysInRange = eachDayOfInterval({ start, end: endDate });
    const isDisabledDateInRange = daysInRange.some((date) =>
      currentDisabledDates.includes(format(date, 'yyyy-MM-dd'))
    );
    return !isDisabledDateInRange;
  };

  const handleDatesSet = (arg) => {
    if (arg.view.type === 'dayGridMonth' && isInitialized) {
      const start = arg.startStr.substring(0, 10);
      const end = arg.endStr.substring(0, 10);
      const date_range = `${start},${end}`;
      onChangeMonth(date_range);
    }
  };

  const currentEvents = useMemo(() => {
    return events?.map((event) => {
      const userName = `| ${event?.user?.name}`;
      const title = `${event.account_rax_lab?.account_name} ${eventsWithUsername ? userName : ''}`;
      const { start_date, end_date, color } = event;
      const start = start_date.substring(0, 10);
      const end = end_date.substring(0, 10);
      const endDate = addDays(parseISO(end), 1);
      const endFormatted = format(endDate, 'yyyy-MM-dd HH:mm:ss');
      return { color, title, start, end: endFormatted, allDay: true };
    });
  }, [events]);

  useEffect(() => {
    const calendarApi = calendarRef.current.getApi();
    if (calendarApi && !isFetching) {
      const dayEls = calendarApi.el.querySelectorAll('.fc-daygrid-day');
      dayEls.forEach((el) => {
        const dateAttr = el.getAttribute('data-date');
        if (isInteractable && currentDisabledDates.includes(dateAttr))
          el.classList.add('disabled-day');
      });
    }
  }, [currentDisabledDates, isFetching]);

  const maxCalendarHeight = isModal ? (isLarge ? '525px' : '440px') : 'auto';

  return (
    <div
      className={`w-100 fc-rax ${!borders ? 'no-borders' : ''} ${
        isInteractable ? 'fc-rax-interactable' : ''
      }`}
      style={{
        maxHeight: maxCalendarHeight,
      }}
    >
      <FullCalendar
        ref={calendarRef}
        plugins={plugins}
        initialView="dayGridMonth"
        selectable={false}
        dateClick={handleDateClick}
        selectAllow={selectAllow}
        fixedWeekCount={true}
        datesSet={handleDatesSet}
        height={maxCalendarHeight}
        headerToolbar={{
          left: 'prev',
          center: 'title',
          right: 'next',
        }}
        events={currentEvents}
      />
      {isInteractable && (
        <div id="day-tooltip" className="day-tooltip">
          {tooltipContent}
        </div>
      )}
    </div>
  );
};

export default Calendar;
