import { useState } from 'react';
import { format } from 'date-fns';
import ButtonIcon from 'components/buttonIcon';
import Tooltip from 'components/tooltip';
import { ContainerDesktop } from 'components/usersTable/styles';
import { colors } from 'constants/styles/colors';
import { Note } from 'contexts/notes/types';
import { CustomNotesTableRow, NotesCell, NotesTableTextCell } from './styles';

interface INotesTableRowProps {
  note: Note;
  notesTableConfig: string;
  onEditNote(note: Note): void;
  onDeleteNoteConfirmation(note: Note): void;
}

const NotesTableRow = ({
  note,
  notesTableConfig,
  onEditNote,
  onDeleteNoteConfirmation,
}: INotesTableRowProps): JSX.Element => {
  const [showMore, setShowMore] = useState<boolean>(false);

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  const getTextContainer = (text: string) => {
    if (text.length > 70) {
      return (
        <div>
          {!showMore ? text.slice(0, 70) : text}{' '}
          <a onClick={handleShowMore} style={{ color: colors.dataVisualization.purple.base }}>
            {showMore ? '[Show less]' : '[Show more]'}
          </a>
        </div>
      );
    } else {
      return text;
    }
  };

  return (
    <CustomNotesTableRow columns={notesTableConfig} style={{ height: showMore ? 'auto' : '70px' }}>
      <ContainerDesktop>
        <NotesCell showMore={showMore}>
          <strong>{note.created_by}</strong>
        </NotesCell>
      </ContainerDesktop>
      <ContainerDesktop>
        <NotesTableTextCell className="d-grid">
          {getTextContainer(note.text)}
          {note.modified_by && (
            <small>{`Edited by ${note.modified_by}, ${format(
              new Date(note.updated_at),
              'p - yyyy/MM/dd'
            )} `}</small>
          )}
        </NotesTableTextCell>
      </ContainerDesktop>
      <ContainerDesktop>
        <NotesCell showMore={showMore}>
          {format(new Date(note.created_at), 'p - yyyy/MM/dd')}
        </NotesCell>
      </ContainerDesktop>
      <NotesCell showMore={showMore}>
        <ContainerDesktop>
          <Tooltip placement="bottomRight" title="Edit note">
            <ButtonIcon $secondary size="small" icon="Edit" onClick={() => onEditNote(note)} />
          </Tooltip>
        </ContainerDesktop>
        <ContainerDesktop>
          <Tooltip placement="bottomRight" title="Delete note">
            <ButtonIcon
              $secondary
              size="small"
              icon="Trash"
              onClick={() => onDeleteNoteConfirmation(note)}
            />
          </Tooltip>
        </ContainerDesktop>
      </NotesCell>
    </CustomNotesTableRow>
  );
};

export default NotesTableRow;
