import { FC, useContext } from 'react';
import styled from 'styled-components';
import Button from 'components/button';
import { Card } from 'components/layout/profile/profileContent/styles';
import { screenMd } from 'constants/styles/breakpoints';
import InviteUsers from 'pages/Users/components/InviteUsers';
import AccountOwnerItem from './AccountOwnerItem';
import { AccountHeaderProfileProps } from 'contexts/accounts/types';
import { SessionContext } from 'contexts/session';
import { UserInvites } from 'contexts/users/types';

interface AccountOwnerCardProps extends AccountHeaderProfileProps {
  users: UserInvites[];
  pendings: UserInvites[];
}

const AccOwnersCardContainer = styled.div`
  > h5 {
    font-weight: 700;
    font-size: 20px;
    ${screenMd} {
      text-align: center;
      font-size: 24px;
    }
  }
  > .accOwnersContainer {
    display: flex;
    flex-direction: column-reverse;
  }
  > button {
    margin-top: 25px;
    margin-left: 60px;
  }
`;

const AccountOnwersCard: FC<AccountOwnerCardProps> = ({
  users,
  pendings,
  handleModal,
  account,
  refreshData,
}) => {
  const { isImpersonated, isTeamManager, isAccountOwner } = useContext(SessionContext);
  const isOwnerOrManager = isTeamManager || isAccountOwner;

  const accountInfo = {
    ...account,
    licenses_available:
      account.licenses_details?.count === null
        ? true
        : account.licenses_details?.count - account.licenses_details?.used,
    id: account.id,
  };

  const handleInviteOwners = (result) => {
    handleModal(null);
    result === 'ok' && refreshData();
  };

  const handleAddAccOwner = () => {
    handleModal(
      <InviteUsers
        account={accountInfo}
        isEditingInvite={true}
        inviteAccOwner
        onClose={handleInviteOwners}
      />
    );
  };

  return (
    <Card>
      <AccOwnersCardContainer>
        <h5>{users.length > 1 ? 'Account owners' : 'Account owner'}</h5>
        <div className={'accOwnersContainer'}>
          {pendings.map((user, index) => {
            return (
              <AccountOwnerItem
                key={user.id}
                {...user}
                zIndex={index + 1 + users.length}
                accountId={account.id}
                operationCallback={refreshData}
              />
            );
          })}
          {users.map((user, index) => {
            return (
              <AccountOwnerItem
                key={user.id}
                {...user}
                active
                zIndex={index + 1 + pendings.length}
                accountId={account.id}
                operationCallback={refreshData}
              />
            );
          })}
        </div>
        {!isImpersonated && !isOwnerOrManager && (
          <Button $secondary onClick={handleAddAccOwner}>
            Add account owner
          </Button>
        )}
      </AccOwnersCardContainer>
    </Card>
  );
};

export default AccountOnwersCard;
