import { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import CreateNote from 'components/Notes/CreateNote';
import { NotesContext } from 'contexts/notes';
import { SectionCard } from 'pages/Analytics/components/AnalyticsContent/styles';
import {
  Content,
  Title,
  TitleWrapper,
} from 'pages/Analytics/components/ActiveUsersCollapse/styles';
import ButtonIcon from 'components/buttonIcon';
import NotesFilters from 'components/Notes/NotesFilters';
import NotesTable from 'components/Notes/NotesTable';
import { Account } from 'contexts/accounts/types';
import Button from 'components/button';
import { Note } from 'contexts/notes/types';
import { ConfirmModal } from 'components/modal';

interface IAccounNotesContentProps {
  account: Account;
}

const AccountNotesContent = ({ account }: IAccounNotesContentProps): JSX.Element => {
  const { fetching, actions } = useContext(NotesContext);
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [showDeleteNoteModal, setShowDeleteNoteModal] = useState(false);
  const [noteSelected, setNoteSelected] = useState<Note>(null);

  const toggleCollapse = () => {
    setCollapsed((prevValue) => !prevValue);
  };

  useEffect(() => {
    actions.onChangeFilters([
      { name: 'content', value: 'account' },
      { name: 'content_id', value: account.id },
    ]);
  }, []);

  const handleCreateNoteModalResponse = (result) => {
    setShowNoteModal(false);
    setNoteSelected(null);
    if (result === 'ok') {
      actions.onNotesRefresh();
      actions.onRefreshNoteUsers();
    }
  };

  const handleEditNote = (note: Note) => {
    setNoteSelected(note);
    setShowNoteModal(true);
  };

  const handleDeleteNote = (note: Note) => {
    setNoteSelected(note);
    setShowDeleteNoteModal(true);
  };

  const handleDeleteNoteCofirmation = () => {
    actions.onDeleteNote(noteSelected.id);
    setNoteSelected(null);
    setShowDeleteNoteModal(false);
  };

  return (
    <>
      <SectionCard rounded shadow dashboard>
        <header>
          <TitleWrapper>
            <Title>Notes</Title>
            <div className="d-flex">
              <Button
                className="add-note-button"
                $secondary
                icon={'Clip'}
                size={'small'}
                onClick={() => setShowNoteModal(true)}
              >
                Add note
              </Button>
              <ButtonIcon
                style={{ marginLeft: '10px' }}
                $terciary
                size="small"
                icon={collapsed ? 'ChevronUp' : 'ChevronDown'}
                onClick={() => toggleCollapse()}
              />
            </div>
          </TitleWrapper>
        </header>
        {!collapsed && (
          <>
            <Content fetching={fetching} className="mt-5">
              <Row>
                <Col lg={5} md={24} sm={24} xs={24}>
                  <NotesFilters />
                </Col>
                <Col lg={19} md={24} sm={24} xs={24}>
                  <NotesTable
                    onEditNote={handleEditNote}
                    onDeleteNoteConfirmation={handleDeleteNote}
                    onAddNote={() => setShowNoteModal(true)}
                  />
                </Col>
              </Row>
            </Content>
          </>
        )}
      </SectionCard>
      {showNoteModal && (
        <CreateNote
          isModalOpen={showNoteModal}
          note={noteSelected}
          entity={account}
          onClose={handleCreateNoteModalResponse}
        />
      )}
      <ConfirmModal
        open={showDeleteNoteModal}
        title={'Delete note'}
        description="Are you sure you want to delete this note?"
        acceptLabel={'Delete note'}
        cancelLabel={'Cancel'}
        onAccept={() => handleDeleteNoteCofirmation()}
        onCancel={() => setShowDeleteNoteModal(false)}
      />
    </>
  );
};

export default AccountNotesContent;
