import styled from 'styled-components';
import { screenSm } from 'constants/styles/breakpoints';
import useWindowSize from 'hooks/useWindowSize';
import {
  IGroupItemCourse,
  IGroupItemLab,
  IGroupItemLearningPath,
  IGroupItemLink,
  IGroupItemSlide,
  IGroupItemVideo,
} from 'contexts/playlists/types';
import { CONTENT_TYPES } from 'constants/playlists';

export const GroupItemProgressContainer = styled.div`
  display: flex;
  align-items: center;
  width: 310px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #57575c;
  gap: 10px;
  margin-left: 20px;
  text-transform: capitalize;
  ${screenSm} {
    margin: 0px;
  }
`;

const BarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 160px;
  height: 4px;
  position: relative;
  background: #f2f2f7;
  border-radius: 30px;
`;

const Bar = styled.div<{ progress?: number }>`
  width: ${({ progress = 0 }) => progress}%;
  height: 4px;
  position: absolute;
  top: 0;
  left: 0;
  background: #a793f6;
  border-radius: 50px;
`;

const Step = styled.div<{ active: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${({ active }) => (active ? '#a793f6' : '#f2f2f7')};
  border-radius: 50%;
`;

interface IProgressBarProps {
  item:
    | IGroupItemCourse
    | IGroupItemLink
    | IGroupItemVideo
    | IGroupItemLab
    | IGroupItemLearningPath
    | IGroupItemSlide;
}

const GroupItemProgress: React.FC<IProgressBarProps> = ({ item }) => {
  const { isScreenLg } = useWindowSize();

  const getRoundedProgress = (progress: number) =>
    Math.round((progress * 100 + Number.EPSILON) * 100) / 100;

  const BarTypeComponent = () => {
    switch (item.content_type) {
      case 'video':
      case 'iframe':
      case 'lab': {
        const isStarted = item.user_status !== 'unstarted';
        const isFinished = item.user_status === 'finished';
        const progress = isFinished ? 100 : isStarted ? 50 : 0;

        return (
          <>
            <BarContainer>
              <Step active={isStarted} />
              <Step active={isStarted} />
              <Step active={isFinished} />
              <Bar progress={progress} />
            </BarContainer>
            <span>{item.user_status === 'unstarted' ? 'Not started' : item.user_status}</span>
          </>
        );
      }
      case 'learning-path':
      case CONTENT_TYPES.COURSE.toLowerCase(): {
        const progress = getRoundedProgress(item.user_status['progress']);

        return (
          <>
            <BarContainer>
              <Bar progress={progress} />
            </BarContainer>
            <span>{progress}%</span>
          </>
        );
      }
      case CONTENT_TYPES.LINK.toLowerCase(): {
        const isVisited = item.user_status === 'visited';

        return (
          <>
            <BarContainer>
              <Step active={isVisited} />
              <Step active={isVisited} />
              <Bar progress={isVisited ? 100 : 0} />
            </BarContainer>
            <span>{item.user_status}</span>
          </>
        );
      }
      default: {
        return <span></span>;
      }
    }
  };

  return (
    <GroupItemProgressContainer>
      {isScreenLg && <span>Progress</span>}
      <BarTypeComponent />
    </GroupItemProgressContainer>
  );
};

export default GroupItemProgress;
