import { useContext } from 'react';
import { Timeline } from 'antd';
import { useScrollDirection } from 'react-use-scroll-direction';
import { ActivityLogContext } from 'contexts/activityLog';
import { ActivityLogListContainer } from '../styles';
import ActivityLogItem from './ActivityLogItem';
import { ACTION_TYPES_BYPASSED } from 'contexts/activityLog/types';

const isInViewport = (el) => {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
  );
};

const ActivityLogList = (): JSX.Element => {
  const {
    table,
    actions,
    fetching,
    filters: { page },
    isEmpty,
  } = useContext(ActivityLogContext);
  const { isScrollingDown, scrollTargetRef } = useScrollDirection();

  const handleScroll = (e) => {
    if (
      isScrollingDown &&
      !fetching &&
      table.items.length !== table.count &&
      isInViewport(e.target.firstChild.lastChild)
    ) {
      actions.onChangeFilter({ name: 'page', value: (parseInt(page) + 1).toString() });
    }
  };
  return (
    <ActivityLogListContainer onScroll={handleScroll} ref={scrollTargetRef}>
      {!isEmpty && (
        <Timeline>
          {table?.items?.map((item, index) => {
            return item.generated_by ||
              (!item.generated_by && ACTION_TYPES_BYPASSED.includes(item.action)) ? (
              <ActivityLogItem key={index} activity={item} />
            ) : (
              <div key={index} />
            );
          })}
        </Timeline>
      )}
    </ActivityLogListContainer>
  );
};

export default ActivityLogList;
