/**
 * `LinkItem Container`
 * @param {IPlaylistLink} playlistLink optionals components.
 * @returns {JSX.Element} Component.
 */

import './styles.scss';
import { IContentItem, IPlaylistLink } from 'contexts/playlists/types';
import ButtonIcon from 'components/buttonIcon';
import ContentEditable from 'components/contentEditable';
import Icon from 'components/icons';

type Props = {
  data: IPlaylistLink;
  onSave?: (link: IPlaylistLink | IContentItem) => void;
  onDelete?: () => void;
};

const LinkItem = ({ data, onSave: handleSave, onDelete }: Props): JSX.Element => {
  const { name, description, link_url } = data;

  const onFieldSave = (name: string, value: string) => {
    handleSave({ ...data, [name]: value });
  };

  const openLink = () => {
    window.open(link_url, '_blank');
  };
  const handleDelete = () => {
    if (onDelete) onDelete();
  };

  return (
    <div className="link">
      <div className="link__img" onClick={openLink}>
        <Icon icon="Link" />
      </div>
      <div className="link__content">
        <div className="link__main-wrapper">
          <span className="link__url">{link_url}</span>

          <ContentEditable
            inputClass="link__name"
            value={name}
            minLenght={1}
            onSave={(v) => onFieldSave('name', v)}
          />

          <ContentEditable
            inputClass="link__description"
            value={description}
            placeholder="+ Add description"
            onSave={(v) => onFieldSave('description', v)}
          />
        </div>
        <div className="link__buttons-wrapper">
          <ButtonIcon
            data-testid="delete-link-btn"
            className="link__delete-button"
            size="small"
            icon="Cancel"
            onClick={handleDelete}
          />
        </div>
      </div>
    </div>
  );
};

export default LinkItem;
