import { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { SessionContext } from 'contexts/session';
import { Team } from 'contexts/teams/types';
import { StickyFooter } from 'components/cards';
import styled from 'styled-components';

import ModalDrawer from 'components/microLayouts/drawer';
import Button from 'components/button/Button';
import ButtonIcon from 'components/buttonIcon';
import Tooltip from 'components/tooltip';

import AvatarCard from './AvatarCard';
import Activity from './Activity';
import Active from './Active';
import Manager from './Manager';
import { TeamsContext } from 'contexts/teams';
import useInviteToTeam from '../hooks/useInviteToTeam';

interface TeamsSettingsProps {
  data: Team;
  onRemoveTeam?: (ids: string[]) => void;
}

const Columns = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 20px;
`;

const Container = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 14px 0px;
  width: 100%;
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.default.colors.palette.Business_LightThemeGreyDarker};
  span {
    color: ${({ theme }) => theme.default.colors.palette.Business_LightThemeGrayDark};
  }
`;

const TeamsSettings = ({ data, onRemoveTeam }: TeamsSettingsProps): JSX.Element => {
  const {
    table: { items: teams },
    actions: teamsActions,
  } = useContext(TeamsContext);
  const {
    account,
    showDrawer,
    setVisibiltyDrawer,
    licenses_available,
    actions: sessionActions,
  } = useContext(SessionContext);
  const { inviteToTeamModal, openInviteToTeamModal, closeInviteToTeamModal } = useInviteToTeam();
  const [id, setId] = useState(null);

  const handlerDrawer = ({ id }: { id: string }) => {
    setId(id);
    setVisibiltyDrawer(!showDrawer);
  };

  const close = () => {
    setVisibiltyDrawer(false);
  };

  useEffect(() => {
    !showDrawer && setId(null);
  }, [showDrawer]);

  const onAddUser = () => {
    const team = teams.find((team) => team.id === id);
    const handleFinishInvite = (afterInvite) => {
      if (afterInvite) {
        if (!licenses_available) {
          sessionActions.refreshSession();
        }
        teamsActions.onForceTeamsRequest();
      }
      closeInviteToTeamModal();
    };
    openInviteToTeamModal({
      account: account,
      isInvite: true,
      team: team,
      onClose: handleFinishInvite,
    });
  };

  return (
    <>
      <Tooltip placement="left" title={'Go to team settings'}>
        <ButtonIcon
          $terciary
          icon="ChevronRight"
          size="small"
          onClick={() => {
            handlerDrawer({ id: data.id });
          }}
        />
      </Tooltip>

      <ModalDrawer
        closable={false}
        placement="bottom"
        visible={showDrawer && data.id === id}
        onClose={close}
        height="calc(100% - 64px)"
        destroyOnClose
      >
        {showDrawer && (
          <>
            <Container>
              <Content>
                <AvatarCard data={data} />
              </Content>
              <Content>
                <Label>USERS’ ACTIVITY</Label>
                <Activity />
              </Content>
              <Columns>
                <Content>
                  <Label>
                    {data.active_users_count || 0} USERS{' '}
                    <span>{data.pending_users_count || 0} Pending</span>
                  </Label>
                  <Active data={data.active_users} />
                </Content>
                <Content>
                  <Label>MANAGER(S)</Label>
                  <Manager data={data.team_managers} />
                </Content>
              </Columns>
              <Tooltip placement="left" title={'Add user'}>
                <ButtonIcon
                  style={{ margin: '0px 5px' }}
                  $secondary
                  size={'small'}
                  icon={'UserPlus'}
                  onClick={onAddUser}
                />
              </Tooltip>
            </Container>

            {inviteToTeamModal}

            <StickyFooter>
              <NavLink to={`/team/${data.id}`} className={'settings-bottom-button'}>
                <Tooltip placement="left" title={'Go to team profile'}>
                  <Button $full $secondary size={'small'} icon={'ChevronRight'}>
                    See team
                  </Button>
                </Tooltip>
              </NavLink>
              <Tooltip placement="left" title={'Remove team'}>
                <Button
                  $full
                  $secondary
                  size={'small'}
                  icon={'Trash'}
                  className={'settings-bottom-button'}
                  onClick={() => onRemoveTeam([data.id])}
                >
                  Remove Team
                </Button>
              </Tooltip>
            </StickyFooter>
          </>
        )}
      </ModalDrawer>
    </>
  );
};

export default TeamsSettings;
