import { useEffect, useState } from 'react';
import cx from 'classnames';
import './progressScore.scss';

interface IProgressScore {
  score: number;
}

const ProgressScore = ({ score }: IProgressScore): JSX.Element => {
  const [delayedScore, setDeleyedScore] = useState(720);

  const scoreFilled = (1 - score) * 720;

  const percentToShow = Math.round(score * 100);

  const donutColor = ['#70FFD4', '#70FFD4'];

  useEffect(() => {
    setDeleyedScore(scoreFilled);
  });

  return (
    <>
      <div className={cx('progress-score', { 'progress-score__passed': score === 100 })}>
        <svg
          version="1.1"
          className="valuescore"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 250 250"
          enableBackground="new 0 0 250 250"
          xmlSpace="preserve"
        >
          <defs>
            <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" stopColor={donutColor[0]} stopOpacity="1.0" />
              <stop offset="100%" stopColor={donutColor[1]} stopOpacity="1.0" />
            </linearGradient>
            <linearGradient id="empty" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" stopColor="#EEEEEE" stopOpacity="1.0" />
              <stop offset="100%" stopColor="#EEEEEE" stopOpacity="1.0" />
            </linearGradient>
          </defs>
          <circle
            className="progress-score__outer"
            cx="125"
            cy="125"
            r="110"
            stroke="url(#empty)"
          />
          <circle
            stroke="url(#linear)"
            className="progress-score__inner"
            cx="125"
            cy="125"
            r="110"
            style={{ strokeDashoffset: delayedScore }}
          />
        </svg>
      </div>
      <div style={{ marginLeft: '5px' }}>{percentToShow}% completed</div>
    </>
  );
};

export default ProgressScore;
