import styled from 'styled-components';
import { screenMd } from 'constants/styles/breakpoints';

export const InputTypeSelect = styled.div<{ error: boolean }>`
  background: rgb(242, 242, 247);
  border-radius: 10px;
  border-color: #f2f2f7;
  flex-grow: 0;
  height: 34px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 3.5px 16px 3.5px 10px;

  > span {
    width: 97px;
    margin-right: 12px;
  }
  > div,
  img {
    width: 37px;
    height: 37px;
    margin-right: 10px;
  }
  > svg {
    margin-left: auto;
    cursor: pointer;
  }
  ${({ error }) =>
    error &&
    `
		box-shadow: 0px 0px 0px 1px red;
		`}
`;

export const SelectDropdown = styled.div<{ full?: boolean }>`
  width: auto;
  ${({ full }) => full && 'width: 100%'};
  padding: 5px;
  background: #ffffff;
  box-shadow: 0px 30px 48px rgba(0, 0, 0, 0.2);
  position: relative;
  border-radius: 6px;
  > div {
    max-height: 230px;
    overflow-y: scroll;
  }
  ${screenMd} {
    min-width: 350px;
  }
`;

export const InputSelectOptions = styled.div<{ disabled?: boolean }>`
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: start;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px;
  box-sizing: border-box;
  border: none;
  background: #ffffff;
  ${({ disabled }) => disabled && 'opacity: 0.5; pointer-events: none;'};
  &:focus,
  &:hover {
    background: ${({ theme }) => theme.default.colors.buttons.blackGray7};
  }
  &:active {
    background: ${({ theme }) => theme.default.colors.buttons.blackGray6};
  }
`;
