import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
`;

export const Sku = styled.div`
  color: var(--light-gray-darker);
  font-size: 10px;
`;

export const Divider = styled.div`
  width: 100%;
  border: 1px solid var(--dusty-gray);
  margin: 20px 0;
`;

export const SectionTitle = styled.h3`
  color: var(--light-gray-darkest);
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 10px;
`;

export const SectionInfo = styled.h5`
  color: var(--light-gray-darker);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 20px;
`;
