import { CONTENT_TYPES, LEARNING_PATH_SHORT_SLUG } from 'constants/playlists';
import { AlgoliaContent } from 'contexts/content/types';

export const buildContentLink = (content: AlgoliaContent): string => {
  const { LEARNING_PATH, VIDEO } = CONTENT_TYPES;
  const { objectID, slug, content_type, learning_areas: areas } = content;
  if (content_type !== VIDEO.toLowerCase()) {
    const id = [LEARNING_PATH, LEARNING_PATH_SHORT_SLUG].includes(content_type)
      ? objectID
      : objectID.slice(0, 8);
    const area = areas && areas[0] ? areas[0].name.replace(/\s+/g, '') : '';
    const content_type_slug = `${content_type}s`;
    const content_link = `/${area}/${content_type_slug}/${id}/${slug}`;
    return `${process.env.REACT_APP_PUBLIC_URL}${content_link}`;
  }
  const content_link = `/search?search=${slug}&Content+Type=${content_type}`;
  return `${process.env.REACT_APP_PUBLIC_URL}${content_link}`;
};
