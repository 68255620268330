import { createContext, useEffect, useState } from 'react';
import { FCProps } from 'types/FCProps';
import { AccountEditCreateAction, AccountsDataCtx, Staff } from './types';
import { accountsInitialValues } from './utils';
import useAccountsTableContent from 'hooks/useAccountsTableContent';
import { ConfirmModal, ConfirmModalProps } from 'components/modal';
import CreateAccount from 'pages/Accounts/components/CreateAccount';
import { getStaff } from './actions';

export const AccountsContext = createContext<AccountsDataCtx>(accountsInitialValues);

const confirmModalDefaultState = { open: false, title: null, onAccept: null, onCancel: null };

const AccountsProvider = ({ children }: FCProps): JSX.Element => {
  const {
    accountsTable,
    fetching,
    isEmpty,
    filters,
    counts,
    expireTableItem,
    onChangeFilters,
    setIsAutoApply,
    onClearSearch,
    onApplySearch,
    onChangeRangeFilters,
    onAccountEdition,
    onForceAccountsRequest,
    onSsoToggled,
  } = useAccountsTableContent();

  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [accountCreateEdit, setAccountCreateEdit] = useState<AccountEditCreateAction>();
  const [staff, setStaff] = useState<Staff[]>([]);
  const [confirmModalState, setConfirmModalState] =
    useState<ConfirmModalProps>(confirmModalDefaultState);

  const onSelectRow = (id: string) => {
    const findRow = selectedItems.find((rowId) => rowId === id);
    if (findRow) setSelectedItems((prevState) => prevState.filter((rowId) => rowId !== id));
    else setSelectedItems((prevState) => [...prevState, id]);
  };

  const onSelectAllRows = () => {
    const areAllRowsSelected = selectedItems.length === accountsTable?.items?.length;
    if (areAllRowsSelected) setSelectedItems([]);
    else setSelectedItems(accountsTable?.items?.map((i) => i.id) || []);
  };

  const clearSelectedItems = () => {
    setSelectedItems([]);
  };

  const onExpireAccount = (accountId: string) => {
    const title = 'Expire Account';

    setConfirmModalState({
      open: true,
      title,
      description: 'Are you sure you want to expire the account?',
      acceptLabel: title,
      cancelLabel: 'Cancel',
      onAccept: () => expireAccount(accountId),
      onCancel: () => setConfirmModalState(confirmModalDefaultState),
    });
  };

  const expireAccount = (accountId: string) => {
    expireTableItem(accountId);
    setSelectedItems([]);
    setConfirmModalState(confirmModalDefaultState);
  };

  const onAccountCreateEdit = ({ type, account }: AccountEditCreateAction) => {
    setAccountCreateEdit({ type, account });
  };

  const updateIneStaff = async () => {
    const newStaff = await getStaff();
    if (!(newStaff instanceof Error)) {
      setStaff(newStaff);
    }
  };

  const handleAccountCreation = (result: string) => {
    setAccountCreateEdit(null);
    if (result === 'ok') {
      onForceAccountsRequest();
    }
  };

  useEffect(() => {
    updateIneStaff();
  }, []);

  return (
    <AccountsContext.Provider
      value={{
        fetching,
        table: accountsTable,
        selectedItems,
        isEmpty,
        filters,
        counts,
        staff,
        actions: {
          onSelectRow,
          onSelectAllRows,
          onChangeFilters,
          onApplySearch,
          setIsAutoApply,
          onClearSearch,
          onChangeRangeFilters,
          onExpireAccount,
          onAccountEdition,
          onForceAccountsRequest,
          onAccountCreateEdit,
          onSsoToggled,
          clearSelectedItems,
        },
      }}
    >
      <>
        {children}
        <ConfirmModal {...confirmModalState} />
        {!!accountCreateEdit && (
          <CreateAccount
            isVisible={true}
            managePlan={accountCreateEdit.type === 'managePlan'}
            editAccount={accountCreateEdit.type === 'edit'}
            onClose={handleAccountCreation}
            account={accountCreateEdit.account}
            onAccountEdition={onAccountEdition}
          />
        )}
      </>
    </AccountsContext.Provider>
  );
};

export default AccountsProvider;
