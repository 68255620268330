import { useContext } from 'react';
import { INE_CONTACT_SALES } from 'constants/settings';
import { AccountAddon } from 'contexts/accounts/types';
import { SessionContext } from 'contexts/session';
import { CustomCard, DataContainer, Disclaimer } from '../AccountLicensesCard/AccountLicensesCard';
import LicenseDataItem from '../AccountLicensesCard/LicenseDataItem';

interface AccountAddonsCardProps {
  accountAddon: AccountAddon;
}

const AccountAddonsCard = ({ accountAddon }: AccountAddonsCardProps): JSX.Element => {
  const { isStaff, account } = useContext(SessionContext);

  const mailtoUrl = isStaff
    ? `mailto:${INE_CONTACT_SALES}`
    : `mailto:${INE_CONTACT_SALES},${account?.sales_contact?.email}`;

  const totalTooltip = accountAddon?.name
    ? `Total number of ${accountAddon?.name} licenses purchased`
    : null;
  const totalAssignedTooltip = accountAddon?.name
    ? `Number of ${accountAddon?.name} licenses assigned to active or pending users within the account`
    : null;
  const availableToAssignTooltip = accountAddon?.name
    ? `Number of ${accountAddon?.name} licenses still available to assign`
    : null;

  return (
    <CustomCard>
      <h5>{accountAddon?.name} Licenses</h5>
      <DataContainer>
        <LicenseDataItem count={accountAddon?.count} label="Total" tooltip={totalTooltip} />
        <LicenseDataItem
          count={accountAddon?.used}
          label="Assigned"
          tooltip={totalAssignedTooltip}
        />
        <LicenseDataItem
          count={accountAddon?.count - accountAddon?.used}
          label="Available"
          tooltip={availableToAssignTooltip}
        />
      </DataContainer>
      <Disclaimer style={{ marginTop: 10 }}>
        Need more licenses? <a href={mailtoUrl}>Contact sales.</a>
      </Disclaimer>
    </CustomCard>
  );
};

export default AccountAddonsCard;
