import { colors } from 'constants/styles/colors';
import styled from 'styled-components';

export const ChartTooltip = styled.div`
  box-shadow: '0px 4px 8px rgba(0, 0, 0, 0.200691)';
  border-radius: 4px;
  width: fit-content;
  padding: 8px 16px;
  background: ${colors.fonts.darkGray};
  color: ${colors.fonts.white};
  font-size: 14px;
  line-height: 20px;
  margin-left: 10px;
  ::before {
    content: '';
    border-style: solid;
    border-width: 10px 15px 10px 0;
    border-color: transparent ${colors.fonts.darkGray} transparent transparent;
    position: absolute;
    left: 0px;
  }
`;
