import { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { PLAYLIST_TYPE } from 'constants/playlists';
import Notification from 'components/notification';
import { playlistInitialValues } from 'contexts/playlists/utils';
import { IPlaylist, IPlaylistUserListItemResponse } from '../contexts/playlists/types';
import {
  deletePlaylist,
  editPlaylist,
  getPlaylist,
  getPlaylistUsersList,
  unAssignUsers,
} from 'services/playlists';
import { SessionContext } from 'contexts/session';

interface IPlaylistContentHook {
  fetching: boolean;
  setFetching: (v: boolean) => void;
  setPlaylist: (IPlaylist) => void;
  fetchingUsers: boolean;
  playlist: IPlaylist;
  playlistUsers: IPlaylistUserListItemResponse[];
  error: string | boolean;
  refreshPlaylist: () => void;
  callEditPlaylist: (playlist: IPlaylist) => void;
  callDeletePlaylist: (playlist: IPlaylist) => void;
  callGetUsers: (isSonar: boolean) => void;
  callRemoveUser: (item: IPlaylistUserListItemResponse) => void;
}

const usePlaylistData = (): IPlaylistContentHook => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const [playlist, setPlaylist] = useState(playlistInitialValues);
  const [fetching, setFetching] = useState(false);
  const [fetchingUsers, setFetchingUsers] = useState(false);
  const { account } = useContext(SessionContext);

  const [playlistUsers, setPlaylistUsers] = useState<IPlaylistUserListItemResponse[]>([]);

  const business_account_id = account?.id;

  const getPlaylistData = async () => {
    try {
      setFetching(true);
      const response = await getPlaylist({
        id,
        business_account_id,
      });
      if (response.status === 200) {
        const { data } = response;
        response.status === 200 && setPlaylist(data);
      }
      setFetching(false);
    } catch (error) {
      const msg = error?.detail
        ? error?.detail
        : 'Something went wrong, if it repeats please contact support.';
      Notification({ text: msg, type: 'error' });
    }
    setFetching(false);
  };

  const callEditPlaylist = async (data) => {
    setFetching(true);
    try {
      const response = await editPlaylist(data);

      if (response.status === 200) {
        Notification({ text: `${response.data.name} playlist has been updated`, type: 'success' });
        setFetching(false);
      } else {
        Notification({ text: 'There has been a problem editing your playlist', type: 'error' });
      }
    } catch (error) {
      if (error?.response?.data?.details) {
        Notification({ text: error.response.data.details, type: 'error' });
      }
    }
    setFetching(false);
  };

  const callDeletePlaylist = async (playlist: IPlaylist) => {
    try {
      setFetching(true);
      await deletePlaylist({
        id: playlist.id,
        business_account_id,
      });
      setFetching(false);
      Notification({ text: `${playlist.name} has been deleted`, type: 'success' });
      history.push('/playlists');
    } catch (error) {
      Notification({ text: 'There has been a problem deleting your playlist', type: 'error' });
    }
  };

  const callGetUsers = async (isSonar: boolean) => {
    if (playlist.assigned_user_ids?.length) {
      setFetchingUsers(true);
      const ids = isSonar ? [playlist.assigned_user_ids[0]] : playlist.assigned_user_ids;
      try {
        const responseUsers = await getPlaylistUsersList({
          account_id: business_account_id,
          playlist_id: playlist.id,
        });
        if (responseUsers.status === 200) {
          const data = responseUsers.data;
          responseUsers.status === 200 && setPlaylistUsers(data);
        }
      } catch (error) {
        Notification({ text: 'There has been a problem', type: 'error' });
      }
      setFetchingUsers(false);
    } else {
      setPlaylistUsers([]);
    }
  };

  const callRemoveUser = async (item: IPlaylistUserListItemResponse) => {
    try {
      await unAssignUsers({
        business_account_id,
        user_ids: [item.user.id],
        id: playlist.id,
      });
    } catch (error) {
      Notification({ text: 'There has been a problem', type: 'error' });
    }
    setFetchingUsers(false);
  };

  return {
    fetching,
    setFetching,
    setPlaylist,
    fetchingUsers,
    playlist,
    playlistUsers,
    error: false,
    refreshPlaylist: getPlaylistData,
    callEditPlaylist,
    callDeletePlaylist,
    callGetUsers,
    callRemoveUser,
  };
};

export default usePlaylistData;
