import React from 'react';
import Button from 'components/button';
import EllipsisGroup from 'components/ellipsisGroup';

import { BackButtonContainer, Container, EllipsisContainer, NextButtonsContainer } from './styles';
import { isMobile } from 'react-device-detect';

interface IFooter {
  disabled?: boolean;
  hasBackButton?: boolean;
  steps: number;
  stepActive: number;
  onNextPress?: () => void;
  onBackPress?: () => void;
  nextLabel?: string;
}

const Footer: React.FC<IFooter> = ({
  disabled,
  hasBackButton,
  steps,
  stepActive,
  onNextPress,
  onBackPress,
  nextLabel = 'Next',
}) => {
  return (
    <Container>
      <BackButtonContainer>
        {hasBackButton && (
          <Button $terciary icon="ArrowLeft" onClick={onBackPress} size={isMobile ? 'small' : null}>
            Back
          </Button>
        )}
      </BackButtonContainer>
      <EllipsisContainer>
        <EllipsisGroup steps={steps} stepActive={stepActive} />
      </EllipsisContainer>
      <NextButtonsContainer>
        <Button
          data-testid="add-by-email-next-button"
          $primary
          onClick={onNextPress}
          disabled={disabled}
        >
          {nextLabel}
        </Button>
      </NextButtonsContainer>
    </Container>
  );
};

export default Footer;
