import { Dispatch, useState } from 'react';

interface UseModalHook {
  modal: JSX.Element;
  setModal: Dispatch<JSX.Element>;
}

const useModal = (): UseModalHook => {
  const [modal, setModal] = useState<JSX.Element>(null);
  return {
    modal,
    setModal,
  };
};

export default useModal;
