import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import useSearchQuery from './useSearchQuery';
import { accountsInitialValues } from 'contexts/accounts/utils';
import { Account, AccountsDataCtx } from 'contexts/accounts/types';
import {
  getAccountsAction,
  getAccountsCounts,
  getExpireAccountsAction,
  getRemoveAccountsAction,
  handleSsoToggle,
} from 'contexts/accounts/actions';
import { FilterItem, FilterProps, FilterRange, GetCountsProps } from 'contexts/accounts/types';
import Notification from 'components/notification';
import { URLSearchParams as URLSearchParamsTypes } from '../contexts/users/types';

interface AccountsContentHook {
  fetching: boolean;
  filters: FilterProps;
  counts: GetCountsProps;
  isEmpty: boolean;
  accountsTable: AccountsDataCtx['table'];
  error: string | boolean;
  updateTable: (items: Account[]) => void;
  removeTableItem: (ids: string[]) => void;
  expireTableItem: (accountId: string) => void;
  onChangeFilters: (filter: FilterItem) => void;
  onChangeRangeFilters: (range: FilterRange) => void;
  onAccountEdition: (account: Account) => void;
  onForceAccountsRequest: () => void;
  onClearSearch: () => void;
  onApplySearch: () => void;
  setIsAutoApply: Dispatch<SetStateAction<boolean>>;
  onSsoToggled: (value: boolean, id: string) => void;
}

function updateStoreFilters(search: URLSearchParamsTypes) {
  const defaultFilters = accountsInitialValues.filters;

  return {
    page: search.get('page') || defaultFilters.page,
    page_size: search.get('page_size') || defaultFilters.page_size,
    search: search.get('search') || defaultFilters.search,
    pricing_plan: search.get('pricing_plan')?.split(',') || defaultFilters.pricing_plan,
    status: search.get('status')?.split(',') || defaultFilters.status,
    is_free_trial: search.get('is_free_trial') || defaultFilters.is_free_trial,
    end_date_from: search.get('end_date_from') || defaultFilters.end_date_from,
    end_date_to: search.get('end_date_to') || defaultFilters.end_date_to,
    licenses_from: search.get('licenses_from') || defaultFilters.licenses_from,
    licenses_to: search.get('licenses_to') || defaultFilters.licenses_to,
    is_license_unlimited: search.get('is_license_unlimited') || defaultFilters.is_license_unlimited,
    ordering: search.get('ordering') || defaultFilters.ordering,
    support: search.get('support') || defaultFilters.support,
    sales: search.get('sales') || defaultFilters.sales,
    addons: search.get('addons')?.split(',') || defaultFilters.addons,
  };
}

function clearStoreFilters(search) {
  return {
    page: search.get('page') || '1',
    page_size: search.get('page_size') || '9',
    search: '',
    pricing_plan: [],
    status: ['active', 'past_due'],
    is_free_trial: 'false',
    end_date_from: '',
    end_date_to: '',
    licenses_from: '0',
    licenses_to: '0',
    is_license_unlimited: '0',
    ordering: '',
    support: '',
    sales: '',
    addons: [],
  };
}

const useAccountsTableContent = (): AccountsContentHook => {
  const [accountsTable, setAccountsTable] = useState(accountsInitialValues.table);
  const [fetching, setFetching] = useState(false);
  const [isAutoApply, setIsAutoApply] = useState(true);
  const { searchQuery, search, updateSearch, clearSearch } = useSearchQuery();
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const [filters, setFilters] = useState<FilterProps>(updateStoreFilters(search));
  const [counts, setCounts] = useState<GetCountsProps>();

  const getPaginatedAccounts = async ({ params }) => {
    setFetching(true);
    const accountsData = await getAccountsAction(params);
    if (accountsData instanceof Error) {
      setAccountsTable((prevState) => ({ ...prevState, error: accountsData?.message }));
    } else {
      setAccountsTable((prevState) => ({
        ...prevState,
        count: accountsData?.results?.count,
        items: accountsData?.results?.results,
      }));
      setIsEmpty(accountsData.results?.results.length === 0);
    }
    setFetching(false);
  };

  useEffect(() => {
    if (isAutoApply) getPaginatedAccounts({ params: handleReqData() });
  }, [searchQuery, isAutoApply]);

  useEffect(() => {
    getCounts();
  }, [accountsTable]);

  const handleReqData = () => {
    return {
      pricing_plan: search.get('pricing_plan') || null,
      status: search.get('status') || 'active,past_due',
      is_free_trial: search.get('is_free_trial') || null,
      end_date_from: search.get('end_date_from') || null,
      end_date_to: search.get('end_date_to') || null,
      licenses_from: search.get('licenses_from') || null,
      licenses_to: search.get('licenses_to') || null,
      is_license_unlimited: search.get('is_license_unlimited') || null,
      ordering: search.get('ordering') || null,
      page: parseFloat(search.get('page')) || null,
      page_size: parseInt(search.get('page_size') || '9') || null,
      search: search.get('search') || null,
      support: search.get('support') || null,
      sales: search.get('sales') || null,
      addons: search.get('addons') || '',
    };
  };

  const getCounts = async () => {
    setFetching(true);
    const dataStats = await getAccountsCounts();
    setFetching(false);
    if (dataStats instanceof Error) {
      setCounts((prevState) => ({ ...prevState, error: dataStats.message }));
    } else {
      setCounts(dataStats);
    }
  };

  const getRemoveAccounts = async (ids) => {
    setFetching(true);
    const response = await getRemoveAccountsAction({ ids });
    setFetching(false);
    if (response instanceof Error) {
      setAccountsTable((prevState) => ({ ...prevState, error: response.message }));
      Notification({ text: response.message, type: 'error' });
    } else {
      Notification({ text: 'Account(s) successfully removed', type: 'success' });
      getPaginatedAccounts({ params: handleReqData() });
    }
  };

  const getExpireAccounts = async (accountId) => {
    setFetching(true);
    const response = await getExpireAccountsAction(accountId);
    if (response instanceof Error) {
      setAccountsTable((prevState) => ({ ...prevState, error: response.message }));
      Notification({ text: response.message, type: 'error' });
    } else {
      Notification({ text: 'Account successfully expired', type: 'success' });
      getPaginatedAccounts({ params: handleReqData() });
    }
    setFetching(false);
  };

  const removeTableItem = (ids: string[]) => {
    getRemoveAccounts(ids);
  };

  const updateTable = (newContent: Account[]) => {
    setAccountsTable((prevState) => {
      return {
        ...prevState,
        items: newContent,
      };
    });
  };

  const onChangeRangeFilters = (range: FilterRange) => {
    const toName = range.toName.toString();
    const fromName = range.fromName.toString();

    const toValue = range.toValue.toString();
    const fromValue = range.fromValue.toString();

    const toMax = range.toMax.toString();

    /* const toPrevValue = search.get(toName);
		const fromPrevValue = search.get(fromName); */

    search.set(fromName, fromValue.toString());
    search.set(toName, toValue.toString());

    if (fromValue === '0') {
      search.delete(fromName);
    }
    if (toValue === toMax || toValue === '0') {
      search.delete(toName);
    }

    handleSearchApply();
  };

  const onChangeFilters = (filter: FilterItem) => {
    const slug = filter.name.toString();
    const prevValue = filters[slug];
    let value = filter.value.toString();

    if (filter.multiple && prevValue) {
      // To add values or remove values in the same query parameter.

      value = prevValue.includes(value)
        ? prevValue.filter((e) => e !== value).join(',')
        : [...prevValue, value].join(',');
    }

    // remove filter so then it is moved to the last place.
    search.delete(slug);

    // Clear paging if last filter applied is not a page change.
    slug !== 'page' && search.set('page', '1');

    switch (value) {
      case 'pricing_all':
        search.delete('pricing_plan');
        break;
      case 'licenses_all':
        search.delete('licenses_to');
        search.delete('licenses_from');
        search.delete('is_license_unlimited');
        break;
      case '':
      case prevValue:
        search.delete(slug);
        break;
      case 'false':
        if (slug === 'is_free_trial') search.delete(slug);
        break;
      case '0':
        if (slug === 'is_license_unlimited') search.delete(slug);
        break;

      default:
        search.set(slug, value);
    }

    handleSearchApply();
  };

  const handleSearchApply = () => {
    updateSearch(search?.toString());
    setFilters(updateStoreFilters(search));
  };

  const handleOnClearSearch = () => {
    clearSearch();
    setFilters(clearStoreFilters(search));
  };

  const onAccountEdition = (account: Account) => {
    const accountIndex = accountsTable.items.findIndex(({ id }) => id === account.id);
    const newAccountData = {
      ...accountsTable.items[accountIndex],
      ...account,
    };
    setAccountsTable((prevState) => {
      return {
        ...prevState,
        ...(prevState.items[accountIndex] = newAccountData),
      };
    });
  };

  const onSsoToggled = async (value: boolean, id: string) => {
    setFetching(true);
    const response = await handleSsoToggle(value, id);
    if (response instanceof Error) {
      setFetching(false);
      const notificationMsg = value ? `Couldn't enable SSO` : `Couldn't disable SSO`;
      Notification({ text: notificationMsg, type: 'error' });
    } else {
      setFetching(false);
      const notificationMsg = value ? 'SSO successfully enabled' : 'SSO successfully disabled';
      Notification({ text: notificationMsg, type: 'success' });
      getPaginatedAccounts({ params: handleReqData() });
    }
  };

  return {
    fetching: fetching,
    accountsTable: accountsTable,
    error: false,
    isEmpty,
    filters,
    counts,
    setIsAutoApply,
    removeTableItem,
    expireTableItem: getExpireAccounts,
    updateTable,
    onChangeFilters,
    onChangeRangeFilters,
    onAccountEdition,
    onClearSearch: handleOnClearSearch,
    onApplySearch: handleSearchApply,
    onForceAccountsRequest: () => getPaginatedAccounts({ params: handleReqData() }),
    onSsoToggled,
  };
};

export default useAccountsTableContent;
