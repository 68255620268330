/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-var-requires */
const { colors } = require('./colors');
const { buttons } = require('./buttons');

const theme = {
  default: {
    colors,
    buttons,
  },
};

const modifiedVariables = {};

module.exports = { theme, modifiedVariables };
