import 'pages/Playlists/components/AddContent/LinkItem/styles.scss';
import ContentEditable from 'components/contentEditable';
import useWindowSize from 'hooks/useWindowSize';
import { GroupItemDataRow } from '../styles';
import { GroupItemDataWrapper } from './styles';
import ContentTypeTag from '../../components/AddContent/ContentTypeTag';

interface IdataData {
  data: any;
  onSave: (uuid: string, key: string, value: string) => void;
}

const LinkItemData = ({ data, onSave }: IdataData): JSX.Element => {
  const { uuid, content_type, name, description, link_url } = data;
  const { isScreenSm } = useWindowSize();

  return (
    <GroupItemDataWrapper>
      <GroupItemDataRow>
        <ContentTypeTag value={content_type} />
        <p className="link__url">{link_url}</p>
      </GroupItemDataRow>
      <ContentEditable
        inputClass="link__name"
        value={name}
        minLenght={1}
        onSave={(v) => onSave(uuid, 'name', v)}
      />
      {isScreenSm && (
        <ContentEditable
          inputClass="link__description"
          value={description}
          maxLenght={90}
          placeholder="+ Add description"
          onSave={(v) => onSave(uuid, 'description', v)}
        />
      )}
    </GroupItemDataWrapper>
  );
};

export default LinkItemData;
