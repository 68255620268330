import { Button as AntdButton } from 'antd';
import styled, { css } from 'styled-components/macro';
import { IButtonProps } from './ButtonIcon';

const defaultStyles = css<{ loading: boolean }>`
  &&& {
    align-items: center;
    border-radius: ${({ theme }) => theme.default.buttons.default.borderRadius};
    border-width: ${({ theme }) => theme.default.buttons.default.borderWidth};
    padding: 0;
    > svg {
      margin: auto;
    }
    .ant-btn-loading-icon {
      position: absolute;
      top: 10px;
      left: 10px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      > span.anticon {
        padding: 0 !important;
        > svg {
          fill: ${({ theme }) => theme.default.colors.iconButtons.purple.dark} !important;
        }
      }
    }
    ${({ loading }) =>
      loading &&
      `
			pointer-events: none;
			> svg{
				display: none !important;
			}
		`}
  }
`;

const primaryStyle = css<{ $active?: boolean }>`
  &&& {
    background-color: ${({ theme }) => theme.default.colors.iconButtons.white};
    border-color: ${({ theme }) => theme.default.colors.iconButtons.purple.dark};
    > svg {
      color: ${({ theme }) => theme.default.colors.iconButtons.purple.dark};
    }
    &:active {
      background-color: ${({ theme }) => theme.default.colors.iconButtons.gray.dark};
      border-color: ${({ theme }) => theme.default.colors.iconButtons.gray.dark};
      > svg {
        color: ${({ theme }) => theme.default.colors.iconButtons.white};
      }
    }
    ${({ $active, theme }) =>
      $active &&
      `
				background-color: ${theme.default.colors.iconButtons.gray.dark};
				border-color: ${theme.default.colors.iconButtons.gray.dark};
				> svg {
					color: ${theme.default.colors.iconButtons.white};
					stroke: ${theme.default.colors.iconButtons.white};
				}
		`}
    &:disabled {
      background-color: ${({ theme }) => theme.default.colors.iconButtons.gray.light};
      border-color: ${({ theme }) => theme.default.colors.iconButtons.gray.light};
      > svg {
        color: ${({ theme }) => theme.default.colors.iconButtons.white};
      }
    }
  }
`;

const secondaryStyle = css<{ $active?: boolean }>`
  &&& {
    background-color: ${({ theme }) => theme.default.colors.iconButtons.gray.lighter};
    border-color: ${({ theme }) => theme.default.colors.iconButtons.gray.lighter};
    > svg {
      color: ${({ theme }) => theme.default.colors.iconButtons.purple.dark};
    }
    &:active {
      background-color: ${({ theme }) => theme.default.colors.iconButtons.blueGray.dark};
      border-color: ${({ theme }) => theme.default.colors.iconButtons.blueGray.dark};
    }
    ${({ $active, theme }) =>
      $active &&
      `
			background-color: ${theme.default.colors.iconButtons.blueGray.dark};
			border-color: ${theme.default.colors.iconButtons.blueGray.dark};
		`}
    &:disabled {
      background-color: ${({ theme }) => theme.default.colors.iconButtons.white};
      > svg {
        color: ${({ theme }) => theme.default.colors.iconButtons.purple.light};
        path {
        }
      }
      &:hover {
        border-color: ${({ theme }) => theme.default.colors.iconButtons.gray.lighter};
      }
    }
    &:hover {
      border-color: ${({ theme }) => theme.default.colors.iconButtons.purple.dark};
    }
  }
`;

const terciaryStyle = css<{ $active?: boolean }>`
  &&& {
    background-color: ${({ theme }) => theme.default.colors.iconButtons.white};
    border: none;
    > svg {
      color: ${({ theme }) => theme.default.colors.iconButtons.purple.dark};
    }
    &:active {
      background-color: ${({ theme }) => theme.default.colors.iconButtons.gray.dark} !important;
    }
    ${({ $active, theme }) =>
      $active &&
      `
			background-color: ${theme.default.colors.iconButtons.gray.dark} !important;
		`}
    &:disabled {
      background-color: ${({ theme }) => theme.default.colors.iconButtons.white};
      > svg {
        color: ${({ theme }) => theme.default.colors.iconButtons.purple.light};
      }
      &:hover {
        background-color: ${({ theme }) => theme.default.colors.iconButtons.white};
      }
    }
    &:hover {
      background-color: ${({ theme }) => theme.default.colors.iconButtons.gray.lighter};
    }
  }
`;

const buttonSizeStyle = css<{ theme; size }>`
  &&& {
    width: ${({ theme, size }) => theme.default.buttons[size].width} !important;
    height: ${({ theme, size }) => theme.default.buttons[size].height} !important;
    > svg {
      width: ${({ theme, size }) => theme.default.buttons[size].icon.width} !important;
      height: ${({ theme, size }) => theme.default.buttons[size].icon.height} !important;
    }
  }
`;

const defaultSizeStyle = css<{ theme }>`
  &&& {
    width: ${({ theme }) => theme.default.buttons.default.width} !important;
    height: ${({ theme }) => theme.default.buttons.default.height} !important;
    > svg {
      width: ${({ theme }) => theme.default.buttons.default.icon.width} !important;
      height: ${({ theme }) => theme.default.buttons.default.icon.height} !important;
    }
  }
`;

const StyledButton = styled(AntdButton)<IButtonProps>`
  ${defaultStyles};

  ${({ size }) => (size ? buttonSizeStyle : defaultSizeStyle)};
  ${({ $primary }) => $primary && primaryStyle};
  ${({ $secondary }) => $secondary && secondaryStyle};
  ${({ $terciary }) => $terciary && terciaryStyle};
`;

export { StyledButton };
