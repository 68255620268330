import { useContext } from 'react';
import styled from 'styled-components';
import { screenMd } from 'constants/styles/breakpoints';
import { colors } from 'constants/styles/colors';
import { NotesContext } from 'contexts/notes';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px auto 40px auto;
  padding-bottom: 100px;
  ${screenMd} {
    margin: 20px auto;
  }
  > h4 {
    font-size: 16px;
    text-align: center;
    ${screenMd} {
      font-size: 24px;
    }
  }
  > p {
    font-size: 14px;
    text-align: center;
    ${screenMd} {
      font-size: 18px;
    }
  }
`;

export const EmptyStateImg = styled.img`
  width: 300px;
  ${screenMd} {
    width: 400px;
  }
`;

export const ActionButton = styled.div`
  position: fixed;
  bottom: 80px;
  left: 1rem;
  right: 1rem;
  z-index: 900 !important;
  button {
    width: 100%;
  }

  ${screenMd} {
    position: static;
    bottom: auto;
    left: auto;
    right: auto;
    z-index: 0 !important;
    .button {
      width: auto;
    }
  }
`;

interface INotesEmpty {
  onAddNote(): void;
}

const NotesEmpty = ({ onAddNote }: INotesEmpty): JSX.Element => {
  const { filters, fetching } = useContext(NotesContext);
  return (
    <>
      {!fetching && (
        <Container>
          {filters.search || filters.created_by || filters.from_date ? (
            <>
              <h4>Sorry, we couldn’t find any matches</h4>
              <p>Please try searching by another term.</p>
            </>
          ) : (
            <>
              <h4>You haven’t added any notes yet!</h4>
              <p>
                <a onClick={onAddNote} style={{ color: colors.dataVisualization.purple.base }}>
                  Add a note
                </a>{' '}
                on this account.
              </p>
              <br />
            </>
          )}
        </Container>
      )}
    </>
  );
};

export default NotesEmpty;
