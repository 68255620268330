import NoteUsersFilter from './NotesFilters/NoteUsersFilter';
import NoteDatesFilter from './NotesFilters/NoteDatesFilter';
import SearchNotesFilter from './NotesFilters/SearchNotesFilter';
import { NotesFiltersContainer } from './styles';

const NotesFilters = (): JSX.Element => {
  return (
    <NotesFiltersContainer>
      <SearchNotesFilter />
      <NoteDatesFilter />
      <NoteUsersFilter />
    </NotesFiltersContainer>
  );
};

export default NotesFilters;
