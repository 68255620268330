import { AxiosResponse } from 'axios';
import { config } from 'config/config.local';
import { BUSINESS_PYTHON, UAA } from './axios';
import { UAAData, UsersMe } from 'types/users';
import { Team } from 'contexts/teams/types';
export interface SessionDataTypes {
  account: AccountData;
  user: UserData;
  accounts: AccountsData;
  teams: Team;
}

export interface UserData {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  team: string;
  team_role: string;
  last_login: string;
  has_license: string;
  avatar_url: string;
}

export interface AccountData {
  name: string;
  logo_url: string;
  licenses_available: number;
  users_pending: number;
  users_count: number;
  teams_count: number;
  account_url: string;
}

export interface AccountsData {
  name: string;
  id: string;
  is_free_trial: boolean;
}

export const getSessionData = (): Promise<AxiosResponse<UsersMe>> => {
  return BUSINESS_PYTHON.get(config.routes.users.getSession);
};

export const impersonateService = ({
  email,
}: {
  email: string;
}): Promise<AxiosResponse<{ data: UAAData }>> => {
  return UAA.post(config.routes.uaa.uaaImpersonate, { email });
};
