import { useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import Aside from './aside';
import Header from './header';
import Footer from './footer';
import { FCProps } from 'types/FCProps';
import './layout.scss';
import { screenMd } from 'constants/styles/breakpoints';
import ImpersonatedNotification from 'components/noticeTopBar/ImpersonatedNotification';
import { SessionContext } from 'contexts/session';
import { NOTICE_HEIGHT } from 'constants/settings';
import MigratedTopNotice from 'components/migratedTopNotice/MigratedTopNotice';
import { setMigrationInfo, setNotificationReaded } from 'services/users';
import TeamsPlaylistReportNotice from 'components/TeamsPlaylistReportNotice/TeamsPlaylistReportNotice';
import { topBarHeight as tbh } from 'utils/helpers';

const Body = styled.div<{ header?: boolean }>`
  display: flex;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.default.colors.body.bg};
  transition: transform 0.6s;
  transform: initial;

  ${screenMd} {
    padding-left: 140px;
  }

  ${({ header }) =>
    header &&
    `
		padding-top: 64px;
		min-height: calc(100vh - 64px);
		${screenMd} {
			min-height: 100vh;
		}
	`}
`;

const Main = styled.div<{ translateY: string }>`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  transition: all 0.3s;

  ${({ translateY }) =>
    translateY &&
    `
		padding-top: ${translateY}
	`}
`;

const Content = styled.div<{ isMobile?: boolean; top?: number }>`
  height: 100%;
  width: 100%;
  padding: 20px;
  ${({ top }) => top && `padding-top: ${top}px`}
  box-sizing: border-box;

  ${({ isMobile }) =>
    isMobile &&
    `
		padding: 0;
    ${({ top }) => top && `padding-top: ${top}px`}
	`}
`;

const Layout = ({ children }: FCProps): JSX.Element => {
  const { user, isTeamManager, isImpersonated, notShowingDrawers } = useContext(SessionContext);
  const [showNotice, setShowNotice] = useState(true);
  const [notificationsReaded, setNotificationsReaded] = useState([]);
  const location = useLocation();

  const showMigratedModal = () => {
    return user.should_show_migration_info;
  };

  const showNoticeToggler = ({ notificationType }) => {
    let conditionValue =
      !isImpersonated &&
      isTeamManager &&
      user.notifications.some((notification) => notification.kind === notificationType);
    if (notificationType === 'playlists' && conditionValue) {
      conditionValue = conditionValue && location.pathname === '/playlists';
    }
    if (conditionValue) {
      const notification_id = user.notifications.find(
        (notification) => notification.kind === notificationType
      ).id;
      if (!notificationsReaded.includes(notification_id)) {
        setTopBarHeight(NOTICE_HEIGHT);
        setNotificationsReaded((prevState) => [...prevState, notification_id]);
        setNotificationReaded({ notification_id });
      }
      return true;
    }
  };

  const topHeight = tbh(user);
  const [topBarHeight, setTopBarHeight] = useState(topHeight);

  const onActiveNotification = ({ height }) => {
    setTopBarHeight(height + topBarHeight);
  };

  const onCloseNotification = () => {
    setTopBarHeight(0);
  };

  useEffect(() => {
    if (!user.migration_info_start_time) {
      setMigrationInfo();
    }
  }, []);

  const showNoticeComponent = () => {
    if (showMigratedModal()) {
      return <MigratedTopNotice />;
    } else if (!isMobile && showNoticeToggler({ notificationType: 'playlists' })) {
      return <TeamsPlaylistReportNotice type="playlists" />;
    } else if (!isMobile && showNoticeToggler({ notificationType: 'reporting' })) {
      return <TeamsPlaylistReportNotice type="reporting" />;
    } else if (topBarHeight !== 0) {
      setShowNotice(false);
      setTopBarHeight(isImpersonated ? NOTICE_HEIGHT : 0);
    }
  };

  return (
    <Body header={true}>
      {showNotice && showNoticeComponent()}
      <ImpersonatedNotification onActive={onActiveNotification} onClose={onCloseNotification} />
      <Aside translateY={notShowingDrawers && `${topBarHeight}px`} />
      <Header translateY={notShowingDrawers && `${topBarHeight}px`} />
      <Main translateY={notShowingDrawers && `${topBarHeight}px`}>
        <Content isMobile={isMobile} top={topBarHeight}>
          {children}
        </Content>
        <Footer />
      </Main>
    </Body>
  );
};

export default Layout;
