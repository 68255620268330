/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-var-requires */
const { colors } = require('./colors');

const buttonColors = colors.buttons;

const buttons = {
  small: {
    fontSize: '14px',
    borderRadius: '8px',
    padding: '0px 16px',
    height: '36px',
    width: '36px',
    lineHeight: '36px',
    icon: {
      height: '16px',
      width: '16px',
    },
  },
  default: {
    fontSize: '16px',
    borderRadius: '8px',
    padding: '0px 24px',
    height: '44px',
    width: '44px',
    lineHeight: '44px',
    icon: {
      height: '24px',
      width: '24px',
    },
  },
  primary: {
    default: {
      iddle: {
        background: buttonColors.dark,
        color: buttonColors.white,
        border: 'none',
        icon: buttonColors.darkPurple,
        opacity: '1',
      },
      hover: {
        background: buttonColors.dark,
        color: buttonColors.white,
        border: 'none',
        icon: buttonColors.darkPurple,
        opacity: '1',
      },
      pressed: {
        background: buttonColors.lightGray,
        color: buttonColors.white,
        border: 'none',
        icon: buttonColors.white,
        opacity: '1',
      },
      disabled: {
        background: buttonColors.darkGray,
        color: buttonColors.white,
        border: 'none',
        icon: buttonColors.white,
        opacity: '0.25',
      },
    },
    dark: {
      iddle: {
        background:
          'linear-gradient(112.2deg, rgba(187, 188, 199, 0.7) -46.39%, rgba(187, 188, 199, 0) 112.38%)',
        color: buttonColors.white,
        border: 'none',
        icon: buttonColors.darkPurple,
        opacity: '1',
      },
      hover: {
        background: buttonColors.blackGray,
        color: buttonColors.dark,
        border: 'none',
        icon: buttonColors.darkPurple,
        opacity: '1',
      },
      pressed: {
        background: '#E9E9ED',
        color: buttonColors.dark,
        border: 'none',
        icon: buttonColors.white,
        opacity: '1',
      },
      disabled: {
        background: '#666',
        color: buttonColors.white,
        border: 'none',
        icon: buttonColors.white,
        opacity: '0.25',
      },
    },
  },
  secondary: {
    default: {
      iddle: {
        background: 'transparent',
        color: buttonColors.dark,
        border: `1px solid ${buttonColors.darkPurple}`,
        icon: buttonColors.darkPurple,
        opacity: '1',
      },
      hover: {
        background: 'transparent',
        color: buttonColors.dark,
        border: `1px solid ${buttonColors.lightGray}`,
        icon: buttonColors.darkPurple,
        opacity: '1',
      },
      pressed: {
        background: buttonColors.dark,
        color: buttonColors.white,
        border: `1px solid ${buttonColors.dark}`,
        icon: buttonColors.darkPurple,
        opacity: '1',
      },
      disabled: {
        background: 'transparent',
        color: buttonColors.dark,
        border: `1px solid ${buttonColors.lightGray}`,
        icon: buttonColors.darkPurple,
        opacity: '0.4',
      },
    },
    dark: {
      iddle: {
        background: 'transparent',
        color: buttonColors.white,
        border: `1px solid ${buttonColors.darkPurple}`,
        icon: buttonColors.darkPurple,
        opacity: '1',
      },
      hover: {
        background: 'transparent',
        color: buttonColors.white,
        border: `1px solid ${buttonColors.lighter}`,
        icon: buttonColors.darkPurple,
        opacity: '1',
      },
      pressed: {
        background: buttonColors.blackGray7,
        color: buttonColors.dark,
        border: 'none',
        icon: buttonColors.darkPurple,
        opacity: '1',
      },
      disabled: {
        background: 'transparent',
        color: buttonColors.white,
        border: `1px solid ${buttonColors.lighter}`,
        icon: buttonColors.darkPurple,
        opacity: '0.4',
      },
    },
  },
  terciary: {
    default: {
      iddle: {
        background: 'transparent',
        color: buttonColors.dark,
        border: `0px solid ${buttonColors.darkPurple}`,
        icon: buttonColors.darkPurple,
        opacity: '1',
      },
      hover: {
        background: buttonColors.lighter,
        color: buttonColors.dark,
        border: `0px solid ${buttonColors.darkPurple}`,
        icon: buttonColors.darkPurple,
        opacity: '1',
      },
      pressed: {
        background: buttonColors.lightGray,
        color: buttonColors.dark,
        border: `0px solid ${buttonColors.darkPurple}`,
        icon: buttonColors.darkPurple,
        opacity: '1',
      },
      disabled: {
        background: buttonColors.white,
        color: buttonColors.dark,
        border: `0px solid ${buttonColors.darkPurple}`,
        icon: buttonColors.darkPurple,
        opacity: '0.4',
      },
    },
    dark: {
      iddle: {
        background: 'transparent',
        color: buttonColors.white,
        border: `0px solid ${buttonColors.darkPurple}`,
        icon: buttonColors.darkPurple,
        opacity: '1',
      },
      hover: {
        background: buttonColors.dark,
        color: buttonColors.white,
        border: `0px solid ${buttonColors.darkPurple}`,
        icon: buttonColors.darkPurple,
        opacity: '1',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      },
      pressed: {
        bbackground: buttonColors.dark,
        color: buttonColors.white,
        border: `0px solid ${buttonColors.darkPurple}`,
        icon: buttonColors.darkPurple,
        opacity: '1',
      },
      disabled: {
        background: 'transparent',
        color: buttonColors.white,
        border: `0px solid ${buttonColors.darkPurple}`,
        icon: buttonColors.darkPurple,
        opacity: '0.4',
      },
    },
  },
};

module.exports.buttons = buttons;
