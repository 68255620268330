import { useEffect, useState } from 'react';
import {
  getReportSelectionPreferences,
  getReportsAction,
  newReportAction,
  saveReportSelectionPreferences,
} from 'contexts/reporting/actions';
import { reportsInitialParams } from 'contexts/reporting/utils';
import {
  IGetReportsParams,
  INewReportParams,
  IReportsDataCtx,
  IUserFilter,
} from 'contexts/reporting/types';
import Notification from 'components/notification';
import { getInvitations } from 'services/users';
import { filtersByTab } from 'pages/Reporting/components/ReportingFiltersConfig';

const useReportingContent = (): IReportsDataCtx => {
  const [reports, setReports] = useState(null);
  const [activeFilters, setActiveFilters] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [fetchingUsers, setFetchingUsers] = useState(false);
  const [fetchingFilters, setFetchingFilters] = useState(false);
  const [users, setUsers] = useState([]);

  const getReports = async (
    { filtersType, sorting, page, page_size }: IGetReportsParams,
    fetch?: boolean
  ) => {
    setFetching(fetch ? true : false);
    try {
      const getReportsMethod = getReportsAction;
      if (filtersType) {
        reportsInitialParams.type = filtersType;
      }
      if (page) {
        reportsInitialParams.page = page;
      }
      if (page_size) {
        reportsInitialParams.page_size = page_size;
      }
      if (sorting) {
        reportsInitialParams.sorting = sorting;
      }

      const reportsResponse = await getReportsMethod(reportsInitialParams);
      setFetching(false);

      setReports(reportsResponse);
    } catch (error) {
      setFetching(false);
      Notification({ text: 'There has been a problem fetching your reports', type: 'error' });
    }
  };

  const generateNewReport = async (params: INewReportParams) => {
    setFetching(true);
    try {
      await saveReportsFiltersPreferences(params.type, activeFilters);
      const newReportMethod = newReportAction;
      const newReportResponse = await newReportMethod(params);
      setFetching(false);

      Notification({ text: 'Congratulations! Your report has been generated.', type: 'success' });
      setReports({
        results: [newReportResponse.result, ...reports.results],
        count: reports.count + 1,
      });
    } catch (error) {
      Notification({ text: 'There has been a problem generating your report', type: 'error' });
      setFetching(false);
    }
  };

  const getReportsFilteringPreferences = async (reportType: string) => {
    setFetchingFilters(true);
    try {
      const preferences = await getReportSelectionPreferences();
      const currentActiveFilters =
        preferences?.results
          .find((p) => p.type === reportType)
          .checkboxes?.filter((c) => filtersByTab[reportType].includes(c)) || [];
      setActiveFilters(currentActiveFilters);
    } catch (error) {
      Notification({ text: 'There has been a problem getting your preferences', type: 'error' });
    } finally {
      setFetchingFilters(false);
    }
  };

  const saveReportsFiltersPreferences = async (reportType: string, filters?: Array<string>) => {
    const preferences = filters ? filters : activeFilters;
    await saveReportSelectionPreferences(preferences, reportType);
  };

  const getUsers = async (query: string): Promise<IUserFilter[]> => {
    setFetchingUsers(true);
    try {
      return getInvitations({
        active: 'true',
        license: null,
        teams: null,
        roles: null,
        page: 1,
        page_size: 500,
        search: query || '',
      }).then((response) => {
        if (response.status === 200) {
          setFetchingUsers(false);
          const newUsers = response.data.results.map((u) => {
            return {
              email: u.email,
              id: u.uaa_id,
              name: `${u.uaa_data?.profile?.data?.first_name} ${u.uaa_data?.profile?.data?.last_name}`,
            };
          });
          setUsers(newUsers);
          return newUsers;
        }
        return users;
      });
    } catch (error) {
      setFetchingUsers(false);
      Notification({ text: 'There has been a problem fetching users', type: 'error' });
    }
  };

  useEffect(() => {
    getReports(reportsInitialParams, true);
    getReportsFilteringPreferences('user');
    getUsers('');
  }, []);

  return {
    fetching,
    fetchingUsers,
    reports,
    activeFilters,
    setActiveFilters,
    users,
    getReports,
    generateNewReport,
    getReportsFilteringPreferences,
    saveReportsFiltersPreferences,
    getUsers,
    error: false,
  };
};

export default useReportingContent;
