import { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { useParams } from 'react-router-dom';
import { SectionCard } from 'pages/Analytics/components/AnalyticsContent/styles';
import {
  Content,
  Title,
  TitleWrapper,
} from 'pages/Analytics/components/ActiveUsersCollapse/styles';
import ButtonIcon from 'components/buttonIcon';
import ActivityLogFilters from './ActivityLogFilters';
import ActivityLogList from './ActivityLogList';
import { ActivityLogContext } from 'contexts/activityLog';
import { SessionContext } from 'contexts/session';

const ActivityLogContent = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const { isStaff } = useContext(SessionContext);
  const { actions, fetching } = useContext(ActivityLogContext);
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    if (isStaff) {
      actions.onChangeFilter({ name: 'account_id', value: id });
      actions.onRetrieveActivityLogUsers(id);
    } else {
      actions.onRetrieveActivityLogUsers();
    }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      actions.onRefreshActivities();
    }, 30000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <SectionCard padding={'30px'} rounded dashboard shadow>
      <header>
        <TitleWrapper>
          <Title>Account recent activity</Title>
          <div className="d-flex">
            <ButtonIcon
              style={{ marginLeft: '10px' }}
              $terciary
              size="small"
              icon={collapsed ? 'ChevronUp' : 'ChevronDown'}
              onClick={() => toggleCollapse()}
            />
          </div>
        </TitleWrapper>
      </header>
      {!collapsed && (
        <Content fetching={fetching} className="mt-5">
          <Row>
            <Col lg={5} md={24} sm={24} xs={24}>
              <ActivityLogFilters />
            </Col>
            <Col lg={19} md={24} sm={24} xs={24}>
              <ActivityLogList />
            </Col>
          </Row>
        </Content>
      )}
    </SectionCard>
  );
};

export default ActivityLogContent;
