import { Label, Input } from 'components/modal/styles';

interface AccountNameProps {
  onChange(value): void;
  value: string;
  error?: boolean;
}

const AccountName: React.FC<AccountNameProps> = ({ value, onChange, error }) => {
  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    onChange(e.currentTarget.value);
  };

  return (
    <>
      <Label>Account name</Label>
      <Input error={error} placeholder={'Account name'} value={value} onChange={handleChange} />
    </>
  );
};

export default AccountName;
