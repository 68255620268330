import { useContext, useEffect, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { format, subDays } from 'date-fns';
import { Tooltip } from 'antd';

import { ReportsContext } from 'contexts/reporting';
import { SessionContext } from 'contexts/session';

import Button from 'components/button/Button';
import Icon from 'components/icons';
import InputSelect from 'components/inputs/inputSelect';
import RangeDatePicker from 'components/rangeDatePicker';
import {
  AdvanceSubtitle,
  AdvanceTitle,
  ButtonsContainer,
  ClearInput,
  DateFilter,
  Filter,
  Filters,
  SelectContainer,
  TeamsSelector,
  UsersSelector,
} from './ReportingFiltersComponents';
import { dateFilterOptions, filters } from './ReportingFiltersConfig';

import {
  ACCOUNT,
  COURSES,
  TEAM_ACTIVITY,
  USER_ACTIVITY,
  coursesColumnName,
  skillDiveColumnName,
  skillSonarColumnName,
  teamColumnName,
  usersColumnName,
} from 'constants/reporting';

import { formatDateInUtc } from 'utils/helpers';
import FeatherIcon from 'feather-icons-react';

const ReportingFilters = ({
  filtersType,
  hasSkillDive,
  sonarEnabled,
}: {
  filtersType: string;
  hasSkillDive: boolean;
  sonarEnabled: boolean;
}): JSX.Element => {
  const {
    generateNewReport,
    fetching,
    activeFilters,
    setActiveFilters,
    getReportsFilteringPreferences,
  } = useContext(ReportsContext);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [dateFilterValue, setDateFilterValue] = useState(dateFilterOptions[0]);
  const dateFormat = 'yyyy-MM-dd';
  const dateLabelFormat = 'dd LLL';
  const [dateFilter, setDateFilter] = useState({
    from: format(subDays(new Date(), Number(dateFilterOptions[0].value)), dateFormat),
    to: format(new Date(), dateFormat),
  });
  const [dateRangeSelected, setDateRangeSelected] = useState(false);
  const [dateRangeValue, setDateRangeValue] = useState(null);
  const [clearDateRange, setClearDateRange] = useState(false);
  const [usersSelected, setUsersSelected] = useState([]);
  const [teamsSelected, setTeamsSelected] = useState([]);
  const { isTeamManager, isMarkAsCompletedEnabled } = useContext(SessionContext);

  useEffect(() => {
    setShowAdvanced(true);
  }, [activeFilters]);

  useEffect(() => {
    getReportsFilteringPreferences(filtersType);
    setShowAdvanced(false);
  }, [filtersType]);

  const onGenerateReportClick = async () => {
    const params = {
      type: filtersType,
      datesFilter: {
        from: dateFilter.from,
        to: dateFilter.to,
      },
      activeFilters: activeFilters,
    };
    if (filtersType === USER_ACTIVITY) {
      params['uaa_ids'] = usersSelected.map((u) => u.id);
    }
    if (filtersType === TEAM_ACTIVITY) {
      params['team_ids'] = teamsSelected.map((t) => t.team_id);
    }
    generateNewReport(params);
  };

  const handleUsersSelectionChange = (selectedUsers) => {
    setUsersSelected(selectedUsers);
  };

  const handleTeamsSelectionChange = (selectedTeams) => {
    const teams = [];
    if (selectedTeams?.length > 0) {
      selectedTeams.forEach((team) => {
        teams.push({
          team,
          team_id: team.id,
          name: team.name,
          is_manager: !!team.is_manager,
        });
      });
    }
    setTeamsSelected(teams);
  };

  const ActionButtons = () => (
    <Filters.Buttons isMobile={isMobile} isTablet={isTablet} marginTop={showAdvanced}>
      <Button $secondary $full size={'small'} onClick={() => setShowAdvanced(!showAdvanced)}>
        {showAdvanced ? 'Hide advanced options' : 'Advanced options'}
      </Button>
      <Button
        data-testid="filter-button-test-id"
        $primary
        $full
        size={'small'}
        disabled={fetching || !activeFilters || activeFilters.length === 0}
        onClick={() => onGenerateReportClick()}
      >
        Generate report
      </Button>
    </Filters.Buttons>
  );

  const ClearInputComponent = () => {
    const onClearInputClick = () => {
      setDateFilterValue(dateFilterOptions[3]);
      setDateRangeValue(null);
      setClearDateRange(false);
    };

    return (
      <ClearInput onClick={() => onClearInputClick()}>
        <div>
          <Icon icon="Cancel" size="medium" />
        </div>
      </ClearInput>
    );
  };

  const onDateSelectChange = (selectValue: string | number) => {
    setDateRangeSelected(false);
    setClearDateRange(false);
    setDateRangeValue(null);
    setDateFilterValue(dateFilterOptions.find((opt) => opt.value === selectValue));
    if (selectValue !== 'custom') {
      setDateFilter({
        from: format(subDays(new Date(), Number(selectValue)), dateFormat),
        to: format(new Date(), 'yyyy-MM-dd'),
      });
    } else {
      setDateRangeSelected(true);
    }
  };

  const onDateFilterChange = (value: [string, string], date?: any) => {
    setDateFilter({
      from: value[0],
      to: value[1],
    });
    setDateFilterValue({
      value: 'custom',
      label: `${formatDateInUtc(new Date(value[0]), dateLabelFormat)} - ${formatDateInUtc(
        new Date(value[1]),
        dateLabelFormat
      )}`,
    });
    setClearDateRange(true);
    setDateRangeValue(date);
  };

  const onFilterClick = (e) => {
    if (e.target.checked) {
      setActiveFilters([...activeFilters, e.target.name]);
    } else {
      setActiveFilters([...activeFilters.filter((filter) => filter !== e.target.name)]);
    }
  };

  const filterDisabled = (columnName) => {
    return (
      (!hasSkillDive && columnName === skillDiveColumnName) ||
      (!sonarEnabled && columnName === skillSonarColumnName)
    );
  };

  const isUsersFiltersGroup = (filter) => {
    return filter.name === usersColumnName;
  };
  const isCoursesFiltersGroup = (filter) => {
    return filter.name === coursesColumnName;
  };
  const isTeamFiltersGroup = (filter) => {
    return filter.name === teamColumnName;
  };

  const filterColumns = (filters) => {
    const checkBoxesLists = [];
    let filtersColumn = [];
    const columnName = filters.name;
    const filtersList = filters.filters;
    filtersList.forEach((filter) => {
      if (filter.showTo.includes(filtersType)) {
        let filtersPerColumn;
        switch (filtersType) {
          case USER_ACTIVITY:
            if (isUsersFiltersGroup(filters)) {
              filtersPerColumn = 3;
            } else {
              filtersPerColumn = 7;
            }
            break;
          case TEAM_ACTIVITY:
            if (isTeamFiltersGroup(filters)) {
              filtersPerColumn = 3;
            } else {
              filtersPerColumn = 7;
            }
            break;
          case COURSES:
            if (isCoursesFiltersGroup(filters)) {
              filtersPerColumn = 3;
            } else {
              filtersPerColumn = 7;
            }
            break;
          default:
            filtersPerColumn = 4;
            break;
        }
        const filterElem = (
          <Filter.Item key={filter.name} greyed={filterDisabled(columnName)}>
            <Filter.Checkbox
              key={filter.name}
              onChange={onFilterClick}
              defaultChecked={activeFilters.includes(filter.name)}
              name={filter.name}
              id={filter.name}
              label={filter.label}
            />
            {filter.tooltip && filter.tooltip[filtersType] && (
              <Tooltip title={filter.tooltip[filtersType]} placement="right">
                <FeatherIcon icon="info" size={14} color="#A793F6" />
              </Tooltip>
            )}
          </Filter.Item>
        );

        if (filtersColumn.length === filtersPerColumn) {
          filtersColumn = [];
          filtersColumn.push(filterElem);
        } else {
          filtersColumn.push(filterElem);
        }
      }
      if (!checkBoxesLists.includes(filtersColumn)) checkBoxesLists.push(filtersColumn);
    });

    return checkBoxesLists.map((filterList, i) => (
      <Filter.List key={`filter-group-${++i}`}>{filterList}</Filter.List>
    ));
  };

  return (
    <Filters.Container>
      <Filters.BasicView isTablet={isTablet}>
        <DateFilter isTablet={isTablet}>
          <label>Date range</label>
          <SelectContainer showCustom={dateFilterValue.value === 'custom'} isTablet={isTablet}>
            <InputSelect
              value={dateFilterValue.label}
              placeHolder={'Select a date'}
              options={dateFilterOptions}
              onChange={(selectValue) => onDateSelectChange(selectValue)}
            />
            {clearDateRange && <ClearInputComponent />}
            {dateRangeSelected && (
              <Button $secondary $full size={'small'}>
                <RangeDatePicker
                  value={dateRangeValue}
                  suffixIcon={<Icon icon="Calendar" size="medium" />}
                  onChange={(date, dateString) => onDateFilterChange(dateString, date)}
                />
              </Button>
            )}
          </SelectContainer>
        </DateFilter>
        <ButtonsContainer desktop={true}>
          <ActionButtons />
        </ButtonsContainer>
      </Filters.BasicView>
      <Filters.AdvancedViewHeader show={showAdvanced}>
        <AdvanceTitle>Include in your report</AdvanceTitle>
        {filtersType === USER_ACTIVITY && (
          <Filters.IdsFilterContainer>
            <>Filter by users</>
            <UsersSelector onUserChange={handleUsersSelectionChange} />
          </Filters.IdsFilterContainer>
        )}
        {filtersType === TEAM_ACTIVITY && (
          <Filters.TeamFilterContainer>
            <>Filter by teams</>
            <TeamsSelector
              analyticsTheme
              teamsSelected={teamsSelected}
              onTeamChange={handleTeamsSelectionChange}
              selectAllOption={isTeamManager ? 'All my teams' : 'All teams from the account'}
              containerStyles={{ minWidth: '70%' }}
            />
          </Filters.TeamFilterContainer>
        )}
      </Filters.AdvancedViewHeader>
      <Filters.AdvancedView show={showAdvanced}>
        {showAdvanced &&
          filtersType &&
          filters(filtersType, isMarkAsCompletedEnabled).map((filter) => {
            const coursesFiltersGroup = isCoursesFiltersGroup(filter);
            const isSkillDiveLicenseFiltersGroup = filter.name === 'Skill Dive Licenses';
            const isEndOfRow =
              (filtersType === COURSES && coursesFiltersGroup) ||
              (filtersType === USER_ACTIVITY && isUsersFiltersGroup(filter)) ||
              (filtersType === TEAM_ACTIVITY && isTeamFiltersGroup(filter)) ||
              (filtersType === ACCOUNT && isSkillDiveLicenseFiltersGroup);
            const displayContainerTitle =
              ((filtersType === USER_ACTIVITY || filtersType === TEAM_ACTIVITY) &&
                filter.name === skillDiveColumnName) ||
              (filtersType === ACCOUNT && filter.name === 'Overall usage');
            const filterElements = filterColumns(filter);
            return (
              filter.showTo.includes(filtersType) && (
                <>
                  {displayContainerTitle && (
                    <AdvanceSubtitle>Content progress & Usage</AdvanceSubtitle>
                  )}
                  <Filter.Container
                    key={filter.name}
                    filtersType={filtersType}
                    endOfRow={isEndOfRow}
                  >
                    <Filter.Title>
                      {filter.name}
                      {filter.columnDisabledTooltip && filterDisabled(filter.name) && (
                        <Tooltip title={filter.columnDisabledTooltip} placement="right">
                          <FeatherIcon icon="info" size={14} color="#A793F6" />
                        </Tooltip>
                      )}
                    </Filter.Title>
                    <Filter.Group twoColumns={filterElements?.length > 1}>
                      {filterElements}
                    </Filter.Group>
                  </Filter.Container>
                </>
              )
            );
          })}
      </Filters.AdvancedView>
      <ButtonsContainer desktop={false}>
        <ActionButtons />
      </ButtonsContainer>
    </Filters.Container>
  );
};

export default ReportingFilters;
