import { Input, InputContainer, Label } from 'components/modal/styles';

interface IPlaylistName {
  playlistName: string;
  setPlaylistName: (value: string) => void;
  playlist: boolean;
}

export const PlaylistNameInput = ({
  playlistName,
  setPlaylistName,
}: IPlaylistName): JSX.Element => {
  return (
    <InputContainer>
      <Label>Playlist name</Label>
      <Input
        placeholder={'New playlist'}
        type={'text'}
        value={playlistName}
        onChange={(e) => setPlaylistName(e.currentTarget.value)}
      />
    </InputContainer>
  );
};
