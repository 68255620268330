import { useContext } from 'react';
import { isMobile } from 'react-device-detect';
import { UsersContext } from 'contexts/users';
import { FilterCount, FilterWrapper } from './styles';
import Checkbox from 'components/checkbox/Checkbox';
import { FilterChangeEvents } from 'components/usersAsideFilters/types';

const AccountUsersAsideFilterAddons = (): JSX.Element => {
  const { filters, actions, usersCounts } = useContext(UsersContext);

  const handleFilter = (e: FilterChangeEvents) => {
    const { name, value } = e.target;
    actions.onChangeFilters(
      {
        name: name,
        value: value,
      },
      true,
      true
    );
  };

  return (
    <>
      {usersCounts?.addons_count?.map((addonCount) => (
        <div key={addonCount.id}>
          <h3 className="mt-16 mb-5 fs-1 card-title">{addonCount.name || 'Addon'} License</h3>
          <FilterWrapper>
            <Checkbox
              label={'License'}
              value={addonCount.id}
              id="license_addons"
              name="license_addons"
              onChange={handleFilter}
              defaultChecked={filters.license_addons.includes(addonCount.id)}
              checked={filters.license_addons.includes(addonCount.id)}
              controlled={!isMobile}
            />
            <FilterCount>{addonCount.license || 0}</FilterCount>
          </FilterWrapper>
          <FilterWrapper>
            <Checkbox
              label={'No License'}
              value={addonCount.id}
              id="no_license_addons"
              name="no_license_addons"
              onChange={handleFilter}
              defaultChecked={filters.no_license_addons.includes(addonCount.id)}
              checked={filters.no_license_addons.includes(addonCount.id)}
              controlled={!isMobile}
            />
            <FilterCount>{addonCount.no_license || 0}</FilterCount>
          </FilterWrapper>
        </div>
      ))}
    </>
  );
};
export default AccountUsersAsideFilterAddons;
