import { createContext } from 'react';
import { FCProps } from 'types/FCProps';
import { UsersDataCtx } from './types';
import { usersInitialValues } from './utils';
import useUsersTableContent from 'hooks/useUsersTableContent';

export const UsersContext = createContext<UsersDataCtx>(usersInitialValues);

interface UsersProviderProps extends FCProps {
  fixedFilters?: {
    active?: string;
    license?: string;
    teams?: string;
    roles?: string;
    page?: number;
    page_size?: number;
    search?: string;
    ordering?: string;
    accounts?: string;
  };
}

const UsersProvider = ({ children, fixedFilters }: UsersProviderProps): JSX.Element => {
  const {
    users,
    fetching,
    loading,
    selectedRows,
    usersCounts,
    search,
    removeTableItem,
    updateUsersTable,
    onForceRequest,
    onRefreshData,
    handleSelectRow,
    handleSelectAllRows,
    updateCounts,
    handleSearchApply,
    updateFilters,
    onClearSearch,
  } = useUsersTableContent({ fixedFilters });

  return (
    <UsersContext.Provider
      value={{
        loading: loading || fetching,
        fetching,
        table: users,
        filters: {
          license: search.get('license')?.split(',') || [],
          team: search.get('team')?.split(',') || [],
          roles: search.get('roles')?.split(',') || [],
          active: search.get('active') || 'true',
          page: search.get('page') || '1',
          page_size: search.get('page_size') || '9',
          search: search.get('search') || '',
          ordering: search.get('ordering') || '',
          accounts: search.get('accounts')?.split(',') || [],
          license_addons: search.get('license_addons')?.split(',') || [],
          no_license_addons: search.get('no_license_addons')?.split(',') || [],
        },
        selectedRows,
        usersCounts,
        actions: {
          onChangeFilters: updateFilters,
          onSelectRow: handleSelectRow,
          onSelectAllRows: handleSelectAllRows,
          onClearSearch,
          onSearchApply: handleSearchApply,
          onRemoveTableItem: removeTableItem,
          onTableUpdate: updateUsersTable,
          onCountsUpdate: updateCounts,
          onForceRequest,
          onRefreshData,
        },
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};

export default UsersProvider;
