import cx from 'classnames';
import './tabs.scss';

interface TabsProps {
  items: { name: string; value: string; counter?: number }[];
  selected: string;
  $flex?: 'fill' | 'grow';
  onTabChange: (tab: string) => void;
}

const Tabs = ({ items, selected, $flex, onTabChange }: TabsProps): JSX.Element => {
  return (
    <div className="tabs">
      {items.map(({ value, name, counter }) => (
        <div
          className={cx('tabs__tab', {
            'tabs__tab--selected': selected === value,
            'tabs__tab--grow': $flex === 'grow',
          })}
          key={value}
          onClick={() => onTabChange(value)}
        >
          <span className="tabs__title">{name}</span>
          {!!counter && <span className="tabs__counter">{counter}</span>}
        </div>
      ))}
      {$flex === 'fill' && <div className="tabs__tab tabs__tab--fill"></div>}
    </div>
  );
};

export default Tabs;
