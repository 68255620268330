import styled from 'styled-components';
import { Space } from 'antd';
import { screenMd } from 'constants/styles/breakpoints';

export const FooterContainer = styled.footer`
  display: none;
  ${screenMd} {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    background-color: ${({ theme }) => theme.default.colors.dataVisualization.gray.light};
    background: linear-gradient(
      112.2deg,
      rgba(187, 188, 199, 0.4) -46.39%,
      rgba(187, 188, 199, 0) 112.38%
    );
    margin-top: auto;
    .popover-divider {
      margin: 4px 0 !important;
    }
  }
`;

export const FooterLinks = styled(Space)`
  span {
    font-size: 12px;
    letter-spacing: 0.5px;
    color: ${({ theme }) => theme.default.colors.fonts.dark};
  }
  a {
    font-size: 12px;
    color: ${({ theme }) => theme.default.colors.fonts.dark};
  }
`;

export const FooterActions = styled(Space)`
  margin-right: 16px;
`;
