import { Dropdown, Space } from 'antd';
import { useEffect, useState } from 'react';

import {
  InputSelectCheckboxOptions,
  InputTypeSelectMultiple,
  SelectDropdownCheckbox,
  SelectDropdownCheckboxButtons,
  SelectDropdownCheckboxContainer,
} from 'components/inputs/styles';
import Checkbox from 'components/checkbox';
import Button from 'components/button';

interface InputSelectCheckboxProps {
  values: string[];
  onChange?: (selected: string[]) => void;
  options?: {
    label: string;
    value: string;
    key?: string;
  }[];
  defaultOpen?: boolean;
  placeHolder?: string;
  disabled?: boolean;
  maxDropdownHeight?: string;
  confirmBtnLabel?: string;
}

const InputSelectCheckbox: React.FC<InputSelectCheckboxProps> = ({
  onChange,
  options,
  defaultOpen,
  placeHolder,
  disabled,
  maxDropdownHeight,
  confirmBtnLabel,
  values,
}) => {
  const [open, setOpen] = useState(defaultOpen);
  const [selected, setSelected] = useState([]);
  const [prevSelected, setPrevSelected] = useState([]);

  const applyValue = () => {
    onChange(selected);
    setPrevSelected(selected);
    setOpen(false);
  };

  const cancel = () => {
    setSelected(prevSelected);
    setOpen(false);
  };

  const handleSelected = (value: string) => {
    if (!value) {
      setSelected([]);
    } else {
      setSelected((prevState) => {
        if (prevState.includes(value)) {
          return prevState.filter((ps) => ps !== value);
        } else {
          return [...prevState, value];
        }
      });
    }
  };

  useEffect(() => {
    values.length &&
      setSelected((prevState) => {
        return [...prevState, values];
      });
  }, []);

  return (
    <Dropdown
      overlay={
        <SelectDropdownCheckboxContainer>
          <SelectDropdownCheckbox maxHeight={maxDropdownHeight}>
            {options.map(({ label, value }) => {
              return (
                <InputSelectCheckboxOptions key={value} onClick={() => handleSelected(value)}>
                  <Space>
                    <Checkbox
                      checked={selected.length === 0 ? value === '' : selected.includes(value)}
                      controlled
                    />
                    {label}
                  </Space>
                </InputSelectCheckboxOptions>
              );
            })}
          </SelectDropdownCheckbox>
          <SelectDropdownCheckboxButtons>
            <Button $terciary size="small" onClick={cancel}>
              Cancel
            </Button>
            <Button $primary size="small" onClick={applyValue}>
              {confirmBtnLabel || 'Aplly'}
            </Button>
          </SelectDropdownCheckboxButtons>
        </SelectDropdownCheckboxContainer>
      }
      trigger={['click']}
      visible={open}
      disabled={disabled}
      onVisibleChange={setOpen}
    >
      <InputTypeSelectMultiple
        clearable={false}
        value={selected}
        mode="multiple"
        maxTagCount="responsive"
        placeholder={placeHolder}
        dropdownClassName={`form-select-multiple-options dropdown-items-wrapper`}
        removeIcon={false}
      />
    </Dropdown>
  );
};

export default InputSelectCheckbox;
