import { useState } from 'react';
import Button from 'components/button/Button';
import EmptyStateSvg from 'assets/images/playlist_empty.svg';
import ThreeStepsCreation from '../ThreeStepsCreation';
import { ActionButton, Container, EmptyStateImg } from './styles';

const PlaylistEmpty: React.FC<{ refreshPlaylist: () => void }> = ({
  refreshPlaylist,
}): JSX.Element => {
  const [isCreatingPlaylist, setIsCreatingPlaylist] = useState(false);

  const hasFinished = (refresh: boolean) => {
    if (refresh) {
      refreshPlaylist();
    }
    setIsCreatingPlaylist(false);
  };

  return (
    <>
      <Container>
        <EmptyStateImg src={EmptyStateSvg} />
        <h4>{`We couldn't find any playlists that match your current filters`}</h4>
        <p>Try adjusting your filters and trying again.</p>
        <br />
        <ActionButton>
          <Button
            $primary={true}
            style={{ paddingLeft: 30, paddingRight: 30 }}
            onClick={() => setIsCreatingPlaylist(true)}
            icon={'Plus'}
          >
            Create playlist
          </Button>
        </ActionButton>

        {isCreatingPlaylist && (
          <ThreeStepsCreation hasFinished={(refresh) => hasFinished(refresh)} />
        )}
      </Container>
    </>
  );
};

export default PlaylistEmpty;
