import { screenMd } from 'constants/styles/breakpoints';
import styled from 'styled-components/macro';

export const Container = styled.div`
  align-items: center;
  background: #f2f2f7;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  overflow: scroll;
  width: 100%;
  padding: 14px 10px;
  max-height: calc(100% - 170px);
  ${screenMd} {
    padding: 20px;
    width: 790px;
    max-height: 265px;
  }
`;

export const BodyTitle = styled.div`
  font-family: Circular-Std;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0px;
  margin-bottom: 26px;
  text-align: left;
  padding: 0 10px;
  ${screenMd} {
    padding: 0;
  }
`;

export const RowContainer = styled.div`
  background: white;
  border-radius: 10px;
  border-style: 1px solid #e52a2a;
  height: 70px;
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${screenMd} {
    width: 750px;
  }
  > .error-data-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    overflow: hidden;
    ${screenMd} {
      flex-grow: 1;
      flex-direction: row;
      align-items: center;
    }
  }
`;

export const LabelEmail = styled.span`
  font-size: 14px;
  text-align: left;
  color: #1a2535;
  ${screenMd} {
    max-width: 500px;
    font-weight: 600;
    flex-grow: 2;
    max-width: 260px;
    min-width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const LabelError = styled.span`
  font-size: 12px;
  font-style: normal;
  line-height: 14px;
  max-width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  > svg {
    float: left !important;
    margin-right: 5.5px;
    max-height: 14px;
  }
  ${screenMd} {
    margin-left: 10px;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-grow: 2;
    justify-content: start;
    margin-left: 0;
    min-width: 400px;
  }
`;
