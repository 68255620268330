import { useContext } from 'react';
import InputSelectMultiple from 'components/inputs/inputSelectMultiple';
import { ActivityLogContext } from 'contexts/activityLog';
import { FilterItem } from 'contexts/activityLog/types';

interface IActivityLogUsersFilter {
  modalScreen?: boolean;
  modalValues?: string;
  usersFilterChange?: (filter: FilterItem) => void;
}

const ActivityLogUsersFilter = ({
  modalScreen,
  modalValues,
  usersFilterChange,
}: IActivityLogUsersFilter): JSX.Element => {
  const { actions, filters, activityLogUsers } = useContext(ActivityLogContext);

  let initialActivityLogUsers: string[];
  if (!modalScreen) {
    initialActivityLogUsers = filters.generated_by ? filters.generated_by.split(',') : [];
  } else {
    initialActivityLogUsers = modalValues ? modalValues.split(',') : [];
  }

  const onChangeCreatedBy = (value: string[] | number[]) => {
    if (modalScreen) {
      usersFilterChange({
        name: 'generated_by',
        value: value.toString(),
      });
    } else {
      actions &&
        actions.onChangeFilter({
          name: 'generated_by',
          value: value.toString(),
        });
    }
  };
  return (
    <InputSelectMultiple
      values={initialActivityLogUsers}
      inputLabel="Generated by"
      placeholder="All"
      optionFilterProp="label"
      options={activityLogUsers}
      onChange={onChangeCreatedBy}
    />
  );
};

export default ActivityLogUsersFilter;
