import React, { useContext, useEffect, useState } from 'react';
import { Spinner } from 'components/loadings';
import {
  BodySubtitle,
  BodyTitle,
  PlaylistsCardsContainer,
  RecommendedContainer,
  PlaylistLoaderContainer,
} from './styles';
import { getRecommendedContentPlaylistsAction } from 'contexts/sonar/actions';
import { RecommendedPlaylistsFetchParams } from 'contexts/sonar/types';
import PlaylistCard from 'pages/Playlists/components/PlaylistCard';
import { PlaylistsContext } from 'contexts/playlists';
import { IPlaylist } from 'contexts/playlists/types';
import { Skeleton } from 'antd';

const UserRecommendedContent = ({
  uaa_id,
  learning_area_id,
  userProfile,
}: {
  uaa_id: string;
  learning_area_id: string;
  userProfile: any;
}): JSX.Element => {
  const [fetching, setFetching] = useState(false);
  const { actions } = useContext(PlaylistsContext);

  const [recommendedContentPlaylists, setRecommendedContentPlaylists] = useState([]);

  const getRecommendedContentPlaylists = async (payload: RecommendedPlaylistsFetchParams) => {
    setFetching(true);
    const { results } = await getRecommendedContentPlaylistsAction(payload);
    if (results instanceof Error) {
      setFetching(false);
    } else {
      if (results) await processRecommendedPlaylistsResponse(results);
      setFetching(false);
    }
  };

  const processRecommendedPlaylistsResponse = async (results: any) => {
    const parsedResults = results.map((playlist) => {
      return {
        ...playlist,
        created_for: {
          id: uaa_id,
          avatar: userProfile.avatar,
          first_name: userProfile.first_name,
          last_name: userProfile.last_name,
        },
      };
    });

    setRecommendedContentPlaylists(parsedResults);
    setFetching(false);
  };

  useEffect(() => {
    const payload = {
      uaa_id: uaa_id,
      learning_area_id: learning_area_id,
    };
    getRecommendedContentPlaylists(payload);
  }, [learning_area_id]);

  const handleAssignUser = async (playlist) => {
    setFetching(true);
    if (actions) {
      await actions.onAssignUser(playlist);
      await getRecommendedContentPlaylists({
        uaa_id: uaa_id,
        learning_area_id: learning_area_id,
      });
    }
  };

  const handleUnassignUser = async (playlist) => {
    setFetching(true);
    if (actions) {
      await actions.onUnassignUser(playlist);
      await getRecommendedContentPlaylists({
        uaa_id: uaa_id,
        learning_area_id: learning_area_id,
      });
    }
  };

  const PlaylistCards = () => {
    return (
      <PlaylistsCardsContainer>
        {fetching && (
          <PlaylistLoaderContainer>
            <Spinner active={fetching} top="230px" left="50%" position="relative" />
          </PlaylistLoaderContainer>
        )}
        {recommendedContentPlaylists.map((playlist: any) => (
          <PlaylistCard
            key={playlist.id}
            playlist={playlist}
            onAddUsers={() => undefined}
            onDeletePlaylist={() => undefined}
            onEditPlaylist={() => undefined}
            onClonePlaylist={() => undefined}
            onAssignUser={(playlist: IPlaylist) => handleAssignUser(playlist)}
            onUnassignUser={(playlist: IPlaylist) => handleUnassignUser(playlist)}
            hideClone={true}
          />
        ))}
      </PlaylistsCardsContainer>
    );
  };

  return (
    <RecommendedContainer>
      {recommendedContentPlaylists?.length > 0 && (
        <>
          <BodyTitle>Recommended training</BodyTitle>
          <BodySubtitle>
            Training playlists evolve with Skill Sonar assessment results.
          </BodySubtitle>
        </>
      )}
      {!recommendedContentPlaylists?.length && fetching ? (
        <Skeleton active={fetching} avatar paragraph={{ rows: 1 }} style={{ marginTop: '10px' }} />
      ) : (
        <PlaylistCards />
      )}
    </RecommendedContainer>
  );
};

export default UserRecommendedContent;
