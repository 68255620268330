import {
  ProfileHeaderCard,
  ProfileHeaderActions,
  ProfileHeaderGradient,
  ProfileData,
  AvatarContainer,
} from 'components/layout/profile/profileHeader/styles';
import { AccountHeaderProfileProps } from 'contexts/accounts/types';
import AccountDefaultLogo from 'assets/images/account-avatar.svg';
import HeaderActions from './HeaderActions';
import { formatDateInUtc } from 'utils/helpers';

const AccountProfileHeader = ({
  account,
  handleModal,
  refreshData,
}: AccountHeaderProfileProps): JSX.Element => {
  const initAt = formatDateInUtc(new Date(account.start_date), 'P');

  return (
    <ProfileHeaderCard>
      <ProfileHeaderGradient>
        <AvatarContainer>
          <img src={account.logo_url || AccountDefaultLogo} />
        </AvatarContainer>
        <ProfileData>
          <h2>{account.name}</h2>
          <p>Plan started at: {initAt}</p>
        </ProfileData>
      </ProfileHeaderGradient>
      <ProfileHeaderActions>
        <HeaderActions account={account} handleModal={handleModal} refreshData={refreshData} />
      </ProfileHeaderActions>
    </ProfileHeaderCard>
  );
};

export default AccountProfileHeader;
