import { BottomDataWrapper, GroupItemDataWrapper, GroupItemTitle } from './styles';
import { PLAN_ENTERPRISE_PLUS_LAB, PLAN_LAB_EXPERIENCE } from 'constants/plans_config';

import { CONTENT_TYPES } from 'constants/playlists';
import ContentTypeTag from '../../components/AddContent/ContentTypeTag';
import { SessionContext } from 'contexts/session';
import SkillDiveLabInfo from './SkillDiveLabInfo';
import { Space } from 'antd';
import Tooltip from 'components/tooltip';
import { stripHtml } from 'utils/helpers';
import { useContext } from 'react';
import useWindowSize from 'hooks/useWindowSize';

interface ILabItemData {
  data: any;
}

const LabItemData = ({ data }: ILabItemData): JSX.Element => {
  const { account } = useContext(SessionContext);
  const { content_type, name, description_html, is_lab_experience, is_trackable } = data;
  const strip = stripHtml(description_html);
  const { isScreenMd } = useWindowSize();

  const has_lab_experience =
    account?.pricing_plan === PLAN_LAB_EXPERIENCE ||
    account?.pricing_plan === PLAN_ENTERPRISE_PLUS_LAB;

  const has_skill_dive =
    account?.account_available_addons?.filter((ad) => ad.name === CONTENT_TYPES.SKILL_DIVE).length >
    0;

  const tooltopOverlayStyle = {
    boxShadow: '0px 0px 48px rgba(0, 0, 0, 0.2)',
    borderRadius: '10px',
    backgroundColor: '#fff',
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: isScreenMd ? '500px' : '270px',
    minHeight: '150px',
    minWidth: '150px',
  };

  const disabled = is_lab_experience && !has_skill_dive && !has_lab_experience;

  return (
    <>
      {disabled ? (
        <Tooltip
          title={<SkillDiveLabInfo salesContactEmail={account?.sales_contact?.email} />}
          overlayInnerStyle={tooltopOverlayStyle}
          color={'#fff'}
        >
          <GroupItemDataWrapper style={{ opacity: disabled && '0.6' }}>
            <Space>
              <ContentTypeTag
                value={content_type}
                is_lab_experience={is_lab_experience}
                is_trackable={is_trackable}
              />
            </Space>
            <GroupItemTitle>{name}</GroupItemTitle>
            <BottomDataWrapper>
              <div
                style={{
                  maxWidth: '50vw',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {strip}
              </div>
            </BottomDataWrapper>
          </GroupItemDataWrapper>
        </Tooltip>
      ) : (
        <GroupItemDataWrapper>
          <Space>
            <ContentTypeTag
              value={content_type}
              is_lab_experience={is_lab_experience}
              is_trackable={is_trackable}
            />
          </Space>
          <GroupItemTitle>{name}</GroupItemTitle>
          <BottomDataWrapper>
            <div
              style={{
                maxWidth: '50vw',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {strip}
            </div>
          </BottomDataWrapper>
        </GroupItemDataWrapper>
      )}
    </>
  );
};

export default LabItemData;
