import styled, { css } from 'styled-components';
import surfaceSvg from 'assets/images/surface.svg';

const professionalBackground = css`
  background-image: url(${surfaceSvg}), linear-gradient(96.51deg, #863ad2 7.05%, #5936e4 93.87%);

  .notice-title {
    color: ${({ theme }) => theme.default.colors.fonts.white};
  }
`;

const aboutExpireBackground = css`
  background-image: url(${surfaceSvg});
  background-color: ${({ theme }) => theme.default.colors.dataVisualization.black.light};

  .notice-title {
    color: ${({ theme }) => theme.default.colors.fonts.lightPurple};
  }
`;

export const UpgradeNoticeContent = styled.div<{ type?: string }>`
  font-family: 'Circular-Std';
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 14px 30px;
  width: 100%;

  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: right;

  ${({ type }) => (type === 'professional' ? professionalBackground : aboutExpireBackground)};

  .notice-title {
    margin: 0;
  }

  svg {
    cursor: pointer;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-right: 24px;
`;

export const Title = styled.h5`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  color: ${({ theme }) => theme.default.colors.palette.Business_LightThemeGreyLighter};
`;

export const Description = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: ${({ theme }) => theme.default.colors.palette.Business_LightThemeGreyLighter};
`;
