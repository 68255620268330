import {
  ICategoriesFetchParams,
  ICategoryFetchParams,
  getCategories,
  getCategory,
} from 'services/categories';
import { ICategory } from './types';
import { handleErrors } from '../helpers';

export const getCategoriesAction = async (
  params?: ICategoriesFetchParams
): Promise<{ results: ICategory[]; count: number } | Error> => {
  try {
    const { data } = await getCategories(params);
    return {
      results: data.results,
      count: data.count,
    };
  } catch (error) {
    return handleErrors(error);
  }
};

export const getCategoryAction = async (
  params: ICategoryFetchParams
): Promise<{ Category: ICategory } | Error> => {
  try {
    const { data } = await getCategory(params);
    return {
      Category: data,
    };
  } catch (error) {
    return handleErrors(error);
  }
};
