import styled from 'styled-components';

export const FilterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
`;

export const FilterCount = styled.div`
  padding: 0 0 0 12px;
  color: #1a2535;
  font-weight: 500;
  font-size: 13px;
  align-self: end;
  justify-self: end;
`;
