import Button from 'components/button';
import Modal from 'components/modal';
import {
  HeaderContainer,
  Input,
  Label,
  ModalContainer,
  ModalContent,
  ModalSection,
  InputHelper,
} from 'components/modal/styles';
import Notification from 'components/notification';
import { Account, Domains } from 'contexts/accounts/types';
import { FC, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { editDomains, createDomains } from 'services/accounts';
import isValidDomain from 'is-valid-domain';

interface ManageDomainsProps {
  onClose: (ok?: boolean) => void;
  edit?: Domains;
  account: Account;
}

const ManageDomains: FC<ManageDomainsProps> = ({ onClose, edit, account }) => {
  const [domain, setDomain] = useState(edit?.email_domain || '');
  const [error, setError] = useState(false);
  const title = edit ? 'Edit domain' : 'Create domains';

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    error && setError(false);
    setDomain(e.currentTarget.value.toLowerCase());
  };

  const handleManageDomains = async () => {
    if (!isValidDomain(domain)) {
      setError(true);
      Notification({ type: 'error', text: `${domain} is not a valid domain` });
      return false;
    }
    const domainsService = edit ? editDomains : createDomains;
    try {
      const response = await domainsService({ id: account.id, email: domain, domainId: edit?.id });
      if (response.status === 201) {
        // create
        Notification({ type: 'success', text: `${domain} has been created` });
        onClose(true);
      } else if (response.status === 200) {
        // edit
        Notification({ type: 'success', text: 'Domain was successfully edited' });
        onClose(true);
      } else throw new Error();
    } catch (error) {
      const msg = edit ? 'Couldn’t update domain' : 'Couldn’t create domain';
      Notification({ type: 'error', text: msg });
      onClose();
    }
  };

  return (
    <Modal
      centered
      visible={true}
      fullscreen={isMobile}
      onCancel={() => onClose()}
      width={isMobile ? '100%' : '595px'}
      footer={null}
      closeIconColor="#fff"
      maskClosable={false}
    >
      <ModalContainer>
        <HeaderContainer style={{ height: '139px' }}>
          <h2>{title}</h2>
        </HeaderContainer>
        <ModalContent grow flexColumn>
          <ModalSection>
            <Label>Domain</Label>
            <Input
              error={error}
              placeholder={edit ? 'domain' : 'Type a domain'}
              value={domain}
              onChange={handleChange}
            />
            <InputHelper>
              The system will restrict invitations to emails ending with this domain. Examples:
              ine.com, google.com.
            </InputHelper>
          </ModalSection>
          <ModalSection toBottom style={{ margin: !isMobile && '20px 0 0 0', display: 'flex' }}>
            {edit && (
              <>
                <Button
                  $secondary
                  icon={'Close'}
                  style={{ marginLeft: 'auto' }}
                  onClick={() => onClose(false)}
                >
                  Cancel
                </Button>
                <Button
                  icon={'Save'}
                  style={{ marginLeft: '20px' }}
                  disabled={!domain || domain?.trim() === ''}
                  onClick={handleManageDomains}
                >
                  Save
                </Button>
              </>
            )}
            {!edit && (
              <Button
                icon={'Plus'}
                style={{ marginLeft: 'auto' }}
                disabled={!domain || domain?.trim() === ''}
                onClick={handleManageDomains}
              >
                Create domain
              </Button>
            )}
          </ModalSection>
        </ModalContent>
      </ModalContainer>
    </Modal>
  );
};

export default ManageDomains;
