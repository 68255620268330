import React, { useState } from 'react';
import cloneDeep from 'lodash.clonedeep';
import Modal from 'components/modal';

import { isMobile } from 'react-device-detect';
import { SectionNameInput } from './SectionNameInput';
import {
  HeaderContainer,
  ModalActions,
  ModalContainer,
  ModalContent,
  ModalSection,
} from 'components/modal/styles';
import Button from 'components/button';
import { IContentGroup, IPlaylist } from 'contexts/playlists/types';
import Notification from 'components/notification';

interface IAAddEditSection {
  isVisible: boolean;
  playlist: IPlaylist;
  group?: IContentGroup;
  onClose: () => void;
  onConfirm: (content?: IContentGroup[]) => void;
}

const AddEditSection: React.FC<IAAddEditSection> = ({
  isVisible,
  playlist,
  group,
  onClose,
  onConfirm,
}) => {
  const title = group ? 'Edit section' : 'Create section';
  const ctaLabel = group ? 'Save changes' : 'Create Category';
  const [name, setName] = useState(group?.name || '');

  const handleConfirm = () => {
    if (playlist.content.find((e) => e.name === name)) {
      Notification({ text: 'Another section with that name already exists', type: 'error' });
      return;
    }

    const clonedContent = cloneDeep(playlist.content);

    if (!group) {
      clonedContent.push({
        name,
        content_type: 'group',
        content: [],
      });
    } else {
      const index = clonedContent.findIndex((e) => e.name === group.name);

      clonedContent[index].name = name;
    }

    onConfirm(clonedContent);
  };

  return (
    <Modal
      centered
      visible={isVisible}
      fullscreen={isMobile}
      onCancel={onClose}
      width={isMobile ? '100%' : '595px'}
      footer={null}
      closeIconColor="#fff"
      maskClosable={false}
    >
      <ModalContainer>
        <HeaderContainer>
          <h2>{title}</h2>
        </HeaderContainer>
        <ModalContent>
          <ModalSection>
            <SectionNameInput
              section={!!group}
              sectionName={name}
              setSectionName={(value) => setName(value)}
            />
          </ModalSection>
        </ModalContent>
        <ModalActions>
          <Button
            $primary
            disabled={!/^(?!\s*$).+/g.test(name)}
            style={{ marginLeft: 'auto' }}
            onClick={handleConfirm}
          >
            {ctaLabel}
          </Button>
        </ModalActions>
      </ModalContainer>
    </Modal>
  );
};

export default AddEditSection;
