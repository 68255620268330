import { FC } from 'react';
import CirclesInfoDisplay from 'components/circlesInfoDisplay/CirclesInfoDisplay';
import Icon from 'components/icons';
import { UserInvites } from 'contexts/users/types';
import { userImagesArray } from 'components/circlesInfoDisplay/utilities';
import AccountDefaultLogo from 'assets/images/account-avatar.svg';
import { CardContent, CustomLink } from 'components/layout/profile/profileContent/styles';
import EmptyTeamUsersCard from './EmptyTeamUsersCard';
import { TeamCardContainer } from '../TeamSharedStyles';
interface TeamUsersCardProps {
  teamUsers: UserInvites[];
  teamSlug: string;
  totalPendings: number;
  totalActives: number;
}

const TeamUsersCard: FC<TeamUsersCardProps> = ({
  teamSlug,
  teamUsers,
  totalActives,
  totalPendings,
}) => {
  const users = teamUsers.map((item) => {
    const { uaa_data } = item;
    return {
      avatar: uaa_data?.profile?.data.avatar,
      first_name: uaa_data?.profile?.data.first_name,
      last_name: uaa_data?.profile?.data.last_name,
      ...item,
    };
  });

  const userItems = userImagesArray(users);
  const total = totalActives + totalPendings;

  if (total <= 0) return <EmptyTeamUsersCard />;

  if (userItems.length < 2) userItems.push(<img src={AccountDefaultLogo} />);

  return (
    <TeamCardContainer>
      <CirclesInfoDisplay elements={userItems} count={total - userItems.length} />
      <CardContent>
        <h5 className="users-card__title">{total} User(s)</h5>
        <span className="users-card__counters">
          <p>Active users: {totalActives}</p>
          <p>Pending users: {totalPendings} </p>
        </span>
      </CardContent>
      <CustomLink to={`/users?team=${teamSlug}`}>
        <Icon icon="ArrowRightShort" size={'small'} />
      </CustomLink>
    </TeamCardContainer>
  );
};

export default TeamUsersCard;
