import { useContext } from 'react';
import UsersAsideFilters from 'components/usersAsideFilters';
import { UsersContext } from 'contexts/users';
import ModalDrawer from 'components/microLayouts/drawer';
import {
  ContentModalFilters,
  ContentScroll,
  ModalFiltersFooter,
} from 'pages/Accounts/components/AccountsHeader/styles';
import Button from 'components/button';

const FiltersModal = ({ open, close }: { open?: boolean; close: () => void }): JSX.Element => {
  const { actions } = useContext(UsersContext);

  return (
    <ModalDrawer
      closable={false}
      placement="bottom"
      visible={open}
      onClose={close}
      height="calc(100% - 64px)"
      destroyOnClose
    >
      <ContentModalFilters>
        <ContentScroll>
          <UsersAsideFilters autoApply={false} />
        </ContentScroll>
        <ModalFiltersFooter>
          <Button
            $full
            $secondary
            size={'small'}
            icon={'ChevronRight'}
            onClick={actions?.onClearSearch}
          >
            Clear filters
          </Button>
          <Button $full $secondary size={'small'} icon={'Trash'} onClick={actions?.onSearchApply}>
            Apply filters
          </Button>
        </ModalFiltersFooter>
      </ContentModalFilters>
    </ModalDrawer>
  );
};

export default FiltersModal;
