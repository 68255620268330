import {
  downloadReport,
  getReportPreferences,
  getReports,
  newReport,
  saveReportPreferences,
} from 'services/reports';
import {
  IGetReportsFetchParams,
  IGetReportsResponse,
  INewReportParams,
  IReport,
  IReportDL,
  IReportPreferencesResponse,
} from './types';
import { reportStatus } from './utils';

const resultParser = ({ created_at, created_by, filename, id, status, size }) => ({
  id,
  filename,
  created_at: created_at.replace(/T/, ' ').replace(/\..+/, ''),
  created_by: {
    first_name: created_by?.first_name,
    last_name: created_by?.last_name,
  },
  status,
  size: status === reportStatus.completed ? `${(parseInt(size) / 1000).toFixed(2)} KB` : '-',
});

export const getReportsAction = async (
  params: IGetReportsFetchParams
): Promise<IGetReportsResponse> => {
  const { data } = await getReports(params);
  const parsedResults = data.results.map(({ created_at, created_by, filename, id, status, size }) =>
    resultParser({
      created_at,
      created_by,
      filename,
      id,
      status,
      size,
    })
  );
  return {
    results: parsedResults,
    count: data.count,
  };
};

export const newReportAction = async (params: INewReportParams): Promise<{ result: IReport }> => {
  const { data } = await newReport(params);
  return {
    result: resultParser(data),
  };
};

export const downloadReportAction = async (id: string): Promise<{ result: IReportDL }> => {
  const { data } = await downloadReport(id);
  return {
    result: data,
  };
};

export const getReportSelectionPreferences = async (
  reportType?: string
): Promise<{ results: IReportPreferencesResponse[] }> => {
  const { data } = await getReportPreferences(reportType);
  return {
    results: data.results,
  };
};

export const saveReportSelectionPreferences = async (
  checkboxes: string[],
  reportType: string
): Promise<IReportPreferencesResponse> => {
  const { data } = await saveReportPreferences(checkboxes, reportType);
  return data;
};
