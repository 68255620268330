import styled from 'styled-components';
import { Card } from 'components/layout/profile/profileContent/styles';

export const CustomTableCard = styled(Card)`
  height: auto;
  grid-gap: 20px;
  display: flex;
  position: relative;
`;

export const TeamUsersTableHeader = styled.div`
  display: inline-flex;
  width: 100%;
  gap: 20px;
`;

export const SpinerContainer = styled.div<{ absolute?: boolean }>`
  position: relative;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 250px;
  padding: 30px;
  background: rgba(255, 255, 255, 0.5);
  z-index: 99;
  top: 0;
  ${({ absolute }) => absolute && 'position: absolute;'}
`;
