import { TeamsDataCtx } from './types';

export const teamsInitialValues: TeamsDataCtx = {
  teams: [],
  table: {
    items: null,
    pageSize: 9,
    page: 1,
    loading: false,
    count: 0,
    error: 'false',
  },
  selectedItems: [],
  isEmpty: false,
  filters: {
    page_size: '9',
    page: '1',
    search: '',
  },
  error: null,
  fetching: false,
};
