import { Tag } from 'antd';
import styled from 'styled-components/macro';

export const RoundedTag = styled(Tag)`
  border-radius: 16px;
  gap: 10px;
  width: fit-content;
`;

export const SsoTagContainer = styled.div`
  position: absolute;
  right: 20px;
`;

export const SsoInformationContainer = styled.div`
  display: grid;
  span {
    font-size: 14px;
    line-height: 20px;
    color: #57575c;
  }
  .sso-tag {
    display: flex;
    font-size: 12px !important;
    line-height: 14px;
    font-weight: 450;
    color: white;
    padding: 7px 12px;
  }
  .attributes-section {
    margin-top: 50px;
    font-weight: 700;
    border-left: 3px solid #ff5e5e;
    color: #57575c;
  }
  .attributes-list {
    margin-left: 10px;
  }
  .documentation-section {
    margin-top: 50px;
    a {
      color: #111a27;
      text-decoration: underline;
    }
  }
  .w-500 {
    font-weight: 500;
  }
`;
