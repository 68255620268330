import { useContext } from 'react';
import { isMobile } from 'react-device-detect';
import { UsersContext } from 'contexts/users';
import { FilterCount, FilterWrapper } from './styles';
import Checkbox from 'components/checkbox/Checkbox';
import { AddonLicenseFilterProps, FilterChangeEvents } from './types';
import Tooltip from 'components/tooltip';
import Icon from 'components/icons';

interface UsersAsideFiltersAddonsItemProps {
  label: string;
  value: string;
  id: string;
  name: string;
  onChange: (e: FilterChangeEvents) => void;
  defaultChecked?: boolean;
  checked?: boolean;
  controlled: boolean;
  count: number;
  tooltipTitle: string;
  disabled?: boolean;
}

const UsersAsideFiltersAddonsItem = ({
  label,
  value,
  id,
  name,
  onChange,
  defaultChecked,
  checked,
  controlled,
  count,
  tooltipTitle,
  disabled,
}: UsersAsideFiltersAddonsItemProps): JSX.Element => {
  return (
    <FilterWrapper>
      <Checkbox
        disabled={disabled}
        label={label}
        value={value}
        id={id}
        name={name}
        onChange={onChange}
        defaultChecked={defaultChecked}
        checked={checked}
        controlled={controlled}
      />
      <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
        <FilterCount disabled={disabled}>{count}</FilterCount>
        {!disabled && (
          <Tooltip
            className="item-tooltip"
            placement="right"
            title={tooltipTitle}
            overlayInnerStyle={{ textAlign: 'center' }}
          >
            <div style={{ position: 'absolute', right: '-18px', marginBottom: '3px' }}>
              <Icon data-testid="info-icon" icon="Info" fill={'var(--lilac)'} size="smaller" />
            </div>
          </Tooltip>
        )}
      </div>
    </FilterWrapper>
  );
};

interface UsersAsideFiltersAddonsProps extends AddonLicenseFilterProps {
  isActiveUsers: string;
}

const UsersAsideFiltersAddons = ({
  autoApply,
  onFilterChange,
  license_addons = [],
  no_license_addons = [],
  isActiveUsers,
}: UsersAsideFiltersAddonsProps): JSX.Element => {
  const { usersCounts } = useContext(UsersContext);
  const disabledFilters = isActiveUsers === 'false';

  return (
    <>
      {usersCounts?.addons_count?.map((addonCount) => (
        <div key={addonCount.id}>
          <h3 className="mt-16 mb-5 fs-1 card-title">{addonCount.name || 'Addon'} License</h3>
          <UsersAsideFiltersAddonsItem
            label={'License'}
            value={addonCount.id}
            id="license_addons"
            name="license_addons"
            onChange={onFilterChange}
            defaultChecked={!autoApply ? license_addons.includes(addonCount.id) : undefined}
            checked={autoApply ? license_addons.includes(addonCount.id) : undefined}
            controlled={!isMobile}
            count={addonCount.license || 0}
            tooltipTitle="Number of active users with a license assigned"
            disabled={disabledFilters}
          />
          <UsersAsideFiltersAddonsItem
            label={'No License'}
            value={addonCount.id}
            id="no_license_addons"
            name="no_license_addons"
            onChange={onFilterChange}
            defaultChecked={!autoApply ? no_license_addons.includes(addonCount.id) : undefined}
            checked={autoApply ? no_license_addons.includes(addonCount.id) : undefined}
            controlled={!isMobile}
            count={addonCount.no_license || 0}
            tooltipTitle="Number of active users without a license assigned"
            disabled={disabledFilters}
          />
        </div>
      ))}
    </>
  );
};

export default UsersAsideFiltersAddons;
