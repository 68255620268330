import { useContext, useState } from 'react';
import styled from 'styled-components';

import AnalyticsProvider from 'contexts/analytics';
import { SessionContext } from 'contexts/session';

import AnalyticsContent from './components/AnalyticsContent/AnalyticsContent';
import AnalyticsEmpty from './components/AnalyticsEmpty';

import { topBarHeight as tbh } from 'utils/helpers';
import { screenMd } from 'constants/styles/breakpoints';

const PageContent = styled.div<{ translateY?: string; showingNotification?: boolean }>`
  background: var(--ghost-white);

  ${({ translateY }) =>
    translateY &&
    `
    padding-top: ${translateY};
  `}

  ${({ showingNotification, translateY }) =>
    showingNotification &&
    `
    ${screenMd} {
      padding-top: calc( ${translateY} + 40px);
    }
  `}
`;

const Analytics = (): JSX.Element => {
  const { isAccountOwner, account, user, notShowingDrawers } = useContext(SessionContext);
  const amountOfUsers = account?.users_count ? Number(account.users_count) : 0;

  // 130 stands for the height of the filters (64) plus the height of Analytics title (56) + padding (10)
  const basicHeight = tbh(user) + 130;
  const [showingNotification, setShowingNotification] = useState(true);

  const onCloseNotification = () => {
    setShowingNotification(false);
  };

  return (
    <>
      {isAccountOwner && amountOfUsers === 0 ? (
        <PageContent
          translateY={notShowingDrawers && `${basicHeight}px`}
          showingNotification={showingNotification}
        >
          <AnalyticsEmpty />
        </PageContent>
      ) : (
        <AnalyticsProvider>
          <PageContent
            translateY={notShowingDrawers && `${basicHeight}px`}
            showingNotification={showingNotification}
          >
            <AnalyticsContent onCloseNotification={onCloseNotification} />
          </PageContent>
        </AnalyticsProvider>
      )}
    </>
  );
};

export default Analytics;
