import { useContext } from 'react';
import { isMobile } from 'react-device-detect';
import { FilterChangeEvents, FilterProps } from './types';
import Checkbox from 'components/checkbox';
import { FilterCount, FilterWrapper } from './styles';
import { UsersContext } from 'contexts/users';
import Tooltip from 'components/tooltip';
import Icon from 'components/icons';

const UsersAsideFiltersLicenseItem = ({
  label,
  value,
  id,
  name,
  onFilterChange,
  autoApply,
  filter,
  countValue,
  tooltipTitle,
}: {
  label: string;
  value: string;
  id: string;
  name: string;
  onFilterChange: (e: FilterChangeEvents) => void;
  autoApply: boolean;
  filter: string | string[];
  countValue: number;
  tooltipTitle: string;
}): JSX.Element => {
  return (
    <FilterWrapper>
      <Checkbox
        label={label}
        value={value}
        id={id}
        name={name}
        onChange={onFilterChange}
        defaultChecked={!autoApply ? filter.includes(value) : undefined}
        checked={autoApply ? filter.includes(value) : undefined}
        controlled={!isMobile}
      />
      <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
        <FilterCount>{countValue}</FilterCount>
        <Tooltip
          className="item-tooltip"
          placement="right"
          title={tooltipTitle}
          overlayInnerStyle={{ textAlign: 'center' }}
        >
          <div style={{ position: 'absolute', right: '-18px', marginBottom: '3px' }}>
            <Icon data-testid="info-icon" icon="Info" fill={'var(--lilac)'} size="smaller" />
          </div>
        </Tooltip>
      </div>
    </FilterWrapper>
  );
};

interface UsersAsideFiltersLicenseProps extends FilterProps {
  isActiveUsers: string;
}

const UsersAsideFiltersLicense = ({
  autoApply,
  onFilterChange,
  filter = [],
  isActiveUsers,
}: UsersAsideFiltersLicenseProps): JSX.Element => {
  const { usersCounts } = useContext(UsersContext);
  const userStatusText = isActiveUsers === 'true' ? 'active' : 'pending';

  return (
    <>
      <h3 className="mt-16 mb-5 fs-1 card-title">Plan Licenses</h3>
      <UsersAsideFiltersLicenseItem
        label="License"
        value="license"
        id="license"
        name="license"
        onFilterChange={onFilterChange}
        autoApply={autoApply}
        filter={filter}
        countValue={usersCounts?.license_count?.license || 0}
        tooltipTitle={`Number of ${userStatusText} users with a license assigned`}
      />
      <UsersAsideFiltersLicenseItem
        label="No license"
        value="no_license"
        id="no_license"
        name="license"
        onFilterChange={onFilterChange}
        autoApply={autoApply}
        filter={filter}
        countValue={usersCounts?.license_count?.no_license || 0}
        tooltipTitle={`Number of ${userStatusText} users without a license assigned`}
      />
    </>
  );
};

export default UsersAsideFiltersLicense;
