import { useContext } from 'react';
import { Account } from 'contexts/accounts/types';
import { UsersContext } from 'contexts/users';
import { FilterChangeEvents } from 'components/usersAsideFilters/types';

const AccountUsersAsideFilterActive = ({ account }: { account: Account }): JSX.Element => {
  const { filters, actions } = useContext(UsersContext);

  const handleFilter = (e: FilterChangeEvents) => {
    actions.onChangeFilters(
      {
        name: 'active',
        value: e.target.value,
      },
      false,
      true
    );
  };
  return (
    <>
      <h3 className="mb-8 fs-1 card-title">Active</h3>

      <form className="users-aside-filters mb-8">
        <div className="form-check mb-6">
          <input
            className="form-check-input"
            type="radio"
            name="active"
            id="isActive"
            value="true"
            onChange={handleFilter}
            defaultChecked={filters.active === 'true'}
            checked={filters.active === 'true'}
          />
          <label
            className="form-check-label fw-bold d-flex justify-content-between"
            htmlFor="isActive"
          >
            <span>Active</span>
            <span>{account.account_users?.active || 0}</span>
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="active"
            id="isPending"
            value="false"
            onChange={handleFilter}
            defaultChecked={filters.active === 'false'}
            checked={filters.active === 'false'}
          />
          <label
            className="form-check-label fw-bold d-flex justify-content-between"
            htmlFor="isPending"
          >
            <span>Pending</span>
            <span>{account.account_users?.pending || 0}</span>
          </label>
        </div>
      </form>
    </>
  );
};

export default AccountUsersAsideFilterActive;
