import styled from 'styled-components';
import { Button as AntdButton } from 'antd';

import { DatePicker } from 'antd';
import { screenSm } from 'constants/styles/breakpoints';
const { RangePicker } = DatePicker;

export const TopContainer = styled.div`
  display: flex;
`;
export const BottomContainer = styled.div`
  display: flex;
  margin-top: 15px;
  padding-left: 20px;
`;

export const PillsWrapper = styled.div`
  display: flex;
  flex: 1;
  color: #1a2535;
  padding: 5px 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  align-items: center;

  button {
    &:hover {
      height: 24px;
      padding: 5px 10px;
      white-space: nowrap;
      border-style: none;
      background: transparent;
      font-weight: 500;
      background: #f7f7f9;
      border-radius: 10px;
    }
  }

  ${screenSm} {
    gap: 10px;
  }
`;

export const CustomGridWrapper = styled.div`
  font-size: 15px;
  line-height: 19px;
  color: #57575c;

  display: flex;
  flex: 1;
  grid-gap: 10px;
`;

export const StyledInput = styled.input<{ error?: boolean }>`
  height: 44px;
  width: 100%;
  max-width: 300px;
  padding: 15px 10px;
  border: none;
  border-radius: 10px;
  background: ${({ theme }) => theme.default.colors.palette.Business_LightThemeGreyLighter};
  &:focus,
  &:active {
    outline: none;
  }
  &:disabled {
    cursor: disabled;
  }
  ${({ error }) =>
    error &&
    `
   		border: 1px solid red;
  	`}
`;

export const RangeDatePicker = styled(RangePicker)`
  width: 44px;
  height: 44px;
  border: 1px solid #a793f6;
  border-radius: 8px;
  &:hover {
    border-color: #1a2535;
  }
  &:active {
    background: #1a2535;
  }
  .ant-picker-range-separator,
  .ant-picker-input,
  .ant-picker-clear {
    display: none;
  }
  .ant-picker-suffix {
    margin: 0px 2px;

    path {
      stroke: #a793f6;
    }
  }
`;

export const DateRangeButton = styled(AntdButton)`
  border-radius: 8px;
  border: 1px solid #a793f6;
  height: 44px;
  width: 44px;
`;

export const DateRangesSelect = styled.select`
  display: flex;
  align-items: center;
  background: #f2f2f7;
  border-radius: 10px;
  padding: 7.5px 10px;
  border-style: none;
`;

export const IntervalWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  line-height: 19px;
  color: #57575c;

  span {
    margin-right: 10px;
  }

  .ant-dropdown {
    width: 200px !important;
  }
`;

export const Label = styled.label`
  margin-right: 10px;
  white-space: nowrap;
  align-self: center;
`;
