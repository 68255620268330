import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const CardContainer = styled.div<{ editable?: boolean }>`
  padding: 20px 20px 0;
  height: 100%;
  width: calc(100% + 40px);
  margin-left: -15px;
  background: linear-gradient(180deg, rgba(231, 231, 231, 0.5) 0%, rgba(255, 255, 255, 0) 40px);
  display: flex;
  gap: 30px;
  ${({ editable }) =>
    !editable &&
    `padding: 20px 20px 0 15px;
     gap: 10px;`}
`;

export const ListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ItemContainer = styled.div`
  display: flex;
  padding: 11px 0;
  font-size: 12px;
  font-style: normal;
  font-weight: 450;
  line-height: 14px;
  color: var(--dove-gray);
  width: 100%;
  box-sizing: content-box;
  align-items: center;
  &:not(:last-child) {
    padding: 11px 0 21px;
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 10px;
  }
`;

export const Booked = styled.div`
  color: var(--positive-dark);
`;

export const BookedContainer = styled.div<{
  color?: boolean;
  active?: boolean;
  inactive?: boolean;
}>`
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 5px;
  ${({ inactive }) =>
    inactive
      ? css`
          border-left: 4px solid var(--lilac-dark-60);
          > :not(.product-status-cta-container) {
            opacity: 0.6;
          }
        `
      : 'border-left: 4px solid var(--lilac-dark)'}
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 204px;
`;

export const AssignedContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const NotBookedContainer = styled.div`
  color: var(--gray-nine);
  font-size: 12px;
  font-weight: 450;
  line-height: 14px;
  span {
    color: var(--lilac-dark);
    text-decoration-line: underline;
    cursor: pointer;
  }
`;

export const CardLoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  z-index: 500;
  background-color: var(--white-50);
  position: absolute;
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

export const VerticalDivider = styled.div`
  width: 1px;
  height: 28px;
  background: var(--bright-gray-lighter);
  margin: 0 10px;
`;
