import { ContentLayout, MainContent } from 'components/layout/profile/profileContent/styles';
import { Header, RaxNotification, Title, VerticalContainer } from 'pages/RaxPage/styles';
import RaxProvider from 'contexts/rax';
import { Redirect } from 'react-router-dom';
import ProductCard from './components/ProductCard';
import { SessionContext } from 'contexts/session';
import { useContext, useEffect, useMemo, useState } from 'react';
import AccOwnerRaxPage from './AccOwnerRaxPage';
import Notification from 'components/notification';
import {
  getAccountRaxLabsAction,
  getRaxBookingsAction,
  getRaxLabsAction,
  postAccountRaxLabAction,
  postRaxBookingAction,
  removeRaxBookingAction,
} from 'contexts/rax/actions';
import { BOOKING_STATUS } from './utils';
import Spinner from 'components/loadings/Spinner';
import { getAccountsAction } from 'contexts/accounts/actions';
import { IBookingsParams } from 'contexts/rax/types';

interface IAssignProduct {
  account: string;
  rax_lab: string;
  quantity: number;
}

const Rax = (): JSX.Element => {
  const isRaxEnvEnabled = process.env.REACT_APP_RAX_RENTALS_ENABLED === 'True';
  const { isStaff, isAccountOwner, isRaxEnabled: isAccountRaxEnabled } = useContext(SessionContext);
  const [products, setProducts] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [accounts, setAccounts] = useState({ count: 0, items: [] });

  if (!isRaxEnvEnabled || (!isStaff && !isAccountOwner)) {
    return <Redirect to="/" />;
  }

  useEffect(() => {
    if (isAccountRaxEnabled === false) {
      Notification({
        type: 'error',
        text: 'You do not have access to this feature.',
      });
    }
  }, [isAccountRaxEnabled]);

  const isRaxEnabled = useMemo(() => {
    if (isStaff) return isRaxEnvEnabled;
    return isRaxEnvEnabled && isAccountRaxEnabled && isAccountOwner;
  }, [isRaxEnvEnabled, isAccountRaxEnabled]);

  const params = { deep_details: true, ...(isAccountOwner ? { status: 'available' } : {}) };

  const getStaffData = async () => {
    setFetching(true);
    const response = await getRaxLabsAction(params);
    if (response?.status === 200) setProducts(response.data.results);
    await loadAccounts();
    setFetching(false);
  };

  const loadAccounts = async () => {
    setFetching(true);
    const filters = { page_size: '999', page: '1', status: 'active' };

    const accountsData = await getAccountsAction(filters);
    if (accountsData instanceof Error) {
      setAccounts((prevState) => ({ ...prevState, error: accountsData?.message }));
    } else {
      setAccounts(() => ({
        count: accountsData?.results?.count,
        items: accountsData?.results?.results,
      }));
    }

    setFetching(false);
  };

  const getAccOwnerData = async () => {
    const { ACTIVE, UPCOMING, RESERVED } = BOOKING_STATUS;
    const status = `${ACTIVE},${UPCOMING},${RESERVED}`;
    const bookingParams = { status };
    try {
      setFetching(true);
      const promises = [getAccountRaxLabsAction(params), getRaxBookingsAction(bookingParams)];
      const [accountDataResponse, bookingDataResponse] = await Promise.allSettled(promises);
      if (accountDataResponse.status === 'fulfilled' && accountDataResponse.value.status === 200)
        setProducts(accountDataResponse.value.data.results);
      if (bookingDataResponse.status === 'fulfilled' && bookingDataResponse.value.status === 200)
        setBookings(bookingDataResponse.value.data.results);
    } catch (e) {
      Notification({
        type: 'error',
        text: 'Error fetching your data. Please try again.',
      });
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    if (isStaff) getStaffData();
    if (isAccountOwner) getAccOwnerData();
  }, []);

  const onScheduleBooking = async (params: IBookingsParams) => {
    try {
      setFetching(true);
      const response = await postRaxBookingAction(params);
      if (response?.status === 201) {
        Notification({
          type: 'success',
          text: 'Your product has been successfully saved!',
          duration: 8,
        });
        getAccOwnerData();
      }
    } catch (e) {
      Notification({
        type: 'error',
        text: 'Error saving your changes. Please try again.',
      });
    } finally {
      setFetching(false);
    }
  };

  const onRemoveBooking = async (id: string) => {
    try {
      setFetching(true);
      const response = await removeRaxBookingAction(id);
      if (response?.status === 204) {
        Notification({
          type: 'success',
          text: 'Your boooking has been successfully canceled.',
          duration: 8,
        });
        getAccOwnerData();
      }
    } catch (e) {
      Notification({
        type: 'error',
        text: 'Error saving your changes. Please try again.',
      });
    } finally {
      setFetching(false);
    }
  };

  const onAssign = async (data: IAssignProduct) => {
    try {
      setFetching(true);
      const response = await postAccountRaxLabAction(data);
      if (response?.status === 201) await getStaffData();
      Notification({
        type: 'success',
        text: (
          <RaxNotification>
            <div>Products Assigned!</div>{' '}
            <div>
              Visit{' '}
              <a href={`/account/${data?.account}}`} target="_blank" rel="noreferrer">
                the account page
              </a>{' '}
              for more details
            </div>
          </RaxNotification>
        ),
        duration: 8,
      });
    } finally {
      setFetching(false);
    }
  };

  return (
    <RaxProvider>
      {isAccountRaxEnabled === false && <Redirect to="/" />}
      {isRaxEnabled && (
        <ContentLayout
          style={{
            height: '100%',
            backgroundColor: isAccountOwner ? 'var(--grey-lighter)' : 'none',
          }}
        >
          <div
            style={{
              width: '100%',
              backgroundColor: isAccountOwner ? 'var(--grey-lighter)' : 'none',
              boxShadow: isAccountOwner ? 'none' : 'inherit',
            }}
          >
            <Header>
              <Title>Remote Access Experience</Title>
            </Header>
            {!fetching && (
              <MainContent>
                <VerticalContainer>
                  {isStaff &&
                    products.map((product) => (
                      <ProductCard
                        key={product.id}
                        {...product}
                        accounts={accounts.items}
                        onAssign={onAssign}
                      />
                    ))}
                  {isAccountOwner && products.length > 0 && (
                    <AccOwnerRaxPage
                      products={products}
                      bookings={bookings}
                      onScheduleBooking={onScheduleBooking}
                      onRemoveBooking={onRemoveBooking}
                    />
                  )}
                </VerticalContainer>
              </MainContent>
            )}
            {fetching && <Spinner active={fetching} />}
          </div>
        </ContentLayout>
      )}
    </RaxProvider>
  );
};

export default Rax;
