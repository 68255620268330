import styled from 'styled-components';
import { colors } from 'constants/styles/colors';

export const AssignedPlaylistsContainer = styled.div`
  height: 100%;
`;

export const AssignedPlaylistsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 80%;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-track {
    background: #c4c4c4;
  }

  ::-webkit-scrollbar-thumb {
    background: #666666;
    border-radius: 20px;
    height: 20px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #666666;
  }
`;

export const AssignedPlaylistsItemContainer = styled.div`
  display: flex;
  gap: 10px;
  color: ${colors.fonts.darkGray};
`;

export const AssignedPlaylistsItemName = styled.span`
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
`;

export const AssignedPlaylistsItemProgress = styled.span`
  font-size: 10px;
  font-weight: 450;
  line-height: 12px;
`;

export const AssignedPlaylistItemNameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const AssignedPlaylistDot = styled.div<{ color: string }>`
  height: 15px;
  width: 15px;
  border-radius: 30px;
  background: ${({ color }) => color};
  margin-top: 2px;
`;
