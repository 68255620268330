import { UserTeamsPayload } from 'components/usersTable/types';
import { UserTeam, Team } from 'contexts/teams/types';

export interface Invite {
  email: string;
  is_account_owner?: boolean;
  is_manager: boolean;
  has_license: boolean;
  team?: string;
  id?: string;
  teams?: UserTeamsPayload[];
  userTeams?: UserTeam[];
}

export interface SetInvites {
  emailInvite: string;
  invites: Invite[];
  action: 'setRole' | 'setLicense' | 'setAccountRole';
  value: boolean;
}

export interface InvitesBatch {
  message: string;
  team?: string;
  teams?: UserTeamsPayload[];
  invites: Invite[];
  detail?: string;
  account?: string;
}

export const setInviteData = ({ invites, emailInvite, action, value }: SetInvites): Invite[] => {
  const inviteIndex = invites.findIndex(({ email }) => email === emailInvite);
  const newInvites = invites;
  if (action === 'setRole') {
    newInvites[inviteIndex].is_manager = value;
  } else if (action === 'setLicense') {
    newInvites[inviteIndex].has_license = value;
  } else if (action === 'setAccountRole') {
    newInvites[inviteIndex].is_account_owner = value;
  }
  return newInvites;
};

interface InvitesAction {
  type: 'SET_EMAIL_INVITES' | 'SET_TEAM' | 'SET_MESSAGE';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const invitesReducer = (state: InvitesBatch, action: InvitesAction): InvitesBatch => {
  if (action.type === 'SET_EMAIL_INVITES' && (action.value as Invite[])) {
    return { ...state, invites: action.value };
  } else if (action.type === 'SET_TEAM') {
    return { ...state, teams: action.value };
  } else if (action.type === 'SET_MESSAGE') {
    return { ...state, message: action.value };
  } else {
    return state;
  }
};

// para pasar objeto a array cada vez que seteo el nuevo state
// let array2 = array.map(a => {return {...a}})
