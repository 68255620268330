import React from 'react';
import { Container, StyledEllipsis } from './styles';

interface IEllipsisGroup {
  steps: number;
  stepActive: number;
}

const EllipsisGroup: React.FC<IEllipsisGroup> = ({ steps, stepActive }) => {
  return (
    <Container>
      {Array.from(Array(steps).keys()).map((key) => (
        <StyledEllipsis key={key} active={key === stepActive} />
      ))}
    </Container>
  );
};

export default EllipsisGroup;
