import {
  CheckboxWrapper,
  ContentListItem,
  CourseRowRightWrapper,
  HitLabel,
  SearchResultsWrapper,
} from './styles';
import { PLAN_ENTERPRISE_PLUS_LAB, PLAN_LAB_EXPERIENCE } from 'constants/plans_config';
import { PreviewCourse, PreviewLab, PreviewVideo } from 'pages/Playlists/components/PreviewContent';
import { UseInfiniteHitsProps, useInfiniteHits } from 'react-instantsearch-hooks';
import { useContext, useEffect, useRef, useState } from 'react';

import { CONTENT_TYPES } from 'constants/playlists';
import Checkbox from 'components/checkbox';
import ContentTypeTag from './ContentTypeTag';
import { IContentItem } from './AddContent';
import Icon from 'components/icons';
import Notification from 'components/notification';
import PreviewLearningPath from '../PreviewContent/PreviewLearningPath';
import { SessionContext } from 'contexts/session';
import { Spinner } from 'components/loadings';
import Tooltip from 'components/tooltip';
import algoliasearch from 'algoliasearch';
import useWindowSize from 'hooks/useWindowSize';

interface SearchResultsProps extends UseInfiniteHitsProps {
  contentToExclude: Record<string, IContentItem>;
  selectedContent: Record<string, IContentItem>;
  onCheckContent: (hit: IContentItem) => void;
}

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_APP_KEY
);

const contentIndex = searchClient.initIndex(process.env.REACT_APP_ALGOLIA_SEARCH_INDEX);

const PreviewComponent = {
  course: PreviewCourse,
  video: PreviewVideo,
  lab: PreviewLab,
  'learning-path': PreviewLearningPath,
};

export const SearchResults = ({
  contentToExclude,
  selectedContent,
  onCheckContent,
  ...props
}: SearchResultsProps): JSX.Element => {
  const { isScreenMd } = useWindowSize();
  const { hits, isLastPage, showMore } = useInfiniteHits(props);
  const filteredHits = hits.filter(
    (hit) =>
      !contentToExclude[hit.objectID] &&
      hit.content_type !== 'bootcamp' &&
      hit.content_type !== 'collection'
  );
  const sentinelRef = useRef(null);
  const [hitsDetails, setHitsDetails] = useState<Record<string, any>>({});
  const [tooltipVisible, setTooltipVisible] = useState(null);
  const { account } = useContext(SessionContext);

  const has_lab_experience =
    account?.pricing_plan === PLAN_LAB_EXPERIENCE ||
    account?.pricing_plan === PLAN_ENTERPRISE_PLUS_LAB;

  const has_skill_dive =
    account?.account_available_addons?.filter((ad) => ad.name === CONTENT_TYPES.SKILL_DIVE).length >
    0;

  useEffect(() => {
    let hitsTooltip = {};
    filteredHits.map((hit) => {
      hitsTooltip = {
        ...hitsTooltip,
        [hit.objectID]: false,
      };
    });
  }, [filteredHits]);

  useEffect(() => {
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isLastPage) showMore();
        });
      });

      observer.observe(sentinelRef.current);

      return () => observer.disconnect();
    }
  }, [isLastPage, showMore, filteredHits]);

  const getHit = async (hit) => {
    if (hitsDetails[`${hit.content_type}:${hit.objectID}`]) return;

    try {
      const hitDetail = await contentIndex.getObject(hit.objectID);
      setHitsDetails((prevState) => ({
        ...prevState,
        [`${hit.content_type}:${hit.objectID}`]: hitDetail,
      }));
    } catch (error) {
      Notification({ text: 'Error loading details', type: 'error' });
    }
  };

  const handleVisibility = (value: boolean, hit: any) => {
    setTooltipVisible((prevState) => ({
      ...prevState,
      [hit.objectID]: value,
    }));
    value && getHit(hit);
  };

  return (
    <SearchResultsWrapper>
      {filteredHits.map((hit: any) => (
        <ContentListItem key={hit.objectID} ref={sentinelRef}>
          <CheckboxWrapper
            onClick={() =>
              hit.is_lab_experience && (!has_skill_dive || !has_lab_experience)
                ? null
                : onCheckContent(hit)
            }
          >
            <Checkbox
              style={{
                fontWeight: '500',
                fontSize: '14px',
                lineHeight: '20px',
              }}
              checked={!!selectedContent[hit.objectID]}
              onChange={() => onCheckContent(hit)}
              controlled
              disabled={hit.is_lab_experience && !has_lab_experience && !has_skill_dive}
            />
            <HitLabel disabled={hit.is_lab_experience && !has_skill_dive && !has_lab_experience}>
              {hit.name}
            </HitLabel>
          </CheckboxWrapper>
          <CourseRowRightWrapper>
            <ContentTypeTag
              value={hit.content_type}
              is_lab_experience={hit.is_lab_experience}
              is_trackable={!!hit.flags?.length}
            />
            <Tooltip
              title={
                hitsDetails[`${hit.content_type}:${hit.objectID}`] ? (
                  PreviewComponent[hit.content_type]({
                    data: hitsDetails[`${hit.content_type}:${hit.objectID}`],
                  })
                ) : (
                  <Spinner active position="static" />
                )
              }
              placement={isScreenMd ? 'right' : 'bottomRight'}
              trigger={['hover', 'click']}
              zIndex={9999}
              overlayInnerStyle={{
                boxShadow: '0px 0px 48px rgba(0, 0, 0, 0.2)',
                borderRadius: '10px',
                padding: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: isScreenMd ? '500px' : '270px',
                minHeight: '150px',
                minWidth: '150px',
              }}
              onVisibleChange={(visible) => handleVisibility(visible, hit)}
              color={'#fff'}
            >
              <div>
                <Icon
                  size="small"
                  fill={tooltipVisible && tooltipVisible[hit.objectID] ? '#7965C6' : '#A793F6'}
                  style={{ cursor: 'pointer' }}
                  icon="Info"
                />
              </div>
            </Tooltip>
          </CourseRowRightWrapper>
        </ContentListItem>
      ))}
    </SearchResultsWrapper>
  );
};
