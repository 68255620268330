import styled from 'styled-components/macro';

import { pendingColor } from 'utils/colors';

const styles = `
	align-items: center;
	border-radius: 50%;
	display: flex;
	font-family: Circular-Std;
	font-size: 4em;
	font-style: normal;
	font-weight: 700;
	height: 135px;
	justify-content: center;
	position: absolute;
	top: 40px;
	width: 135px;
`;

export const Container = styled.div`
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 153px;
  justify-content: center;
  text-align: center;
  width: 100%;
`;

export const IMG = styled.img<{
  src: string;
}>`
  ${styles};
`;

export const DivFirstLetterEmail = styled.div<{ grow?: number }>`
  background-color: ${pendingColor.lighter};
  color: ${pendingColor.base};
  ${styles}
`;
