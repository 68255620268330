import { createContext } from 'react';
import { FCProps } from 'types/FCProps';
import { playlistsInitialValues } from './utils';
import { IFilterItem, IPlaylistsDataCtx } from './types';
import useSearchQuery from 'hooks/useSearchQuery';
import usePlaylistsContent from 'hooks/usePlaylistsContent';

export const PlaylistsContext = createContext<IPlaylistsDataCtx>(playlistsInitialValues);

const PlaylistsProvider = ({ children }: FCProps): JSX.Element => {
  const { playlists, fetching, deletePlaylist, refreshPlaylists, onAssignUser, onUnassignUser } =
    usePlaylistsContent();
  const { search, updateSearch, clearSearch } = useSearchQuery();
  const playlistsItems = playlists;

  const updateFilters = (newValue: IFilterItem, concat: boolean, autoApply: boolean) => {
    const filter = newValue.name.toString();
    const prevValue = search.get(filter);
    let value = newValue.value.toString();

    if (concat && prevValue) {
      // To add values or remove values in the same query parameter.
      const valuesArr = prevValue.split(',');
      value = valuesArr.includes(value)
        ? valuesArr.filter((e) => e !== value).join(',')
        : [...valuesArr, value].join(',');
    }
    // remove filter so then it is moved to the last place.
    search.delete(filter);
    search.set(filter, value.toString());
    // Clear paging if last filter applied is not a page change.
    filter !== 'page' && search.set('page', '1');

    // Apply query params if it is an autoapply filter.
    autoApply && handleSearchApply();
  };

  const handleSearchApply = () => {
    updateSearch(search?.toString());
  };

  const handleRefreshPlaylists = () => {
    refreshPlaylists();
  };

  const clearTypeFilters = () => {
    search.delete('type');
    handleSearchApply();
  };

  const clearAssignedFilters = () => {
    search.delete('assigned');
    handleSearchApply();
  };

  return (
    <PlaylistsContext.Provider
      value={{
        list: {
          items: playlistsItems.items,
          business_account_id: playlistsItems.business_account_id,
          error: playlistsItems.error,
          loading: playlistsItems.loading,
          count: playlistsItems.count,
        },
        fetching: fetching,
        filters: {
          page: search.get('page') || playlistsInitialValues.filters.page,
          page_size: search.get('page_size') || playlistsInitialValues.filters.page_size,
          name: search.get('name') || playlistsInitialValues.filters.name,
          type: search.get('type') || playlistsInitialValues.filters.type,
          assigned: search.get('assigned') || playlistsInitialValues.filters.assigned,
        },
        actions: {
          onChangeFilters: updateFilters,
          onClearSearch: clearSearch,
          onClearTypeFilters: clearTypeFilters,
          onClearAssignedFilters: clearAssignedFilters,
          onSearchApply: handleSearchApply,
          deletePlaylist: (playlist) => deletePlaylist(playlist),
          refreshPlaylists: handleRefreshPlaylists,
          onAssignUser: (playlist) => onAssignUser(playlist),
          onUnassignUser: (playlist) => onUnassignUser(playlist),
        },
      }}
    >
      {children}
    </PlaylistsContext.Provider>
  );
};

export default PlaylistsProvider;
