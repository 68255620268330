import { screenMd, screenSm } from 'constants/styles/breakpoints';
import styled from 'styled-components';
import { cardStyles } from 'components/layout/profile/profileContent/styles';

export const AvatarContainer = styled.div`
  width: 132px;
  height: 132px;
  border-radius: 50%;
  object-fit: cover;
  position: absolute;
  overflow: hidden;
  left: 12px;
  top: 8px;
  margin-bottom: 10px;
  > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  > svg {
    font-size: 70px !important;
  }
  > div {
    width: 100%;
    height: 100%;
    > div {
      // letter
      font-size: 75px;
    }
  }
  ${screenMd} {
    top: 13px;
    left: 30px;
    width: 156px;
    height: 156px;
    margin-bottom: 0px;
  }
`;

export const ProfileHeaderCard = styled.div`
  ${cardStyles}
  overflow: hidden;
  padding: 0 !important;
  border-radius: 0;
  margin-bottom: 10px;
  position: relative;
  ${screenMd} {
    margin-bottom: 20px;
    border-radius: 20px;
    ${cardStyles}
  }
`;

export const ProfileHeaderGradient = styled.div`
  width: 100%;
  background: linear-gradient(89.99deg, #863ad2 3.58%, #5936e4 97.14%);
  height: auto;
  padding: 5px;
  height: 100px;
  box-sizing: border-box;
  padding-left: 160px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: start;
  ${screenSm} {
    align-items: flex-start;
    padding-left: 160px;
  }
  ${screenMd} {
    display: inline-block;
    height: 125px;
    padding: 0;
    padding-left: 208px;
  }
`;

export const ProfileHeaderActions = styled.div`
  height: 46px;
  padding-left: 155px;
  ${screenMd} {
    height: 56px;
    padding-left: 200px;
  }
`;

export const AvatarImg = styled.img`
  width: 132px;
  height: 132px;
  border-radius: 50%;
  object-fit: cover;
  position: static;
  left: 12px;
  top: 4px;
  margin-bottom: 10px;
  top: 13px;
  width: 156px;
  height: 156px;
  position: absolute;
  margin-bottom: 0;
`;

export const ProfileData = styled.div`
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: end;
  height: 100%;
  > h2 {
    color: #fff;
    margin-bottom: 0;
    font-size: 20px;
    ${screenMd} {
      font-size: 32px;
      line-height: 32px;
    }
  }
  > p {
    font-size: 14px;
    margin-bottom: 7px;
    ${screenMd} {
      font-size: 18px;
      margin-bottom: 10px;
    }
  }
`;

export const ActionsContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 12px;
  ${screenMd} {
    justify-content: flex-end;
    padding-right: 30px;
    > span.edit-label {
      font-size: 18px;
      font-weight: 500;
      flex-grow: 2;
    }
  }
`;

export const Actions = styled.span`
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

export const LastActive = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

export const HeaderLabel = styled.span`
  display: block;
  font-size: 14px !important;
  padding: 5px 2px;
  color: ${({ theme }) => theme.default.colors.fonts.darkGray};
  margin-right: 10px;
  line-height: normal;
`;
