import { useContext, useState } from 'react';
import Button from 'components/button';
import { StickyFooter } from 'components/cards';
import ModalDrawer from 'components/microLayouts/drawer';
import { DrawerContainer } from 'components/usersTable/styles';
import { ActivityLogContext } from 'contexts/activityLog';
import { FilterItem } from 'contexts/activityLog/types';
import ActivityLogDatesFilter from '../ActivityLogFilters/ActivityLogDatesFilter';
import ActivityLogTypesFilter from '../ActivityLogFilters/ActivityLogTypesFilter';
import ActivityLogUsersFilter from '../ActivityLogFilters/ActivityLogUsersFilter';

export interface IModalActivityLogFiltersProps {
  generated_by: string;
  from_date: string;
  to_date: string;
  types: string;
}

interface IActivityLogFiltersModal {
  isModalOpen: boolean;
  onClose(): void;
  onClearFilters(): void;
  onApplyFilters: (filters: IModalActivityLogFiltersProps) => void;
}

const ActivityLogFiltersModal = ({
  isModalOpen,
  onClose,
  onClearFilters,
  onApplyFilters,
}: IActivityLogFiltersModal): JSX.Element => {
  const { filters } = useContext(ActivityLogContext);
  const [modalFilters, setModalFilters] = useState<IModalActivityLogFiltersProps>({
    generated_by: filters.generated_by,
    types: filters.types,
    from_date: filters.from_date,
    to_date: filters.to_date,
  });

  const handleFilterChange = (filter: FilterItem) => {
    setModalFilters((prevState) => ({ ...prevState, [filter.name]: filter.value.toString() }));
  };

  const handleDateChages = (filters: FilterItem[]) => {
    setModalFilters((prevState) => ({
      ...prevState,
      from_date: filters[0]?.value?.toString() || null,
      to_date: filters[1]?.value?.toString() || null,
    }));
  };

  return (
    <>
      <ModalDrawer
        closable={false}
        placement="bottom"
        visible={isModalOpen}
        onClose={onClose}
        height="92%"
        destroyOnClose
      >
        <DrawerContainer style={{ padding: '0px', paddingTop: '0px', gap: '20px' }}>
          <ActivityLogDatesFilter modalScreen={true} datesFilterChange={handleDateChages} />
          <ActivityLogTypesFilter
            modalScreen={true}
            modalValues={modalFilters.types}
            typesFilterChange={handleFilterChange}
          />
          <ActivityLogUsersFilter
            modalScreen={true}
            modalValues={modalFilters.generated_by}
            usersFilterChange={handleFilterChange}
          />
        </DrawerContainer>
        <StickyFooter>
          <Button className="w-100" $secondary onClick={() => onClearFilters()}>
            Clear filters
          </Button>
          <Button className="w-100" $primary onClick={() => onApplyFilters(modalFilters)}>
            Apply filters
          </Button>
        </StickyFooter>
      </ModalDrawer>
    </>
  );
};

export default ActivityLogFiltersModal;
