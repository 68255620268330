import PlaylistEmpty from './components/PlaylistsEmpty';
import PlaylistsEmptySearch from './components/PlaylistsEmpty/PlaylistsEmptySearch';
import PlaylistsCards from './components/PlaylistsCards';
import { PlaylistsContext } from 'contexts/playlists';
import PlaylistsHeader from './components/PlaylistsHeader';
import { Spinner } from 'components/loadings';
import { isMobile } from 'react-device-detect';
import { screenLg, screenSm } from 'constants/styles/breakpoints';
import styled from 'styled-components';
import { useContext } from 'react';

const ContentTable = styled.div`
  display: flex;
  padding: 10px;
  margin-top: 0px;
  opacity: 1;
  transition: opacity 0.4s;
  height: 100%;

  ${screenSm} {
    background-color: #f2f2f7;
    padding: 20px;
  }
  ${screenLg} {
    padding: 20px 20px 0;
  }
`;

const PlaylistsContent = (): JSX.Element => {
  const { list, fetching, actions, filters } = useContext(PlaylistsContext);
  const playlists = list;

  return (
    <>
      <PlaylistsHeader refreshPlaylist={actions.refreshPlaylists} />
      {playlists?.items?.length > 0 ? (
        <>
          <ContentTable>
            <Spinner active={fetching} top={isMobile ? '250px' : '300px'} />
            <PlaylistsCards lock={fetching} />
          </ContentTable>
        </>
      ) : (
        <>
          {!fetching && !filters.name && (
            <PlaylistEmpty refreshPlaylist={actions.refreshPlaylists} />
          )}
          {!fetching && filters.name && <PlaylistsEmptySearch searchQuery={filters.name} />}
          {fetching && <Spinner active={fetching} top={isMobile ? '250px' : '300px'} />}
        </>
      )}
    </>
  );
};

export default PlaylistsContent;
