import styled, { css } from 'styled-components';
import Icon from 'components/icons';
import { colors } from 'constants/styles/colors';
export const Container = styled.div`
  width: 100%;
`;

export const Card = styled.div`
  flex: 1 1 0;
  padding-right: 20px;
  background: white;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid var(--dusty-gray);
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
  display: flex;
`;

export const Indicator = styled.div`
  width: 10px;
  align-self: stretch;
  background-color: ${({ color }) => color || 'none'};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

export const CardContent = styled.div`
  flex: 1 1 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  display: inline-flex;
`;

export const ContentSection = styled.div`
  align-self: stretch;
  padding-top: 15px;
  padding-bottom: 15px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  display: flex;
`;

export const ContentBlock = styled.div`
  align-self: stretch;
  justify-content: space-between;
  align-items: flex-start;
  gap: 9px;
  display: inline-flex;
`;

export const ContentItem = styled.div<{ main?: boolean; info?: boolean }>`
  flex: 1 1 0;
  display: flex;
  gap: 9px;
  justify-content: space-between;
  ${({ info }) => info && `flex: 0; justify-content: flex-end; gap: 30px;`}
  ${({ main }) => main && `flex: 1 1 55%;`}
`;

const containerColumnStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const ItemDetailContainer = styled.div`
  ${containerColumnStyles}
`;

export const BlockContainer = styled.div`
  ${containerColumnStyles}
`;

export const ItemDetailTitle = styled.div`
  font-family: 'Circular-Std';
  font-size: 12px;
  font-weight: 450;
  line-height: 14px;
  text-align: left;
  color: var(--menu-subcategory);
  text-transform: uppercase;
`;

export const ItemText = styled.span`
  color: var(--black-darkest);
  font-size: 14px;
  font-family: 'Circular-Std';
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
`;

export const Title = styled.div`
  color: var(--black-dark);
  font-size: 18px;
  font-family: 'Circular-Std';
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
`;

export const Subtitle = styled.div`
  color: var(--black-dark);
  font-size: 14px;
  font-family: 'Circular-Std';
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
`;

export const DescriptionAndCategory = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: space-between;
`;

export const Description = styled.div`
  color: var(--menu-subcategory);
  font-size: 12px;
  font-style: normal;
  font-weight: 450;
  line-height: 14px;
  word-wrap: break-word;
`;

export const Category = styled.div`
  display: flex;
  margin-top: 8px;
`;

export const CategoryText = styled.div`
  background: var(--grey-lighter);
  height: 25px;
  padding: 5px 10px 5px 10px;
  border-radius: 40px;
  color: var(--light-gray-darker);
  font-size: 10px;
  font-family: 'Circular-Std';
  text-transform: uppercase;
`;

export const AssignmentCount = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-left: 10px;
`;

export const AssignmentTextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AssignmentDivider = styled.div`
  width: 0.5px;
  align-self: stretch;
  background: var(--bright-gray-lighter);
`;

export const AssigmentsListContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
`;

export const AssigmentsList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const AssignmentItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
`;

export const Assignments = styled.div<{ editable?: boolean }>`
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  ${({ editable }) => editable && `width: 50px;`}
`;

export const NumberBig = styled.div`
  text-align: center;
  color: var(--black-dark);
  font-size: 32px;
  font-family: 'Circular-Std';
  font-weight: 700;
  line-height: 38px;
  word-wrap: break-word;
`;

export const Number = styled.div`
  color: var(--black-dark);
  font-size: 14px;
  font-family: 'Circular-Std';
  font-weight: 700;
  line-height: 26px;
  word-wrap: break-word;
  width: 20px;
  text-align: center;
`;

export const Text = styled.div`
  text-align: center;
  color: var(--menu-subcategory);
  font-size: 12px;
  font-family: 'Circular-Std';
  font-weight: 450;
  line-height: 12px;
  word-wrap: break-word;
`;

export const FooterDivider = styled.div`
  align-self: stretch;
  height: 1px;
  background: var(--bright-gray-lighter);
`;

export const ViewCalendar = styled.div`
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  display: flex;
  a {
    color: var(--black-dark);
  }
`;

export const AccountName = styled.div`
  color: var(--lilac-dark);
  font-size: 14px;
  font-family: 'Circular-Std';
  font-weight: 450;
  line-height: 20px;
  word-wrap: break-word;
`;

export const InfoIcon = styled(Icon)<{ fill?: string }>`
  fill: ${colors.secondaryPallete.purple.light};
  &:hover {
    fill: ${colors.secondaryPallete.purple.base};
  }
  margin-left: 3px;
`;

export const NoAssignments = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 450;
  color: #777;
  span {
    color: var(--lilac-dark);
  }
`;
