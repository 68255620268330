import { Buttons, Container, Description, Info, Title } from './styles';
import { FC, ReactNode, useEffect, useState } from 'react';
import Button from 'components/button';
import Icon from 'components/icons';
import Modal from 'components/modal';

interface ActionModalProps {
  title: string;
  description: string | ReactNode;
  active: boolean;
  buttons: Array<{
    disabled?: boolean;
    label: string;
    type: string;
    onClick: () => void;
  }>;
  handleCancel: () => void;
  loading?: boolean;
}

const ActionModal: FC<ActionModalProps> = ({
  title,
  description,
  active,
  buttons,
  handleCancel,
  loading,
}) => {
  const [open, setOpen] = useState(false);

  const onCancel = () => {
    setOpen(false);
    if (handleCancel) handleCancel();
  };

  useEffect(() => {
    setOpen(active);
  }, [active]);

  return (
    <Modal
      centered
      onCancel={onCancel}
      visible={open}
      footer={null}
      width="400px"
      bodyStyle={{ borderRadius: '3px !important' }}
    >
      <Container>
        <Info>
          {title && (
            <Title>
              {title}
              {loading && (
                <span className="rotating">
                  <Icon icon="Loading" />
                </span>
              )}
            </Title>
          )}
          <Description>{description}</Description>
        </Info>

        <Buttons>
          {buttons.map(({ disabled, label, onClick, type }, index) => {
            return (
              <Button
                disabled={disabled || loading}
                $primary={type === 'primary'}
                $secondary={type === 'secondary'}
                onClick={onClick}
                size="small"
                key={index}
                $full
              >
                {label}
              </Button>
            );
          })}
        </Buttons>
      </Container>
    </Modal>
  );
};

export default ActionModal;
