import { screenMd } from 'constants/styles/breakpoints';
import styled from 'styled-components/macro';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const StyledEllipsis = styled.div<{ active?: boolean }>`
  background: ${({ theme, active }) =>
    active
      ? theme.default.colors.dataVisualization.purple.dark
      : theme.default.colors.dataVisualization.purple.lighter};
  border-radius: 50px;
  width: 7.5px;
  height: 7.5px;
  ${screenMd} {
    width: 13px;
    height: 13px;
  }
  &:not(:last-child) {
    margin-right: 10px;
  }
`;
