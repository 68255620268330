import { useContext, useEffect, useState } from 'react';
import useSearchQuery from './useSearchQuery';
import { getInvitationsAction, getRemoveUsersAction, getUsersCounts } from 'contexts/users/actions';
import { usersInitialValues } from 'contexts/users/utils';
import {
  FilterItem,
  URLSearchParams,
  UserInvites,
  UsersCounts,
  UsersDataCtx,
} from '../contexts/users/types';
import Notification from 'components/notification';
import { SessionContext } from 'contexts/session';

interface UsersContentHook {
  fetching: boolean;
  loading: boolean;
  users: UsersDataCtx['table'];
  error: string | boolean;
  selectedRows: string[];
  usersCounts: UsersCounts;
  search: URLSearchParams;
  updateUsersTable: (rows: UserInvites[]) => void;
  removeTableItem: (ids: string[], message: string) => void;
  onForceRequest: () => void;
  onRefreshData: () => void;
  handleSelectRow: (id: string) => void;
  handleSelectAllRows: () => void;
  updateCounts: () => void;
  updateFilters: (newValue: FilterItem, concat: boolean, autoApply: boolean) => void;
  handleSearchApply: () => void;
  onClearSearch: () => void;
}

interface UsersContentProps {
  fixedFilters?: {
    active?: string;
    license?: string;
    teams?: string;
    roles?: string;
    page?: number;
    page_size?: number;
    search?: string;
    ordering?: string;
    accounts?: string;
    license_addons?: string;
    no_license_addons?: string;
  };
}

const useUsersTableContent = ({ fixedFilters }: UsersContentProps): UsersContentHook => {
  const {
    updateLicensesAvailables,
    user: { uaa_id },
  } = useContext(SessionContext);
  const [usersTable, setUsersTable] = useState(usersInitialValues.table);
  const [fetching, setFetching] = useState(false);
  const { searchQuery, search, updateSearch } = useSearchQuery();
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [usersCounts, setUsersCounts] = useState({});

  const getUsers = async () => {
    setFetching(true);
    const getUsersMethod = getInvitationsAction;
    const usersResponse = await getUsersMethod(handleReqData());
    setFetching(false);
    if (usersResponse instanceof Error) {
      setUsersTable((prevState) => ({ ...prevState, error: usersResponse.message }));
    } else {
      setUsersTable((prevState) => ({
        ...prevState,
        ...usersResponse,
        rows: usersResponse.results,
      }));
    }
  };

  const updateFilters = (newValue: FilterItem, concat: boolean, autoApply: boolean) => {
    const filter = newValue.name.toString();
    const prevValue = search.get(filter);
    let value = newValue.value.toString();

    if (concat && prevValue) {
      // To add values or remove values in the same query parameter.
      const valuesArr = prevValue.split(',');
      value = valuesArr.includes(value)
        ? valuesArr.filter((e) => e !== value).join(',')
        : [...valuesArr, value].join(',');
    }
    // remove filter so then it is moved to the last place.
    search.delete(filter);
    // Clear paging if last filter applied is not a page change.
    filter !== 'page' && search.set('page', '1');

    // Remove team filter if user selectcs all
    if (!['all_teams', 'all_roles'].includes(newValue.value.toString()) && value)
      search.set(filter, value);

    if (filter === 'active') {
      search.set('search', ''); // reset search on chage actives or pendings
      getCounts({ active: newValue.value !== 'false' });
    }

    // Apply query params if it is an autoapply filter.
    autoApply && handleSearchApply();
  };

  const handleSearchApply = () => {
    updateSearch(search?.toString());
  };

  useEffect(() => {
    getUsers();
  }, [searchQuery]);

  useEffect(() => {
    updateCounts();
  }, []);

  useEffect(() => {
    setSelectedRows([]);
    updateCounts();
  }, [usersTable?.rows]);

  const handleReqData = () => {
    return {
      active: fixedFilters?.active || search.get('active') || 'true',
      license: fixedFilters?.license || search.get('license') || null,
      teams: fixedFilters?.teams || search.get('team') || null,
      roles: fixedFilters?.roles || search.get('roles') || null,
      page: fixedFilters?.page || parseInt(search.get('page')) || null,
      page_size: fixedFilters?.page_size || parseInt(search.get('page_size') || '9') || null,
      search: fixedFilters?.search || search.get('search') || null,
      ordering: fixedFilters?.ordering || search.get('ordering') || null,
      accounts: fixedFilters?.accounts || search.get('accounts') || null,
      license_addons: fixedFilters?.license_addons || search.get('license_addons') || null,
      no_license_addons: fixedFilters?.no_license_addons || search.get('no_license_addons') || null,
    };
  };

  const removeTableItem = async (ids: string[], message: string) => {
    setFetching(true);
    const cleanIds = ids.filter((id) => id !== uaa_id);
    const updatedTable = usersTable?.rows.filter(({ id }) => !cleanIds.includes(id));
    try {
      const response = await getRemoveUsersAction({ ids: cleanIds });
      if (response instanceof Error) {
        setUsersTable((prevState) => ({ ...prevState, error: response.message }));
      } else {
        usersTable?.rows.filter(({ id, license }) => {
          if (cleanIds.includes(id) && license) {
            updateLicensesAvailables(false, true);
          }
        });
        setUsersTable((prevState) => ({
          ...prevState,
          rows: updatedTable,
          count: prevState.count - 1,
        }));
        Notification({ text: message, type: 'success' });
      }
    } catch (error) {
      Notification({ text: 'Error trying to remove invitation.', type: 'error' });
    } finally {
      setFetching(false);
    }
  };

  const handleSelectRow = (id: string) => {
    const findRow = selectedRows.find((rowId) => rowId === id);
    if (findRow) setSelectedRows((prevState) => prevState.filter((rowId) => rowId !== id));
    else setSelectedRows((prevState) => [...prevState, id]);
  };

  const handleSelectAllRows = () => {
    const areAllRowsSelected = selectedRows.length === usersTable?.rows?.length;
    if (areAllRowsSelected) setSelectedRows([]);
    else setSelectedRows(usersTable?.rows?.map((i: UserInvites) => i.id) || []);
  };

  const getCounts = async ({ active }) => {
    setLoading(true);
    const params: { active: boolean; account_id?: string } = { active };
    if (fixedFilters && fixedFilters.accounts) {
      params.account_id = fixedFilters.accounts;
    }
    const dataStats = await getUsersCounts(params);
    setLoading(false);
    if (dataStats instanceof Error) {
      setUsersCounts((prevState) => ({ ...prevState, error: dataStats.message }));
    } else {
      setUsersCounts(dataStats);
    }
  };

  const updateCounts = () => {
    const active = search.get('active');
    getCounts({ active: active !== 'false' });
  };

  const updateUsersTable = (newContent: UserInvites[]) => {
    setUsersTable((prevState) => {
      return {
        ...prevState,
        rows: newContent,
      };
    });
  };

  const clearFilters = () => {
    search.delete('license');
    search.delete('team');
    search.delete('roles');
    search.delete('page');
    search.delete('page_size');
    search.delete('search');
    search.delete('ordering');
    search.delete('accounts');
    search.delete('license_addons');
    search.delete('no_license_addons');
    handleSearchApply();
  };

  return {
    fetching: fetching,
    loading: loading,
    users: usersTable,
    error: false,
    selectedRows,
    usersCounts,
    search,
    removeTableItem,
    updateUsersTable: updateUsersTable,
    onForceRequest: getUsers,
    onRefreshData: getUsers,
    handleSelectRow,
    handleSelectAllRows,
    updateCounts,
    handleSearchApply,
    updateFilters,
    onClearSearch: clearFilters,
  };
};

export default useUsersTableContent;
