import { useEffect, useMemo, useState } from 'react';
import Button from 'components/button';
import Modal from 'components/modal';
import {
  HeaderContainer,
  ModalActions,
  ModalContainer,
  ModalContent,
} from 'components/modal/styles';

import { NotBookedContainer } from './styles/AssignedProductCard.styles';
import {
  FooterContainer,
  InfoColorContainer,
  InfoColorReference,
  InfoColorWrapper,
} from './styles';
import { Step, StepNumber, StepTitle, StepsContainer } from './styles/ScheduleModal.styles';
import ButtonIcon from 'components/buttonIcon';
import AssignUser from './AssignUser';
import ScheduleUser from './ScheduleUser';
import { IBookingsParams, IDatesParams, IRaxProduct } from 'contexts/rax/types';
import Tooltip from 'components/tooltip';

interface AvailabilityProps {
  currentUserId?: string;
  edit?: boolean;
  isDisabled?: boolean;
  product: IRaxProduct;
  users: any;
  modalOpen?: boolean;
  onScheduleBooking?: (params: IBookingsParams) => void;
}

const ScheduleModalActionAO = ({
  currentUserId,
  edit = false,
  isDisabled = false,
  product,
  users,
  onScheduleBooking,
}: AvailabilityProps): JSX.Element => {
  const STEPS = {
    ASSIGN: 'ASSIGN',
    SCHEDULE: 'SCHEDULE',
  };

  const [currentStep, setCurrentStep] = useState(STEPS.ASSIGN);
  const [openModal, setOpenModal] = useState(false);
  const [datesSelected, setDatesSelected] = useState(null);
  const [userAssigned, setUserAssigned] = useState(null);

  useEffect(() => {
    setUserAssigned(null);
    setDatesSelected(null);
  }, []);

  const onSave = async () => {
    const { start_date } = datesSelected;
    const params = {
      user: userAssigned,
      account_rax_lab: product.id,
      start_date: `${start_date} 00:00:00`,
    };
    onScheduleBooking(params);
    setOpenModal(false);
  };

  const onManageBooking = () => setOpenModal(true);

  const onScheduleUser = (dates: IDatesParams) => {
    const { start_date, end_date } = dates;
    const value = start_date && end_date ? dates : null;
    setDatesSelected(value);
  };

  const onAssignUser = (user: number) => setUserAssigned(user);

  const getUserById = (id: string) => {
    return users.find((user) => user?.user === id);
  };

  const canSave = useMemo(() => {
    return edit ? datesSelected !== null : datesSelected !== null && userAssigned !== null;
  }, [datesSelected, userAssigned]);

  useEffect(() => {
    !edit ? setCurrentStep(STEPS.ASSIGN) : setCurrentStep(STEPS.SCHEDULE);
    if (currentUserId) {
      const user = getUserById(currentUserId);
      setUserAssigned(user);
    }
  }, [openModal]);

  const currentUser = useMemo(() => {
    const user = getUserById(userAssigned);
    const username = `${user?.uaa_data?.profile?.data?.first_name} ${user?.uaa_data?.profile?.data?.last_name}`;
    const email = user?.uaa_data?.profile?.data?.email;
    const avatar = user?.uaa_data?.profile?.data?.avatar;
    return { username, email, avatar };
  }, [userAssigned]);

  return (
    <>
      {!edit ? (
        <div className="d-flex align-items-center w-100" style={{ gap: '5px' }}>
          <NotBookedContainer>
            It looks like you haven&apos;t booked this session yet.
            <span onClick={() => onManageBooking()}>Select five consecutive days</span> from the
            available time slots.{' '}
          </NotBookedContainer>
          <Tooltip title="Manage booking">
            <ButtonIcon
              data-testid="plus-button"
              style={{ margin: '0 5px 0 auto' }}
              $terciary
              size={'small'}
              icon={'Plus'}
              onClick={onManageBooking}
            />
          </Tooltip>
        </div>
      ) : (
        <ButtonIcon
          data-testid="edit-button"
          style={{ margin: '0 5px' }}
          $terciary
          disabled={isDisabled}
          size={'small'}
          icon={'Edit'}
          onClick={onManageBooking}
        />
      )}
      {openModal && (
        <Modal
          visible={openModal}
          bodyStyle={{ borderRadius: '20px' }}
          footer={null}
          width={'761px'}
          closeIconColor="#fff"
          maskClosable={false}
          onCancel={() => setOpenModal(false)}
        >
          <ModalContainer style={{ borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }}>
            <HeaderContainer
              isRax={true}
              style={{ height: '100px', minHeight: '100px', padding: 0 }}
            >
              <h2>
                {!edit ? 'Schedule' : 'Edit'} {product.name}
              </h2>
            </HeaderContainer>
          </ModalContainer>
          <ModalContent
            className="d-flex flex-column"
            style={{ height: '675px', paddingBottom: 10, maxHeight: '78vh' }}
            sidePadding
          >
            <StepsContainer>
              {!edit && (
                <Step active={currentStep === STEPS.ASSIGN}>
                  <StepNumber>1</StepNumber>
                  <StepTitle>Assign user</StepTitle>
                </Step>
              )}
              <Step active={currentStep === STEPS.SCHEDULE}>
                {!edit && <StepNumber>2</StepNumber>}
                <StepTitle>Schedule time</StepTitle>
              </Step>
            </StepsContainer>
            <div className="d-flex h-100 flex-column">
              {currentStep === STEPS.ASSIGN && (
                <AssignUser users={users} onSelectUser={onAssignUser} />
              )}
              {currentStep === STEPS.SCHEDULE && (
                <ScheduleUser
                  user={currentUser}
                  onSelectDates={onScheduleUser}
                  raxLabId={product.product_id}
                />
              )}
            </div>
          </ModalContent>
          <ModalActions>
            {currentStep === STEPS.SCHEDULE && (
              <FooterContainer>
                <InfoColorContainer style={{ gap: '15px' }}>
                  <InfoColorWrapper>
                    <InfoColorReference bgcolor="var(--white)" borderColor="var(--grey-light)" />
                    Available
                  </InfoColorWrapper>
                  <InfoColorWrapper>
                    <InfoColorReference
                      bgcolor="var(--grey-light)"
                      borderColor="var(--grey-light)"
                    />
                    Unavailable
                  </InfoColorWrapper>
                  <InfoColorWrapper>
                    <InfoColorReference
                      bgcolor="var(--melrose-quartz)"
                      borderColor="var(--melrose-quartz)"
                    />
                    Selected
                  </InfoColorWrapper>
                </InfoColorContainer>
              </FooterContainer>
            )}
            {currentStep === STEPS.ASSIGN && (
              <Button
                $secondary
                onClick={() => setCurrentStep(STEPS.SCHEDULE)}
                disabled={userAssigned === null}
                data-testid="continue-btn"
              >
                Continue
              </Button>
            )}
            {currentStep === STEPS.SCHEDULE && (
              <>
                <Button
                  $secondary
                  style={{ marginLeft: 'auto' }}
                  onClick={() => setOpenModal(false)}
                  data-testid="cancel-btn"
                >
                  Cancel
                </Button>
                <Button $primary onClick={onSave} disabled={!canSave} data-testid="save-btn">
                  {`Save ${!edit ? '' : 'changes'}`}
                </Button>
              </>
            )}
          </ModalActions>
        </Modal>
      )}
    </>
  );
};

export default ScheduleModalActionAO;
