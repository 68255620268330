import { format } from 'date-fns';
import { useContext } from 'react';
import { ActivityLog } from 'contexts/activityLog/types';
import {
  ActivityLogItemRowMobile,
  ItemDateContainer,
  ItemDateLabel,
  ItemTimeLabel,
  TimelineDot,
  TimelineTail,
} from '../styles';
import { renderActivityLogByAction } from '../ActivityLogList/utils';
import { SessionContext } from 'contexts/session';

interface IActivityLogItemMobileProps {
  activity: ActivityLog;
}

const ActivityLogItemMobile = ({ activity }: IActivityLogItemMobileProps): JSX.Element => {
  const { isStaff } = useContext(SessionContext);
  return (
    <ActivityLogItemRowMobile>
      <ItemDateContainer>
        <ItemDateLabel>{format(new Date(activity.created_at), 'dd/MM/yy')}</ItemDateLabel>
        <ItemTimeLabel>{format(new Date(activity.created_at), 'HH:mm')}</ItemTimeLabel>
      </ItemDateContainer>
      <div>
        <TimelineDot />
        <TimelineTail />
      </div>
      <div>{renderActivityLogByAction(activity, isStaff)}</div>
    </ActivityLogItemRowMobile>
  );
};

export default ActivityLogItemMobile;
