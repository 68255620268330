import React, { useMemo, useState } from 'react';
import { Card, CardContent, Container } from './styles/ProductCard.styles';
import { format } from 'date-fns';
import { every, isNull } from 'lodash';

import {
  InputContainer,
  ScheduleDaysInfo,
  UserContainer,
  UserName,
} from './styles/AssignEditUser.styles';
import { Input } from 'antd';

import CalendarContainer from '../components/CalendarContainer';
import Icon from 'components/icons';
import { AvatarLetter } from 'components/avatar';
import { IDatesParams } from 'contexts/rax/types';

interface IUser {
  email: string;
  username: string;
  avatar: string;
}
export interface AssignUserProps {
  user: IUser;
  raxLabId: string;
  onSelectDates: (value: IDatesParams) => void;
}

const ScheduleUser: React.FC<AssignUserProps> = ({ user, raxLabId, onSelectDates }) => {
  const [datesSelected, setDatesSelected] = useState({ start_date: null, end_date: null });
  const selectDates = (value: IDatesParams) => {
    setDatesSelected(value);
    onSelectDates(value);
  };

  const dateStringFormat = (dateString: string) => {
    const [year, month, day] = dateString.split('-');
    return `${month}-${day}-${year}`;
  };

  const datesLabel = useMemo(() => {
    if (!every(datesSelected, isNull)) {
      const endDate = format(new Date(datesSelected.end_date).setHours(0, 0, 0, 0), 'MM-dd-yyyy');
      return `${dateStringFormat(datesSelected.start_date)} / ${endDate}`;
    }
    return 'Start Date / End Date';
  }, [datesSelected]);

  return (
    <Container className="d-flex h-100 flex-column">
      <Card className="p-0 border-0" style={{ overflowY: 'auto' }}>
        <CardContent>
          <InputContainer>
            <Input
              size="large"
              value={datesLabel}
              disabled
              style={{
                height: '44px',
                color: 'var(--black-darkest)',
                fontSize: '12px',
              }}
            />
            <Icon
              icon={'Calendar'}
              size="md"
              style={{ position: 'absolute', top: '11px', right: '10px' }}
            />
          </InputContainer>
          <UserContainer className="w-100">
            <div style={{ minWidth: '25px' }}>
              <AvatarLetter
                size="xs"
                name={user.username}
                avatar={user.avatar}
                avatarSize="xs"
                rounded
              />
            </div>
            <UserName>
              Assigned to: <span>{user.username}</span>
              <Icon
                style={{ margin: '0 5px' }}
                icon="CheckGreen"
                color={'var(--positive-dark'}
                size="small"
              />
            </UserName>
            <ScheduleDaysInfo>
              <span>|</span> Select five consecutive days from the available time slots.
            </ScheduleDaysInfo>
          </UserContainer>
          <CalendarContainer
            isInteractable
            flat
            onSelectDates={selectDates}
            raxLabId={raxLabId}
            fetchData={true}
            isModal={true}
          />
        </CardContent>
      </Card>
    </Container>
  );
};

export default ScheduleUser;
