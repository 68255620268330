import { useContext, useEffect, useState } from 'react';
import { format, subDays } from 'date-fns';
import { useScrollDirection } from 'react-use-scroll-direction';
import Button from 'components/button';
import { StickyFooter } from 'components/cards';
import { Spinner } from 'components/loadings';
import ModalDrawer from 'components/microLayouts/drawer';
import { Account } from 'contexts/accounts/types';
import { NotesContext } from 'contexts/notes';
import CreateNote from '../CreateNote';
import SearchNotesFilter from '../NotesFilters/SearchNotesFilter';
import {
  NotesContainerMobile,
  NotesDrawerFiltersContainer,
  NotesMobileDrawerContainer,
  NotesMobileTitle,
} from '../styles';
import NoteListItemMobile from './NoteListItemMobile';
import ButtonIcon from 'components/buttonIcon';
import NoteDetailModal from './NoteDetailModal';
import { Note } from 'contexts/notes/types';
import NotesFiltersModal, { NotesFiltersProps } from './NotesFiltersModal';
import NotesEmpty from '../NotesEmpty';

interface INotesDrawerProps {
  entity: Account;
  notesDrawerVisible: boolean;
}

const isInViewport = (el) => {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
  );
};

export const NotesDrawer = ({ entity, notesDrawerVisible }: INotesDrawerProps): JSX.Element => {
  const {
    fetching,
    actions,
    table,
    filters: { page },
    isEmpty,
  } = useContext(NotesContext);
  const { isScrollingDown, scrollTargetRef } = useScrollDirection();
  const [showCreateNoteModal, setShowCreateNoteModal] = useState<boolean>(false);
  const [showNoteDetail, setShowNoteDetail] = useState<boolean>(false);
  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);
  const [noteSelected, setNoteSelected] = useState<Note>(null);

  const handleCreateNoteModalResponse = (result) => {
    setShowCreateNoteModal(false);
    setNoteSelected(null);
    if (result === 'ok') {
      actions.onNotesRefresh();
      actions.onRefreshNoteUsers();
    }
  };

  const handleDeleteNoteCofirmation = () => {
    actions.onDeleteNote(noteSelected.id);
    setShowNoteDetail(false);
  };

  const handleNoteDetailEdited = () => {
    setShowNoteDetail(false);
    setNoteSelected(null);
    actions.onNotesRefresh();
  };

  const handleShowNoteDetail = (note: Note) => {
    setNoteSelected(note);
    setShowNoteDetail(true);
  };

  const handleCloseNoteDetail = () => {
    setNoteSelected(null);
    setShowNoteDetail(false);
  };

  const handleClearFilters = () => {
    actions.onChangeFilters([
      { name: 'from_date', value: null },
      { name: 'to_date', value: null },
      { name: 'search', value: '' },
      { name: 'created_by', value: '' },
      { name: 'page', value: 1 },
      { name: 'append_results', value: false },
    ]);
    setShowFiltersModal(false);
  };

  const handleApplyFilters = (modalFilters: NotesFiltersProps) => {
    const newFilters = [
      { name: 'page', value: 1 },
      { name: 'append_results', value: false },
    ];
    Object.keys(modalFilters).map((key) => {
      newFilters.push({ name: key, value: modalFilters[key] });
    });
    actions.onChangeFilters(newFilters);
    setShowFiltersModal(false);
  };

  const handleScroll = (e) => {
    if (
      isScrollingDown &&
      !fetching &&
      table.items.length !== table.count &&
      isInViewport(e.target.lastChild)
    ) {
      actions.onChangeFilter({ name: 'page', value: (parseInt(page) + 1).toString() });
    }
  };

  useEffect(() => {
    if (notesDrawerVisible) {
      actions.onChangeFilters([
        { name: 'content', value: 'account' },
        { name: 'content_id', value: entity.id },
        { name: 'append_results', value: false },
        { name: 'from_date', value: null },
        { name: 'to_date', value: null },
      ]);
    }
  }, [notesDrawerVisible]);

  return (
    <>
      <ModalDrawer
        closable={false}
        placement="bottom"
        visible={notesDrawerVisible}
        height="92%"
        destroyOnClose
      >
        <NotesMobileDrawerContainer>
          <Spinner active={fetching} />
          <NotesMobileTitle>Notes</NotesMobileTitle>
          <NotesDrawerFiltersContainer>
            <SearchNotesFilter />
            <ButtonIcon $secondary icon={'Filter'} onClick={() => setShowFiltersModal(true)} />
          </NotesDrawerFiltersContainer>

          <NotesContainerMobile
            fetching={fetching}
            className="mt-5"
            onScroll={handleScroll}
            ref={scrollTargetRef}
          >
            {!isEmpty ? (
              table?.items?.map((item, index) => {
                return (
                  <NoteListItemMobile
                    key={index}
                    note={item}
                    onShowNoteDetail={handleShowNoteDetail}
                  />
                );
              })
            ) : (
              <NotesEmpty onAddNote={() => setShowCreateNoteModal(true)} />
            )}
          </NotesContainerMobile>
        </NotesMobileDrawerContainer>
        <StickyFooter>
          <Button
            className="w-100"
            $primary
            icon={'Clip'}
            onClick={() => setShowCreateNoteModal(true)}
          >
            Add note
          </Button>
        </StickyFooter>
      </ModalDrawer>
      {showCreateNoteModal && (
        <CreateNote
          isModalOpen={showCreateNoteModal}
          note={null}
          entity={entity}
          onClose={handleCreateNoteModalResponse}
        />
      )}
      {showNoteDetail && (
        <NoteDetailModal
          isModalOpen={showNoteDetail}
          note={noteSelected}
          entity={entity}
          onClose={handleCloseNoteDetail}
          onNoteEdited={handleNoteDetailEdited}
          onDeleteConfirmation={handleDeleteNoteCofirmation}
        />
      )}
      {showFiltersModal && (
        <NotesFiltersModal
          isModalOpen={showFiltersModal}
          onClose={handleCloseNoteDetail}
          onClearFilters={handleClearFilters}
          onApplyFilters={handleApplyFilters}
        />
      )}
    </>
  );
};

export default NotesDrawer;
