import Icon from 'components/icons';
import { InputContainer, StyledNumericInput } from './styles';

interface NumericInputProps {
  onChange: (value: number) => void;
  value: number;
  unlimited?: boolean;
  minValue?: number;
  maxValue?: number;
  placeholder: string;
  error: boolean;
}

const buttonsStyles = {
  width: '10px',
  height: '10px',
  position: 'absolute',
  right: '18px',
  background: 'transparent',
  border: 'none',
  padding: 0,
} as React.CSSProperties;

const NumericInput: React.FC<NumericInputProps> = ({
  onChange,
  value,
  unlimited,
  minValue,
  maxValue,
  placeholder,
  error,
}) => {
  const minusDisabled = unlimited || minValue >= value || !value;
  const plusDisabled = unlimited || maxValue <= value;

  const handleSum = () => {
    onChange(value ? value + 1 : 1);
  };

  const handleRest = () => {
    onChange(value - 1);
  };

  const handleOnChange = (e) => {
    const newValue = e.target.value;

    if (newValue.match(/[^0-9]/g)) return;

    onChange(parseInt(newValue) || 0);
  };

  const handleOnBlur = () => {
    if (minValue && minValue >= value) onChange(minValue);
    if (maxValue && maxValue <= value) onChange(maxValue);
  };

  return (
    <InputContainer>
      <StyledNumericInput
        unlimited={unlimited}
        value={unlimited ? '∞' : value}
        disabled={unlimited}
        placeholder={placeholder}
        error={error}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
      />
      <button
        className="numeric-input__plus-button"
        disabled={plusDisabled}
        onClick={() => handleSum()}
        style={{ ...buttonsStyles, top: '10px', cursor: plusDisabled && 'not-allowed' }}
      >
        <Icon
          color={plusDisabled ? '#AEAEB2' : '#BB85FF'}
          size={'smaller'}
          icon={'PlusNumeric'}
          style={{ width: '8px' }}
        />
      </button>
      <button
        className="numeric-input__minus-button"
        disabled={minusDisabled}
        onClick={() => handleRest()}
        style={{ ...buttonsStyles, bottom: '10px', cursor: minusDisabled && 'not-allowed' }}
      >
        <Icon
          color={minusDisabled ? '#AEAEB2' : '#BB85FF'}
          size={'smaller'}
          icon={'MinusNumeric'}
          style={{ width: '8px' }}
        />
      </button>
    </InputContainer>
  );
};

export default NumericInput;
