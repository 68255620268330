import { useContext, useState } from 'react';
import { AccountsContext } from 'contexts/accounts';
import { isBrowser, isMobile } from 'react-device-detect';
import Button from 'components/button/Button';
import SearchFilter from './Search';
import {
  ActionButton,
  Container,
  Content,
  ContentModalFilters,
  ContentScroll,
  ContentTitle,
  FilterButton,
  Header,
  ModalFiltersFooter,
  SelectedCounts,
  Title,
} from './styles';
import ModalDrawer from 'components/microLayouts/drawer';
import AccountsFilters from '../AccountsFilters';
import { SessionContext } from 'contexts/session';
import useSearchQuery from 'hooks/useSearchQuery';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';

const UserHeaderFilters: React.FC = (): JSX.Element => {
  const { actions } = useContext(AccountsContext);
  const { showDrawer, setVisibiltyDrawer } = useContext(SessionContext);
  const { search, updateSearch } = useSearchQuery();
  const [savedSearch, setSavedSearch] = useState(null);

  const handleCreateAccount = () => {
    actions.onAccountCreateEdit({ type: 'create' });
  };

  const openFilterModal = () => {
    setVisibiltyDrawer(true);
    setSavedSearch(search.toString());
    actions?.setIsAutoApply(false);
  };

  const closeFilterModal = (isBack?: boolean) => {
    setVisibiltyDrawer(false);
    actions?.setIsAutoApply(true);
    if (isBack) updateSearch(savedSearch);
  };

  const handleOnClearFilters = () => {
    actions?.onClearSearch();
    closeFilterModal();
  };

  const handleOnApplyFilters = () => {
    actions?.setIsAutoApply(true);
    //actions?.onApplySearch();
    closeFilterModal();
  };

  return (
    <>
      <Header>
        <Container>
          {isBrowser && (
            <ContentTitle>
              <Title>Accounts</Title>
            </ContentTitle>
          )}

          <Content grow={2}>
            <SearchFilter />
            {isMobile && (
              <FilterButton
                onClick={() => {
                  openFilterModal();
                }}
              >
                <FilterIcon color={'#A793F6'} />
              </FilterButton>
            )}
          </Content>

          <ActionButton>
            <Button $primary={true} onClick={handleCreateAccount} icon={'Plus'}>
              Create account
            </Button>
          </ActionButton>
        </Container>
      </Header>
      {isMobile && (
        <ModalDrawer
          closable={false}
          placement="bottom"
          visible={showDrawer}
          onClose={() => {
            closeFilterModal(true);
          }}
          height="calc(100% - 64px)"
          destroyOnClose
        >
          <ContentModalFilters>
            <ContentScroll>
              <AccountsFilters />
            </ContentScroll>
            <ModalFiltersFooter>
              <Button
                $full
                $secondary
                size={'small'}
                icon={'ChevronRight'}
                onClick={() => handleOnClearFilters()}
              >
                Clear filters
              </Button>
              <Button
                $full
                $secondary
                size={'small'}
                icon={'Trash'}
                onClick={() => handleOnApplyFilters()}
              >
                Apply filters
              </Button>
            </ModalFiltersFooter>
          </ContentModalFilters>
        </ModalDrawer>
      )}
    </>
  );
};

export default UserHeaderFilters;
