import React, { useMemo, useState } from 'react';
import {
  BlockContainer,
  Card,
  CardContent,
  Container,
  Indicator,
  Title,
} from './styles/ProductCard.styles';

import {
  CardContainer,
  Divider,
  SectionInfo,
  SectionTitle,
  Sku,
} from './styles/ProductCardAssignment.styles';
import InputNumberFlat from 'components/inputs/inputNumberFlat';
import InputSelectSearch from 'components/inputs/inputSelectSearch';

export interface ProductCardAssignmentProps {
  name: string;
  max_quantity?: number;
  checkfront_sku: string;
  category?: { name: string; color: string; id: string };
  accounts?: Array<any>;
  onAssign?: (value) => void;
}

const ProductCardAssignment: React.FC<ProductCardAssignmentProps> = ({
  name,
  checkfront_sku,
  category,
  accounts,
  onAssign,
}) => {
  const inputProps = {
    minValue: 1,
    maxValue: 150,
    placeholder: '1',
    unlimited: false,
    error: false,
  };

  const options = useMemo(
    () =>
      accounts
        ?.map(({ name, id }) => {
          return {
            label: `${name}`,
            info: `${id}`,
            value: id,
            key: id,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label)),
    []
  );

  const [seats, setSeats] = useState(1);
  const [accountSelected, setAccountSelected] = useState(null);

  const onSelectAccount = (account) => {
    setAccountSelected(account.value);
    onAssign({ account: account.value, quantity: seats });
  };

  const setSeatsHandler = (value: number) => {
    setSeats(value);
    onAssign({ account: accountSelected, quantity: value });
  };
  return (
    <>
      <Container>
        <SectionTitle>Select product</SectionTitle>
        <Card className="p-0">
          <Indicator color={category.color} />
          <CardContent style={{ padding: '5px' }}>
            <CardContainer className="w-100 d-flex justify-content-between">
              <BlockContainer>
                <Title>{name}</Title>
                <Sku>{checkfront_sku}</Sku>
              </BlockContainer>
              <InputNumberFlat
                {...inputProps}
                value={seats}
                onChange={(value) => setSeatsHandler(value)}
              />
            </CardContainer>
          </CardContent>
        </Card>
        <Divider />
        <SectionTitle style={{ marginBottom: '0' }}>Select account</SectionTitle>
        <SectionInfo>Choose an account from the dropdown to assign products to.</SectionInfo>
        <InputSelectSearch
          value={accountSelected ? `${accountSelected.label} ${accountSelected.value}` : null}
          onChange={onSelectAccount}
          options={options}
          defaultOpen={false}
          placeHolder="Select one account"
          maxDropdownHeight="250px"
        />
        <Divider />
      </Container>
    </>
  );
};

export default ProductCardAssignment;
