import EmptySvg from 'assets/images/empty-users.svg';
import { EmptyCustomCard } from 'components/layout/profile/profileContent/styles';

const EmptyTeamManagersCard = (): JSX.Element => (
  <EmptyCustomCard>
    <img src={EmptySvg} />
    <h5>This team hasn’t set any team managers yet</h5>
  </EmptyCustomCard>
);

export default EmptyTeamManagersCard;
