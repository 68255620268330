export const MAX_LOGO_SIZE_BYTES = 25600;
export const VALID_LOGO_IMG_TYPES = '.jpg,.png';
export const NOTICE_HEIGHT = 66;
export const MIGRATED_NOTICE_HEIGHT = 66;
export const MIGRATED_NOTICE_HEIGHT_MOB = 110;
export const ASIDE_DESKTOP_WIDTH = 140;
export const ASIDE_MOBILE_WIDTH = 60;
export const HEADER_HEIGHT = 64;
export const FEEDBACK_ISSUES_CONTACT = 'beta@ine.com';
export const INE_CONTACT_SUPPORT = 'support@ine.com';
export const INE_CONTACT_SALES = 'sales@ine.com';
export const FIRST_PLAYLIST_CATEGORY = 'Introduction';
export const INE_UUID = 'a491bc32-c056-4946-9169-cc053387bada';
export const SSO_DOCUMENTATION_URL =
  'https://docs.google.com/document/d/1JBvzXgSFkO1r8yJbjhCdde11fjMvih-CBH2fXRq2rFQ';
