import styled from 'styled-components';
import { screenMd } from 'constants/styles/breakpoints';

export const ActivityLogDatesSelectContainer = styled.div<{ showCustom?: boolean }>`
  display: grid;
  grid-template-columns: ${({ showCustom }) => (showCustom ? '80% 20%' : '100%')};
  .ant-picker-suffix svg {
    width: 18px;
    height: 18px;
  }
  button {
    height: 43px !important;
  }
  div:first-of-type {
    ${({ showCustom }) => showCustom && 'margin-right: 5px;'};
  }
  ${screenMd} {
    width: 100%;
    grid-template-columns: ${({ showCustom }) => (showCustom ? '80% 20%' : '100%')};
  }
`;
