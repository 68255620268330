import { useContext, useEffect, useState } from 'react';
import { ReportsContext } from 'contexts/reporting';
import { SessionContext } from 'contexts/session';
import { isMobile } from 'react-device-detect';

import Button from 'components/button/Button';
import ButtonIcon from 'components/buttonIcon';
import ModalDrawer from 'components/microLayouts/drawer';
import Tooltip from 'components/tooltip';

import { Details } from './ReportingHistoryComponents';
import { reportStatus } from 'contexts/reporting/utils';

interface ReportDetailsProps {
  reportData: {
    id: string;
    filename: string;
    created_at: string;
    size: string;
    created_by: {
      first_name: string;
      last_name: string;
    };
    status: string;
  };
  onDownloadClick: (id: string) => void;
}

const ReportDetailsDrawer = ({ reportData, onDownloadClick }: ReportDetailsProps): JSX.Element => {
  const { reportUrl } = useContext(ReportsContext);
  const { showDrawer, setVisibiltyDrawer } = useContext(SessionContext);
  const [selectedReport, setSelectedReport] = useState(null);
  const [fetchingUrl, setFetchingUrl] = useState(false);

  const handlerDrawer = () => {
    setSelectedReport(reportData);
    setVisibiltyDrawer(!showDrawer);
  };

  useEffect(() => {
    reportUrl && window.open(reportUrl);
    setFetchingUrl(false);
  }, [reportUrl]);

  useEffect(() => {
    !showDrawer && setSelectedReport(null);
  }, [showDrawer]);

  const onClickDownloadHandler = (id: string) => {
    onDownloadClick(id);
    setFetchingUrl(true);
  };

  return (
    <>
      <Tooltip placement="left" title="Go to report details">
        <ButtonIcon
          data-testid="handler-drawer-btn"
          $terciary
          icon="ChevronRight"
          size="small"
          onClick={() => {
            handlerDrawer();
          }}
        />
      </Tooltip>

      <ModalDrawer
        closable={false}
        placement="bottom"
        visible={showDrawer && selectedReport === reportData}
        onClose={handlerDrawer}
        height="calc(100% - 64px)"
        destroyOnClose
      >
        <Details.Background>
          <Details.Header>
            <Details.Title>{reportData.filename}</Details.Title>
          </Details.Header>
          <Details.Content>
            <Details.Row.Container>
              <Details.Row.Title>{'size'}</Details.Row.Title>
              <Details.Row.Separator></Details.Row.Separator>
              <Details.Row.Value>{reportData.size}</Details.Row.Value>
            </Details.Row.Container>
            <Details.Row.Container>
              <Details.Row.Title>{'created'}</Details.Row.Title>
              <Details.Row.Separator></Details.Row.Separator>
              <Details.Row.Value>{reportData.created_at}</Details.Row.Value>
            </Details.Row.Container>
            <Details.Row.Container>
              <Details.Row.Title>{'created by'}</Details.Row.Title>
              <Details.Row.Separator></Details.Row.Separator>
              <Details.Row.Value>{reportData.created_at}</Details.Row.Value>
            </Details.Row.Container>
            {reportData.status === reportStatus.completed && (
              <Details.CTAs isMobile={isMobile}>
                <Button
                  $full
                  $primary
                  size={'small'}
                  icon={'Download'}
                  loading={fetchingUrl}
                  onClick={() => onClickDownloadHandler(reportData.id)}
                >
                  Download report
                </Button>
              </Details.CTAs>
            )}
          </Details.Content>
        </Details.Background>
      </ModalDrawer>
    </>
  );
};

export default ReportDetailsDrawer;
